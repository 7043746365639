import { t } from '@lingui/macro';
import { IconButton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useAssistant } from 'app/domains/assistant';
/**
 * Button to delete the latest document assistant chat
 */
export function AssistantClearHistoryButton() {
    const { thread, clearThread, loading, hasCredits, isPendingAssistantMessage } = useAssistant();
    const hasMessages = thread.length > 0;
    const disabled = loading || !hasMessages || !hasCredits || isPendingAssistantMessage;
    return (<IconButton onClick={clearThread} appearance="ghost" disabled={disabled} aria-label={hasMessages
            ? t({
                comment: 'Document Assistant Chat - Clear history button tooltip label',
                message: 'Clear history',
            })
            : undefined} tooltip={{
            position: 'bottom',
        }}>
      <Falcon icon="trash"/>
    </IconButton>);
}
