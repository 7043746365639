import { createPath, parsePath } from 'history';
export function generateReturnTo(options) {
    const { marketCode, returnTo = '/' } = options !== null && options !== void 0 ? options : {};
    if (marketCode) {
        const location = parsePath(returnTo);
        const pathname = `/${marketCode}${location.pathname}`;
        return createPath(Object.assign(Object.assign({}, location), { pathname }));
    }
    return returnTo;
}
