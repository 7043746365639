import { IconButton, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Header, TextWrapper, HeaderTitleContainer, Title } from './styled';
export function DrawerHeader(props) {
    const { onClose, title, subTitle, children } = props;
    return (<Header>
      <TextWrapper>
        <HeaderTitleContainer>
          <Title $fontSize="large" $appearance="300" $fontWeight="medium">
            {title}
          </Title>
          <IconButton $round size="medium" appearance="ghost" onClick={onClose}>
            <Falcon icon="xmark"/>
          </IconButton>
        </HeaderTitleContainer>
        {subTitle && (<Typography $fontSize="small" $appearance="100">
            {subTitle}
          </Typography>)}
      </TextWrapper>
      {children}
    </Header>);
}
