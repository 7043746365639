import { breakpoints } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const AlignRight = styled.div `
  display: flex;
  margin-left: auto;
`;
export const ButtonText = styled.span `
  ${breakpoints.xs} {
    display: none;
  }
`;
