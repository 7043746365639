import { t } from '@lingui/macro';
import { AvatarWithLabel } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { SuggestionBox } from './SuggestionBox';
import { isSuggestionBoxRewriteHighlight } from './utils';
import { MessageText } from '../MessageText';
import { Message } from '../styled';
export function AssistantMessage(props) {
    const { message, writing = false, actions } = props;
    const { rewriteSuggestions = {} } = actions || {};
    const validRewriteSuggestions = Object.values(rewriteSuggestions).filter(isSuggestionBoxRewriteHighlight);
    return (<Message $direction="left">
      <AvatarWithLabel solid size="md" appearance="ai" title={t({
            message: 'Plai',
            comment: 'Review chat - message - assistant name',
        })}>
        <Falcon icon="sparkles"/>
      </AvatarWithLabel>
      <MessageText text={message} writing={writing}/>
      {validRewriteSuggestions.map(highlight => (<SuggestionBox key={highlight.id} writing={writing} highlight={highlight}/>))}
    </Message>);
}
