import { useState } from 'react';
import { Menu, MenuList, IconButton, MenuBody } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
export function AccessMenuButton(props) {
    const { children } = props;
    const [open, setOpen] = useState(false);
    return (<Menu open={open} onClose={() => setOpen(false)} target={<IconButton onClick={() => setOpen(!open)} $round appearance="ghost" size="small">
          <Falcon icon="ellipsis-vertical"/>
        </IconButton>}>
      <MenuBody>
        <MenuList>{children}</MenuList>
      </MenuBody>
    </Menu>);
}
