import { FieldArray, useField } from 'formik';
import { DocumentField } from './DocumentField';
import { TemplateField } from './TemplateField';
import { AttachmentFieldLabel } from '../AttachmentFieldLabel';
const getFieldByType = (type) => {
    switch (type) {
        case 'template':
            return TemplateField;
        case 'document':
            return DocumentField;
        default:
            return null;
    }
};
export function AttachmentField() {
    var _a;
    const name = 'attachments';
    const [field] = useField(name);
    const hide = ((_a = field.value) === null || _a === void 0 ? void 0 : _a.length) < 1;
    if (hide) {
        return null;
    }
    return (<AttachmentFieldLabel>
      <FieldArray name={name}>
        {({ remove, replace }) => field.value.map((attachment, index) => {
            const Field = getFieldByType(attachment.type);
            return (Field && (<div key={attachment.attachmentId}>
                  <Field index={index} attachment={attachment} replace={replace} remove={remove}/>
                </div>));
        })}
      </FieldArray>
    </AttachmentFieldLabel>);
}
