import { t } from '@lingui/macro';
import { Logotype } from '@pocketlaw/tetris';
import { PROVIDER_IDS, DocuSignIcon, ScriveIcon } from 'app/domains/esigning/utils';
export const getProviderData = (provider) => {
    const data = {
        [PROVIDER_IDS.POCKETLAW]: {
            logo: Logotype,
            name: t({ message: 'PocketLaw', comment: 'PocketLaw provider name' }),
            description: t({
                message: 'Fully integrated',
                comment: 'This is the description for our own (PocketLaw) provider',
            }),
            ariaLabelConnected: t({
                message: 'Choose PocketLaw',
                comment: 'Used for aria label (screen reader) for our own provider',
            }),
            ariaLabelUnconnected: t({
                message: 'Connect to PocketLaw',
                comment: 'Used for aria label (screen reader) for our own provider',
            }),
        },
        [PROVIDER_IDS.DOCUSIGN]: {
            logo: DocuSignIcon,
            name: t({ message: 'DocuSign', comment: 'DocuSign provider name' }),
            description: t({
                message: 'Subscription service',
                comment: 'This is the description for the DocuSign provider',
            }),
            ariaLabelConnected: t({
                message: 'Choose DocuSign',
                comment: 'Used for aria label (screen reader) for the DocuSign provider',
            }),
            ariaLabelUnconnected: t({
                message: 'Connect to DocuSign',
                comment: 'Used for aria label (screen reader) for the DocuSign provider',
            }),
        },
        [PROVIDER_IDS.SCRIVE]: {
            logo: ScriveIcon,
            name: t({ message: 'Scrive', comment: 'Scrive provider name' }),
            description: t({
                message: 'Subscription service',
                comment: 'This is the description for the Scrive provider',
            }),
            ariaLabelConnected: t({
                message: 'Choose Scrive',
                comment: 'Used for aria label (screen reader) for the Scrive provider',
            }),
            ariaLabelUnconnected: t({
                message: 'Connect to Scrive',
                comment: 'Used for aria label (screen reader) for the Scrive provider',
            }),
        },
    };
    return data[provider];
};
