import { t } from '@lingui/macro';
import { SignRequestStatus } from 'shared/domains/apollo/generated/types';
import { SearchStringFilter } from './SearchStringFilter';
export const esigningStatusFilterLabel = () => t({ message: 'eSigning status', comment: 'Insights filter: Esigning status' });
export function EsigningStatusFilter() {
    const items = [
        {
            id: SignRequestStatus.Pending,
            title: t({
                message: 'Pending',
                comment: 'Insights: Esigning status: Pending',
            }),
        },
        {
            id: SignRequestStatus.Signed,
            title: t({
                message: 'Signed',
                comment: 'Insights: Esigning status: Signed',
            }),
        },
        {
            id: SignRequestStatus.Rejected,
            title: t({
                message: 'Rejected',
                comment: 'Insights: Esigning status: Rejected',
            }),
        },
    ];
    return (<SearchStringFilter name="metadataDocumentEsigningStatus" items={items} label={esigningStatusFilterLabel()}/>);
}
