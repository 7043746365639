import { Trans, t } from '@lingui/macro';
import { Alert, EmptyState, TableBody, TableHead, TableHeadCell, TableHeaderText, TableRow, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useFileBrowserContext } from 'app/domains/filesystem';
import { FolderPermissionWarning } from './FolderPermissionWarning';
import { Row } from './Row';
import { SkeletonRow } from './SkeletonRow';
import { PickerContent } from '../PickerContent';
import { AlertContainer, StyledTable } from './styled';
export function PickerTableFilesystem(props) {
    const { selectType, selectedEntryId, selectedEntryAlert } = props;
    const { entries, selected, loading, limit } = useFileBrowserContext();
    const showEntryAlert = selected && selectedEntryAlert && selectedEntryAlert.check(selected);
    const showSelectCell = selectType === 'document';
    const loadingEntries = Array(limit)
        .fill(null)
        .map((_, index) => index);
    if (!entries.length && !loading) {
        return (<PickerContent>
        <EmptyState withAvatar icon={<Falcon icon="folder-open"/>} title={t({
                comment: 'When a folder does not contain any documents - Title',
                message: 'Empty folder',
            })} description={t({
                comment: 'When a folder does not contain any documents - Description',
                message: 'This folder does not contain any documents.',
            })}/>
      </PickerContent>);
    }
    return (<PickerContent>
      {showEntryAlert && (<AlertContainer>
          <Alert {...selectedEntryAlert}/>
        </AlertContainer>)}

      <FolderPermissionWarning />

      <StyledTable dense>
        <colgroup>
          <col width="auto"/>
          <col width="120"/>
          <col width="120"/>
          <col width="180"/>
          {showSelectCell && <col width="40"/>}
        </colgroup>
        <TableHead>
          <TableRow>
            <TableHeadCell id="cell:name">
              <TableHeaderText>
                <Trans comment="Table column header: Title">Title</Trans>
              </TableHeaderText>
            </TableHeadCell>
            <TableHeadCell id="cell:updated">
              <TableHeaderText>
                <Trans comment="Table column header: Updated">Updated</Trans>
              </TableHeaderText>
            </TableHeadCell>
            <TableHeadCell id="cell:status">
              <TableHeaderText>
                <Trans comment="Table column header: Status">Status</Trans>
              </TableHeaderText>
            </TableHeadCell>
            <TableHeadCell id="cell:owner">
              <TableHeaderText>
                <Trans comment="Table column header: Owner">Owner</Trans>
              </TableHeaderText>
            </TableHeadCell>
            {showSelectCell && <TableHeadCell id="cell:select"/>}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading
            ? loadingEntries.map(index => (<SkeletonRow key={index} showSelectCell={showSelectCell}/>))
            : entries.map(entry => (<Row key={entry.id} entry={entry} showSelectCell={showSelectCell} selectedEntryId={selectedEntryId}/>))}
        </TableBody>
      </StyledTable>
    </PickerContent>);
}
