import { useState } from 'react';
import { useLingui } from '@lingui/react';
import { FilterButton, Menu, MenuBody } from '@pocketlaw/tetris';
import { parseISO } from 'date-fns';
import { DateRangeForm } from './DateRangeForm';
import { formatSelectedDateRange } from './formatSelectedDateRange';
import { useFilter } from '../useFilter';
export function DateRange(props) {
    const { name } = props;
    const filter = useFilter(name);
    const [open, setOpen] = useState(!filter.hasValue);
    const { i18n } = useLingui();
    const data = filter.value;
    const handleOnSubmit = () => {
        setOpen(false);
    };
    const gte = (data === null || data === void 0 ? void 0 : data.gte) ? parseISO(data === null || data === void 0 ? void 0 : data.gte) : undefined;
    const lte = (data === null || data === void 0 ? void 0 : data.lte) ? parseISO(data === null || data === void 0 ? void 0 : data.lte) : undefined;
    const appearance = filter.isTouched ? 'draft' : 'default';
    const selectedValue = i18n._(formatSelectedDateRange(filter.value));
    return (<Menu open={open} onClose={() => setOpen(!open)} target={<FilterButton type="button" appearance={appearance} onClick={() => setOpen(!open)}>
          {selectedValue}
        </FilterButton>}>
      <MenuBody>
        <DateRangeForm name={name} initialValue={{
            gte,
            lte,
        }} onSubmit={handleOnSubmit}/>
      </MenuBody>
    </Menu>);
}
