import { msg } from '@lingui/macro';
import { EsigningAttachmentKind } from 'shared/domains/apollo/generated/types';
import { localizedFormatDate } from 'shared/domains/common-utils';
const getDateLabelType = (createdAt, updatedAt, kind) => {
    if (kind === EsigningAttachmentKind.Default) {
        return 'default';
    }
    if (!createdAt && !updatedAt) {
        return 'new';
    }
    if (createdAt && !updatedAt) {
        return 'created';
    }
    if (updatedAt && !createdAt) {
        return 'updated';
    }
    if (createdAt === updatedAt) {
        return 'created';
    }
    return 'updated';
};
export const getAttachmentSubtitle = (createdAt, updatedAt, kind) => {
    const dateLabelType = getDateLabelType(createdAt, updatedAt, kind);
    const createdAtDate = localizedFormatDate(createdAt, {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
    });
    const updatedAtDate = localizedFormatDate(updatedAt, {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
    });
    const createdAtLabel = msg({
        comment: 'Attachment added label',
        message: `Added ${createdAtDate}`,
    });
    const updatedAtLabel = msg({
        comment: 'Attachment updated label',
        message: `Updated ${updatedAtDate}`,
    });
    const newAttachmentLabel = msg({ comment: 'Attachment added label', message: 'New attachment' });
    const defaultAttachmentLabel = msg({
        comment: 'Default attachment label',
        message: 'Default attachment',
    });
    const map = {
        default: defaultAttachmentLabel,
        created: createdAtLabel,
        updated: updatedAtLabel,
        new: newAttachmentLabel,
    };
    return map[dateLabelType];
};
