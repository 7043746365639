import { t } from '@lingui/macro';
import { SidebarMenuHeader, SidebarMenuSearch } from '@pocketlaw/tetris';
import { useUniversalSearch } from 'app/domains/global-search';
import { isUserAgent } from 'shared/domains/common-utils';
export function Header(props) {
    const { mobileAction } = props;
    const { openSearchPopup } = useUniversalSearch();
    const shortcutLabelMac = t({
        message: '⌘K',
        comment: 'Universal search popup shortcut label for mac: ⌘k',
    });
    const shortcutLabelNonMac = t({
        message: 'Ctrl+k',
        comment: 'Universal search popup shortcut label for non mac: Ctrl+K',
    });
    const shortcutLabel = isUserAgent('mac') ? shortcutLabelMac : shortcutLabelNonMac;
    const searchLabel = t({
        message: 'Search',
        comment: 'Sidebar universal search label',
    });
    return (<SidebarMenuHeader action={mobileAction}>
      <SidebarMenuSearch label={searchLabel} shortcutLabel={shortcutLabel} onClick={openSearchPopup}/>
    </SidebarMenuHeader>);
}
