import { Fragment, useState } from 'react';
import { Trans } from '@lingui/macro';
import { Button } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useEsigning } from 'app/domains/esigning/components/EsigningProvider';
import { ConfirmReminderDialog } from './ConfirmReminderDialog';
export function SendReminderButton() {
    const [showDialog, setShowDialog] = useState(false);
    const { signed, rejected } = useEsigning();
    const closeDialog = () => setShowDialog(false);
    const openDialog = () => setShowDialog(true);
    if (signed || rejected) {
        return null;
    }
    return (<Fragment>
      {showDialog && <ConfirmReminderDialog onClose={closeDialog}/>}
      <Button $stretch size="medium" appearance="secondary" onClick={openDialog}>
        <Falcon icon="alarm-clock"/>
        <Trans comment="E-signing send reminder button label">Send reminder</Trans>
      </Button>
    </Fragment>);
}
