import { t } from '@lingui/macro';
import { Box, EmptyState } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
export function EmptyVisualization() {
    const title = t({ message: 'Nothing here', comment: 'Insights: No data title' });
    const description = t({
        message: 'There is no activity to report yet.',
        comment: 'Insights: No data subtitle',
    });
    return (<Box flexDirection="column" justifyContent="center" width="100%">
      <EmptyState withAvatar title={title} description={description} icon={<Falcon icon="chart-line-up"/>}/>
    </Box>);
}
