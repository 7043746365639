import { t } from '@lingui/macro';
import { AssistantContext } from 'app/domains/assistant';
/**
 * Helper to strip the context from a ChatMessageType.
 * Example: `DOCUMENT_ASK` will return `Document`
 */
export const getPromptContextByPromptType = (type) => {
    const [context] = (type === null || type === void 0 ? void 0 : type.split('_').slice(0, -1)) || [];
    switch (context) {
        case AssistantContext.Selection:
            return t({
                comment: 'Document assistant chat - context for disclaimer - selection',
                message: 'Selection',
            });
        case AssistantContext.Document:
            return t({
                comment: 'Document assistant chat - context for disclaimer - document',
                message: 'Document',
            });
        default:
            return undefined;
    }
};
