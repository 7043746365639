import { Fragment } from 'react';
import useAccount from '../../hooks/useAccount';
export function SSORestricted(props) {
    var _a;
    const { children } = props;
    const { data, loading } = useAccount();
    if (loading || ((_a = data.company) === null || _a === void 0 ? void 0 : _a.ssoEnterprise)) {
        return null;
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <Fragment>{children}</Fragment>;
}
