import { AvatarLetter } from '@pocketlaw/tetris';
import { StyledAvatar } from './styled';
export function Avatar(props) {
    const { title, grayscale = false } = props;
    if (!title) {
        return null;
    }
    const initial = title.substring(0, 1).toUpperCase();
    return (<StyledAvatar size="md" appearance={grayscale ? 'secondary' : 'primary'}>
      <AvatarLetter>{initial}</AvatarLetter>
    </StyledAvatar>);
}
