import gql from 'graphql-tag';
export const mutation = gql `
  mutation deleteCompanyUser($id: UUID!) {
    deleteCompanyUser(where: { id: { _eq: $id } }) {
      returning {
        id
        authId: user_id
      }
    }
  }
`;
