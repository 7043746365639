import { matchPath } from 'react-router-dom';
export function isCachingAccount(query) {
    var _a, _b, _c;
    if (query.loading || query.error) {
        return false;
    }
    return ((_b = (_a = query.data) === null || _a === void 0 ? void 0 : _a.companyUsers) === null || _b === void 0 ? void 0 : _b.length) && !((_c = query.data) === null || _c === void 0 ? void 0 : _c.account);
}
export function isLoading(query) {
    return query.loading || isCachingAccount(query);
}
export function resolveAccount(accounts, storedAccountId) {
    var _a;
    if (!accounts) {
        return null;
    }
    if (storedAccountId) {
        const account = accounts.find(({ id }) => id === storedAccountId);
        if (account) {
            return account;
        }
    }
    return (_a = accounts[0]) !== null && _a !== void 0 ? _a : null;
}
const EXCLUDED_AUTH_ROUTES = ['/verify', '/onboarding'];
export const isExcludedRoute = (pathname) => Boolean(matchPath(pathname, {
    path: EXCLUDED_AUTH_ROUTES,
}));
