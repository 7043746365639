import { Trans } from '@lingui/macro';
import { Button } from '@pocketlaw/tetris';
import { Container } from './styled';
export function ShareButton(props) {
    const { onClick } = props;
    return (<Container>
      <Button $stretch appearance="primary" onClick={onClick}>
        <Trans comment="Label for sharing a document externally">Share document</Trans>
      </Button>
    </Container>);
}
