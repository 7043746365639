import { useMutation } from '@apollo/client';
import { mutation } from './mutation';
export const useDeleteUser = (id) => useMutation(mutation, {
    variables: { id: id || '' },
    update(cache, { data }) {
        var _a, _b;
        const { authId } = ((_b = (_a = data === null || data === void 0 ? void 0 : data.deleteCompanyUser) === null || _a === void 0 ? void 0 : _a.returning) === null || _b === void 0 ? void 0 : _b[0]) || {};
        cache.modify({
            id: cache.identify({ id: authId, __typename: 'User' }),
            fields(_, { DELETE }) {
                return DELETE;
            },
        });
    },
});
