import { gql } from '@apollo/client';
export const addTeamMembersMutation = gql `
  mutation addTeamMembers($teamId: UUID!, $userIds: [CreateTeamMemberRelativeInput!]) {
    updateTeam(id: $teamId, input: { members: $userIds }) {
      id
      members {
        id
        createdAt
        user {
          id
          email
          name
        }
      }
    }
  }
`;
