import { Fragment, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Alert, Button } from '@pocketlaw/tetris';
import { useEsigning } from 'app/domains/esigning/components/EsigningProvider';
import { useDocumentApproversWithStatus } from 'app/domains/esigning/hooks/useDocumentApproversWithStatus';
import { ApprovalType } from 'shared/domains/apollo/generated/types';
import { RejectedByApprover } from './RejectedByApprover';
import { RequestApprovalDialog } from './RequestApprovalDialog';
import { useDocumentApproversPermissions } from './useDocumentApproversPermissions';
import { DocumentApprovers } from '../DocumentApprovers';
import { SectionContent, SectionFooter } from '../components';
import { OverlayLoader } from '../components/OverlayLoader';
export function ApprovalRequestState() {
    const { loading: loadingApprovers } = useDocumentApproversWithStatus();
    const { approversWithoutPermission, loading: loadingApproversPermissions } = useDocumentApproversPermissions();
    const { approvalWorkflow } = useEsigning();
    const { approvalType } = approvalWorkflow;
    const [showDialog, setShowDialog] = useState(false);
    const loading = loadingApprovers || loadingApproversPermissions;
    const handleClick = () => {
        setShowDialog(true);
    };
    const handleClose = () => {
        setShowDialog(false);
    };
    const alertTitle = t({
        comment: 'Document requires approval state - Info label',
        message: 'Approval required',
    });
    const oneOfMessage = t({
        comment: 'Document requires approval state - Needs approval by one of the approvers',
        message: 'This document needs approval by one of the assigned approvers before being sent for e-signing',
    });
    const allMessage = t({
        comment: 'Document requires approval state - Needs approval by all of the approvers',
        message: 'This document needs approval by all of the assigned approvers before being sent for e-signing',
    });
    const alertMessage = approvalType === ApprovalType.All ? allMessage : oneOfMessage;
    const loadingMessage = t({
        comment: 'Document requires approval state - Loading document approvers message',
        message: 'Loading document approvers...',
    });
    return (<Fragment>
      {loading && <OverlayLoader overlay message={loadingMessage}/>}
      {showDialog && (<RequestApprovalDialog approversWithoutPermission={approversWithoutPermission} onClose={handleClose}/>)}
      <SectionContent>
        <RejectedByApprover />
        <DocumentApprovers />
      </SectionContent>
      <SectionFooter>
        <Alert appearance="info" title={alertTitle} message={alertMessage}/>
        <Button $stretch disabled={loading} onClick={handleClick}>
          <Trans comment="Ask for approval button label">Ask for approval</Trans>
        </Button>
      </SectionFooter>
    </Fragment>);
}
