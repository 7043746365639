import { defineMessage } from '@lingui/macro';
import { TeamMemberRole } from 'shared/domains/apollo/generated/types';
export const teamRoles = [
    {
        value: TeamMemberRole.Manager,
        label: defineMessage({
            message: 'Team Manager',
            comment: 'Name of team manager role',
        }),
        description: defineMessage({
            message: 'Can edit team settings and invite and remove team members',
            comment: 'Description of team manager role.',
        }),
    },
    {
        value: TeamMemberRole.Member,
        label: defineMessage({
            message: 'Team Member',
            comment: 'Name of team member role',
        }),
        description: defineMessage({
            message: 'Can access everything the team has access to',
            comment: 'Description of team member role.',
        }),
    },
];
