import { Card } from '@pocketlaw/tetris';
import { WidgetLoader } from 'app/domains/common-ui';
import { StyledCardContent } from './styled';
export function MetricCardLoader() {
    return (<Card>
      <StyledCardContent>
        <WidgetLoader />
      </StyledCardContent>
    </Card>);
}
