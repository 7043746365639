import { t } from '@lingui/macro';
import { Alert } from '@pocketlaw/tetris';
export function ErrorAlert() {
    const title = t({
        message: 'Something went wrong',
        comment: 'users table: error alert title',
    });
    const message = t({
        message: 'We cant load the users right now. Please try again later.',
        comment: 'users table: error alert message',
    });
    return <Alert title={title} message={message} appearance="danger"/>;
}
