import { useLingui } from '@lingui/react';
export function useFormatDate(date) {
    const { i18n } = useLingui();
    // eslint-disable-next-line no-underscore-dangle
    const _i18n = i18n;
    const DATE_FORMAT = Object.freeze({
        year: 'numeric',
        month: 'short',
        day: '2-digit',
    });
    return _i18n.date(date, DATE_FORMAT);
}
