import { Trans } from '@lingui/macro';
import { Button, Loader } from '@pocketlaw/tetris';
import { Formik } from 'formik';
import useSendMessage from 'shared/domains/feedback/hooks/useSendMessage';
import { Field } from './Field';
import { validationSchema } from './schema';
import { StyledForm } from './styled';
const INITIAL_VALUES = { message: '' };
export function SendMessage(props) {
    const { inviteId, recipientId, onSuccess } = props;
    const onSubmit = useSendMessage({
        inviteId,
        recipientId,
        onSuccess,
    });
    return (<Formik initialValues={INITIAL_VALUES} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ values, isSubmitting }) => (<StyledForm>
          <Field />
          <Button type="submit" disabled={!values.message || isSubmitting}>
            {isSubmitting ? (<Loader />) : (<Trans comment="Label for sending message in externally shared chat">Send</Trans>)}
          </Button>
        </StyledForm>)}
    </Formik>);
}
