import { msg } from '@lingui/macro';
import { localizedFormatDate } from 'shared/domains/common-utils';
export function formatSelectedDateRange(filter) {
    const { gte, lte } = filter || {};
    const greaterOrEqualDate = gte && localizedFormatDate(gte);
    const lessOrEqualDate = lte && localizedFormatDate(lte);
    if (!gte && !lte) {
        return msg({
            message: 'Select value',
            comment: 'Search filter: Date range filter: Select value',
        });
    }
    if (!gte && lte) {
        return msg({
            message: `before ${lessOrEqualDate}`,
            comment: 'Search filter: Date range filter value selection',
        });
    }
    if (gte && !lte) {
        return msg({
            message: `after ${greaterOrEqualDate}`,
            comment: 'Search filter: Date range filter value selection',
        });
    }
    return msg({
        message: `between ${greaterOrEqualDate} and ${lessOrEqualDate}`,
        comment: 'Search filter: Date range filter value selection',
    });
}
