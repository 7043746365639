var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Box } from '@pocketlaw/tetris';
import { Formik } from 'formik';
import { FormContent } from './FormContent';
import { schema } from './schema';
export function AttachmentsForm(props) {
    const { isEdit, isEqual, initialAttachments, onSubmit } = props;
    const isOverrideEdit = isEdit && !isEqual;
    const handleSubmit = (values) => __awaiter(this, void 0, void 0, function* () {
        yield onSubmit(values.attachments);
    });
    return (<Formik validateOnMount initialValues={{ attachments: initialAttachments }} onSubmit={handleSubmit} validationSchema={schema}>
      <Box flexDirection="column" height="100%">
        <FormContent showOverrideAlert={isOverrideEdit}/>
      </Box>
    </Formik>);
}
