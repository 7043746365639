import { Trans, t } from '@lingui/macro';
import { Checkbox, Typography } from '@pocketlaw/tetris';
import { Field, useFormikContext } from 'formik';
export function Checkboxes() {
    const { isSubmitting } = useFormikContext();
    return (<div>
      <Typography $fontSize="small" $fontWeight="semibold">
        <Trans comment="Document assistant - feedback modal - dislike - checkboxes section title">
          Add context to your feedback
        </Trans>
      </Typography>
      <Field as={Checkbox} label={t({
            message: 'Harmful',
            comment: 'Document assistant - feedback modal - dislike - checkbox label - Harmful',
        })} name="harmful" disabled={isSubmitting}/>
      <Field as={Checkbox} label={t({
            message: 'Incorrect',
            comment: 'Document assistant - feedback modal - dislike - checkbox label - Incorrect',
        })} name="incorrect" disabled={isSubmitting}/>
      <Field as={Checkbox} label={t({
            message: 'Unhelpful',
            comment: 'Document assistant - feedback modal - dislike - checkbox label - Unhelpful',
        })} name="unhelpful" disabled={isSubmitting}/>
    </div>);
}
