var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useRef } from 'react';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { ChipDropdown } from 'shared/domains/common-ui';
import { Form } from 'shared/domains/forms';
import { useCategories } from './useCategories';
import { useUpdateCategory } from './useUpdateCategory';
import { useDocumentMetadata } from '../DocumentMetadataProvider';
import { SingleSelectList } from '../MetadataField/MetadataForm/SingleSelectList';
export function TemplateCategory() {
    const { data, documentId } = useDocumentMetadata();
    const { categories } = useCategories();
    const { addToast } = useToast();
    const { mutation } = useUpdateCategory(documentId);
    const ref = useRef();
    const { systemData } = data;
    const { category, categoryId } = systemData;
    const closeDropdown = () => { var _a; return (_a = ref.current) === null || _a === void 0 ? void 0 : _a.toggleDropdown(); };
    const handleOnSubmit = (formData) => __awaiter(this, void 0, void 0, function* () {
        const { value } = formData;
        try {
            yield mutation(value);
            addToast({
                title: t({ message: 'Template category updated', comment: 'Metadata: Category updated' }),
                appearance: 'success',
            });
            closeDropdown();
        }
        catch (_a) {
            addToast({
                title: t({ message: 'An error occurred', comment: 'Metadata: Category update failed' }),
                appearance: 'danger',
            });
        }
    });
    return (<ChipDropdown size="large" label={t({ message: 'Template category', comment: 'Metadata: Label for template category' })} secondary={category} ref={ref} dropdownContent={<Form onSubmit={handleOnSubmit} initialValues={{ value: categoryId }}>
          <SingleSelectList data={categories}/>
        </Form>}/>);
}
