import { useField, useFormikContext } from 'formik';
export function useFilter(name) {
    const { status } = useFormikContext();
    const [field, meta, helpers] = useField(`filters.${name}`);
    const { value, name: path } = field;
    const operator = Object.keys(value || {})[0];
    const hasValue = Boolean(operator && value && value[operator] != null);
    const isDraft = !hasValue && !meta.touched;
    const isTouched = meta.touched && (status === null || status === void 0 ? void 0 : status.showDirty);
    const setValue = (newValue) => {
        helpers.setValue(newValue);
        helpers.setTouched(true);
    };
    const resetValue = () => {
        helpers.setValue(undefined);
        helpers.setTouched(true);
    };
    return {
        path,
        value,
        isDraft,
        isTouched,
        hasValue,
        operator,
        setValue,
        resetValue,
        name: field.name,
    };
}
