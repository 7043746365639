var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AclAction } from 'shared/domains/apollo/generated/types';
import { getDocumentUsersWithAcls } from './getDocumentUsersWithAcls';
import { sanitizeUserObject } from './utils';
import { PleditorPlugin } from '../PleditorPlugin';
export class UsersManagerRTC extends PleditorPlugin {
    constructor() {
        super(...arguments);
        this.userAclsMap = {};
    }
    static get pluginName() {
        return 'UsersManagerRTC';
    }
    static get requires() {
        return ['Users', 'ApiConnector'];
    }
    init() {
        return __awaiter(this, void 0, void 0, function* () {
            this.getDocumentUsersWithAcls();
        });
    }
    getAllUsers() {
        const usersPlugin = this.editor.plugins.get('Users');
        return [...usersPlugin.users];
    }
    addUser(user) {
        if (!user)
            return;
        const usersPlugin = this.editor.plugins.get('Users');
        const sanitizedUser = sanitizeUserObject(user);
        const exitingUser = usersPlugin.getUser(sanitizedUser.id);
        if (exitingUser) {
            // Override properties of existing user
            Object.assign(exitingUser, Object.assign({}, sanitizedUser));
        }
        else {
            usersPlugin.addUser(sanitizedUser);
        }
    }
    getUserActions(userId) {
        if (this.isExternal) {
            return [AclAction.Read];
        }
        return this.userAclsMap[userId] || [];
    }
    getDocumentUsersWithAcls() {
        return __awaiter(this, void 0, void 0, function* () {
            const { users, userAclsMap } = yield getDocumentUsersWithAcls(this.apiConnector, this.documentId, this.isExternal);
            this.userAclsMap = userAclsMap;
            users.forEach(user => {
                this.addUser(user);
            });
        });
    }
}
