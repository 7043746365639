import { Button, padding, fontSize, unit, marginTop, pxToRem, themeGet } from '@pocketlaw/tetris';
import { Text } from '@pocketlaw/tetris/deprecated';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: ${unit(4)};
  min-height: 100vh;
  ${padding(6)};
  margin: 0 auto;
`;
export const ErrorCode = styled(Text) `
  ${fontSize(pxToRem(30), 1)};
`;
export const Line = styled.hr `
  margin-left: inherit;
  width: ${unit(25)};
  border-color: ${themeGet('colors.gray.100')};
`;
export const StyledButton = styled(Button) `
  ${marginTop(6)};
  &:hover {
    background-color: ${themeGet('colors.primary.700')};
  }
`;
export const Image = styled.img `
  display: block;
  max-width: 100%;
`;
