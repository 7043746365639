import { List } from '@pocketlaw/tetris';
import { useEsigning } from 'app/domains/esigning';
import { useFeatureFlag } from 'shared/domains/featureFlag';
import { AccessButton } from './AccessButton';
import { ApprovalButton } from './ApprovalButton';
import { AttachmentsButton } from './AttachmentsButton';
import { ESigningButton } from './ESigningButton';
import { ExternalSharingButton } from './ExternalSharingButton';
import { MetadataButton } from './MetadataButton';
import { NoticeDate } from './NoticeDate';
import { TasksButton } from './TasksButton';
export function SettingsList() {
    const { approvalWorkflow } = useEsigning();
    const { approvalWorkflowAvailable } = approvalWorkflow;
    const isApprovalEnabled = useFeatureFlag('feature_advanced_approval_workflow') && approvalWorkflowAvailable;
    return (<List $density="large">
      <AttachmentsButton />
      <NoticeDate />
      <TasksButton />
      {isApprovalEnabled && <ApprovalButton />}
      <ESigningButton />
      <AccessButton />
      <ExternalSharingButton />
      <MetadataButton />
    </List>);
}
