import { gql } from '@apollo/client';
export const templateNameQuery = gql `
  query templateName($id: Unstable__UUID!) {
    template: unstable__template(id: $id) {
      id

      composerTemplates {
        id
        composer {
          id
          metadata {
            name
            marketLocaleCode
          }
        }
      }
    }
  }
`;
