import { Dialog } from '@pocketlaw/tetris';
import { DraftPreview } from '../DraftPreview';
import { StyledDialogContent } from './styled';
export function DraftPreviewDialog(props) {
    const { composerId, onClose } = props;
    return (<Dialog size="xl" height="80dvh" onClose={onClose}>
      <StyledDialogContent disablePadding>
        <DraftPreview composerId={composerId} onClose={onClose}/>;
      </StyledDialogContent>
    </Dialog>);
}
