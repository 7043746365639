import { t } from '@lingui/macro';
import { usePollLastSignRequest } from './usePollLastSignRequest';
import { ESignPausedPollingState } from '../ESignPausedPollingState';
import { OverlayLoader } from '../components/OverlayLoader';
export function ESignPollingState(props) {
    const { title, message, overlay = false } = props;
    const { paused, resume } = usePollLastSignRequest();
    if (paused) {
        return (<ESignPausedPollingState onResume={resume} label={t({
                message: 'Connection paused',
                comment: 'Message when the document polling, during e-signing creation or cancellation, took too long so we paused it',
            })} message={t({
                message: 'It looks like it is taking longer than expected so we have paused the connection. Do you wish to resume?',
                comment: 'Descriptive message when the document polling, during e-signing creation or cancellation, took too long so we paused it',
            })} buttonLabel={t({
                message: 'Resume',
                comment: 'Button label when the document polling, during e-signing creation or cancellation, took too long so we paused it',
            })}/>);
    }
    return <OverlayLoader overlay={overlay} title={title} message={message}/>;
}
