import { useRef } from 'react';
import { Context } from '../Context';
export function UploadFileSystemDocumentProvider(props) {
    const { children } = props;
    const inputRef = useRef(null);
    const uploadDocument = () => {
        var _a;
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    return <Context.Provider value={{ uploadDocument, ref: inputRef }}>{children}</Context.Provider>;
}
