import { Trans } from '@lingui/macro';
import { ButtonGroup } from '@pocketlaw/tetris';
import { BulkChangeStatus } from './BulkChangeStatus';
import { BulkMove } from './BulkMove';
import { useTableContext } from '../SearchResultsTable/TableContext';
import { SelectedText } from './styled';
export function TableBulkActions() {
    const { selected } = useTableContext();
    const selectedCount = selected.length;
    return (<ButtonGroup>
      <SelectedText disabled={selectedCount === 0}>
        <Trans comment="Bulk action: Selected count">{selectedCount} selected</Trans>
      </SelectedText>

      <BulkMove />

      <BulkChangeStatus />
    </ButtonGroup>);
}
