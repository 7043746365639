import { useState } from 'react';
import { t } from '@lingui/macro';
import { Chip } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useHistory } from 'react-router-dom';
import { JobItem } from './JobItem';
import { StatusChip } from './StatusChip';
export function Job(props) {
    const { status, name, documentId } = props;
    const [hover, setHover] = useState(false);
    const history = useHistory();
    const nameFallback = t({
        message: 'Unknown document name',
        comment: 'Fallback for document name in auto tag job',
    });
    const hoverText = t({
        message: 'Go to document',
        comment: 'Auto tag notifications: Go to document link',
    });
    const gotoDocument = () => history.push(`/document/${documentId}`);
    return (<JobItem onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} icon={<Falcon icon="file"/>} name={name !== null && name !== void 0 ? name : nameFallback} actions={hover ? (<Chip appearance="secondary" icon={<Falcon icon="file"/>} secondary={hoverText} onClick={gotoDocument}/>) : (<StatusChip status={status}/>)}/>);
}
