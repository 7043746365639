import { Trans } from '@lingui/macro';
import { Button } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { usePlai } from 'app/domains/review';
export function Recover(props) {
    const { feedbackId } = props;
    const { recoverFeedback } = usePlai();
    const handleRecoverIssue = () => recoverFeedback(feedbackId);
    return (<Button size="small" appearance="secondary" onClick={handleRecoverIssue}>
      <Falcon icon="rotate-left"/>
      <Trans comment="Plai Review - Issue description - reopen button - label">Reopen</Trans>
    </Button>);
}
