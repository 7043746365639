import { useMutation } from '@apollo/client';
import { makeVariables } from './makeVariables';
import { createUserInviteMutation } from './mutation';
export function useCreateUserInvite(options) {
    const { onCreated } = options || {};
    const [mutate, state] = useMutation(createUserInviteMutation, {
        onCompleted(data) {
            var _a;
            onCreated((_a = data.invite) === null || _a === void 0 ? void 0 : _a.returning);
        },
    });
    const handleMutation = (values) => {
        const variables = makeVariables(values);
        return mutate({ variables: { objects: variables } });
    };
    return {
        mutate: handleMutation,
        state,
    };
}
