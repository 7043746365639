var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { extensionMap } from 'shared/domains/filesystem/utils/fileExtensions';
import * as Sentry from 'shared/domains/sentry';
export const getFileToUpload = (input) => __awaiter(void 0, void 0, void 0, function* () {
    const { url, name, mimeType } = input;
    if (!url) {
        const error = new Error('Entry file does not have a signed URL');
        Sentry.captureException(error);
        throw error;
    }
    return fetch(url)
        .then(response => response.blob())
        .then(blob => new File([blob], `${name}${extensionMap.get(mimeType)}`, {
        type: mimeType,
    }))
        .catch(Sentry.captureException);
});
