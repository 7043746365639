import { t } from '@lingui/macro';
import { MimeTypesByExtension } from './types';
export const extensionMap = new Map([
    [MimeTypesByExtension.Pdf, '.pdf'],
    [MimeTypesByExtension.Doc, '.doc'],
    [MimeTypesByExtension.Docx, '.docx'],
    [MimeTypesByExtension.Xlsx, '.xlsx'],
    [MimeTypesByExtension.Ppt, '.ppt'],
    [MimeTypesByExtension.Pptx, '.pptx'],
    [MimeTypesByExtension.Pages, '.pages'],
    [MimeTypesByExtension.Key, '.key'],
    [MimeTypesByExtension.Numbers, '.numbers'],
    [MimeTypesByExtension.Png, '.png'],
    [MimeTypesByExtension.Jpeg, '.jpeg'],
]);
export const getFiletypeName = () => ({
    [MimeTypesByExtension.Doc]: t({ message: 'DOC', comment: 'Name of .doc file' }),
    [MimeTypesByExtension.Docx]: t({ message: 'DOCX', comment: 'Name of .docx file' }),
    [MimeTypesByExtension.Jpeg]: t({ message: 'JPEG', comment: 'Name of .jpeg file' }),
    [MimeTypesByExtension.Key]: t({ message: 'KEY', comment: 'Name of .key file' }),
    [MimeTypesByExtension.Numbers]: t({ message: 'NUMBERS', comment: 'Name of .numbers file' }),
    [MimeTypesByExtension.Pages]: t({ message: 'PAGES', comment: 'Name of .pages file' }),
    [MimeTypesByExtension.Pdf]: t({ message: 'PDF', comment: 'Name of .pdf file' }),
    [MimeTypesByExtension.Png]: t({ message: 'PNG', comment: 'Name of .png file' }),
    [MimeTypesByExtension.Ppt]: t({ message: 'PPT', comment: 'Name of .ppt file' }),
    [MimeTypesByExtension.Pptx]: t({ message: 'PPTX', comment: 'Name of .pptx file' }),
    [MimeTypesByExtension.Xlsx]: t({ message: 'XLSX', comment: 'Name of .xlsx file' }),
});
export const mimeTypeMap = new Map([...extensionMap.entries()].map(([key, value]) => [value, key]));
