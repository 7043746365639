import { useMutation } from '@apollo/client';
import { ROOT } from 'app/domains/filesystem';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { CreateFileSystemFolderDocument } from './mutation.gql';
export function useCreateFolder() {
    var _a;
    const account = useAccount();
    const companyId = (_a = account.data) === null || _a === void 0 ? void 0 : _a.companyId;
    const [mutate, state] = useMutation(CreateFileSystemFolderDocument);
    const handler = (data) => {
        const { name, parentId } = data;
        return mutate({
            variables: {
                name,
                companyId,
                parentId: parentId || ROOT,
                userAcls: [],
                teamAcls: [],
            },
            update(cache, { data: createData }) {
                const { folder } = createData || {};
                if (!folder) {
                    return;
                }
                cache.modify({
                    fields: {
                        fileSystemEntries(existing = [], { toReference }) {
                            return [...existing, toReference(folder, true)];
                        },
                    },
                });
            },
        });
    };
    return [handler, state];
}
