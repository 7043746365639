import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${themeGet('spacing.sm')};
  width: 100%;
  padding: ${themeGet('spacing.md')};
  border-radius: ${themeGet('borderRadius.md')};
  border: solid ${pxToRem(1)} ${themeGet('colors.gray.100')};
`;
export const ToggleButtonContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${themeGet('spacing.sm')};
  border-radius: ${themeGet('borderRadius.md')};
  transition: background-color 150ms ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: ${themeGet('colors.gray.50')};
  }
`;
export const FileList = styled.ul `
  display: ${props => (props.$show ? 'flex' : 'none')};
  flex-direction: column;
  gap: ${themeGet('spacing.sm')};
`;
export const File = styled.li `
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${themeGet('spacing.sm')};
`;
