import { gql } from '@apollo/client';
export const userRoleQuery = gql `
  query GetUserRole($id: UUID!) {
    companyUser(id: $id) {
      id
      role
      canManageMember: can_manage_member
    }
  }
`;
