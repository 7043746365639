import { pxToRem, themeGet, unit } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const TableFilterContainer = styled.div `
  z-index: 1;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  gap: ${unit('lg')};
  padding: ${unit('xl')} ${unit('2xl')};
  border: ${pxToRem(1)} solid ${themeGet('colors.border.100')};
  background-color: ${themeGet('colors.background.50')};
`;
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${unit('xl')};
`;
