import { unit } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Wrapper = styled.div `
  padding: ${unit('xl')} 0;
`;
export const LoadingContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const TableHeadCheckboxContainer = styled.div `
  padding-left: ${unit('sm')};
`;
