import { Pagination } from 'shared/domains/common-ui';
import { useFileBrowserContext } from './FileBrowserProvider';
import { PaginationContainer } from './styled';
/**
 * Displays pagination for the file browser.
 */
export function FileBrowserPagination() {
    const { limit, count, offset, setOffset } = useFileBrowserContext();
    return (<PaginationContainer>
      <Pagination limit={limit} count={count} offset={offset} setOffset={setOffset}/>
    </PaginationContainer>);
}
