import { t } from '@lingui/macro';
import { useActiveWorkspace } from '../FileSystemProvider';
export function useGetWorkspaceBreadcrumbs() {
    const { fileSystemData } = useActiveWorkspace();
    const { folder, ancestors } = fileSystemData || {};
    const baseName = t({
        message: 'Pocketlaw',
        comment: 'Base breadcrumb name in filesystem navigates to /dashboard',
    });
    const rootName = t({ message: 'Files', comment: 'Root breadcrumb name in filesystem' });
    const breadcrumbs = (ancestors || [])
        .filter(Boolean)
        .sort((a, b) => ((a === null || a === void 0 ? void 0 : a.depth) || 0) - ((b === null || b === void 0 ? void 0 : b.depth) || 0))
        .map(crumb => ({
        id: crumb.id,
        name: crumb.name,
        link: `/files/${crumb.id}`,
    }));
    if (folder) {
        breadcrumbs.push({
            id: folder.id,
            name: folder.name,
        });
    }
    const base = {
        id: 'base',
        name: baseName,
        link: '/dashboard',
    };
    const root = {
        id: 'root',
        name: rootName,
        link: '/files',
    };
    return [base, root, ...breadcrumbs];
}
