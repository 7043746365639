import { Fragment } from 'react';
import { t } from '@lingui/macro';
import { Loader, Alert, MenuBody, Avatar, AvatarLetter } from '@pocketlaw/tetris';
import { useFormikContext } from 'formik';
import { SearchableMenuMultiSelectList, SearchableMenuProvider, SearchableMenuSearchHeader, } from 'shared/domains/common-ui';
import { useGetUsers } from './useGetUsers';
import { SubmitButtonForSearchableLists } from '../SubmitButtonForSearchableLists';
export function UserField() {
    const { values, setFieldValue } = useFormikContext();
    const { users, loading, error } = useGetUsers();
    const handleSelectUser = (value) => {
        const added = values.value.includes(value);
        let newValues;
        if (added) {
            newValues = values.value.filter(existingValue => existingValue !== value);
        }
        else {
            newValues = [...values.value, value];
        }
        setFieldValue('value', newValues);
    };
    if (loading) {
        return <Loader />;
    }
    if (error) {
        return (<Alert appearance="danger" message={t({
                message: 'Error loading the users',
                comment: 'Metadata: Error message displayed when we could not load the company users',
            })}/>);
    }
    return (<SearchableMenuProvider items={users} keys={['name']}>
      <SearchableMenuSearchHeader />
      <MenuBody>
        <SearchableMenuMultiSelectList onSelect={item => handleSelectUser(item.id)} renderItem={({ name }) => (<Fragment>
              <Avatar size="sm">
                <AvatarLetter>{name.slice(0, 1)}</AvatarLetter>
              </Avatar>
              {name}
            </Fragment>)} selected={values.value}/>
      </MenuBody>
      <SubmitButtonForSearchableLists />
    </SearchableMenuProvider>);
}
