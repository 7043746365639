import { gql } from '@apollo/client';
export const metadataUpdateCategory = gql `
  mutation MetadataUpdateCategory($documentId: ID!, $categoryId: UUID!) {
    updateFileSystemDocument(id: $documentId, input: { categoryId: $categoryId }) {
      id
      category {
        id
        metadata {
          id
          title
          marketLocaleCode: market_locale_code
        }
      }
    }
  }
`;
