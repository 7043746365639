import { Component } from 'react';
import T from 'prop-types';

import { AppError } from 'shared/domains/errors';
import * as Sentry from 'shared/domains/sentry';

export class ErrorBoundary extends Component {
  constructor() {
    super();

    this.state = {
      errorEventId: null,
      fatalError: false,
    };
  }

  componentDidCatch(error, info) {
    // In development mode React will rethrow errors caught within an error boundary.
    // This will result in errors being reported twice to Sentry with the above setup,
    // but this won’t occur in your production build.
    Sentry.withScope(scope => {
      scope.setExtras(info);
      const errorEventId = Sentry.captureException(error);
      this.setState({ fatalError: true, errorEventId });
    });
  }

  render() {
    const { fatalError, errorEventId } = this.state;

    if (fatalError) {
      return <AppError id={errorEventId} />;
    }

    const { children } = this.props;

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: T.node,
};

ErrorBoundary.defaultProps = {
  children: undefined,
};
