var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Chip, Loader, ChipAction, Menu } from '@pocketlaw/tetris';
import { Container } from './styled';
export const ChipDropdown = forwardRef((props, ref) => {
    const { label, secondary, dropdownContent, appearance = 'secondary', size = 'large', loading = false, action, initiallyOpen = false } = props, rest = __rest(props, ["label", "secondary", "dropdownContent", "appearance", "size", "loading", "action", "initiallyOpen"]);
    const [open, setOpen] = useState(initiallyOpen);
    const toggleDropdown = () => setOpen(!open);
    useImperativeHandle(ref, () => ({ toggleDropdown }));
    return (<Menu open={open} onClose={() => setOpen(false)} options={{ placement: 'bottom-start' }} target={<Chip appearance={appearance} size={size} label={label} secondary={secondary} onClick={() => setOpen(!open)} action={loading ? (<ChipAction type="button">
                  <Loader />
                </ChipAction>) : (action)} {...rest}/>}>
        <Container>{dropdownContent}</Container>
      </Menu>);
});
ChipDropdown.displayName = 'ChipDropdown';
