import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${themeGet('spacing.md')};
  padding: ${themeGet('spacing.lg')};
  border-radius: ${themeGet('borderRadius.md')};
  border: solid ${pxToRem(1)} ${themeGet('colors.border.100')};
  box-shadow: ${themeGet('dropShadow.100')};
`;
