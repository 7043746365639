import { t } from '@lingui/macro';
import { EmptyState } from '@pocketlaw/tetris';
import { Container } from './styled';
export function EmptyStateSearch() {
    return (<Container>
      <EmptyState title={t({
            comment: 'Universal search - Empty search title',
            message: 'Search for anything',
        })} description={t({
            comment: 'Universal search - Empty search description',
            message: 'Then navigate results with arrow keys and hit Enter to select.',
        })} icon={undefined}/>
    </Container>);
}
