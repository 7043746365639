import { gql } from '@apollo/client';
const setAsRead = gql `
  mutation SetMessagesAsRead($ids: [UUID!]!) {
    updateExternalSharingMessages(input: { isRead: true }, ids: $ids) {
      id
      isRead
      message
    }
  }
`;
export default setAsRead;
