import { t } from '@lingui/macro';
import { AvatarWithLabel } from '@pocketlaw/tetris';
import { useAssistant } from 'app/domains/assistant';
import { getIconFromContext, getTitleFromContext } from './utils';
import { Container } from './styled';
export function Disclaimer() {
    var _a, _b;
    const { thread } = useAssistant();
    // TODO: Fetch the type from DocumentChat instead
    const [context] = ((_b = (_a = thread[0]) === null || _a === void 0 ? void 0 : _a.type) === null || _b === void 0 ? void 0 : _b.split('_').slice(0, -1)) || [];
    const title = getTitleFromContext(context);
    const icon = getIconFromContext(context);
    return (<Container>
      <AvatarWithLabel appearance="assistant" alignment="vertical" title={title} subtitle={t({
            comment: 'Document assistant - chat thread - intro disclaimer - subtitle',
            message: 'Note that the answers provided are not verified by Pocketlaw and may not be legally accurate. Please see our terms for more info.',
        })}>
        {icon}
      </AvatarWithLabel>
    </Container>);
}
