import { gql } from '@apollo/client';
export const QUERY = gql `
  query GetCompanyFeatures($companyId: UUID!) {
    company(id: $companyId) {
      id
      esigningFeatures {
        id
      }
      features {
        id
      }
      assistantFeatures {
        id
      }
      userFeatures {
        id
      }
      webApiFeatures {
        id
      }
    }
  }
`;
