import { useMutation } from '@apollo/client';
import { useLastSignRequest } from 'app/domains/esigning/hooks/useLastSignRequest';
import { sendReminderToSignatoriesMutation } from './mutation';
export const useSendReminderToSignatories = () => {
    const { lastSignRequest } = useLastSignRequest();
    const { id } = lastSignRequest || {};
    return useMutation(sendReminderToSignatoriesMutation, {
        variables: {
            id: id || '',
        },
    });
};
