const sessionDataHelper = (key) => {
    function set(data) {
        if (data === undefined || data === null) {
            return;
        }
        sessionStorage.setItem(key, JSON.stringify(data));
    }
    function get() {
        const data = sessionStorage.getItem(key);
        if (data !== null) {
            return JSON.parse(data);
        }
        return data;
    }
    function clear() {
        sessionStorage.removeItem(key);
    }
    return { set, get, clear };
};
export const signupDataHelper = sessionDataHelper('signupData');
