import { Fragment, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Button, EmptyState } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { ESignSignatoryFormDialog } from 'app/domains/esigning/components/ESignSignatoryFormDialog';
import { useEsigningSignatoriesFormField } from 'app/domains/esigning/components/EsigningForm';
export function EmptySigningParties() {
    const { signatories, addSignatory } = useEsigningSignatoriesFormField();
    const [showAddPartyModal, setShowAddPartyModal] = useState(false);
    const showModal = () => setShowAddPartyModal(true);
    const hideModal = () => setShowAddPartyModal(false);
    return (<Fragment>
      {showAddPartyModal && (<ESignSignatoryFormDialog type="Signatory" allSignatories={signatories} onClose={hideModal} onSubmit={addSignatory}/>)}
      <EmptyState withAvatar icon={<Falcon icon="user"/>} title={t({
            comment: 'No signing parties added empty state - title',
            message: 'Add a signing party',
        })} description={t({
            comment: 'No signing parties added empty state - description',
            message: 'All your signing parties will be listed here',
        })} actions={<Button appearance="secondary" size="small" onClick={showModal}>
            <Falcon icon="plus"/>
            <Trans comment="No signing parties added empty state - call to action label">
              Add signing party
            </Trans>
          </Button>}/>
    </Fragment>);
}
