import { useState } from 'react';
import { Trans } from '@lingui/macro';
import { Box, Button, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { SelectPlaybook } from './SelectPlaybook';
import { StyledList, StyledListItem } from './styled';
export function NoReviewYetState(props) {
    const { listTexts, onStartReview } = props;
    const [selectedPlaybookId, setSelectedPlaybookId] = useState('');
    const handleStartReview = () => {
        if (selectedPlaybookId) {
            onStartReview(selectedPlaybookId);
        }
    };
    return (<Box flexDirection="column" gap="xl">
      <Box flexDirection="column" gap="lg">
        <Typography $appearance="300" $fontSize="large" $fontWeight="semibold">
          <Trans comment="Document contract review - Review not started - Title">
            Automatic AI review
          </Trans>
        </Typography>
        <Typography>
          <Trans comment="Document contract review - Review not started - Description">
            Get instant feedback and input on your contracts with Pocketlaw automated contract
            review.
          </Trans>
        </Typography>
        <StyledList>
          {listTexts.map(text => (<StyledListItem key={text}>
              <Typography>{text}</Typography>
            </StyledListItem>))}
        </StyledList>
      </Box>
      <SelectPlaybook selectedPlaybookId={selectedPlaybookId} onSelectPlaybook={setSelectedPlaybookId}/>
      <Button $stretch appearance="primary" disabled={!selectedPlaybookId} onClick={handleStartReview}>
        <Falcon icon="bolt"/>
        <Trans comment="Document contract review - Review not started - Start review button label">
          Start review
        </Trans>
      </Button>
    </Box>);
}
