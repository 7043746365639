import { t } from '@lingui/macro';
import { Box, Typography } from '@pocketlaw/tetris';
import { useComposerData } from '../../../ComposerDataProvider';
import { FilterChip } from '../FilterChip';
export function Categories() {
    const { categories, selectedCategoryIds, addCategoryId, removeCategoryId } = useComposerData();
    const title = t({ message: 'Categories', comment: 'Select template dialog - Categories label' });
    return (<Box flexDirection="column" gap="md">
      <Typography $fontSize="small" $appearance="100" $fontWeight="bold">
        {title}
      </Typography>
      <Box gap="md" flexDirection="column">
        {categories.map(({ id, metadata }) => (<FilterChip key={id} selected={selectedCategoryIds.includes(id)} onAdd={() => addCategoryId(id)} onRemove={() => removeCategoryId(id)} label={metadata[0].title}/>))}
      </Box>
    </Box>);
}
