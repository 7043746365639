import { Dialog } from '@pocketlaw/tetris';
import { SearchPopupResults } from './SearchPopupResults';
import { EmptyStateSearch } from './SearchResultStates/EmptyStateSearch';
import { LoadingSkeleton } from './SearchResultStates/LoadingSkeleton';
import { NoResults } from './SearchResultStates/NoResults';
import { UniversalSearchInput } from './UniversalSearchInput';
import { useUniversalSearch } from './useUniversalSearch';
import { StyledCardContent, StyledDialogHeader, Wrapper } from './styled';
export function SearchPopup(props) {
    var _a;
    const { onClose, open } = props;
    const { loading, data, searchText } = useUniversalSearch();
    const { results = [] } = (_a = data === null || data === void 0 ? void 0 : data.search) !== null && _a !== void 0 ? _a : {};
    const hasResults = !loading && Boolean(results.length);
    if (!open) {
        return null;
    }
    return (<Dialog size="lg" onClose={onClose}>
      <StyledDialogHeader>
        <UniversalSearchInput />
      </StyledDialogHeader>

      <Wrapper $hasResults={hasResults}>
        <StyledCardContent>
          {loading && <LoadingSkeleton />}

          {!loading && hasResults && Boolean(searchText.length) && <SearchPopupResults />}

          {!loading && !hasResults && Boolean(searchText.length) && <NoResults />}

          {!loading && !searchText && <EmptyStateSearch />}
        </StyledCardContent>
      </Wrapper>
    </Dialog>);
}
