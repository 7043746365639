export function sortKeyPath(sortId) {
    switch (sortId) {
        case 'name':
            return ['name'];
        case 'category':
            return ['category', 'title'];
        case 'language':
            return ['marketLocale'];
        case 'jurisdiction':
            return ['jurisdiction'];
        default:
            return null;
    }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getObjectPath(path, obj) {
    if (path === null || path === void 0 ? void 0 : path.length) {
        return (path.reduce((acc, key) => {
            if (acc) {
                return acc === null || acc === void 0 ? void 0 : acc[key];
            }
            return undefined;
        }, obj) || '');
    }
    return '';
}
