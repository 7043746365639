import { Fragment } from 'react';
import { TaskStatusEnumEnum } from 'shared/domains/apollo/generated/types';
import { CompletedToggle } from './CompletedToggle';
import { DueDate } from './DueDate';
import { SortSelector } from './SortSelector';
import { TaskDropdown } from './TaskDropdown';
import { useToggleCompleteState } from '../../hooks/useToggleCompletedState';
import { StatusChip } from '../StatusChip';
import { Item, List, ItemContent, ContentRow, Title } from './styled';
function TaskList(props) {
    const { tasks, onClick, hideSortSelector } = props;
    const toggleCompletedState = useToggleCompleteState();
    return (<Fragment>
      {!hideSortSelector && <SortSelector />}

      <List data-testid="task-widget-list">
        {tasks.map(({ id, isCompleted, status, title, dueDate }) => {
            const completed = isCompleted || status === TaskStatusEnumEnum.Completed;
            return (<Item key={id}>
              <CompletedToggle completed={completed} onClick={() => toggleCompletedState.mutate({
                    id,
                    isCompleted,
                    status,
                })} disabled={toggleCompletedState.loading}/>
              <ItemContent onClick={() => onClick(id)}>
                <ContentRow>
                  <Title $completed={completed}>{title}</Title>
                </ContentRow>
                <ContentRow>
                  <DueDate date={dueDate}/>
                </ContentRow>
              </ItemContent>
              <StatusChip dueDate={dueDate} id={id} status={status}/>
              <TaskDropdown completed={completed} taskId={id}/>
            </Item>);
        })}
      </List>
    </Fragment>);
}
export { TaskList };
