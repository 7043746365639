import { defineMessage } from '@lingui/macro';
export default {
    AB: defineMessage({ id: 'countries.ab', message: `Abkhazia` }),
    AC: defineMessage({ id: 'countries.ac', message: `Ascension Island` }),
    AD: defineMessage({ id: 'countries.ad', message: `Andorra` }),
    AE: defineMessage({ id: 'countries.ae', message: `United Arab Emirates` }),
    AF: defineMessage({ id: 'countries.af', message: `Afghanistan` }),
    AG: defineMessage({ id: 'countries.ag', message: `Antigua and Barbuda` }),
    AI: defineMessage({ id: 'countries.ai', message: `Anguilla` }),
    AL: defineMessage({ id: 'countries.al', message: `Albania` }),
    AM: defineMessage({ id: 'countries.am', message: `Armenia` }),
    AO: defineMessage({ id: 'countries.ao', message: `Angola` }),
    AQ: defineMessage({ id: 'countries.aq', message: `Antarctica` }),
    AR: defineMessage({ id: 'countries.ar', message: `Argentina` }),
    AS: defineMessage({ id: 'countries.as', message: `American Samoa` }),
    AT: defineMessage({ id: 'countries.at', message: `Austria` }),
    AU: defineMessage({ id: 'countries.au', message: `Australia` }),
    AW: defineMessage({ id: 'countries.aw', message: `Aruba` }),
    AX: defineMessage({ id: 'countries.ax', message: `Åland Islands` }),
    AZ: defineMessage({ id: 'countries.az', message: `Azerbaijan` }),
    BA: defineMessage({ id: 'countries.ba', message: `Bosnia and Herzegovina` }),
    BB: defineMessage({ id: 'countries.bb', message: `Barbados` }),
    BD: defineMessage({ id: 'countries.bd', message: `Bangladesh` }),
    BE: defineMessage({ id: 'countries.be', message: `Belgium` }),
    BF: defineMessage({ id: 'countries.bf', message: `Burkina Faso` }),
    BG: defineMessage({ id: 'countries.bg', message: `Bulgaria` }),
    BH: defineMessage({ id: 'countries.bh', message: `Bahrain` }),
    BI: defineMessage({ id: 'countries.bi', message: `Burundi` }),
    BJ: defineMessage({ id: 'countries.bj', message: `Benin` }),
    BL: defineMessage({ id: 'countries.bl', message: `Saint Barthélemy` }),
    BM: defineMessage({ id: 'countries.bm', message: `Bermuda` }),
    BN: defineMessage({ id: 'countries.bn', message: `Brunei Darussalam` }),
    BO: defineMessage({ id: 'countries.bo', message: `Bolivia` }),
    BQ: defineMessage({ id: 'countries.bq', message: `Bonaire, Sint Eustatius and Saba` }),
    BR: defineMessage({ id: 'countries.br', message: `Brazil` }),
    BS: defineMessage({ id: 'countries.bs', message: `Bahamas` }),
    BT: defineMessage({ id: 'countries.bt', message: `Bhutan` }),
    BV: defineMessage({ id: 'countries.bv', message: `Bouvet Island` }),
    BW: defineMessage({ id: 'countries.bw', message: `Botswana` }),
    BY: defineMessage({ id: 'countries.by', message: `Belarus` }),
    BZ: defineMessage({ id: 'countries.bz', message: `Belize` }),
    CA: defineMessage({ id: 'countries.ca', message: `Canada` }),
    CC: defineMessage({ id: 'countries.cc', message: `Cocos (Keeling) Islands` }),
    CD: defineMessage({ id: 'countries.cd', message: `Congo, Democratic Republic of the` }),
    CF: defineMessage({ id: 'countries.cf', message: `Central African Republic` }),
    CG: defineMessage({ id: 'countries.cg', message: `Congo` }),
    CH: defineMessage({ id: 'countries.ch', message: `Switzerland` }),
    CI: defineMessage({ id: 'countries.ci', message: `Cote d'Ivoire` }),
    CK: defineMessage({ id: 'countries.ck', message: `Cook Islands` }),
    CL: defineMessage({ id: 'countries.cl', message: `Chile` }),
    CM: defineMessage({ id: 'countries.cm', message: `Cameroon` }),
    CN: defineMessage({ id: 'countries.cn', message: `China` }),
    CO: defineMessage({ id: 'countries.co', message: `Colombia` }),
    CR: defineMessage({ id: 'countries.cr', message: `Costa Rica` }),
    CU: defineMessage({ id: 'countries.cu', message: `Cuba` }),
    CV: defineMessage({ id: 'countries.cv', message: `Cape Verde` }),
    CW: defineMessage({ id: 'countries.cw', message: `Curaçao` }),
    CX: defineMessage({ id: 'countries.cx', message: `Christmas Island` }),
    CY: defineMessage({ id: 'countries.cy', message: `Cyprus` }),
    CZ: defineMessage({ id: 'countries.cz', message: `Czech Republic` }),
    DE: defineMessage({ id: 'countries.de', message: `Germany` }),
    DJ: defineMessage({ id: 'countries.dj', message: `Djibouti` }),
    DK: defineMessage({ id: 'countries.dk', message: `Denmark` }),
    DM: defineMessage({ id: 'countries.dm', message: `Dominica` }),
    DO: defineMessage({ id: 'countries.do', message: `Dominican Republic` }),
    DZ: defineMessage({ id: 'countries.dz', message: `Algeria` }),
    EC: defineMessage({ id: 'countries.ec', message: `Ecuador` }),
    EE: defineMessage({ id: 'countries.ee', message: `Estonia` }),
    EG: defineMessage({ id: 'countries.eg', message: `Egypt` }),
    EH: defineMessage({ id: 'countries.eh', message: `Western Sahara` }),
    ER: defineMessage({ id: 'countries.er', message: `Eritrea` }),
    ES: defineMessage({ id: 'countries.es', message: `Spain` }),
    ET: defineMessage({ id: 'countries.et', message: `Ethiopia` }),
    FI: defineMessage({ id: 'countries.fi', message: `Finland` }),
    FJ: defineMessage({ id: 'countries.fj', message: `Fiji` }),
    FK: defineMessage({ id: 'countries.fk', message: `Falkland Islands` }),
    FM: defineMessage({ id: 'countries.fm', message: `Federated States of Micronesia` }),
    FO: defineMessage({ id: 'countries.fo', message: `Faroe Islands` }),
    FR: defineMessage({ id: 'countries.fr', message: `France` }),
    GA: defineMessage({ id: 'countries.ga', message: `Gabon` }),
    GB: defineMessage({ id: 'countries.gb', message: `United Kingdom` }),
    GD: defineMessage({ id: 'countries.gd', message: `Grenada` }),
    GE: defineMessage({ id: 'countries.ge', message: `Georgia` }),
    GF: defineMessage({ id: 'countries.gf', message: `French Guiana` }),
    GG: defineMessage({ id: 'countries.gg', message: `Guernsey` }),
    GH: defineMessage({ id: 'countries.gh', message: `Ghana` }),
    GI: defineMessage({ id: 'countries.gi', message: `Gibraltar` }),
    GL: defineMessage({ id: 'countries.gl', message: `Greenland` }),
    GM: defineMessage({ id: 'countries.gm', message: `Gambia` }),
    GN: defineMessage({ id: 'countries.gn', message: `Guinea` }),
    GP: defineMessage({ id: 'countries.gp', message: `Guadeloupe` }),
    GQ: defineMessage({ id: 'countries.gq', message: `Equatorial Guinea` }),
    GR: defineMessage({ id: 'countries.gr', message: `Greece` }),
    GS: defineMessage({
        id: 'countries.gs',
        message: `South Georgia and the South Sandwich Islands`,
    }),
    GT: defineMessage({ id: 'countries.gt', message: `Guatemala` }),
    GU: defineMessage({ id: 'countries.gu', message: `Guam` }),
    GW: defineMessage({ id: 'countries.gw', message: `Guinea-Bissau` }),
    GY: defineMessage({ id: 'countries.gy', message: `Guyana` }),
    HK: defineMessage({ id: 'countries.hk', message: `Hong Kong` }),
    HM: defineMessage({ id: 'countries.hm', message: `Heard Island and McDonald Islands` }),
    HN: defineMessage({ id: 'countries.hn', message: `Honduras` }),
    HR: defineMessage({ id: 'countries.hr', message: `Croatia` }),
    HT: defineMessage({ id: 'countries.ht', message: `Haiti` }),
    HU: defineMessage({ id: 'countries.hu', message: `Hungary` }),
    ID: defineMessage({ id: 'countries.id', message: `Indonesia` }),
    IE: defineMessage({ id: 'countries.ie', message: `Ireland` }),
    IL: defineMessage({ id: 'countries.il', message: `Israel` }),
    IM: defineMessage({ id: 'countries.im', message: `Isle of Man` }),
    IN: defineMessage({ id: 'countries.in', message: `India` }),
    IO: defineMessage({ id: 'countries.io', message: `British Indian Ocean Territory` }),
    IQ: defineMessage({ id: 'countries.iq', message: `Iraq` }),
    IR: defineMessage({ id: 'countries.ir', message: `Iran` }),
    IS: defineMessage({ id: 'countries.is', message: `Iceland` }),
    IT: defineMessage({ id: 'countries.it', message: `Italy` }),
    JE: defineMessage({ id: 'countries.je', message: `Jersey` }),
    JM: defineMessage({ id: 'countries.jm', message: `Jamaica` }),
    JO: defineMessage({ id: 'countries.jo', message: `Jordan` }),
    JP: defineMessage({ id: 'countries.jp', message: `Japan` }),
    KE: defineMessage({ id: 'countries.ke', message: `Kenya` }),
    KG: defineMessage({ id: 'countries.kg', message: `Kyrgyzstan` }),
    KH: defineMessage({ id: 'countries.kh', message: `Cambodia` }),
    KI: defineMessage({ id: 'countries.ki', message: `Kiribati` }),
    KM: defineMessage({ id: 'countries.km', message: `Comoros` }),
    KN: defineMessage({ id: 'countries.kn', message: `Saint Kitts and Nevis` }),
    KP: defineMessage({ id: 'countries.kp', message: `North Korea` }),
    KR: defineMessage({ id: 'countries.kr', message: `South Korea` }),
    KW: defineMessage({ id: 'countries.kw', message: `Kuwait` }),
    KY: defineMessage({ id: 'countries.ky', message: `Cayman Islands` }),
    KZ: defineMessage({ id: 'countries.kz', message: `Kazakhstan` }),
    LA: defineMessage({ id: 'countries.la', message: `Laos` }),
    LB: defineMessage({ id: 'countries.lb', message: `Lebanon` }),
    LC: defineMessage({ id: 'countries.lc', message: `Saint Lucia` }),
    LI: defineMessage({ id: 'countries.li', message: `Liechtenstein` }),
    LK: defineMessage({ id: 'countries.lk', message: `Sri Lanka` }),
    LR: defineMessage({ id: 'countries.lr', message: `Liberia` }),
    LS: defineMessage({ id: 'countries.ls', message: `Lesotho` }),
    LT: defineMessage({ id: 'countries.lt', message: `Lithuania` }),
    LU: defineMessage({ id: 'countries.lu', message: `Luxembourg` }),
    LV: defineMessage({ id: 'countries.lv', message: `Latvia` }),
    LY: defineMessage({ id: 'countries.ly', message: `Libya` }),
    MA: defineMessage({ id: 'countries.ma', message: `Morocco` }),
    MC: defineMessage({ id: 'countries.mc', message: `Monaco` }),
    MD: defineMessage({ id: 'countries.md', message: `Moldova` }),
    ME: defineMessage({ id: 'countries.me', message: `Montenegro` }),
    MF: defineMessage({ id: 'countries.mf', message: `Saint Martin (French Part)` }),
    MG: defineMessage({ id: 'countries.mg', message: `Madagascar` }),
    MH: defineMessage({ id: 'countries.mh', message: `Marshall Islands` }),
    MK: defineMessage({ id: 'countries.mk', message: `North Macedonia` }),
    ML: defineMessage({ id: 'countries.ml', message: `Mali` }),
    MM: defineMessage({ id: 'countries.mm', message: `Burma` }),
    MN: defineMessage({ id: 'countries.mn', message: `Mongolia` }),
    MO: defineMessage({ id: 'countries.mo', message: `Macao` }),
    MP: defineMessage({ id: 'countries.mp', message: `Northern Mariana Islands` }),
    MQ: defineMessage({ id: 'countries.mq', message: `Martinique` }),
    MR: defineMessage({ id: 'countries.mr', message: `Mauritania` }),
    MS: defineMessage({ id: 'countries.ms', message: `Montserrat` }),
    MT: defineMessage({ id: 'countries.mt', message: `Malta` }),
    MU: defineMessage({ id: 'countries.mu', message: `Mauritius` }),
    MV: defineMessage({ id: 'countries.mv', message: `Maldives` }),
    MW: defineMessage({ id: 'countries.mw', message: `Malawi` }),
    MX: defineMessage({ id: 'countries.mx', message: `Mexico` }),
    MY: defineMessage({ id: 'countries.my', message: `Malaysia` }),
    MZ: defineMessage({ id: 'countries.mz', message: `Mozambique` }),
    NA: defineMessage({ id: 'countries.na', message: `Namibia` }),
    NC: defineMessage({ id: 'countries.nc', message: `New Caledonia` }),
    NE: defineMessage({ id: 'countries.ne', message: `Niger` }),
    NF: defineMessage({ id: 'countries.nf', message: `Norfolk Island` }),
    NG: defineMessage({ id: 'countries.ng', message: `Nigeria` }),
    NI: defineMessage({ id: 'countries.ni', message: `Nicaragua` }),
    NL: defineMessage({ id: 'countries.nl', message: `Netherlands` }),
    NO: defineMessage({ id: 'countries.no', message: `Norway` }),
    NP: defineMessage({ id: 'countries.np', message: `Nepal` }),
    NR: defineMessage({ id: 'countries.nr', message: `Nauru` }),
    NU: defineMessage({ id: 'countries.nu', message: `Niue` }),
    NZ: defineMessage({ id: 'countries.nz', message: `New Zealand` }),
    OM: defineMessage({ id: 'countries.om', message: `Oman` }),
    OS: defineMessage({ id: 'countries.os', message: `South Ossetia` }),
    PA: defineMessage({ id: 'countries.pa', message: `Panama` }),
    PE: defineMessage({ id: 'countries.pe', message: `Peru` }),
    PF: defineMessage({ id: 'countries.pf', message: `French Polynesia` }),
    PG: defineMessage({ id: 'countries.pg', message: `Papua New Guinea` }),
    PH: defineMessage({ id: 'countries.ph', message: `Philippines` }),
    PK: defineMessage({ id: 'countries.pk', message: `Pakistan` }),
    PL: defineMessage({ id: 'countries.pl', message: `Poland` }),
    PM: defineMessage({ id: 'countries.pm', message: `Saint Pierre and Miquelon` }),
    PN: defineMessage({ id: 'countries.pn', message: `Pitcairn` }),
    PR: defineMessage({ id: 'countries.pr', message: `Puerto Rico` }),
    PS: defineMessage({ id: 'countries.ps', message: `Palestine` }),
    PT: defineMessage({ id: 'countries.pt', message: `Portugal` }),
    PW: defineMessage({ id: 'countries.pw', message: `Palau` }),
    PY: defineMessage({ id: 'countries.py', message: `Paraguay` }),
    QA: defineMessage({ id: 'countries.qa', message: `Qatar` }),
    RE: defineMessage({ id: 'countries.re', message: `Reunion` }),
    RO: defineMessage({ id: 'countries.ro', message: `Romania` }),
    RS: defineMessage({ id: 'countries.rs', message: `Serbia` }),
    RU: defineMessage({ id: 'countries.ru', message: `Russia` }),
    RW: defineMessage({ id: 'countries.rw', message: `Rwanda` }),
    SA: defineMessage({ id: 'countries.sa', message: `Saudi Arabia` }),
    SB: defineMessage({ id: 'countries.sb', message: `Solomon Islands` }),
    SC: defineMessage({ id: 'countries.sc', message: `Seychelles` }),
    SD: defineMessage({ id: 'countries.sd', message: `Sudan` }),
    SE: defineMessage({ id: 'countries.se', message: `Sweden` }),
    SG: defineMessage({ id: 'countries.sg', message: `Singapore` }),
    SH: defineMessage({ id: 'countries.sh', message: `Saint Helena` }),
    SI: defineMessage({ id: 'countries.si', message: `Slovenia` }),
    SJ: defineMessage({ id: 'countries.sj', message: `Svalbard and Jan Mayen` }),
    SK: defineMessage({ id: 'countries.sk', message: `Slovakia` }),
    SL: defineMessage({ id: 'countries.sl', message: `Sierra Leone` }),
    SM: defineMessage({ id: 'countries.sm', message: `San Marino` }),
    SN: defineMessage({ id: 'countries.sn', message: `Senegal` }),
    SO: defineMessage({ id: 'countries.so', message: `Somalia` }),
    SR: defineMessage({ id: 'countries.sr', message: `Suriname` }),
    SS: defineMessage({ id: 'countries.ss', message: `South Sudan` }),
    ST: defineMessage({ id: 'countries.st', message: `Sao Tome and Principe` }),
    SV: defineMessage({ id: 'countries.sv', message: `El Salvador` }),
    SX: defineMessage({ id: 'countries.sx', message: `Sint Maarten` }),
    SY: defineMessage({ id: 'countries.sy', message: `Syria` }),
    SZ: defineMessage({ id: 'countries.sz', message: `Swaziland` }),
    TA: defineMessage({ id: 'countries.ta', message: `Tristan da Cunha` }),
    TC: defineMessage({ id: 'countries.tc', message: `Turks and Caicos Islands` }),
    TD: defineMessage({ id: 'countries.td', message: `Chad` }),
    TF: defineMessage({ id: 'countries.tf', message: `French Southern Territories` }),
    TG: defineMessage({ id: 'countries.tg', message: `Togo` }),
    TH: defineMessage({ id: 'countries.th', message: `Thailand` }),
    TJ: defineMessage({ id: 'countries.tj', message: `Tajikistan` }),
    TK: defineMessage({ id: 'countries.tk', message: `Tokelau` }),
    TL: defineMessage({ id: 'countries.tl', message: `Timor-Leste` }),
    TM: defineMessage({ id: 'countries.tm', message: `Turkmenistan` }),
    TN: defineMessage({ id: 'countries.tn', message: `Tunisia` }),
    TO: defineMessage({ id: 'countries.to', message: `Tonga` }),
    TR: defineMessage({ id: 'countries.tr', message: `Turkey` }),
    TT: defineMessage({ id: 'countries.tt', message: `Trinidad and Tobago` }),
    TV: defineMessage({ id: 'countries.tv', message: `Tuvalu` }),
    TW: defineMessage({ id: 'countries.tw', message: `Taiwan` }),
    TZ: defineMessage({ id: 'countries.tz', message: `Tanzania` }),
    UA: defineMessage({ id: 'countries.ua', message: `Ukraine` }),
    UG: defineMessage({ id: 'countries.ug', message: `Uganda` }),
    UM: defineMessage({ id: 'countries.um', message: `United States Minor Outlying Islands` }),
    US: defineMessage({ id: 'countries.us', message: `United States` }),
    UY: defineMessage({ id: 'countries.uy', message: `Uruguay` }),
    UZ: defineMessage({ id: 'countries.uz', message: `Uzbekistan` }),
    VA: defineMessage({ id: 'countries.va', message: `Holy See (Vatican City State)` }),
    VC: defineMessage({ id: 'countries.vc', message: `Saint Vincent and the Grenadines` }),
    VE: defineMessage({ id: 'countries.ve', message: `Venezuela` }),
    VG: defineMessage({ id: 'countries.vg', message: `Virgin Islands, British` }),
    VI: defineMessage({ id: 'countries.vi', message: `Virgin Islands, U.S.` }),
    VN: defineMessage({ id: 'countries.vn', message: `Vietnam` }),
    VU: defineMessage({ id: 'countries.vu', message: `Vanuatu` }),
    WF: defineMessage({ id: 'countries.wf', message: `Wallis and Futuna` }),
    WS: defineMessage({ id: 'countries.ws', message: `Samoa` }),
    XK: defineMessage({ id: 'countries.xk', message: `Kosovo` }),
    YE: defineMessage({ id: 'countries.ye', message: `Yemen` }),
    YT: defineMessage({ id: 'countries.yt', message: `Mayotte` }),
    ZA: defineMessage({ id: 'countries.za', message: `South Africa` }),
    ZM: defineMessage({ id: 'countries.zm', message: `Zambia` }),
    ZW: defineMessage({ id: 'countries.zw', message: `Zimbabwe` }),
    ZZ: defineMessage({ id: 'countries.zz', message: `International` }),
};
