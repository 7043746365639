import { Trans } from '@lingui/macro';
import { Loader } from '@pocketlaw/tetris';
import { AssistantComment, useAssistant, AssistantThreadView } from 'app/domains/assistant';
import { useResetFuzzySearchOnUnmount } from './hooks/useResetFuzzySearchOnUnmount';
import { StyledTypography } from './styled';
/**
 * Thread view containing the messages between the User and the Assistant
 */
export function AssistantChatThread() {
    const { thread, creating } = useAssistant();
    useResetFuzzySearchOnUnmount();
    return (<AssistantThreadView dependency={thread.length}>
      {creating ? (<StyledTypography>
          <Trans comment="Document assistant - when a new chat is being created">
            Starting new conversation…
          </Trans>
          <Loader />
        </StyledTypography>) : (thread.map(comment => <AssistantComment key={comment.id} {...comment}/>))}
    </AssistantThreadView>);
}
