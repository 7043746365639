import { themeGet, Card } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const StyledCard = styled(Card) `
  height: 100%;
`;
export const Container = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row-reverse;
  margin-top: ${themeGet('spacing.2xl')};
  column-gap: ${themeGet('spacing.xl')};
`;
