import { Fragment } from 'react';
import { plural, Trans, t } from '@lingui/macro';
import { Alert, Card, LoaderOverlay } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useTeam } from 'app/domains/teams';
import { localizedFormatDate } from 'shared/domains/common-utils';
import { TeamAvatarWithLabel } from '../TeamAvatarWithLabel';
import { StyledCardContent, Container, List, ListItem, Divider } from './styled';
export function TeamProfileCard(props) {
    const errorMessage = t({
        message: 'Could not load team profile',
        comment: 'team profile card: error message',
    });
    const { teamId } = props;
    const { team, loading, error } = useTeam(teamId);
    const { name, members, description, createdByUser } = team || {};
    const numberOfMembers = (members === null || members === void 0 ? void 0 : members.length) || 0;
    const date = localizedFormatDate((team === null || team === void 0 ? void 0 : team.createdAt) || '', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    });
    const numberOfMembersLabel = t({
        message: plural(numberOfMembers, {
            one: '# team member',
            other: '# team members',
        }),
        comment: 'team profile card: number of team members',
    });
    return (<Container>
      <Card>
        <LoaderOverlay enabled={loading}>
          <StyledCardContent>
            {error ? (<Alert message={errorMessage} appearance="danger"/>) : (<Fragment>
                <TeamAvatarWithLabel truncate={false} size="2xl" alignment="vertical-left" title={name} subtitle={description || ''}/>
                <Divider />
                <List>
                  <ListItem>
                    <Falcon icon="user" $size={16}/>
                    {numberOfMembersLabel}
                  </ListItem>
                  <ListItem>
                    <Falcon icon="calendar" $size={16}/>
                    <Trans comment="team profile card: team added date">Added on {date}</Trans>
                  </ListItem>
                  {(createdByUser === null || createdByUser === void 0 ? void 0 : createdByUser.name) && (<ListItem>
                      <Falcon icon="pen-to-square" $size={16}/>
                      <Trans comment="team profile card: team created by">
                        Added by {createdByUser.name}
                      </Trans>
                    </ListItem>)}
                </List>
              </Fragment>)}
          </StyledCardContent>
        </LoaderOverlay>
      </Card>
    </Container>);
}
