import { pxToRem, themeGet } from '@pocketlaw/tetris';
import { css } from 'styled-components';
export const balloonToolbarStyles = css `
  .ck.ck-balloon-panel {
    border: none;
    box-shadow: none;

    &.ck-tooltip {
      padding: ${themeGet('spacing.sm')} ${themeGet('spacing.lg')};
      border-radius: ${themeGet('borderRadius.md')};
      background-color: ${themeGet('colors.gray.900')};
    }

    &.ck-toolbar-container {
      background: transparent;

      &:before {
        display: none;
      }
    }

    &.ck-toolbar-container[class*='arrow_n']:after {
      border-bottom-color: ${themeGet('colors.gray.1000')};
    }

    &.ck-toolbar-container[class*='arrow_s']:after {
      border-top-color: ${themeGet('colors.gray.1000')};
    }

    .ck-balloon-rotator:has(.ck-table-form),
    .ck-balloon-rotator:has(.ck-link-form) {
      border: solid ${pxToRem(1)} ${themeGet('colors.border.100')};
      background-color: ${themeGet('colors.background.50')};
      box-shadow: ${themeGet('dropShadow.200')}, ${themeGet('dropShadow.300')};
    }

    .ck-toolbar:not(.ck-toolbar_compact) {
      border-radius: ${themeGet('borderRadius.lg')};
      background-color: ${themeGet('colors.gray.1000')};
      box-shadow: ${themeGet('dropShadow.200')}, ${themeGet('dropShadow.300')};

      &.ck-rounded-corners {
        border-radius: ${themeGet('borderRadius.md')};
      }

      .ck-toolbar__separator {
        background-color: ${themeGet('colors.gray.800')};
      }

      .ck-dropdown {
        .ck-splitbutton,
        .ck-splitbutton_flatten {
          &:not(.ck-disabled) {
            &:hover button {
              background-color: ${themeGet('colors.gray.900')};
            }

            & .ck-splitbutton__action {
              border-top-right-radius: unset;
              border-bottom-right-radius: unset;
            }

            & .ck-splitbutton__arrow {
              border-top-left-radius: unset;
              border-bottom-left-radius: unset;
              border-width: ${pxToRem(1)};

              &:after {
                display: none;
              }
            }
          }

          &.ck-splitbutton_open > button:not(.ck-disabled) {
            border-color: ${themeGet('colors.gray.900')};
            background-color: ${themeGet('colors.gray.900')};
          }

          &.ck-splitbutton_open > .ck-button:not(.ck-on):not(.ck-disabled):not(:hover) {
            border-color: ${themeGet('colors.gray.900')};
            background-color: ${themeGet('colors.gray.900')};
          }
        }

        .ck-dropdown__panel {
          border-color: ${themeGet('colors.gray.900')};
          background-color: ${themeGet('colors.gray.1000')};

          .ck-list {
            background-color: inherit;

            .ck-list__separator {
              background-color: ${themeGet('colors.gray.900')};
            }

            .ck-list__item {
              .ck-button:not(.ck-list-item-button):not(.ck-disabled) {
                &:focus {
                  background-color: ${themeGet('colors.gray.1000')};
                }

                &:hover,
                &:active {
                  color: ${themeGet('colors.gray.50')};
                  background-color: ${themeGet('colors.gray.900')};
                }
              }

              .ck-list-item-button:not(.ck-disabled):hover {
                color: ${themeGet('colors.gray.50')};
                background-color: ${themeGet('colors.gray.900')};
              }

              .ck-switchbutton.ck-on,
              .ck-switchbutton.ck-off {
                border-color: transparent;
                color: ${themeGet('colors.gray.100')};
                background-color: ${themeGet('colors.gray.900')};
              }
            }
          }
        }
      }

      & button {
        outline: none;
        background: inherit;
        color: ${themeGet('colors.gray.100')};
        border-radius: ${themeGet('borderRadius.md')};
        transition: background-color 200ms ease-in-out;

        .ck-icon {
          color: ${themeGet('colors.gray.100')};
        }

        &:focus,
        &:active {
          border-color: transparent;
          box-shadow: none;
        }

        &:not(.ck-disabled),
        &:not(.ck-off) {
          cursor: pointer;

          &:hover {
            color: ${themeGet('colors.gray.50')};
            background-color: ${themeGet('colors.gray.900')};
          }
        }
      }
    }
  }
`;
