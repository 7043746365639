import { Trans } from '@lingui/macro';
import { Typography, useTetrisTheme } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Container, DarkThemeBox, DarkThemeContent, DarkThemeSelected } from './styled';
export function DarkThemeButton(props) {
    const { selected, onClick } = props;
    const { themeType } = useTetrisTheme();
    return (<Container>
      <DarkThemeBox $themeType={themeType} onClick={onClick}>
        <DarkThemeContent $themeType={themeType}>Aa</DarkThemeContent>
        {selected && (<DarkThemeSelected $themeType={themeType}>
            <Falcon icon="check" $size={12}/>
          </DarkThemeSelected>)}
      </DarkThemeBox>
      <Typography $fontSize="small" $appearance="100">
        <Trans comment="App theme selector - Dark theme label">Dark</Trans>
      </Typography>
    </Container>);
}
