import { gql } from '@apollo/client';
export const upsertReminder = gql `
  mutation UpsertTaskReminder($object: ReminderInsertInput!) {
    createOneReminder(
      object: $object
      on_conflict: { constraint: reminder_pkey, update_columns: [due_date, interval] }
    ) {
      id
      dueDate: due_date
    }
  }
`;
export const removeReminder = gql `
  mutation DeleteReminder($id: UUID!) {
    deleteOneReminder(id: $id) {
      id
      dueDate: due_date
    }
  }
`;
