import { FileSystemContext } from './FileSystemContext';
import { useQueryFileSystem } from './useQueryFileSystem';
export function FileSystemProvider(props) {
    const { folderId, children } = props;
    const fileSystem = useQueryFileSystem(folderId);
    const values = {
        fileSystemData: fileSystem.data,
        loading: fileSystem.loading,
        error: fileSystem.error,
        folderId,
    };
    return <FileSystemContext.Provider value={values}>{children}</FileSystemContext.Provider>;
}
