import { unit, padding, margin, fontWeight, fontSize, themeGet } from '@pocketlaw/tetris';
import { Spacer } from '@pocketlaw/tetris/deprecated';
import styled from 'styled-components';
export const ButtonGroup = styled(Spacer) `
  ${padding(2)};
  align-items: center;
  border-radius: ${unit(2)};
  background-color: ${themeGet('colors.gray.50')};

  margin-left: auto;
`;
export const Text = styled.span `
  ${margin(1, 0)};
  ${fontSize('small')};
  ${fontWeight('bold')};
  color: ${themeGet('colors.gray.600')};
`;
