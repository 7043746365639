import { t } from '@lingui/macro';
import { ButtonGroup, FilterButton, Skeleton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useGetPath } from './useGetPath';
import { withFilterErrorBoundary } from '../FilterErrorBoundary';
import { useFilter } from '../useFilter';
export const fileSystemPathFilterLabel = () => t({ message: 'Path', comment: 'Search filter: Path' });
export const FilesystemPathFilter = withFilterErrorBoundary(() => {
    const filter = useFilter('metadataEntryPath');
    const { startsWith } = filter.value || {};
    const { data, error, loading } = useGetPath(startsWith);
    const handleOnClick = () => {
        if (!loading) {
            filter.resetValue();
        }
    };
    const showFallback = !startsWith || error || !data.path;
    const fallback = t({
        message: 'No path found',
        comment: 'Insights: Filepath filter error message',
    });
    const path = showFallback ? fallback : data.path;
    return (<Skeleton loading={loading}>
      <ButtonGroup>
        <FilterButton type="button" appearance={filter.isTouched ? 'draft' : 'default'}>
          <b>{fileSystemPathFilterLabel()}</b>
          {path}
        </FilterButton>
        <FilterButton type="button" onClick={handleOnClick} appearance={filter.isTouched ? 'draft' : 'default'}>
          <Falcon icon="remove"/>
        </FilterButton>
      </ButtonGroup>
    </Skeleton>);
});
