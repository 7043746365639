import { t } from '@lingui/macro';
import { SidebarMenuBody } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { MetadataNotifications } from 'app/domains/metadata';
import { useFeatureFlag } from 'shared/domains/featureFlag';
import { CollapsibleCustomerSuccess } from './CollapsibleCustomerSuccess';
import { CollapsibleDocuments } from './CollapsibleDocuments';
import { CollapsibleInsights } from './CollapsibleInsights';
import { CollapsibleSavedViews } from './CollapsibleSavedViews';
import { CollapsibleSettings } from './CollapsibleSettings';
import { ItemLink } from './ItemLink';
export function Body() {
    const showCustomerSuccess = useFeatureFlag('feature_customer_success');
    return (<SidebarMenuBody>
      <ItemLink matchExact to="/dashboard" icon={<Falcon icon="grid-2"/>} label={t({ comment: 'Sidebar menu - Dashboard label', message: 'Dashboard' })} matchPath={['/dashboard', '/documents/*']}/>
      <CollapsibleDocuments />
      <CollapsibleInsights />
      <CollapsibleSavedViews />
      <ItemLink to="/tasks" icon={<Falcon icon="list-check"/>} label={t({ comment: 'Sidebar menu - Tasks label', message: 'Tasks' })} matchPath={['/tasks']}/>
      <ItemLink to="/contracts" icon={<Falcon icon="pen-to-square"/>} label={t({ comment: 'Sidebar menu - Templates label', message: 'Templates' })} matchPath={['/contracts']}/>
      <CollapsibleSettings />
      {showCustomerSuccess && <CollapsibleCustomerSuccess />}
      <MetadataNotifications />
    </SidebarMenuBody>);
}
