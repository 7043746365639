import { t } from '@lingui/macro';
import { isPossiblePhoneNumber } from '@pocketlaw/tetris';
import { object, string, boolean, mixed } from 'yup';
import { useEsigningAuthenticationModes } from 'app/domains/esigning/hooks/useEsigningAuthenticationModes';
import { useEsigningProviderMetadata } from 'app/domains/esigning/hooks/useEsigningProviderMetadata';
import { EsigningProviderAuthenticationModeSlug } from 'shared/domains/apollo/generated/types';
function makeAuthenticationMode(hasAuthenticationModes) {
    const requiredString = string().required(t `This is a required field.`);
    return mixed().when('isSigning', {
        is: (isSigning) => isSigning === true && hasAuthenticationModes,
        then: requiredString,
        otherwise: string(),
    });
}
function makeSignatory(hasAuthenticationModes, nameFormat) {
    const requiredString = string().required(t `This is a required field.`);
    const emailField = string()
        .email(t `This must be a valid email.`)
        .required(t `This is a required field.`);
    const mobileField = string().when('authenticationMode', {
        is: EsigningProviderAuthenticationModeSlug.SmsOtp,
        then: requiredString.test('valid-phone-number', t({
            message: 'This must be a valid phone number.',
            comment: 'Error message for invalid phone number for e-signing signatory',
        }), (mobile = '') => isPossiblePhoneNumber(mobile)),
        otherwise: string(),
    });
    const fullNameSchema = object().shape({ name: requiredString });
    const firstNameLastNameSchema = object().shape({
        firstName: requiredString,
        lastName: requiredString,
    });
    const authenticationMode = makeAuthenticationMode(hasAuthenticationModes);
    const signatorySchema = object().shape({
        email: emailField,
        label: string(),
        title: string(),
        message: string(),
        isSigning: boolean(),
        mobile: mobileField,
        authenticationMode,
    });
    if (nameFormat === 'firstNameLastName') {
        return signatorySchema.concat(firstNameLastNameSchema);
    }
    return signatorySchema.concat(fullNameSchema);
}
export function useGetSignatorySchema() {
    const { authenticationModes } = useEsigningAuthenticationModes();
    const hasAuthenticationModes = authenticationModes.length > 0;
    const providerMetadata = useEsigningProviderMetadata();
    const { nameFormat } = providerMetadata.properties;
    return makeSignatory(hasAuthenticationModes, nameFormat);
}
