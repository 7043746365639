import { Box, Loader } from '@pocketlaw/tetris';
import { BarChart } from './BarChart';
import { CountWidget } from './CountWidget';
import { DoughnutChart } from './DoughnutChart';
import { EmptyVisualization } from './EmptyVisualization';
import { ErrorFallback } from './ErrorFallback';
import { LineChart } from './LineChart';
import { TableWidget } from './TableWidget';
import { useGetData } from './useGetData';
import { ComponentErrorBoundary } from '../ComponentErrorBoundary';
export function Visualization(props) {
    const { type, aggregations, filters, colorScheme = 'mono', isHorizontal = false } = props;
    const { data, error, loading } = useGetData({
        aggregations: aggregations || [],
        filters,
    });
    const { dataPoints, totalHitsCount } = data;
    const showLoader = !dataPoints && loading;
    const hasData = Boolean(dataPoints === null || dataPoints === void 0 ? void 0 : dataPoints.length);
    if (error) {
        return <ErrorFallback />;
    }
    if (showLoader) {
        return (<Box width="100%" alignItems="center" justifyContent="center">
        <Loader size={16}/>
      </Box>);
    }
    if (!hasData) {
        return <EmptyVisualization />;
    }
    return (<ComponentErrorBoundary fallback={<ErrorFallback />}>
      {type === 'count' && (<CountWidget aggregations={aggregations} data={dataPoints} filters={filters} colorScheme={colorScheme}/>)}
      {type === 'barchart' && (<BarChart data={dataPoints} aggregations={aggregations} totalHitsCount={totalHitsCount} colorScheme={colorScheme} layout={isHorizontal ? 'horizontal' : 'vertical'} filters={filters}/>)}
      {type === 'linechart' && (<LineChart data={dataPoints} aggregations={aggregations} totalHitsCount={totalHitsCount} colorScheme={colorScheme}/>)}
      {type === 'table' && (<TableWidget data={dataPoints} aggregations={aggregations} totalHitsCount={totalHitsCount}/>)}
      {type === 'doughnut' && (<DoughnutChart data={dataPoints} aggregations={aggregations} totalHitsCount={totalHitsCount} colorScheme={colorScheme}/>)}
    </ComponentErrorBoundary>);
}
