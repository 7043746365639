import { useState } from 'react';
import { Trans } from '@lingui/macro';
import { Chip, IconButton, Menu, MenuList, MenuItem, MenuBody } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { UserAvatar } from 'app/domains/users/components/UserList';
import { Container, LeftCell, RightCell, Description, Label, TextContainer } from './styled';
export function ListItem(props) {
    const { label, description, unreadMessages = 0, pending = false, onClick, onRemove } = props;
    const [open, setOpen] = useState(false);
    const handleCallback = (callback) => () => {
        setOpen(false);
        return callback();
    };
    return (<Container>
      <LeftCell $pending={pending} onClick={onClick}>
        <UserAvatar title={label} grayscale={pending}/>
        <TextContainer>
          <Label $pending={pending}>{label}</Label>
          <Description $pending={pending}>{description}</Description>
        </TextContainer>
      </LeftCell>
      <RightCell>
        {unreadMessages > 0 && <Chip appearance="danger" label={unreadMessages}/>}
        <Menu open={open} onClose={() => setOpen(false)} target={<IconButton size="small" appearance="secondary" onClick={() => setOpen(!open)}>
              <Falcon icon="ellipsis-h" $size={16}/>
            </IconButton>}>
          <MenuBody>
            <MenuList>
              {onClick && (<MenuItem onClick={handleCallback(onClick)}>
                  <Trans comment="Label for opening the chat with an external user">
                    Send message
                  </Trans>
                </MenuItem>)}
              <MenuItem onClick={handleCallback(onRemove)}>
                <Trans comment="Label for removing access for a shared document for an external user">
                  Remove access
                </Trans>
              </MenuItem>
            </MenuList>
          </MenuBody>
        </Menu>
      </RightCell>
    </Container>);
}
