import { Falcon } from '@pocketlaw/tetris/macro';
import { ChatMessageType } from 'shared/domains/apollo/generated/types';
export const getPromptIconByPromptType = (type) => {
    switch (type) {
        case ChatMessageType.DocumentSuggest:
            return <Falcon icon="section" $color="gray.500"/>;
        case ChatMessageType.SelectionSuggest:
            return <Falcon icon="comment" $color="gray.500"/>;
        case ChatMessageType.DocumentAsk:
        case ChatMessageType.SelectionAsk:
        case ChatMessageType.DocumentAskWithQuotes:
        case ChatMessageType.SelectionAskWithQuotes:
            return <Falcon icon="question" $color="gray.500"/>;
        case ChatMessageType.DocumentSummarize:
        case ChatMessageType.SelectionSummarize:
        case ChatMessageType.DocumentSummarizeWithQuotes:
        case ChatMessageType.SelectionSummarizeWithQuotes:
            return <Falcon icon="bolt" $color="gray.500"/>;
        case ChatMessageType.SelectionExplain:
            return <Falcon icon="lightbulb" $color="gray.500"/>;
        case ChatMessageType.SelectionTranslate:
            return <Falcon icon="globe" $color="gray.500"/>;
        case ChatMessageType.SelectionRewrite:
            return <Falcon icon="pen-nib" $color="gray.500"/>;
        case ChatMessageType.DocumentCompare:
            return <Falcon icon="file" $color="gray.500"/>;
        default:
            return null;
    }
};
