import { useQuery } from '@apollo/client';
import { getAllMarketCodes, supportedMarketCodes } from 'app/domains/markets/config';
import { GlobalSearchDocument } from './GlobalSearch.gql';
import { transformSearchQuery } from './transformSearchQuery';
import { useSearchContext } from '../../components/SearchProvider';
export function useSearchQuery(variables, options) {
    const { values } = useSearchContext();
    const { query, filters, referrer, offset, limit, orderBy } = values;
    const { where } = variables || {};
    const queryVariables = {
        query,
        where: Object.assign(Object.assign({}, filters), where),
        limit,
        referrer,
        offset,
        orderBy,
    };
    const userMarketCodes = getAllMarketCodes();
    const excludedMarketCodes = supportedMarketCodes.filter(item => !userMarketCodes.includes(item));
    queryVariables.where = Object.assign(Object.assign({}, queryVariables.where), { marketCode: {
            notIn: excludedMarketCodes,
        } });
    const { data, loading, error } = useQuery(GlobalSearchDocument, {
        variables: queryVariables,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        skip: options === null || options === void 0 ? void 0 : options.skip,
    });
    const transformQueryData = transformSearchQuery(data);
    return {
        data: transformQueryData,
        loading,
        error,
        query,
    };
}
