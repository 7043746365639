import { useMutation } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { UpdateTeamErrorMessages } from './UpdateTeamErrorMessages';
import { UPDATE_TEAM } from './mutation';
import { UpdateTeamErrorCodes } from './types';
export const useUpdateTeam = (id) => {
    const [mutate] = useMutation(UPDATE_TEAM);
    const { addToast } = useToast();
    const updateTeam = (options) => new Promise((resolve, reject) => {
        mutate({
            variables: {
                id,
                input: options,
            },
            context: { sentry: { ignore: [UpdateTeamErrorCodes.DUPLICATE_ENTITY] } },
            onCompleted(data) {
                addToast({
                    appearance: 'success',
                    title: t({ message: 'Team updated', comment: 'Success toast title when updated team' }),
                });
                resolve(data);
            },
            onError(error) {
                const errorMessage = UpdateTeamErrorMessages(error);
                addToast({
                    appearance: 'danger',
                    title: t({
                        message: 'Something went wrong',
                        comment: 'Error toast title when failed to update team',
                    }),
                    message: errorMessage,
                });
                reject(error);
            },
        });
    });
    return { updateTeam };
};
