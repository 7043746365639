import { forwardRef } from 'react';
import { Avatar, Typography, Link, IconButton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useField } from 'formik';
import { Container, LinkContainer } from './styled';
export const LinkField = forwardRef((props, ref) => {
    const { icon, onClick, onRemove, linkText, linkPrefix, name } = props;
    const [field] = useField(name);
    const isValueTruthy = () => {
        if (field.value && typeof field.value === 'object') {
            return Object.values(field.value).length > 0;
        }
        return Boolean(field.value);
    };
    const hasValue = isValueTruthy();
    return (<Container ref={ref}>
      <Avatar size="sm" appearance="light">
        {icon}
      </Avatar>
      <LinkContainer>
        {hasValue && <Typography>{linkPrefix}</Typography>}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link onClick={onClick} as="button" type="button">
          {linkText}
        </Link>
      </LinkContainer>
      {hasValue && (<IconButton $round size="xs" appearance="ghost" onClick={onRemove}>
          <Falcon icon="xmark"/>
        </IconButton>)}
    </Container>);
});
