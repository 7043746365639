import { Link, Typography, pxToRem, themeGet } from '@pocketlaw/tetris';
import styled, { css } from 'styled-components';
export const StyledLink = styled(Link) `
  cursor: pointer;
`;
export const StyledTypography = styled(Typography) `
  margin-top: ${themeGet('spacing.lg')};
`;
const getDraggingStyles = (props) => {
    const { $dragging, $draggingOver } = props;
    if ($draggingOver) {
        return css `
      min-height: ${pxToRem(200)};
      outline: solid ${pxToRem(2)} ${themeGet('colors.blue.500')};
      border: solid ${pxToRem(1)} ${themeGet('colors.blue.500')};
      background-color: ${themeGet('colors.blue.50')};
    `;
    }
    if ($dragging) {
        return css `
      min-height: ${pxToRem(200)};
      outline: solid ${pxToRem(2)} transparent;
      border: dashed ${pxToRem(1)} ${themeGet('colors.blue.200')};
      background-color: ${themeGet('colors.gray.50')};
    `;
    }
    return css `
    min-height: ${pxToRem(160)};
    outline: solid ${pxToRem(2)} transparent;
    border: dashed ${pxToRem(1)} ${themeGet('colors.gray.100')};
    background-color: ${themeGet('colors.gray.50')};
  `;
};
export const DropContainer = styled.div `
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  outline-offset: ${pxToRem(-2)};
  border-radius: ${themeGet('borderRadius.md')};
  transition: all 175ms ease-in-out;

  ${props => getDraggingStyles(props)};
`;
export const DropContainerContent = styled.div `
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: ${props => (props.$dragging ? 'none' : 'all')};
`;
