import { t } from '@lingui/macro';
import { EmptyState } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
export function SearchResultsNoMatch() {
    return (<EmptyState withAvatar icon={<Falcon icon="magnifying-glass"/>} title={t({
            message: 'No documents found',
            comment: 'Global search no results found title',
        })} description={t({
            message: 'There are no documents that match your current filter',
            comment: 'Global search no results found subtitle',
        })}/>);
}
