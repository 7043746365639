import { TextInput, pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const EditContainer = styled.div `
  display: flex;
  align-items: center;
  gap: ${themeGet('spacing.md')};
`;
export const StyledTextInput = styled(TextInput) `
  margin: ${pxToRem(-8)} 0;
`;
