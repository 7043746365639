var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createContext, useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { useHistory } from 'react-router-dom';
import { localeConfig } from 'shared/domains/languages';
import { useCreateDraftWrapper } from './useCreateDraftWrapper';
import { useDraft } from '../useDraft';
export const DraftPreviewContext = createContext(null);
export { useDraftPreview } from './hook';
export function DraftPreviewProvider(props) {
    const { composerId, children, onClose, onGoBack } = props;
    const { data, loading, error } = useDraft(composerId);
    const { composer, templates } = data || {};
    const { createDraft } = useCreateDraftWrapper();
    const { addToast } = useToast();
    const history = useHistory();
    const activeLanguageCode = localeConfig.getActiveLocale().languageCode;
    const templateByLocale = templates.find(template => template.languageCode === activeLanguageCode);
    const [firstTemplate] = templates || [];
    const initialSelected = templateByLocale || firstTemplate;
    const [selectedTemplateId, setSelectedTemplateId] = useState('');
    useEffect(() => {
        if (!selectedTemplateId) {
            setSelectedTemplateId(initialSelected === null || initialSelected === void 0 ? void 0 : initialSelected.id);
        }
    }, [initialSelected === null || initialSelected === void 0 ? void 0 : initialSelected.id, selectedTemplateId]);
    const selectedTemplate = templates.find(template => template.id === selectedTemplateId);
    const selectTemplate = (templateId) => setSelectedTemplateId(templateId);
    const create = () => __awaiter(this, void 0, void 0, function* () {
        if (!selectedTemplate) {
            return;
        }
        const { id: templateId, latestVersion } = selectedTemplate;
        const { treeVersion } = composer;
        if (!latestVersion || !treeVersion) {
            return;
        }
        try {
            const draftId = yield createDraft({
                composer: {
                    id: composerId,
                    tree: {
                        version: treeVersion,
                    },
                },
                template: {
                    id: templateId,
                    latestVersion,
                },
            });
            history.push(`/draft/${draftId}`);
        }
        catch (_a) {
            addToast({
                appearance: 'danger',
                title: t({ comment: 'Create draft error - Title', message: 'Could not create draft' }),
                message: t({
                    comment: 'Create draft error - Message',
                    message: 'Something went wrong while trying to create the draft, please try again',
                }),
            });
        }
    });
    const value = Object.assign(Object.assign({}, data), { loading,
        selectedTemplate,
        selectedTemplateId, createDraft: create, selectTemplate, error: Boolean(error), closeDialog: onClose, goBackToSelect: onGoBack });
    return <DraftPreviewContext.Provider value={value}>{children}</DraftPreviewContext.Provider>;
}
