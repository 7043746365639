import { breakpoints, themeGet, Typography, pxToRem, ListItem, CardHeader, } from '@pocketlaw/tetris';
import styled, { css, keyframes } from 'styled-components';
function transitionStyles(state) {
    switch (state) {
        case 'visible':
            return css `
        transform: translate(0, 0);
        opacity: 1;
      `;
        case 'hidden':
            return css `
        display: none;
      `;
        case 'entering':
        case 'leaving':
        default:
            return css `
        transform: translate(0, 15%);
        opacity: 0;
      `;
    }
}
export const Container = styled.div `
  position: fixed;
  top: ${themeGet('spacing.xl')};
  right: ${themeGet('spacing.xl')};
  bottom: ${themeGet('spacing.xl')};
  left: ${themeGet('spacing.xl')};

  ${breakpoints.md} {
    width: ${pxToRem(600)};
    height: unset;
    top: unset;
    right: unset;
  }

  transition: all 0.2s ease-in-out;

  ${props => transitionStyles(props.$transitionState)};

  z-index: 1000;
`;
export const Footer = styled.footer `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${themeGet('colors.gray.50')};
  padding: ${themeGet('spacing.lg')} ${themeGet('spacing.2xl')};
`;
export const RightAlign = styled.div `
  margin-left: auto;
  padding-left: ${themeGet('spacing.4xl')};
`;
export const StyledTypography = styled(Typography) `
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const fadeInAnimation = keyframes `
 0% { opacity: 0 }
 100% { opacity: 1 }
`;
export const StyledListItem = styled(ListItem) `
  ${({ disabled }) => disabled &&
    `
    visibility: hidden;
  `};

  animation-name: ${fadeInAnimation};
  animation-duration: 0.2s;
`;
export const StyledCardHeader = styled(CardHeader) `
  padding: ${themeGet('spacing.xl')} ${themeGet('spacing.2xl')};
  border-bottom: ${pxToRem(1)} solid ${themeGet('colors.gray.200')};
  padding-bottom: 0;
  gap: ${themeGet('spacing.md')};
  margin-bottom: ${themeGet('spacing.md')};

  [role='tablist'] {
    border-bottom: none;
  }
`;
