import { t } from '@lingui/macro';
import { ApprovalType } from 'shared/domains/apollo/generated/types';
export const getTitleFromType = (type) => {
    switch (type) {
        case ApprovalType.OneOf:
            return t({
                message: 'Approval needed by one of',
                comment: 'Approval Drawer - approvers list - section title - one of',
            });
        case ApprovalType.All:
        default:
            return t({
                message: 'Approval needed by',
                comment: 'Approval Drawer - approvers list - section title - all and default',
            });
    }
};
