import { createContext, useContext, useEffect, useState } from 'react';
import { useField } from 'formik';
import { getCurrentDropdown } from '../utils';
const Context = createContext({});
export function useCurrentDropdown() {
    return useContext(Context);
}
export function CurrentDropdownProvider(props) {
    const { children } = props;
    const [dueDateField] = useField('dueDate');
    const dueDate = dueDateField.value;
    const [isOpen, setIsOpen] = useState(false);
    const [currentDropdown, setCurrentDropdown] = useState(getCurrentDropdown(dueDate));
    useEffect(() => {
        setCurrentDropdown(getCurrentDropdown(dueDate));
    }, [dueDate]);
    const openDropdown = () => setIsOpen(true);
    const closeDropdown = () => {
        setIsOpen(false);
        setCurrentDropdown(getCurrentDropdown(dueDate));
    };
    const toggleDropdown = () => {
        if (isOpen) {
            closeDropdown();
        }
        else {
            openDropdown();
        }
    };
    const openCalendar = () => {
        setCurrentDropdown('calendar');
        openDropdown();
    };
    const value = {
        isOpen,
        currentDropdown,
        setCurrentDropdown,
        openDropdown,
        closeDropdown,
        toggleDropdown,
        openCalendar,
    };
    return <Context.Provider value={value}>{children}</Context.Provider>;
}
