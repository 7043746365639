import { Bold, Essentials, Heading, Italic, List, Paragraph, Table, Strikethrough, Underline, Alignment, PageBreak, Link, Indent, IndentBlock, Notification, Permissions, } from 'pleditor';
export const PREVIEW_PLUGINS = [
    Bold,
    Essentials,
    Heading,
    Italic,
    List,
    Paragraph,
    Table,
    Strikethrough,
    Underline,
    Alignment,
    PageBreak,
    Link,
    Indent,
    IndentBlock,
    Notification,
    Permissions,
];
