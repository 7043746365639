import { pxToRem, themeGet } from '@pocketlaw/tetris';
import { css } from 'styled-components';
export const commentErrorMessageStyles = css `
  .ck .pl-comment-error-message {
    border-left: ${pxToRem(3)} solid var(--ck-color-comment-box-border);
    color: ${themeGet('colors.text.danger')};
    padding: 0 1rem 0 2rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .ck .pl-close-error-button {
    color: ${themeGet('colors.text.danger')};
  }
`;
