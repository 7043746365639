var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useFormikContext } from 'formik';
export function useSearchContext() {
    const { values, setFieldValue, setFieldTouched, resetForm, touched, handleSubmit, isSubmitting } = useFormikContext();
    const { filters } = values;
    const hasFilters = Boolean(Object.keys(filters || {}).length);
    const dirty = Boolean(touched.query || touched.filters || touched.columns || touched.orderBy);
    const activeFilters = Object.entries(values.filters || {}).map(([key]) => key);
    const clearFilters = () => {
        setFieldValue('filters', {});
        setFieldTouched('filters', true);
    };
    const setOffset = (newOffset) => {
        setFieldValue('offset', newOffset);
        setFieldTouched('offset', false);
    };
    const setOrderBy = (orderBy) => {
        setFieldValue('orderBy', orderBy);
        setFieldTouched('orderBy', true);
    };
    const setHiddenColRows = (newColumnOrder) => {
        setFieldValue('columns', newColumnOrder);
        setFieldTouched('columns', true);
    };
    const removeFilter = (id) => {
        const _a = filters || {}, 
        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
        _b = id, 
        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
        _ = _a[_b], newFilterValues = __rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
        setFieldValue('filters', newFilterValues);
        setFieldTouched(`filters.${id}`, true);
    };
    const toggleFilter = (id) => {
        const isAdded = activeFilters.includes(id);
        if (isAdded) {
            removeFilter(id);
        }
        else {
            setFieldValue('filters', Object.assign(Object.assign({}, filters), { [id]: undefined }));
            setFieldTouched(`filters.${id}`, false);
        }
        setFieldTouched('filters', true);
    };
    return {
        values,
        hasFilters,
        activeFilters,
        toggleFilter,
        removeFilter,
        clearFilters,
        setOffset,
        setOrderBy,
        resetForm,
        setHiddenColRows,
        handleSubmit,
        dirty,
        touched,
        isSubmitting,
    };
}
