import { useMemo } from 'react';
import { Trans, t } from '@lingui/macro';
import { TextInput, Button, Dialog, DialogHeader, DialogContent, DialogFooter, Grid, Column, Loader, Alert, AvatarWithLabel, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Formik, Form, Field } from 'formik';
import { useRenameEntry } from 'app/domains/filesystem';
import { EntryKind } from 'shared/domains/apollo/generated/types';
import { useActiveDocument } from 'shared/domains/documents';
import * as Sentry from 'shared/domains/sentry';
import { schema } from './schema';
export function RenameDocumentDialog(props) {
    const { onClose } = props;
    const { id, name } = useActiveDocument();
    const [renameEntry, mutation] = useRenameEntry({ type: EntryKind.Document, id });
    const initialValues = useMemo(() => ({ name }), [name]);
    const title = t({
        message: 'Edit name',
        comment: 'rename document dialog title',
    });
    const handleSubmit = (values, { resetForm }) => {
        renameEntry(values.name)
            .then(onClose)
            .catch(error => {
            Sentry.captureException(error);
            resetForm({ values: initialValues });
        });
    };
    return (<Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit} validateOnMount>
      {({ isSubmitting, isValid }) => (<Dialog as={Form} size="sm" onClose={isSubmitting ? undefined : onClose}>
          <DialogHeader media={<AvatarWithLabel size="xl" shape="square" title={title}>
                <Falcon icon="pen"/>
              </AvatarWithLabel>}/>
          <DialogContent>
            <Grid directions="vertical" spacing={2}>
              <Column width="100%">
                <Field name="name" as={TextInput} stretch/>
              </Column>
              {mutation.error ? (<Column width="100%">
                  <Alert appearance="danger" message={t({
                    message: 'Something went wrong, please try again later.',
                    comment: 'failed to do something error message',
                })}/>
                </Column>) : null}
            </Grid>
          </DialogContent>
          <DialogFooter>
            <Button type="button" appearance="secondary" disabled={isSubmitting} onClick={onClose}>
              <Trans>Cancel</Trans>
            </Button>
            <Button type="submit" disabled={!isValid || isSubmitting}>
              <Trans>Rename</Trans>
              {isSubmitting && <Loader />}
            </Button>
          </DialogFooter>
        </Dialog>)}
    </Formik>);
}
