import { gql } from '@apollo/client';
export const invitesQuery = gql `
  query userInvites($orderBy: [UserInviteOrderBy!], $search: String) {
    userInvites(
      where: { accepted_by_user_id: { _is_null: true }, email: { _ilike: $search } }
      order_by: $orderBy
    ) {
      id
      email
      expired
      role
      createdAt: created_at
      canManageMemeber: can_manage_member
    }
  }
`;
