import { useLingui } from '@lingui/react';
import { getRole } from 'app/domains/users/utils';
import { roles } from 'app/domains/users/utils/roles';
export function useRoleName() {
    const { i18n } = useLingui();
    // eslint-disable-next-line no-underscore-dangle
    const _i18n = i18n;
    const getRoleName = (invite) => {
        const userRole = getRole(invite.role, invite.canManageMemeber);
        const { label: userRoleName } = roles.find(({ value }) => value === userRole) || {};
        if (!userRoleName) {
            return '';
        }
        return _i18n._(userRoleName);
    };
    return { getRoleName };
}
