import { localeConfig } from 'shared/domains/languages/config';
export function findLocalizedTemplateId(templates) {
    var _a;
    const { languageCode } = localeConfig.getActiveLocale();
    if (!Array.isArray(templates) || !templates.length) {
        return '';
    }
    const byCode = (code) => (template) => template.marketLocaleCode === code;
    const localizedTemplate = templates.find(byCode(languageCode));
    const fallbackTemplate = templates.find(byCode('en'));
    const match = localizedTemplate || fallbackTemplate;
    return (match === null || match === void 0 ? void 0 : match.id) || ((_a = templates[0]) === null || _a === void 0 ? void 0 : _a.id);
}
