import { gql } from '@apollo/client';
export const companyPlansQuery = gql `
  query CompanyPlans($companyId: UUID!) {
    company(id: $companyId) {
      id
      plans {
        id
        plan {
          id
          name
        }
      }
    }
  }
`;
