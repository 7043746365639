const isAttachmentsEqual = (attachmentsA, attachmentsB) => {
    const uniquePathsA = attachmentsA.map(({ path }) => path);
    const uniquePathsB = attachmentsB.map(({ path }) => path);
    return (uniquePathsA.every(path => uniquePathsB.includes(path)) &&
        uniquePathsB.every(path => uniquePathsA.includes(path)));
};
export const isComposerDefaultAttachmentsEqual = (composerAttachments) => {
    const firstAttachments = composerAttachments[0];
    return [...composerAttachments].slice(1).every(attachments => {
        if (!firstAttachments && !attachments) {
            return true;
        }
        if (firstAttachments && attachments) {
            return isAttachmentsEqual(firstAttachments, attachments);
        }
        return false;
    });
};
export const isComposerDefaultAttachmentsEdit = (composerAttachments) => composerAttachments.some(attachments => attachments.length > 0);
