import { isValid } from 'date-fns';
import { localeConfig } from 'shared/domains/languages/config';
import * as Sentry from 'shared/domains/sentry';
export function localizedFormatDate(str, options) {
    const valid = str && isValid(new Date(str));
    if (!valid) {
        return undefined;
    }
    try {
        const localeCode = localeConfig.getActiveLocale().code;
        const date = new Date(str);
        const formattedDate = new Intl.DateTimeFormat(localeCode, options).format(date);
        return formattedDate;
    }
    catch (error) {
        Sentry.captureException(error);
        return undefined;
    }
}
