import { t } from '@lingui/macro';
import { Alert, Box, ConfirmDialog, Typography } from '@pocketlaw/tetris';
export function ConfirmRemoveDialog(props) {
    const { embedded, onCancel, onConfirm } = props;
    const heading = t({
        comment: 'Remove uploaded attachment confirm dialog - title',
        message: 'Remove attachment',
    });
    const confirm = t({
        comment: 'Remove uploaded attachment confirm dialog - confirm button label',
        message: 'Remove',
    });
    const cancel = t({
        comment: 'Remove uploaded attachment confirm dialog - cancel button label',
        message: 'Cancel',
    });
    const message = t({
        comment: 'Remove uploaded attachment confirm dialog - message',
        message: 'Are you sure you want to remove this attached document?',
    });
    return (<ConfirmDialog size="sm" appearance="danger" heading={heading} confirm={confirm} cancel={cancel} onConfirm={onConfirm} onCancel={onCancel}>
      <Box flexDirection="column" gap="md">
        <Typography>{message}</Typography>
        {embedded && (<Alert appearance="warning" message={t({
                comment: 'Remove uploaded attachment confirm dialog - Attachment is embedded in the document warning message',
                message: 'This attachment is embedded in the document, removing the attachment will remove it from the document',
            })}/>)}
      </Box>
    </ConfirmDialog>);
}
