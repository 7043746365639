import { gql } from '@apollo/client';
export const createMutation = gql `
  mutation UpsertDocumentAttachment($objects: [TaskDocumentInsertInput!]!) {
    createTaskDocument(objects: $objects) {
      returning {
        id
        task_id
        document_id
      }
    }
  }
`;
export const removeMutation = gql `
  mutation DeleteDocumentAttachment($where: TaskDocumentBoolExp!) {
    deleteTaskDocument(where: $where) {
      affected_rows
    }
  }
`;
