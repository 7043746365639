// Errors that may be fixed by redirecting to auth0
export const RECOVERABLE_ERRORS = [
    'login_required',
    'consent_required',
    'interaction_required',
    'account_selection_required',
];
// Auth0 supported languages
// Reference: https://auth0.com/docs/universal-login/i18n#internationalization-in-the-new-universal-login-experience
export const SUPPORTED_LANGUAGES = [
    'da',
    'de',
    'en',
    'es',
    'fi',
    'fr',
    'hi',
    'it',
    'ja',
    'ko',
    'nb',
    'nl',
    'pt-BR',
    'pt-PT',
    'ru',
    'sv',
    'zh-CN',
    'zh-TW',
];
