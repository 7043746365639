var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation, useQuery } from '@apollo/client';
import { useActiveDocument } from 'shared/domains/documents';
import { AddDocumentAttachmentDocument } from './add.gql';
import { GetDocumentAttachmentsDocument } from './get.gql';
import { RemoveDocumentAttachmentDocument } from './remove.gql';
import { UpdateDocumentAttachmentDocument } from './update.gql';
export const useDocumentAttachments = () => {
    const { id: documentId } = useActiveDocument();
    const { data, loading, error } = useQuery(GetDocumentAttachmentsDocument, {
        variables: { documentId },
    });
    const [addMutation, { loading: addingAttachment }] = useMutation(AddDocumentAttachmentDocument, {
        update: (cache, { data: addData }) => {
            if (addData === null || addData === void 0 ? void 0 : addData.addDocumentAttachment) {
                const { id } = addData.addDocumentAttachment;
                cache.modify({
                    id: cache.identify({ id: documentId, __typename: 'FileSystemDocument' }),
                    fields: {
                        attachments(existing = [], { toReference }) {
                            const newReference = toReference({ id, __typename: 'DocumentAttachment' }, true);
                            return [...existing, newReference];
                        },
                        attachmentsCount(currentCount = 0) {
                            return Math.max(currentCount + 1, 0);
                        },
                    },
                });
            }
        },
    });
    const [updateMutation, { loading: updatingAttachment }] = useMutation(UpdateDocumentAttachmentDocument);
    const [removeMutation, { loading: removingAttachment }] = useMutation(RemoveDocumentAttachmentDocument, {
        update(cache, { data: removeData }) {
            const { id, __typename } = (removeData === null || removeData === void 0 ? void 0 : removeData.removeDocumentAttachment) || {};
            cache.modify({
                id: cache.identify({ id, __typename }),
                fields(_, { DELETE }) {
                    return DELETE;
                },
            });
            cache.modify({
                id: cache.identify({ id: documentId, __typename: 'FileSystemDocument' }),
                fields: {
                    attachmentsCount(currentCount = 0) {
                        return Math.max(currentCount - 1, 0);
                    },
                },
            });
        },
    });
    const { attachments: documentAttachments = [], attachmentsCount } = (data === null || data === void 0 ? void 0 : data.fileSystemDocument) || {};
    const count = attachmentsCount || 0;
    const attachments = documentAttachments || [];
    const isAttachmentLoading = addingAttachment || updatingAttachment || removingAttachment;
    const addAttachment = (input) => __awaiter(void 0, void 0, void 0, function* () { return addMutation({ variables: { input: Object.assign({ fileSystemDocumentId: documentId }, input) } }); });
    const updateAttachment = (input) => __awaiter(void 0, void 0, void 0, function* () {
        return updateMutation({
            variables: {
                attachmentId: input.attachmentId,
                input: {
                    name: input.name,
                },
            },
        });
    });
    const removeAttachment = (attachmentId) => __awaiter(void 0, void 0, void 0, function* () { return removeMutation({ variables: { attachmentId } }); });
    return {
        count,
        attachments,
        addingAttachment,
        isAttachmentLoading,
        error,
        loading,
        addAttachment,
        updateAttachment,
        removeAttachment,
    };
};
