import { MenuItem, Avatar, AvatarLetter } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useSelectUsersContext } from '../useSelectUsersContext';
import { AlignRight, TextContainer } from './styled';
export function UserItem(props) {
    const { user } = props;
    const { userId, name = '' } = user;
    const { selectedUserIds, onSelectUser, onUnselectUser } = useSelectUsersContext();
    const selected = selectedUserIds.includes(userId);
    const handleClick = () => {
        if (selected) {
            onUnselectUser(user);
        }
        else {
            onSelectUser(user);
        }
    };
    return (<MenuItem onClick={handleClick}>
      <Avatar size="sm">
        <AvatarLetter>{name === null || name === void 0 ? void 0 : name[0]}</AvatarLetter>
      </Avatar>
      <TextContainer>{name}</TextContainer>
      <AlignRight>{selected ? <Falcon icon="remove"/> : <Falcon icon="add"/>}</AlignRight>
    </MenuItem>);
}
