import { Trans, t } from '@lingui/macro';
import { Typography } from '@pocketlaw/tetris';
import { AppError } from 'shared/domains/errors';
export function DocumentNotFoundError() {
    return (<AppError code={404} title={t({
            message: 'Document not found',
            comment: 'Document view: 404 error page title',
        })} message={<Trans comment="Document view: 404 error page description">
          <Typography>Ooops, the document can not be found...</Typography>
          <br />
          <br />
          <Typography>
            You might not have access to this document, or your access has been revoked.
          </Typography>
          <br />
          <Typography>Please contact the owner of the document to request access.</Typography>
        </Trans>}/>);
}
