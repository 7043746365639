import { Falcon } from '@pocketlaw/tetris/macro';
import { EntryKind } from 'shared/domains/apollo/generated/types';
export function getAppearance(type) {
    switch (type) {
        case EntryKind.Document:
            return 'primary';
        case EntryKind.Folder:
        default:
            return 'success';
    }
}
export function getIcon(type) {
    switch (type) {
        case EntryKind.Document:
            return <Falcon icon="file-lines"/>;
        case EntryKind.Folder:
        default:
            return <Falcon icon="folder"/>;
    }
}
