import { useState } from 'react';
import { Trans } from '@lingui/macro';
import { Tabs, TabNav, Tab, TabContent } from '@pocketlaw/tetris';
import { HandledFeedback } from './HandledFeedback';
import { OpenFeedback } from './OpenFeedback';
import { ReviewTabs } from './types';
export function ReviewFeedback() {
    const [activeTab, setActiveTab] = useState(ReviewTabs.Open);
    const handleSelectTab = (tab) => setActiveTab(tab);
    return (<Tabs activeTab={activeTab} onSelect={handleSelectTab}>
      <TabNav>
        <Tab id={ReviewTabs.Open}>
          <Trans comment="Label for contract review issues tab - Open issues">Issues</Trans>
        </Tab>
        <Tab id={ReviewTabs.Handled}>
          <Trans comment="Label for contract review issues tab - Handled issues">Handled</Trans>
        </Tab>
      </TabNav>
      <TabContent tabId={ReviewTabs.Open}>
        <OpenFeedback />
      </TabContent>
      <TabContent tabId={ReviewTabs.Handled}>
        <HandledFeedback />
      </TabContent>
    </Tabs>);
}
