import { Falcon } from '@pocketlaw/tetris/macro';
import { CloudIconContainer, Container } from './styled';
export function UploadIcon(props) {
    const { draggingOver } = props;
    return (<Container>
      <CloudIconContainer $draggingOver={draggingOver}>
        <Falcon $size={56} icon="cloud-arrow-up" sourceConfig={{ family: 'classic', style: 'thin' }}/>
      </CloudIconContainer>
    </Container>);
}
