var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import Binary from './Binary';
import Variable from './Variable';
function Span(props) {
    const { 'data-markstache-key': id, 'data-markstache-predicate': predicate, children } = props, rest = __rest(props, ['data-markstache-key', 'data-markstache-predicate', "children"]);
    if (predicate) {
        return (<Binary {...rest} id={id} predicate={predicate}>
        {children}
      </Binary>);
    }
    if (id) {
        return (<Variable {...rest} id={id}>
        {children}
      </Variable>);
    }
    return <span {...rest}>{children}</span>;
}
export default Span;
