import { gql } from '@apollo/client';
export const MUTATION = gql `
  mutation RejectApprovalRequest($input: RejectApprovalRequestInput!) {
    rejectApprovalRequest(input: $input) {
      id
      status
      approvers {
        id
        status
      }
    }
  }
`;
