export function DocuSignIcon() {
    return (<svg viewBox="0 0 380 83" fill="#0A0B09">
      <path d="M25.5466399,54.2263317 C38.0240722,54.2263317 42.883651,46.5995763 42.883651,33.5466707 C42.883651,20.4937651 37.2066199,11.3949153 25.9227683,11.3949153 L13.2748245,11.3949153 L13.2748245,54.2263317 L25.5466399,54.2263317 Z M-0.0300902708,0 L27.552658,0 C45.0752257,0 56.3590772,14.1532082 56.3590772,33.5466707 C56.3590772,44.2080508 52.7833501,53.5832324 45.9027081,59.3761501 C41.0381143,63.4206416 34.7091274,65.716707 26.4543631,65.716707 L-0.0300902708,65.716707 L-0.0300902708,0 L-0.0300902708,0 Z"/>
      <path d="M94.3781344,43.3840799 C94.3781344,34.18977 90.0702106,27.9396489 82.4523571,27.9396489 C74.7492477,27.9396489 70.5265797,34.18977 70.5265797,43.3840799 C70.5265797,52.5783898 74.7492477,58.7330508 82.4523571,58.7330508 C90.0702106,58.7330508 94.3781344,52.5733656 94.3781344,43.3840799 Z M57.8635908,43.3840799 C57.8635908,29.3162833 67.8635908,18.4740315 82.4373119,18.4740315 C97.0110331,18.4740315 107.011033,29.3213075 107.011033,43.3840799 C107.011033,57.4468523 97.0110331,68.2941283 82.4373119,68.2941283 C67.8635908,68.2941283 57.8635908,57.4468523 57.8635908,43.3840799 L57.8635908,43.3840799 Z"/>
      <path d="M109.102307,43.3840799 C109.102307,29.3162833 118.27984,18.4740315 132.592778,18.4740315 C144.794383,18.4740315 152.316951,25.5531477 153.966901,35.7523002 L141.765296,35.7523002 C140.937813,31.6173729 137.728185,28.4923123 133.139418,28.4923123 C125.616851,28.4923123 121.765296,34.28523 121.765296,43.3840799 C121.765296,52.2970339 125.341023,58.2758475 132.958877,58.2758475 C138.004012,58.2758475 141.579739,55.6079903 142.407222,50.2773002 L154.44333,50.2773002 C153.615848,60.2955811 145.817452,68.2941283 133.249749,68.2941283 C118.37011,68.2941283 109.102307,57.4468523 109.102307,43.3840799"/>
      <path d="M186.915747,66.9124697 L186.915747,61.3858354 L186.63992,61.3858354 C183.430291,65.7016344 179.759278,68.1835956 173.154463,68.1835956 C162.693079,68.1835956 156.820461,61.4762712 156.820461,51.1816586 L156.820461,19.76023 L169.207623,19.76023 L169.207623,49.0815375 C169.207623,54.6081719 171.685055,57.6226998 177.006018,57.6226998 C182.878636,57.6226998 186.454363,53.2114407 186.454363,46.9613196 L186.454363,19.76023 L198.931795,19.76023 L198.931795,66.9124697 L186.915747,66.9124697"/>
      <path d="M259.714142,66.9375908 L272.191575,66.9375908 L272.191575,19.7853511 L259.714142,19.7853511 L259.714142,66.9375908 Z M259.714142,13.1684625 L272.191575,13.1684625 L272.191575,1.50726392 L259.714142,1.50726392 L259.714142,13.1684625 Z"/>
      <path d="M310.310933,41.2688862 C310.310933,34.3756659 306.644935,28.4923123 299.117352,28.4923123 C292.788365,28.4923123 288.585757,33.4562349 288.585757,41.359322 C288.585757,49.3578692 292.80341,54.04546 299.227683,54.04546 C307.101304,54.04546 310.310933,48.2324455 310.310933,41.2688862 Z M277.377131,68.2941283 L289.759278,68.2941283 C290.762287,71.233293 293.430291,73.5293584 299.212638,73.5293584 C306.273821,73.5293584 309.669007,70.1279661 309.669007,63.6969734 L309.669007,58.547155 L309.398195,58.547155 C306.64995,61.6722155 303.069208,64.0737893 297.106319,64.0737893 C286.644935,64.0737893 276.369107,55.8039346 276.369107,41.5552663 C276.369107,27.4874697 284.814443,18.4840799 296.740221,18.4840799 C302.612839,18.4840799 306.920762,20.7801453 309.779338,24.734201 L309.95988,24.734201 L309.95988,19.76023 L321.995988,19.76023 L321.995988,63.3302058 C321.995988,69.9470944 319.884654,74.4487893 316.399198,77.6693099 C312.407222,81.3420097 306.389168,83 299.39318,83 C286.549649,83 278.660983,77.483414 277.377131,68.2941283 L277.377131,68.2941283 Z"/>
      <path d="M339.488465,26.196247 L339.764293,26.196247 C342.973922,21.1418886 347.286861,18.4740315 353.711133,18.4740315 C363.435306,18.4740315 369.94985,25.8294794 369.94985,36.124092 L369.94985,66.9124697 L357.492477,66.9124697 L357.492477,37.962954 C357.492477,32.9035714 354.553661,29.3213075 349.327984,29.3213075 C343.811434,29.3213075 339.799398,33.7325666 339.799398,40.1685835 L339.799398,66.9124697 L327.306921,66.9124697 L327.306921,19.76023 L339.488465,19.76023 L339.488465,26.196247"/>
      <path d="M231.283852,27.2965496 C221.559679,25.1863801 216.975928,23.6238499 216.975928,18.2931598 C216.975928,13.605569 221.654965,10.5709443 228.811434,10.5709443 C235.330993,10.5709443 240.346038,13.6407385 241.349047,19.76023 L254.147442,19.76023 C252.848546,6.9937046 243.395186,0 228.901705,0 C214.312939,0 203.761284,6.70732446 203.761284,19.6697942 C203.761284,33.365799 214.498495,36.4908596 226.238716,39.1536925 C236.238716,41.4547821 242.477432,42.7409806 242.477432,49.1719734 C242.477432,55.2412228 236.514544,57.627724 229.729188,57.627724 C220.366098,57.627724 215.872618,54.5026634 214.954865,46.5744552 L201.915747,46.5744552 C202.377131,60.4563559 212.447342,68.3594431 230.265797,68.3594431 C245.04012,68.3594431 255.772324,60.9135593 255.772324,47.584322 C255.782347,33.4562349 244.774323,30.2407385 231.283852,27.2965496"/>
      <path d="M375.035105,19.3080508 C372.722668,19.3080508 370.847543,21.1861017 370.847543,23.5032688 C370.847543,25.8204358 372.722668,27.6984867 375.035105,27.6984867 C377.347543,27.6984867 379.222668,25.8204358 379.222668,23.5032688 C379.222668,23.5017615 379.222668,23.4997518 379.222668,23.4982446 C379.22016,21.1830872 377.345537,19.3080508 375.035105,19.3080508 Z M375.035105,28.4722155 C372.292879,28.4722155 370.070211,26.2454843 370.070211,23.4982446 C370.070211,20.7510048 372.292879,18.5242736 375.035105,18.5242736 C377.777332,18.5242736 380,20.7510048 380,23.4982446 C379.996991,26.243977 377.776329,28.4697034 375.035105,28.4722155 L375.035105,28.4722155 Z"/>
      <path d="M374.267803,21.764891 L374.267803,23.3525424 L374.593781,23.3525424 C374.919759,23.3525424 375.04012,23.3525424 375.220662,23.3525424 C375.655466,23.39977 376.046138,23.0847518 376.093781,22.6486501 C376.097292,22.6175 376.098788,22.585345 376.098295,22.5536925 C376.108826,22.2658051 375.94985,21.9985169 375.692076,21.8703995 C375.369609,21.7623789 375.027081,21.726707 374.689067,21.764891 L374.267803,21.764891 Z M373.32999,20.9409201 L374.944835,20.9409201 C375.621866,20.9409201 376.07322,20.9409201 376.519559,21.3076877 C376.891174,21.6161743 377.098796,22.0804116 377.081244,22.5637409 C377.10682,23.2048305 376.693079,23.7811077 376.078235,23.9604722 L377.20662,26.0254237 L376.12337,26.0254237 L375.095286,24.1162228 L374.247743,24.1162228 L374.247743,26.0254237 L373.30993,26.0254237 L373.32999,20.9409201 L373.32999,20.9409201 Z"/>
    </svg>);
}
