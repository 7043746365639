import { useRef } from 'react';
import { t } from '@lingui/macro';
import { SearchInput } from '@pocketlaw/tetris';
import { useDebouncedCallback } from 'use-debounce';
import { Wrapper, Container } from './styled';
export function TableToolbar(props) {
    const { children, onSearch, loading } = props;
    const tableRef = useRef(null);
    const handleSearch = useDebouncedCallback((value) => {
        if (onSearch) {
            onSearch(value);
        }
    }, 500);
    return (<Wrapper>
      <Container>
        {onSearch && (<SearchInput stretch placeholder={t({ message: 'Search', comment: 'search placeholder for tableToolbar' })} loading={loading} onChange={e => {
                handleSearch(e.target.value);
            }}/>)}
      </Container>
      {children({ tableRef })}
    </Wrapper>);
}
