import { Trans, t } from '@lingui/macro';
import { Box } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useField } from 'formik';
import { DataPoints } from './DataPoints';
import { Label } from './Label';
import { SelectDataPoint } from './SelectDataPoint';
export function Breakdown(props) {
    var _a;
    const { pathBase } = props;
    const [field] = useField(pathBase);
    const displayAddBreakdown = !((_a = field.value.aggregations) === null || _a === void 0 ? void 0 : _a.length);
    return (<Box flexDirection="column">
      <Label>
        <Trans comment="Insights widget form: Label for when a data point is split by sub aggregation">
          Breakdown
        </Trans>
      </Label>

      {displayAddBreakdown && (<Box>
          <SelectDataPoint icon={<Falcon icon="plus"/>} label={t({
                message: 'Add',
                comment: 'Insights widget form: Breakdown data point',
            })} name={`${pathBase}.aggregations[0]`}/>
        </Box>)}

      <DataPoints isSubAggregation name={`${pathBase}.aggregations`}/>
    </Box>);
}
