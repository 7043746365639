import { useId } from 'react';
import { t } from '@lingui/macro';
import { Text } from '@pocketlaw/tetris/deprecated';
import { Falcon } from '@pocketlaw/tetris/macro';
import { localizedFormatDate } from 'shared/domains/common-utils';
import { StyledButton } from './styled';
export function DateButton(props) {
    const { date, label, onClick } = props;
    const id = useId();
    return (<div>
      <span id={id}>
        <Text appearance="neutral" weight="semibold">
          {label}
        </Text>
      </span>
      <StyledButton aria-describedby={id} appearance="ghost" $stretch onClick={onClick} type="button">
        <Falcon icon="calendar" $size={16}/>
        {date
            ? localizedFormatDate(date.toISOString())
            : t({
                message: 'Set date',
                comment: 'Date range filter: "Set date" button label',
            })}
      </StyledButton>
    </div>);
}
