import { useMutation } from '@apollo/client';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { MUTATION } from './mutation';
export const useDocxToPdf = () => {
    var _a, _b;
    const account = useAccount();
    const companyId = (_b = (_a = account.data) === null || _a === void 0 ? void 0 : _a.company) === null || _b === void 0 ? void 0 : _b.id;
    const [mutate] = useMutation(MUTATION);
    const handler = (file) => {
        const { path, mimeType, size } = file;
        return mutate({
            variables: { companyId, path, mimeType, size },
        }).then(({ data }) => data === null || data === void 0 ? void 0 : data.files);
    };
    return handler;
};
