import { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Checkbox, Column, Dialog, DialogHeader, DialogContent, DialogFooter, Button, Grid, AvatarWithLabel, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useEsigning } from 'app/domains/esigning/components/EsigningProvider';
export function RemoveScriveSignatoryDialog(props) {
    const { onClose } = props;
    const [checked, setChecked] = useState(false);
    const { cancelSignRequest } = useEsigning();
    const handleChange = () => {
        setChecked(value => !value);
    };
    const handleSubmit = () => {
        onClose();
        cancelSignRequest();
    };
    const title = t({
        message: 'Cancel e-signing',
        comment: 'cancel e-signing for one user dialog title',
    });
    return (<Dialog size="sm" onClose={onClose}>
      <DialogHeader media={<AvatarWithLabel size="xl" shape="square" appearance="danger" title={title}>
            <Falcon icon="exclamation-triangle"/>
          </AvatarWithLabel>}/>
      <DialogContent>
        <Grid directions="vertical" spacing={4}>
          <Column width="100%">
            <Trans>
              Scrive does not allow you to withdraw for one user. By continuing you will cancel the
              e-signing process for all parties.
            </Trans>
          </Column>
          <Column width="100%">
            <Checkbox checked={checked} onChange={handleChange} label={t `I want to cancel e-signing for all parties`}/>
          </Column>
        </Grid>
      </DialogContent>
      <DialogFooter>
        <Button type="button" appearance="secondary" onClick={onClose}>
          <Trans>Cancel</Trans>
        </Button>
        <Button type="submit" disabled={!checked} onClick={handleSubmit}>
          <Trans>Confirm</Trans>
        </Button>
      </DialogFooter>
    </Dialog>);
}
