import { t } from '@lingui/macro';
export const getLabels = (searchQuery, templatesFilter) => {
    if (searchQuery.length > 0) {
        return {
            title: searchQuery,
            description: t({
                comment: 'Select template dialog - Search active description',
                message: 'Templates based off your search results',
            }),
        };
    }
    if (templatesFilter === 'templates:all') {
        return {
            title: t({
                comment: 'Select template dialog - Browse all templates title',
                message: 'Browse all templates',
            }),
            description: t({
                comment: 'Select template dialog - Browse all templates description',
                message: 'Go through all templates in Pocketlaw',
            }),
        };
    }
    if (templatesFilter === 'templates:included') {
        return {
            title: t({
                comment: 'Select template dialog - Browse templates included in plan title',
                message: 'Browse available templates',
            }),
            description: t({
                comment: 'Select template dialog - Browse templates included in plan description',
                message: 'Templates included in your plan',
            }),
        };
    }
    return {
        title: t({
            comment: 'Select template dialog - Browse company templates title',
            message: "My company's templates",
        }),
        description: t({
            comment: 'Select template dialog - Browse company templates description',
            message: 'Custom templates created by your company',
        }),
    };
};
