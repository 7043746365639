import { useRef } from 'react';
import { t } from '@lingui/macro';
import { Box, ChipAction } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useField } from 'formik';
import { ChipDropdown } from 'shared/domains/common-ui';
import { TextField } from 'shared/domains/forms';
import { allowedValuesTranslations } from './translations';
import { SelectDataPoint } from '../SelectDataPoint';
import { FieldInputContainer } from '../styled';
export function TopHitsInput(props) {
    const { pathBase, remove } = props;
    const currentPath = `${pathBase}.topHitsInput`;
    const [field] = useField(currentPath);
    const configurationDropdownRef = useRef();
    const { orderBy, size } = field.value;
    const [selectedFieldKey] = Object.keys((orderBy === null || orderBy === void 0 ? void 0 : orderBy[0]) || {});
    return (<FieldInputContainer>
      <SelectDataPoint label={t({ message: 'Top hits', comment: 'Insights widget form: Field' })} selectedValue={allowedValuesTranslations(selectedFieldKey)} name={pathBase} action={<ChipAction onClick={remove}>
            <Falcon icon="close"/>
          </ChipAction>}/>

      <ChipDropdown label={t({ message: 'Config', comment: 'Insights: Top hits configuration' })} ref={configurationDropdownRef} secondary={size} appearance="secondary" variant="outline" dropdownContent={<Box flexDirection="column" p="md" pt="0">
            <TextField name={`${currentPath}.size`} label="Size" type="number"/>
          </Box>}/>
    </FieldInputContainer>);
}
