import { matchPath } from 'react-router-dom';
const publicRoutes = [
    '/:market?/signup',
    '/:market?/invite/:id',
    '/:market?/logout',
    '/:market?/company-creation',
    '/:market?/signed-out',
];
/**
 * Check if the current URL is public (not requiring auth)
 */
export function isPublicRoute() {
    const match = matchPath(window.location.pathname, { path: publicRoutes });
    return Boolean(match);
}
