import { t } from '@lingui/macro';
import { TableRow, TableBodyCell, AvatarWithLabel } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Container } from './styled';
export function EmptyComposer(props) {
    const { totalComposers } = props;
    if (totalComposers > 0) {
        return null;
    }
    return (<TableRow>
      <TableBodyCell colSpan={2} headers="composer-select">
        <Container>
          <AvatarWithLabel title={t({
            message: 'We could not find any templates.',
            comment: 'user invite modal - no composer title',
        })} alignment="vertical">
            <Falcon icon="inbox"/>
          </AvatarWithLabel>
        </Container>
      </TableBodyCell>
    </TableRow>);
}
