import { Typography } from '@pocketlaw/tetris';
import { Container } from './styled';
export function MetadataEntry(props) {
    const { label, component } = props;
    if (!component) {
        return null;
    }
    return (<Container>
      <Typography $appearance="300" $fontSize="medium" $fontWeight="semibold">
        {label}
      </Typography>
      <Typography $appearance="200" $fontSize="small">
        {component}
      </Typography>
    </Container>);
}
