import { t } from '@lingui/macro';
import { CcsDocumentFlushReason } from 'shared/domains/apollo/generated/types';
export const useReasonTexts = (reason, userName) => {
    switch (reason) {
        case CcsDocumentFlushReason.Converted:
            return {
                modalTitle: t({
                    comment: 'RTC Document flushed - Reason: Converted - Dialog title',
                    message: 'Document converted to free-text',
                }),
                modalContent: userName
                    ? t({
                        comment: 'RTC Document flushed - Reason: Converted - Dialog content with user name',
                        message: `The document has been converted to free-text by ${userName}`,
                    })
                    : t({
                        comment: 'RTC Document flushed - Reason: Converted - Dialog content without user name',
                        message: 'The document has been converted to free-text',
                    }),
                buttonLabel: t({
                    comment: 'RTC Document flushed - Reason: Converted - Dialog button label',
                    message: 'Reload document',
                }),
            };
        case CcsDocumentFlushReason.Deleted:
            return {
                modalTitle: t({
                    comment: 'RTC Document flushed - Reason: Deleted - Dialog title',
                    message: 'Document deleted',
                }),
                modalContent: userName
                    ? t({
                        comment: 'RTC Document flushed - Reason: Deleted - Dialog content with user name',
                        message: `The document has been deleted by ${userName}`,
                    })
                    : t({
                        comment: 'RTC Document flushed - Reason: Deleted - Dialog content without user name',
                        message: 'The document has been deleted',
                    }),
                buttonLabel: t({
                    comment: 'RTC Document flushed - Reason: Deleted - Dialog button label',
                    message: 'Go back',
                }),
            };
        case CcsDocumentFlushReason.Locked:
            return {
                modalTitle: t({
                    comment: 'RTC Document flushed - Reason: Locked - Dialog title',
                    message: 'Document locked',
                }),
                modalContent: userName
                    ? t({
                        comment: 'RTC Document flushed - Reason: Locked - Dialog content with user name',
                        message: `The document has been locked by ${userName}`,
                    })
                    : t({
                        comment: 'RTC Document flushed - Reason: Locked - Dialog content without user name',
                        message: 'The document has been locked',
                    }),
                buttonLabel: t({
                    comment: 'RTC Document flushed - Reason: Locked - Dialog button label',
                    message: 'Close',
                }),
            };
        case CcsDocumentFlushReason.Unlocked:
            return {
                modalTitle: t({
                    comment: 'RTC Document flushed - Reason: Unlocked - Dialog title',
                    message: 'Document unlocked',
                }),
                modalContent: userName
                    ? t({
                        comment: 'RTC Document flushed - Reason: Unlocked - Dialog content with user name',
                        message: `The document has been unlocked by ${userName}`,
                    })
                    : t({
                        comment: 'RTC Document flushed - Reason: Unlocked - Dialog content without user name',
                        message: 'The document has been unlocked',
                    }),
                buttonLabel: t({
                    comment: 'RTC Document flushed - Reason: Unlocked - Dialog button label',
                    message: 'Close',
                }),
            };
        case CcsDocumentFlushReason.SigningStatusPending:
            return {
                modalTitle: t({
                    comment: 'RTC Document flushed - Reason: Signing status pending - Dialog title',
                    message: 'Document sent for eSigning',
                }),
                modalContent: userName
                    ? t({
                        comment: 'RTC Document flushed - Reason: Signing status pending - Dialog content with user name',
                        message: `The document has been sent for eSigning by ${userName}`,
                    })
                    : t({
                        comment: 'RTC Document flushed - Reason: Signing status pending - Dialog content without user name',
                        message: 'The document has been sent for eSigning',
                    }),
                buttonLabel: t({
                    comment: 'RTC Document flushed - Reason: Signing status pending - Dialog button label',
                    message: 'Close',
                }),
            };
        case CcsDocumentFlushReason.SigningStatusCancelled:
            return {
                modalTitle: t({
                    comment: 'RTC Document flushed - Reason: Signing status cancelled - Dialog title',
                    message: 'Document eSigning cancelled',
                }),
                modalContent: userName
                    ? t({
                        comment: 'RTC Document flushed - Reason: Signing status cancelled - Dialog content with user name',
                        message: `The document eSigning has been cancelled by ${userName}`,
                    })
                    : t({
                        comment: 'RTC Document flushed - Reason: Signing status cancelled - Dialog content without user name',
                        message: 'The document eSigning has been cancelled',
                    }),
                buttonLabel: t({
                    comment: 'RTC Document flushed - Reason: Signing status cancelled - Dialog button label',
                    message: 'Close',
                }),
            };
        case CcsDocumentFlushReason.SigningStatusRejected:
            return {
                modalTitle: t({
                    comment: 'RTC Document flushed - Reason: Signing status rejected - Dialog title',
                    message: 'Document eSigning declined',
                }),
                modalContent: t({
                    comment: 'RTC Document flushed - Reason: Signing status rejected - Dialog content',
                    message: `The document eSigning has been declined`,
                }),
                buttonLabel: t({
                    comment: 'RTC Document flushed - Reason: Signing status rejected - Dialog button label',
                    message: 'Close',
                }),
            };
        case CcsDocumentFlushReason.EditingWithTemplate:
            return {
                modalTitle: t({
                    comment: 'RTC Document flushed - Reason: Editing with template - Dialog title',
                    message: 'Editing with template started',
                }),
                modalContent: userName
                    ? t({
                        comment: 'RTC Document flushed - Reason: Editing with template - Dialog content with user name',
                        message: `${userName} has started editing the document with the template editor.`,
                    })
                    : t({
                        comment: 'RTC Document flushed - Reason: Editing with template - Dialog content without user name',
                        message: 'Someone has started editing the document with the template editor.',
                    }),
                buttonLabel: t({
                    comment: 'RTC Document flushed - Reason: Editing with template - Dialog button label',
                    message: 'Go back',
                }),
            };
        case CcsDocumentFlushReason.NewBundleVersion:
            return {
                modalTitle: t({
                    comment: 'RTC Document flushed - Reason: New editor bundle deployed - Dialog title',
                    message: 'New and improved text editor',
                }),
                modalContent: t({
                    comment: 'RTC Document flushed - Reason: New editor bundle deployed - Dialog content without user name',
                    message: "A new and improved text editor is now available which requires us to reload the page. Don't worry, your changes are saved.",
                }),
                buttonLabel: t({
                    comment: 'RTC Document flushed - Reason: New editor bundle deployed - - Dialog button label',
                    message: 'Reload page',
                }),
            };
        default:
            return null;
    }
};
