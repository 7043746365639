import { gql } from '@apollo/client';
export const QUERY = gql `
  query documentApproval($documentId: ID!) {
    document: fileSystemDocument(id: $documentId) {
      id
      approval {
        id
        type
      }
    }
  }
`;
