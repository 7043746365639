import { useEffect, useState } from 'react';
import { usePreview } from '../../../../../hooks/usePreview';
import { Logo } from './styled';
function CompanyLogo(props) {
    const { sidebar, container, editorLoaded } = props;
    const { company, isFreeText, isComposed } = usePreview();
    const { logoPath, logoUploaded } = company || {};
    const [paperX, setPaperX] = useState(0);
    useEffect(() => {
        function setPaperXPosition(paper) {
            setPaperX((paper === null || paper === void 0 ? void 0 : paper.getBoundingClientRect().x) || 0);
        }
        function setLogoPosition() {
            const paper = window.document.querySelector('.ck-content');
            const resizeObserver = new ResizeObserver(() => {
                setPaperXPosition(paper);
            });
            const resizeContainerObserver = new ResizeObserver(() => {
                setPaperXPosition(paper);
            });
            if (sidebar) {
                resizeObserver.observe(sidebar);
            }
            if (container) {
                resizeContainerObserver.observe(container);
            }
            setPaperXPosition(paper);
        }
        if (editorLoaded) {
            setLogoPosition();
        }
        window.addEventListener('resize', setLogoPosition);
        return () => {
            window.removeEventListener('resize', setLogoPosition);
        };
    }, [sidebar, container, editorLoaded]);
    const displayLogo = Boolean(logoUploaded && logoPath && (isFreeText || isComposed));
    const src = `${process.env.CDN_URL}/${logoPath}`;
    if (!displayLogo || paperX === 0) {
        return null;
    }
    return <Logo $left={paperX} src={src}/>;
}
export { CompanyLogo };
