var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { i18n } from '@lingui/core';
import * as Sentry from '@sentry/browser';
const isLocalOrPreview = process.env.APP_ENV === 'local' || process.env.APP_ENV === 'preview';
export function loadTranslations(locale) {
    return __awaiter(this, void 0, void 0, function* () {
        // Use pseudo locale in local and preview environments, so you don't have to download translations externally
        const localeCode = isLocalOrPreview && locale.code !== 'en-gb' ? 'pseudo-LOCALE' : locale.code;
        try {
            const { messages } = yield import(`../../../../../locales/${localeCode}/messages.po`);
            i18n.loadAndActivate({ messages, locale: localeCode });
        }
        catch (error) {
            // Make sure we activate an empty locale if we fail to load translations
            i18n.loadAndActivate({ messages: {}, locale: localeCode });
            Sentry.captureMessage(`Failed to load translations for locale "${localeCode}". Lingui will fallback to ids.`);
        }
    });
}
