import { t } from '@lingui/macro';
import { SearchAggregationsAllowedPropertiesForDateRangeAggregation as AllowedProperties } from 'shared/domains/apollo/generated/types';
export function allowedPropertiesTranslations(key) {
    const translations = {
        [AllowedProperties.CreatedAt]: t({
            message: 'Document created date',
            comment: 'Insights: Date Range allowed input: CreatedAt',
        }),
        [AllowedProperties.MetadataDocumentDateSigned]: t({
            message: 'Signed date',
            comment: 'Insights: Date Range allowed input: MetadataDocumentDateSigned',
        }),
        [AllowedProperties.MetadataDocumentNoticeDate]: t({
            message: 'Notice date',
            comment: 'Insights: Date Range allowed input: MetadataDocumentNoticeDate',
        }),
        [AllowedProperties.MetadataDocumentProbationaryPeriodExpirationDate]: t({
            message: 'Probationary period expiration date',
            comment: 'Insights: Date Range allowed input: MetadataDocumentProbationaryPeriodExpirationDate',
        }),
        [AllowedProperties.MetadataDocumentStartDate]: t({
            message: 'Start date',
            comment: 'Insights: Date Range allowed input: MetadataDocumentStartDate',
        }),
        [AllowedProperties.MetadataDocumentTerminationDate]: t({
            message: 'Termination date',
            comment: 'Insights: Date Range allowed input: MetadataDocumentTerminationDate',
        }),
        [AllowedProperties.UpdatedAt]: t({
            message: 'Document updated at',
            comment: 'Insights: Date Range allowed input: UpdatedAt',
        }),
    };
    return translations[key] || '';
}
