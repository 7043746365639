import { Trans } from '@lingui/macro';
import { Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { localizedFormatDate } from 'shared/domains/common-utils';
import { PreviewText } from '../PreviewText';
export function DueDate(props) {
    const { dueDate } = props;
    const formatted = localizedFormatDate(dueDate, {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    });
    return (<PreviewText empty={!formatted} icon={<Falcon icon="calendar"/>}>
      {formatted ? (<Trans comment="tasks preview modal due date field - When task is due">
          Due on <Typography $appearance="100">{formatted}</Typography>
        </Trans>) : (<Trans comment="tasks preview modal due date field - When task has no due date">
          Due date <Typography $appearance="100">None</Typography>
        </Trans>)}
    </PreviewText>);
}
