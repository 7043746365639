import { Trans, t } from '@lingui/macro';
import { Typography } from '@pocketlaw/tetris';
import { UserRole } from '../UserRole';
import { Container, StyledDl } from './styled';
export function DropDownContent(props) {
    const { id, role, isSelf, createdAt } = props;
    return (<Container>
      <UserRole role={role} id={id} isSelf={isSelf} selectLabel={t({
            message: 'User role',
            comment: 'users table - dropdown content role label',
        })}/>
      <StyledDl>
        <dt>
          <Typography $appearance="300" $fontSize="small">
            <Trans comment="users table - dropdown content date label">Date added</Trans>
          </Typography>
        </dt>
        <dd>
          <Typography $appearance="200" $fontSize="tiny">
            {createdAt}
          </Typography>
        </dd>
      </StyledDl>
    </Container>);
}
