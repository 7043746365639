import { useMutation, isApolloError } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import * as Sentry from 'shared/domains/sentry';
import { ChatWithDocumentDocument } from './ChatWithDocument.gql';
import { useGetAvailableAssistantCredits } from '../useGetAvailableAssistantCredits';
import { DocumentAssistantChatErrorSubcodes } from './types';
export function useChatWithDocument() {
    const { addToast } = useToast();
    const { refetch: refetchCredits } = useGetAvailableAssistantCredits();
    const [mutate, state] = useMutation(ChatWithDocumentDocument);
    const handler = ({ data, selection, type, chatId, message }) => mutate({
        variables: { data, selection, type, chatId, message },
    }).catch(mutationError => {
        var _a;
        if (isApolloError(mutationError)) {
            const subcode = (_a = mutationError.graphQLErrors[0]) === null || _a === void 0 ? void 0 : _a.extensions.subcode;
            if (subcode === DocumentAssistantChatErrorSubcodes.NoCredit) {
                refetchCredits();
                return addToast({
                    appearance: 'danger',
                    title: t({
                        message: 'No assitant credit left',
                        comment: 'Message for when the company ran out of assistant credit',
                    }),
                });
            }
        }
        Sentry.captureException(mutationError);
        return addToast({
            appearance: 'danger',
            title: t({
                message: 'Could not send message',
                comment: 'Message for when the document assistant could not sent the message',
            }),
        });
    });
    return [handler, state];
}
