import { useState } from 'react';
import { t } from '@lingui/macro';
import { Calendar, Menu, MenuBody, defaultFloatingPositionMiddleware } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useField } from 'formik';
import { localizedFormatDate } from 'shared/domains/common-utils';
import { ErrorMessage } from 'shared/domains/forms/components/ErrorMessage';
import { localeConfig } from 'shared/domains/languages/config';
import { safeCalendarDate } from '../../utils/safeCalendarDate';
import { LinkField } from '../LinkField';
import { Container } from './styled';
function DueDateField() {
    const localeCode = localeConfig.getActiveLocale().code;
    const [open, setOpen] = useState(false);
    const name = 'dueDate';
    const [dueDateField, , helpers] = useField(name);
    const TODAYS_DATE = new Date();
    const formatted = localizedFormatDate(dueDateField.value, {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    });
    const handleOpen = () => {
        setOpen(!open);
    };
    const handleOnChange = (date) => {
        const isoDate = date.toISOString();
        helpers.setValue(isoDate);
        handleOpen();
    };
    const handleRemoveDueDate = () => {
        helpers.setValue('');
        setOpen(false);
    };
    return (<Container>
      <Menu onClose={() => setOpen(false)} open={open} options={{
            middleware: [...defaultFloatingPositionMiddleware],
        }} target={<LinkField name={name} icon={<Falcon icon="calendar"/>} linkText={formatted ||
                t({
                    message: 'Add due date',
                    comment: 'task due date field link text when no due date selected',
                })} linkPrefix={t({
                message: 'Due on',
                comment: 'task due date field link text when due date selected',
            })} onClick={handleOpen} onRemove={handleRemoveDueDate}/>}>
        <MenuBody>
          <Calendar locale={localeCode} value={safeCalendarDate(dueDateField.value)} min={TODAYS_DATE} onChange={handleOnChange}/>
        </MenuBody>
      </Menu>
      <ErrorMessage name="dueDate"/>
    </Container>);
}
export { DueDateField };
