import { Trans } from '@lingui/macro';
import { AiBorderStackingOrderGuard, Button } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { usePlaiDrawer } from 'app/domains/documents';
export function FollowUp(props) {
    const { feedbackId } = props;
    const { onSelectFeedback } = usePlaiDrawer();
    return (<AiBorderStackingOrderGuard guard>
      <Button size="small" appearance="ai" onClick={() => onSelectFeedback(feedbackId)}>
        <Falcon icon="sparkles"/>
        <Trans comment="Plai Review - Issue description - iterate button - label">Iterate</Trans>
      </Button>
    </AiBorderStackingOrderGuard>);
}
