import { gql } from '@apollo/client';
export const updateSignRequestSignatoryMutation = gql `
  mutation updateSignRequestSignatory(
    $signRequestId: UUID!
    $signatoryId: UUID!
    $email: String
    $firstName: String
    $lastName: String
    $label: String
    $title: String
    $mobile: String
    $message: String
    $authenticationMode: EsigningProviderAuthenticationModeSlug
  ) {
    updateSignRequestSignatory(
      sign_request_id: $signRequestId
      id: $signatoryId
      input: {
        email: $email
        firstName: $firstName
        lastName: $lastName
        label: $label
        title: $title
        mobile: $mobile
        message: $message
        authenticationMode: $authenticationMode
      }
    ) {
      id
      email
      firstName
      lastName
      label
      title
      mobile
      message
      authenticationMode
    }
  }
`;
