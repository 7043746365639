import { useQuery } from '@apollo/client';
import { useLingui } from '@lingui/react';
import { accountsQuery } from './query';
import { createSort } from './utils/createSort';
export function useAccounts(options) {
    const { data } = useQuery(accountsQuery, Object.assign(Object.assign({}, options), { fetchPolicy: 'cache-only' }));
    const accounts = (data === null || data === void 0 ? void 0 : data.accounts) || [];
    const { i18n } = useLingui();
    const { locale } = i18n;
    const sort = createSort(locale);
    return accounts.slice(0).sort((a, b) => sort(a.company.name, b.company.name));
}
