import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled, { css } from 'styled-components';
export const SuggestionBoxContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${themeGet('spacing.md')};
  padding: ${themeGet('spacing.lg')};
  border-radius: ${themeGet('borderRadius.md')};
  border: solid ${pxToRem(1)} ${themeGet('colors.border.100')};

  ${({ $highlighted }) => $highlighted &&
    css `
      border-color: ${themeGet('colors.assistant.200')};
      background-color: ${themeGet('colors.assistant.50')};
    `}
`;
export const MessageContainer = styled.div `
  ${({ $expanded }) => !$expanded &&
    css `
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;
