import { useState } from 'react';
import { Context } from './TeamsContext';
import { useTeams } from '../../hooks/useTeams';
export function TeamsProvider(props) {
    const { children } = props;
    const [sort, setSort] = useState({ id: 'name', order: 'asc' });
    const [search, setSearch] = useState('');
    const [searching, setSearching] = useState(false);
    const { teams, loading, error, initialLoading } = useTeams({ sort, search });
    if (searching && !loading) {
        setSearching(false);
    }
    const handleSearch = (value) => {
        setSearch(value);
        setSearching(true);
    };
    const noSearchResults = Boolean(search) && teams.length < 1;
    const value = {
        teams,
        sort,
        setSort,
        loading: initialLoading,
        error,
        searching,
        setSearch: handleSearch,
        noSearchResults,
    };
    return <Context.Provider value={value}>{children}</Context.Provider>;
}
