import { breakpoints, unit } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  align-items: center;
  gap: ${unit('md')};

  &:empty {
    display: none;
  }

  ${breakpoints.xs} {
    flex-wrap: wrap;
    padding: 0 ${unit('xl')} ${unit('xl')} ${unit('xl')};
  }
`;
