import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { pollCompanyESigningProvidersQuery } from './query';
const ONE_SECOND = 1000;
const POLL_INTERVAL = 1.5 * ONE_SECOND;
export const usePollCompanyEsigningProviders = (enabled = false) => {
    var _a;
    const account = useAccount();
    const companyId = (_a = account.data) === null || _a === void 0 ? void 0 : _a.companyId;
    const query = useQuery(pollCompanyESigningProvidersQuery, {
        skip: !enabled,
        variables: { companyId },
    });
    useEffect(() => {
        if (enabled) {
            query.startPolling(POLL_INTERVAL);
        }
        return query.stopPolling;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enabled]);
    return query;
};
