import { useLayoutEffect, useRef, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Box, Button, Chip, IconButton, Tooltip, Typography, useToast } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { usePlai } from 'app/domains/review/components/PlaiProvider';
import { SuggestionBoxContainer, MessageContainer } from './styled';
export function SuggestionBox(props) {
    const { highlight, writing } = props;
    const { id: highlightId, title, suggestion, type: suggestionType } = highlight;
    const { addToast } = useToast();
    const { isRedlineAvailable, feedbackHighlightsData, activeDocumentHighlight, updateActiveDocumentHighlight, } = usePlai();
    const [suggestionAdded, setSuggestionAdded] = useState(false);
    const [messageOverflow, setMessageOverflow] = useState(true);
    const [expanded, setExpanded] = useState(false);
    const { documentHighlights = [] } = feedbackHighlightsData || {};
    const messageContainerRef = useRef(null);
    const matchingDocumentHighlight = documentHighlights.find(h => h.id === highlightId);
    const isHighlighted = (activeDocumentHighlight === null || activeDocumentHighlight === void 0 ? void 0 : activeDocumentHighlight.id) === (matchingDocumentHighlight === null || matchingDocumentHighlight === void 0 ? void 0 : matchingDocumentHighlight.id);
    const suggestionButtonLabelMap = {
        prepend: t({
            comment: 'Review chat - message action - insert suggestion button label - prepend',
            message: 'Prepend suggestion',
        }),
        append: t({
            comment: 'Review chat - message action - insert suggestion button label - append',
            message: 'Append suggestion',
        }),
        modify: t({
            comment: 'Review chat - message action - insert suggestion button label - modify',
            message: 'Apply suggestion',
        }),
        remove: t({
            comment: 'Review chat - message action - insert suggestion button label - remove',
            message: 'Remove clause',
        }),
    };
    const handleRedlineSuggestion = () => {
        if (matchingDocumentHighlight) {
            matchingDocumentHighlight.addSuggestionToDoc(suggestion, suggestionType);
            setSuggestionAdded(true);
        }
    };
    const handleCopy = () => {
        navigator.clipboard.writeText(suggestion).then(() => addToast({
            appearance: 'success',
            title: t({
                message: 'Copied to clipboard!',
                comment: 'Review chat - assistant reply action - toast when reply has been copied to clipboard',
            }),
        }));
    };
    const handleToggleExpand = () => setExpanded(!expanded);
    const handleHighlightSuggestion = () => {
        if (matchingDocumentHighlight) {
            updateActiveDocumentHighlight(matchingDocumentHighlight);
        }
    };
    useLayoutEffect(() => {
        if (!expanded) {
            const observeOverflow = () => {
                const { offsetHeight = 0, scrollHeight = 0 } = messageContainerRef.current || {};
                setMessageOverflow(offsetHeight < scrollHeight);
            };
            observeOverflow();
            window.addEventListener('resize', observeOverflow);
            return () => window.removeEventListener('resize', observeOverflow);
        }
        return undefined;
    }, [expanded, highlight]);
    return (<SuggestionBoxContainer $highlighted={isHighlighted}>
      <Typography $appearance="300" $fontWeight="semibold">
        {title}
      </Typography>
      <MessageContainer ref={messageContainerRef} $expanded={expanded}>
        <Typography $appearance="200" $fontSize="small">
          {suggestion}
        </Typography>
      </MessageContainer>
      <Box alignItems="center" justifyContent="space-between">
        <Box gap="md">
          {suggestionAdded ? (<Chip appearance="success" icon={<Falcon icon="check"/>} label={t({
                comment: 'Review chat - message action - suggestion added chip label',
                message: 'Suggestion added',
            })}/>) : (<Tooltip position="top" disabled={isRedlineAvailable} title={t({
                comment: 'Review chat - message action - insert suggestion disabled tooltip',
                message: 'Insert suggestion is only available for free text documents',
            })}>
              <Button size="xs" appearance="primary" disabled={writing || !isRedlineAvailable} onClick={handleRedlineSuggestion}>
                <Falcon icon="comment-lines"/>
                {suggestionButtonLabelMap[suggestionType]}
              </Button>
            </Tooltip>)}
          <Tooltip position="top" title={t({
            comment: 'Review chat - message action - highlight suggestion in document tooltip',
            message: 'Highlight in document',
        })}>
            <IconButton size="xs" appearance="ghost" onClick={handleHighlightSuggestion}>
              <Falcon icon="highlighter"/>
            </IconButton>
          </Tooltip>
          <Button size="xs" appearance="ghost" onClick={handleCopy}>
            <Falcon icon="copy"/>
            <Trans comment="Review chat - message action - copy button label">Copy</Trans>
          </Button>
        </Box>
        {messageOverflow && (<Box>
            <Button size="xs" appearance="ghost" onClick={handleToggleExpand}>
              <Trans comment="Review chat - message action - show all button label">Show all</Trans>
              {expanded ? <Falcon icon="chevron-up"/> : <Falcon icon="chevron-down"/>}
            </Button>
          </Box>)}
      </Box>
    </SuggestionBoxContainer>);
}
