import { i18n } from '@lingui/core';
import { AvatarWithLabel } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { getAttachmentSubtitle } from 'shared/domains/documents';
import { AttachmentContainer } from './styled';
export function AttachmentItem(props) {
    const { attachment, endElement, isEmbedded = false } = props;
    const { name, createdAt, updatedAt } = attachment;
    const subtitle = getAttachmentSubtitle(createdAt, updatedAt);
    return (<AttachmentContainer $isEmbedded={isEmbedded}>
      <AvatarWithLabel solid size="sm" appearance="info" title={name} subtitle={i18n._(subtitle)}>
        <Falcon icon="paperclip"/>
      </AvatarWithLabel>
      {endElement}
    </AttachmentContainer>);
}
