import { useMutation } from '@apollo/client';
import { metadataUpdateCategory } from './mutation';
export function useUpdateCategory(documentId) {
    const [mutation, state] = useMutation(metadataUpdateCategory);
    const handleMutation = (categoryId) => mutation({
        variables: {
            documentId,
            categoryId,
        },
    });
    return Object.assign({ mutation: handleMutation }, state);
}
