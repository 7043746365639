import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGetTasks } from 'app/domains/tasks/hooks/useGetTasks';
import { TaskStatusEnumEnum } from 'shared/domains/apollo/generated/types';
import { TasksContext } from './TasksContext';
import { useURLFilters } from './useURLFilters';
import { SortIds, TabState } from '../types';
export function TasksProvider(props) {
    const { children, documentId, taskId } = props;
    const filters = useURLFilters();
    const history = useHistory();
    const filterObject = filters.fromUrl({
        status: [TaskStatusEnumEnum.Todo, TaskStatusEnumEnum.InProgress],
    });
    const [displayAdd, setDisplayAdd] = useState(false);
    const [activeTaskId, setActiveTaskId] = useState(taskId);
    const [isEditing, setIsEditing] = useState(false);
    const [activeFilter, setActiveFilter] = useState(TabState.UNCOMPLETED);
    const [tasksFilter, setTasksFilter] = useState(filterObject);
    const [sortBy, setSortBy] = useState({ sortId: SortIds.createdAt, sortOrder: 'desc' });
    const tasks = useGetTasks({ documentId, sortBy, filter: tasksFilter });
    const handleSetTasksFilter = (newFilters) => {
        const params = filters.toUrl(newFilters);
        history.replace({ search: params });
        setTasksFilter(newFilters);
    };
    const handleSelectTask = (selectedTaskId) => setActiveTaskId(selectedTaskId);
    const handleUnselectTask = () => setActiveTaskId(undefined);
    const handleEditTask = (editTaskId) => {
        if (editTaskId) {
            handleSelectTask(editTaskId);
        }
        setIsEditing(true);
    };
    const handleCancelEdit = () => setIsEditing(false);
    const activeTask = tasks.data.find(task => task.id === activeTaskId);
    const values = {
        documentId,
        activeTaskId,
        displayAdd,
        setDisplayAdd,
        activeTask,
        activeFilter,
        setActiveFilter,
        tasks,
        handleSelectTask,
        handleEditTask,
        handleCancelEdit,
        handleUnselectTask,
        isEditing,
        sortBy,
        setSortBy,
        setTasksFilter: handleSetTasksFilter,
        tasksFilter,
    };
    return <TasksContext.Provider value={values}>{children}</TasksContext.Provider>;
}
