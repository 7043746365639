import { Fragment } from 'react';
import { t } from '@lingui/macro';
import { TextInput } from '@pocketlaw/tetris';
import { useField } from 'formik';
import { useEsigning } from 'app/domains/esigning/components/EsigningProvider';
import { ErrorMessage } from 'shared/domains/forms/components/ErrorMessage';
export function EmailField() {
    const { submittingCreation } = useEsigning();
    const [field, meta] = useField('email');
    const error = Boolean(meta.touched && meta.error);
    return (<Fragment>
      <TextInput label={t `Email`} invalid={error} stretch {...field} disabled={submittingCreation}/>
      <ErrorMessage name="email"/>
    </Fragment>);
}
