import { useQuery } from '@apollo/client';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { AutoTagQueueDocument } from './AutoTagQueue.gql';
import { LIMIT, POLL_INTERVAL } from './constants';
import { useAutotagFeatureAvailability } from '../../../hooks/useAutotagFeatureAvailability';
export function useAutoTagNotifications() {
    var _a;
    const account = useAccount();
    const autotagFeature = useAutotagFeatureAvailability();
    const userId = (_a = account.data) === null || _a === void 0 ? void 0 : _a.userId;
    const skip = !autotagFeature.data || !userId;
    const { data, loading, error, startPolling, stopPolling } = useQuery(AutoTagQueueDocument, {
        skip,
        fetchPolicy: 'cache-and-network',
        variables: {
            userId: userId || '',
            limit: LIMIT,
        },
        notifyOnNetworkStatusChange: true,
        onCompleted(newData) {
            const { count } = newData || {};
            if (count > 0) {
                startPolling(POLL_INTERVAL);
            }
            else {
                stopPolling();
            }
        },
    });
    const { count, active, completed, failed } = data || {};
    const countWithCache = count || 0;
    return {
        data: {
            active: active || [],
            completed: completed || [],
            failed: failed || [],
            count: countWithCache,
        },
        loading,
        error,
    };
}
