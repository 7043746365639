import { fontWeight, unit, themeGet, Chip } from '@pocketlaw/tetris';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
export const Container = styled.div `
  padding-bottom: ${themeGet('spacing.lg')};
`;
export const StyledChip = styled(Chip) `
  width: fit-content;
`;
const gridStyles = css `
  display: grid;
  grid-template-columns: 8fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: ${unit(4)};
  grid-row-gap: 0;
`;
export const StyledLink = styled(Link) `
  ${gridStyles};
  position: relative;
  padding: ${themeGet('spacing.lg')};
  text-decoration: none;

  scroll-margin: ${themeGet('spacing.lg')};

  ${({ $highlighted }) => $highlighted &&
    css `
      background-color: ${themeGet('colors.gray.50')};
      border-radius: ${themeGet('borderRadius.md')};
    `}
`;
export const Highlight = styled.strong `
  ${fontWeight('semibold')};
`;
