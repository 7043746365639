import { Trans } from '@lingui/macro';
import { Typography, useTetrisTheme } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Container, SystemThemeBox, SystemDarkThemeBox, SystemDarkThemeContent, SystemLightThemeBox, SystemLightThemeContent, SystemThemeSelected, } from './styled';
export function SystemThemeButton(props) {
    const { selected, onClick } = props;
    const { themeType } = useTetrisTheme();
    return (<Container>
      <SystemThemeBox onClick={onClick}>
        <SystemDarkThemeBox $themeType={themeType}>
          <SystemDarkThemeContent $themeType={themeType}>Aa</SystemDarkThemeContent>
        </SystemDarkThemeBox>
        <SystemLightThemeBox $themeType={themeType}>
          <SystemLightThemeContent $themeType={themeType}>Aa</SystemLightThemeContent>
        </SystemLightThemeBox>
        {selected && (<SystemThemeSelected $themeType={themeType}>
            <Falcon icon="check" $size={12}/>
          </SystemThemeSelected>)}
      </SystemThemeBox>
      <Typography $fontSize="small" $appearance="100">
        <Trans comment="App theme selector - System theme label">System</Trans>
      </Typography>
    </Container>);
}
