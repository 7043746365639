import { Trans } from '@lingui/macro';
import { Link } from '@pocketlaw/tetris';
import { Link as RouterLink } from 'react-router-dom';
import { useGetLinks } from 'shared/domains/urls';
import { useAccount } from 'shared/domains/users';
import { PrivacySettingsLink } from '../PrivacySettingsLink';
import { UserAvatarWithLabel } from '../UserAvatarWithLabel';
import { Divider, AccountInfoSectionLinkContainer, AccountInfoSectionContainer } from './styled';
export function AccountInfoSection() {
    var _a, _b;
    const account = useAccount();
    const userName = ((_a = account.data.profile) === null || _a === void 0 ? void 0 : _a.name) || '';
    const userCompany = (_b = account.data.company) === null || _b === void 0 ? void 0 : _b.name;
    const { contact } = useGetLinks();
    return (<AccountInfoSectionContainer>
      <UserAvatarWithLabel size="md" title={userName} subtitle={userCompany}/>
      <AccountInfoSectionLinkContainer>
        <PrivacySettingsLink />
        <Link as={RouterLink} to="/logout">
          <Trans comment="User account menu: Sign out link text">Sign out</Trans>
        </Link>
        <Link href={contact}>
          <Trans comment="User account menu: Contact support link text">Contact support</Trans>
        </Link>
      </AccountInfoSectionLinkContainer>
      <Divider />
    </AccountInfoSectionContainer>);
}
