export const initialState = {
    loading: false,
    called: false,
    data: null,
    error: false,
};
export function reducer(state, action) {
    switch (action.type) {
        case 'send':
            return Object.assign(Object.assign({}, state), { called: true, loading: true });
        case 'data':
            return Object.assign(Object.assign({}, state), { loading: false, error: false, data: action.payload });
        case 'error':
            return Object.assign(Object.assign({}, state), { loading: false, error: action.payload });
        default:
            throw new TypeError(`Unexpected action type: ${action.type}`);
    }
}
