import styled from 'styled-components';
export const TextContainer = styled.div `
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const AlignRight = styled.div `
  display: flex;
  align-items: center;
  margin-left: auto;
`;
