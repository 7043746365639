import { useQuery } from '@apollo/client';
import { fileSystemAclsQuery } from './fileSystemAclsQuery';
import { useNormalizeTeam } from './useNormalizeTeam';
import { useNormalizeUser } from './useNormalizeUser';
import { useTeamAccess } from './useTeamAccess';
import { useUserAccess } from './useUserAccess';
/**
 * returns a list of users and teams with and without access
 * owners don't have aclIds but are still counted as users "with access", and their access cannot be removed
 * if "entryId" is not provided, the query will return all owners as usersWithAccess
 */
export function useFileSystemAcls(options) {
    const { entryId, parentEntryId } = options || {};
    const { data: result, refetch, loading, error, previousData, called, } = useQuery(fileSystemAclsQuery, {
        variables: {
            entryId: entryId || '',
            hasEntryId: Boolean(entryId),
            parentEntryId: parentEntryId || '',
            hasParentEntryId: Boolean(parentEntryId),
        },
    });
    const getParentAcls = () => {
        var _a, _b, _c, _d, _e, _f;
        if (entryId) {
            return {
                parentUserAcls: ((_b = (_a = result === null || result === void 0 ? void 0 : result.fileSystemEntry) === null || _a === void 0 ? void 0 : _a.parent) === null || _b === void 0 ? void 0 : _b.userAcls) || [],
                parentTeamAcls: ((_d = (_c = result === null || result === void 0 ? void 0 : result.fileSystemEntry) === null || _c === void 0 ? void 0 : _c.parent) === null || _d === void 0 ? void 0 : _d.teamAcls) || [],
            };
        }
        return {
            parentUserAcls: ((_e = result === null || result === void 0 ? void 0 : result.parentEntry) === null || _e === void 0 ? void 0 : _e.userAcls) || [],
            parentTeamAcls: ((_f = result === null || result === void 0 ? void 0 : result.parentEntry) === null || _f === void 0 ? void 0 : _f.teamAcls) || [],
        };
    };
    const data = called && loading ? previousData : result;
    const { fileSystemEntry, companyUsers = [], teams = [] } = data !== null && data !== void 0 ? data : {};
    const { name: entryName, userAcls = [], teamAcls = [] } = fileSystemEntry !== null && fileSystemEntry !== void 0 ? fileSystemEntry : {};
    const { parentUserAcls, parentTeamAcls } = getParentAcls();
    const { normalizeUser } = useNormalizeUser({ userAcls, parentUserAcls });
    const { normalizeTeam } = useNormalizeTeam({ teamAcls, parentTeamAcls });
    const { userHasAccess } = useUserAccess({ userAcls, parentUserAcls });
    const { teamHasAccess } = useTeamAccess({ teamAcls, parentTeamAcls });
    const usersWithAccess = companyUsers
        .filter(companyUser => userHasAccess(companyUser))
        .map(normalizeUser);
    const usersWithoutAccess = companyUsers
        .filter(companyUser => !userHasAccess(companyUser))
        .map(normalizeUser);
    const teamsWithAccess = teams.filter(team => teamHasAccess(team)).map(normalizeTeam);
    const teamsWithoutAccess = teams.filter(team => !teamHasAccess(team)).map(normalizeTeam);
    return {
        refetch,
        entryName,
        usersWithAccess,
        teamsWithAccess,
        usersWithoutAccess,
        teamsWithoutAccess,
        loading,
        error,
    };
}
