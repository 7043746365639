import { Fragment } from 'react';
import { Trans } from '@lingui/macro';
import { Breadcrumb, Breadcrumbs, PageHeader } from '@pocketlaw/tetris';
import { Link } from 'react-router-dom';
import { MainContent } from 'app/domains/common-ui/components/PageLayout';
import { TeamMembersTable, DeleteTeamButton } from 'app/domains/teams';
import { AddTeamMembersButton } from '../AddTeamMembersButton';
import { EditTeamButton } from '../EditTeamButton';
import { TeamProfileCard } from '../TeamProfileCard';
import { Container, CardContainer, TableContainer } from './styled';
export function TeamProfilePage(props) {
    const { teamId } = props;
    return (<Fragment>
      <PageHeader breadcrumbs={<Breadcrumbs>
            <Breadcrumb as={Link} to="/dashboard">
              <Trans comment="Breadcrumb pointing to /dashboard">Pocketlaw</Trans>
            </Breadcrumb>
            <Breadcrumb as={Link} to="/users-teams?tab=teams">
              <Trans comment="users & teams breadcrumb">Users & teams</Trans>
            </Breadcrumb>
            <Breadcrumb>
              <Trans comment="Team profile breadcrumb">Team profile</Trans>
            </Breadcrumb>
          </Breadcrumbs>} heading={<Trans comment="Team profile page header">Team profile</Trans>} actions={<Fragment>
            <AddTeamMembersButton teamId={teamId}/>
            <EditTeamButton teamId={teamId}/>
            <DeleteTeamButton teamId={teamId}/>
          </Fragment>}/>
      <MainContent>
        <Container>
          <CardContainer>
            <TeamProfileCard teamId={teamId}/>
          </CardContainer>
          <TableContainer>
            <TeamMembersTable teamId={teamId}/>
          </TableContainer>
        </Container>
      </MainContent>
    </Fragment>);
}
