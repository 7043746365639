import { Box, Breadcrumbs, Breadcrumb } from '@pocketlaw/tetris';
import { useFileBrowserContext } from 'app/domains/filesystem';
export function PickerBreadcrumbs() {
    const { breadcrumbs, loading, onSelect } = useFileBrowserContext();
    const handleClick = (crumb) => {
        if (loading) {
            return;
        }
        onSelect(crumb);
    };
    return (<Box p="2xl" pt="0">
      <Breadcrumbs>
        {breadcrumbs.map(crumb => (<Breadcrumb key={crumb.id} onClick={() => handleClick(crumb)}>
            {crumb.name}
          </Breadcrumb>))}
      </Breadcrumbs>
    </Box>);
}
