import { Trans, t } from '@lingui/macro';
import { Falcon } from '@pocketlaw/tetris/macro';
import { AssistantPromptButton, useAssistant } from 'app/domains/assistant';
import { ChatMessageType } from 'shared/domains/apollo/generated/types';
import { Section } from '../Section';
export function SelectionPrompts() {
    const { addComment, selectedText } = useAssistant();
    const commonSelectionPromptButtonProps = {
        disabled: !selectedText,
        tooltip: !selectedText
            ? t({
                comment: 'Document Assistant Chat - Tooltip message when selection prompts are disabled',
                message: 'You need to select text in your document to enable this action.',
            })
            : undefined,
    };
    return (<Section>
      <AssistantPromptButton title={<Trans comment="Document Assistant predefined prompts - translate text">
            Suggest clause
          </Trans>} leftIcon={<Falcon icon="comment"/>} onClick={() => addComment({
            type: ChatMessageType.SelectionSuggest,
        })} {...commonSelectionPromptButtonProps}/>
      <AssistantPromptButton title={<Trans comment="Document Assistant predefined prompts - translate text">Translate</Trans>} leftIcon={<Falcon icon="globe"/>} onClick={() => addComment({
            type: ChatMessageType.SelectionTranslate,
        })} {...commonSelectionPromptButtonProps}/>
      <AssistantPromptButton title={<Trans comment="Document Assistant predefined prompts - explain text">Explain</Trans>} leftIcon={<Falcon icon="lightbulb"/>} onClick={() => addComment({
            type: ChatMessageType.SelectionExplain,
        })} {...commonSelectionPromptButtonProps}/>
      <AssistantPromptButton title={<Trans comment="Document Assistant predefined prompts - rewrite text">
            Alternative wording
          </Trans>} leftIcon={<Falcon icon="pen-nib"/>} onClick={() => addComment({
            type: ChatMessageType.SelectionRewrite,
        })} {...commonSelectionPromptButtonProps}/>

      <AssistantPromptButton title={<Trans comment="Document Assistant predefined prompts - summarize text">Summarize</Trans>} leftIcon={<Falcon icon="bolt"/>} onClick={() => addComment({
            type: ChatMessageType.SelectionSummarize,
        })} {...commonSelectionPromptButtonProps}/>
    </Section>);
}
