import { Fragment, useRef, useState } from 'react';
import { Trans } from '@lingui/macro';
import { Avatar, Button, Dialog, DialogContent, DialogFooter, DialogHeader, Menu, MenuBody, MenuItem, MenuList, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { AssistantPromptButton, useAssistant } from 'app/domains/assistant';
import { FileBrowser } from 'app/domains/filesystem';
import { FileUpload } from 'app/domains/upload';
import { ChatMessageType, DocumentType } from 'shared/domains/apollo/generated/types';
import { useActiveDocument } from 'shared/domains/documents';
import { useFeatureFlag } from 'shared/domains/featureFlag';
import { MimeTypesByExtension } from 'shared/domains/filesystem/utils/fileExtensions';
const SupportedMimeTypes = [MimeTypesByExtension.Docx, MimeTypesByExtension.Pdf];
export function DocumentComparePromptButton(props) {
    const { setError } = props;
    const { id } = useActiveDocument();
    const { addComment } = useAssistant();
    const fileUploadRef = useRef(null);
    const [selected, setSelected] = useState();
    const [showBrowser, setShowBrowser] = useState(false);
    const [open, setOpen] = useState(false);
    const isCompareDocumentsUploadEnabled = useFeatureFlag('feature_compare_documents_upload');
    const handleClose = () => setOpen(false);
    const handleToggle = () => setOpen(!open);
    const handleShowBrowser = () => {
        setOpen(false);
        setShowBrowser(true);
    };
    const handleHideBrowser = () => setShowBrowser(false);
    const handleSelectFile = () => {
        var _a;
        setOpen(false);
        (_a = fileUploadRef.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    const handleChange = (event) => {
        var _a, _b;
        const filesToUpload = (_b = (_a = event.target) === null || _a === void 0 ? void 0 : _a.files) !== null && _b !== void 0 ? _b : [];
        const file = filesToUpload[0];
        if (!file) {
            setError('NO_FILE_PROVIDED');
            return;
        }
        if (!SupportedMimeTypes.includes(file.type)) {
            setError('UNSUPPORTED_FILE_FORMAT');
            return;
        }
        if (file.size > 10000000) {
            setError('FILE_SIZE_EXCEEDS_MAX');
            return;
        }
        const reader = new FileReader();
        reader.onload = function onload(evt) {
            var _a;
            const result = (_a = evt.target) === null || _a === void 0 ? void 0 : _a.result;
            if (typeof result !== 'string') {
                return;
            }
            const pattern = /data:(?<mime>.*?);base64,(?<base64>.*)/;
            const match = result.match(pattern);
            if (match === null) {
                return;
            }
            const [, , base64] = match;
            addComment({
                data: {
                    base64,
                    filename: file.name,
                    mimeType: file.type,
                    type: 'document-compare-with-uploaded-file',
                },
                type: ChatMessageType.DocumentCompare,
            });
        };
        reader.readAsDataURL(file);
    };
    const handleAdd = () => {
        if (!selected) {
            return;
        }
        addComment({
            data: {
                documentId: selected.id,
                type: 'document-compare-with-pocketlaw-file',
            },
            type: ChatMessageType.DocumentCompare,
        });
    };
    const selectable = (entry) => {
        var _a;
        if (entry.id === id || ((_a = entry.documentAi) === null || _a === void 0 ? void 0 : _a.enabled) === false) {
            return false;
        }
        if (entry.type === DocumentType.Uploaded) {
            if (!entry.mimeType || !SupportedMimeTypes.includes(entry.mimeType)) {
                return false;
            }
        }
        return true;
    };
    return (<Fragment>
      <Menu open={open} onClose={handleClose} target={<AssistantPromptButton title={<Trans comment="Document Assistant predefined prompts - compare">Compare</Trans>} leftIcon={<Falcon icon="file"/>} onClick={handleToggle}/>}>
        <MenuBody>
          <MenuList>
            <MenuItem onClick={handleShowBrowser}>
              <Falcon icon="arrow-pointer"/>
              <Trans comment="Option for plai compare document">
                With one of your Pocketlaw files
              </Trans>
            </MenuItem>
            {isCompareDocumentsUploadEnabled && (<MenuItem onClick={handleSelectFile}>
                <Falcon icon="up-from-bracket"/>
                <Trans comment="Option for plai compare upload">With uploaded file</Trans>
              </MenuItem>)}
          </MenuList>
        </MenuBody>
      </Menu>
      <FileUpload ref={fileUploadRef} onChange={handleChange}/>
      {showBrowser && (<Dialog onClose={handleHideBrowser}>
          <DialogHeader title={<Trans comment="Assistant file browser title">Select the file to compare with</Trans>} media={<Avatar shape="square">
                <Falcon icon="file-circle-plus"/>
              </Avatar>}/>
          <DialogContent>
            <FileBrowser selfId={id} onSelect={entry => setSelected(entry)} selectable={selectable} showSignRequestStatus includeDocumentAi/>
          </DialogContent>
          <DialogFooter>
            <Button appearance="secondary" onClick={() => setShowBrowser(false)}>
              <Trans comment="Cancel button label in the assistant file browser">Cancel</Trans>
            </Button>
            <Button appearance="primary" onClick={handleAdd} disabled={!selected}>
              <Trans comment="Submit button label in the assistant file browser">Add</Trans>
            </Button>
          </DialogFooter>
        </Dialog>)}
    </Fragment>);
}
