import { useFormatDate } from 'app/domains/users';
import { DesktopTableBodyCell } from '../styled';
import { TableBodyCellText } from './styled';
export function DateCell(props) {
    const { createdAt } = props;
    const date = useFormatDate(createdAt);
    return (<DesktopTableBodyCell headers="createdAt">
      <TableBodyCellText $fontSize="medium" $appearance="100">
        {date}
      </TableBodyCellText>
    </DesktopTableBodyCell>);
}
