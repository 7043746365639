import { Fragment, useState } from 'react';
import { Trans } from '@lingui/macro';
import { Button } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { SaveViewDialog } from './SaveViewDialog';
export function SaveViewButton() {
    const [open, setOpen] = useState(false);
    return (<Fragment>
      <Button onClick={() => setOpen(true)} appearance="ghost" size="small">
        <Falcon icon="layer-plus"/>
        <Trans comment="Save view: Save as view button">Save view</Trans>
      </Button>
      {open && <SaveViewDialog onClose={() => setOpen(false)}/>}
    </Fragment>);
}
