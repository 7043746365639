import useAccount from 'shared/domains/users/hooks/useAccount';
export function useGetCurrentUser() {
    const account = useAccount();
    const { userId, profile } = account.data;
    const currentUser = {
        id: userId,
        name: profile === null || profile === void 0 ? void 0 : profile.name,
    };
    return currentUser;
}
