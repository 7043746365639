import { gql } from '@apollo/client';
export const saveSigneeMutation = gql `
  mutation AddSignatoryValue($objects: [EsigningSignatoryValueInsertInput!]!) {
    createEsigningSignatoryValue(
      objects: $objects
      on_conflict: {
        constraint: esigning_signatory_value_company_id_user_id_name_email_labe_key
        update_columns: [email, is_signing, file_system_document_id, name, label]
      }
    ) {
      returning {
        id
        name
        email
        label
        isSigning: is_signing
      }
    }
  }
`;
