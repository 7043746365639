import { addDays, addMonths, addWeeks, isAfter, isSameDay, isValid } from 'date-fns';
export function getDateByTimeBeforeDate(options) {
    const { timeBefore, date } = options;
    switch (timeBefore) {
        case 'one-month':
            return addMonths(date, -1);
        case 'two-weeks':
            return addWeeks(date, -2);
        case 'two-days':
            return addDays(date, -2);
        case 'one-day':
        default:
            return addDays(date, -1);
    }
}
export function getCurrentDropdown(dueDateString) {
    const dueDate = new Date(dueDateString || '');
    if (isValid(dueDate) && dueDate > addDays(new Date(), 1)) {
        return 'simple';
    }
    return 'calendar';
}
export function isReminderAfterDueDate(options) {
    const { dueDate, reminderDate } = options;
    if (isValid(dueDate) && isValid(reminderDate)) {
        return isAfter(reminderDate, dueDate);
    }
    return false;
}
export function isReminderOnDueDate(options) {
    const { dueDate, reminderDate } = options;
    if (isValid(dueDate) && isValid(reminderDate)) {
        return isSameDay(reminderDate, dueDate);
    }
    return false;
}
