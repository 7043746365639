import { LoaderOverlay } from '@pocketlaw/tetris';
import { useActiveDocument, useDocumentAi } from 'shared/domains/documents';
import { DocumentAiNotAvailable } from '../DocumentAiNotAvailable';
export function DocumentAi(props) {
    const { children } = props;
    const { id: documentId } = useActiveDocument();
    const { data: documentAiData, loading: documentAiLoading } = useDocumentAi(documentId);
    return (<LoaderOverlay enabled={documentAiLoading}>
      {documentAiData.enabled ? (children) : (<DocumentAiNotAvailable reason={documentAiData.disabledReason}/>)}
    </LoaderOverlay>);
}
