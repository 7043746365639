import { defineMessage } from '@lingui/macro';
const messages = {
    ALREADY_INVITED: defineMessage({
        message: 'An invite has already been sent to this email address for this document.',
        comment: 'Error message when failing to invite external user to document',
    }),
    ALREADY_HAS_ACCESS: defineMessage({
        message: 'The user already has access to this document as a member of your company.',
        comment: 'Error message when failing to invite external user to document',
    }),
    IS_COMPANY_MEMBER: defineMessage({
        message: 'This user is a user of your company and not an external user. We have added the user as a member to the document.',
        comment: 'Error message when failing to invite external user to document',
    }),
    DEFAULT: defineMessage({
        message: 'There was a problem sending the invite',
        comment: 'Default error message when failing to invite external user to document',
    }),
};
function getErrorMessage(error) {
    if (!error) {
        return undefined;
    }
    const code = error.message;
    const message = messages[code];
    const defaultMessage = messages.DEFAULT;
    if (!message) {
        return defaultMessage;
    }
    return message;
}
export { getErrorMessage };
