import { getRole } from 'app/domains/users/utils';
import { roles } from '../../../utils/roles';
export function normalizeUsers(companyUsers, currentUserId) {
    return companyUsers.map(({ id, role, canManageMember, createdAt, user }) => {
        var _a;
        const userRole = getRole(role, canManageMember);
        const { label: userRoleName } = roles.find(({ value }) => value === userRole) || {};
        const isSelf = currentUserId ? currentUserId === (user === null || user === void 0 ? void 0 : user.id) : false;
        return {
            id,
            userId: (_a = user === null || user === void 0 ? void 0 : user.id) !== null && _a !== void 0 ? _a : '',
            name: user === null || user === void 0 ? void 0 : user.name,
            email: user === null || user === void 0 ? void 0 : user.email,
            createdAt,
            isSelf,
            role: userRole,
            roleName: userRoleName || '',
        };
    });
}
