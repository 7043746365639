import { breakpoints, pxToRem, unit, TableBodyCell, TableHeadCell, themeGet, Typography, } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const MinWidth = styled.div `
  min-width: ${({ $minWidth }) => $minWidth};
`;
export const SelectContainer = styled.div `
  min-width: ${unit(35)};
  max-width: ${pxToRem(150)};
`;
export const DesktopTableBodyCell = styled(TableBodyCell) `
  ${breakpoints.xs} {
    display: none;
  }
`;
export const DesktopTableHeaderCell = styled(TableHeadCell) `
  ${breakpoints.xs} {
    display: none;
  }
`;
export const MobileTableHeaderCell = styled(TableHeadCell) `
  ${breakpoints.sm} {
    display: none;
  }
`;
export const List = styled.ul `
  display: block;
  padding: ${themeGet('spacing.sm')} 0;
`;
export const ListItem = styled.li `
  height: ${pxToRem(32)};
  padding: ${pxToRem(1)} ${themeGet('spacing.md')};
`;
export const ListItemTitle = styled(Typography).attrs({
    $appearance: '300',
    $fontSize: 'small',
}) `
  margin-right: ${themeGet('spacing.sm')};
`;
export const ListItemValue = styled(Typography).attrs({
    $appearance: '200',
    $fontSize: 'tiny',
}) ``;
