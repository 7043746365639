import { t } from '@lingui/macro';
import { localizedFormatDate } from 'shared/domains/common-utils';
const DATE_FORMAT = Object.freeze({
    year: 'numeric',
    month: 'short',
    day: '2-digit',
});
function extractDateSigned(systemData) {
    const { lastSignRequest } = systemData || {};
    const { signedAt } = lastSignRequest || {};
    const noDate = t({ message: 'Not signed', comment: 'Metadata: Not signed' });
    if (!signedAt) {
        return noDate;
    }
    return localizedFormatDate(signedAt, DATE_FORMAT);
}
export function getSystemData(data) {
    const { createdAt, updatedAt, category, user, template, name, status, type } = data || {};
    const [composerTemplate] = (template === null || template === void 0 ? void 0 : template.composerTemplates) || [];
    const [metadata] = (composerTemplate === null || composerTemplate === void 0 ? void 0 : composerTemplate.composer.metadata) || [];
    const { name: templateName } = metadata || {};
    const formattedCreatedAt = localizedFormatDate(createdAt, DATE_FORMAT);
    const formattedUpdatedAt = localizedFormatDate(updatedAt, DATE_FORMAT);
    const [categoryMetadata] = (category === null || category === void 0 ? void 0 : category.metadata) || [];
    const categoryId = category === null || category === void 0 ? void 0 : category.id;
    const createdBy = (user === null || user === void 0 ? void 0 : user.name) || t({ message: 'Unknown', comment: 'Unknown user name' });
    const dateSigned = extractDateSigned(data);
    return {
        name,
        templateName,
        createdBy,
        category: categoryMetadata === null || categoryMetadata === void 0 ? void 0 : categoryMetadata.title,
        categoryId,
        updatedAt: formattedUpdatedAt,
        createdAt: formattedCreatedAt,
        dateSigned,
        status,
        type: type,
    };
}
