import { useQuery } from '@apollo/client';
import { useComposersTable } from 'app/domains/templates';
import { getComposerNameQuery } from './getComposerNameQuery';
/**
 * Get the selected composer name if there's only one composer selected
 * If there are multiple composers selected, an empty string will be returned
 */
export function useComposerName() {
    var _a, _b;
    const { drawerData } = useComposersTable();
    const composerId = ((_a = drawerData === null || drawerData === void 0 ? void 0 : drawerData.composerIds) === null || _a === void 0 ? void 0 : _a[0]) || '';
    const { data } = useQuery(getComposerNameQuery, {
        variables: { composerId },
        skip: (drawerData === null || drawerData === void 0 ? void 0 : drawerData.composerIds.length) !== 1, // We only need the name if there's one composer selected
    });
    const composerName = ((_b = data === null || data === void 0 ? void 0 : data.composer.metadata[0]) === null || _b === void 0 ? void 0 : _b.name) || '';
    return { composerName };
}
