import { t } from '@lingui/macro';
import { EmptyState } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import 'content-visibility';
import { Item } from './Item';
import { usePromptLibrary } from '../../PromptLibraryProvider';
import { ErrorState } from '../ErrorState';
import { LoadingState } from '../LoadingState';
import { EmptyContent, GridContent, contentVisibilityStyle } from './styled';
export function Grid() {
    const { prompts, loading, error } = usePromptLibrary();
    if (loading) {
        return <LoadingState />;
    }
    if (error) {
        return <ErrorState />;
    }
    if (prompts.length) {
        return (<content-visibility style={contentVisibilityStyle}>
        <GridContent>
          {prompts.map(prompt => (<Item key={prompt.id} prompt={prompt}/>))}
        </GridContent>
      </content-visibility>);
    }
    return (<EmptyContent>
      <EmptyState icon={<Falcon icon="shelves-empty"/>} title={t({
            comment: 'Prompt library - empty search title',
            message: 'No prompts found here',
        })} description={t({
            comment: 'Prompt library - empty search description',
            message: "We don't seem to have the prompts you're looking for",
        })}/>
    </EmptyContent>);
}
