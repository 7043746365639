import { useCallback, Fragment, useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import { useToast, Alert, LoaderOverlay, Box } from '@pocketlaw/tetris';
import { useHistory } from 'react-router-dom';
import { MainContent } from 'app/domains/common-ui/components/PageLayout';
import { useACLCan } from 'app/domains/filesystem';
import { EntryKind } from 'shared/domains/apollo/generated/types';
import { Pagination } from 'shared/domains/common-ui';
import { useCloneFreeTextDocument } from 'shared/domains/documents';
import AccessWarningAlert from './AccessWarningAlert';
import Dialog from './Dialog';
import DropUpload from './DropUpload';
import { FileSystemEmpty } from './FileSystemEmpty';
import { useActiveWorkspace, FileSystemProvider } from './FileSystemProvider';
import FileSystemTable from './FileSystemTable';
import { Header } from './Header';
import useCacheHandlers from './useCacheHandlers';
import useDialogState from './useDialogState';
import { useFileSystemEntries } from './useFileSystemEntries';
import useSortState from './useSortState';
import { AlertContainer } from './styled';
export { FileSystemProvider, useActiveWorkspace };
const PAGE_SIZE = 50;
export function FileSystem() {
    var _a;
    const activeWorkspace = useActiveWorkspace();
    const [entriesOffset, setEntriesOffset] = useState(0);
    const { folderId, fileSystemData, loading, error } = activeWorkspace;
    const { folder } = fileSystemData || {};
    const { addToast } = useToast();
    const history = useHistory();
    const [state, { sort }] = useSortState();
    const entriesQuery = useFileSystemEntries(Object.assign(Object.assign({}, state), { folderId, limit: PAGE_SIZE, offset: entriesOffset }));
    useEffect(() => {
        setEntriesOffset(0);
    }, [folderId]);
    const [dialog, actions] = useDialogState();
    const [cloneDocument] = useCloneFreeTextDocument();
    const { createEntries, renameEntries, deleteEntries } = useCacheHandlers(entriesQuery.updateEntries);
    const { canRead, canWrite } = useACLCan({ userAcls: folder === null || folder === void 0 ? void 0 : folder.acl, teamAcls: folder === null || folder === void 0 ? void 0 : folder.teamAcls });
    const { entries = [], count } = (_a = entriesQuery.data) !== null && _a !== void 0 ? _a : {};
    const enableDropZone = canWrite();
    const showAccessWarning = folder && !canRead();
    const hasError = error || entriesQuery.error;
    const handleDuplicated = (createdDraft) => {
        history.push(`/draft/${createdDraft.id}`);
    };
    const handleDocumentsUploaded = (entryIds, locationFolderId) => {
        if (folderId === locationFolderId) {
            entriesQuery.refetch();
        }
        else if (!locationFolderId) {
            history.push('/files');
        }
        else {
            history.push(`/files/${locationFolderId}`);
        }
    };
    const handleDuplicateFreeTextDocs = useCallback(() => {
        var _a, _b;
        const cloneId = (_a = dialog === null || dialog === void 0 ? void 0 : dialog.payload) === null || _a === void 0 ? void 0 : _a.id;
        const cloneName = (_b = dialog === null || dialog === void 0 ? void 0 : dialog.payload) === null || _b === void 0 ? void 0 : _b.name;
        if (cloneId && cloneName) {
            cloneDocument({ id: cloneId, name: cloneName })
                .then(response => {
                var _a;
                history.push(`/document/${(_a = response.data) === null || _a === void 0 ? void 0 : _a.cloneFromFileSystemDocument.id}`);
            })
                .catch(() => {
                addToast({ title: t `Could not create copy.`, appearance: 'danger' });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dialog.payload]);
    useEffect(() => {
        var _a;
        if (`${dialog.name}:${dialog.type}` === `DUPLICATE:${EntryKind.Document}` &&
            ((_a = dialog.payload) === null || _a === void 0 ? void 0 : _a.isFreeText)) {
            handleDuplicateFreeTextDocs();
        }
    }, [dialog.name, dialog.payload, dialog.type, handleDuplicateFreeTextDocs]);
    if (hasError) {
        return (<AlertContainer>
        <Alert appearance="danger" title={t({
                message: 'Could not load workspace',
                comment: 'Alert title displayed when we could not load the workspace',
            })} message={t({
                message: 'Something went wrong while loading the workspace, please try again later',
                comment: 'Alert message displayed when we could not load the workspace',
            })}/>
      </AlertContainer>);
    }
    return (<Fragment>
      <Header onUploadFolder={actions.uploadFolder} onUploadFile={actions.uploadFiles} onCreateFolder={actions.createFolder}/>

      <MainContent>
        {showAccessWarning && <AccessWarningAlert />}

        {enableDropZone && <DropUpload onDropUpload={actions.uploadFiles}/>}

        <Box justifyContent="flex-end">
          <Pagination disabled={entriesQuery.loading} limit={PAGE_SIZE} count={count} offset={entriesOffset} setOffset={setEntriesOffset}/>
        </Box>

        {Boolean(!entries.length && !loading) && (<FileSystemEmpty onUploadFolder={actions.uploadFolder} onUploadFile={actions.uploadFiles} onCreateFolder={actions.createFolder}/>)}

        <LoaderOverlay enabled={entriesQuery.loading} transparent>
          {Boolean(entries.length) && (<FileSystemTable {...state} entries={entries} onSort={sort} onDuplicate={actions.duplicate} onRename={actions.rename} onMove={actions.move} onQuickMove={actions.quickMove} onDelete={actions.delete}/>)}
        </LoaderOverlay>
      </MainContent>

      <Dialog action={dialog} folder={folder} onCreated={createEntries} onDuplicated={handleDuplicated} onRenamed={renameEntries} onDeleted={deleteEntries} onDocumentsUploaded={handleDocumentsUploaded} onClose={actions.reset}/>
    </Fragment>);
}
