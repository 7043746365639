import { useMobile } from 'shared/domains/common-ui';
import { Filters } from './Filters';
import { Grid } from './Grid';
import { Header } from './Header';
import { Content, GridContainer, LeftColumn } from './styled';
export function PromptLibrarySelect() {
    const isMobile = useMobile();
    return (<Content>
      {!isMobile && (<LeftColumn>
          <Filters />
        </LeftColumn>)}
      <GridContainer>
        <Header />
        <Grid />
      </GridContainer>
    </Content>);
}
