import { useReducer, useCallback, useEffect } from 'react';
import { reducer, initialState } from './utils';
export function useWorker(worker) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const postMessage = useCallback((message) => {
        if (worker && worker.postMessage) {
            dispatch({ type: 'send' });
            worker.postMessage(message);
        }
    }, [worker]);
    useEffect(() => {
        const handleMessage = (event) => dispatch({ type: 'data', payload: event.data });
        const handleError = (event) => dispatch({ type: 'error', payload: event.message });
        worker.addEventListener('message', handleMessage);
        worker.addEventListener('error', handleError);
        return () => {
            worker.removeEventListener('message', handleMessage);
            worker.removeEventListener('error', handleError);
        };
    }, [worker]);
    return [postMessage, state];
}
