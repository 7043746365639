import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
const CONTAINER_HEIGHT = pxToRem(180);
const PAGE_WIDTH = pxToRem(180);
const PAGE_HEIGHT = pxToRem(160);
const TRANSITION = 'transform 325ms cubic-bezier(0.25, 1.25, 0.55, 1.0)';
const IllustrationPageBase = styled.div `
  position: absolute;
  bottom: 0;
  width: ${PAGE_WIDTH};
  height: ${PAGE_HEIGHT};
  border-radius: ${themeGet('borderRadius.md')};
  background: ${themeGet('colors.background.50')};
  transition: ${TRANSITION};
`;
export const IllustrationPageLeft = styled(IllustrationPageBase) `
  z-index: 1;
  bottom: -${pxToRem(20)};
  margin-left: -25%;
  box-shadow: ${themeGet('dropShadow.300')};
`;
export const IllustrationPageRight = styled(IllustrationPageBase) `
  z-index: 2;
  bottom: -${pxToRem(60)};
  margin-right: -25%;
  box-shadow: ${themeGet('dropShadow.400')};
`;
export const IllustrationLoaderContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transform: translateY(100%);
  transition: ${TRANSITION};
`;
export const IllustrationContainer = styled.div `
  position: relative;
  width: 100%;
  height: ${CONTAINER_HEIGHT};
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-radius: ${themeGet('borderRadius.lg')};
  background: linear-gradient(
    35deg,
    ${themeGet('colors.assistant.300')} 0%,
    ${themeGet('colors.assistant.100')} 50%,
    ${themeGet('colors.assistant.50')} 100%
  );
  background-size: 300% 300%;
  background-position: 0% 0%;

  transition: background 325ms ease-in-out;

  ${props => props.$loading &&
    `
    background-size: 225% 100%;
    background-position: 100% 100%;

    ${IllustrationLoaderContainer} {
      transform: translateY(0%);
    }

    ${IllustrationPageLeft} {
      transform: translateY(100%);
    }

    ${IllustrationPageRight} {
      transform: translateY(100%);
    }
  `}
`;
export const StyledList = styled.ul `
  list-style: disc;
  list-style-position: inside;
  padding-left: ${themeGet('spacing.md')};
  color: ${themeGet('color.200')};
`;
export const StyledListItem = styled.li `
  &::marker {
    color: inherit;
  }
`;
