var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { BulkMoveDocumentsDocument } from './BulkMoveFiles.gql';
import { BulkMoveSynchronizeDocument } from './BulkMoveSynchronize.gql';
const POLL_INTERVAL = 2000;
export function useBulkMoveFiles(options) {
    const { onSuccess, onError } = options;
    const [state, setState] = useState(undefined);
    const client = useApolloClient();
    const { addToast } = useToast();
    const [queryFiles, queryState] = useLazyQuery(BulkMoveSynchronizeDocument, {
        fetchPolicy: 'network-only',
    });
    const [mutate] = useMutation(BulkMoveDocumentsDocument);
    const successCallback = (entry) => __awaiter(this, void 0, void 0, function* () {
        yield client.refetchQueries({
            include: ['GlobalSearch'],
            onQueryUpdated(observableQuery) {
                return observableQuery.refetch();
            },
        });
        setState('done');
        onSuccess(entry);
    });
    const moveFiles = (entry, documents) => __awaiter(this, void 0, void 0, function* () {
        const { id: parentId } = entry;
        try {
            const movedFiles = [];
            setState('moving');
            const moveResults = yield Promise.all(documents.map(document => mutate({
                variables: {
                    id: document.entityId,
                    parentId,
                },
            })));
            moveResults.forEach(result => {
                var _a;
                if ((_a = result.data) === null || _a === void 0 ? void 0 : _a.entries) {
                    movedFiles.push({
                        id: result.data.entries.id,
                        parentId: result.data.entries.parentId || '',
                    });
                }
            });
            setState('synchronizing');
            yield queryFiles({
                variables: {
                    documents: documents.map(document => document.entityId),
                },
                fetchPolicy: 'network-only',
                notifyOnNetworkStatusChange: true,
                pollInterval: POLL_INTERVAL,
                onCompleted(syncSearchFiles) {
                    const searchResult = syncSearchFiles.search.results || [];
                    const synchronizedFiles = movedFiles.filter(movedEntry => {
                        const searchResultEntry = searchResult.find(resultEntry => resultEntry.entityId === movedEntry.id);
                        const parentIdMatch = (searchResultEntry === null || searchResultEntry === void 0 ? void 0 : searchResultEntry.parentId) === movedEntry.parentId;
                        return parentIdMatch;
                    });
                    if (synchronizedFiles.length !== movedFiles.length) {
                        queryState.startPolling(POLL_INTERVAL);
                        setState('synchronizing');
                        return undefined;
                    }
                    queryState.stopPolling();
                    return successCallback(entry);
                },
            });
        }
        catch (_a) {
            setState('error');
            onError();
            addToast({
                appearance: 'danger',
                title: t({
                    message: 'Error occurred while moving documents',
                    comment: 'Move documents error toast',
                }),
            });
        }
        return undefined;
    });
    return { moveFiles, state };
}
