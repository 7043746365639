var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCreateDraft } from 'app/domains/templates';
import { useGtm } from 'shared/domains/tracking';
export const useCreateDraftWrapper = () => {
    const { mutate } = useCreateDraft();
    const { push: gtmPush } = useGtm();
    const createDraft = (options) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const { composer, template } = options;
        const { id: composerId } = composer;
        const { id: templateId, latestVersion } = template;
        const { data } = yield mutate({
            composerId,
            templateId,
            version: composer.tree.version,
            templateVersionTemplateId: latestVersion.templateId,
            templateVersionReference: latestVersion.reference,
        });
        gtmPush({
            event: 'template_start',
            form: templateId,
        });
        const { id: newId = '' } = ((_a = data === null || data === void 0 ? void 0 : data.created) === null || _a === void 0 ? void 0 : _a.returning[0]) || {};
        return newId;
    });
    return { createDraft };
};
