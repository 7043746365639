var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { CreateInsightsDashboardDocument, } from './CreateInsightsDashboard.gql';
export function useCreateDashboard() {
    const [mutate, state] = useMutation(CreateInsightsDashboardDocument);
    const handler = (input) => __awaiter(this, void 0, void 0, function* () {
        const { data } = yield mutate({
            variables: { input },
            update(cache, { data: newData }) {
                const { id } = (newData === null || newData === void 0 ? void 0 : newData.createSearchInsightsDashboard) || {};
                if (id) {
                    cache.modify({
                        fields: {
                            searchInsightsDashboards(existing = [], { toReference }) {
                                const newReference = toReference({ id, __typename: 'SearchInsightsDashboard' }, true);
                                return [...existing, newReference];
                            },
                        },
                    });
                }
            },
        });
        const { createSearchInsightsDashboard } = data || {};
        return Object.assign({}, createSearchInsightsDashboard);
    });
    return [handler, state];
}
