import { t } from '@lingui/macro';
import { Select } from '@pocketlaw/tetris';
import { useField } from 'formik';
import { EsigningProviderAuthenticationModeSlug } from 'shared/domains/apollo/generated/types';
import { ErrorMessage } from 'shared/domains/forms/components/ErrorMessage';
import { FieldWrapper } from '../../styled';
export function RoleSelect() {
    const [field, meta, helpers] = useField('isSigning');
    const [, , { setValue: setTitle }] = useField('title');
    const [, , { setValue: setAuthenticationMode }] = useField('authenticationMode');
    const error = Boolean(meta.touched && meta.error);
    const handleChange = (event) => {
        const isSigning = JSON.parse(event.target.value);
        if (!isSigning) {
            setTitle(undefined);
            setAuthenticationMode(undefined);
        }
        else {
            setTitle('');
            setAuthenticationMode(EsigningProviderAuthenticationModeSlug.Email);
        }
        helpers.setValue(isSigning);
    };
    return (<FieldWrapper>
      <Select stretch label={t `Role`} invalid={error} {...field} value={JSON.stringify(field.value)} onChange={handleChange}>
        <option value="true">{t `Signing`}</option>
        <option value="false">{t `Non-Signing`}</option>
      </Select>
      <ErrorMessage name="cc"/>
    </FieldWrapper>);
}
