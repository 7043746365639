import { themeGet, unit } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  align-items: center;
  gap: ${unit('xl')};
`;
export const ItemContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;

  [data-icon='falcon'] {
    color: ${themeGet('colors.indigo.500')};
  }
`;
export const TextContainer = styled.div `
  display: flex;
  flex-direction: column;
`;
