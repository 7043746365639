import { gql } from '@apollo/client';
export const query = gql `
  query GetFeedbackThreads($documentId: String!) {
    externalSharingInvites(where: { fileSystemDocumentId: { eq: $documentId } }) {
      id

      inviter {
        id
        name
        email
      }

      invitee {
        id
        name
        email
      }

      documentId: fileSystemDocumentId

      messageThreads {
        unreadMessagesCount
        interlocutor {
          id
          name
          email
        }

        messages {
          id
          createdAt
          message
          isRead
          senderId
          sender {
            id
            name
          }
        }
      }
    }
  }
`;
