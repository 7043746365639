import { useRef, useEffect, useState } from 'react';
import { gql, useApolloClient } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { useDebouncedCallback } from 'use-debounce';
import { EditorDraftDocument } from 'app/domains/templates';
import { getFormattedValues } from './getFormattedValues';
const updateDraftResultMutation = gql `
  mutation updateDraftResult($id: UUID!, $result: JSON!) {
    updateDraft(where: { id: { _eq: $id } }, _set: { result: $result }) {
      returning {
        id
        result
      }
    }
  }
`;
export function useUpdateDraft() {
    const client = useApolloClient();
    const controllerRef = useRef();
    const { addToast } = useToast();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const mutate = useDebouncedCallback((params) => {
        const { values, draftId } = params;
        const result = getFormattedValues(values);
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        controllerRef.current = new AbortController();
        setLoading(true);
        setError(false);
        return client
            .mutate({
            mutation: updateDraftResultMutation,
            variables: { id: draftId, result },
            context: {
                fetchOptions: {
                    signal: controllerRef.current.signal,
                },
            },
            refetchQueries: [EditorDraftDocument],
        })
            .finally(() => {
            setLoading(false);
        })
            .catch(() => {
            setError(true);
            addToast({ title: t `Failed to save`, appearance: 'danger' });
        });
    }, 500, { leading: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => () => mutate.flush(), []);
    return { updateDraft: mutate, loading, error };
}
