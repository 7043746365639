import { Trans } from '@lingui/macro';
import { Box, Typography, Button } from '@pocketlaw/tetris';
export function ReviewInProgressState(props) {
    const { disabled, onCancelReview } = props;
    return (<Box flexDirection="column" gap="xl">
      <Box flexDirection="column" gap="lg">
        <Typography $appearance="300" $fontSize="large" $fontWeight="semibold">
          <Trans comment="Document contract review - Review in progress - Title">
            Review in progress
          </Trans>
        </Typography>
        <Typography>
          <Trans comment="Document contract review - Review in progress - Description">
            This may take some time to complete. We will send you an email once the review is
            complete.
          </Trans>
        </Typography>
      </Box>
      <Button appearance="secondary" size="small" disabled={disabled} onClick={onCancelReview}>
        <Trans comment="Document contract review - Review in progress - Cancel review button label">
          Cancel review
        </Trans>
      </Button>
    </Box>);
}
