import { isComposedDocument } from '../isComposedDocument';
import { isDocxDocument } from '../isDocxDocument';
import { isFreeTextDocument } from '../isFreeTextDocument';
import { isPdfDocument } from '../isPdfDocument';
import { isSentForSigning } from '../isSentForSigning';
import { PreviewTypes } from '../types';
export function getPreviewType(document) {
    const isFreeText = isFreeTextDocument(document);
    const sentForSigning = isSentForSigning(document);
    const composedDocument = isComposedDocument(document);
    const docxDocument = isDocxDocument(document);
    const pdfDocument = isPdfDocument(document);
    if (sentForSigning) {
        return PreviewTypes.PDF;
    }
    if (isFreeText) {
        return PreviewTypes.FREE_TEXT;
    }
    if (composedDocument) {
        return PreviewTypes.COMPOSED;
    }
    if (docxDocument) {
        return PreviewTypes.DOCX;
    }
    if (pdfDocument) {
        return PreviewTypes.PDF;
    }
    return PreviewTypes.UNKNOWN;
}
