import { useQuery } from '@apollo/client';
import { useActiveDocument } from 'shared/domains/documents';
import { GetLastDocumentChatDocument } from './getLastChatQuery.gql';
export function useGetLastDocumentChat() {
    var _a;
    const { id: documentId } = useActiveDocument();
    const query = useQuery(GetLastDocumentChatDocument, {
        variables: { documentId },
    });
    const lastChat = (_a = query.data) === null || _a === void 0 ? void 0 : _a.documentChatsByUser[0];
    const { messages = [], resolutionStatus, resolved = false } = lastChat || {};
    const thread = messages.slice(0).reverse();
    return {
        chatId: lastChat === null || lastChat === void 0 ? void 0 : lastChat.id,
        thread,
        loading: query.loading,
        refetch: query.refetch,
        polling: false,
        resolved,
        resolutionStatus,
    };
}
export { GetLastDocumentChatDocument as getLastChatQuery, ChatFragmentFragmentDoc as ChatFragment, MessageFragmentFragmentDoc as MessageFragment, } from './getLastChatQuery.gql';
