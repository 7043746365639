import { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Avatar, Button, Chip, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { CreateButton } from './CreateButton';
import { getTemplateImage } from '../../utils/getTemplateImage';
import { ImageContainer, HoverContent, TitleContainer, OverlayButton } from './styled';
export function TemplateCard(props) {
    const { composerId, title, category, locked = false, onClick } = props;
    const src = getTemplateImage(composerId);
    const [display, setDisplay] = useState(false);
    const handleOnMouseEnter = () => setDisplay(true);
    const handleOnMouseLeave = () => setDisplay(false);
    return (<div onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
      <ImageContainer $src={src}>
        <OverlayButton type="button" onClick={onClick} aria-label={t({
            message: 'Preview',
            comment: 'clickable template card hidden text (screen reader only): preview template',
        })}>
          <Chip appearance="secondary" size="small" label={category}/>
          {locked && (<Avatar appearance="ghost" size="lg">
              <Falcon icon="lock"/>
            </Avatar>)}
        </OverlayButton>
        {display && (<HoverContent>
            <CreateButton composerId={composerId} disabled={locked}/>
            <Button type="button" appearance="secondary" onClick={onClick} $stretch size="small">
              <Trans comment="template card hover button: preview template">Preview</Trans>
            </Button>
          </HoverContent>)}
      </ImageContainer>
      <TitleContainer>
        <Typography $fontSize="small" $fontWeight="regular" as="h3">
          {title}
        </Typography>
      </TitleContainer>
    </div>);
}
