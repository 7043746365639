var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import { EmptyState } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { DocumentDrawerHeader } from 'shared/domains/documents';
import useMessageThread from 'shared/domains/feedback/hooks/useMessageThread';
import { Message } from './Message';
import { getTransformedMessages } from './helpers';
import { DrawerContainer } from '../../../styled';
import { LoadErrorAlert } from '../LoadErrorAlert';
import { SendMessage } from '../SendMessage';
import { Container, InputContainer, MessageList, StyledLoader, EmptyContainer } from './styled';
export function MessageThread(props) {
    var _a;
    const { inviteId, interlocutorId, documentId, onClose, onGoBack } = props;
    const [listRef, setListRef] = useState(null);
    const { data, error, loading, refetch } = useMessageThread({
        shouldLoadData: true,
        interlocutorId,
        documentId,
        inviteId,
    });
    useEffect(() => {
        if (listRef && data) {
            listRef.scrollTo({ top: listRef.scrollHeight, behavior: 'smooth' });
        }
    }, [listRef, data]);
    if (error) {
        return <LoadErrorAlert />;
    }
    if (loading) {
        return <StyledLoader size={16}/>;
    }
    const messages = getTransformedMessages(data === null || data === void 0 ? void 0 : data.thread.messages);
    const title = ((_a = data === null || data === void 0 ? void 0 : data.invitee) === null || _a === void 0 ? void 0 : _a.name) || '';
    return (<DrawerContainer>
      <DocumentDrawerHeader type="nested" title={title} onClose={onClose} onGoBack={onGoBack}/>
      <Container>
        {messages.length === 0 ? (<EmptyContainer>
            <EmptyState withAvatar icon={<Falcon icon="comment-alt-lines"/>} title={t({
                message: 'No messages sent',
                comment: 'Empty feedback chat with external user state - Title',
            })} description={t({
                message: 'No messages have been sent from or to this user',
                comment: 'Empty feedback chat with external user state - Message',
            })}/>
          </EmptyContainer>) : (<MessageList ref={el => setListRef(el)}>
            {messages.map((_a) => {
                var { hideSender, hideTimestamp } = _a, message = __rest(_a, ["hideSender", "hideTimestamp"]);
                return (<Message key={message.id} message={message} hideSender={hideSender} hideTimestamp={hideTimestamp}/>);
            })}
          </MessageList>)}
        <InputContainer>
          <SendMessage inviteId={inviteId} recipientId={interlocutorId} onSuccess={refetch}/>
        </InputContainer>
      </Container>
    </DrawerContainer>);
}
