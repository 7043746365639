import { gql } from '@apollo/client';
export const permissionQuery = gql `
  query DocumentPermission($documentId: ID!, $userId: String!) {
    fileSystemEntryACL: fileSystemAcl(
      where: { entryId: { eq: $documentId }, userId: { eq: $userId } }
    ) {
      id
      userId
      fileSystemEntryId: entryId
      actions
    }
  }
`;
