const isElement = (node) => node.is('element');
const emptyString = (text) => text.trim().length > 0;
const removeExtraSpaces = (text) => text.replace(/ +(?= )/g, '');
// TODO: Hw to handle pl-highlight-block?
export const elementIndexChunky = (root) => {
    /**
     * Pre order traversal of the element node.
     * Returns the content of the element as a string.
     */
    const getContentFromElement = (element) => {
        if (element.name === 'table') {
            return '';
        }
        const flatten = (childNode) => {
            if (childNode.is('$text')) {
                return childNode.data.length > 0 ? [childNode.data] : [];
            }
            if (isElement(childNode)) {
                if (childNode.name === 'table') {
                    return [];
                }
                if (childNode.isEmpty) {
                    return [];
                }
                return [...childNode.getChildren()].flatMap(flatten);
            }
            return [];
        };
        const content = [...element.getChildren()].flatMap(flatten).filter(emptyString);
        if (content.length === 0) {
            return '';
        }
        return removeExtraSpaces(content.join(' '));
    };
    const extractChildChunksFromHighlightBlock = (element) => {
        const childNodes = [...element.getChildren()];
        return childNodes.flatMap(node => {
            let text = '';
            if (isElement(node)) {
                text = getContentFromElement(node);
            }
            return [{ node, text }];
        });
    };
    const childChunks = [...root.getChildren()].flatMap(node => {
        let text = '';
        if (isElement(node)) {
            if (node.name === 'pl-highlight-block') {
                return extractChildChunksFromHighlightBlock(node);
            }
            text = getContentFromElement(node);
        }
        return [{ node, text }];
    });
    return childChunks.filter(({ text }) => text.length > 0);
};
// Old way of getting text nodes
export const elementIndex = (root) => {
    const go = (node, path) => 
    // eslint-disable-next-line no-nested-ternary
    node.is('$text')
        ? [{ text: node.data, path }]
        : node.is('element')
            ? [...node.getChildren()].map(child => go(child, [...path, node])).flat()
            : [];
    const entries = go(root, []);
    const txt = entries.map(({ text }) => text).join(' ');
    const idx = Array(txt.length);
    let z = 0;
    entries.forEach(({ text }, i) => {
        const l = i === entries.length - 1 ? text.length : text.length + 1;
        for (let j = 0; j < l; j += 1) {
            idx[z] = i;
            z += 1;
        }
    });
    return {
        entries,
        idx,
        txt,
    };
};
// Text operations that update the inverse index
export const trim = (ctx) => {
    let start = 0;
    let end = ctx.txt.length;
    while (start < end && ctx.txt[start] === ' ') {
        start += 1;
    }
    while (end > start && ctx.txt[end - 1] === ' ') {
        end -= 1;
    }
    return {
        entries: ctx.entries,
        idx: ctx.idx.slice(start, end),
        txt: ctx.txt.slice(start, end),
    };
};
export const normalizeWhitespace = (ctx) => {
    const txtArr = ctx.txt.replace(/\s/g, ' ').split('');
    const idx = [...ctx.idx];
    for (let i = 0; i < txtArr.length; i += 1) {
        if (txtArr[i] === ' ' && txtArr[i + 1] === ' ') {
            txtArr.splice(i, 1);
            idx.splice(i, 1);
            i -= 1;
        }
    }
    return {
        entries: ctx.entries,
        idx,
        txt: txtArr.join(''),
    };
};
export const slice = (ctx, start, end) => ({
    entries: ctx.entries,
    idx: ctx.idx.slice(start, end),
    txt: ctx.txt.slice(start, end),
});
export const split = (ctx, char) => {
    const splits = [];
    for (let i = 0; i < ctx.txt.length; i += 1) {
        if (ctx.txt[i] === char) {
            splits.push(i);
        }
    }
    const res = splits.reduce(({ lastSplit, slices }, i) => {
        const start = lastSplit;
        const end = i;
        return {
            lastSplit: i + 1,
            slices: [...slices, slice(ctx, start, end)],
        };
    }, {
        lastSplit: 0,
        slices: [],
    });
    res.slices.push(slice(ctx, res.lastSplit, ctx.txt.length));
    return res.slices;
};
