import { pxToRem, themeGet, themeGetColor, scrollbar } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const ColumnContainer = styled.div `
  display: flex;
  width: 100%;
  position: relative;
  overflow: hidden;
`;
export const InputColumn = styled.div `
  display: flex;
  width: 30%;
  flex-direction: column;
  overflow: auto;
  ${scrollbar({ trackMargin: 'xl' })};

  padding: ${themeGet('spacing.2xl')};

  border-right: ${pxToRem(1)} solid ${themeGetColor('border.100')};
`;
export const PreviewColumn = styled.div `
  display: flex;
  width: 70%;
  flex-direction: column;
  overflow: auto;
  ${scrollbar({ trackMargin: 'xl' })};

  padding: ${themeGet('spacing.2xl')};
  padding-bottom: 0;
`;
export const PreviewFooter = styled.div `
  display: flex;
  gap: ${themeGet('spacing.lg')};
  position: sticky;
  bottom: 0;
  width: 100%;
  background: linear-gradient(0, ${themeGetColor('background.50')} 50%, transparent);
  justify-content: flex-end;

  padding-top: ${themeGet('spacing.xl')};
  padding-bottom: ${themeGet('spacing.2xl')};
`;
export const VisualizationContainer = styled.div `
  border: ${pxToRem(1)} solid ${themeGetColor('border.100')};
  border-radius: ${themeGet('borderRadius.md')};
  padding: ${themeGet('spacing.2xl')};
  height: ${pxToRem(400)};

  align-content: center;
  overflow-x: auto;
  ${scrollbar({ trackMargin: 'xl' })};
`;
export const FieldInputContainer = styled.div `
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${themeGet('spacing.lg')};
`;
