var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { ApolloError } from '@apollo/client';
import { Trans, t } from '@lingui/macro';
import { Alert, Avatar, DialogFooter, DialogHeader, GridCell, GridRow, IconButton, Typography, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { documentFlush, usePreview } from 'shared/domains/documents';
import { CancelButton, FormDialog, SubmitButton } from 'shared/domains/forms';
import { MessageField } from './MessageField';
import { getLabels } from './labels';
import { ApprovalErrorSubcodes, APPROVAL_ERROR_SUBCODES } from '../../utils';
import { useApproval } from '../ApprovalProvider';
import { useApprovers } from '../ApproversProvider';
import { StyledDialogContent } from './styled';
export function CancelApprovalDialog(props) {
    const { onClose, origin } = props;
    const { refetch } = useApprovers();
    const { cancelRequest, approvalApproved } = useApproval();
    const { isRtcAvailable } = usePreview();
    const [isApprovalStatusChanged, setIsApprovalStatusChanged] = useState(false);
    const [alert, setAlert] = useState(null);
    const labels = getLabels(approvalApproved, origin);
    const handleClose = () => {
        if (isApprovalStatusChanged) {
            refetch();
        }
        onClose();
    };
    const handleSubmit = (values) => __awaiter(this, void 0, void 0, function* () {
        const { message } = values;
        setAlert(null);
        if (isRtcAvailable) {
            documentFlush({ state: 'initialized' });
        }
        return cancelRequest({ message })
            .then(handleClose)
            .catch(error => {
            var _a;
            if (isRtcAvailable) {
                documentFlush({ state: 'not-initialized' });
            }
            if (error instanceof ApolloError) {
                const subcode = (_a = error.graphQLErrors[0]) === null || _a === void 0 ? void 0 : _a.extensions.subcode;
                if (subcode && APPROVAL_ERROR_SUBCODES.includes(subcode)) {
                    setIsApprovalStatusChanged(true);
                    if (subcode === ApprovalErrorSubcodes.AlreadyCancelled) {
                        return setAlert({ appearance: 'warning', message: labels.alreadyCancelled });
                    }
                    if (subcode === ApprovalErrorSubcodes.AlreadyRejected) {
                        return setAlert({ appearance: 'warning', message: labels.alreadyRejected });
                    }
                }
            }
            return setAlert({ appearance: 'danger', message: labels.requestError });
        });
    });
    const initialValues = {
        message: undefined,
    };
    return (<FormDialog size="sm" onClose={handleClose} onSubmit={handleSubmit} initialValues={initialValues}>
      <DialogHeader title={t({
            message: 'Cancel approval request',
            comment: 'Approval Drawer - Cancel approval dialog - tile',
        })} media={<Avatar shape="square" appearance="danger">
            <Falcon icon="circle-x"/>
          </Avatar>} action={<IconButton appearance="ghost" onClick={handleClose}>
            <Falcon icon="close"/>
          </IconButton>}/>
      <StyledDialogContent>
        {alert && (<GridRow>
            <GridCell width={12}>
              <Alert {...alert}/>
            </GridCell>
          </GridRow>)}
        <Typography $appearance="200">
          <Trans comment="Approval Drawer - Cancel approval dialog - description">
            If you continue the pending approval request will be cancelled.
          </Trans>
        </Typography>
        <MessageField />
      </StyledDialogContent>
      <DialogFooter>
        {isApprovalStatusChanged && (<CancelButton>
            <Trans comment="Approval Drawer - Cancel approval dialog - cancel button label">
              Close
            </Trans>
          </CancelButton>)}
        <SubmitButton disabled={isApprovalStatusChanged}>
          <Trans comment="Approval Drawer - Cancel approval dialog - submit button label">
            Submit
          </Trans>
        </SubmitButton>
      </DialogFooter>
    </FormDialog>);
}
