import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled, { keyframes } from 'styled-components';
const enterAnimation = keyframes `
  from {
    opacity: 0;
    transform: translateY(0) scale(0.75);
  }

  to {
    opacity: 1;
    transform: translateY(-${pxToRem(16)}) scale(1);
  }
`;
export const Container = styled.div `
  position: absolute;
  align-self: center;
  bottom: 0;
  padding: ${themeGet('spacing.lg')};
  border-radius: ${themeGet('borderRadius.lg')};
  border: solid ${pxToRem(1)} ${themeGet('colors.border.100')};
  background-color: ${themeGet('colors.background.50')};
  box-shadow: ${themeGet('dropShadow.400')};
  animation: ${enterAnimation} 250ms ease-in-out forwards;
`;
