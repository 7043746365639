export function filterUsersByAccess(data) {
    const composers = data.composers || [];
    const users = data.users || [];
    const defaultUserIdsWithAccessCount = {};
    const userIdsWithAccessCount = composers.reduce((prev, permissions) => {
        const userAccessCount = Object.assign({}, prev);
        permissions.forEach(permission => {
            if (permission.allowedActions.includes('read')) {
                const count = userAccessCount[permission.userId] || 0;
                Object.assign(userAccessCount, { [permission.userId]: count + 1 });
            }
        });
        return userAccessCount;
    }, defaultUserIdsWithAccessCount);
    const defaultUserFilteredAccess = {
        access: [],
        partialAccess: [],
        noAccess: [],
    };
    return users.reduce((prev, user) => {
        const userCount = userIdsWithAccessCount[user.id] || 0;
        if (!userCount) {
            return Object.assign(Object.assign({}, prev), { noAccess: [...prev.noAccess, user] });
        }
        if (userCount < data.countComposers) {
            return Object.assign(Object.assign({}, prev), { partialAccess: [...prev.partialAccess, user] });
        }
        return Object.assign(Object.assign({}, prev), { access: [...prev.access, user] });
    }, defaultUserFilteredAccess);
}
export function filterTeamsByAccess(data) {
    const composers = data.composers || [];
    const teams = data.teams || [];
    const defaultTeamIdsWithAccessCount = {};
    const teamIdsWithAccessCount = composers.reduce((prev, permissions) => {
        const teamAccessCount = Object.assign({}, prev);
        permissions.forEach(permission => {
            if (permission.allowedActions.includes('read')) {
                const count = teamAccessCount[permission.teamId] || 0;
                Object.assign(teamAccessCount, { [permission.teamId]: count + 1 });
            }
        });
        return teamAccessCount;
    }, defaultTeamIdsWithAccessCount);
    const defaultTeamFilteredAccess = {
        access: [],
        partialAccess: [],
        noAccess: [],
    };
    return teams.reduce((prev, team) => {
        const teamCount = teamIdsWithAccessCount[team.id] || 0;
        if (!teamCount) {
            return Object.assign(Object.assign({}, prev), { noAccess: [...prev.noAccess, team] });
        }
        if (teamCount < data.countComposers) {
            return Object.assign(Object.assign({}, prev), { partialAccess: [...prev.partialAccess, team] });
        }
        return Object.assign(Object.assign({}, prev), { access: [...prev.access, team] });
    }, defaultTeamFilteredAccess);
}
