import { unit } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const EntryName = styled.button `
  background: transparent;
  border: none;
  margin: 0;
  margin-right: ${unit('sm')};
  padding: 0;

  text-align: left;
  white-space: nowrap;

  &:not(:disabled) {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;
export const Spacer = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;
export const TableCellContainer = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${unit(4)};
`;
