import { UNASSIGNED_SIGNING_ORDER } from 'app/domains/esigning/utils';
/**
 *
 * @param signatory The signing / non signing party
 * @param useSigningOrder If the send out is using signing order
 * @param currentSigningOrder If the party has been updated and this is its current signing order
 * @returns `undefined` if the party is a non signing party
 * @returns `currentSigningOrder || -1` if the party is a signing party and the send out is using signing order
 * @returns `-1` if the party is a signing party / default signing party and the send out is not using signing order
 */
export const getSigningOrder = (signatory, useSigningOrder, currentSigningOrder) => {
    const { isSigning } = signatory;
    if (!isSigning) {
        return undefined;
    }
    if (useSigningOrder) {
        return currentSigningOrder || UNASSIGNED_SIGNING_ORDER;
    }
    return UNASSIGNED_SIGNING_ORDER;
};
