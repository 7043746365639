var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@apollo/client';
import { DocumentMetadataFeature } from 'shared/domains/apollo/generated/types';
import { AutotagFeatureAvailabilityDocument } from './AutotagFeatureAvailability.gql';
/**
 * Query to check if the company has access to the Auto tag feature.
 * @returns {boolean} Whether the autotag feature is available for the current user
 */
export function useAutotagFeatureAvailability() {
    const _a = useQuery(AutotagFeatureAvailabilityDocument), { data } = _a, rest = __rest(_a, ["data"]);
    const { companies } = data || {};
    const [company] = companies || [];
    const { documentMetadataFeatures } = company || {};
    const autoTagFeature = documentMetadataFeatures === null || documentMetadataFeatures === void 0 ? void 0 : documentMetadataFeatures.find(({ feature }) => feature === DocumentMetadataFeature.AiAutoTagging);
    const featureAvailability = Boolean(autoTagFeature);
    return Object.assign({ data: featureAvailability }, rest);
}
