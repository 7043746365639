import { Trans } from '@lingui/macro';
import { MenuItem, MenuList } from '@pocketlaw/tetris';
import { addDays, addMonths, addWeeks, isValid } from 'date-fns';
import { useField } from 'formik';
import { useCurrentDropdown } from '../hooks/useCurrentDropdown';
import { useOnChange } from '../hooks/useOnChange';
import { getDateByTimeBeforeDate } from '../utils';
export function MenuItems() {
    const { openCalendar } = useCurrentDropdown();
    const { handleChange } = useOnChange();
    const [dueDateField] = useField('dueDate');
    const dueDate = new Date(dueDateField.value || '');
    const setDateBeforeDueDate = (timeBeforeDueDate) => {
        const date = getDateByTimeBeforeDate({ date: dueDate, timeBefore: timeBeforeDueDate });
        handleChange(date);
    };
    const handleOpenCalendar = () => {
        openCalendar();
    };
    return (<MenuList>
      {isValid(dueDate) && dueDate > addDays(new Date(), 1) && (<MenuItem onClick={() => setDateBeforeDueDate('one-day')}>
          <Trans>1 day before due date</Trans>
        </MenuItem>)}
      {isValid(dueDate) && dueDate > addDays(new Date(), 2) && (<MenuItem onClick={() => setDateBeforeDueDate('two-days')}>
          <Trans>2 days before due date</Trans>
        </MenuItem>)}
      {isValid(dueDate) && dueDate > addWeeks(new Date(), 2) && (<MenuItem onClick={() => setDateBeforeDueDate('two-weeks')}>
          <Trans>2 weeks before due date</Trans>
        </MenuItem>)}
      {isValid(dueDate) && dueDate > addMonths(new Date(), 1) && (<MenuItem onClick={() => setDateBeforeDueDate('one-month')}>
          <Trans>1 month before due date</Trans>
        </MenuItem>)}
      <MenuItem onClick={handleOpenCalendar}>
        <Trans>Custom reminder</Trans>
      </MenuItem>
    </MenuList>);
}
