import { Trans, t, select } from '@lingui/macro';
import { MenuItem, Typography, Loader } from '@pocketlaw/tetris';
import { useUpdateComposerTeamPermissions, useComposersTable } from 'app/domains/templates';
import { TextContainer } from './styled';
export function UpdateTeamAccessMenuItem(props) {
    const { type, teamIds, withSubtitle = false } = props;
    const { drawerData } = useComposersTable();
    const composerIds = (drawerData === null || drawerData === void 0 ? void 0 : drawerData.composerIds) || [];
    const { updateComposerTeamPermissions, loading, refetching } = useUpdateComposerTeamPermissions();
    const handleClick = () => updateComposerTeamPermissions({ type, teamIds, composerIds });
    const title = t({
        comment: 'Contract access dropdown option: Title',
        message: select(type, {
            add: 'Grant access',
            remove: 'Remove access',
            other: 'Grant access',
        }),
    });
    return (<MenuItem disabled={loading || refetching} onClick={handleClick}>
      <TextContainer>
        {title}
        {withSubtitle && (<Typography $fontSize="small" $appearance="200">
            <Trans comment="Contract team access dropdown option: Subtitle">For all teams</Trans>
          </Typography>)}
      </TextContainer>
      {loading && <Loader />}
    </MenuItem>);
}
