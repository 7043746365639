import { gql } from '@apollo/client';
export const getComposerNameQuery = gql `
  query getComposerName($composerId: Unstable__UUID!) {
    composer: unstable__composer(id: $composerId) {
      id

      metadata {
        id
        name
      }
    }
  }
`;
