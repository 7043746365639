import { t } from '@lingui/macro';
import { Alert } from '@pocketlaw/tetris';
import { useField } from 'formik';
export function RoleInfo() {
    const [field] = useField('role');
    const showInfo = field.value === 'member' || field.value === 'admin';
    if (!showInfo) {
        return null;
    }
    return (<Alert appearance="info" title={t({ message: 'Adjust authorization and access', comment: 'Role info alert title' })} message={t({
            message: 'When the new user has connected to your account you can easily adjust what folders and documents the user should have access to',
            comment: 'role info alert message',
        })}/>);
}
