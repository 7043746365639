import { memo } from 'react';
import { Typography, H4 } from '@pocketlaw/tetris';
import { useTransitionState } from 'shared/domains/common-ui';
import { Overlay, SliceInfoContainer } from './styled';
export const SliceInfoItem = memo((props) => {
    const { active, name, value } = props;
    const state = useTransitionState(active, 300);
    const displayValue = value >= 10000 ? '10,000+' : value;
    return (<Overlay>
      <SliceInfoContainer $state={state}>
        <Typography>{name}</Typography>
        <H4>{displayValue}</H4>
      </SliceInfoContainer>
    </Overlay>);
});
