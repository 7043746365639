import React from 'react';
import { SidebarSectionLoader } from 'app/domains/documents';
import { MetadataListContainer, MetadataList, SystemMetadataList, DocumentMetadataProvider, MetadataActions, } from 'app/domains/metadata';
import { useActiveDocument, DocumentDrawerHeader } from 'shared/domains/documents';
import { DrawerContainer } from '../../styled';
import { getTitle } from '../../utils';
import { Container } from './styled';
export function Metadata(props) {
    const { onClose, onGoBack } = props;
    const { id } = useActiveDocument();
    const title = getTitle('metadata');
    return (<DrawerContainer>
      <DocumentDrawerHeader type="nested" title={title} onClose={onClose} onGoBack={onGoBack}/>
      <Container>
        <DocumentMetadataProvider documentId={id} loadingFallback={<SidebarSectionLoader />}>
          <MetadataListContainer>
            <MetadataActions />
            <SystemMetadataList />
            <MetadataList />
          </MetadataListContainer>
        </DocumentMetadataProvider>
      </Container>
    </DrawerContainer>);
}
