import { AvatarWithLabel } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { EntryKind } from 'shared/domains/apollo/generated/types';
import { Container } from './styled';
export function Entry(props) {
    const { kind, title } = props;
    switch (kind) {
        case EntryKind.Folder:
            return (<Container>
          <AvatarWithLabel size="md" shape="square" appearance="success" title={title}>
            <Falcon icon="folder"/>
          </AvatarWithLabel>
        </Container>);
        case EntryKind.Document:
        default:
            return (<Container>
          <AvatarWithLabel size="md" shape="square" title={title}>
            <Falcon icon="file"/>
          </AvatarWithLabel>
        </Container>);
    }
}
