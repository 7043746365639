import { Alignment, Bold, Essentials, FontSize, FontColor, FontBackgroundColor, Heading, Indent, IndentBlock, Italic, Link, List, AdjacentListsSupport, Notification, PageBreak, Paragraph, Permissions, Strikethrough, Table, TableToolbar, TableCaption, TableProperties, TableColumnResize, TableCellProperties, Underline, ComposedHighlight, ListAttributesExtender, BalloonToolbar, Widget, AttachmentStore, AttachmentWidgetEditing, InsertAttachmentWidgetEditing, } from 'pleditor';
import { AppApiConnector } from '../../plugins/AppApiConnector';
import { InsertAttachmentWidgetUI } from '../../plugins/Attachments';
import { EditMode } from '../../plugins/EditMode';
import { FlexGap } from '../../plugins/FlexGap';
import { InlineComment } from '../../plugins/InlineComment';
import { OffsetPlugin } from '../../plugins/OffsetPlugin';
import { ReviewPlugin } from '../../plugins/Review';
import { ToastHandler } from '../../plugins/ToastHandler';
import { ToggleComments } from '../../plugins/ToggleComments';
import { TopToolbar } from '../../plugins/TopToolbar';
export const SANDBOX_PLUGINS = [
    // CKEditor
    Alignment,
    BalloonToolbar,
    Bold,
    Essentials,
    FontSize,
    FontColor,
    FontBackgroundColor,
    Heading,
    Italic,
    Indent,
    IndentBlock,
    Link,
    List,
    AdjacentListsSupport,
    Notification,
    PageBreak,
    Paragraph,
    Permissions,
    Strikethrough,
    Table,
    TableToolbar,
    TableCaption,
    TableColumnResize,
    TableProperties,
    TableCellProperties,
    Underline,
    Widget,
    // Custom
    ComposedHighlight,
    ListAttributesExtender,
    AppApiConnector,
    TopToolbar,
    FlexGap,
    OffsetPlugin,
    ToastHandler,
    ToggleComments,
    InlineComment,
    EditMode,
    ReviewPlugin,
    AttachmentStore,
    AttachmentWidgetEditing,
    InsertAttachmentWidgetEditing,
    InsertAttachmentWidgetUI,
];
