import { gql } from '@apollo/client';
export const getSignRequestAttachments = gql `
  query getSignRequestAttachments($id: ID!) {
    document: fileSystemDocument(id: $id) {
      id
      lastSignRequest {
        id
        attachments {
          id
          name
        }
      }
    }
  }
`;
