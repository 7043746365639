import { themeGet } from '@pocketlaw/tetris';
import { css } from 'styled-components';
export const revisionHistoryToolbarStyles = css `
  & > div:last-child {
    border-radius: ${themeGet('borderRadius.md')};

    border-collapse: collapse;
    cursor: auto;
    float: none;
    text-align: left;
    white-space: nowrap;
    color: var(--ck-color-text);
    font: normal normal normal var(--ck-font-size-base) / var(--ck-line-height-base)
      var(--ck-font-face);

    .ck-revision-history-ui__back-to-editing {
      color: inherit;
    }

    .ck-button {
      display: inline-flex;
      align-items: center;
      gap: ${themeGet('spacing.md')};

      & > span {
        line-height: normal;
      }
    }
  }
`;
