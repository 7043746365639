import { useMemo } from 'react';
import { t } from '@lingui/macro';
import { AvatarWithLabel } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { usePrevious } from 'react-use';
import { usePreview } from '../../../hooks/usePreview';
import { DocumentPageContainer } from '../../styled';
import { SkipDocumentContent } from '../SkipDocumentContent';
import { ZoomManager } from '../ZoomManager';
import { getFileExtensionName } from '../utils/getFileExtensionName';
import { PageManagers, Content, PaperLimit, Paper, StyledLoader, EmptyContainer } from './styled';
export function BasePreview(props) {
    var _a;
    const { loading = false, disableZoom = false, children, file, pageManagers } = props;
    const endOfDocumentId = 'end-of-document';
    const { dimensions, zoom, zoomRef } = usePreview();
    const previousDimensions = usePrevious(dimensions);
    const shouldAnimate = Boolean(dimensions && previousDimensions);
    const fileExtension = (_a = file === null || file === void 0 ? void 0 : file.name) === null || _a === void 0 ? void 0 : _a.split('.').pop();
    const fileExtensionName = useMemo(() => { var _a; return (_a = getFileExtensionName(fileExtension)) !== null && _a !== void 0 ? _a : ''; }, [fileExtension]);
    const emptyTitle = t({
        message: `Uploaded ${fileExtensionName} document`,
        comment: 'document preview unsupported document type title',
    });
    const emptySubtitle = t({
        message: 'We do not currently support previewing documents of this file type.',
        comment: 'document preview unsupported document type subtitle',
    });
    if (!children && !loading) {
        return (<DocumentPageContainer>
        <EmptyContainer>
          <AvatarWithLabel alignment="vertical" size="2xl" shape="square" title={emptyTitle} subtitle={emptySubtitle}>
            <Falcon icon="eye-slash"/>
          </AvatarWithLabel>
        </EmptyContainer>
      </DocumentPageContainer>);
    }
    return (<DocumentPageContainer>
      <Content>
        <PaperLimit $shouldAnimate={shouldAnimate} $dimensions={dimensions}>
          <Paper ref={zoomRef} $shouldAnimate={shouldAnimate} $zoom={zoom}>
            <SkipDocumentContent target={endOfDocumentId}/>

            {loading && <StyledLoader size={16}/>}

            {children}
          </Paper>
        </PaperLimit>
        <div id={endOfDocumentId}/>
      </Content>

      <PageManagers>
        {pageManagers}
        {!disableZoom && <ZoomManager />}
      </PageManagers>
    </DocumentPageContainer>);
}
