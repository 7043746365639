import { visitParents as visit } from 'unist-util-visit-parents';

import Store from './Store';
import { addLineBreaks } from './utils';

const truthy = value => Boolean(value);

function falsy(value) {
  return !value && value != null;
}

const condition = (predicate, value) => {
  switch (predicate) {
    case 'assuming':
      return truthy(value);
    case 'unless':
      return falsy(value);
    default:
      throw new Error(`markstace-replace: Unexpected predicate ${predicate}.`);
  }
};

export function markstacheReplace({ wrap = true } = {}) {
  const store = new Store(this.data());

  return ast => {
    const visitor = (node, ancestors) => {
      const parent = ancestors[ancestors.length - 1];
      const value = store.get(node.key);
      const index = parent.children.indexOf(node);

      if (node.type !== 'text' && node.children.length === 0) {
        parent.children.splice(index, 1);

        return [visit.SKIP, index];
      }

      if (condition(node.predicate, value)) {
        if (wrap) {
          return visit.CONTINUE;
        }

        parent.children.splice(index, 1, ...node.children);
      } else if (wrap) {
        parent.children.splice(index, 1, { ...node, children: [] });

        return visit.CONTINUE;
      } else {
        parent.children.splice(index, 1);
      }

      return [visit.SKIP, index];
    };

    visit(ast, ['condition', 'binary'], visitor);

    visit(ast, 'variable', (node, ancestors) => {
      const parent = ancestors[ancestors.length - 1];
      const value = (store.get(node.key) || '').toString().trim();
      const index = parent.children.indexOf(node);

      parent.children.splice(index, 1, {
        ...node,
        children: addLineBreaks(value),
      });

      return visit.SKIP;
    });
  };
}
