import { t } from '@lingui/macro';
import { Box, Button, ButtonGroup, Typography, useGeneratedId } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { isDate } from 'date-fns';
import { localizedFormatDate } from 'shared/domains/common-utils';
export function DateButton(props) {
    const { date, label, onClick, onReset } = props;
    const id = useGeneratedId('dateButton');
    const safeDate = isDate(date) ? date.toISOString() : date;
    const formattedDate = localizedFormatDate(safeDate);
    return (<Box flexDirection="column">
      <Typography id={id} $fontSize="small" $fontWeight="semibold">
        {label}
      </Typography>

      <ButtonGroup>
        <Button aria-describedby={id} appearance="secondary" $stretch onClick={onClick} type="button" size="small">
          <Falcon icon="calendar"/>
          {date
            ? formattedDate
            : t({
                message: 'Set date',
                comment: 'Date range filter: "Set date" button label',
            })}
        </Button>
        {date && (<Button type="button" onClick={onReset} appearance="secondary" size="small">
            <Falcon icon="xmark"/>
          </Button>)}
      </ButtonGroup>
    </Box>);
}
