import { useLingui } from '@lingui/react';
import { getBadgePropsFromEventType, getTimePassedFromNow, getTitleByEventType } from './utils';
import { CustomListItem } from '../../CustomListItem';
export function EventListItem(props) {
    const { userName, eventType, approverUserNames, message, createdAt } = props;
    const { i18n } = useLingui();
    return (<CustomListItem avatarLetter={userName[0].toUpperCase()} primary={getTitleByEventType({
            type: eventType,
            userName,
            approverUserNames,
            locale: i18n.locale,
        })} secondary={message} tertiary={getTimePassedFromNow(createdAt)} badge={getBadgePropsFromEventType(eventType)}/>);
}
