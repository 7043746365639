import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Context } from './Context';
export function FocusManagerProvider(props) {
    const { children } = props;
    const previousFocusedFieldRef = useRef();
    const focusedFieldsRef = useRef([]);
    const [focusedFieldId, setFocusedFieldId] = useState(undefined);
    const focusRef = useCallback(elementNode => {
        const refIsResetting = elementNode === null;
        if (!refIsResetting) {
            focusedFieldsRef.current.push(elementNode);
        }
    }, []);
    useLayoutEffect(() => {
        const [focusedField] = focusedFieldsRef.current;
        const previousFocusedField = previousFocusedFieldRef.current;
        if (focusedField && focusedField !== previousFocusedField) {
            focusedField.scrollIntoView({
                behavior: 'smooth',
            });
            previousFocusedFieldRef.current = focusedField;
        }
        focusedFieldsRef.current = [];
    }, [focusedFieldId, children]);
    const value = useMemo(() => ({ focusRef, focusedFieldId, setFocusedFieldId }), [focusRef, focusedFieldId, setFocusedFieldId]);
    return <Context.Provider value={value}>{children}</Context.Provider>;
}
