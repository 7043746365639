import { Trans } from '@lingui/macro';
import { Button, Typography } from '@pocketlaw/tetris';
import { Overlay, ButtonContainer } from './styled';
function ConfirmAction(props) {
    const { display, onConfirm, onCancel } = props;
    if (!display) {
        return null;
    }
    return (<Overlay>
      <Typography>
        <Trans comment="close task modal verification message">
          You have unsaved changes. Do you want to continue?
        </Trans>
      </Typography>
      <ButtonContainer>
        <Button type="button" appearance="secondary" onClick={onCancel}>
          <Trans comment="close task modal verification continue with current changes text">
            Continue editing
          </Trans>
        </Button>
        <Button type="button" appearance="danger" onClick={onConfirm}>
          <Trans comment="close task modal verification discard current changes text">
            Discard changes
          </Trans>
        </Button>
      </ButtonContainer>
    </Overlay>);
}
export { ConfirmAction };
