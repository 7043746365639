import { Trans } from '@lingui/macro';
import { Typography } from '@pocketlaw/tetris';
import { SummarizeComposerTable } from './SummarizeComposerTable';
import { SummarizeEmailTable } from './SummarizeEmailTable';
import { SummarizeRoleTable } from './SummarizeRoleTable';
import { Container } from './styled';
export function Summarize() {
    return (<Container>
      <Typography>
        <Trans comment="invite user modal - summarize step description">
          Check that the details and template access is correct before you send the invitation.
        </Trans>
      </Typography>

      <SummarizeEmailTable />
      <SummarizeRoleTable />
      <SummarizeComposerTable />
    </Container>);
}
