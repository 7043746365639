import { pxToRem, TabContent, TabNav, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const StyledTabNav = styled(TabNav) `
  margin: 0 ${themeGet('spacing.7xl')};
`;
export const StyledTabContent = styled(TabContent) `
  margin: ${themeGet('spacing.2xl')} ${themeGet('spacing.7xl')} 0;
  overflow-y: auto;
  min-height: ${pxToRem(200)};
`;
