import { plural, Trans, t } from '@lingui/macro';
import { Typography } from '@pocketlaw/tetris';
import { useFormatDate } from 'app/domains/users';
import { Container, Ul, Li } from './styled';
export function DropdownContent(props) {
    const { members, createdAt } = props;
    const membersCount = (members === null || members === void 0 ? void 0 : members.length) || 0;
    const date = useFormatDate(createdAt);
    const membersMessage = t({
        comment: 'Template table dropdown content - total members',
        message: plural(membersCount, {
            one: '# user',
            other: '# users',
        }),
    });
    return (<Container>
      <Ul>
        <Li>
          <Typography $appearance="300" $fontSize="small">
            <Trans comment="Teams table dropdown content - date added label">Date added</Trans>
          </Typography>
          <Typography $appearance="200" $fontSize="small">
            {date}
          </Typography>
        </Li>
        <Li>
          <Typography $appearance="300" $fontSize="small">
            <Trans comment="Teams table dropdown content - team members label">Team Members</Trans>
          </Typography>
          <Typography $appearance="200" $fontSize="small">
            {membersMessage}
          </Typography>
        </Li>
      </Ul>
    </Container>);
}
