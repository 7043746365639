import { Link } from 'react-router-dom';
import { StyledTypography, StyledTableCell, StyledButton } from './styled';
export function NameCell(props) {
    const { id, name } = props;
    return (<StyledTableCell headers="nameCell">
      <StyledButton $stretch as={Link} to={`/document/${id}`} appearance="ghost">
        <StyledTypography $appearance="300" $fontSize="medium">
          {name}
        </StyledTypography>
      </StyledButton>
    </StyledTableCell>);
}
