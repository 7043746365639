import { Fragment } from 'react';
import { t } from '@lingui/macro';
import { IconButton, MenuBody, MenuHeader, MenuList } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { ThemeSelector } from 'app/domains/common-ui';
import { AccountInfoSection } from '../AccountInfoSection';
import { CompanySelector } from '../CompanySelector';
import { LanguageSelector } from '../LanguageSelector';
export function UserAccountMenuContent(props) {
    const { onClose } = props;
    return (<Fragment>
      <MenuHeader action={<IconButton $round appearance="ghost" size="small" onClick={onClose}>
            <Falcon icon="close"/>
          </IconButton>} title={t({
            message: 'User account',
            comment: 'User menu: header text',
        })}/>
      <MenuBody>
        <MenuList>
          <AccountInfoSection />
          <CompanySelector />
          <LanguageSelector />
          <ThemeSelector />
        </MenuList>
      </MenuBody>
    </Fragment>);
}
