import { useQuery } from '@apollo/client';
import { externalSharingInvitesQuery } from './query';
import { useActiveDocument } from '../usePreview';
export function useExternalSharingInvites() {
    const { id } = useActiveDocument();
    const { data, loading, refetch } = useQuery(externalSharingInvitesQuery, {
        variables: { documentId: id },
    });
    const invites = (data === null || data === void 0 ? void 0 : data.externalSharingInvites) || [];
    return { invites, loading, refetch };
}
