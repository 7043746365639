import { t } from '@lingui/macro';
import { AvatarWithLabel, DialogHeader } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { FormDialog } from 'shared/domains/forms';
import { Content } from '../Content';
import { Footer } from '../Footer';
export function LikeDialog(props) {
    const { commentId, onClose, onLike, mutationError } = props;
    const initialValues = {
        id: commentId,
        feedback: '',
    };
    return (<FormDialog size="sm" onClose={onClose} onSubmit={onLike} initialValues={initialValues}>
      <DialogHeader media={<AvatarWithLabel truncate title={t({
                message: 'Provide feedback',
                comment: 'Document assistant - feedback dialog - title',
            })} shape="circle">
            <Falcon icon="thumbs-up"/>
          </AvatarWithLabel>}/>
      <Content error={mutationError}/>
      <Footer onClose={onClose}/>
    </FormDialog>);
}
