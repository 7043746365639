import { Box } from '@pocketlaw/tetris';
import { useHistory } from 'react-router-dom';
import { Bar, BarChart as RechartsBarChart, XAxis, YAxis, ResponsiveContainer, Tooltip, } from 'recharts';
import { CustomBar } from './CustomBar';
import { VerticalTick } from './VerticalTick';
import { VERTICAL_Y_AXIS_WIDTH } from './constants';
import { sliceToURL } from './sliceToURL';
import { CartesianGrid } from '../CartesianGrid';
import { EmptyVisualization } from '../EmptyVisualization';
import { TooltipContent } from '../TooltipContent';
import { Unsupported } from '../Unsupported';
import { VisualizationMeta } from '../VisualizationMeta';
import { MIN_RECHART_HEIGHT, RECHARTS_FONT_SIZE, TOOLTIP_CURSOR_FILL } from '../constants';
import { getDateHistogramDiff } from '../getDateHistogramDiff';
import { getTheme } from '../getTheme';
import { transformAggregation } from '../transformData';
import { bucketTypeGuard } from '../typeGuards';
export function BarChart(props) {
    const { data, totalHitsCount, aggregations, colorScheme, layout, filters } = props;
    const history = useHistory();
    const [dataPoint] = data || [];
    const [aggregation] = aggregations || [];
    const isBucketAggregation = bucketTypeGuard(dataPoint);
    const { entries, keys, missing, count, breakdown: hasBreakdown, } = transformAggregation(dataPoint, aggregation);
    if (!dataPoint || !keys.length) {
        return <EmptyVisualization />;
    }
    if (!isBucketAggregation) {
        return <Unsupported />;
    }
    const handleClick = (event, key) => {
        const url = sliceToURL(event.payload, key, filters);
        history.push(url);
    };
    const { sumOtherCount } = dataPoint || {};
    const diffValue = getDateHistogramDiff(dataPoint);
    const theme = getTheme(colorScheme);
    return (<Box flexDirection="column" width="100%" height="100%">
      <VisualizationMeta count={count} missing={missing} sumOtherCount={sumOtherCount || 0} totalHitsCount={totalHitsCount} diffValue={diffValue} aggregation={aggregation}/>
      <ResponsiveContainer minHeight={MIN_RECHART_HEIGHT} width="100%">
        <RechartsBarChart data={entries} margin={{ top: 0, right: 0, left: 0, bottom: 0 }} maxBarSize={40} layout={layout}>
          <Tooltip animationDuration={200} cursor={{ fill: TOOLTIP_CURSOR_FILL }} shared={false} content={<TooltipContent />}/>
          <XAxis tickLine={false} dataKey={layout === 'vertical' ? 'total' : 'name'} type={layout === 'vertical' ? 'number' : 'category'} axisLine={false} tick={{ fontSize: RECHARTS_FONT_SIZE, dy: 4 }} interval="preserveStartEnd" minTickGap={64}/>
          <YAxis dataKey={layout === 'vertical' ? 'name' : 'total'} width={layout === 'vertical' ? VERTICAL_Y_AXIS_WIDTH : undefined} type={layout === 'vertical' ? 'category' : 'number'} axisLine={false} tickLine={false} allowDecimals={false} 
    // @ts-expect-error The missing props TSC complains about will be injected by Recharts
    tick={layout === 'vertical' ? <VerticalTick /> : { fontSize: RECHARTS_FONT_SIZE }} interval={layout === 'vertical' ? 0 : undefined}/>

          {layout === 'horizontal' && <CartesianGrid />}

          {keys.map((key, index) => (<Bar layout={layout} key={key} dataKey={key} onClick={event => handleClick(event, key)} stackId="a" 
        // @ts-expect-error The missing props TSC complains about will be injected by Recharts
        shape={<CustomBar stroke="#fff"/>} fill={theme(hasBreakdown ? 'breakdown' : 'single', index)}/>))}
        </RechartsBarChart>
      </ResponsiveContainer>
    </Box>);
}
