import { t } from '@lingui/macro';
import { EmptyState } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
export function EmptyList(props) {
    const { completed = false } = props;
    if (completed) {
        return (<EmptyState withAvatar size="small" icon={<Falcon icon="list-check"/>} title={t({
                message: 'Nothing completed!',
                comment: 'Task widget no completed tasks - title',
            })} description={t({
                message: 'Get started by creating a new task.',
                comment: 'Task widget no completed tasks - description',
            })}/>);
    }
    return (<EmptyState withAvatar size="small" icon={<Falcon icon="check"/>} title={t({
            message: 'Well done!',
            comment: 'Task widget no uncompleted tasks - title',
        })} description={t({
            message: 'You have completed all your tasks.',
            comment: 'Task widget no uncompleted tasks - description',
        })}/>);
}
