import { useEffect, useRef } from 'react';
import { useMount } from 'react-use';
import { AssistantMessage } from './AssistantMessage';
import { ChatActions } from './ChatActions';
import { ChatBox } from './ChatBox';
import { UserMessage } from './UserMessage';
import { usePlai } from '../../PlaiProvider';
import { ChatContainer, Container, ScrollAnchor, Thread } from './styled';
export function Chat(props) {
    const { feedback, send, messages, actions, userName } = props;
    const { feedbackHighlightsData } = usePlai();
    const { documentHighlights = [] } = feedbackHighlightsData || {};
    const anchorRef = useRef(null);
    const isStreaming = Boolean(messages.find(({ writing }) => writing));
    const isRewriteAvailable = documentHighlights.length > 0;
    const sendRewriteMessage = () => {
        if (!isRewriteAvailable) {
            return;
        }
        const message = `Please write me rewrite suggestions for the most relevant clauses related to this issue. 
    \n${documentHighlights.map(highlight => `${`<clause><hid>${highlight.id}</hid>\n${highlight.text.trim()}\n</clause>`}`).join('\n')}`;
        send(message);
    };
    useEffect(() => {
        if (anchorRef.current) {
            anchorRef.current.scrollIntoView();
        }
    }, [messages, actions]);
    useMount(() => {
        sendRewriteMessage();
    });
    return (<Container>
      <ChatContainer>
        <Thread>
          <AssistantMessage message={feedback.feedback.content}/>
          {messages.map(({ messageId, role, message, writing }) => role === 'assistant' ? (<AssistantMessage key={messageId} message={message} writing={writing} actions={actions[messageId]}/>) : (<UserMessage key={messageId} message={message} userName={userName} writing={writing}/>))}
          <ScrollAnchor key="scroll-anchor" ref={anchorRef}/>
        </Thread>
      </ChatContainer>
      <ChatActions feedback={feedback} isStreaming={isStreaming} onRewriteClick={sendRewriteMessage}/>
      <ChatBox userName={userName} isStreaming={isStreaming} onSendMessage={send}/>
    </Container>);
}
