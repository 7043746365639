import { useState, useLayoutEffect, useCallback, useEffect } from 'react';
import { useMobile } from 'shared/domains/common-ui';
const DEFAULT_ZOOM = 0.75;
const ZOOM_LEVELS = [0.5, 0.75, 0.9, 1, 1.25, 1.5, 2];
function useDimensions(options) {
    const { ref, disableZoom, previewType } = options;
    const isMobile = useMobile();
    const [zoom, setZoom] = useState(1);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    useEffect(() => {
        if (!(ref === null || ref === void 0 ? void 0 : ref.current)) {
            return;
        }
        const fitZoom = Math.min(window.outerWidth / ref.current.offsetWidth, 1);
        const defaultZoom = disableZoom ? 1 : DEFAULT_ZOOM;
        const initZoom = isMobile ? fitZoom : defaultZoom;
        setZoom(initZoom);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile, disableZoom]);
    useLayoutEffect(() => {
        function calculate() {
            if (!ref.current) {
                return;
            }
            const width = ref.current.offsetWidth * zoom;
            const height = ref.current.offsetHeight * zoom;
            setDimensions({ width, height });
        }
        calculate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zoom, previewType]);
    const zoomTo = useCallback((inc) => {
        const currentIndex = ZOOM_LEVELS.indexOf(zoom);
        const desiredIndex = currentIndex + inc;
        const nextLevel = ZOOM_LEVELS[desiredIndex];
        if (nextLevel) {
            setZoom(nextLevel);
        }
    }, [zoom]);
    return { dimensions, zoom, zoomTo };
}
export { useDimensions, DEFAULT_ZOOM };
