import { breakpoints, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${themeGet('spacing.xl')};
`;
export const Container = styled.div `
  display: flex;
  flex-direction: row;

  ${breakpoints.sm} {
    max-width: 20rem;
  }
`;
