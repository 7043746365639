const endpoints = {
    default: process.env.API_HTTP_URL,
    esPublic: process.env.EXTERNAL_SERVICE_URL,
};
/**
 * If specifying 'endpoint' in the context when doing a query or mutation
 * we can call different API endpoints.
 *
 * useQuery(query, { context: { endpoint: key } });
 *
 * @param operation
 * @returns
 */
function getEndpoint(operation) {
    const { endpoint } = operation.getContext();
    if (!endpoint) {
        return endpoints.default;
    }
    const selectedEndpoint = endpoints[endpoint];
    return selectedEndpoint;
}
export default getEndpoint;
