import { FileBrowserProvider, ROOT } from 'app/domains/filesystem';
import { EntryKind } from 'shared/domains/apollo/generated/types';
import { PickerBreadcrumbs } from './PickerBreadcrumbs';
import { PickerDialog } from './PickerDialog';
import { PickerFooter } from './PickerFooter';
import { PickerHeader } from './PickerHeader';
import { PickerLoaderOverlay } from './PickerLoaderOverlay';
import { PickerContext, PickerProvider } from './PickerProvider';
import { PickerTableFilesystem } from './PickerTableFilesystem';
import { PickerPermissionWarning } from './PickerWarnings/PickerPermissionWarning';
import { PickerRootWarning } from './PickerWarnings/PickerRootWarning';
export function PickFolderDialog(props) {
    const { dialogTitle, dialogSubtitle, submitLabel, initialFolderId = ROOT, selectedEntryAlert, loaderOverlay, onClose, onSubmit, } = props;
    const selectable = (entry) => entry.kind === EntryKind.Folder;
    return (<FileBrowserProvider customLoadingState showSignRequestStatus selectable={selectable} parentId={initialFolderId}>
      <PickerProvider initialEntryId={initialFolderId} onSubmit={onSubmit}>
        <PickerContext.Consumer>
          {ctx => ctx && (<PickerDialog>
                <PickerHeader title={dialogTitle} subtitle={dialogSubtitle} onClose={onClose}/>
                <PickerBreadcrumbs />
                <PickerTableFilesystem selectType="folder" selectedEntryAlert={selectedEntryAlert}/>
                <PickerFooter showPagination showCreateFolder submitLabel={submitLabel} onSubmit={ctx.submit}/>
                <PickerRootWarning show={ctx.showRootWarning} onClose={ctx.hideRootWarning} onSubmit={ctx.submit}/>
                <PickerPermissionWarning show={ctx.showRootWarning} onClose={ctx.hideRootWarning}/>
                {loaderOverlay && <PickerLoaderOverlay {...loaderOverlay}/>}
              </PickerDialog>)}
        </PickerContext.Consumer>
      </PickerProvider>
    </FileBrowserProvider>);
}
