import { t } from '@lingui/macro';
export const getTableHeaders = () => ({
    dropDown: {
        id: 'dropDown',
        label: t({ message: 'Name', comment: 'Users table - table header name column' }),
    },
    name: {
        id: 'name',
        label: t({ message: 'Name', comment: 'Users table - table header name column' }),
    },
    createdAt: {
        id: 'created_at',
        label: t({
            message: 'Date added',
            comment: 'Users table - table header date added column',
        }),
    },
    role: {
        id: 'role',
        label: t({
            message: 'User role',
            comment: 'Users table - table header user role column',
        }),
    },
    action: {
        id: 'action',
        label: t({
            message: 'Action',
            comment: 'Users table - table header action button column (screen readers only)',
        }),
    },
});
