import { useCompanyFeatures } from 'app/domains/companies';
import { useDocumentApproval } from 'app/domains/esigning/hooks/useDocumentApproval';
import { useLastApprovalRequest } from 'app/domains/esigning/hooks/useLastApprovalRequest';
import { ApprovalRequestApproverStatus, ApprovalRequestStatus, ApprovalType, } from 'shared/domains/apollo/generated/types';
import { useIsCurrentUserApprover } from './useIsCurrentUserApprover';
const REQUESTED_STATUSES = [ApprovalRequestStatus.Pending, ApprovalRequestStatus.Approved];
const NOT_REQUESTED_STATUSES = [ApprovalRequestStatus.Cancelled, ApprovalRequestStatus.Rejected];
export const useApprovalWorkflow = (documentId) => {
    const { documentApproval, loading: loadingApproval } = useDocumentApproval(documentId);
    const { lastApprovalRequest, loading: loadingLastRequest } = useLastApprovalRequest(documentId);
    const { isFeatureAvailable } = useCompanyFeatures();
    const currentUser = useIsCurrentUserApprover(documentId);
    const documentRequiresApproval = Boolean(documentApproval);
    const approvalWorkflowAvailable = isFeatureAvailable('approval');
    const approvalRequired = approvalWorkflowAvailable && documentRequiresApproval;
    const approvalRequestId = lastApprovalRequest === null || lastApprovalRequest === void 0 ? void 0 : lastApprovalRequest.id;
    const approvalType = documentApproval === null || documentApproval === void 0 ? void 0 : documentApproval.type;
    const status = approvalRequired ? (lastApprovalRequest === null || lastApprovalRequest === void 0 ? void 0 : lastApprovalRequest.status) || null : null;
    const approvalNotRequested = approvalRequired && (status === null || NOT_REQUESTED_STATUSES.includes(status));
    const approvalRequested = approvalRequired && Boolean(status && REQUESTED_STATUSES.includes(status));
    const approvalPending = status === ApprovalRequestStatus.Pending;
    const approvalApproved = status === ApprovalRequestStatus.Approved;
    const approvalLoading = loadingApproval || loadingLastRequest;
    const { approvers = [] } = lastApprovalRequest || {};
    const approvedApprovers = approvers.filter(approver => approver && approver.status === ApprovalRequestApproverStatus.Approved);
    const approvalAmount = {
        current: approvedApprovers.length,
        max: approvalType === ApprovalType.All ? approvers.length : 1,
    };
    return {
        approvalWorkflowAvailable,
        approvalNotRequested,
        approvalRequested,
        approvalPending,
        approvalApproved,
        approvalLoading,
        approvalRequestId,
        approvalType,
        approvalAmount,
        currentUser,
    };
};
