import { t } from '@lingui/macro';
import { withFilterErrorBoundary } from './FilterErrorBoundary';
import { SearchStringFilter } from './SearchStringFilter';
import { useGetUsers } from './useGetUsers/useGetUsers';
export const contractOwnerFilterLabel = () => t({ message: 'Contract owner', comment: 'Insights filter: Contract owner' });
export const ContractOwnerFilter = withFilterErrorBoundary(() => {
    const { data, error, loading } = useGetUsers();
    const disabled = Boolean(loading || error);
    return (<SearchStringFilter name="metadataDocumentContractOwner" items={data} label={contractOwnerFilterLabel()} loading={disabled}/>);
});
