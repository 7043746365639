import { useRouteMatch } from 'react-router-dom';
import { SkipNav } from 'shared/domains/common-ui';
import { DesktopNavigationProvider } from './components/DesktopNavigation';
import { MobileNavigationProvider } from './components/MobileNavigation';
import { Sidebar } from './components/Sidebar';
import { TopNavigationBar } from './components/TopNavigationBar';
import { Content, PageContainer } from './styled';
export function PageLayout(props) {
    const { children } = props;
    const startOfContentId = 'start-of-content';
    const isDocumentScreen = useRouteMatch('/document/:id');
    const hideTopNavBar = Boolean(isDocumentScreen);
    return (<DesktopNavigationProvider>
      <MobileNavigationProvider>
        <SkipNav target={startOfContentId}/>
        <PageContainer>
          <Sidebar />
          <Content>
            {!hideTopNavBar && <TopNavigationBar />}
            <div id={startOfContentId}/>
            {children}
          </Content>
        </PageContainer>
      </MobileNavigationProvider>
    </DesktopNavigationProvider>);
}
