import { t } from '@lingui/macro';
import { Box, MenuFooter } from '@pocketlaw/tetris';
import { useField } from 'formik';
import { SubmitButton, ErrorMessage } from 'shared/domains/forms';
export function FormSubmitButton(props) {
    const { disabled = false } = props;
    const [field] = useField('id');
    const saveButtonText = t({
        message: 'Create',
        comment: 'Document metadata: Save button',
        id: 'metadata.field.action.create',
    });
    const editButtonText = t({
        message: 'Update',
        comment: 'Document metadata: Update button',
        id: 'metadata.field.action.update',
    });
    return (<MenuFooter sticky>
      <Box flexDirection="column" width="100%">
        <ErrorMessage name="value"/>
        <SubmitButton $stretch size="small" disabled={disabled}>
          {field.value ? editButtonText : saveButtonText}
        </SubmitButton>
      </Box>
    </MenuFooter>);
}
