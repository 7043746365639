import * as yup from 'yup';
import { Form } from 'shared/domains/forms';
import { FormField } from './FormField';
import { useMetadataField } from '../useMetadataField';
import { validateValue } from '../utils';
import { convertJsonSchemaToYup } from '../validationSchema';
export function MetadataForm(props) {
    const { onSubmit } = props;
    const { pair, property } = useMetadataField();
    const { schema } = property;
    const yupSchema = convertJsonSchemaToYup(schema);
    const value = validateValue(pair.value, property.type);
    const validationSchema = yup.object().shape({ value: yupSchema });
    return (<Form enableReinitialize initialValues={{
            id: pair.id,
            value,
        }} onSubmit={onSubmit} validationSchema={validationSchema}>
      <FormField />
    </Form>);
}
