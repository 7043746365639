import { closestTo } from 'date-fns';
export function getBucketValueByDate(date, buckets) {
    const dates = buckets
        .map(bucket => bucket.to)
        .flatMap(to => (to ? [to] : [])) // filter out null and undefined
        .map(to => new Date(to));
    const closestDate = closestTo(date, dates);
    const closestBucket = buckets.find(bucket => bucket.to && bucket.to === (closestDate === null || closestDate === void 0 ? void 0 : closestDate.toISOString()));
    if (!closestBucket) {
        return undefined;
    }
    return closestBucket.value;
}
