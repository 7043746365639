import { useMemo } from 'react';
import { t } from '@lingui/macro';
import { Spacer } from '@pocketlaw/tetris/deprecated';
import { useField } from 'formik';
import { ErrorMessage } from 'shared/domains/forms/components/ErrorMessage';
import { FieldMessage } from 'shared/domains/forms/components/FieldMessage';
import { AnimateHeightContainer } from '../../AnimateHeightContainer';
import { isReminderAfterDueDate, isReminderOnDueDate } from '../utils';
export function WarningAndErrors() {
    const [reminderField, reminderMeta] = useField('reminder.dueDate');
    const [dueDateField] = useField('dueDate');
    const warningMessage = useMemo(() => {
        const dueDate = new Date(dueDateField.value || '');
        const reminderDate = new Date(reminderField.value);
        if (isReminderOnDueDate({ dueDate, reminderDate })) {
            return t({
                message: 'Note that the reminder date is set on the same date as this task is due',
                comment: 'Warning message for task reminder date set to same date as due date',
            });
        }
        if (isReminderAfterDueDate({ dueDate, reminderDate })) {
            return t({
                message: 'Note that the reminder date is set on a date after this task is due',
                comment: 'Warning message for task reminder date set to date later than due date',
            });
        }
        return null;
    }, [dueDateField.value, reminderField.value]);
    const hasError = Boolean(reminderMeta.touched && reminderMeta.error);
    return (<Spacer spacing={0}>
      <AnimateHeightContainer isOpen={hasError}>
        <ErrorMessage name="reminder.dueDate" show/>
      </AnimateHeightContainer>
      <AnimateHeightContainer isOpen={warningMessage !== null}>
        <FieldMessage show>{warningMessage}</FieldMessage>
      </AnimateHeightContainer>
    </Spacer>);
}
