import { padding, pxToRem, themeGet, unit } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  ${padding(0, 4)};
  display: flex;
  flex-shrink: 0;
  height: ${pxToRem(48)};
  margin: 0 ${unit('xl')};
  border-radius: ${themeGet('borderRadius.md')};
  background-color: ${themeGet('colors.gray.50')};
`;
export const LeftContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: start;
  flex: 1 1 auto;
`;
export const MiddleContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
`;
export const RightContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: end;
  flex: 1 1 auto;
`;
