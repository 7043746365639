import { gql } from '@apollo/client';
export const GetPendingApprovalsQuery = gql `
  query PendingApprovalsQuery {
    esigningApprovalRequests(where: { status: { in: [Pending] } }) {
      id
      createdAt

      fileSystemDocument {
        id
        name
      }

      requesterCompanyUser {
        id
        user {
          id
          name
        }
      }

      approvers {
        companyUser {
          user {
            id
            name
          }
        }
      }
    }
  }
`;
