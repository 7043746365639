import { useLingui } from '@lingui/react';
import { Alert } from '@pocketlaw/tetris';
import { getErrorMessage } from './getErrorMessage';
import { AlertContainer } from './styled';
export function ErrorMessage(props) {
    const { message } = props;
    const { i18n } = useLingui();
    // eslint-disable-next-line no-underscore-dangle
    const _i18n = i18n;
    const error = getErrorMessage(message);
    if (!error) {
        return null;
    }
    return (<AlertContainer>
      <Alert appearance="danger" message={_i18n._(error)}/>
    </AlertContainer>);
}
