import { Trans } from '@lingui/macro';
import { Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useTasks } from 'app/domains/tasks/components/TasksProvider';
import { PreviewText } from '../PreviewText';
export function Assignee() {
    const { activeTask } = useTasks();
    const { assignees = [] } = activeTask || {};
    const getFieldText = () => {
        const numberOfAssignees = assignees.length;
        const [assignee] = assignees || [];
        const { user } = assignee || {};
        const { name: userName } = user || {};
        const numberOfAssigneesMinusOne = numberOfAssignees - 1;
        if (!numberOfAssignees) {
            return '';
        }
        if (numberOfAssignees === 1) {
            return userName;
        }
        return `${userName} +${numberOfAssigneesMinusOne}`;
    };
    const text = getFieldText();
    return (<PreviewText empty={!text} icon={<Falcon icon="user"/>}>
      {text ? (<Trans comment="tasks preview modal reminder assignee field - When task assignee(s) is set">
          Assigned to <Typography $appearance="100">{text}</Typography>
        </Trans>) : (<Trans comment="tasks preview modal reminder assignee field - When task assignee(s) is not set">
          Assigned to <Typography $appearance="100">None</Typography>
        </Trans>)}
    </PreviewText>);
}
