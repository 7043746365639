import { useQuery } from '@apollo/client';
import { documentQuery } from './query';
export function useGetDocumentsData(id) {
    const { data, error, loading } = useQuery(documentQuery, {
        variables: {
            id,
        },
    });
    const { fileSystemDocument } = data || {};
    return {
        data: fileSystemDocument,
        error,
        loading,
    };
}
