import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { GetDocumentDocument } from './query.gql';
export function useDocument(documentId) {
    var _a, _b;
    const params = useParams();
    const workspace = useAccount();
    const companyId = (_b = (_a = workspace.data) === null || _a === void 0 ? void 0 : _a.company) === null || _b === void 0 ? void 0 : _b.id;
    const id = documentId || params.id;
    return useQuery(GetDocumentDocument, {
        variables: { id, companyId },
    });
}
