import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled, { css } from 'styled-components';
export const Container = styled.div `
  display: flex;
  gap: ${themeGet('spacing.lg')};
  padding: 0 ${themeGet('spacing.md')};
`;
export const themeBoxStyles = css `
  position: relative;
  overflow: hidden;
  display: flex;
  text-align: left;
  height: ${pxToRem(60)};
  border-radius: ${themeGet('borderRadius.md')};
  outline: solid ${pxToRem(3)} transparent;
  transition: outline 225ms ease-in-out;

  &:hover {
    cursor: pointer;
    outline-color: ${themeGet('colors.primary.1000')};
  }

  &:focus-visible {
    outline-color: ${themeGet('colors.focus.primary')};
  }
`;
export const themeContentStyles = css `
  position: absolute;
  width: 100%;
  height: 100%;
  border: inherit;
  padding: ${pxToRem(5)};
  border-radius: ${themeGet('borderRadius.md')};
  font-size: ${themeGet('fontSize.small')};
  font-weight: ${themeGet('fontWeight.semibold')};
  transform: translate(${pxToRem(20)}, ${pxToRem(20)});
`;
export const themeSelectedStyles = css `
  position: absolute;
  bottom: ${pxToRem(5)};
  right: ${pxToRem(5)};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxToRem(20)};
  height: ${pxToRem(20)};
  border-radius: 50%;

  [data-icon='falcon'] {
    color: inherit;
  }
`;
