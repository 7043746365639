var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useFileDragDetector } from './useFileDragDetector';
import { getFiles, isDraggingFiles } from './utils';
export const useDropZone = (options) => {
    const { ref, onDrop } = options;
    const [draggingOver, setDraggingOver] = useState(false);
    const dragging = useFileDragDetector();
    useEffect(() => {
        if (!dragging) {
            setDraggingOver(false);
        }
    }, [dragging]);
    useEffect(() => {
        const dropElement = ref.current;
        const handleDrop = (event) => __awaiter(void 0, void 0, void 0, function* () {
            if (!isDraggingFiles || !event.dataTransfer)
                return;
            event.preventDefault();
            setDraggingOver(false);
            try {
                const files = yield getFiles(event.dataTransfer.items);
                onDrop({ status: 'success', files });
            }
            catch (_a) {
                onDrop({ status: 'error', files: [] });
            }
        });
        const handleDragOver = (event) => {
            if (!isDraggingFiles)
                return;
            event.preventDefault();
            if (event.dataTransfer) {
                // eslint-disable-next-line no-param-reassign
                event.dataTransfer.effectAllowed = 'copy';
            }
        };
        const handleDragEnter = (event) => {
            if (!isDraggingFiles)
                return;
            event.preventDefault();
            setDraggingOver(true);
        };
        const handleDragLeave = (event) => {
            if (!isDraggingFiles || !event.relatedTarget) {
                return;
            }
            event.preventDefault();
            setDraggingOver(false);
        };
        dropElement === null || dropElement === void 0 ? void 0 : dropElement.addEventListener('drop', handleDrop);
        dropElement === null || dropElement === void 0 ? void 0 : dropElement.addEventListener('dragover', handleDragOver);
        dropElement === null || dropElement === void 0 ? void 0 : dropElement.addEventListener('dragenter', handleDragEnter);
        dropElement === null || dropElement === void 0 ? void 0 : dropElement.addEventListener('dragleave', handleDragLeave);
        return () => {
            dropElement === null || dropElement === void 0 ? void 0 : dropElement.removeEventListener('drop', handleDrop);
            dropElement === null || dropElement === void 0 ? void 0 : dropElement.removeEventListener('dragover', handleDragOver);
            dropElement === null || dropElement === void 0 ? void 0 : dropElement.removeEventListener('dragenter', handleDragEnter);
            dropElement === null || dropElement === void 0 ? void 0 : dropElement.removeEventListener('dragleave', handleDragLeave);
        };
    }, [ref, onDrop, dragging]);
    return { dragging, draggingOver };
};
