import { padding, fontWeight, fontSize, marginBottom, marginLeft, marginRight, marginTop, unit, pxToRem, themeGet, } from '@pocketlaw/tetris';
import styled from 'styled-components';
const Root = styled.article `
  box-sizing: border-box;
  background-color: ${themeGet('colors.gray.0')};
  color: ${themeGet('colors.gray.800')};

  h1,
  h2,
  h3,
  h4 {
    ${fontWeight('bold')};
  }

  h1 {
    ${fontSize('large')};
    ${marginBottom(6)};
  }

  h2 {
    ${fontSize('medium')};
    ${marginBottom(6)};
  }

  h3 {
    ${fontSize(pxToRem(16))};
  }

  h4 {
    ${fontSize(pxToRem(14))};
  }

  ol,
  ul {
    ${marginLeft(6)};
    list-style: none;

    li[data-visibility='hidden'] {
      display: none;
    }
  }

  ul {
    li[data-visibility='visible']::before {
      ${marginRight(2)};
      vertical-align: middle;
      font-family: sans-serif;
      float: left;
      content: '\u25E6';
    }
  }

  hr {
    width: 100%;
    height: ${unit(0.5)};
    border: 0;
    background-color: ${themeGet('colors.gray.50')};
  }

  ol {
    counter-reset: item;

    > li:not(:empty) {
      ${marginTop(4)};
      counter-increment: item;

      &::before {
        ${marginRight(2)};
        vertical-align: bottom;
        float: left;
        content: counters(item, '.') '.';
      }
    }

    > li > ol > li:not(:empty)::before {
      content: counter(item, lower-alpha) ')';
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
  }

  th,
  td {
    margin: 0;
    padding: 0;
    border: ${pxToRem(1)} solid ${themeGet('colors.gray.800')};
    ${padding(4)};
    width: 50%;
  }
`;
export default Root;
