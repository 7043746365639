import { useQuery } from '@apollo/client';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { QUERY } from './query';
export function useGetAvailableAssistantCredits() {
    var _a;
    const account = useAccount();
    const companyId = (_a = account.data) === null || _a === void 0 ? void 0 : _a.companyId;
    const { data, error, loading, refetch } = useQuery(QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            companyId,
        },
    });
    const availableCredits = (data === null || data === void 0 ? void 0 : data.assistantCreditCounts.available) || 0;
    return { availableCredits, error, loading, refetch };
}
