import { useLastApprovalRequest } from 'app/domains/esigning/hooks/useLastApprovalRequest';
import { ApprovalRequestApproverStatus } from 'shared/domains/apollo/generated/types';
import { useCompanyUser } from './useCompanyUser';
export const useIsCurrentUserApprover = (documentId) => {
    const { lastApprovalRequest } = useLastApprovalRequest(documentId);
    const { companyUser } = useCompanyUser();
    const userApprover = lastApprovalRequest === null || lastApprovalRequest === void 0 ? void 0 : lastApprovalRequest.approvers.find(approver => {
        if (!(approver === null || approver === void 0 ? void 0 : approver.companyUserId) || !(companyUser === null || companyUser === void 0 ? void 0 : companyUser.id)) {
            return false;
        }
        return approver.companyUserId === companyUser.id;
    });
    if (userApprover === null || userApprover === void 0 ? void 0 : userApprover.id) {
        return {
            isApprover: true,
            approverId: userApprover.id,
            hasApproved: userApprover.status === ApprovalRequestApproverStatus.Approved,
        };
    }
    return {
        isApprover: false,
        approverId: null,
        hasApproved: false,
    };
};
