import { Fragment } from 'react';
import { EditPromptDialog } from '../EditPromptDialog';
import { PromptLibraryDialog } from '../PromptLibraryDialog';
import { usePromptLibrary } from '../PromptLibraryProvider';
import { DeletePromptDialog } from '../PromptLibrarySelect/DeletePrompt/DeletePromptDialog';
export function PromptLibraryModal() {
    const { view, onClose, setView } = usePromptLibrary();
    return (<Fragment>
      {view === 'library' && <PromptLibraryDialog onClose={onClose}/>}
      {view === 'confirmDelete' && <DeletePromptDialog />}
      {view === 'edit' && <EditPromptDialog onClose={() => setView('library')}/>}
    </Fragment>);
}
