import { gql } from '@apollo/client';
export const UPDATE_TEAM_MEMBER = gql `
  mutation updateTeamMember($teamMemberId: UUID!, $teamId: UUID!, $role: TeamMemberRole!) {
    updateTeamMember(id: $teamMemberId, teamId: $teamId, input: { role: $role }) {
      id
      role
      user {
        id
        name
        email
      }
      userId
    }
  }
`;
