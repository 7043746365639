var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@apollo/client';
import { metadataFilesystemEntries } from './query';
export function useSelectedEntries(documentIds) {
    const skipEntriesQuery = Boolean(!documentIds || !documentIds.length);
    const _a = useQuery(metadataFilesystemEntries, {
        skip: skipEntriesQuery,
        variables: {
            ids: documentIds,
        },
    }), { data, previousData } = _a, rest = __rest(_a, ["data", "previousData"]);
    const selectedEntries = (data === null || data === void 0 ? void 0 : data.fileSystemEntries) || (previousData === null || previousData === void 0 ? void 0 : previousData.fileSystemEntries) || [];
    return Object.assign({ data: selectedEntries }, rest);
}
