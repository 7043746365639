import { t } from '@lingui/macro';
import { ChatResolutionStatus } from 'shared/domains/apollo/generated/types';
export const getTitleByStatus = (status) => {
    switch (status) {
        case ChatResolutionStatus.ChatNotSupported:
            return t({
                message: 'Chatting is temporarily disabled for Summary',
                comment: 'Document assistant - when the chat has been resolved after requesting a summary - title',
            });
        case ChatResolutionStatus.ContentLengthExceeded:
            return t({
                message: 'Token limit reached',
                comment: 'Document assistant - when the chat has been resolved - token limit has been reached - title',
            });
        case ChatResolutionStatus.Unknown:
        default:
            return t({
                message: 'Something happened…',
                comment: 'Document assistant - when the chat has been resolved - general message - title',
            });
    }
};
