import { gql } from '@apollo/client';
export const MUTATION = gql `
  mutation docxToPdfForEsigningAttachment(
    $companyId: UUID!
    $path: String!
    $mimeType: String!
    $size: Int!
  ) {
    files: docxToPdfByFilePath(
      input: { companyId: $companyId, path: $path, mimeType: $mimeType, size: $size }
    ) {
      id
      mimeType
      signed(name: "attachment") {
        url
      }
    }
  }
`;
