import { useMutation } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { CancelChatMessageDocument } from './CancelChatMessage.gql';
export function useCancelDocumentChatMessage() {
    const [mutate, { loading }] = useMutation(CancelChatMessageDocument);
    const { addToast } = useToast();
    const handler = (id) => mutate({
        variables: {
            id,
        },
        onCompleted() {
            addToast({
                title: t({
                    comment: 'Document assistant - comment action - cancelling output - succesful toast message',
                    message: 'Got it! Succesfully stopped.',
                }),
                appearance: 'success',
            });
        },
        onError() {
            addToast({
                title: t({
                    comment: 'Document assistant - comment action - cancelling output - error toast message',
                    message: 'An error occured, try again later.',
                }),
                appearance: 'danger',
            });
        },
    });
    return { cancel: handler, loading };
}
