import { Box, IconButton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useUniversalSearch } from 'app/domains/global-search';
import { useMobileNavigation } from '../MobileNavigation';
export function TopNavigationButtons() {
    const { open } = useMobileNavigation();
    const { openSearchPopup } = useUniversalSearch();
    return (<Box alignItems="center" gap="lg">
      <IconButton $round appearance="secondary" onClick={open}>
        <Falcon icon="bars"/>
      </IconButton>
      <IconButton $round appearance="secondary" onClick={openSearchPopup}>
        <Falcon icon="search"/>
      </IconButton>
    </Box>);
}
