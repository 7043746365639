import { themeGet } from '@pocketlaw/tetris';
import styled, { css } from 'styled-components';
import { chatLoaderDotStyles } from '../ChatLoader';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${themeGet('spacing.md')};
  color: ${themeGet('colors.text.300')};

  & > * {
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: ${themeGet('fontSize.medium')};
    font-weight: ${themeGet('fontWeight.semibold')};
    line-height: ${themeGet('lineHeight.default')};
  }

  ol {
    list-style: decimal inside;
  }

  ul {
    list-style: disc inside;
  }

  ol,
  ul {
    padding-left: ${themeGet('spacing.lg')};

    li > p:first-child {
      display: inline;
    }
  }

  ol,
  ul,
  p {
    font-size: ${themeGet('fontSize.medium')};
  }

  a {
    color: ${themeGet('colors.secondary.500')};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  strong {
    font-weight: ${themeGet('fontWeight.semibold')};
  }

  li > p {
    display: inline;
  }

  code {
    display: block;
    white-space: break-spaces;
    color: ${themeGet('colors.text.200')};
    font-size: ${themeGet('fontSize.small')};
    font-family: ${themeGet('fontFamily.default')};
    line-height: ${themeGet('lineHeight.default')};
  }

  ${props => props.$writing &&
    css `
      & > *:last-child::after {
        content: '';
        ${chatLoaderDotStyles};
      }
    `}
`;
