import { pxToRem, themeGet, unit } from '@pocketlaw/tetris';
import styled, { css } from 'styled-components';
import { themeBoxStyles, themeContentStyles, themeSelectedStyles } from '../styled';
export const Container = styled.div `
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${unit('sm')};
`;
const systemThemeBoxStyles = css `
  ${themeBoxStyles};

  width: 50%;
  outline: none;
`;
export const SystemThemeBox = styled.button `
  ${themeBoxStyles};

  padding: 0;
  border: none;
`;
export const SystemDarkThemeBox = styled.div `
  ${systemThemeBoxStyles};

  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  ${props => props.$themeType === 'dark' &&
    css `
      border: solid ${pxToRem(1)} ${themeGet('colors.gray.100')};
      background: ${themeGet('colors.gray.50')};
    `}

  ${props => props.$themeType === 'light' &&
    css `
      border: solid ${pxToRem(1)} ${themeGet('colors.gray.800')};
      background-color: ${themeGet('colors.gray.900')};
    `}
`;
export const SystemLightThemeBox = styled.div `
  ${systemThemeBoxStyles};

  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  ${props => props.$themeType === 'light' &&
    css `
      border: solid ${pxToRem(1)} ${themeGet('colors.gray.100')};
      background: ${themeGet('colors.gray.50')};
    `}

  ${props => props.$themeType === 'dark' &&
    css `
      border: solid ${pxToRem(1)} ${themeGet('colors.gray.800')};
      background-color: ${themeGet('colors.gray.900')};
    `}
`;
const systemThemeContentStyles = css `
  ${themeContentStyles};

  transform: translate(${pxToRem(10)}, ${pxToRem(20)});
`;
export const SystemDarkThemeContent = styled.div `
  ${systemThemeContentStyles}

  ${props => props.$themeType === 'dark' &&
    css `
      color: ${themeGet('colors.gray.1000')};
      background-color: ${themeGet('colors.gray.0')};
    `}

  ${props => props.$themeType === 'light' &&
    css `
      color: ${themeGet('colors.gray.50')};
      background-color: ${themeGet('colors.gray.1000')};
    `}
`;
export const SystemLightThemeContent = styled.div `
  ${systemThemeContentStyles};

  ${props => props.$themeType === 'light' &&
    css `
      color: ${themeGet('colors.gray.1000')};
      background-color: ${themeGet('colors.gray.0')};
    `}

  ${props => props.$themeType === 'dark' &&
    css `
      color: ${themeGet('colors.gray.0')};
      background-color: ${themeGet('colors.gray.1000')};
    `}
`;
export const SystemThemeSelected = styled.div `
  ${themeSelectedStyles};

  ${props => props.$themeType === 'light' &&
    css `
      color: ${themeGet('colors.gray.0')};
      background-color: ${themeGet('colors.gray.1000')};
    `}

  ${props => props.$themeType === 'dark' &&
    css `
      color: ${themeGet('colors.gray.1000')};
      background-color: ${themeGet('colors.gray.0')};
    `}
`;
