import { Link as ReactRouterLink } from 'react-router-dom';
import { StyledLink } from './styled';
export function MetricCardTitle(props) {
    const { children, to } = props;
    if (to) {
        return (<StyledLink forwardedAs={ReactRouterLink} to={to} appearance="text-200">
        {children}
      </StyledLink>);
    }
    return children;
}
