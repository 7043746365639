import { makeVar } from '@apollo/client';
import { Plugin } from 'pleditor';
export const toastNotificationVar = makeVar(undefined);
export class ToastHandler extends Plugin {
    init() {
        const notificationPlugin = this.editor.plugins.get('Notification');
        notificationPlugin.on('show:success', (evt, data) => {
            toastNotificationVar({ title: data.message, appearance: 'success' });
        });
        notificationPlugin.on('show:info', (evt, data) => {
            toastNotificationVar({ title: data.message, appearance: 'info' });
        });
        notificationPlugin.on('show:warning', (evt, data) => {
            evt.stop();
            toastNotificationVar({ title: data.message, appearance: 'danger' });
        });
    }
    // eslint-disable-next-line class-methods-use-this
    destroy() {
        // clear notification var
        toastNotificationVar(undefined);
    }
}
