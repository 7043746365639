import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const LinkButton = styled.button `
  cursor: pointer;

  &:disabled {
    cursor: auto;
  }
`;
export const AttachmentContainer = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: ${themeGet('spacing.md')};
  padding: ${themeGet('spacing.md')} ${themeGet('spacing.xl')};
  border: ${pxToRem(1)} solid ${themeGet('colors.border.100')};
  border-radius: ${themeGet('borderRadius.md')};
`;
