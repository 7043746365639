import { gql } from '@apollo/client';
export const createSignRequestMutation = gql `
  mutation CreateSignRequest(
    $documentId: ID!
    $esigningProviderId: UUID!
    $file: FileSystemDocumentFileInsertInput!
    $signatories: [SignatoryInsertInput!]!
    $ccRecipients: [CarbonCopyRecipientInsertInput!]
    $message: String
    $defaultSignatories: [DefaultSignatoryInsertInput!]
  ) {
    createFileSystemDocumentFile(input: $file) {
      id
      companyId
      name
      path
      type: mimeType
      size
    }

    createSignRequestNew(
      input: {
        esigning_provider_id: $esigningProviderId
        file_system_document_id: $documentId
        signatories: $signatories
        cc_recipients: $ccRecipients
        message: $message
        defaultSignatories: $defaultSignatories
      }
    ) {
      id
      status
    }
  }
`;
