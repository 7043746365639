import { Trans } from '@lingui/macro';
import { Switch, Typography } from '@pocketlaw/tetris';
import { useField } from 'formik';
import { Container, TextContainer } from './styled';
const FIELD = 'saveSignee';
export function SaveContact() {
    const [saveSigneeField] = useField(FIELD);
    const { value, onChange, onBlur } = saveSigneeField;
    return (<Container>
      <TextContainer>
        <Typography $appearance="300" $fontSize="medium">
          <Trans comment="Signing party form field - Save contact">Save contact</Trans>
        </Typography>
        <Typography $appearance="100" $fontSize="tiny">
          <Trans comment="Signing party form field - Save contact description">
            To quickly access your saved contacts next time.
          </Trans>
        </Typography>
      </TextContainer>
      <Switch name={FIELD} checked={value} onChange={onChange} onBlur={onBlur}/>
    </Container>);
}
