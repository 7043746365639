import { Fragment, useState } from 'react';
import { t } from '@lingui/macro';
import { AvatarWithLabel, Menu, MenuBody, MenuFooter, MenuList } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useCompanyFeatures } from 'app/domains/companies';
import { useRole } from 'app/domains/users';
import { ChatButton } from './ChatButton';
import { ResponsiveButton } from './ResponsiveButton';
import { CreateTeamDialog } from '../CreateTeamDialog';
import { AvatarContainer } from './styled';
export function CreateTeamButton() {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const role = useRole();
    const { loading, isFeatureAvailable } = useCompanyFeatures();
    if (!role.can('create:team') || loading) {
        return null;
    }
    if (isFeatureAvailable('team')) {
        return (<Fragment>
        <ResponsiveButton onClick={() => setDialogOpen(!dialogOpen)}/>
        <CreateTeamDialog open={dialogOpen} onClose={() => setDialogOpen(false)}/>
      </Fragment>);
    }
    return (<Menu target={<ResponsiveButton onClick={() => setDropdownOpen(!dropdownOpen)}/>} onClose={() => setDropdownOpen(false)} open={dropdownOpen}>
      <MenuBody>
        <MenuList>
          <AvatarContainer>
            <AvatarWithLabel size="lg" title={t({
            message: 'Upgrade',
            comment: 'upsell teams dropdown title on create-team button',
        })} subtitle={t({
            message: 'This is an enterprise feature',
            comment: 'upsell teams dropdown subtitle on create-team button',
        })}>
              <Falcon icon="circle-up"/>
            </AvatarWithLabel>
          </AvatarContainer>
        </MenuList>
      </MenuBody>
      <MenuFooter>
        <ChatButton />
      </MenuFooter>
    </Menu>);
}
