import { t } from '@lingui/macro';
import { Avatar, ConfirmDialog, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { getProviderData } from 'app/domains/esigning/utils';
export function ReconnectExpiredProviderDialog(props) {
    const { providerId, loading, onClose, onReconnect } = props;
    const { name } = getProviderData(providerId) || {};
    const heading = t({
        comment: 'Reconnect expired e-signing provider dialog - Title',
        message: 'Reconnect e-sign provider',
    });
    const confirm = t({
        comment: 'Reconnect expired e-signing provider dialog - Confirm button label',
        message: 'Reconnect provider',
    });
    const cancel = t({
        comment: 'Reconnect expired e-signing provider dialog - Cancel button label',
        message: 'Cancel',
    });
    return (<ConfirmDialog media={<Avatar appearance="info" shape="square" size="lg">
          <Falcon icon="paper-plane"/>
        </Avatar>} heading={heading} appearance="primary" size="sm" loading={loading} confirm={confirm} cancel={cancel} onConfirm={onReconnect} onCancel={onClose}>
      <Typography $appearance="200">
        {t({
            comment: 'Reconnect expired e-signing provider dialog - Message',
            message: `Since you haven't used e-signing in more than 30 days you need to reconnect to ${name}.`,
        })}
      </Typography>
    </ConfirmDialog>);
}
