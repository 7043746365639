import { createContext, useState } from 'react';
import { useTemplates } from './useTemplates';
export const TemplateBrowserContext = createContext(null);
export function TemplateBrowserProvider(props) {
    const { children, onSelect } = props;
    const { templates, loading, error } = useTemplates();
    const [selectedTemplateId, setSelectedTemplateId] = useState('');
    const handleSelectTemplateId = (templateId) => {
        setSelectedTemplateId(templateId);
        const template = templates.find(({ id }) => id === templateId);
        if (template) {
            onSelect(template);
        }
    };
    const value = {
        templates,
        loading,
        selectedTemplateId,
        selectTemplateId: handleSelectTemplateId,
        error: Boolean(error),
    };
    return (<TemplateBrowserContext.Provider value={value}>{children}</TemplateBrowserContext.Provider>);
}
