import { useQuery } from '@apollo/client';
import { QUERY } from './query';
import { useActiveDocument } from '../../../../hooks/usePreview';
import { isPdf } from '../../../../utils/fileHelpers';
export const useDocumentPDF = () => {
    var _a, _b;
    const { id: documentId } = useActiveDocument();
    const { data, loading, error } = useQuery(QUERY, {
        variables: {
            documentId,
        },
    });
    const pdf = (_b = (_a = data === null || data === void 0 ? void 0 : data.document) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b.find(({ type }) => isPdf({ type }));
    return { pdf, loading, error };
};
