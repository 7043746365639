import { gql } from '@apollo/client';
export const query = gql `
  query team($id: UUID!) {
    team(id: $id) {
      id
      name
      createdAt
      description
      createdByUser {
        id
        name
      }
      members {
        id
      }
    }
  }
`;
