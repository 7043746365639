import { localizedFormatDate } from 'shared/domains/common-utils';
import { getMessageType } from 'shared/domains/feedback/utils/getMessageType';
function transformMessage(thread) {
    return (message) => {
        var _a;
        return (Object.assign(Object.assign({}, message), { type: getMessageType({ message, thread }), createdAtDate: new Date(message.createdAt), createdAt: (_a = localizedFormatDate(message.createdAt, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            })) !== null && _a !== void 0 ? _a : '' }));
    };
}
export { transformMessage };
