import { Fragment } from 'react';
import { useEsigningSignatoriesFormField } from 'app/domains/esigning/components/EsigningForm';
import { EmptySigningParties } from './EmptySigningParties';
import { NonSigningParties } from './NonSigningParties';
import { SigningParties } from './SigningParties';
export function Parties() {
    const { signatories } = useEsigningSignatoriesFormField();
    if (signatories.length === 0) {
        return <EmptySigningParties />;
    }
    return (<Fragment>
      <SigningParties />
      <NonSigningParties />
    </Fragment>);
}
