import { useState } from 'react';
import { t } from '@lingui/macro';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useFormikContext } from 'formik';
import { AttachmentBrowser } from '../AttachmentBrowser';
import { AttachmentFieldLabel } from '../AttachmentFieldLabel';
import { LinkField } from '../LinkField';
export function AttachmentAdd() {
    const [open, setOpen] = useState(false);
    const { values, setFieldValue } = useFormikContext();
    const { attachments } = values;
    const hide = attachments === null || attachments === void 0 ? void 0 : attachments.length;
    const handleOnAdd = (attachment) => {
        setFieldValue('attachments', [
            {
                attachmentId: attachment.id,
                type: attachment.type,
            },
        ]);
        setOpen(false);
    };
    if (hide) {
        return null;
    }
    return (<AttachmentFieldLabel>
      <LinkField onClick={() => setOpen(!open)} linkText={t({
            message: 'Attach document',
            comment: 'Document link text when no document is attached.',
        })} icon={<Falcon icon="file"/>} name="attachments"/>
      {open && <AttachmentBrowser onAdd={handleOnAdd} onCancel={() => setOpen(false)}/>}
    </AttachmentFieldLabel>);
}
