import { gql } from '@apollo/client';
const mutation = gql `
  mutation SendFeedbackMessage($inviteId: UUID!, $recipientId: String!, $message: String!) {
    createExternalSharingMessage(
      input: { inviteId: $inviteId, recipientId: $recipientId, message: $message }
    ) {
      id
      createdAt
      message
      isRead
      sender {
        id
        name
      }
    }
  }
`;
export default mutation;
