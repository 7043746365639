import { gql } from '@apollo/client';
import { TaskFragment } from '../../utils/fragments';
const mutation = gql `
  mutation UpdateTask($id: UUID!, $isCompleted: Boolean!, $status: TaskStatusEnumEnum!) {
    updateOneTask(pk_columns: { id: $id }, _set: { is_completed: $isCompleted, status: $status}) {
      ...TaskFragment
    }

    ${TaskFragment}
  }
`;
export { mutation };
