import { Trans } from '@lingui/macro';
import { Typography } from '@pocketlaw/tetris';
import { Container } from './styled';
export function AttachmentFieldLabel(props) {
    const { children } = props;
    return (<Container>
      <Typography $fontSize="small" $fontWeight="medium" $appearance="200">
        <Trans comment="Task attachment field label">Related attachment</Trans>
      </Typography>
      {children}
    </Container>);
}
