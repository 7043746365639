import { gql } from '@apollo/client';
export const createDraftMutation = gql `
  mutation createDraft(
    $composerId: UUID!
    $templateId: UUID!
    $companyId: UUID!
    $userId: String!
    $version: String!
    $result: JSON = []
    $templateVersionTemplateId: UUID!
    $templateVersionReference: String!
  ) {
    created: createDraft(
      objects: {
        composer_id: $composerId
        template_id: $templateId
        company_id: $companyId
        user_id: $userId
        version: $version
        result: $result
        template_version_template_id: $templateVersionTemplateId
        template_version_reference: $templateVersionReference
      }
    ) {
      returning {
        id
      }
    }
  }
`;
