import { useMutation } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { TaskStatusEnumEnum } from 'shared/domains/apollo/generated/types';
import { UPDATE_TASK_STATUS } from './mutation';
export function useUpdateStatus() {
    const [mutate, { loading, error }] = useMutation(UPDATE_TASK_STATUS);
    const { addToast } = useToast();
    const updateStatus = (options) => {
        const { id, status } = options;
        const completed = status === TaskStatusEnumEnum.Completed;
        mutate({
            variables: { id, status, completed },
            update: (cache, { data }) => {
                const { updateTask } = data || {};
                if (updateTask) {
                    const [task] = updateTask.returning;
                    cache.modify({
                        id: cache.identify(task),
                        fields: {
                            status() {
                                return task.status;
                            },
                        },
                    });
                }
            },
            onCompleted: () => {
                addToast({
                    title: t({
                        message: 'Task status updated',
                        comment: 'Toast message when task status was updated',
                    }),
                    appearance: 'success',
                });
            },
            onError: () => {
                addToast({
                    title: t({
                        message: 'Failed to update task status',
                        comment: 'Toast message when task status failed to update',
                    }),
                    appearance: 'danger',
                });
            },
        });
    };
    return { updateStatus, loading, error };
}
