var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getDocumentFlush } from './getDocumentFlush';
import { documentFlush } from '../../documentFlush';
import { PleditorPlugin } from '../PleditorPlugin';
export const READ_ONLY_FLUSH_EVENT = 'rtc-offline';
export class HandleDocumentFlushedPlugin extends PleditorPlugin {
    constructor() {
        super(...arguments);
        this.handleDocumentFlush = () => __awaiter(this, void 0, void 0, function* () {
            try {
                const { state } = documentFlush();
                // The flush was initiated by the current user, so we don't need to fetch the flush reason
                if (state === 'initialized') {
                    documentFlush({ state: 'not-initialized' });
                }
                else {
                    documentFlush({ state: 'loading' });
                    const flush = yield getDocumentFlush(this.apiConnector, this.documentId);
                    if (flush) {
                        documentFlush({
                            state: 'done',
                            reason: flush.reason,
                            initiatorUserId: flush.userId,
                        });
                    }
                    else {
                        documentFlush({ state: 'not-initialized' });
                    }
                }
            }
            catch (_a) {
                documentFlush({ state: 'not-initialized' });
            }
        });
    }
    static get pluginName() {
        return 'HandleDocumentFlushedPlugin';
    }
    init() {
        const { editor } = this;
        editor.decorate('enableReadOnlyMode');
        editor.once('enableReadOnlyMode', (_info, events) => {
            if (events.includes(READ_ONLY_FLUSH_EVENT)) {
                // Remove read only mode, we handle this by adding the RestrictedEditingMode plugin instead
                // This is because we can't flush the document when it is in read only mode
                editor.disableReadOnlyMode(READ_ONLY_FLUSH_EVENT);
                this.handleDocumentFlush();
            }
        });
    }
}
