import { Trans, t } from '@lingui/macro';
import { DialogContent, DialogHeader, Avatar } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { CompanyRoleEnum } from 'shared/domains/apollo/generated/types';
import { FormDialog } from 'shared/domains/forms';
import { FormFooter } from './FormFooter';
import { useWizardContext } from './WizardContext';
import { StyledAlert } from './styled';
const initialValues = {
    email: '',
    role: CompanyRoleEnum.Member,
};
export function Wizard() {
    const { handleNextStep, onClose, step, steps, error } = useWizardContext();
    const { component: Component, schema } = steps[step];
    return (<FormDialog initialValues={initialValues} onSubmit={handleNextStep} validationSchema={schema} onClose={onClose}>
      <DialogHeader title={<Trans>Invite members</Trans>} media={<Avatar size="xl" shape="square">
            <Falcon icon="user-plus"/>
          </Avatar>}/>
      <DialogContent>
        {error && (<StyledAlert appearance="danger" message={t({
                message: 'Failed to send invite. Please check that you do not already have a pending invite for the same email',
                comment: 'General error message for invite user modal. Reason for error could be inviting the same user twice or something else',
            })}/>)}
        <Component />
      </DialogContent>
      <FormFooter onClose={onClose}/>
    </FormDialog>);
}
