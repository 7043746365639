import { Fragment } from 'react';
import { useAssistant, AssistantChatActions, AssistantChatThread, AssistantConversations, AssistantViewType, } from 'app/domains/assistant';
import { AssistantChat } from './AssistantChat';
import { AssistantDashboard } from './AssistantDashboard';
import { AssistantSuggestionsThread } from './AssistantSuggestionsThread';
import { NoCredits } from './NoCredits';
import { OverlayLoader } from './OverlayLoader';
export function AssistantView() {
    const { initiating, creating, hasCredits, view } = useAssistant();
    if (initiating) {
        return <OverlayLoader />;
    }
    if (!hasCredits) {
        return <NoCredits />;
    }
    if (creating) {
        return (<Fragment>
        <AssistantChatThread />
        <AssistantChatActions />
      </Fragment>);
    }
    switch (view) {
        case AssistantViewType.Conversations:
            return <AssistantConversations />;
        case AssistantViewType.Chat:
            return <AssistantChat />;
        case AssistantViewType.Dashboard:
            return <AssistantDashboard />;
        case AssistantViewType.Suggestions:
            return (<Fragment>
          <AssistantSuggestionsThread />
          <AssistantChatActions />
        </Fragment>);
        default:
            return null;
    }
}
