import { forwardRef } from 'react';
import { Tooltip } from '@pocketlaw/tetris';
import { Button, RightIconContainer } from './styled';
export const AssistantPromptButton = forwardRef((props, ref) => {
    const { onClick, leftIcon, rightIcon, title, disabled, tooltip, align = 'left' } = props;
    const promptButton = (<Button onClick={onClick} disabled={disabled} $align={align} ref={ref}>
        {leftIcon}
        {title}
        {rightIcon && <RightIconContainer>{rightIcon}</RightIconContainer>}
      </Button>);
    if (tooltip) {
        return (<Tooltip position="bottom" title={tooltip}>
          {promptButton}
        </Tooltip>);
    }
    return promptButton;
});
