import { ESignPartyItem } from 'app/domains/esigning/components/ESignPartyItem';
import { useLastSignRequest } from 'app/domains/esigning/hooks/useLastSignRequest';
import { SignatoryStatus } from 'shared/domains/apollo/generated/types';
import { PendingActions } from '../PendingActions';
const NO_ACTIONS_STATUSES = [SignatoryStatus.Signed, SignatoryStatus.Rejected];
export function PendingParty(props) {
    const { party, isNotOnlyParty } = props;
    const { status, updatedAt } = party;
    const { lastSignRequest } = useLastSignRequest();
    const { message: groupMessage } = lastSignRequest || {};
    const hideActions = NO_ACTIONS_STATUSES.includes(status);
    return (<ESignPartyItem signatory={Object.assign(Object.assign({}, party), { isSigning: true })} signingStatus={{ status, updatedAt }} groupMessage={groupMessage} endElement={!hideActions && <PendingActions signatory={party} isNotOnlyParty={isNotOnlyParty}/>}/>);
}
