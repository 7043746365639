import { t } from '@lingui/macro';
export function allowedValuesTranslations(key) {
    const translations = {
        metadataDocumentNoticePeriodMonths: t({
            message: 'Notice period (months)',
            comment: 'Insights: Top hits allowed field: metadataDocumentNoticePeriodMonths',
        }),
        metadataDocumentNoticePeriodDays: t({
            message: 'Notice period (days)',
            comment: 'Insights: Top hits allowed field: metadataDocumentNoticePeriodDays',
        }),
        metadataDocumentOriginalTermMonths: t({
            message: 'Original Term (months)',
            comment: 'Insights: Top hits allowed field: metadataDocumentOriginalTermMonths',
        }),
        metadataDocumentRenewalTermMonths: t({
            message: 'Revental Term (months)',
            comment: 'Insights: Top hits allowed field: metadataDocumentRenewalTermMonths',
        }),
        metadataDocumentTotalContractCost: t({
            message: 'Total contract cost',
            comment: 'Insights: Top hits allowed field: metadataDocumentTotalContractCost',
        }),
        metadataDocumentTotalContractValue: t({
            message: 'Total contract value',
            comment: 'Insights: Top hits allowed field: metadataDocumentTotalContractValue',
        }),
    };
    return translations[key] || '';
}
