import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  border-top: ${pxToRem(1)} solid ${themeGet('colors.border.100')};
  padding: ${themeGet('spacing.2xl')} ${themeGet('spacing.xl')};
`;
export const Spacer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: ${themeGet('spacing.md')};
`;
export const Actions = styled.div `
  display: flex;
  justify-content: center;
  gap: ${themeGet('spacing.md')};
`;
