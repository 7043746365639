import { Button, themeGet, unit } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const UploadButton = styled(Button) `
  border-radius: ${unit(2)};
  height: ${unit(13)};
  border: ${unit(0.5)} dashed ${themeGet('colors.gray.50')};
  & > span {
    display: flex;
    align-items: center;
  }
`;
