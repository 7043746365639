import { matchPath } from 'react-router-dom';
function isAuthenticating(location) {
    const match = matchPath(location.pathname, {
        path: '/auth/callback',
    });
    return Boolean(match);
}
function normalize(user) {
    var _a, _b, _c, _d, _e, _f;
    if (!user) {
        return null;
    }
    return {
        userId: (_a = user.sub) !== null && _a !== void 0 ? _a : '',
        email: (_b = user.email) !== null && _b !== void 0 ? _b : '',
        name: (_c = user.name) !== null && _c !== void 0 ? _c : '',
        picture: (_d = user.name) !== null && _d !== void 0 ? _d : '',
        preferredLocale: (_e = user['https://pocketlaw.com/preferred_locale']) !== null && _e !== void 0 ? _e : '',
        verified: (_f = user.email_verified) !== null && _f !== void 0 ? _f : false,
    };
}
function handleOnRejected() {
    return {
        userId: '',
        email: '',
        name: '',
        picture: '',
        preferredLocale: '',
        verified: false,
    };
}
export default function authenticate(options) {
    const { client, history } = options;
    function redirect(redirectLoginResult) {
        var _a;
        history.replace(((_a = redirectLoginResult.appState) === null || _a === void 0 ? void 0 : _a.returnTo) || '/');
    }
    function getUser() {
        return client.getUser().then(normalize, handleOnRejected);
    }
    if (isAuthenticating(history.location)) {
        return client.handleRedirectCallback().then(redirect).then(getUser);
    }
    return client.checkSession().then(getUser);
}
