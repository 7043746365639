import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled, { keyframes } from 'styled-components';
const SPINNER_SIZE = pxToRem(72);
const SPINNER_WIDTH = pxToRem(4);
const SPINNER_COLOR = themeGet('colors.blue.600');
const spinAnimation = keyframes `
  100% {
    transform: rotate(360deg);
  }
`;
export const SpinnerWrapper = styled.div `
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${SPINNER_SIZE};
  height: ${SPINNER_SIZE};
`;
export const SpinnerIcon = styled.div `
  position: absolute;
  width: ${pxToRem(30)};
  height: ${pxToRem(30)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  [data-icon='falcon'] {
    color: ${SPINNER_COLOR};
  }
`;
const Spinner = styled.div `
  position: absolute;
  width: ${SPINNER_SIZE};
  height: ${SPINNER_SIZE};
  border-radius: 50%;
  border-style: solid;
  border-width: ${SPINNER_WIDTH};
`;
export const SpinnerBackground = styled(Spinner) `
  border-color: rgba(0, 0, 0, 0.1);
`;
export const SpinnerForeground = styled(Spinner) `
  border-color: ${SPINNER_COLOR} transparent transparent transparent;
  animation: ${spinAnimation} 1.15s infinite ease-in-out;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: ${SPINNER_WIDTH};
    width: ${SPINNER_WIDTH};
    height: ${SPINNER_WIDTH};
    background: ${SPINNER_COLOR};
    border-radius: 50%;
  }

  &:before {
    left: 0;
    transform: translate(150%, 50%);
  }

  &:after {
    right: 0;
    transform: translate(-150%, 50%);
  }
`;
