import { useMutation } from '@apollo/client';
import { createPrompt, updatePrompt } from './query';
export function useUpsertPrompt(id) {
    const operation = id ? updatePrompt : createPrompt;
    const [mutate, { loading, error }] = useMutation(operation);
    const upsertPrompt = (props) => mutate({
        variables: Object.assign(Object.assign({}, props), { id }),
        update(cache, { data }) {
            if (data === null || data === void 0 ? void 0 : data.documentAssistantPrompt) {
                cache.modify({
                    fields: {
                        documentAssistantPromptsByUser(existing = [], { toReference, isReference }) {
                            const existingRefs = existing;
                            const ref = toReference(data.documentAssistantPrompt);
                            if (isReference(ref)) {
                                return [
                                    ref,
                                    // eslint-disable-next-line no-underscore-dangle
                                    ...existingRefs.filter(existingRef => existingRef.__ref !== ref.__ref),
                                ];
                            }
                            return existing;
                        },
                    },
                });
            }
        },
    });
    return { upsertPrompt, loading, error };
}
