import { Fragment } from 'react';
import { Trans, t } from '@lingui/macro';
import { DialogContent as TetrisDialogContent, Typography } from '@pocketlaw/tetris';
import { useFormikContext } from 'formik';
import { useEsigningProviderMetadata } from 'app/domains/esigning/hooks/useEsigningProviderMetadata';
import { useSignatoryFieldFallbacks } from 'app/domains/esigning/utils';
import { EsigningProviderAuthenticationModeSlug } from 'shared/domains/apollo/generated/types';
import { TextField } from 'shared/domains/forms';
import { PhoneNumberField } from 'shared/domains/forms/components/PhoneNumberField';
import { Autocomplete } from './Autocomplete';
import { MessageField } from './MessageField';
import { RoleAndAuth } from './RoleAndAuth';
import { SaveContact } from './SaveContact';
import { ColumnsContainer, ContainerDivider, FieldWrapper, LeftColumn, RightColumn, } from './styled';
export function DialogContent(props) {
    const { type, hideSaveContact } = props;
    const { values: formValues, setValues } = useFormikContext();
    const { isSigning, authenticationMode } = formValues;
    const { properties: metadataProperties } = useEsigningProviderMetadata();
    const { nameFormat, canUseTitleField, canUseEsigningMessages } = metadataProperties;
    const { getFieldFallbacksForSignatory } = useSignatoryFieldFallbacks();
    const showMessageField = type === 'Signatory' && canUseEsigningMessages;
    const showSaveContact = type === 'Signatory' && !hideSaveContact;
    const showTitleField = isSigning && canUseTitleField;
    const showMobileField = isSigning && authenticationMode === EsigningProviderAuthenticationModeSlug.SmsOtp;
    const handleAutoFill = (autoFillValues) => {
        const mobile = autoFillValues.mobile === null ? undefined : autoFillValues.mobile;
        const title = autoFillValues.title === null ? undefined : autoFillValues.title;
        const saveSignee = false;
        const autoFillSignatory = getFieldFallbacksForSignatory(Object.assign(Object.assign(Object.assign({}, formValues), autoFillValues), { mobile,
            title,
            saveSignee }));
        setValues(autoFillSignatory);
    };
    const optionalFieldDescription = t({
        comment: 'Signing party form field - Optional field text',
        message: 'If applicable',
    });
    return (<TetrisDialogContent>
      <ColumnsContainer>
        <LeftColumn>
          <Typography $appearance="300" $fontSize="large" $fontWeight="bold">
            <Trans comment="Title for the add/edit e-signing party details">Details</Trans>
          </Typography>
          <Typography $appearance="100" $fontSize="small">
            <Trans comment="Message for the add/edit e-signing party details">
              Add the details for each party. You can add multiple parties but only one at the time.
              You can also add non-signing parties, who will receive a copy of the document.
            </Trans>
          </Typography>
        </LeftColumn>
        <RightColumn>
          {nameFormat === 'firstNameLastName' && (<FieldWrapper>
              <Autocomplete name="firstName" signatoryKey="firstName" label={t({
                comment: 'Signing party form field - First name',
                message: 'First name',
            })} onAutoFill={handleAutoFill}/>
            </FieldWrapper>)}
          {nameFormat === 'firstNameLastName' && (<FieldWrapper>
              <Autocomplete name="lastName" signatoryKey="lastName" label={t({ comment: 'Signing party form field - Last name', message: 'Last name' })} onAutoFill={handleAutoFill}/>
            </FieldWrapper>)}
          {nameFormat === 'fullName' && (<FieldWrapper>
              <Autocomplete name="name" signatoryKey="name" label={t({ comment: 'Signing party form field - Full name', message: 'Full name' })} onAutoFill={handleAutoFill}/>
            </FieldWrapper>)}
          <FieldWrapper>
            <TextField name="email" label={t({ comment: 'Signing party form field - Email', message: 'Email' })}/>
          </FieldWrapper>
          {showTitleField && (<FieldWrapper>
              <TextField name="title" label={t({ comment: 'Signing party form field - Title', message: 'Title' })} description={optionalFieldDescription}/>
            </FieldWrapper>)}
          <FieldWrapper>
            <TextField name="label" label={t({ comment: 'Signing party form field - Company', message: 'Company' })} description={optionalFieldDescription}/>
          </FieldWrapper>
          <RoleAndAuth />
          {showMobileField && (<PhoneNumberField stretch name="mobile" label={t({
                comment: 'Signing party form field - Phone number',
                message: 'Phone number',
            })}/>)}
          {showSaveContact && <SaveContact />}
        </RightColumn>
      </ColumnsContainer>
      {showMessageField && (<Fragment>
          <ContainerDivider />
          <ColumnsContainer>
            <LeftColumn>
              <Typography $appearance="300" $fontSize="large" $fontWeight="bold">
                <Trans comment="Title for the add/edit e-signing party personal message">
                  Personal message
                </Trans>
              </Typography>
              <Typography $appearance="100" $fontSize="small">
                <Trans comment="Description for the add/edit e-signing party personal message">
                  Add a message that will only be visible to this specific party.
                </Trans>
              </Typography>
            </LeftColumn>
            <RightColumn>
              <MessageField />
            </RightColumn>
          </ColumnsContainer>
        </Fragment>)}
    </TetrisDialogContent>);
}
