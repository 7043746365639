import { createContext, useRef, useState } from 'react';
export const DocumentDrawerContext = createContext(null);
export function Provider(props) {
    const { children } = props;
    const [drawerKey, setDrawerKey] = useState(null);
    const [activeComponent, setActiveComponent] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const collapse = () => setExpanded(false);
    const expand = () => setExpanded(true);
    const toggleExpanded = () => setExpanded(prevExpanded => !prevExpanded);
    const drawerKeyRef = useRef(null);
    const isDrawerOpen = (key) => key === drawerKey && key === drawerKeyRef.current;
    const openDrawer = (key) => {
        setDrawerKey(key);
        drawerKeyRef.current = key;
    };
    const closeDrawer = (key) => {
        if (isDrawerOpen(key)) {
            collapse();
            setDrawerKey(null);
            setActiveComponent(null);
            drawerKeyRef.current = null;
        }
    };
    const toggleDrawer = (key) => {
        if (isDrawerOpen(key)) {
            closeDrawer(key);
        }
        else {
            setDrawerKey(key);
            drawerKeyRef.current = key;
        }
    };
    const value = {
        openDrawer,
        closeDrawer,
        toggleDrawer,
        isDrawerOpen,
        activeComponent,
        setActiveComponent,
        collapse,
        expand,
        expanded,
        toggleExpanded,
    };
    return <DocumentDrawerContext.Provider value={value}>{children}</DocumentDrawerContext.Provider>;
}
