import { useQuery } from '@apollo/client';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { getCompanyPlanId } from './query';
export function useGetPlanId() {
    var _a;
    const account = useAccount();
    const { companyId = '' } = account.data || {};
    const { data } = useQuery(getCompanyPlanId, {
        variables: {
            companyId,
        },
    });
    const { planId } = ((_a = data === null || data === void 0 ? void 0 : data.company) === null || _a === void 0 ? void 0 : _a.plan) || {};
    return planId;
}
