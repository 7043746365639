import { Typography, TableBodyCell } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const StyledTableBodyCell = styled(TableBodyCell) `
  &:hover {
    cursor: pointer;
  }
`;
export const StyledTypography = styled(Typography) `
  ${props => props.$completed && 'text-decoration: line-through'};
`;
