import { useApprovers } from 'app/domains/approval';
import { Approvers } from './Approvers';
import { NoApprovers } from './NoApprovers';
export function ApproversTab() {
    const { approvers } = useApprovers();
    if (approvers.length === 0) {
        return <NoApprovers />;
    }
    return <Approvers />;
}
