import { pxToRem } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  color: transparent;

  ${props => `
    width: ${pxToRem(props.$width)};
    height: ${pxToRem(props.$height)};
  `}
`;
