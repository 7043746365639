import { Box, Chip, Typography } from '@pocketlaw/tetris';
import { TemplateCardContainer } from './styled';
export function TemplateCard(props) {
    const { bg, label, description, actions } = props;
    return (<Box flexDirection="column" width="100%">
      <TemplateCardContainer bg={bg}>
        <Box>
          <Chip label={label} size="large" appearance="secondary" variant="outline"/>
        </Box>

        {actions && (<Box gap="lg" alignItems="stretch" justifyContent="center">
            {actions}
          </Box>)}
      </TemplateCardContainer>
      <Typography $fontSize="small" $truncate>
        {description}
      </Typography>
    </Box>);
}
