import { EmptyState, Button } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useTasks } from '../TasksProvider';
export function TasksError(props) {
    const { size } = props;
    const { tasks } = useTasks();
    if (!tasks.error) {
        return null;
    }
    return (<EmptyState title="Something went wrong!" description="An error occurred when trying to fetch tasks." icon={<Falcon icon="xmark"/>} actions={<Button onClick={tasks.refetch}>Try again</Button>} size={size} withAvatar/>);
}
