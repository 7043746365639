import { Trans } from '@lingui/macro';
function DeriveTitle(props) {
    const { code } = props;
    switch (code) {
        case 400:
            return <Trans>Bad request</Trans>;
        case 404:
            return <Trans>Page not found</Trans>;
        default:
            return <Trans>An error has occured</Trans>;
    }
}
export default DeriveTitle;
