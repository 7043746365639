import { Trans } from '@lingui/macro';
import { Link } from '@pocketlaw/tetris';
export function PrivacySettingsLink(props) {
    const handlePrivacySettingsClick = () => {
        var _a, _b;
        if (Boolean(window.UC_UI) && typeof ((_a = window.UC_UI) === null || _a === void 0 ? void 0 : _a.showSecondLayer) === 'function') {
            (_b = window.UC_UI) === null || _b === void 0 ? void 0 : _b.showSecondLayer();
        }
    };
    return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link {...props} as="button" onClick={handlePrivacySettingsClick}>
      <Trans comment="Privacy settings link text">Privacy settings</Trans>
    </Link>);
}
