import { Fragment, useMemo } from 'react';
import { WidthProvider, Responsive } from 'react-grid-layout';
import { ResizeHandle } from './ResizeHandle';
import { DRAGGABLE_HANDLE } from './constants';
import { EditModeStyles, GridCSS } from './styled';
export function WidgetGridLayout(props) {
    const { onLayoutChange, editMode = false, children, layouts } = props;
    const ResponsiveReactGridLayout = useMemo(() => WidthProvider(Responsive), []);
    return (<Fragment>
      <GridCSS />
      {editMode && <EditModeStyles />}
      <ResponsiveReactGridLayout useCSSTransforms className="layout" containerPadding={[0, 0]} margin={[16, 16]} rowHeight={16} layouts={layouts} cols={{
            lg: 12,
            md: 12,
            sm: 12,
            xs: 1,
            xxs: 1,
        }} draggableHandle={`.${DRAGGABLE_HANDLE}`} onDragStop={onLayoutChange} onResizeStop={onLayoutChange} resizeHandle={editMode && <ResizeHandle />}>
        {children}
      </ResponsiveReactGridLayout>
    </Fragment>);
}
