var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fragment, useRef, useState } from 'react';
import { t } from '@lingui/macro';
import { useGeneratedId } from '@pocketlaw/tetris';
import { ESIGNING_UPLOAD_ATTACHMENT_SUPPORTED_MIME_TYPES } from 'app/domains/esigning/utils';
import { FileUpload, MAX_ATTACHMENT_UPLOAD_SIZE_BYTES } from 'app/domains/upload';
import { DocumentType } from 'shared/domains/apollo/generated/types';
import { PickFileTemplateDialog } from 'shared/domains/common-ui';
import { ACTIVE_STATUSES } from 'shared/domains/documents';
import { useActiveDocument } from 'shared/domains/documents/hooks/usePreview';
import { CustomError } from 'shared/domains/errors/CustomError';
import { MimeTypesByExtension } from 'shared/domains/filesystem/utils/fileExtensions';
import * as Sentry from 'shared/domains/sentry';
import { Dropdown } from './Dropdown';
import { getFileToUpload } from './getFileToUpload';
import { useGetEntryFile } from './useGetEntryFile';
import { useUploadAttachment } from './useUploadAttachment';
import { useAttachments } from '../Provider';
import { AttachmentErrors } from '../Provider/types';
export function UploadAttachmentDropdown(props) {
    const { origin } = props;
    const { disableAdd, addAttachment, setAttachmentsError, uploadingAttachment, setUploadingAttachment, setFileSizeTooBigWarning, } = useAttachments();
    const fileUploadRef = useRef(null);
    const [renderPicker, setRenderPicker] = useState(false);
    const inputId = useGeneratedId('attachment-input');
    const [uploadFile] = useUploadAttachment();
    const getEntryFile = useGetEntryFile();
    const { id: documentId } = useActiveDocument();
    const startLoading = () => {
        setAttachmentsError(undefined);
        setFileSizeTooBigWarning(false);
        setUploadingAttachment(true);
    };
    const cancelLoading = () => {
        setUploadingAttachment(false);
    };
    const showPicker = () => setRenderPicker(true);
    const hidePicker = () => setRenderPicker(false);
    const handleUploadFile = () => { var _a; return (_a = fileUploadRef.current) === null || _a === void 0 ? void 0 : _a.click(); };
    const handleUploadError = (error) => {
        var _a;
        if (error instanceof CustomError && ((_a = error.data) === null || _a === void 0 ? void 0 : _a.code) && error.data.code === 'EntityTooLarge') {
            setFileSizeTooBigWarning(true);
        }
        else {
            setAttachmentsError(AttachmentErrors.UploadFailed);
            Sentry.captureException(error);
        }
    };
    const handleGetPdfError = (error) => {
        setUploadingAttachment(false);
        setAttachmentsError(AttachmentErrors.GetPdfFailed);
        Sentry.captureException(error);
    };
    const handleUpload = (file) => {
        startLoading();
        uploadFile(file).then(addAttachment).catch(handleUploadError).finally(cancelLoading);
    };
    const handleSelectEntry = (entry) => __awaiter(this, void 0, void 0, function* () {
        var _a;
        setUploadingAttachment(true);
        const entryFile = yield getEntryFile(entry).catch(handleGetPdfError);
        hidePicker();
        if (!entryFile || typeof entryFile === 'string') {
            return;
        }
        const fileToUpload = yield getFileToUpload({
            url: (_a = entryFile.signed) === null || _a === void 0 ? void 0 : _a.url,
            name: entry.name,
            mimeType: entryFile.mimeType,
        });
        if (!fileToUpload || typeof fileToUpload === 'string') {
            setAttachmentsError(AttachmentErrors.NoFileFound);
            cancelLoading();
            return;
        }
        handleUpload(fileToUpload);
    });
    const handleChange = (event) => {
        var _a, _b;
        const filesToUpload = (_b = (_a = event.target) === null || _a === void 0 ? void 0 : _a.files) !== null && _b !== void 0 ? _b : [];
        const file = filesToUpload[0];
        startLoading();
        if (!file) {
            setAttachmentsError(AttachmentErrors.NoFileProvided);
            cancelLoading();
            return;
        }
        if (!ESIGNING_UPLOAD_ATTACHMENT_SUPPORTED_MIME_TYPES.includes(file.type)) {
            setAttachmentsError(AttachmentErrors.InvalidFormat);
            cancelLoading();
            return;
        }
        if (file.size > MAX_ATTACHMENT_UPLOAD_SIZE_BYTES) {
            setFileSizeTooBigWarning(true);
            cancelLoading();
            return;
        }
        handleUpload(file);
        // eslint-disable-next-line no-param-reassign
        event.target.value = ''; // reset input value so there's no reference to it for future events (hover, focus)
    };
    const isEntryPdf = (entry) => {
        const { status, type, mimeType } = entry;
        const isSentForEsigning = Boolean(status && ACTIVE_STATUSES.includes(status));
        const isPdf = isSentForEsigning ||
            (type === DocumentType.Uploaded && mimeType === MimeTypesByExtension.Pdf);
        return isPdf;
    };
    return (<Fragment>
      <Dropdown origin={origin} onUpload={handleUploadFile} onSelect={showPicker}/>
      <FileUpload id={inputId} accept={ESIGNING_UPLOAD_ATTACHMENT_SUPPORTED_MIME_TYPES} multiple={false} ref={fileUploadRef} onChange={handleChange} disabled={disableAdd || uploadingAttachment} name="attachments"/>
      {renderPicker && (<PickFileTemplateDialog selfId={documentId} dialogTitle={t({
                comment: 'Select document attachment: title',
                message: 'Select attachment',
            })} dialogSubtitle={t({
                comment: 'Select document attachment: subtitle',
                message: 'Select a document to attach',
            })} submitLabel={t({
                comment: 'Select document attachment: submit button label',
                message: 'Attach',
            })} selectedEntryAlert={{
                appearance: 'info',
                title: t({
                    comment: 'Select document attachment: Selected attachment is not a pdf',
                    message: 'Selected attachment is not a pdf',
                }),
                message: t({
                    comment: 'Select document attachment: The selected document will be attached as a pdf',
                    message: 'The selected document will be attached as a pdf',
                }),
                check: isEntryPdf,
            }} onSubmitDocument={handleSelectEntry} onClose={hidePicker}/>)}
    </Fragment>);
}
