import { Trans } from '@lingui/macro';
import { Button, Loader } from '@pocketlaw/tetris';
import { useFormikContext } from 'formik';
export function SubmitButton() {
    const { handleSubmit, dirty, isValid, isSubmitting } = useFormikContext();
    const disabled = !dirty || !isValid || isSubmitting;
    const handleClick = () => handleSubmit();
    return (<Button $stretch type="submit" disabled={disabled} onClick={handleClick}>
      <Trans comment="Save default signatories button label">Save default signing parties</Trans>
      {isSubmitting && <Loader />}
    </Button>);
}
