import { useEsigningAuthenticationModes } from 'app/domains/esigning/hooks/useEsigningAuthenticationModes';
import { useEsigningProviderMetadata } from 'app/domains/esigning/hooks/useEsigningProviderMetadata';
export const useDefaultAuthenticationMode = (isSigning) => {
    const { authenticationModes } = useEsigningAuthenticationModes();
    const { properties } = useEsigningProviderMetadata();
    const { defaultAuthenticationMode } = properties;
    const hasAuthenticationModes = authenticationModes.length > 0;
    return isSigning && hasAuthenticationModes && defaultAuthenticationMode
        ? defaultAuthenticationMode
        : undefined;
};
