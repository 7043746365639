import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { TetrisThemeProvider, TooltipProvider } from '@pocketlaw/tetris';
import { localTheme } from 'app/domains/common-ui';
export function Providers(props) {
    const { children } = props;
    const { getTetrisTheme } = localTheme;
    return (<TetrisThemeProvider theme={getTetrisTheme()}>
      <I18nProvider i18n={i18n}>
        <TooltipProvider>{children}</TooltipProvider>
      </I18nProvider>
    </TetrisThemeProvider>);
}
