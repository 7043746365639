import { Drawer, pxToRem, themeGet, breakpoints } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const StyledDrawer = styled(Drawer) `
  width: 100%;
  max-width: ${pxToRem(360)};
  background-color: ${themeGet('colors.background.50')};
`;
export const DrawerHeader = styled.div `
  padding: ${themeGet('spacing.xl')};
`;
export const DrawerBody = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${themeGet('spacing.md')};
  padding: 0 ${themeGet('spacing.xl')};
`;
export const MobileContainer = styled.div `
  ${breakpoints.sm} {
    display: none;
  }
`;
export const DesktopContainer = styled.div `
  ${breakpoints.xs} {
    display: none;
  }
`;
