/* eslint-disable lingui/text-restrictions */
import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled, { css } from 'styled-components';
export const Background = styled.div `
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: ${themeGet('borderRadius.sm')};
  border: solid ${pxToRem(1)} ${themeGet('colors.gray.500')};

  &:after {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    right: 0;
    width: ${pxToRem(6)};
    height: 100%;
    border-left: inherit;
    background-color: ${themeGet('colors.gray.500')};
  }
`;
export const Arrow = styled.div `
  position: absolute;
  display: flex;
  align-items: center;
  color: ${themeGet('colors.gray.500')};
  transform: translateX(-${pxToRem(3)}) rotate(180deg);

  &:before {
    display: block;
    content: '';
    width: ${pxToRem(4)};
    height: ${pxToRem(2)};
    background-color: currentColor;
  }

  &:after {
    display: block;
    content: '';
    width: 0;
    height: 0;
    border-radius: ${themeGet('borderRadius.sm')};
    border-top: ${pxToRem(4)} solid transparent;
    border-bottom: ${pxToRem(4)} solid transparent;
    border-left: ${pxToRem(4)} solid currentColor;
  }
`;
export const Container = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${themeGet('spacing.md')};

  ${props => props.$open &&
    css `
      ${Arrow} {
        color: ${themeGet('colors.gray.0')};
        transform: translateX(${pxToRem(3)}) rotate(0deg);
      }

      ${Background}:after {
        width: ${pxToRem(14)};
      }
    `}
`;
