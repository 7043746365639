import { t } from '@lingui/macro';
import { PickFileTemplateDialog } from 'shared/domains/common-ui';
export function AttachmentBrowser(props) {
    const { onAdd, onCancel } = props;
    const title = t({
        message: 'Attach to',
        comment: 'Task link attachment dialog title',
    });
    const submitLabel = t({
        message: 'Attach',
        comment: 'Task link attachment dialog submit button label',
    });
    const handleSubmitDocument = (entry) => {
        onAdd({ id: entry.id, type: 'document' });
    };
    const handleSubmitTemplate = (template) => {
        onAdd({ id: template.id, type: 'template' });
    };
    return (<PickFileTemplateDialog dialogTitle={title} submitLabel={submitLabel} onSubmitDocument={handleSubmitDocument} onSubmitTemplate={handleSubmitTemplate} onClose={onCancel}/>);
}
