import { useMutation } from '@apollo/client';
import { useLastSignRequest } from 'app/domains/esigning/hooks/useLastSignRequest';
import { ERROR_CODES } from './constants';
import { updateSignRequestSignatoryMutation } from './mutation';
export const useUpdateSignRequestSignatory = (signatoryId) => {
    const { lastSignRequest } = useLastSignRequest();
    const { id: signRequestId } = lastSignRequest || {};
    const baseVariables = {
        signRequestId: signRequestId || '',
        signatoryId: signatoryId || '',
    };
    const [mutate, result] = useMutation(updateSignRequestSignatoryMutation, {
        variables: baseVariables,
    });
    const handler = (options) => {
        const variables = Object.assign(Object.assign({}, baseVariables), options);
        return mutate({
            variables,
            context: { sentry: { ignore: ERROR_CODES } },
        });
    };
    return [handler, result];
};
