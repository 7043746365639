import { gql } from '@apollo/client';
export const getImportDocumentToCcsJob = gql `
  query ImportDocumentToCcsJob($jobId: ID!) {
    importDocumentToCcsJob(id: $jobId) {
      id
      documentId
      status
    }
  }
`;
