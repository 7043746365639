import { select, t } from '@lingui/macro';
import { getItemType } from './getItemType';
export function getTitles(options) {
    const { users = [], teams = [], search } = options;
    const itemType = getItemType({ users, teams });
    const selectedItemsTitle = t({
        message: select(itemType, {
            usersAndTeams: 'Selected users & teams',
            users: 'Selected users',
            teams: 'Selected teams',
            other: 'Selected users & teams',
        }),
        comment: 'users & team select: subheading for selected users depending on if the list contains users, teams, or both',
    });
    const allItemsTitle = t({
        message: select(itemType, {
            usersAndTeams: 'All users & teams',
            users: 'All users',
            teams: 'All teams',
            other: 'All users & teams',
        }),
        comment: 'users & team select: subheading for all users (no text inserted in the search) depending on if the list contains users, teams, or both',
    });
    const filteredItemsTitle = t({
        message: select(itemType, {
            usersAndTeams: 'Filtered users & teams',
            users: 'Filtered users',
            teams: 'Filtered teams',
            other: 'Filtered users & teams',
        }),
        comment: 'users & team select: subheading for filtered users (text inserted in the search) depending on if the list contains users, teams, or both',
    });
    const unselectedItemsTitle = search.length === 0 ? allItemsTitle : filteredItemsTitle;
    return { selectedItemsTitle, unselectedItemsTitle };
}
