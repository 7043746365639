import { useLazyQuery } from '@apollo/client';
import { getAllMarketCodes, supportedMarketCodes } from 'app/domains/markets/config';
import { AdvancedSearchDocument } from './AdvancedSearch.gql';
import { transformSearchQuery } from './transformSearchQuery';
export const useLazySearch = (options) => {
    const { referrer = undefined } = options || {};
    const [getQuery, query] = useLazyQuery(AdvancedSearchDocument);
    const userMarketCodes = getAllMarketCodes();
    const excludedMarketCodes = supportedMarketCodes.filter(item => !userMarketCodes.includes(item));
    const search = (input) => getQuery({
        fetchPolicy: 'network-only',
        variables: {
            query: input,
            where: {
                marketCode: { notIn: excludedMarketCodes },
            },
            referrer,
        },
    });
    return {
        search,
        data: transformSearchQuery(query.data),
        loading: query.loading,
        error: query.error,
    };
};
