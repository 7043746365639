import { TableBodyCell, TableCellText } from '@pocketlaw/tetris';
import { localizedFormatDate } from 'shared/domains/common-utils';
import { EmptyCell } from './EmptyCell';
export function DateCell(props) {
    const { date, headers } = props;
    const primary = localizedFormatDate(date, {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
    });
    if (!date) {
        return <EmptyCell headers={headers}/>;
    }
    return (<TableBodyCell headers={headers}>
      <TableCellText primary={primary}/>
    </TableBodyCell>);
}
