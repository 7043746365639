var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@apollo/client';
import { SearchDistinctValuesForPropertyDocument } from './SearchDistinctValuesForProperty.gql';
export function useGetDistinctValues(propertyName) {
    const _a = useQuery(SearchDistinctValuesForPropertyDocument, {
        variables: {
            propertyName,
        },
    }), { data } = _a, rest = __rest(_a, ["data"]);
    const propertyValues = ((data === null || data === void 0 ? void 0 : data.documentMetadataPairsDistinctValuesForProperty) || []);
    const mappedValues = propertyValues.reduce((acc, pair) => {
        if (typeof pair !== 'string') {
            return acc;
        }
        const trimmedValue = pair.trim();
        if (trimmedValue === '') {
            return acc;
        }
        return [...acc, { id: trimmedValue, title: trimmedValue }];
    }, []);
    return Object.assign({ data: mappedValues }, rest);
}
