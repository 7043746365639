var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export const autosave = (editor) => __awaiter(void 0, void 0, void 0, function* () {
    const revisionTracker = editor.plugins.get('RevisionTracker');
    // @ts-ignore
    const { currentRevision } = revisionTracker;
    const oldRevisionVersion = currentRevision.toVersion;
    // Update the current revision with the newest document changes.
    yield revisionTracker.update();
    // Check if the revision was updated.
    // If not, do not make an unnecessary call.
    if (oldRevisionVersion === currentRevision.toVersion) {
        return true;
    }
    return false;
});
