import { TableBodyCell, Typography, breakpoints } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const DesktopTableBodyCell = styled(TableBodyCell) `
  ${breakpoints.xs} {
    display: none;
  }
`;
export const TableBodyCellText = styled(Typography) `
  white-space: nowrap;
`;
