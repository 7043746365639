import { Box, Checkbox } from '@pocketlaw/tetris';
export function ColumnToggle(props) {
    const { onClick, active } = props;
    const handleOnChange = () => {
        setTimeout(onClick, 300);
    };
    return (<Box ml="auto">
      <Checkbox defaultChecked={active} onChange={handleOnChange}/>
    </Box>);
}
