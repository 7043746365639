import { Trans, t } from '@lingui/macro';
import { Box, TextInput, Select, ToggleButton, ButtonGroup } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { SearchTimeUnit } from 'shared/domains/apollo/generated/types';
export function CustomOffset(props) {
    var _a, _b, _c, _d;
    const { selectedValue, setSelectedValue } = props;
    const value = ((_a = selectedValue === null || selectedValue === void 0 ? void 0 : selectedValue.rgte) === null || _a === void 0 ? void 0 : _a.value) || ((_b = selectedValue === null || selectedValue === void 0 ? void 0 : selectedValue.rlte) === null || _b === void 0 ? void 0 : _b.value) || 0;
    const currentTimeUnit = ((_c = selectedValue === null || selectedValue === void 0 ? void 0 : selectedValue.rgte) === null || _c === void 0 ? void 0 : _c.timeUnit) || ((_d = selectedValue === null || selectedValue === void 0 ? void 0 : selectedValue.rlte) === null || _d === void 0 ? void 0 : _d.timeUnit) || SearchTimeUnit.Day;
    const timeUnit = [
        {
            value: SearchTimeUnit.Day,
            label: t({ message: 'Day', comment: 'Filter - Relative offset selection: Day' }),
        },
        {
            value: SearchTimeUnit.Week,
            label: t({ message: 'Week', comment: 'Filter - Relative offset selection: Week' }),
        },
        {
            value: SearchTimeUnit.Month,
            label: t({ message: 'Month', comment: 'Filter - Relative offset selection: Month' }),
        },
        {
            value: SearchTimeUnit.Year,
            label: t({ message: 'Year', comment: 'Filter - Relative offset selection: Year' }),
        },
    ];
    const handleChangeValue = (ev) => {
        const newValue = parseInt(ev.target.value, 10) || 0;
        if (newValue > 0) {
            setSelectedValue({
                rgte: { value: 0, timeUnit: currentTimeUnit },
                rlte: { value: newValue, timeUnit: currentTimeUnit },
            });
        }
        else {
            setSelectedValue({
                rgte: { value: newValue, timeUnit: currentTimeUnit },
                rlte: { value: 0, timeUnit: currentTimeUnit },
            });
        }
    };
    const handleChangeTimeUnit = (ev) => {
        var _a, _b, _c, _d;
        const newTimeUnit = ev.target.value;
        setSelectedValue({
            rgte: { value: (_b = (_a = selectedValue === null || selectedValue === void 0 ? void 0 : selectedValue.rgte) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : 0, timeUnit: newTimeUnit },
            rlte: { value: (_d = (_c = selectedValue === null || selectedValue === void 0 ? void 0 : selectedValue.rlte) === null || _c === void 0 ? void 0 : _c.value) !== null && _d !== void 0 ? _d : 0, timeUnit: newTimeUnit },
        });
    };
    const invertValue = () => {
        const newValue = value > 0 ? -value : Math.abs(value);
        setSelectedValue({
            rgte: { value: newValue, timeUnit: currentTimeUnit },
            rlte: { value: 0, timeUnit: currentTimeUnit },
        });
    };
    return (<Box p="md" flexDirection="column" gap="md">
      <TextInput type="number" value={Math.abs(value)} min={0} onChange={handleChangeValue}/>
      <Select value={currentTimeUnit} onChange={handleChangeTimeUnit}>
        {timeUnit.map(unit => (<option key={unit.value} value={unit.value}>
            {unit.label}
          </option>))}
      </Select>
      <ButtonGroup>
        <ToggleButton disabled={value === 0} $stretch $toggled={value < 0} size="small" onClick={invertValue}>
          {value < 0 && <Falcon icon="check"/>}
          <Trans comment="Filter - Dates: Past button label">Past</Trans>
        </ToggleButton>
        <ToggleButton disabled={value === 0} $stretch $toggled={value > 0} size="small" onClick={invertValue}>
          {value > 0 && <Falcon icon="check"/>}
          <Trans comment="Filter - Dates: Future button label">Future</Trans>
        </ToggleButton>
      </ButtonGroup>
    </Box>);
}
