import { useMemo } from 'react';
import { t } from '@lingui/macro';
import { Chip } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useActiveDocument, usePreview } from 'shared/domains/documents';
import useGetThreads from 'shared/domains/feedback/hooks/useGetThreads';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { ListButton } from '../ListButton';
export function ExternalSharingButton() {
    var _a;
    const account = useAccount();
    const { isNotSupported } = usePreview();
    const { id: documentId } = useActiveDocument();
    const { userId: excludeUserId } = (_a = account.data) !== null && _a !== void 0 ? _a : {};
    const { data: threads } = useGetThreads({ excludeUserId: excludeUserId || '', id: documentId });
    const chipProps = useMemo(() => {
        const unreadMessages = threads.reduce((acc, thread) => acc + thread.unreadMessagesCount, 0);
        if (unreadMessages > 0) {
            return {
                appearance: 'danger',
                label: unreadMessages,
            };
        }
        if (isNotSupported) {
            return {
                appearance: 'secondary',
                label: t({
                    message: 'Not supported',
                    comment: 'Label for document with a file type that is not supported for external sharing',
                }),
            };
        }
        return undefined;
    }, [threads, isNotSupported]);
    return (<ListButton settingsKey="external-sharing" icon={<Falcon icon="share"/>} endElement={chipProps && <Chip {...chipProps}/>}/>);
}
