import { useFormikContext } from 'formik';
import { Visualization } from '../Visualization';
import { VisualizationContainer } from './styled';
export function Preview() {
    const { values } = useFormikContext();
    const { aggregations, type, filters, colorScheme, isHorizontal } = values;
    return (<VisualizationContainer>
      <Visualization type={type} aggregations={aggregations} filters={filters} colorScheme={colorScheme} isHorizontal={isHorizontal}/>
    </VisualizationContainer>);
}
