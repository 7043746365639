import { t } from '@lingui/macro';
import { useDocumentApproversWithStatus } from 'app/domains/esigning/hooks/useDocumentApproversWithStatus';
import { getApproverStatusBadge } from './utils';
import { SectionList, SectionListItem } from '../components';
export function DocumentApprovers(props) {
    const { showStatus = false } = props;
    const { approvers } = useDocumentApproversWithStatus();
    return (<SectionList title={t({ comment: 'Document approvers', message: 'Approvers' })}>
      {approvers.map(approver => (<SectionListItem key={approver.companyUserId} primary={approver.name} secondary={approver.email} endElement={showStatus && getApproverStatusBadge(approver.status)}/>))}
    </SectionList>);
}
