import { t } from '@lingui/macro';
import { Box, EmptyState } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { ContextMenuButton } from '../ContextMenuButton';
export function FileSystemEmpty(props) {
    return (<Box flexDirection="column" justifyContent="center">
      <EmptyState withAvatar icon={<Falcon icon="folder"/>} title={t({ message: 'This folder is empty', comment: 'Filesystem: Title for empty state' })} description={t({
            message: 'Be the first one to add documents in here.',
            comment: 'Filesystem: Description for empty state',
        })} actions={<ContextMenuButton {...props}/>}/>
    </Box>);
}
