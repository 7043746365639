var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { ReviewStatus } from 'shared/domains/apollo/generated/types';
import { usePleditor, usePreview } from 'shared/domains/documents';
import { Context } from './Context';
import { useAcceptFeedback } from './useAcceptFeedback';
import { useCancelDocumentReview } from './useCancelDocumentReview';
import { useCurrentReview } from './useCurrentReview';
import { useDiscardFeedback } from './useDiscardFeedback';
import { useDocumentHighlights } from './useDocumentHighlights';
import { useRecoverFeedback } from './useRecoverFeedback';
import { useRequestReview } from './useRequestReview';
export function PlaiProvider(props) {
    const { children } = props;
    const { pleditor } = usePleditor();
    const { isFreeText } = usePreview();
    const { cancelDocumentReview, loading: cancellingReview } = useCancelDocumentReview();
    const { requestReview, loading: requestingReview } = useRequestReview();
    const { acceptFeedback } = useAcceptFeedback();
    const { discardFeedback } = useDiscardFeedback();
    const { recoverFeedback } = useRecoverFeedback();
    const { addToast } = useToast();
    const { review, refetchReview, loading: loadingCurrentReview, startPolling: startPollingReview, stopPolling: stopPollingReview, } = useCurrentReview();
    const [activeFeedback, setActiveFeedback] = useState(null);
    const [activeDocumentHighlight, setActiveDocumentHighlight] = useState(null);
    const { getDocumentHighlights, getFeedbackHighlightsData, primeDocumentHighlightEntries } = useDocumentHighlights();
    const isRedlineAvailable = isFreeText && pleditor !== null;
    const feedbackHighlightsData = getFeedbackHighlightsData((activeFeedback === null || activeFeedback === void 0 ? void 0 : activeFeedback.id) || '');
    const updateActiveDocumentHighlight = useCallback((documentHighlight) => {
        activeDocumentHighlight === null || activeDocumentHighlight === void 0 ? void 0 : activeDocumentHighlight.highlightOff();
        documentHighlight.highlightOn();
        setActiveDocumentHighlight(documentHighlight);
    }, [activeDocumentHighlight]);
    const updateActiveFeedback = (feedback) => {
        activeDocumentHighlight === null || activeDocumentHighlight === void 0 ? void 0 : activeDocumentHighlight.highlightOff();
        setActiveFeedback(feedback);
        if (feedback) {
            getDocumentHighlights(feedback.feedback);
        }
    };
    const handleAcceptFeedback = (feedbackId) => {
        try {
            updateActiveFeedback(null);
            acceptFeedback(feedbackId);
        }
        catch (_a) {
            addToast({
                appearance: 'danger',
                title: t({
                    comment: 'Plai Review - accept issue - error toast message',
                    message: 'Could not accept the issue, please try again',
                }),
            });
        }
    };
    const handleDiscardFeedback = (feedbackId) => {
        try {
            updateActiveFeedback(null);
            discardFeedback(feedbackId);
        }
        catch (_a) {
            addToast({
                appearance: 'danger',
                title: t({
                    comment: 'Plai Review - discard issue - error toast message',
                    message: 'Could not discard the issue, please try again',
                }),
            });
        }
    };
    const handleRecoverFeedback = (feedbackId) => {
        try {
            updateActiveFeedback(null);
            recoverFeedback(feedbackId);
        }
        catch (_a) {
            addToast({
                appearance: 'danger',
                title: t({
                    comment: 'Plai Review - reopen issue - error toast message',
                    message: 'Could not reopen the issue, please try again',
                }),
            });
        }
    };
    const requestContractReview = (playbookId) => __awaiter(this, void 0, void 0, function* () {
        yield requestReview(playbookId);
        primeDocumentHighlightEntries();
    });
    useEffect(() => {
        if (feedbackHighlightsData && feedbackHighlightsData.documentHighlights.length > 0) {
            updateActiveDocumentHighlight(feedbackHighlightsData.documentHighlights[0]);
        }
    }, [feedbackHighlightsData, updateActiveDocumentHighlight]);
    const value = {
        initiating: loadingCurrentReview,
        requestReview: requestContractReview,
        review,
        refetchReview,
        requestingReview,
        reviewing: (review === null || review === void 0 ? void 0 : review.status) === ReviewStatus.Loading || (review === null || review === void 0 ? void 0 : review.status) === ReviewStatus.Cancelling,
        startPollingReview,
        stopPollingReview,
        activeFeedback,
        updateActiveFeedback,
        cancelDocumentReview,
        cancellingReview,
        acceptFeedback: handleAcceptFeedback,
        discardFeedback: handleDiscardFeedback,
        recoverFeedback: handleRecoverFeedback,
        isRedlineAvailable,
        activeDocumentHighlight,
        updateActiveDocumentHighlight,
        feedbackHighlightsData,
    };
    return <Context.Provider value={value}>{children}</Context.Provider>;
}
