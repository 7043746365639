import { GlobalNavigation, pxToRem, unit } from '@pocketlaw/tetris';
import { breakpoint } from '@pocketlaw/tetris/deprecated';
import styled, { css } from 'styled-components';
export const CloseContainer = styled.div `
  height: ${pxToRem(GlobalNavigation.GLOBAL_NAVIGATION_HEIGHT)};
  position: absolute;
  right: ${unit(3)};
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
function getPositionCSS(position) {
    if (position === 'right') {
        return css `
      margin-left: auto;
    `;
    }
    return undefined;
}
function getTransitionCSS(state, position) {
    const endPosition = position === 'left' ? '-100%' : '100%';
    switch (state) {
        case 'visible':
            return css `
        transform: translate(0, 0);
      `;
        case 'entering':
        case 'leaving':
        case 'hidden':
            return css `
        transform: translate(${endPosition}, 0);
      `;
        default:
            return undefined;
    }
}
export const Container = styled.div `
  background: #fff;
  height: 100%;
  width: calc(100% - 15vw);
  overflow: hidden;
  overflow-y: auto;

  transition: transform 0.2s ease-in-out;
  transition-delay: 5ms;

  ${props => getPositionCSS(props.$position)}

  ${props => getTransitionCSS(props.$state, props.$position)}

  @media ${breakpoint.lapAndUp} {
    width: 45vw;
  }
`;
