import { forwardRef } from 'react';
import { UPLOAD_DOCUMENT_SUPPORTED_MIME_TYPES } from 'app/domains/upload';
import { FileInput } from './styled';
export const UploadFilesInput = forwardRef((props, ref) => {
    const { onChange } = props;
    const accept = UPLOAD_DOCUMENT_SUPPORTED_MIME_TYPES.join(',');
    const handleChange = (event) => {
        onChange(Array.from(event.target.files || []));
        // eslint-disable-next-line no-param-reassign
        event.target.value = '';
    };
    return (<FileInput readOnly multiple type="file" name="upload-input" ref={ref} tabIndex={-1} accept={accept} onChange={handleChange}/>);
});
