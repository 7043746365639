import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${themeGet('spacing.sm')};
`;
export const LanguageButton = styled.button `
  appearance: none;
  text-decoration: none;
  box-sizing: border-box;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${themeGet('spacing.md')} ${themeGet('spacing.lg')};

  border-radius: ${themeGet('borderRadius.md')};
  border: solid ${pxToRem(1)} ${themeGet('colors.border.100')};
  background-color: ${props => props.$selected ? themeGet('colors.background.100') : themeGet('colors.background.50')};

  transition: all 175ms ease-out;

  &:hover {
    cursor: pointer;
    border-color: ${themeGet('colors.border.300')};
    background-color: ${themeGet('colors.background.100')};
  }
`;
