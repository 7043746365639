export function Mono() {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <circle cx="9" cy="9" r="9" fill="#63636D"/>
    </svg>);
}
export function MonoShaded() {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <mask id="mask0_3005_198185" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
        <circle cx="9" cy="9" r="9" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_3005_198185)">
        <rect x="-1" y="-1" width="10" height="10" fill="#63636D"/>
        <rect x="9" y="-1" width="10" height="10" fill="#A2A2AD"/>
        <rect x="-1" y="9" width="10" height="10" fill="#63636D"/>
        <rect x="9" y="9" width="10" height="10" fill="#A2A2AD"/>
      </g>
    </svg>);
}
export function Blue() {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <mask id="mask0_3005_198194" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
        <circle cx="9" cy="9" r="9" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_3005_198194)">
        <rect x="-1" y="-1" width="10" height="10" fill="#528BF4"/>
        <rect x="9" y="-1" width="10" height="10" fill="#528BF4"/>
        <rect x="-1" y="9" width="10" height="10" fill="#528BF4"/>
        <rect x="9" y="9" width="10" height="10" fill="#528BF4"/>
      </g>
    </svg>);
}
export function BlueShaded() {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <mask id="mask0_3005_198203" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
        <circle cx="9" cy="9" r="9" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_3005_198203)">
        <rect x="-1" y="-1" width="10" height="10" fill="#528BF4"/>
        <rect x="9" y="-1" width="10" height="10" fill="#9CBCF9"/>
        <rect x="-1" y="9" width="10" height="10" fill="#528BF4"/>
        <rect x="9" y="9" width="10" height="10" fill="#9CBCF9"/>
      </g>
    </svg>);
}
export function TwoColor() {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <mask id="mask0_3005_198212" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
        <circle cx="9" cy="9" r="9" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_3005_198212)">
        <rect x="-1" y="-1" width="10" height="10" fill="#528BF4"/>
        <rect x="9" y="-1" width="10" height="10" fill="#F3976C"/>
        <rect x="-1" y="9" width="10" height="10" fill="#528BF4"/>
        <rect x="9" y="9" width="10" height="10" fill="#F3976C"/>
      </g>
    </svg>);
}
export function MultiColor() {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <mask id="mask0_3041_12714" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
        <circle cx="9" cy="9" r="9" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_3041_12714)">
        <rect x="-1" y="-1" width="10" height="10" fill="#528BF4"/>
        <rect x="9" y="-1" width="10" height="10" fill="#E06456"/>
        <rect x="-1" y="9" width="10" height="10" fill="#61B589"/>
        <rect x="9" y="9" width="10" height="10" fill="#FFCD69"/>
      </g>
    </svg>);
}
