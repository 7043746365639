import { MenuBody } from '@pocketlaw/tetris';
import { useField } from 'formik';
import { SearchableMenuMultiSelectList, SearchableMenuProvider, SearchableMenuSearchHeader, } from 'shared/domains/common-ui';
import { NewValue } from './NewValue';
import { useGetPropertyValues } from './useGetPropertyValues';
import { SubmitButtonForSearchableLists } from '../SubmitButtonForSearchableLists';
export function MultiSelectWithInput(props) {
    const { name } = props;
    const [field, , helpers] = useField({
        name: 'value',
    });
    const { data } = useGetPropertyValues(name, field.value);
    const searchableItems = data.map(value => ({ id: value }));
    const selected = Array.isArray(field.value) ? field.value : [];
    const handleSelectItem = (item) => {
        const isSelected = selected.includes(item);
        if (isSelected) {
            helpers.setValue(selected.filter(value => value !== item));
        }
        else {
            helpers.setValue([...selected, item]);
        }
    };
    return (<SearchableMenuProvider items={searchableItems} keys={['id']} fuseOptions={{
            threshold: 0,
        }}>
      <SearchableMenuSearchHeader />
      <MenuBody>
        <NewValue addItem={handleSelectItem}/>
        <SearchableMenuMultiSelectList onSelect={item => handleSelectItem(item.id)} renderItem={({ id }) => id} selected={selected} emptyComponent={false}/>
      </MenuBody>
      <SubmitButtonForSearchableLists />
    </SearchableMenuProvider>);
}
