import { useEffect } from 'react';
import { useLingui } from '@lingui/react';
import * as Sentry from 'shared/domains/sentry';
import { namedRoles } from './namedRoles';
import { getUserRoleActions } from './roleActions';
import { useGetRole } from './useGetRole';
export function useRole(id) {
    const { role, loading, error } = useGetRole(id);
    const actions = getUserRoleActions(role);
    const { i18n } = useLingui();
    // NOTE: Will throw warnings and skip if not renamed. Will probably be solved in v4
    const tmpI18n = i18n;
    useEffect(() => {
        if (role && !actions) {
            Sentry.captureMessage(`No actions set for role "${role}".`);
        }
    }, [role, actions]);
    return {
        can: (action) => Boolean(actions === null || actions === void 0 ? void 0 : actions.includes(action)),
        has: (roles) => {
            const newRoles = Array.isArray(roles) ? roles : [roles];
            return role ? newRoles.includes(role) : false;
        },
        is: () => role,
        name: () => {
            const name = namedRoles[role || 'default'];
            return tmpI18n._(name);
        },
        state: {
            loading,
            error,
        },
    };
}
