import { t } from '@lingui/macro';
import { Tooltip, CheckButton } from '@pocketlaw/tetris';
function CompletedToggle(props) {
    const { completed, disabled, onClick } = props;
    const markAsClosed = t `Mark task as completed`;
    const markAsOpen = t `Mark task as unfinished`;
    const tooltipTitle = completed ? markAsOpen : markAsClosed;
    return (<Tooltip title={tooltipTitle}>
      <div>
        <CheckButton label={t({
            message: 'Button to complete task',
            comment: 'screen reader label for complate task CheckButton',
        })} selected={completed} onClick={onClick} disabled={disabled}/>
      </div>
    </Tooltip>);
}
export { CompletedToggle };
