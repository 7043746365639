import { useState } from 'react';
import { Box } from '@pocketlaw/tetris';
import { constructSearchURL } from 'app/domains/global-search';
import { Count } from './Count';
import { CountChart } from './CountChart';
import { TERMS_AGGREGATION_MISSING_VALUE_NAME } from '../../../constants';
import { getLabelForAllowedProperty } from '../../WidgetForm/SelectDataPoint/getAllowedProperties';
import { bucketAggregationToFilter } from '../bucketAggregationToFilter';
import { fieldDisplayValue } from '../fieldDisplayValue';
import { getSelectedField } from '../getSelectedField';
export function BucketCount(props) {
    const { data, aggregation, colorScheme, filters } = props;
    const [activeIndex, setActiveIndex] = useState(null);
    const field = getSelectedField(aggregation);
    const entries = (data === null || data === void 0 ? void 0 : data.buckets.filter(entry => entry.name !== TERMS_AGGREGATION_MISSING_VALUE_NAME)) || [];
    const totalCount = (entries === null || entries === void 0 ? void 0 : entries.reduce((acc, curr) => acc + curr.value, 0)) || 0;
    const activeEntry = entries === null || entries === void 0 ? void 0 : entries[activeIndex || 0];
    const activeName = activeEntry ? activeEntry.name : '';
    const translatedLabel = getLabelForAllowedProperty(aggregation === null || aggregation === void 0 ? void 0 : aggregation.type, field);
    const formattedValue = fieldDisplayValue(field, activeName);
    const activeLabel = activeEntry ? formattedValue : `${translatedLabel}`;
    const local = bucketAggregationToFilter(activeEntry, aggregation);
    const url = field &&
        constructSearchURL({
            where: Object.assign(Object.assign({}, filters), local),
        });
    return (<Box justifyContent="space-between" width="100%" alignItems="center" alignSelf="flex-start" gap="lg">
      <Count entries={entries} totalCount={totalCount} activeName={activeName} subtitle={activeLabel} to={url}/>
      <CountChart data={entries} dataKey="value" onHover={setActiveIndex} colorScheme={colorScheme}/>
    </Box>);
}
