var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useLazyQuery, useMutation } from '@apollo/client';
import { DeleteInsightsDashboardDocument, ListDashboardsAfterDeleteDocument, } from './DeleteInsightsDashboard.gql';
export function useDeleteDashboard() {
    const [mutate, state] = useMutation(DeleteInsightsDashboardDocument);
    const [getDashboards] = useLazyQuery(ListDashboardsAfterDeleteDocument);
    const handleDelete = (id) => __awaiter(this, void 0, void 0, function* () {
        yield mutate({
            variables: { id },
            update(cache, result) {
                const { deleteSearchInsightsDashboard } = result.data || {};
                const { __typename } = deleteSearchInsightsDashboard || {};
                if (__typename) {
                    cache.modify({
                        fields: {
                            searchInsightsDashboards(existing = [], { toReference, isReference }) {
                                var _a;
                                // eslint-disable-next-line no-underscore-dangle
                                const refToDelete = (_a = toReference({ id, __typename }, true)) === null || _a === void 0 ? void 0 : _a.__ref;
                                return existing.filter(existingDashboard => {
                                    if (isReference(existingDashboard)) {
                                        // eslint-disable-next-line no-underscore-dangle
                                        return existingDashboard.__ref !== refToDelete;
                                    }
                                    return existingDashboard.id !== id;
                                });
                            },
                        },
                    });
                }
            },
        });
        const { data } = yield getDashboards();
        const { searchInsightsDashboards } = data || {};
        return searchInsightsDashboards || [];
    });
    return [handleDelete, state];
}
