import * as faPenField from '@fortawesome/pro-regular-svg-icons/faPenField';
import * as faPenLine from '@fortawesome/pro-regular-svg-icons/faPenLine';
export const PenLine = `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="${faPenLine.svgPathData}"/>
  </svg>
`;
export const PenField = `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="${faPenField.svgPathData}"/>
  </svg>
`;
