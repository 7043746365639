import { Box, Radio } from '@pocketlaw/tetris';
import { StickyMenuItem } from './styled';
export function MenuSingleSelectItem(props) {
    const { selected, onClick, children } = props;
    return (<StickyMenuItem $selected={selected} as="label">
      {children}
      <Box ml="auto">
        <Radio checked={selected} onChange={onClick}/>
      </Box>
    </StickyMenuItem>);
}
