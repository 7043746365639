import { SearchFiltersToolbar } from 'app/domains/global-search';
import { ActiveFilter, activeFilterLabel } from '../Filters/ActiveFilter';
import { AutomaticRenewalFilter, automaticRenewalFilterLabel, } from '../Filters/AutomaticRenewalFilter';
import { ContractCreatorFilter, contractCreatorFilterLabel, } from '../Filters/ContractCreatorFilter';
import { ContractOwnerFilter, contractOwnerFilterLabel } from '../Filters/ContractOwnerFilter';
import { TotalContractValueFilter, totalContractValueFilterLabel, } from '../Filters/ContractValueFilter';
import { CreatedAtFilter, createdAtFilterLabel } from '../Filters/CreatedAtFilter';
import { CurrencyFilter, currencyFilterLabel } from '../Filters/CurrencyFilter';
import { DateSignedFilter, dateSignedFilterLabel } from '../Filters/DateSignedFilter';
import { DeviateFromStandardFilter, deviateFromStandardFilterLabel, } from '../Filters/DeviateFromStandardFilter';
import { DiscountFilter, discountFilterLabel } from '../Filters/DiscountFilter';
import { DocumentCategoryFilter, documentCategoryFilterLabel, } from '../Filters/DocumentCategoryFilter';
import { DocumentStartDateFilter, documentStartDateFilterLabel, } from '../Filters/DocumentStartDateFilter';
import { DocumentStatusFilter, documentStatusFilterLabel } from '../Filters/DocumentStatusFilter';
import { DocumentTypeFilter, documentTypeFilterLabel } from '../Filters/DocumentTypeFilter';
import { EntityTypeFilter, entityTypeFilterLabel } from '../Filters/EntityTypeFilter';
import { EsigningStatusFilter, esigningStatusFilterLabel } from '../Filters/EsigningStatusFilter';
import { FeeAdjustmentClauseFilter, feeAdjustmentClauseFilterLabel, } from '../Filters/FeeAdjustmentClauseFilter';
import { GoverningLawFilter, governingLawFilterLabel } from '../Filters/GoverningLawFilter';
import { IndemnityFilter, indemnityFilterLabel } from '../Filters/IndemnityFilter';
import { LiabilityCapFilter, liabilityCapFilterLabel } from '../Filters/LiabilityCapFilter';
import { LiabilityCapScopeFilter, liabilityCapScopeFilterLabel, } from '../Filters/LiabilityCapScopeFilter';
import { LimitationOfLiabilityFilter, limitationOfLiabilityFilterLabel, } from '../Filters/LimitationOfLiabilityFilter';
import { MaterialAgreementFilter, materialAgreementFilterLabel, } from '../Filters/MaterialAgreementFilter';
import { NoticeDateFilter, noticeDateFilterLabel } from '../Filters/NoticeDateFilter';
import { NoticePeriodDaysFilter, noticePeriodDaysFilterLabel, } from '../Filters/NoticePeriodDaysFilter';
import { NoticePeriodMonthsFilter, noticePeriodMonthsFilterLabel, } from '../Filters/NoticePeriodMonthsFilter';
import { OriginalTermMonthsFilter, originalTermMonthsFilterLabel, } from '../Filters/OriginalTermMonthsFilter';
import { OutsourcingFilter, outsourcingFilterLabel } from '../Filters/OutsourcingFilter';
import { PartiesFilter, partiesFilterLabel } from '../Filters/PartiesFilter';
import { PaymentTermsDaysFilter, paymentTermsDaysFilterLabel, } from '../Filters/PaymentTermsDaysFilter';
import { PostTerminationObligationsFilter, postTerminationObligationsFilterLabel, } from '../Filters/PostTerminationObligationsFilter';
import { ProbationaryPeriodExpirationDateFilter, probationaryPeriodExpirationDateFilterLabel, } from '../Filters/ProbationaryPeriodExpirationDateFilter';
import { ProbationaryPeriodFilter, probationaryPeriodFilterLabel, } from '../Filters/ProbationaryPeriodFilter';
import { ProcessingOfPersonalDataFilter, processingOfPersonalDataFilterLabel, } from '../Filters/ProcessingOfPersonalDataFilter';
import { RenewalTermMonthsFilter, renewalTermMonthsFilterLabel, } from '../Filters/RenewalTermMonthsFilter';
import { SharedExternallyFilter, sharedExternallyFilterLabel, } from '../Filters/SharedExternallyFilter';
import { TemplateCategoryFilter, templateCategoryFilterLabel, } from '../Filters/TemplateCategoryFilter';
import { TerminationDateFilter, terminationDateFilterLabel, } from '../Filters/TerminationDateFilter';
import { TerminationForCauseFilter, terminationForCauseFilterLabel, } from '../Filters/TerminationForCauseFilter';
import { TerminationForConvenienceFilter, terminationForConvenienceFilterLabel, } from '../Filters/TerminationForConvenienceFilter';
import { TotalContractCostFilter, totalContractCostFilterLabel, } from '../Filters/TotalContractCostFilter';
import { UpdatedAtFilter, updatedAtFilterLabel } from '../Filters/UpdatedAtFilter';
export function FilterSelection() {
    return (<SearchFiltersToolbar filterItems={[
            {
                id: 'createdByUserId',
                component: ContractCreatorFilter,
                name: contractCreatorFilterLabel(),
            },
            {
                id: 'entityType',
                component: EntityTypeFilter,
                name: entityTypeFilterLabel(),
            },
            {
                id: 'categoryId',
                component: TemplateCategoryFilter,
                name: templateCategoryFilterLabel(),
            },
            {
                id: 'metadataDocumentStatus',
                component: DocumentStatusFilter,
                name: documentStatusFilterLabel(),
            },
            {
                id: 'metadataDocumentActive',
                component: ActiveFilter,
                name: activeFilterLabel(),
            },
            {
                id: 'metadataDocumentAutomaticRenewal',
                component: AutomaticRenewalFilter,
                name: automaticRenewalFilterLabel(),
            },
            {
                id: 'metadataDocumentDeviateFromStandard',
                component: DeviateFromStandardFilter,
                name: deviateFromStandardFilterLabel(),
            },
            {
                id: 'metadataDocumentMaterialAgreement',
                component: MaterialAgreementFilter,
                name: materialAgreementFilterLabel(),
            },
            {
                id: 'metadataDocumentOutsourcing',
                component: OutsourcingFilter,
                name: outsourcingFilterLabel(),
            },
            {
                id: 'metadataDocumentStartDate',
                component: DocumentStartDateFilter,
                name: documentStartDateFilterLabel(),
            },
            {
                id: 'createdAt',
                component: CreatedAtFilter,
                name: createdAtFilterLabel(),
            },
            {
                id: 'updatedAt',
                component: UpdatedAtFilter,
                name: updatedAtFilterLabel(),
            },
            {
                id: 'metadataDocumentDateSigned',
                component: DateSignedFilter,
                name: dateSignedFilterLabel(),
            },
            {
                id: 'metadataDocumentNoticeDate',
                component: NoticeDateFilter,
                name: noticeDateFilterLabel(),
            },
            {
                id: 'metadataDocumentTerminationDate',
                component: TerminationDateFilter,
                name: terminationDateFilterLabel(),
            },
            {
                id: 'metadataDocumentContractOwner',
                component: ContractOwnerFilter,
                name: contractOwnerFilterLabel(),
            },
            {
                id: 'metadataDocumentParties',
                component: PartiesFilter,
                name: partiesFilterLabel(),
            },
            {
                id: 'metadataDocumentCurrency',
                component: CurrencyFilter,
                name: currencyFilterLabel(),
            },
            {
                id: 'metadataDocumentDocumentCategory',
                component: DocumentCategoryFilter,
                name: documentCategoryFilterLabel(),
            },
            {
                id: 'metadataDocumentDocumentType',
                component: DocumentTypeFilter,
                name: documentTypeFilterLabel(),
            },
            {
                id: 'metadataDocumentEsigningStatus',
                component: EsigningStatusFilter,
                name: esigningStatusFilterLabel(),
            },
            {
                id: 'metadataDocumentGoverningLaw',
                component: GoverningLawFilter,
                name: governingLawFilterLabel(),
            },
            {
                id: 'metadataDocumentIsSharedExternally',
                component: SharedExternallyFilter,
                name: sharedExternallyFilterLabel(),
            },
            {
                id: 'metadataDocumentNoticePeriodDays',
                component: NoticePeriodDaysFilter,
                name: noticePeriodDaysFilterLabel(),
            },
            {
                id: 'metadataDocumentNoticePeriodMonths',
                component: NoticePeriodMonthsFilter,
                name: noticePeriodMonthsFilterLabel(),
            },
            {
                id: 'metadataDocumentOriginalTermMonths',
                component: OriginalTermMonthsFilter,
                name: originalTermMonthsFilterLabel(),
            },
            {
                id: 'metadataDocumentRenewalTermMonths',
                component: RenewalTermMonthsFilter,
                name: renewalTermMonthsFilterLabel(),
            },
            {
                id: 'metadataDocumentTotalContractCost',
                component: TotalContractCostFilter,
                name: totalContractCostFilterLabel(),
            },
            {
                id: 'metadataDocumentTotalContractValue',
                component: TotalContractValueFilter,
                name: totalContractValueFilterLabel(),
            },
            {
                id: 'metadataDocumentProcessingOfPersonalData',
                component: ProcessingOfPersonalDataFilter,
                name: processingOfPersonalDataFilterLabel(),
            },
            {
                id: 'metadataDocumentLiabilityCapScope',
                component: LiabilityCapScopeFilter,
                name: liabilityCapScopeFilterLabel(),
            },
            {
                id: 'metadataDocumentDiscount',
                component: DiscountFilter,
                name: discountFilterLabel(),
            },
            {
                id: 'metadataDocumentFeeAdjustmentClause',
                component: FeeAdjustmentClauseFilter,
                name: feeAdjustmentClauseFilterLabel(),
            },
            {
                id: 'metadataDocumentIndemnity',
                component: IndemnityFilter,
                name: indemnityFilterLabel(),
            },
            {
                id: 'metadataDocumentLiabilityCap',
                component: LiabilityCapFilter,
                name: liabilityCapFilterLabel(),
            },
            {
                id: 'metadataDocumentLimitationOfLiability',
                component: LimitationOfLiabilityFilter,
                name: limitationOfLiabilityFilterLabel(),
            },
            {
                id: 'metadataDocumentPaymentTermsDaysFromInvoiceDate',
                component: PaymentTermsDaysFilter,
                name: paymentTermsDaysFilterLabel(),
            },
            {
                id: 'metadataDocumentPostTerminationObligations',
                component: PostTerminationObligationsFilter,
                name: postTerminationObligationsFilterLabel(),
            },
            {
                id: 'metadataDocumentProbationaryPeriod',
                component: ProbationaryPeriodFilter,
                name: probationaryPeriodFilterLabel(),
            },
            {
                id: 'metadataDocumentProbationaryPeriodExpirationDate',
                component: ProbationaryPeriodExpirationDateFilter,
                name: probationaryPeriodExpirationDateFilterLabel(),
            },
            {
                id: 'metadataDocumentTerminationForCause',
                component: TerminationForCauseFilter,
                name: terminationForCauseFilterLabel(),
            },
            {
                id: 'metadataDocumentTerminationForConvenience',
                component: TerminationForConvenienceFilter,
                name: terminationForConvenienceFilterLabel(),
            },
        ]}/>);
}
