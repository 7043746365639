import { useMutation } from '@apollo/client';
import { useActiveDocument } from 'shared/domains/documents';
import { CreateDocumentChatDocument } from './CreateDocumentChat.gql';
import { getLastChatQuery } from '../useGetLastDocumentChat';
export function useCreateDocumentChat() {
    const { id: documentId } = useActiveDocument();
    const [mutate, state] = useMutation(CreateDocumentChatDocument);
    const handler = () => mutate({
        variables: {
            documentId,
        },
        refetchQueries: [getLastChatQuery],
        onQueryUpdated(observableQuery) {
            return observableQuery.refetch();
        },
    }).then(response => { var _a; return (_a = response.data) === null || _a === void 0 ? void 0 : _a.createDocumentChat.id; });
    return [handler, state];
}
