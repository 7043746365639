import { breakpoints, unit, Card } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const StyledCard = styled(Card) `
  height: 100%;
`;
export const Container = styled.div `
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 100%;
  gap: ${unit('2xl')};

  ${breakpoints.lg} {
    flex-direction: row;
  }
`;
export const ChartContainer = styled.div `
  position: relative;
  display: block;
`;
