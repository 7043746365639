import { themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Overlay = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${themeGet('spacing.md')};
  position: absolute;
  z-index: 2;
  background: ${themeGet('colors.gray.0')};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
export const ButtonContainer = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: ${themeGet('spacing.md')};
`;
