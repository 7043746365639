var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useMutation, useQuery, NetworkStatus } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import apollo from 'shared/domains/apollo/cache';
import { DocumentMetadataAiAutoTaggingQueueEntryStatus } from 'shared/domains/apollo/generated/types';
import { extractDocumentMetadata } from './mutation';
import { autoTaggingQuery } from './query';
import { getState } from './utils';
const POLL_INTERVAL = 1000 * 3;
export function useAutoTagging(documentId, options) {
    const { featureAvailable, onCompleted } = options;
    const { addToast } = useToast();
    const [mutation, mutationState] = useMutation(extractDocumentMetadata);
    const _a = useQuery(autoTaggingQuery, {
        variables: {
            documentId,
        },
        skip: !featureAvailable,
        notifyOnNetworkStatusChange: true,
        onCompleted: newQueueData => {
            const previousState = getState({ queue: previousData });
            const currentState = getState({ queue: newQueueData });
            const isPolling = networkStatus === NetworkStatus.poll;
            const shouldStartPolling = currentState === 'loading';
            if (isPolling === false && shouldStartPolling) {
                startPolling(POLL_INTERVAL);
            }
            if (currentState !== 'loading' && previousState && currentState !== previousState) {
                addToast({
                    title: t({
                        message: 'Document metadata extraction completed.',
                        comment: 'Metadata Auto tag: Completed',
                    }),
                    appearance: 'success',
                });
                stopPolling();
                if (onCompleted) {
                    onCompleted();
                }
            }
        },
    }), { data, startPolling, stopPolling, previousData, networkStatus } = _a, queryState = __rest(_a, ["data", "startPolling", "stopPolling", "previousData", "networkStatus"]);
    const { autoTagging } = data || {};
    const [docAutoTag] = autoTagging || [];
    const { attemptCount = 0 } = docAutoTag || {};
    const handleMutation = () => __awaiter(this, void 0, void 0, function* () {
        const typeName = 'DocumentMetadataAiAutoTaggingQueueEntry';
        // We will immediately update the cache to show the user that the document is being processed.
        apollo.writeQuery({
            query: autoTaggingQuery,
            data: {
                autoTagging: [
                    {
                        __typename: typeName,
                        documentId,
                        status: DocumentMetadataAiAutoTaggingQueueEntryStatus.Pending,
                        attemptCount: attemptCount + 1,
                    },
                ],
            },
            variables: {
                documentId,
            },
        });
        try {
            yield mutation({
                variables: {
                    documentIds: [documentId],
                },
                update(cache, { data: newData }) {
                    const { newQueueEntries } = newData || {};
                    if (newQueueEntries && newQueueEntries.length) {
                        cache.modify({
                            fields: {
                                documentMetadataAiAutoTaggingQueueEntries(existing = []) {
                                    return [...existing, ...newQueueEntries];
                                },
                            },
                        });
                    }
                },
                onCompleted: () => {
                    startPolling(POLL_INTERVAL);
                },
            });
            addToast({
                title: t({
                    message: 'Document metadata extraction started.',
                    comment: 'Metadata Auto tag: Started',
                }),
                appearance: 'info',
            });
        }
        catch (_b) {
            addToast({
                title: t({
                    message: 'Document metadata extraction failed.',
                    comment: 'Metadata Auto tag: Error starting auto tag',
                }),
                appearance: 'danger',
            });
            // Revert the cache changes to the previous data if there is an error.
            apollo.writeQuery({
                query: autoTaggingQuery,
                data: {
                    autoTagging,
                },
                variables: {
                    documentId,
                },
            });
        }
    });
    const currentState = getState({
        queue: data,
        apiStates: [mutationState, queryState],
        featureAvailability: featureAvailable,
    });
    return {
        extract: handleMutation,
        state: currentState,
        attemptCount,
    };
}
