import { RevisionHistory } from 'pleditor';
import { PleditorPlugin } from '../PleditorPlugin';
const SHOW = '_showRevisionViewer';
const CLOSE = '_closeRevisionViewer';
export class RevisionHistoryToolbarHelper extends PleditorPlugin {
    static get pluginName() {
        return 'RevisionHistoryToolbarHelper';
    }
    static get requires() {
        return [RevisionHistory];
    }
    constructor(editor) {
        super(editor);
        this.toolbarElement = null;
    }
    init() {
        const { editor } = this;
        const revisionHistory = editor.plugins.get(RevisionHistory);
        revisionHistory.decorate(SHOW);
        revisionHistory.decorate(CLOSE);
        editor.on('ready', this.handleEditorReady.bind(this), { priority: 'lowest' });
        revisionHistory.on(SHOW, this.hideToolbar.bind(this), { priority: 'highest' });
        revisionHistory.on(CLOSE, this.showToolbar.bind(this), { priority: 'highest' });
    }
    handleEditorReady() {
        const editor = this.editor;
        this.toolbarElement = editor.ui.view.toolbar.element;
    }
    hideToolbar() {
        if (this.toolbarElement) {
            this.toolbarElement.style.display = 'none';
        }
    }
    showToolbar() {
        if (this.toolbarElement) {
            this.toolbarElement.style.display = 'flex';
        }
    }
}
