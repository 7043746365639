import { useQuery } from '@apollo/client';
import { AclAction, CompanyRoleEnum } from 'shared/domains/apollo/generated/types';
import { QUERY } from './query';
import { useActiveDocument } from '../../../../../hooks/usePreview';
export function useGetUserIdAccess() {
    const { id } = useActiveDocument();
    const { data } = useQuery(QUERY, {
        variables: {
            id,
            where: { fileSystemDocumentId: { eq: id } },
        },
    });
    const { entry, companyUsers = [], invites = [] } = data || {};
    const { acls = [] } = entry || {};
    const ownersUserIds = companyUsers
        .filter(({ role }) => role === CompanyRoleEnum.Owner)
        .map(({ user }) => user === null || user === void 0 ? void 0 : user.id)
        .filter(Boolean);
    const otherCompanyUserIdsWithAccess = acls
        .filter(({ actions }) => actions.includes(AclAction.Read))
        .map(({ userId }) => userId);
    const companyUsersIdsWithAccess = [...ownersUserIds, ...otherCompanyUserIdsWithAccess];
    const companyUsersWithoutAccess = companyUsers
        .filter(({ user }) => (user === null || user === void 0 ? void 0 : user.id) && !companyUsersIdsWithAccess.includes(user.id))
        .map(({ user }) => user === null || user === void 0 ? void 0 : user.id)
        .filter(Boolean);
    const externalUserIds = invites
        .filter(({ invitee }) => !!invitee)
        .map(({ invitee }) => invitee === null || invitee === void 0 ? void 0 : invitee.id)
        .filter(Boolean);
    return {
        usersWithoutAccess: companyUsersWithoutAccess,
        usersWithAccess: companyUsersIdsWithAccess,
        usersExternal: externalUserIds,
    };
}
