import { themeGet } from '@pocketlaw/tetris';
import { css } from 'styled-components';
export const dropdownStyles = css `
  .ck-dropdown__panel {
    border-radius: ${themeGet('borderRadius.md')} !important;

    .ck-list {
      border-radius: ${themeGet('borderRadius.md')} !important;

      .ck-list__item:not(.ck-hidden) > .ck-button {
        padding: ${themeGet('spacing.md')};
        border-radius: unset !important;
      }

      // Add border radius to the first visible item in the list
      .ck-list__item:not(.ck-hidden):first-child,
      .ck-list__item.ck-hidden + .ck-list__item:not(.ck-hidden) {
        & .ck-button {
          border-top-left-radius: ${themeGet('borderRadius.md')} !important;
          border-top-right-radius: ${themeGet('borderRadius.md')} !important;
        }
      }

      // If the first item is visible but the button is hidden, add border radius to the next visible item with a visible button
      .ck-list__item:not(.ck-hidden):has(> .ck-button:is(.ck-hidden))
        + .ck-list__item:not(.ck-hidden) {
        & .ck-button {
          border-top-left-radius: ${themeGet('borderRadius.md')} !important;
          border-top-right-radius: ${themeGet('borderRadius.md')} !important;
        }
      }

      // Remove border radius from items in the list that are after a hidden item and after the first visible item
      .ck-list__item:not(.ck-hidden):first-child ~ .ck-list__item,
      .ck-list__item.hidden + .ck-list__item:not(.hidden) ~ .ck-list__item {
        & .ck-button {
          border-top-left-radius: unset !important;
          border-top-right-radius: unset !important;
        }
      }

      // Add border radius to the last visible item in the list
      .ck-list__item:not(.ck-hidden):last-child {
        & > .ck-button {
          border-bottom-left-radius: ${themeGet('borderRadius.md')} !important;
          border-bottom-right-radius: ${themeGet('borderRadius.md')} !important;
        }
      }
    }
  }
`;
