import { t } from '@lingui/macro';
import { Box, EmptyState, List, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { usePlai } from 'app/domains/review';
import { getFeedbackLabel } from 'app/domains/review/utils';
import { ReviewFeedbackStatus } from 'shared/domains/apollo/generated/types';
import { Feedback } from '../Feedback';
export function HandledFeedback() {
    const { review } = usePlai();
    const { feedbacks = [] } = review || {};
    const resolvedFeedback = {
        title: getFeedbackLabel('resolved'),
        items: feedbacks.filter(({ feedback }) => feedback.status === ReviewFeedbackStatus.Accepted),
    };
    const rejectedFeedback = {
        title: getFeedbackLabel('rejected'),
        items: feedbacks.filter(({ feedback }) => feedback.status === ReviewFeedbackStatus.Discarded),
    };
    const feedbackItems = [resolvedFeedback, rejectedFeedback].filter(({ items }) => items.length > 0);
    if (feedbackItems.length === 0) {
        return (<EmptyState withAvatar icon={<Falcon icon="check"/>} title={t({
                comment: 'Contract review handled issues - Empty state title',
                message: 'Nothing resolved yet',
            })} description={t({
                comment: 'Contract review handled issues - Empty state description',
                message: 'Issues you resolve will show up here.',
            })}/>);
    }
    return (<Box flexDirection="column">
      {feedbackItems.map(({ title, items }) => (<List key={title} $density="large">
          <Typography $fontSize="small">{title}</Typography>
          {items.map(feedbackItem => (<Feedback key={feedbackItem.feedback.id} feedback={feedbackItem}/>))}
        </List>))}
    </Box>);
}
