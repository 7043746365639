import { gql, useMutation } from '@apollo/client';
const removeExternalSharingInviteMutation = gql `
  mutation removeExternalSharingInvite($id: UUID!) {
    removeExternalSharingInvite(id: $id) {
      id
    }
  }
`;
export function useRemoveExternalSharingInvite() {
    const [mutate, { loading, error }] = useMutation(removeExternalSharingInviteMutation);
    const removeExternalSharingInvite = (id) => mutate({
        variables: { id },
        update(cache) {
            cache.modify({
                id: cache.identify({ id, __typename: 'ExternalSharingInvite' }),
                fields(fieldValue, { DELETE }) {
                    return DELETE;
                },
            });
        },
    });
    return { removeExternalSharingInvite, loading, error };
}
