import { TableBodyCell } from '@pocketlaw/tetris';
import { UserAvatarWithLabel } from 'app/domains/users';
import { EmptyCell } from './EmptyCell';
export function AvatarCell(props) {
    const { primary, headers } = props;
    if (!primary) {
        return <EmptyCell headers={headers}/>;
    }
    return (<TableBodyCell headers={headers}>
      <UserAvatarWithLabel title={primary} size="sm"/>
    </TableBodyCell>);
}
