import { useEffect } from 'react';
import { createPath } from 'history';
import { useLocation } from 'react-router-dom';

import { getCurrentMarket } from 'app/domains/markets/config';

export default function useEnsureCorrectMarket(companyMarketCode) {
  const location = useLocation();
  const marketCode = getCurrentMarket().code;
  const shouldRedirect = companyMarketCode != null && companyMarketCode !== marketCode;

  useEffect(() => {
    if (shouldRedirect) {
      window.location.replace(`/${companyMarketCode}${createPath(location)}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRedirect, location, createPath]);

  return shouldRedirect;
}
