import { themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${themeGet('spacing.xl')};
  padding: ${themeGet('spacing.xl')};
  border-radius: ${themeGet('borderRadius.md')};
  background-color: ${themeGet('colors.gray.50')};
`;
export const SwitchContainer = styled.div `
  display: flex;
  flex-shrink: 0;
`;
