import { usePlai } from 'app/domains/review';
import { Highlights } from './Highlights';
import { usePleditor } from '../Preview/Pleditor';
export function DocumentContractHighlights() {
    const { pleditorState } = usePleditor();
    const { activeFeedback } = usePlai();
    if (!activeFeedback || pleditorState !== 'initialized') {
        return null;
    }
    return <Highlights />;
}
