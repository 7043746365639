import { t } from '@lingui/macro';
import { Select } from '@pocketlaw/tetris';
import { useField } from 'formik';
import { useEsigningAuthenticationModes } from 'app/domains/esigning/hooks/useEsigningAuthenticationModes';
import { getAuthenticationModeTitle } from 'app/domains/esigning/utils';
import { ErrorMessage } from 'shared/domains/forms/components/ErrorMessage';
import { FieldWrapper } from '../../styled';
const FIELD_NAME = 'authenticationMode';
export function AuthenticationModeSelect() {
    const { authenticationModes } = useEsigningAuthenticationModes();
    const [field, meta] = useField(FIELD_NAME);
    const [isSigning] = useField('isSigning');
    const error = Boolean(meta.touched && meta.error);
    const disabled = isSigning.value === false;
    if (authenticationModes.length === 0) {
        return null;
    }
    return (<FieldWrapper>
      <Select disabled={disabled} stretch invalid={error} {...field} label={t({
            comment: 'Signing party form field - Authentication',
            message: 'Authentication',
        })} placeholder={t({
            comment: 'Select signing party authentication placeholder label',
            message: 'Select authentication',
        })}>
        {authenticationModes.map(({ slug }) => (<option key={slug} value={slug}>
            {getAuthenticationModeTitle(slug)}
          </option>))}
      </Select>
      <ErrorMessage name={FIELD_NAME}/>
    </FieldWrapper>);
}
