import { Fragment } from 'react';
import { t } from '@lingui/macro';
import { useAssistant, AssistantChatActions, AssistantChatThread } from 'app/domains/assistant';
import { ResolvedChatActions } from './ResolvedChatActions';
import { OverlayLoader } from '../OverlayLoader';
export function AssistantChat() {
    const { clearing, resolved } = useAssistant();
    return (<Fragment>
      {clearing && (<OverlayLoader title={t({
                comment: 'Loading message when the document assistant is clearing the current conversation - title',
                message: 'Loading',
            })} message={t({
                comment: 'Loading message when the document assistant is clearing the current conversation  - description',
                message: 'Wait a moment while we are deleting this conversation',
            })} overlay/>)}
      <AssistantChatThread />
      {resolved ? <ResolvedChatActions /> : <AssistantChatActions />}
    </Fragment>);
}
