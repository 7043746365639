import { useQuery } from '@apollo/client';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { GET_TEAM_MEMBERS } from './query';
import { getTeamRoleActions } from '../getTeamRoleActions';
export function useTeamRole(teamId) {
    var _a, _b, _c;
    const account = useAccount();
    const currentUserId = account.data.id;
    const team = useQuery(GET_TEAM_MEMBERS, {
        variables: {
            id: teamId,
        },
    });
    const loading = team.loading || account.loading;
    const error = team.error || account.error;
    const { role } = ((_c = (_b = (_a = team.data) === null || _a === void 0 ? void 0 : _a.team) === null || _b === void 0 ? void 0 : _b.members) === null || _c === void 0 ? void 0 : _c.find(({ companyUser }) => (companyUser === null || companyUser === void 0 ? void 0 : companyUser.id) === currentUserId && Boolean(companyUser === null || companyUser === void 0 ? void 0 : companyUser.id))) || {};
    const actions = getTeamRoleActions(role);
    return {
        can: (action) => Boolean(actions === null || actions === void 0 ? void 0 : actions.includes(action)),
        is: () => role,
        state: {
            loading,
            error,
        },
    };
}
