import { Chip } from '@pocketlaw/tetris';
import { SignatoryStatus } from 'shared/domains/apollo/generated/types';
import { getAppearanceByStatus, getLabelByStatus } from './utils';
const UNHANDLED_STATUSES = [SignatoryStatus.Failed, SignatoryStatus.Unknown];
export function SignatoryStatusBadge(props) {
    const { status } = props;
    if (UNHANDLED_STATUSES.includes(status)) {
        return null;
    }
    return <Chip appearance={getAppearanceByStatus(status)} label={getLabelByStatus(status)}/>;
}
