import { gql } from '@apollo/client';
export const REMOVE_INVITE = gql `
  mutation RemoveInvite($id: UUID!) {
    updateUserInvite(where: { id: { _eq: $id } }, _set: { deleted_at: "now()" }) {
      returning {
        id
      }
    }
  }
`;
