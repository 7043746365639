import { isSameDay } from 'date-fns';
export const getTransformedMessages = (messages) => {
    if (!messages) {
        return [];
    }
    return messages.map((message, index, collection) => {
        if (index > 0) {
            const previousMessage = collection[index - 1];
            const hideSender = previousMessage.sender.id === message.sender.id;
            const hideTimestamp = hideSender && isSameDay(previousMessage.createdAtDate, message.createdAtDate);
            return Object.assign(Object.assign({}, message), { hideSender, hideTimestamp });
        }
        return Object.assign(Object.assign({}, message), { hideSender: false, hideTimestamp: false });
    });
};
