import { t, plural } from '@lingui/macro';
import { Alert } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useCompanyFeatures } from 'app/domains/companies';
import { MetricCard } from './MetricCard';
import { MetricCardLoader } from './MetricCardLoader';
import { useApprovalDocumentsCount } from './useApprovalDocumentsCount';
export function ApprovalDocumentsWidget() {
    const title = t({
        message: 'Approvals',
        comment: 'Title for the Approved Documents widget',
    });
    const errorMessage = t({
        message: 'Failed to load approved documents',
        comment: 'Error message for the Approved Documents widget',
    });
    const { approvedDocumentsCount, loading: loadingApprovalCounts, error, } = useApprovalDocumentsCount();
    const { isFeatureAvailable, loading: loadingCompanyFeatures } = useCompanyFeatures();
    const isApprovalWorkflowAvailable = isFeatureAvailable('approval');
    const to = isApprovalWorkflowAvailable ? '/documents/awaiting-approvals' : undefined;
    const loading = loadingApprovalCounts || loadingCompanyFeatures;
    const suffix = t({
        message: plural(approvedDocumentsCount, {
            one: 'Document',
            other: 'Documents',
        }),
        comment: 'Suffix for the Approved Documents widget',
    });
    if (loading) {
        return <MetricCardLoader />;
    }
    if (error) {
        return <Alert message={errorMessage} appearance="danger"/>;
    }
    return (<MetricCard title={title} value={approvedDocumentsCount} suffix={suffix} icon={<Falcon icon="check-circle"/>} to={to} tooltip={t({
            message: 'Documents that require approval',
            comment: 'Tooltip for the Approved Documents widget',
        })}/>);
}
