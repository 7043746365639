import { breakpoints, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const DesktopOnly = styled.div `
  ${breakpoints.xs} {
    display: none;
  }
`;
export const MobileOnly = styled.div `
  ${breakpoints.sm} {
    display: none;
  }
`;
export const AvatarContainer = styled.div `
  padding: ${themeGet('spacing.xl')} ${themeGet('spacing.xl')} 0;
`;
