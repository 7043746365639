import { Fragment, memo, useEffect, useRef } from 'react';
import { deriveStyle } from './utils';
import { Text, Highlight } from './styled';
export const TextItemLayer = memo((props) => {
    const { str, highlight, scrollTo, textStyle } = props;
    const { fontFamily } = textStyle;
    const { top, left, width, height, fontSize } = deriveStyle(props);
    const highlightRef = useRef(null);
    useEffect(() => {
        if (scrollTo && highlightRef.current) {
            highlightRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
            });
        }
    }, [scrollTo]);
    return (<Fragment>
      {highlight ? <Highlight ref={highlightRef} style={{ top, left, width, height }}/> : null}
      <Text style={{ top, left, fontSize }} $fontFamily={fontFamily}>
        {str}
      </Text>
    </Fragment>);
});
