import { Fragment } from 'react';
import { SectionAccordionListDivider, SectionAccordionListItem } from '../../components';
export function AccordionPartyStatus(props) {
    const { timestamps, label, bigIcon } = props;
    if (!timestamps) {
        return null;
    }
    const { date, time } = timestamps;
    const dot = '\u2022';
    const space = '\u2006';
    const text = `${date} ${space} ${dot} ${space} ${time}`;
    return (<Fragment>
      <SectionAccordionListItem icon={bigIcon} label={label} text={text}/>
      <SectionAccordionListDivider />
    </Fragment>);
}
