import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  max-width: ${pxToRem(310)};
  margin: 0 auto ${themeGet('spacing.xl')};

  * {
    white-space: inherit;
  }
`;
