import { v4 as uuid } from 'uuid';
import { SearchAggregationType } from 'shared/domains/apollo/generated/types';
export function getDefaultTopHitsValue(value = 'metadataDocumentNoticePeriodDays') {
    return {
        type: SearchAggregationType.TopHits,
        name: uuid(),
        topHitsInput: {
            size: 10,
            offset: 0,
            orderBy: [{ [value]: 'desc' }],
        },
    };
}
