import { plural, t } from '@lingui/macro';
import { formatDuration } from 'date-fns';
import { DocumentMetadataPropertyTypeEnum } from 'shared/domains/apollo/generated/types';
import { localizedFormatDate } from 'shared/domains/common-utils';
import { metadataConfig } from 'shared/domains/metadata';
import { hasValidValue } from './MetadataField/utils';
function booleanValue(value) {
    const yes = t({ message: 'Yes', comment: 'Metadata boolean value: yes' });
    const no = t({ message: 'No', comment: 'Metadata boolean value: no' });
    if (value === true) {
        return yes;
    }
    if (value === false) {
        return no;
    }
    return undefined;
}
const getUnknownValue = () => t({
    message: 'No data',
    comment: 'Metadata: No data, unknown or unsupported document metadata value.',
});
const getListValue = (data, name) => {
    var _a, _b;
    const allValues = (_b = (_a = metadataConfig
        .get(name)) === null || _a === void 0 ? void 0 : _a.allowedValues) === null || _b === void 0 ? void 0 : _b.map(({ text, value, groupValues }) => [
        { text, value },
        ...(groupValues || []),
    ]).flat();
    const { text } = (allValues === null || allValues === void 0 ? void 0 : allValues.find(type => type.value === data)) || {};
    if (!text) {
        return getUnknownValue();
    }
    return text;
};
const DATE_FORMAT = Object.freeze({
    year: 'numeric',
    month: 'short',
    day: '2-digit',
});
const getNumberValue = (value) => {
    if (typeof value !== 'number') {
        return getUnknownValue();
    }
    return value.toString();
};
const getTextValue = (value) => {
    if (value === '' || typeof value !== 'string') {
        return getUnknownValue();
    }
    return value;
};
const getReference = (id) => {
    const idArray = Array.isArray(id) ? id : [id];
    const count = (idArray === null || idArray === void 0 ? void 0 : idArray.length) || 0;
    if (!id || !count) {
        return getUnknownValue();
    }
    return t({
        message: plural(count, {
            one: `${count} reference`,
            other: `${count} references`,
        }),
        comment: 'Suffix for the number of references added',
    });
};
function getPartiesDisplayValue(value) {
    if (!Array.isArray(value)) {
        return getUnknownValue();
    }
    return value.filter(str => typeof str === 'string').join(', ');
}
export function getMetadataDisplayValue(options) {
    const { value, property } = options || {};
    const { name, type } = property || {};
    const getValueByPropertyName = () => {
        switch (name) {
            case 'NoticePeriodDays': {
                return formatDuration({ days: value }, {
                    format: ['months', 'days'],
                    delimiter: ', ',
                });
            }
            default:
                return undefined;
        }
    };
    if (name === 'Parties') {
        return getPartiesDisplayValue(value);
    }
    const getValueByType = () => {
        switch (type) {
            case DocumentMetadataPropertyTypeEnum.Boolean:
                return booleanValue(value);
            case DocumentMetadataPropertyTypeEnum.Date:
                return localizedFormatDate(value, DATE_FORMAT);
            case DocumentMetadataPropertyTypeEnum.List:
                return getListValue(value, name);
            case DocumentMetadataPropertyTypeEnum.SimpleText:
            case DocumentMetadataPropertyTypeEnum.MultiLineText:
                return getTextValue(value);
            case DocumentMetadataPropertyTypeEnum.Number:
                return getNumberValue(value);
            case DocumentMetadataPropertyTypeEnum.Reference:
                return getReference(value);
            default:
                return undefined;
        }
    };
    const formattedValue = getValueByPropertyName() || getValueByType();
    return hasValidValue(formattedValue) ? formattedValue : getUnknownValue();
}
