var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useLazyQuery } from '@apollo/client';
import { QUERY } from './query';
export function useEntryLastSignRequestStatus() {
    const [getLastSignRequest] = useLazyQuery(QUERY);
    const handler = (id) => __awaiter(this, void 0, void 0, function* () {
        return getLastSignRequest({ variables: { id } }).then(query => { var _a, _b, _c; return (_c = (_b = (_a = query.data) === null || _a === void 0 ? void 0 : _a.document) === null || _b === void 0 ? void 0 : _b.lastSignRequest) === null || _c === void 0 ? void 0 : _c.status; });
    });
    return handler;
}
