const domain = process.env.EXTERNAL_AUTH_DOMAIN || '';
const redirectUri = window.location.origin;
const clientID = process.env.EXTERNAL_AUTH_CLIENT_ID || '';
const responseType = 'token id_token';
const audience = 'com.pocketlaw.app-back-end';
const scope = 'openid email profile';
const config = {
    domain,
    redirectUri,
    clientID,
    responseType,
    audience,
    scope,
};
export default config;
