import { useApprovers } from 'app/domains/approval';
import { getTitleFromType } from './utils';
import { CustomListItem } from '../../../CustomListItem';
import { StyledList, StyledListHeader } from './styled';
export function ApproversList() {
    const { approvers, type } = useApprovers();
    const title = getTitleFromType(type);
    return (<StyledList $density="large">
      <StyledListHeader>{title}</StyledListHeader>

      {approvers.map(approver => (<CustomListItem key={approver.userId} primary={approver.name} avatarLetter={approver.name[0].toUpperCase()}/>))}
    </StyledList>);
}
