import { Fragment, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { ConfirmDialog, IconButton, TableBodyCell, Avatar } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useRemoveInvite, useRole } from 'app/domains/users';
export function ActionsCell(props) {
    const { inviteId } = props;
    const [open, setOpen] = useState(false);
    const { removeInvite, loading } = useRemoveInvite();
    const role = useRole();
    if (!role.can('delete:invite')) {
        return null;
    }
    return (<Fragment>
      <TableBodyCell headers="actions" stickyColumn="right">
        <IconButton disabled={loading} appearance="ghost" size="small" onClick={() => setOpen(true)}>
          <Falcon icon="trash"/>
        </IconButton>
      </TableBodyCell>
      {open && (<ConfirmDialog media={<Avatar appearance="danger" shape="square" size="lg">
              <Falcon icon="triangle-exclamation"/>
            </Avatar>} heading={t({ message: 'Remove invite', comment: 'Remove invite confirm dialog title' })} appearance="danger" size="sm" onConfirm={() => removeInvite(inviteId)} confirm={t({ message: 'Remove', comment: 'Remove invite confirm dialog remove button' })} onCancel={() => setOpen(false)} cancel={t({ message: 'Cancel', comment: 'Remove invite confirm dialog cancel button' })} loading={loading} disabled={loading}>
          <Trans comment="Remove invite confirm dialog body text">
            Are you sure you want to remove invite?
          </Trans>
        </ConfirmDialog>)}
    </Fragment>);
}
