export const AuthenticateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'Authenticate' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'authenticated' },
                        directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'picture' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};
