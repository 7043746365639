import { t } from '@lingui/macro';
export const getTitleByEventType = (input) => {
    const { type, userName, approverUserNames, locale } = input;
    switch (type) {
        case 'approve':
            return t({
                comment: 'Approval drawer - History tab - Event when user has approved the request',
                message: `${userName} approved`,
            });
        case 'cancel':
            return t({
                comment: 'Approval drawer - History tab - Event when user has cancelled the request',
                message: `${userName} cancelled the request`,
            });
        case 'comment':
            return t({
                comment: 'Approval drawer - History tab - Event when user has left a comment on the request',
                message: `${userName} left a comment`,
            });
        case 'reject':
            return t({
                comment: 'Approval drawer - History tab - Event when user has rejected the request',
                message: `${userName} reject`,
            });
        case 'request':
            if (approverUserNames && approverUserNames.length > 0) {
                const listFormat = new Intl.ListFormat(locale);
                const jointApproverUserNames = listFormat.format(approverUserNames);
                return t({
                    comment: 'Approval drawer - History tab - Event when user has requested approval and we display the names of the approvers with the following format: "User 1, User 2 and User 3"',
                    message: `${userName} requested approval from ${jointApproverUserNames}`,
                });
            }
            return t({
                comment: 'Approval drawer - History tab - Event when user has requested approval',
                message: `${userName} requested approval`,
            });
        default:
            return undefined;
    }
};
