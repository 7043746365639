import { useMutation } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { addTeamMembersMutation } from './addTeamMembersMutation';
export function useAddTeamMembers() {
    const failedMessage = t({
        message: 'Something went wrong, try again',
        comment: 'team profile: add team members failed message',
    });
    const successMessage = t({
        message: 'Team members successfully added',
        comment: 'team profile: add team members success message',
    });
    const { addToast } = useToast();
    const [mutate] = useMutation(addTeamMembersMutation);
    const addTeamMembers = (teamId, userIds) => {
        const userIdObjects = userIds.map(userId => ({ userId }));
        const handleError = (error) => {
            addToast({
                title: failedMessage,
                appearance: 'danger',
            });
            throw error;
        };
        return mutate({
            variables: {
                teamId,
                userIds: userIdObjects,
            },
        })
            .then(({ errors }) => {
            if (errors) {
                throw errors[0];
            }
            addToast({
                title: successMessage,
                appearance: 'success',
            });
        })
            .catch(handleError);
    };
    return {
        addTeamMembers,
    };
}
