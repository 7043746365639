import { Chip, pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  position: relative;
  width: 100%;
  opacity: ${({ $disabled }) => $disabled && 0.5};
`;
export const StyledChip = styled(Chip) `
  pointer-events: none;
  position: absolute;
  top: 0;
  right: ${pxToRem(16)};
  transform: translateY(-50%);
`;
export const LogoContainer = styled.div `
  pointer-events: none;
  position: absolute;
  height: 100%;
  width: ${pxToRem(62)};
  display: flex;
  align-items: center;
  top: 0;
  right: ${themeGet('spacing.4xl')};
`;
