import { t } from '@lingui/macro';
export function getText(state) {
    const labels = {
        error: t({
            comment: 'Pleditor save indicator label: Error',
            message: 'Error',
        }),
        waiting: t({
            comment: 'Pleditor save indicator label: Unsaved changes',
            message: 'Unsaved changes',
        }),
        synchronized: t({
            comment: 'Pleditor save indicator label: Saved',
            message: 'Saved',
        }),
        saving: t({
            comment: 'Pleditor save indicator label: Saving',
            message: 'Saving',
        }),
        synchronizing: t({
            comment: 'Pleditor save indicator label: Synchronizing',
            message: 'Synchronizing',
        }),
        unauthorized: t({
            comment: 'Pleditor save indicator label: Not authorized',
            message: 'Your session has expired',
        }),
        unauthenticated: t({
            comment: 'Pleditor save indicator label: Not authenticated',
            message: 'Your session has expired',
        }),
        reconnecting: t({
            comment: 'Pleditor save indicator label: Reconnecting',
            message: 'Reconnecting',
        }),
        ready: t({
            comment: 'Pleditor save indicator label: Ready',
            message: 'Ready',
        }),
    };
    const tooltips = {
        error: t({
            comment: 'Pleditor save indicator tooltip: Error',
            message: 'An error occurred',
        }),
        waiting: t({
            comment: 'Pleditor save indicator tooltip: Unsaved changes',
            message: 'Unsaved changes',
        }),
        synchronized: t({
            comment: 'Pleditor save indicator tooltip: Synchronized',
            message: 'Document is saved',
        }),
        saving: t({
            comment: 'Pleditor save indicator tooltip: Saving',
            message: 'Saving document',
        }),
        synchronizing: t({
            comment: 'Pleditor save indicator label: Synchronizing',
            message: 'Synchronizing',
        }),
        unauthorized: t({
            comment: 'Pleditor save indicator label: Not authorized',
            message: 'Your session has expired',
        }),
        unauthenticated: t({
            comment: 'Pleditor save indicator label: Not authenticated',
            message: 'Your session has expired',
        }),
        reconnecting: t({
            comment: 'Pleditor save indicator label: Reconnecting',
            message: 'Reconnecting',
        }),
        ready: t({
            comment: 'Pleditor save indicator label: Ready',
            message: 'Ready',
        }),
    };
    const label = state ? labels[state] : labels.synchronized;
    const tooltip = state ? tooltips[state] : tooltips.synchronized;
    return {
        label,
        tooltip,
    };
}
