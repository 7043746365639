// eslint-disable-next-line max-classes-per-file
import apiClient from 'app/domains/apollo';
export class AppApiConnector {
    constructor() {
        this.query = apiClient.query;
        this.mutate = apiClient.mutate;
        this.cache = apiClient.cache;
    }
    static get pluginName() {
        return 'ApiConnector';
    }
    // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-empty-function
    destroy() { }
}
