var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useRole } from 'app/domains/users/hooks/useRole';
import { StyledChip } from './styled';
export function RoleBadge(props) {
    const { id } = props, restProps = __rest(props, ["id"]);
    const userRole = useRole(id);
    const role = userRole.is();
    const roleName = userRole.name();
    const badgeColors = {
        owner: 'primary',
        admin: 'info',
        member: 'secondary',
        disabled: 'secondary',
    };
    const badgeColor = badgeColors[role || 'disabled'];
    return <StyledChip variant="outline" appearance={badgeColor} {...restProps} label={roleName}/>;
}
