import { Fragment, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Button, EmptyState } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { ESignSignatoryFormDialog } from 'app/domains/esigning/components/ESignSignatoryFormDialog';
import { useDefaultSignatories } from '../useDefaultSignatories';
export function EmptySignatoriesState() {
    const { signatories, addSignatory } = useDefaultSignatories();
    const [renderDialog, setRenderDialog] = useState(false);
    const showDialog = () => setRenderDialog(true);
    const hideDialog = () => setRenderDialog(false);
    return (<Fragment>
      {renderDialog && (<ESignSignatoryFormDialog type="DefaultSignatory" allSignatories={signatories} onClose={hideDialog} onSubmit={addSignatory}/>)}
      <EmptyState withAvatar icon={<Falcon icon="paper-plane"/>} title={t({
            comment: 'Manage default signing parties drawer - No signing parties added - title',
            message: 'Add a default signing party',
        })} description={t({
            comment: 'Manage default signing parties drawer - No signing parties added - description',
            message: 'All your default signing parties will be listed here',
        })} actions={<Button $stretch appearance="secondary" size="medium" onClick={showDialog}>
            <Trans comment="Manage default signing parties drawer - No signing parties added - add default signing party call to action label">
              Add default signing party
            </Trans>
          </Button>}/>
    </Fragment>);
}
