export var ActionType;
(function (ActionType) {
    ActionType["CREATE"] = "CREATE";
    ActionType["DUPLICATE"] = "DUPLICATE";
    ActionType["RENAME"] = "RENAME";
    ActionType["DELETE"] = "DELETE";
    ActionType["MOVE"] = "MOVE";
    ActionType["QUICK_MOVE"] = "QUICK_MOVE";
    ActionType["RESET"] = "RESET";
})(ActionType || (ActionType = {}));
