var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Dialog } from '@pocketlaw/tetris';
import { Formik } from 'formik';
import { Fieldset } from '../Fieldset';
import { StyledForm } from './styled';
function FormDialog(props) {
    const { children, initialValues, onClose, size, 'aria-labelledby': ariaLabelledBy, 'aria-describedby': ariaDescribedBy } = props, rest = __rest(props, ["children", "initialValues", "onClose", "size", 'aria-labelledby', 'aria-describedby']);
    return (<Formik initialValues={initialValues} {...rest}>
      <Dialog size={size} onClose={onClose} aria-labelledby={ariaLabelledBy} aria-describedby={ariaDescribedBy}>
        <StyledForm>
          <Fieldset>{children}</Fieldset>
        </StyledForm>
      </Dialog>
    </Formik>);
}
export { FormDialog };
