import { t } from '@lingui/macro';
import { Avatar, DialogHeader } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
export function Header() {
    return (<DialogHeader title={t({
            comment: 'Dashboard: send for e-signing modal - title',
            message: 'Send for e-signing',
        })} media={<Avatar size="xl" shape="square">
          <Falcon icon="pen-swirl"/>
        </Avatar>}/>);
}
