import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Content = styled.div `
  display: flex;
  height: 100%;
`;
export const LeftColumn = styled.div `
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 100%;
  width: ${pxToRem(300)};
  padding: ${themeGet('spacing.xl')};
  border-right: solid ${pxToRem(1)} ${themeGet('colors.border.100')};
`;
export const Header = styled.div `
  display: flex;
  align-items: center;
  gap: ${themeGet('spacing.md')};
  margin-bottom: ${themeGet('spacing.xl')};
`;
