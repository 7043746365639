import { Fragment } from 'react';
import { t } from '@lingui/macro';
import { TextInput } from '@pocketlaw/tetris';
import { useField } from 'formik';
import { ErrorMessage } from 'shared/domains/forms/components/ErrorMessage';
function EmailInput() {
    const [field, meta] = useField('email');
    const error = Boolean(meta.touched && meta.error);
    return (<Fragment>
      <TextInput {...field} invalid={error} stretch label={t({ message: 'Email', comment: 'Share document dialog email input label' })} description={t({
            message: 'Share with people outside your account',
            comment: 'Share document dialog email input description',
        })}/>
      <ErrorMessage name={field.name}/>
    </Fragment>);
}
export default EmailInput;
