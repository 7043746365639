import { Typography } from '@pocketlaw/tetris';
import { Container } from './styled';
export function Prompt(props) {
    const { icon, title, subtitle } = props;
    if (!title && !subtitle && !icon) {
        return null;
    }
    return (<Container data-testid="assistant-bubble-prompt">
      {icon}
      {title && (<Typography $appearance="200" $fontSize="small" $fontWeight="medium">
          {title}
        </Typography>)}
      {subtitle && (<Typography $appearance="100" $fontSize="small" data-testid="assistant-bubble-prompt-context">
          {subtitle}
        </Typography>)}
    </Container>);
}
