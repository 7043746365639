import { Fragment } from 'react';
import { Calendar, MenuBody } from '@pocketlaw/tetris';
import { format, isValid } from 'date-fns';
import { useFormikContext } from 'formik';
import { localeConfig } from 'shared/domains/languages/config';
import { FormSubmitButton } from './FormSubmitButton';
export function DateField() {
    const { setFieldValue, values } = useFormikContext();
    const localeCode = localeConfig.getActiveLocale().code;
    const { value } = values;
    const safeDate = value && isValid(new Date(value)) ? new Date(value) : undefined;
    const handleOnChange = (date) => {
        const formattedDate = format(date, 'yyyy-MM-dd');
        setFieldValue('value', formattedDate);
    };
    return (<Fragment>
      <MenuBody>
        <Calendar locale={localeCode} value={safeDate} onChange={handleOnChange}/>
      </MenuBody>
      <FormSubmitButton />
    </Fragment>);
}
