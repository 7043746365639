import { pxToRem } from '@pocketlaw/tetris';
import { css } from 'styled-components';
export const commentsArchiveStyles = css `
  .ck {
    .ck-thread__header {
      align-items: center;

      .ck-context-wrapper {
        height: ${pxToRem(34)};
      }

      .ck-context--quotes {
        align-items: center;
      }
    }
  }
`;
