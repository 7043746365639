import { Typography } from '@pocketlaw/tetris';
import { List, Item, Square } from './styled';
export function Legend(props) {
    const { data, onSelect, onClick } = props;
    const handleOnClick = (id) => {
        if (!onClick) {
            return undefined;
        }
        return onClick(id);
    };
    return (<List>
      {data.map(item => (<Item key={item.id} onMouseEnter={() => onSelect(item)} onMouseLeave={() => onSelect()} onClick={() => handleOnClick(item.id)}>
          <Typography>
            <Square $fill={item.fill}/>
            {item.name}
          </Typography>
        </Item>))}
    </List>);
}
