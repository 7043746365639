import { breakpoints, interactiveTransition, pxToRem, themeGet } from '@pocketlaw/tetris';
import styled, { css } from 'styled-components';
export const buttonBase = css `
  appearance: none;
  text-decoration: none;
  box-sizing: border-box;

  font-style: normal;
  font-weight: ${themeGet('fontWeight.medium')};
  font-size: ${themeGet('fontSize.medium')};
  line-height: ${themeGet('lineHeight.default')};
  text-align: left;

  border-style: solid;
  border-width: 0;

  display: flex;
  align-items: baseline;
  column-gap: ${themeGet('spacing.md')};

  &:not(:disabled) {
    cursor: pointer;
  }

  &:focus-visible {
    outline: none;
  }

  ${interactiveTransition};
`;
const MARGIN = 'spacing.5xl';
const alignLeftStyles = css `
  margin-right: ${themeGet(MARGIN)};
  border-top-left-radius: 0;
`;
const alignRightStyles = css `
  margin-left: ${themeGet(MARGIN)};
  border-top-right-radius: 0;
`;
export const Button = styled.button `
  ${buttonBase};

  padding: ${themeGet('spacing.lg')} ${themeGet('spacing.xl')} ${themeGet('spacing.lg')}
    ${themeGet('spacing.2xl')};
  color: ${themeGet('colors.assistant.800')};
  background-color: ${themeGet('colors.assistant.50')};
  border-color: ${themeGet('colors.focus.assistant')};
  border-radius: ${themeGet('spacing.md')};

  ${({ $align }) => ($align === 'left' ? alignLeftStyles : alignRightStyles)}

  ${breakpoints.xs} {
    margin-right: 0;
  }

  [data-icon='falcon'] {
    position: relative;
    top: ${pxToRem(3)};
    color: ${themeGet('colors.assistant.500')};
  }

  &:disabled {
    opacity: 0.5;
    color: ${themeGet('colors.text.200')};
    background-color: ${themeGet('colors.background.100')};

    [data-icon='falcon'] {
      color: ${themeGet('colors.gray.600')};
    }
  }

  &:not(:disabled) {
    &:hover {
      background-color: ${themeGet('colors.assistant.100')};
    }

    &:active {
      background-color: ${themeGet('colors.assistant.200')};
    }

    &:focus-visible {
      background-color: ${themeGet('colors.assistant.50')};
      box-shadow: 0 0 0 ${pxToRem(4)} ${themeGet('colors.focus.assistant')};
    }
  }
`;
export const RightIconContainer = styled.div `
  margin-left: auto;
  margin-right: 0;
`;
