import { useMutation } from '@apollo/client';
import { DeleteDocumentChatDocument } from './DeleteDocumentChat.gql';
export function useDeleteDocumentChat() {
    const [mutate, state] = useMutation(DeleteDocumentChatDocument);
    const handler = (chatId) => mutate({
        variables: {
            chatId: chatId || '',
        },
        update(cache, { data }) {
            const id = data === null || data === void 0 ? void 0 : data.deleteDocumentChat.id;
            // eslint-disable-next-line no-underscore-dangle
            const typename = data === null || data === void 0 ? void 0 : data.deleteDocumentChat.__typename;
            if (id) {
                cache.evict({ id: `${typename}:${id}` });
                cache.gc();
            }
        },
        onQueryUpdated(observableQuery) {
            return observableQuery.refetch();
        },
    });
    return [handler, state];
}
