import { useLingui } from '@lingui/react';
import { Avatar } from '@pocketlaw/tetris';
import { getSearchEntityAppearance } from 'app/domains/global-search/utils/getSearchEntityAppearance';
import { getSearchEntityIcon } from 'app/domains/global-search/utils/getSearchEntityIcon';
import { getSearchEntityLink } from 'app/domains/global-search/utils/getSearchEntityLink';
import { getSearchEntityTypeText } from 'app/domains/global-search/utils/getSearchEntityTypeText';
import { Match } from './Match';
import { SearchResultActionMenu } from '../SearchResultActionMenu';
import { ItemContainer, TextContainer, DetailsContainer, StyledLink, StyledText } from './styled';
const DATE_FORMAT = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
};
export function SearchResultsListItem(props) {
    const { result, onClick } = props;
    const { entityId, name, createdAt, entityType, meta, createdByUser } = result;
    const { i18n } = useLingui();
    const icon = getSearchEntityIcon(entityType);
    const appearance = getSearchEntityAppearance(entityType);
    return (<ItemContainer>
      <StyledLink to={getSearchEntityLink(entityType, entityId)} onClick={onClick}>
        <Avatar size="md" appearance={appearance}>
          {icon}
        </Avatar>
        <TextContainer>
          <Match highlight={meta.highlight} name={name}/>
          <DetailsContainer>
            <StyledText appearance="neutral" size="tiny">
              {getSearchEntityTypeText(entityType)}
            </StyledText>
            {createdAt && (<StyledText appearance="neutral" size="tiny">
                {i18n.date(createdAt, DATE_FORMAT)}
              </StyledText>)}
            {'createdByUser' in result && (createdByUser === null || createdByUser === void 0 ? void 0 : createdByUser.name) && (<StyledText appearance="neutral" size="tiny">
                {createdByUser.name}
              </StyledText>)}
          </DetailsContainer>
        </TextContainer>
      </StyledLink>

      <SearchResultActionMenu cacheId={result.id} entityId={result.entityId} entityType={result.entityType} 
    // eslint-disable-next-line no-underscore-dangle
    typename={result.__typename}/>
    </ItemContainer>);
}
