var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isDocx, isPdf, isDocxDocument, isPdfDocument } from 'shared/domains/documents';
import { useDocxToPdf } from './useDocxToPdf';
import { useHtmlToPdf } from './useHtmlToPdf';
export const useGetPdf = () => {
    const { docxToPdf } = useDocxToPdf();
    const { htmlToPdf } = useHtmlToPdf();
    const getPdf = (options) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const { document, companyId } = options;
        const pdfDocument = isPdfDocument(document);
        const docxDocument = isDocxDocument(document);
        if (docxDocument) {
            const docxFile = document.files.find(isDocx);
            if (docxFile) {
                try {
                    return docxToPdf(docxFile);
                }
                catch (_b) {
                    throw Error('No docx file attached to docx view');
                }
            }
        }
        if (pdfDocument) {
            const pdfFile = document.files.find(isPdf);
            if (pdfFile) {
                try {
                    return pdfFile;
                }
                catch (_c) {
                    throw Error('No pdf file attached to pdf view');
                }
            }
        }
        return htmlToPdf({
            companyId,
            documentId: document.id,
            name: (_a = document.files[0]) === null || _a === void 0 ? void 0 : _a.name,
        });
    });
    return { getPdf };
};
