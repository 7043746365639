import { Trans } from '@lingui/macro';
import { Button } from '@pocketlaw/tetris';
import { useWizardContext } from '../../../WizardContext';
export function BackButton() {
    const { step, handlePrevStep } = useWizardContext();
    if (step < 1) {
        return null;
    }
    return (<Button type="button" onClick={handlePrevStep} appearance="secondary">
      <Trans comment="user invite modal - back button title">Back</Trans>
    </Button>);
}
