import { t } from '@lingui/macro';
import { Box, AvatarWithLabel } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
export function NoHighlights() {
    return (<Box alignItems="center" gap="xl">
      <AvatarWithLabel size="sm" appearance="ai" title={t({ comment: 'No document highlights label', message: 'No highlights available' })}>
        <Falcon icon="highlighter"/>
      </AvatarWithLabel>
    </Box>);
}
