import { gql } from '@apollo/client';
export const signRequestQuery = gql `
  query lastSignRequest($documentId: ID!) {
    fileSystemDocument(id: $documentId) {
      id
      lastSignRequest {
        id
        status
        message
        statusSubcode: status_subcode
        createdAt: created_at
        signatories {
          id
          title
          label
          name
          firstName
          lastName
          email
          status
          updatedAt
          authenticationMode
          order
          mobile
          message
          isDefault
        }
        ccRecipients: cc_recipients {
          label
          name
          firstName
          lastName
          email
          message
          isDefault
        }
      }
    }
  }
`;
