var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Children } from 'react';
import { useFocusManager } from 'app/domains/templates';
import { useSection } from '../Section';
import { Container } from './styled';
export function Conditional(props) {
    const { id, children } = props, restProps = __rest(props, ["id", "children"]);
    const { focusedFieldId, focusRef } = useFocusManager();
    const ref = id === focusedFieldId ? focusRef : undefined;
    const section = useSection();
    if (Children.count(children) === 0) {
        return section ? null : <Container ref={ref} {...restProps} data-pre="true"/>;
    }
    return (<Container ref={ref} {...restProps} $answered data-pre="false">
      {children}
    </Container>);
}
