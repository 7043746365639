import { useApproval } from 'app/domains/approval';
import { ApproversList } from './ApproversList';
import { PendingApprovalActions } from './PendingApprovalActions';
import { RequestApprovalButton } from './RequestApprovalButton';
import { Container } from './styled';
export function Approvers() {
    const { approvalPending } = useApproval();
    return (<Container>
      <ApproversList />
      {approvalPending ? <PendingApprovalActions /> : <RequestApprovalButton />}
    </Container>);
}
