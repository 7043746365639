import { t, Trans } from '@lingui/macro';
import { EmptyState, Button } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useComposerData } from '../../ComposerDataProvider';
import { Content } from '../styled';
import { EmptyStateContainer } from './styled';
export function ErrorState() {
    const { closeDialog } = useComposerData();
    return (<Content>
      <EmptyStateContainer>
        <EmptyState icon={<Falcon icon="circle-exclamation"/>} title={t({
            comment: 'Select template dialog - Error title',
            message: 'Could not load any templates',
        })} description={t({
            comment: 'Select template dialog - Error description',
            message: 'Something went wrong while trying to load the templates',
        })} actions={<Button appearance="secondary" onClick={closeDialog}>
              <Trans comment="Select template dialog - Close button label">Close dialog</Trans>
            </Button>}/>
      </EmptyStateContainer>
    </Content>);
}
