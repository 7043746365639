import { useMutation } from '@apollo/client';
import { ReviewFeedbackStatus } from 'shared/domains/apollo/generated/types';
import { ACCEPT_FEEDBACK_MUTATION } from './mutation';
export function useAcceptFeedback() {
    const [mutation] = useMutation(ACCEPT_FEEDBACK_MUTATION);
    const acceptFeedback = (feedbackId) => mutation({
        variables: { feedbackId },
        optimisticResponse: {
            acceptReviewFeedback: {
                __typename: 'ReviewFeedback',
                id: feedbackId,
                status: ReviewFeedbackStatus.Accepted,
            },
        },
    });
    return { acceptFeedback };
}
