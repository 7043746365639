import { useMobile } from 'shared/domains/common-ui';
import { Categories } from './Categories';
import { Languages } from './Languages';
import { Search } from './Search';
import { Templates } from './Templates';
import { Container } from './styled';
export function Filters() {
    const isMobile = useMobile();
    return (<Container>
      <Search />
      <Templates />
      {!isMobile && <Categories />}
      {!isMobile && <Languages />}
    </Container>);
}
