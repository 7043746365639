import { Trans } from '@lingui/macro';
import { AvatarLetter, AvatarWithLabel, Button } from '@pocketlaw/tetris';
import { Item } from './styled';
export function TemplateDrawerItem(props) {
    const { id, name, onRemove } = props;
    const handleClick = () => {
        onRemove(id);
    };
    return (<Item>
      <AvatarWithLabel title={name} size="sm">
        <AvatarLetter>{name[0]}</AvatarLetter>
      </AvatarWithLabel>
      <Button size="small" appearance="ghost" onClick={handleClick}>
        <Trans comment="Label for removing a selected user in the template drawer">Remove</Trans>
      </Button>
    </Item>);
}
