import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const GridContent = styled.div `
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(${pxToRem(225)}, 1fr));
  gap: ${themeGet('spacing.2xl')};
  padding: ${themeGet('spacing.xl')} ${themeGet('spacing.2xl')} ${themeGet('spacing.2xl')};
`;
export const EmptyContent = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
export const contentVisibilityStyle = {
    display: 'block',
    width: '100%',
    height: '100%',
};
