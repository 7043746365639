import { useEffect, useState } from 'react';
import { isDraggingFiles } from './utils';
export function useFileDragDetector() {
    const [isDragging, setIsDragging] = useState(false);
    useEffect(() => {
        const handleDragEnter = (event) => {
            if (isDraggingFiles(event)) {
                setIsDragging(true);
            }
        };
        const handleDragLeave = (event) => {
            if (isDraggingFiles(event)) {
                if (!event.relatedTarget) {
                    setIsDragging(false);
                }
            }
        };
        const handleDrop = (event) => {
            if (isDraggingFiles(event)) {
                setIsDragging(false);
            }
        };
        document.addEventListener('dragenter', handleDragEnter);
        document.addEventListener('dragleave', handleDragLeave);
        document.addEventListener('drop', handleDrop);
        return () => {
            document.removeEventListener('dragenter', handleDragEnter);
            document.removeEventListener('dragleave', handleDragLeave);
            document.removeEventListener('drop', handleDrop);
        };
        return undefined;
    }, []);
    return isDragging;
}
