import { t, Trans } from '@lingui/macro';
import { ConfirmDialog, Avatar } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
export function ConfirmRemoveEsigningDialog(props) {
    const { onCancel, onSubmit } = props;
    return (<ConfirmDialog appearance="danger" size="sm" heading={t `Cancel e-signing`} media={<Avatar appearance="danger" shape="square" size="lg">
          <Falcon icon="triangle-exclamation"/>
        </Avatar>} confirm={t `Confirm`} cancel={t `Cancel`} onConfirm={onSubmit} onCancel={onCancel}>
      <Trans>The signing parties will no longer be able to sign the document.</Trans>
    </ConfirmDialog>);
}
