import { keyExtractor } from 'shared/domains/common-utils';
import { buildDateRangeFilter } from '../../utils/buildDateRangeFilter';
import { buildStatusFilter } from '../../utils/buildStatusFilter';
import { buildUsersFilter } from '../../utils/buildUsersFilter';
const orderMap = new Map([
    ['asc', 'asc_nulls_last'],
    ['desc', 'desc_nulls_last'],
]);
export function buildTaskQueryFilters(inputs) {
    const { documentId, sortBy, filter = {} } = inputs;
    const taskQueryFilter = {};
    if (documentId) {
        taskQueryFilter.whereArgs = {
            task_documents: {
                document_id: { _eq: documentId },
            },
        };
    }
    const keys = keyExtractor(filter);
    keys.forEach(key => {
        switch (key) {
            case 'dueDates': {
                const dueDateRangeFilter = buildDateRangeFilter({
                    dateRange: filter[key],
                    key: 'due_date',
                });
                taskQueryFilter.whereArgs = Object.assign(Object.assign({}, taskQueryFilter.whereArgs), dueDateRangeFilter);
                break;
            }
            case 'createdAtDates': {
                const createdAtRangeFilter = buildDateRangeFilter({
                    dateRange: filter[key],
                    key: 'created_at',
                });
                taskQueryFilter.whereArgs = Object.assign(Object.assign({}, taskQueryFilter.whereArgs), createdAtRangeFilter);
                break;
            }
            case 'creators': {
                const creatorIdFilter = buildUsersFilter({ items: filter[key], key: 'creator_id' });
                taskQueryFilter.whereArgs = Object.assign(Object.assign({}, taskQueryFilter.whereArgs), creatorIdFilter);
                break;
            }
            case 'assignees': {
                const assigneesIdFilter = buildUsersFilter({ items: filter[key], key: 'id' });
                if (assigneesIdFilter) {
                    const assignees = { user: assigneesIdFilter };
                    taskQueryFilter.whereArgs = Object.assign(Object.assign({}, taskQueryFilter.whereArgs), { assignees });
                }
                break;
            }
            case 'status': {
                const statusFilter = buildStatusFilter({ status: filter[key], key: 'status' });
                taskQueryFilter.whereArgs = Object.assign(Object.assign({}, taskQueryFilter.whereArgs), statusFilter);
                break;
            }
            default:
                delete taskQueryFilter.whereArgs;
                break;
        }
    });
    taskQueryFilter.orderByArgs = { [sortBy.sortId]: orderMap.get(sortBy.sortOrder) };
    return taskQueryFilter;
}
