var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import ExpiryMap from 'expiry-map';
import pMemoize from 'p-memoize';
import { PProgress } from 'p-progress';
import pRetry from 'p-retry';
import * as Sentry from 'shared/domains/sentry';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { getMimeType } from './getMimeType';
import { getSignedUrlMutation } from './mutation';
import { uploadFile } from './uploadFile';
// eslint-disable-next-line @typescript-eslint/no-empty-function
function noop() { }
const ONE_MINUTE_IN_MILLISECONDS = 60 * 1000;
export function useS3FileUpload() {
    var _a;
    const account = useAccount();
    // Uploaded files are stored in `{bucket}/{company-id}/uploaded`.
    const prefix = [(_a = account.data) === null || _a === void 0 ? void 0 : _a.companyId, 'uploaded'].filter(Boolean).join('/');
    const [getSignedUrl] = useMutation(getSignedUrlMutation);
    function upload(files, handleProgress) {
        return __awaiter(this, void 0, void 0, function* () {
            // A signed URL will expire after a set amount of time (the current value in file-service is 5 minutes).
            // Preferrably the "max age" value should have been read from signed URL response, but let's settle for
            // a good enough value that is way below the actual expiration value.
            const cache = new ExpiryMap(2 * ONE_MINUTE_IN_MILLISECONDS);
            // A memoized function for fetching signed URLs per MIME type. It is possible to upload multiple files
            // (with the same MIME type) for a signed URL.
            const memoizedFetchSignedUrl = pMemoize((mimeType) => __awaiter(this, void 0, void 0, function* () {
                return getSignedUrl({
                    variables: {
                        path: prefix,
                        mimeType,
                    },
                });
            }), { cache });
            const uploads = files.map(file => () => __awaiter(this, void 0, void 0, function* () {
                var _a;
                try {
                    const mimeType = (_a = getMimeType(file)) !== null && _a !== void 0 ? _a : null;
                    if (mimeType === null) {
                        throw new Error('missing MIME type');
                    }
                    const result = yield pRetry(() => __awaiter(this, void 0, void 0, function* () {
                        var _b, _c;
                        const getSignedUrlResult = yield memoizedFetchSignedUrl(mimeType);
                        const signed = (_c = (_b = getSignedUrlResult === null || getSignedUrlResult === void 0 ? void 0 : getSignedUrlResult.data) === null || _b === void 0 ? void 0 : _b.signed) !== null && _c !== void 0 ? _c : null;
                        if (signed === null) {
                            throw new Error('failed to request signed URL');
                        }
                        const uploadedFile = yield uploadFile(file, prefix, signed.fields, signed.url, mimeType);
                        const uploadResultSuccess = {
                            originalFile: file,
                            uploadedFile,
                        };
                        return uploadResultSuccess;
                    }), { retries: 3 });
                    return result;
                }
                catch (e) {
                    Sentry.captureException(e);
                    throw e;
                }
            }));
            const multiFileUpload = PProgress.all(uploads, { concurrency: 5 });
            multiFileUpload.onProgress(handleProgress !== null && handleProgress !== void 0 ? handleProgress : noop);
            const settledResults = yield multiFileUpload;
            return settledResults;
        });
    }
    return { upload };
}
