import { fieldDisplayValue } from '../fieldDisplayValue';
import { getSelectedField } from '../getSelectedField';
export function getDateRangeBucketFilterPayload(bucket, aggregation) {
    const { from, to } = bucket;
    const primaryField = getSelectedField(aggregation);
    const formattedFrom = fieldDisplayValue(primaryField, from);
    const formattedTo = fieldDisplayValue(primaryField, to);
    return {
        id: `${formattedFrom} - ${formattedTo}`,
        field: primaryField,
        filter: { [primaryField]: { gte: from, lte: to } },
    };
}
