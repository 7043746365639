import { pxToRem } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const FolderInput = styled.input.withConfig({
    shouldForwardProp: () => true,
}) `
  width: ${pxToRem(1)};
  height: ${pxToRem(1)};
  appearance: none;
  opacity: 0;
`;
