import { forwardRef } from 'react';
import { Trans, t } from '@lingui/macro';
import { Column, Alert, Button } from '@pocketlaw/tetris';
import { Link } from 'react-router-dom';
import { useRole } from 'app/domains/users/hooks/useRole';
const UpgradeAlert = forwardRef((props, forwardedRef) => {
    const role = useRole();
    const isOwner = role.is() === 'owner';
    if (isOwner) {
        return (<Column width="100%">
        <Alert appearance="warning" title={t({ message: 'Upgrade account', comment: '' })} message={t({
                message: 'The contract template is not included in your current subscription.',
                comment: '',
            })}>
          <Button as={Link} to="/plans" ref={forwardedRef} appearance="secondary">
            <Trans comment="Upgrade alert button text">Upgrade</Trans>
          </Button>
        </Alert>
      </Column>);
    }
    return (<Column width="100%">
      <Alert appearance="warning" title={t({
            message: 'Missing access',
            comment: 'Alert title - duplicate document when lacking permission to composer',
        })} message={t({
            message: 'You do not have access to this contract template, contact your company owner.',
            comment: 'Alert body - duplicate document when lacking permission to composer',
        })}/>
    </Column>);
});
export default UpgradeAlert;
