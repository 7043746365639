import gql from 'graphql-tag';
export const MUTATION = gql `
  mutation renderPlastToDocxForEsigningAttachment(
    $companyId: UUID!
    $content: JSON!
    $name: String!
    $logoUrl: String
  ) {
    files: plastToDocx(input: { companyId: $companyId, content: $content, logoUrl: $logoUrl }) {
      id
      path
      mimeType
      size
      signed(name: $name) {
        url
      }
    }
  }
`;
