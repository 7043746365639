import { t } from '@lingui/macro';
import { IconButton, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { usePreview } from '../../../../../hooks/usePreview';
import { Container, TextContainer } from './styled';
export function Zoom() {
    const { zoomTo, zoom } = usePreview();
    const zoomOut = () => zoomTo(-1);
    const zoomIn = () => zoomTo(+1);
    const buttonProps = {
        $round: true,
        size: 'small',
        appearance: 'ghost',
    };
    const zoomText = `${Math.round(zoom * 100)}%`;
    return (<Container>
      <IconButton aria-label={t({ message: 'Zoom out', comment: 'Aria label for zoom out button' })} onClick={zoomOut} {...buttonProps}>
        <Falcon icon="minus"/>
      </IconButton>
      <TextContainer>
        <Typography aria-label={t({ message: 'Zoom level', comment: 'Aria label for zoom level text' })}>
          {zoomText}
        </Typography>
      </TextContainer>
      <IconButton aria-label={t({ message: 'Zoom in', comment: 'Aria label for zoom in button' })} onClick={zoomIn} {...buttonProps}>
        <Falcon icon="plus"/>
      </IconButton>
    </Container>);
}
