import { gql } from '@apollo/client';
export const CompanyDetailsFragment = gql `
  fragment CompanyDetailsFragment on Company {
    id
    name
    organizationNumber: organization_number
    addressStreet: address_street
    addressZip: address_zip
    addressCity: address_city
    addressCountry: address_country
  }
`;
