import { useMutation } from '@apollo/client';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { updateUserAccessMutation } from './mutation';
export function useAddUserAccess() {
    var _a;
    const account = useAccount();
    const companyId = (_a = account.data) === null || _a === void 0 ? void 0 : _a.companyId;
    const [mutate, { error, loading }] = useMutation(updateUserAccessMutation);
    const handleMutation = (entryId, options) => {
        const { users, actions } = options;
        return mutate({
            variables: {
                input: users.map(userId => ({
                    actions,
                    companyId,
                    entryId,
                    userId,
                })),
            },
            update(cache, { data }) {
                const id = cache.identify({
                    __typename: 'FileSystemDocument',
                    id: entryId,
                });
                cache.modify({
                    id,
                    fields: {
                        acls(existing, { toReference }) {
                            var _a;
                            const added = (_a = data === null || data === void 0 ? void 0 : data.createdACL) === null || _a === void 0 ? void 0 : _a.map(createdAcl => toReference(createdAcl, true));
                            return existing.concat(added);
                        },
                    },
                });
            },
        });
    };
    return {
        mutate: handleMutation,
        error,
        loading,
    };
}
