import { v4 as uuid } from 'uuid';
import { getPreviewFile } from './getPreviewFile';
import { isFolder } from './guards';
const findFolder = (parentFolder, targetFolderPath) => {
    if (!parentFolder) {
        return undefined;
    }
    if (parentFolder.path === targetFolderPath) {
        return parentFolder;
    }
    const folders = parentFolder.children.filter(isFolder);
    let match = folders.find(folder => folder.path === targetFolderPath);
    if (match) {
        return match;
    }
    folders.forEach(folder => {
        if (!match) {
            match = findFolder(folder, targetFolderPath);
        }
    });
    return match;
};
const sortFilesByName = (fileA, fileB) => {
    if (fileA.path > fileB.path) {
        return 1;
    }
    if (fileA.path < fileB.path) {
        return -1;
    }
    return 0;
};
export const getFolderMap = (files) => {
    let rootFolder = null;
    const folderMap = {};
    const getFolder = (path, name) => Object.values(folderMap).find(folder => (folder === null || folder === void 0 ? void 0 : folder.path) === path && (folder === null || folder === void 0 ? void 0 : folder.name) === name);
    const createFolder = (id, path, name) => {
        const folder = {
            id,
            path,
            name,
            previewType: 'folder',
            children: [],
        };
        folderMap[id] = folder;
        return folder;
    };
    const extractFolders = (folderNames) => {
        folderNames.forEach((folderName, index) => {
            const folderPath = [...folderNames].slice(0, index + 1).join('/');
            const parentFolderPath = index > 0 ? [...folderNames].slice(0, index).join('/') : null;
            const folderId = uuid();
            const folder = getFolder(folderPath, folderName) || createFolder(folderId, folderPath, folderName);
            if (!findFolder(rootFolder, folderPath)) {
                if (parentFolderPath && folderPath !== parentFolderPath) {
                    const parentFolder = findFolder(rootFolder, parentFolderPath);
                    if (parentFolder) {
                        folder.parentId = parentFolder.id;
                        parentFolder.children.push(folder);
                    }
                }
                else {
                    rootFolder = folder;
                }
            }
        });
    };
    const extractFile = (file, folderNames) => {
        const filePath = [...folderNames].join('/');
        const fileParentFolder = findFolder(rootFolder, filePath);
        if (fileParentFolder) {
            const previewFile = getPreviewFile(file, fileParentFolder.id);
            fileParentFolder.children.push(previewFile);
        }
    };
    files.sort(sortFilesByName).forEach(file => {
        const folderNames = file.path.split('/').slice(0, -1);
        extractFolders(folderNames);
        extractFile(file, folderNames);
    });
    return folderMap;
};
