// Reference: https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-arrays-of-non-normalized-objects
export function mergeArrayByField(fieldName) {
    return (existing, incoming, options) => {
        const { readField, mergeObjects } = options;
        const merged = existing ? existing.slice(0) : [];
        const fieldNameToIndex = {};
        if (existing) {
            existing.forEach((reference, index) => {
                const key = readField(fieldName, reference);
                if (key) {
                    fieldNameToIndex[key] = index;
                }
            });
        }
        incoming.forEach(reference => {
            const name = readField(fieldName, reference);
            const index = name ? fieldNameToIndex[name] : undefined;
            if (!name) {
                return;
            }
            if (typeof index === 'number') {
                // Merge the new data with the existing data.
                merged[index] = mergeObjects(merged[index], reference);
            }
            else {
                // First time we've seen this data in this array.
                fieldNameToIndex[name] = merged.length;
                merged.push(reference);
            }
        });
        return merged;
    };
}
