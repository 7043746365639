import { useCurrentUserTeamIds } from 'app/domains/teams';
import { AclAction } from 'shared/domains/apollo/generated/types';
import useAccount from 'shared/domains/users/hooks/useAccount';
export function useACLCan(params) {
    const { userAcls, teamAcls } = params;
    const account = useAccount();
    const { teamIds } = useCurrentUserTeamIds();
    const can = (action) => (userId = account.data.userId) => {
        var _a;
        if (account.data.role === 'owner') {
            if (action === AclAction.Traverse) {
                return false;
            }
            return true;
        }
        return ((_a = ((userAcls === null || userAcls === void 0 ? void 0 : userAcls.some(access => access.userId === userId && access.actions.includes(action))) ||
            (teamAcls === null || teamAcls === void 0 ? void 0 : teamAcls.some(access => teamIds.includes(access.teamId) && access.actions.includes(action))))) !== null && _a !== void 0 ? _a : false);
    };
    return {
        canTraverse: can(AclAction.Traverse),
        canRead: can(AclAction.Read),
        canWrite: can(AclAction.Write),
        canDelete: can(AclAction.Delete),
    };
}
