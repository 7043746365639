export const englishSystemPrompts = [
    {
        id: 'a4955131-fffe-4b39-9859-4606c80cb393',
        name: 'Summary of NDA',
        content: 'In 200 words, please summarise:\nAny penalties for breach; any non-solicitation clauses; and the duration of this agreement',
        labels: ['NDA'],
    },
    {
        id: 'b22be07a-52ca-4dd5-b870-961ccc240316',
        name: 'Draft an email to IT Director',
        content: 'Draft an email to our IT Director, asking how their week is going and summarising their responsibilities if they signed this agreement on behalf of the company in 150 words',
        labels: ['NDA', 'Email'],
    },
    {
        id: 'd9b1c9b2-4a45-4f4e-9980-3c2e1638f456',
        name: 'Should Legal be reviewing this doc?',
        content: 'The contract will require a full or partial review by the Legal Team where:\n1. The total value of the contract more than £100,000 (for example a contract for £40,000 a year for 3 years would meet this, but would not if the duration was only 2 years);\n2. The contract is for longer than 3 years;\n3. The contract contains any assignment of intellectual property; or\n4. The contract is for the sale or purchase of land (including leases)\n\nPlease review this contract and tell me in 50 words or less if and why this contract should be reviewed by the Legal Team?',
        labels: ['Supplier Review'],
    },
    {
        id: '728021c6-0aa5-492e-b5be-cc16a0045b64',
        name: 'General review of key dates',
        content: 'Please review the document and summaries key dates in a straightforward bullet pointed timeline. Please do this in 120 words max',
        labels: ['Supplier Review'],
    },
    {
        id: '519ff204-fa6a-43b3-8d0d-ad7baadd610f',
        name: 'General review of supplier terms',
        content: 
        // eslint-disable-next-line lingui/text-restrictions
        'Please review the document for the below and bold each heading in your answer:\n\nPayment Terms: Payment terms must be at least 60 days from receipt of invoice - give me 10 words on whether this agreement meets that requirement?\n\nTerm: unless there is the ability to terminate for convenience we do not recommend signing up to a contract which is for longer than 12 months. What should we do on this contract in 35 words?\n\nLiability: Please review the liability cap in this document and summarise it for me with potential risks in bullet points, in no more than 100 words.\n\nExit: Please detail the exit routes of this contract in simple bullet points in 100 words max\n\nSLAs: What SLAs would the supplier be agreeing to in this agreement? If there are no SLA’s, please let me know. Answer this section in 50 words max.\n\nAny unusual clauses: Is there anything outside of what I have asked already which is unusual for a supplier agreement in here, which I should consider reviewing?',
        labels: ['Supplier Review'],
    },
    {
        id: 'b6e24654-5d81-4fd8-8c2a-fb42f7adb23e',
        name: 'General review of liability',
        content: 
        // eslint-disable-next-line lingui/text-restrictions
        'Please do a calculation on whether this agreement would meet our minimum liability cap requirement for £5,000,000, and if it doesn’t please draft me a clause addressing this.',
        labels: ['Supplier Review'],
    },
    {
        id: '44e70ff1-d1ed-4b72-8f20-ab9b4eba0c38',
        name: 'Email for internal contract owner',
        content: 
        // eslint-disable-next-line lingui/text-restrictions
        'Please draft me an email to the contract owner highlighting the following the information:\nKey responsibilities from a financial perspective if we purchase this vendor\nSLA’s and deliverables which need to be done\nLicensing, IP and and use of logos\nTermination & key dates\n\nDo this in 150 words and ask them how they are at the beginning',
        labels: ['Supplier Review', 'Email'],
    },
    {
        id: 'fe847303-abfa-4e7c-a570-b97431a94d72',
        name: 'General review of an employment contract',
        content: 'Please review the document for the below and bold each heading in your answer:\n\nSalary: Please review the document and tell me the salary and any bonuses you can find, in bullet points in no more than 100 words.\n\nNon-compete: if there is a non-compete and what the terms are around that in plain english, in no more than 100 words\n\nNon-solicitation: What the coverage is for non-solicitation and in plain english what can and cannot be done, in 100 words.\n\nNotice: What the notice period is in the contract, in 30 words max.\n\nProbation: When the probation period ends (calculate the date) and any specific terms which are important to know about probation in bullet points, and no more than 100 words.\n\nAny unusual clauses: Is there anything outside of what I have asked already which is unusual for a supplier agreement in here, which I should consider reviewing?',
        labels: ['HR'],
    },
    {
        id: 'b1902d67-d45c-492c-9bd7-47b3f32299bd',
        name: 'Identify Key Terms',
        content: 'List and define any key terms or jargon used in the contract. Provide clear definitions and context for each term.',
        labels: ['Summary'],
    },
    {
        id: 'fe9b067c-3492-4775-a8af-77fb71d61d1c',
        name: 'Outline Termination Clauses',
        content: 'Summarize the conditions under which the contract can be terminated by either party. Include any notice periods and specific actions required.',
        labels: ['Termination'],
    },
    {
        id: '7461ac50-b083-4108-bb53-4c7f3e74d027',
        name: 'Highlight Confidentiality Requirements',
        content: 'Describe the confidentiality requirements included in this agreement. Specify any obligations, durations, and exceptions.',
    },
    {
        id: 'ed50e0ff-b488-45a0-a8a3-8c4facd1d107',
        name: 'Summarize Payment Terms',
        content: 'Detail the payment terms in the contract. Include due dates, amounts, conditions for payment, and any penalties for late payments.',
        labels: ['Commercial'],
    },
    {
        id: '1c6be15f-2c27-4ff5-bdde-9292bbe27502',
        name: 'Explain Dispute Resolution',
        content: 'Explain the dispute resolution process outlined in the contract. Describe steps involved, any mediation or arbitration requirements, and governing rules.',
        labels: ['Dispute'],
    },
    {
        id: '3a5afc6f-a41c-4df9-bff8-22cbb1e8a1bb',
        name: 'Examine Liability Limits',
        content: 'Detail the limits of liability specified in the contract. Include caps on damages and any exclusions of certain types of liability.',
    },
    {
        id: 'ff13bf59-fd54-4cb0-9c6f-a19d186bac96',
        name: 'Assess Non-Compete Clauses',
        content: 'Identify and summarize any non-compete clauses. Specify the duration, geographic scope, and activities restricted by the clause.',
    },
    {
        id: '32d13876-73cd-4f91-9bee-1a0e3e7407a5',
        name: 'Check for Non-Disclosure Agreements',
        content: 'Summarize any non-disclosure agreements (NDAs) included in the contract. Detail the scope, duration, and exceptions to the NDA.',
    },
    {
        id: '63090169-13df-4461-9b7f-ff7347e5f655',
        name: 'Review Amendments Procedure',
        content: 'Detail the procedure for amending the contract. Include any requirements for written consent, notice periods, and approval processes.',
    },
    {
        id: '55a5aaa9-804a-4e6c-9535-3019b784ec5b',
        name: 'Evaluate Compliance Requirements',
        content: 'Identify any compliance requirements specified in the contract. Describe the standards, regulations, or laws that must be adhered to by the parties.',
    },
].map(props => (Object.assign(Object.assign({ isSystemPrompt: true, isPublic: true, labels: [] }, props), { __typename: 'DocumentAssistantPrompt' })));
