import { AvatarList, AvatarLetter } from '@pocketlaw/tetris';
import { getFirstLetter } from 'app/domains/users';
import { DesktopTableBodyCell } from '../styled';
export function MembersCell(props) {
    const { members } = props;
    return (<DesktopTableBodyCell headers="members">
      {members && (<AvatarList appearance="cyan" avatars={members.map(({ id, name }) => ({
                id,
                tooltip: { title: name },
                component: <AvatarLetter>{getFirstLetter(name)}</AvatarLetter>,
            }))} limit={5}/>)}
    </DesktopTableBodyCell>);
}
