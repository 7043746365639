import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled, { css } from 'styled-components';
export const Overlay = styled.div `
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  padding: ${themeGet('spacing.4xl')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
function transitionStates(state) {
    const hidden = css `
    opacity: 0;
    transform: translate(0, ${pxToRem(-10)});
  `;
    const visible = css `
    opacity: 1;
    transform: translate(0, 0);
  `;
    const leaving = css `
    opacity: 0;
    transform: translate(0, ${pxToRem(10)});
  `;
    switch (state) {
        case 'hidden':
            return hidden;
        case 'leaving':
            return leaving;
        case 'visible':
        default:
            return visible;
    }
}
export const SliceInfoContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${props => transitionStates(props.$state)};

  transition: all 0.2s ease-in-out;
`;
