import { t } from '@lingui/macro';
import { ESignPollingState } from '../ESignPollingState';
import { OverlayLoader } from '../components/OverlayLoader';
export function ESignCreatingState(props) {
    const { polling = true, overlay = false } = props;
    const title = t({
        message: 'Sending document for e-signing',
        comment: 'Message when the document is being sent for e-signing',
    });
    const message = t({
        message: 'Waiting for the e-signing provider. This may take a moment.',
        comment: 'Descriptive message when the document is being sent for e-signing',
    });
    if (polling) {
        return <ESignPollingState overlay={overlay} title={title} message={message}/>;
    }
    return <OverlayLoader overlay={overlay} title={title} message={message}/>;
}
