import { Falcon } from '@pocketlaw/tetris/macro';
import { ChatMessageType } from 'shared/domains/apollo/generated/types';
export const getAssistantMessageIconByPromptType = (type) => {
    switch (type) {
        case ChatMessageType.DocumentSummarize:
        case ChatMessageType.DocumentSummarizeWithQuotes:
        case ChatMessageType.SelectionSuggest:
        case ChatMessageType.DocumentSuggest:
            return <Falcon icon="section" $color="assistant.500"/>;
        default:
            return null;
    }
};
