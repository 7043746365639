import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  padding: ${themeGet('spacing.lg')} ${themeGet('spacing.xl')};
  margin-top: ${themeGet('spacing.md')};
`;
export const AlignRight = styled.div `
  display: flex;
  align-items: center;
  margin-left: auto;
`;
export const Row = styled.div `
  display: flex;
  padding: ${themeGet('spacing.lg')} 0;

  &:not(:last-child) {
    border-bottom: ${pxToRem(1)} solid ${themeGet('colors.border.100')};
  }
`;
