import { localeConfig } from 'shared/domains/languages/config';
import { getLocalizedDate, getLocalizedTime } from 'shared/domains/languages/utils';
import { getIconFromStatus, getLabelFromStatus } from './utils';
export const usePartyStatus = (signingStatus) => {
    const { status, updatedAt } = signingStatus || {};
    const locale = localeConfig.getActiveLocale().code;
    const formattedDate = getLocalizedDate(updatedAt, locale);
    const formattedTime = getLocalizedTime(updatedAt, locale);
    if (!status) {
        return undefined;
    }
    const date = formattedDate;
    const time = formattedTime;
    const label = getLabelFromStatus(status);
    const bigIcon = getIconFromStatus(status);
    const smallIcon = getIconFromStatus(status, true);
    const timestamps = date && time ? { date, time } : null;
    return { label, bigIcon, smallIcon, timestamps };
};
