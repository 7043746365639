import { gql } from '@apollo/client';
export const pollCompanyESigningProvidersQuery = gql `
  query pollCompanyESigningProviders($companyId: UUID!) {
    company(id: $companyId) {
      id
      esigningProvider {
        id
        companyId
        esigningProviderId
        expiresAt
      }
    }
  }
`;
