import { useMutation } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { removeFileSystemAclMutation } from './removeFileSystemAclMutation';
export function useRemoveFileSystemAcl() {
    const successMessage = t({
        message: 'Successfully removed access',
        comment: 'remove access from folder or file toast success message',
    });
    const errorMessage = t({
        message: 'Failed to remove access',
        comment: 'remove access from folder or file toast error message',
    });
    const [mutate, { loading, error }] = useMutation(removeFileSystemAclMutation);
    const { addToast } = useToast();
    const removeFileSystemAcl = (input) => {
        const { aclId, type } = input;
        mutate({
            variables: { aclId, isUser: type === 'user', isTeam: type === 'team' },
            onCompleted() {
                addToast({
                    title: successMessage,
                    appearance: 'success',
                });
            },
            onError() {
                addToast({
                    title: errorMessage,
                    appearance: 'danger',
                });
            },
            update(cache) {
                const typename = type === 'user' ? 'FileSystemAcl' : 'FileSystemTeamAcl';
                cache.modify({
                    id: cache.identify({ id: aclId, __typename: typename }),
                    fields(_, { DELETE }) {
                        return DELETE;
                    },
                });
            },
        });
    };
    return { removeFileSystemAcl, loading, error };
}
