var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { useActiveDocument } from 'shared/domains/documents';
import { RequestReviewDocument } from './requestReview.gql';
import { GetReviewDocument } from '../useCurrentReview/getCurrentReview.gql';
export function useRequestReview() {
    const { id: documentId } = useActiveDocument();
    const [mutate, { loading }] = useMutation(RequestReviewDocument);
    const handler = (playbookId) => __awaiter(this, void 0, void 0, function* () {
        yield mutate({
            variables: { documentId, playbookId },
            refetchQueries: [GetReviewDocument],
            onQueryUpdated(observableQuery) {
                return observableQuery.refetch();
            },
        });
    });
    return {
        requestReview: handler,
        loading,
    };
}
