import { visit, SKIP } from 'unist-util-visit';
function removeFootnoteDefintions(tree) {
    const footnoteDefinitions = [];
    visit(tree, 'footnoteDefinition', (node, nodeIndex, parent) => {
        const textValues = [];
        visit(node, 'text', textNode => {
            textValues.push(textNode.value);
        });
        const value = textValues.join('');
        footnoteDefinitions.push({ identifier: node.identifier, value });
        // Remove node from tree
        if (parent && nodeIndex != null) {
            parent.children.splice(nodeIndex, 1);
            // Continue traversing at the same index as we have removed the current node
            return nodeIndex;
        }
        return SKIP;
    });
    return footnoteDefinitions;
}
function decorateFootnoteReferences(tree, footnoteDefinitions) {
    visit(tree, 'footnoteReference', (node, nodeIndex, parent) => {
        const footnoteDefinition = footnoteDefinitions.find(({ identifier }) => identifier === node.identifier);
        if (parent && nodeIndex != null && footnoteDefinition) {
            const htmlElementName = 'sup';
            const htmlElementAttributes = {
                'data-footnote-definition': footnoteDefinition.value,
            };
            const htmlElementChildren = [
                {
                    type: 'text',
                    value: node.identifier,
                },
            ];
            parent.children.splice(nodeIndex, 1, Object.assign(Object.assign({}, node), { data: Object.assign(Object.assign({}, node.data), { hName: htmlElementName, hProperties: htmlElementAttributes, hChildren: htmlElementChildren }) }));
        }
        return SKIP;
    });
}
export function footNotesToQuotes() {
    return function transformer(tree) {
        const footnoteDefinitions = removeFootnoteDefintions(tree);
        decorateFootnoteReferences(tree, footnoteDefinitions);
        return tree;
    };
}
