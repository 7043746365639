import { Checkbox, MenuItem } from '@pocketlaw/tetris';
import { TeamAvatarWithLabel } from 'app/domains/teams/components/TeamAvatarWithLabel';
import { UserAvatarWithLabel } from 'app/domains/users/components/UserAvatarWithLabel';
import { AlignRight } from '../styled';
export function Item(props) {
    const { type, id, name, onChange, checked } = props;
    const AvatarComponent = type === 'user' ? UserAvatarWithLabel : TeamAvatarWithLabel;
    return (<MenuItem as="label">
      <AvatarComponent title={name || ''} size="xs" shape="circle"/>
      <AlignRight>
        <Checkbox checked={checked} onChange={() => onChange(id)}/>
      </AlignRight>
    </MenuItem>);
}
