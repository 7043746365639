import { useMemo } from 'react';
import { useLingui } from '@lingui/react';
import countries from './countries';
export const useCountries = () => {
    const { i18n } = useLingui();
    // NOTE: Will throw warnings and skip if not renamed. Will probably be solved in v4
    const tmpI18n = i18n;
    const translations = useMemo(() => {
        const result = {};
        Object.entries(countries).forEach(([countryCode, title]) => {
            Object.assign(result, { [countryCode]: tmpI18n._(title) });
        });
        return result;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.locale]);
    return translations;
};
