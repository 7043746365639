var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as Sentry from 'shared/domains/sentry';
const currentVersion = process.env.APP_VERSION || '0.0.0';
export function useNewAppRelease() {
    const location = useLocation();
    const [nextVersion, setNextVersion] = useState(currentVersion);
    const isFetching = useRef(false);
    const newReleaseAvailable = currentVersion !== nextVersion;
    useEffect(() => {
        function runAsync() {
            return __awaiter(this, void 0, void 0, function* () {
                if (newReleaseAvailable || isFetching.current || process.env.NODE_ENV === 'development') {
                    return;
                }
                try {
                    isFetching.current = true;
                    const response = yield fetch('/version.json', {
                        method: 'GET',
                        headers: {
                            accept: 'application/json',
                        },
                    });
                    const contentType = response.headers.get('content-type');
                    if (!(contentType === null || contentType === void 0 ? void 0 : contentType.includes('application/json'))) {
                        throw new Error(`Response of "/version.json" expected type application/json, but was ${contentType}`);
                    }
                    const data = yield response.json();
                    if (!data || !data.version) {
                        throw new Error('Fetched "/version.json" successfully, but no version was found.');
                    }
                    setNextVersion(data.version);
                }
                catch (error) {
                    Sentry.captureException(error, {
                        extra: {
                            message: 'Failed to get latest app version from NGINX endpoint.',
                        },
                    });
                }
                finally {
                    isFetching.current = false;
                }
            });
        }
        runAsync();
    }, [location, setNextVersion, newReleaseAvailable]);
    return newReleaseAvailable;
}
