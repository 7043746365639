import { t } from '@lingui/macro';
import { AvatarWithLabel } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useHistory } from 'react-router-dom';
import { useAttachmentData } from 'app/domains/tasks/components/PreviewTask/useAttachmentData';
import { LinkButton, AttachmentContainer } from './styled';
export function Attachment() {
    const history = useHistory();
    const { attachmentData } = useAttachmentData();
    const { name = '', id, hasAccess } = attachmentData;
    const message = t({
        message: "You don't have access to this attachment",
        comment: 'task preview attachment field - when you missing access to attachment',
    });
    const disabled = !hasAccess;
    const getLink = () => {
        if ((attachmentData === null || attachmentData === void 0 ? void 0 : attachmentData.type) === 'template') {
            return `/contracts?id=${id}`;
        }
        return `/document/${id}`;
    };
    return (<AttachmentContainer>
      <LinkButton disabled={disabled} type="button" onClick={() => history.push(getLink())}>
        <AvatarWithLabel title={hasAccess ? name : message} solid size="sm" truncate={false} appearance={hasAccess ? 'primary' : 'light'}>
          {hasAccess ? <Falcon icon="file"/> : <Falcon icon="lock"/>}
        </AvatarWithLabel>
      </LinkButton>
    </AttachmentContainer>);
}
