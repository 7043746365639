export function getSelectedInput(aggregation) {
    const { type } = aggregation || {};
    switch (type) {
        case 'dateHistogram':
            return aggregation === null || aggregation === void 0 ? void 0 : aggregation.dateHistogramInput;
        case 'terms':
            return aggregation === null || aggregation === void 0 ? void 0 : aggregation.termsInput;
        case 'dateRange':
            return aggregation === null || aggregation === void 0 ? void 0 : aggregation.dateRangeInput;
        case 'metric':
            return aggregation === null || aggregation === void 0 ? void 0 : aggregation.metricInput;
        case 'topHits':
        default:
            return undefined;
    }
}
export function getSelectedField(aggregation) {
    var _a, _b, _c, _d;
    const { type } = aggregation || {};
    switch (type) {
        case 'dateHistogram':
            return (_a = aggregation === null || aggregation === void 0 ? void 0 : aggregation.dateHistogramInput) === null || _a === void 0 ? void 0 : _a.field;
        case 'terms':
            return (_b = aggregation === null || aggregation === void 0 ? void 0 : aggregation.termsInput) === null || _b === void 0 ? void 0 : _b.field;
        case 'dateRange':
            return (_c = aggregation === null || aggregation === void 0 ? void 0 : aggregation.dateRangeInput) === null || _c === void 0 ? void 0 : _c.field;
        case 'metric':
            return (_d = aggregation === null || aggregation === void 0 ? void 0 : aggregation.metricInput) === null || _d === void 0 ? void 0 : _d.field;
        case 'topHits':
        default:
            return undefined;
    }
}
