import { forwardRef } from 'react';
import { t } from '@lingui/macro';
import { Falcon } from '@pocketlaw/tetris/macro';
import { LinkField } from '../../LinkField';
import { useAssigneesField } from '../useAssigneesField';
export const AssigneeTarget = forwardRef((props, ref) => {
    const { onClick } = props;
    const { removeAllAssignees, selectedAssignees } = useAssigneesField();
    const formatUsers = () => {
        const assigneeLength = selectedAssignees.length;
        const [assignee] = selectedAssignees;
        const firstName = (assignee === null || assignee === void 0 ? void 0 : assignee.name) || '';
        const zero = t({ message: 'Assign users', comment: 'Assignees added to task' });
        const one = firstName;
        const other = `${firstName} +${assigneeLength - 1}`;
        if (assigneeLength === 0) {
            return zero;
        }
        if (assigneeLength === 1) {
            return one;
        }
        return other;
    };
    const linkText = formatUsers();
    return (<LinkField ref={ref} name="taskAssignees" icon={<Falcon icon="user"/>} linkText={linkText} linkPrefix={t({
            message: 'Assigned to',
            comment: 'add assignee link text when selected users',
        })} onClick={onClick} onRemove={removeAllAssignees}/>);
});
