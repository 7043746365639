import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: ${themeGet('spacing.2xl')};
`;
export const CardContainer = styled.div `
  flex: 0 0 ${pxToRem(330)};
  margin: 0 auto;
`;
export const TableContainer = styled.div `
  flex: 1 1 auto;
  margin: 0 auto;
`;
