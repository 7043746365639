import { t } from '@lingui/macro';
import { PendingParty } from './PendingParty';
import { SectionList } from '../../../components';
export function UnorderedSigningParties(props) {
    const { signingParties } = props;
    if (!signingParties.length) {
        return null;
    }
    return (<SectionList hideTooManyItems title={t({ message: 'Signing parties', comment: 'Label for signing parties' })}>
      {signingParties.map(party => (<PendingParty key={party.id} party={party} isNotOnlyParty={signingParties.length > 1}/>))}
    </SectionList>);
}
