import { t } from '@lingui/macro';
import { Box, Typography } from '@pocketlaw/tetris';
import { getCurrentMarketTemplateLocales } from 'app/domains/markets/config';
import { useComposerData } from '../../../ComposerDataProvider';
import { FilterChip } from '../FilterChip';
export function Languages() {
    const locales = getCurrentMarketTemplateLocales();
    const { selectedLanguages, addSelectedLanguage, removeSelectedLanguage } = useComposerData();
    const title = t({
        message: 'Languages',
        comment: 'Select template dialog - Languages label',
    });
    return (<Box flexDirection="column" gap="md">
      <Typography $fontSize="small" $appearance="100" $fontWeight="bold">
        {title}
      </Typography>
      <Box gap="md" flexDirection="column">
        {locales.map(({ getName, languageCode }) => (<FilterChip key={languageCode} selected={selectedLanguages.includes(languageCode)} onAdd={() => addSelectedLanguage(languageCode)} onRemove={() => removeSelectedLanguage(languageCode)} label={getName()}/>))}
      </Box>
    </Box>);
}
