import { t } from '@lingui/macro';
import { SearchEntityTypeEnum } from 'shared/domains/apollo/generated/types';
export function getSearchEntityTypeText(type) {
    switch (type) {
        case SearchEntityTypeEnum.DocumentComposed:
        case SearchEntityTypeEnum.DocumentUploaded:
        case SearchEntityTypeEnum.DocumentFreetext:
            return t `Document`;
        case SearchEntityTypeEnum.Draft:
            return t `Draft`;
        case SearchEntityTypeEnum.Template:
            return t `Template`;
        case SearchEntityTypeEnum.Folder:
            return t `Folder`;
        case SearchEntityTypeEnum.User:
            return t `User`;
        default:
            return '';
    }
}
