var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@apollo/client';
import { transformThread } from 'shared/domains/feedback/utils/transformThread';
import { getInviteByID } from './getInviteByID';
import getInvite from './query';
function useMessageThread(options) {
    const { shouldLoadData, documentId, inviteId, interlocutorId } = options;
    const _a = useQuery(getInvite, {
        skip: !shouldLoadData,
        variables: {
            documentId,
        },
    }), { data } = _a, queryWithoutData = __rest(_a, ["data"]);
    const { externalSharingInvites = [] } = data || {};
    const _b = getInviteByID(externalSharingInvites, inviteId), { messageThreads = [] } = _b, invite = __rest(_b, ["messageThreads"]);
    const thread = messageThreads
        .map(messageThread => transformThread({ thread: messageThread, inviteId: invite.id, documentId }))
        .find(({ interlocutor }) => interlocutor.id === interlocutorId);
    const emptyThread = {};
    const dataToReturn = !invite ? null : Object.assign(Object.assign({}, invite), { thread: thread || emptyThread });
    return Object.assign(Object.assign({}, queryWithoutData), { data: dataToReturn });
}
export default useMessageThread;
