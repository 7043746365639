import { Fragment } from 'react';
import { AvatarWithLabel, Avatar, IconButton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { EditFile } from './EditFile';
import { UnsupportedChip } from '../../../UnsupportedChip';
import { useFilesPreview } from '../../../hook';
import { TreeBranch } from '../../TreeBranch';
import { AlignRight, NodeContent } from '../styled';
import { StyledIconButton, StyledNode } from './styled';
export function FileNode(props) {
    const { id } = props;
    const { getFile, removeFile, fileIdBeingEdited, setFileIdBeingEdited } = useFilesPreview();
    const file = getFile(id);
    const isEditing = id === fileIdBeingEdited;
    const handleRemove = () => removeFile(id);
    const handleEditName = () => setFileIdBeingEdited(id);
    if (!file) {
        return null;
    }
    const { name, extension, supported, unsupportedReason } = file;
    const iconButtonProps = {
        $round: true,
        size: 'small',
        appearance: 'ghost',
    };
    return (<StyledNode>
      <TreeBranch />
      <NodeContent $file>
        {isEditing ? (<EditFile id={id} name={name}/>) : (<Fragment>
            <AvatarWithLabel size="md" appearance="secondary" title={name + extension}>
              <Avatar>
                <Falcon icon="file"/>
              </Avatar>
            </AvatarWithLabel>
            {supported && (<StyledIconButton {...iconButtonProps} onClick={handleEditName}>
                <Falcon icon="pen-to-square"/>
              </StyledIconButton>)}
          </Fragment>)}
        <AlignRight>
          <UnsupportedChip unsupportedReason={unsupportedReason}/>
          <IconButton {...iconButtonProps} onClick={handleRemove}>
            <Falcon icon="xmark"/>
          </IconButton>
        </AlignRight>
      </NodeContent>
    </StyledNode>);
}
