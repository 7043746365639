import { useState, useEffect } from 'react';
import { t, Trans } from '@lingui/macro';
import { Loader, Button, Dialog, DialogHeader, DialogContent, DialogFooter, useToast, Alert, } from '@pocketlaw/tetris';
import { Text } from '@pocketlaw/tetris/deprecated';
import { useEsigningProviders } from 'app/domains/esigning/hooks/useEsigningProviders';
import { getEsigningProviderMetadataByProviderId } from 'app/domains/esigning/utils';
import { useRole } from 'app/domains/users/hooks/useRole';
import * as Sentry from 'shared/domains/sentry';
import { EsigningProviderList } from './EsigningProviderList';
import { ReconnectExpiredProviderDialog } from './ReconnectExpiredProviderDialog';
import { useConnectProvider } from './useConnectProvider';
import { useIsPaidPlan } from './useIsPaidPlan';
import { Container } from './styled';
export function ConnectEsigningProviderDialog(props) {
    const { onClose } = props;
    const { addToast } = useToast();
    const { loading, providers, connectedProvider, isConnectedProviderExpired, error: fetchProvidersError, } = useEsigningProviders();
    const isPaidPlan = useIsPaidPlan();
    const esigningProviders = providers.filter(esigningProvider => {
        const { properties } = getEsigningProviderMetadataByProviderId(esigningProvider.id);
        return properties.hasFreePlanAccess || isPaidPlan;
    });
    const providerInitiallySelected = esigningProviders.find(esigningProvider => {
        const { properties } = getEsigningProviderMetadataByProviderId(esigningProvider.id);
        return properties.selectedByDefault;
    });
    const initiallySelectedId = (providerInitiallySelected === null || providerInitiallySelected === void 0 ? void 0 : providerInitiallySelected.id) || '';
    const [selectedEsigningProviderId, setSelectedEsigningProviderId] = useState(initiallySelectedId);
    const role = useRole();
    const canManageProvider = role.can('manage:settings');
    const onConnectProviderError = () => {
        addToast({
            appearance: 'danger',
            title: t({
                message: 'Failed to connect the provider, please try again',
                comment: "Message that shows when the user couldn't connect to the selected provider",
            }),
        });
    };
    const onConnectProviderSuccess = () => {
        addToast({
            appearance: 'success',
            title: t({
                message: 'Successfully connected to the provider',
                comment: 'Message that shows when the user successfully connected to the selected provider',
            }),
        });
        onClose();
    };
    const { connectingProvider, connectProvider, connectProviderError } = useConnectProvider({
        onConnectProviderError,
        onConnectProviderSuccess,
    });
    const handleCloseDialog = () => {
        if (!connectingProvider) {
            onClose();
        }
    };
    // Handles fetch providers error
    useEffect(() => {
        if (!fetchProvidersError && !loading && !esigningProviders.length) {
            Sentry.captureMessage('No esigning providers found in ConnectEsigningProviderDialog');
        }
        if (fetchProvidersError) {
            Sentry.captureException(fetchProvidersError);
        }
    }, [esigningProviders, loading, fetchProvidersError]);
    if (connectedProvider && connectedProvider.expired) {
        return (<ReconnectExpiredProviderDialog providerId={connectedProvider.id} loading={connectingProvider} onClose={handleCloseDialog} onReconnect={() => connectProvider(connectedProvider.id)}/>);
    }
    return (<Dialog size="sm" onClose={handleCloseDialog}>
      <DialogHeader title={<Trans comment="Title for select e-signing provider modal">Select e-sign provider</Trans>}/>
      <DialogContent>
        <Container>
          <Text appearance="neutral">
            <Trans comment="Descriptive text for the user that we show when they select an e-signing provider">
              To use e-signing you need to select an e-signing provider. Use our integrated service
              or connect your existing provider to continue.
            </Trans>
          </Text>
          <EsigningProviderList expired={isConnectedProviderExpired} loading={loading} connecting={connectingProvider} providers={esigningProviders} connectedProvider={connectedProvider} selectedProviderId={selectedEsigningProviderId} onSelectProvider={setSelectedEsigningProviderId}/>
          {connectProviderError && (<Alert appearance="danger" message={t({
                message: 'Could not connect to the PocketLaw provider, please try again later',
                comment: 'Message that we show when en error occurs while trying to connect to the pocketlaw e-signing provider',
            })}/>)}
          {fetchProvidersError && (<Alert appearance="danger" message={t({
                message: 'Something went wrong, please try again later',
                comment: 'Message that we show when en error occurs while fetching e-signing providers from the backend',
            })}/>)}
        </Container>
      </DialogContent>
      <DialogFooter>
        <Button type="button" appearance="secondary" size="medium" disabled={connectingProvider} onClick={onClose}>
          <Trans comment="Button for canceling when selecting e-signing provider">Cancel</Trans>
        </Button>
        <Button type="button" appearance="primary" size="medium" disabled={connectingProvider || !selectedEsigningProviderId || !canManageProvider} onClick={() => connectProvider(selectedEsigningProviderId)}>
          {connectingProvider ? (<Loader />) : (<Trans comment="Button for continuing with the selected e-signing provider">
              Select
            </Trans>)}
        </Button>
      </DialogFooter>
    </Dialog>);
}
