import { SearchAggregationType } from 'shared/domains/apollo/generated/types';
export function bucketTypeGuard(aggregation) {
    // eslint-disable-next-line no-underscore-dangle
    return (aggregation === null || aggregation === void 0 ? void 0 : aggregation.__typename) === 'SearchResponseBucketedAggregation';
}
export function metricTypeGuard(aggregation) {
    // eslint-disable-next-line no-underscore-dangle
    return (aggregation === null || aggregation === void 0 ? void 0 : aggregation.__typename) === 'SearchResponseScalarAggregation';
}
export function topHitsTypeGuard(aggregation) {
    // eslint-disable-next-line no-underscore-dangle
    return (aggregation === null || aggregation === void 0 ? void 0 : aggregation.__typename) === 'SearchResponseTopHitsAggregation';
}
export function dateHistogramTypeGuard(data) {
    return bucketTypeGuard(data) && data.type === SearchAggregationType.DateHistogram;
}
export function dateRangeTypeGuard(data) {
    return bucketTypeGuard(data) && data.type === SearchAggregationType.DateRange;
}
export function isDateRangeBucket(data) {
    return Boolean(data.from || data.to);
}
