import { useQuery } from '@apollo/client';
import { useEsigning } from 'app/domains/esigning/components/EsigningProvider';
import { ESIGNING_SENDOUT_SUPPORTED_MIME_TYPES } from 'app/domains/esigning/utils';
import { isComposedDocument, isFreeTextDocument } from 'shared/domains/documents';
import { getDocumentTypes } from './query';
export const useDocumentForEsigning = () => {
    var _a;
    const { documentId } = useEsigning();
    const { data, loading, error } = useQuery(getDocumentTypes, {
        variables: { documentId },
    });
    const { type, files = [] } = (_a = data === null || data === void 0 ? void 0 : data.document) !== null && _a !== void 0 ? _a : {};
    const supportedFile = files.find(file => ESIGNING_SENDOUT_SUPPORTED_MIME_TYPES.includes(file.mimeType));
    const documentSupportsEsigning = supportedFile !== undefined || isFreeTextDocument({ type }) || isComposedDocument({ type });
    return { loading, error, documentSupportsEsigning };
};
