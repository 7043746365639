import { useEffect, useRef } from 'react';
/**
 * Note: This hook does not behave the same as the react-use `usePrevious` hook.
 */
function usePrevious(value) {
    const ref = useRef(value);
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}
export { usePrevious };
