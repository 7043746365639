import { nullToUndefined } from 'app/domains/esigning/utils';
import { SignatoryStatus } from 'shared/domains/apollo/generated/types';
export const mapToSignatory = (signatory) => ({
    name: signatory.name,
    id: signatory.id,
    email: signatory.email,
    status: signatory.status || SignatoryStatus.Unknown,
    firstName: nullToUndefined(signatory.firstName),
    lastName: nullToUndefined(signatory.lastName),
    title: nullToUndefined(signatory.title),
    label: nullToUndefined(signatory.label),
    mobile: nullToUndefined(signatory.mobile),
    message: nullToUndefined(signatory.message),
    order: nullToUndefined(signatory.order),
    updatedAt: nullToUndefined(signatory.updatedAt),
    isDefault: nullToUndefined(signatory.isDefault),
    authenticationMode: nullToUndefined(signatory.authenticationMode),
});
export const mapToRecipient = (ccRecipient) => ({
    email: ccRecipient.email,
    name: ccRecipient.name,
    firstName: nullToUndefined(ccRecipient.firstName),
    lastName: nullToUndefined(ccRecipient.lastName),
    message: nullToUndefined(ccRecipient.message),
    isDefault: nullToUndefined(ccRecipient.isDefault),
});
