import { t } from '@lingui/macro';
import { ButtonGroup, Skeleton } from '@pocketlaw/tetris';
import { ClearFilter } from './components/ClearFilter';
import { MultiselectOperator } from './components/MultiselectOperator';
import { NullOperator } from './components/NullOperator';
import { OperatorSelector } from './components/OperatorSelector';
import { useFilter } from './useFilter';
/**
 * Filter to use on SearchStringArrayFilter metadata properties.
 */
export function StringArrayFilter(props) {
    const { items, label, loading = false, name } = props;
    const filter = useFilter(name);
    return (<Skeleton loading={loading}>
      <ButtonGroup>
        <OperatorSelector name={name} label={label} operators={[
            {
                id: 'hasSome',
                menuItem: t({ message: 'In', comment: 'Search: Filter operator - has some' }),
                label: t({ message: 'in', comment: 'Search: Filter operator - has some' }),
                onSelect: () => filter.setValue({ hasSome: undefined }),
            },
            {
                id: 'hasNone',
                menuItem: t({ message: 'Not in', comment: 'Search: Filter operator - has none' }),
                label: t({ message: 'not in', comment: 'Search: Filter operator - has none' }),
                onSelect: () => filter.setValue({ hasNone: undefined }),
            },
            {
                id: 'isNull',
                menuItem: t({ message: 'Has value', comment: 'Search: Filter operator - has value' }),
                label: t({ message: 'has', comment: 'Search: Filter operator - has value' }),
                onSelect: () => filter.setValue({ isNull: undefined }),
            },
        ]}/>

        {!loading && filter.operator === 'isNull' && <NullOperator name={name}/>}

        {!loading && (filter.operator === 'hasSome' || filter.operator === 'hasNone') && (<MultiselectOperator items={items} name={name}/>)}

        <ClearFilter name={name}/>
      </ButtonGroup>
    </Skeleton>);
}
