import { breakpoints, pxToRem } from '@pocketlaw/tetris';
import styled from 'styled-components';
const PAPER_PADDING_DESKTOP = 48;
const PAPER_PADDING_MOBILE = 16;
const LOGO_RATIO_WIDTH = 20;
const LOGO_RATIO_HEIGHT = 9;
const LOGO_SIZE = 1.4; // Adjust to match DOCX/PDF logo size (visual comparison)
export const Logo = styled.img `
  position: absolute;
  left: ${({ $left }) => pxToRem($left + PAPER_PADDING_DESKTOP)};
  margin-top: ${pxToRem(20)};
  width: ${LOGO_RATIO_WIDTH * LOGO_SIZE}mm;
  height: ${LOGO_RATIO_HEIGHT * LOGO_SIZE}mm;

  ${breakpoints.xs} {
    left: ${({ $left }) => pxToRem($left + PAPER_PADDING_MOBILE)};
  }
`;
