import styled from 'styled-components';
export const FolderButton = styled.button `
  background-color: transparent;
  border: none;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
export const RightAlign = styled.div `
  margin-left: auto;
  display: flex;
  align-items: center;
`;
