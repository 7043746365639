import { t } from '@lingui/macro';
import { IconButton, Tooltip } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { usePlai } from 'app/domains/review';
export function Accept(props) {
    const { feedbackId } = props;
    const { acceptFeedback } = usePlai();
    const handleAcceptIssue = () => acceptFeedback(feedbackId);
    return (<Tooltip position="top" title={t({
            message: 'Accept issue',
            comment: 'Plai Review - Issue Item - accept - button tooltip',
        })}>
      <IconButton size="small" appearance="ghost" onClick={handleAcceptIssue}>
        <Falcon icon="check"/>
      </IconButton>
    </Tooltip>);
}
