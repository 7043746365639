import { gql } from '@apollo/client';
import { AutoTagEntryFragment } from './fragment';
export const autoTaggingQuery = gql `
  query AutoTagData($documentId: ID!) {
    autoTagging: documentMetadataAiAutoTaggingQueueEntries(
      where: { documentId: { eq: $documentId } }
    ) {
      ... AutoTagEntryFragment
    }

    ${AutoTagEntryFragment}
  }
`;
