import { t } from '@lingui/macro';
import { TableHead as TetrisTableHead, TableHeadCell, TableRow, TableHeaderText, VisuallyHidden, } from '@pocketlaw/tetris';
import { DesktopTableHeaderCell, MobileTableHeaderCell } from '../styled';
export function TableHead() {
    const nameColumnTitle = t({
        message: 'Name',
        comment: 'invites table: title of name column',
    });
    const dateSentColumnTitle = t({
        message: 'Date sent',
        comment: 'invites table: title of date-sent column',
    });
    const statusColumnTitle = t({
        message: 'Status',
        comment: 'invites table: title of status column',
    });
    const userRoleColumnTitle = t({
        message: 'User role',
        comment: 'invites table: title of user-role column',
    });
    const actionsColumnTitle = t({
        message: 'Actions',
        comment: 'invites table: title of actions column (screen readers only)',
    });
    const expandColumnTitle = t({
        message: 'Expand',
        comment: 'invites table: title of expand column (screen readers only)',
    });
    return (<TetrisTableHead>
      <TableRow>
        <MobileTableHeaderCell id="expand">
          <VisuallyHidden>{expandColumnTitle}</VisuallyHidden>
        </MobileTableHeaderCell>
        <TableHeadCell id="email">
          <TableHeaderText sortId="email">{nameColumnTitle}</TableHeaderText>
        </TableHeadCell>
        <DesktopTableHeaderCell id="created_at">
          <TableHeaderText sortId="created_at">{dateSentColumnTitle}</TableHeaderText>
        </DesktopTableHeaderCell>
        <DesktopTableHeaderCell id="expired">
          <TableHeaderText sortId="expired">{statusColumnTitle}</TableHeaderText>
        </DesktopTableHeaderCell>
        <DesktopTableHeaderCell id="role">
          <TableHeaderText>{userRoleColumnTitle}</TableHeaderText>
        </DesktopTableHeaderCell>
        <TableHeadCell id="actions" stickyColumn="right">
          <VisuallyHidden>{actionsColumnTitle}</VisuallyHidden>
        </TableHeadCell>
      </TableRow>
    </TetrisTableHead>);
}
