import { SearchTimeUnit } from 'shared/domains/apollo/generated/types';
export const searchDateTimeFilterPastPreset = [
    {
        rlte: {
            value: 0,
            timeUnit: SearchTimeUnit.Week,
        },
        rgte: {
            value: -1,
            timeUnit: SearchTimeUnit.Week,
        },
    },
    {
        rlte: {
            value: 0,
            timeUnit: SearchTimeUnit.Month,
        },
        rgte: {
            value: -1,
            timeUnit: SearchTimeUnit.Month,
        },
    },
    {
        rlte: {
            value: 0,
            timeUnit: SearchTimeUnit.Month,
        },
        rgte: {
            value: -3,
            timeUnit: SearchTimeUnit.Month,
        },
    },
    {
        rlte: {
            value: 0,
            timeUnit: SearchTimeUnit.Year,
        },
        rgte: {
            value: -1,
            timeUnit: SearchTimeUnit.Year,
        },
    },
];
export const searchDateTimeFilterFuturePreset = [
    {
        rlte: {
            value: 1,
            timeUnit: SearchTimeUnit.Week,
        },
        rgte: {
            value: 0,
            timeUnit: SearchTimeUnit.Week,
        },
    },
    {
        rlte: {
            value: 1,
            timeUnit: SearchTimeUnit.Month,
        },
        rgte: {
            value: 0,
            timeUnit: SearchTimeUnit.Month,
        },
    },
    {
        rlte: {
            value: 3,
            timeUnit: SearchTimeUnit.Month,
        },
        rgte: {
            value: 0,
            timeUnit: SearchTimeUnit.Month,
        },
    },
    {
        rlte: {
            value: 1,
            timeUnit: SearchTimeUnit.Year,
        },
        rgte: {
            value: 0,
            timeUnit: SearchTimeUnit.Year,
        },
    },
];
