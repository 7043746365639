import { useMemo } from 'react';
import { makeVar, useReactiveVar } from '@apollo/client';
/**
 * This represents the current state of the document.
 */
export const documentState = makeVar(undefined);
export function useDocumentState() {
    const state = useReactiveVar(documentState);
    return useMemo(() => state, [state]);
}
