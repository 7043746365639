import { Fragment, useState } from 'react';
import { Trans, plural, t } from '@lingui/macro';
import { Avatar, Chip, IconButton, Tabs, TabNav, TabContent, Tab, Card, CardContent, SidebarMenuItem, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useHistory } from 'react-router-dom';
import { useTransitionState } from 'shared/domains/common-ui';
import { JobList } from './JobList';
import { useAutoTagNotifications } from './useAutoTagNotifications';
import { Container, Footer, StyledCardHeader } from './styled';
const tabId = {
    Active: 'active',
    Completed: 'completed',
    Failed: 'failed',
};
/**
 * This component is used to display the auto tagging notifications.
 */
export function MetadataNotifications() {
    const history = useHistory();
    const { data } = useAutoTagNotifications();
    const { count, active, completed, failed } = data;
    const [isOpen, setIsOpen] = useState(false);
    const transitionState = useTransitionState(isOpen, 250);
    const toggleOpen = () => setIsOpen(!isOpen);
    const showNotifications = count > 0;
    const zeroNotifications = t({
        message: 'No auto tagging documents',
        comment: 'Auto tagging notification tooltip title',
    });
    const oneNotification = t({
        message: 'Auto tagging # document',
        comment: 'Auto tagging notification tooltip title',
    });
    const otherNotifications = t({
        message: 'Auto tagging # documents',
        comment: 'Auto tagging notification tooltip title',
    });
    const notificationTitle = plural(count, {
        0: zeroNotifications,
        one: oneNotification,
        other: otherNotifications,
    });
    return (<Fragment>
      {showNotifications && (<SidebarMenuItem icon={<Falcon icon="tags"/>} label={t({ comment: 'Sidebar menu - Ongoing tags label', message: 'Ongoing tags' })} adornmentRight={<Chip appearance="info" size="small" label={count}/>} onClick={toggleOpen}/>)}

      <Container $transitionState={transitionState}>
        <Tabs>
          <Card dropShadow="400">
            <StyledCardHeader title={notificationTitle} media={<Avatar shape="circle" appearance="assistant">
                  <Falcon icon="sparkles"/>
                </Avatar>} action={<IconButton $round appearance="ghost" size="small" onClick={() => setIsOpen(false)}>
                  <Falcon icon="xmark"/>
                </IconButton>}>
              <TabNav>
                <Tab id={tabId.Active}>
                  <Trans comment="Auto tag notifications: Active tab nav">Active</Trans>
                </Tab>
                <Tab id={tabId.Completed}>
                  <Trans comment="Auto tag notifications: Completed tab nav">Completed</Trans>
                </Tab>
                <Tab id={tabId.Failed}>
                  <Trans comment="Auto tag notifications: Failed tab nav">Failed</Trans>
                </Tab>
              </TabNav>
            </StyledCardHeader>

            <CardContent>
              <TabContent tabId={tabId.Active}>
                <JobList jobs={active}/>
              </TabContent>
              <TabContent tabId={tabId.Completed}>
                <JobList jobs={completed}/>
              </TabContent>
              <TabContent tabId={tabId.Failed}>
                <JobList jobs={failed}/>
              </TabContent>
            </CardContent>

            <Footer>
              <Chip variant="outline" appearance="secondary" onClick={() => history.push('/unverified-tags')} icon={<Falcon icon="shield"/>} label={<Trans comment="Auto tag notifications link to unverified documents">
                    View all unverified documents
                  </Trans>}/>
            </Footer>
          </Card>
        </Tabs>
      </Container>
    </Fragment>);
}
