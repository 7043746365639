import { forwardRef } from 'react';
import { Trans } from '@lingui/macro';
import { Button as TetrisButton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { DesktopOnly, MobileOnly } from 'app/domains/teams/components/CreateTeamButton/styled';
export const ResponsiveButton = forwardRef((props, forwardedRef) => {
    const { onClick } = props;
    return (<TetrisButton ref={forwardedRef} appearance="secondary" onClick={onClick}>
        <DesktopOnly>
          <Falcon icon="plus"/>
        </DesktopOnly>
        <MobileOnly>
          <Falcon icon="layer-plus"/>
        </MobileOnly>
        <DesktopOnly>
          <Trans comment="Create team button text">New team</Trans>
        </DesktopOnly>
      </TetrisButton>);
});
