var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Text } from '@pocketlaw/tetris/deprecated';
import { StyledChip } from '../styled';
const DATE_FORMAT = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
};
export function Date(props) {
    const { createdAt } = props, restProps = __rest(props, ["createdAt"]);
    const { i18n } = useLingui();
    if (!createdAt) {
        return null;
    }
    return (<Text {...restProps} size="tiny" appearance="neutral">
      <StyledChip appearance="secondary" label={t({
            comment: 'Universal search - Added label for date field',
            message: 'Added',
        })} secondary={i18n.date(createdAt, DATE_FORMAT)}/>
    </Text>);
}
