import { themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const DrawerContainer = styled.div `
  display: flex;
  flex-direction: column;
  height: 100%;
`;
export const ScrollContainer = styled.div `
  flex: 1;
  overflow-y: auto;
`;
export const ListContainer = styled.div `
  padding: ${themeGet('spacing.xl')};
`;
