import { Typography, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  align-items: ${({ $direction }) => $direction === 'right' && 'flex-end'};

  &:not(:last-child) {
    margin-bottom: ${themeGet('spacing.xl')};
  }
`;
export const BubblePrefix = styled(Typography) `
  margin-bottom: ${themeGet('spacing.sm')};
`;
