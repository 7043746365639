import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import client from 'app/domains/apollo';
import { ImportDocumentToCcsStatus } from 'shared/domains/apollo/generated/types';
import { useActiveDocument } from 'shared/domains/documents';
import { importDocumentToCcs } from './mutation';
import { useGetDocumentConvertedToCcs } from './useGetDocumentConvertedToCcs';
import { getDocumentConvertedToCcs } from './useGetDocumentConvertedToCcs/query';
import { useGetImportDocumentToCcsJob } from './useGetImportDocumentToCcsJob';
export const useImportDocumentToCcs = () => {
    const { isConvertedToCcs, loading } = useGetDocumentConvertedToCcs();
    const { startPollingJob, stopPollingJob } = useGetImportDocumentToCcsJob();
    const { id: documentId } = useActiveDocument();
    const [polling, setPolling] = useState(false);
    const [importError, setImportError] = useState(false);
    const isLoading = loading || polling;
    const [startImportDocumentToCcs] = useMutation(importDocumentToCcs);
    useEffect(() => {
        if (isConvertedToCcs) {
            return;
        }
        if (!isConvertedToCcs && !loading && !polling) {
            const handlePolling = (pollingStatus) => {
                if (pollingStatus === ImportDocumentToCcsStatus.Done) {
                    client.writeQuery({
                        query: getDocumentConvertedToCcs,
                        data: {
                            document: {
                                __typename: 'FileSystemDocument',
                                id: documentId,
                                isConvertedToCcs: true,
                            },
                        },
                    });
                    stopPollingJob();
                    setPolling(false);
                }
                if (pollingStatus === ImportDocumentToCcsStatus.Failed) {
                    stopPollingJob();
                    setPolling(false);
                    setImportError(true);
                }
            };
            startImportDocumentToCcs({
                variables: { documentId },
                onCompleted: data => {
                    setImportError(false);
                    startPollingJob(data.importDocumentToCcsAsync.id, handlePolling);
                },
            });
            setPolling(true);
        }
    }, [
        documentId,
        isConvertedToCcs,
        loading,
        polling,
        startImportDocumentToCcs,
        startPollingJob,
        stopPollingJob,
    ]);
    return { error: importError, loading: isLoading };
};
