const actions = new Map([
    [
        'owner',
        [
            'manage:settings',
            'read:company_referrer',
            'read:company_industry',
            'invite:user',
            'delete:invite',
            'delete:user',
            'create:team',
            'delete:team',
            'update:team',
            'invite:team_member',
            'update:team_member',
            'delete:team_member',
        ],
    ],
    ['member', []],
    ['admin', ['invite:user', 'delete:invite']],
]);
export function getUserRoleActions(role) {
    if (!role) {
        return [];
    }
    return actions.get(role);
}
