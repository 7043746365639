import { plural, t, select } from '@lingui/macro';
import { ListItem, Tooltip } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { TeamAvatarWithLabel } from 'app/domains/teams';
import { UserAvatarWithLabel } from 'app/domains/users';
import { StyledIconButton, IconContainer, AvatarContainer } from './styled';
export function AccessListItem(props) {
    const { id, name, roleName, numberOfTeamMembers = 0, type, onRemove, hasAccessToParentFolder, isSelf, } = props;
    const buttonTooltip = t({
        message: 'Revoke access',
        comment: 'Filesystem access drawer list item remove access button tooltip',
    });
    const teamSubtitle = t({
        message: plural(numberOfTeamMembers, {
            one: '# member',
            other: '# members',
        }),
        comment: 'Filesystem access drawer list item subtitle for teams',
    });
    const tooltipTitle = t({
        comment: 'info tooltip in access list for new folder/document: explanation why you can not remove access for current user/team',
        message: select(type, {
            user: `You can not remove access for this user because they have access to a parent folder`,
            team: `You can not remove access for this team because they have access to a parent folder`,
            other: `You can not remove access for this user/team because they have access to a parent folder`,
        }),
    });
    return (<ListItem>
      <AvatarContainer>
        {type === 'user' && (<UserAvatarWithLabel size="md" title={name !== null && name !== void 0 ? name : undefined} subtitle={roleName}/>)}
        {type === 'team' && (<TeamAvatarWithLabel size="md" title={name !== null && name !== void 0 ? name : undefined} subtitle={teamSubtitle}/>)}
      </AvatarContainer>
      {hasAccessToParentFolder && !isSelf && (<Tooltip title={tooltipTitle}>
          <IconContainer>
            <Falcon icon="info-circle"/>
          </IconContainer>
        </Tooltip>)}
      {!hasAccessToParentFolder && onRemove && !isSelf && (<Tooltip title={buttonTooltip}>
          <StyledIconButton appearance="ghost" $round size="small" onClick={() => onRemove(id)}>
            <Falcon icon="xmark"/>
          </StyledIconButton>
        </Tooltip>)}
    </ListItem>);
}
