import { useQuery } from '@apollo/client';
import { t } from '@lingui/macro';
import { Skeleton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useActiveDocument } from 'shared/domains/documents';
import { DocumentStatusChipDocument } from './DocumentStatusChip.gql';
import { DocumentStatusChip } from '../../DocumentStatusChip';
export function StatusChip() {
    const { id } = useActiveDocument();
    const { data, loading, error } = useQuery(DocumentStatusChipDocument, {
        variables: {
            documentId: id,
        },
    });
    const { status, type } = (data === null || data === void 0 ? void 0 : data.fileSystemDocument) || {};
    if (error) {
        return null;
    }
    return (<Skeleton loading={loading}>
      <DocumentStatusChip label={t({
            message: 'Document Status',
            comment: 'Document header chips: Label for document status',
        })} icon={<Falcon icon="plus"/>} documentId={id} status={status} type={type}/>
    </Skeleton>);
}
