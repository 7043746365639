import { Fragment } from 'react';
import { useEsigning } from 'app/domains/esigning/components/EsigningProvider';
import { useEsigningProviderMetadata } from 'app/domains/esigning/hooks/useEsigningProviderMetadata';
import { ErrorAlert } from './ErrorAlert';
import { Parties } from './Parties';
import { SendButton } from './SendButton';
import { Settings } from './Settings';
import { ESignCreatingState } from '../ESignCreatingState';
import { ESignTabs } from '../ESignTabs';
import { SectionContent, SectionFooter } from '../components';
import { AlertsContainer } from './styled';
export function Form() {
    const { submittingCreation } = useEsigning();
    const { properties: providerProperties } = useEsigningProviderMetadata();
    const { canUseSigningOrder, canUseEsigningMessages } = providerProperties;
    const isSettingsAvailable = canUseSigningOrder || canUseEsigningMessages;
    return (<Fragment>
      {submittingCreation && <ESignCreatingState overlay polling={false}/>}
      <SectionContent>
        <AlertsContainer>
          <ErrorAlert />
        </AlertsContainer>
        <ESignTabs parties={<Parties />} settings={isSettingsAvailable && <Settings />}/>
      </SectionContent>
      <SectionFooter>
        <SendButton />
      </SectionFooter>
    </Fragment>);
}
