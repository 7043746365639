import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
import { WidgetBody } from 'app/domains/tasks/components/styled';
export const Container = styled.div `
  padding: ${themeGet('spacing.xl')};
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden auto;
`;
export const StyledWidgetBody = styled(WidgetBody) `
  max-height: ${pxToRem(300)};
`;
