import { Select } from '@pocketlaw/tetris';
import { DesktopTableBodyCell, SelectContainer } from '../styled';
export function RoleCell(props) {
    const { role } = props;
    return (<DesktopTableBodyCell headers="role">
      <SelectContainer>
        <Select size="small" value={role} disabled>
          <option value={role}>{role}</option>
        </Select>
      </SelectContainer>
    </DesktopTableBodyCell>);
}
