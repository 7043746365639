import { t } from '@lingui/macro';
import { Table, TableHead, TableRow, TableHeaderText, TableBody, TableHeadCell, TableBodyCell, Typography, } from '@pocketlaw/tetris';
import { useField } from 'formik';
export function SummarizeEmailTable() {
    const [field] = useField('email');
    return (<Table border>
      <TableHead>
        <TableRow>
          <TableHeadCell id="email">
            <TableHeaderText>
              {t({ message: 'Email', comment: 'user invite modal - summarize email title' })}
            </TableHeaderText>
          </TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableBodyCell headers="email">
            <Typography $appearance="300">{field.value}</Typography>
          </TableBodyCell>
        </TableRow>
      </TableBody>
    </Table>);
}
