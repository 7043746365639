import { t } from '@lingui/macro';
import { isValid, isFuture, differenceInSeconds, differenceInHours, differenceInDays, differenceInWeeks, differenceInMonths, differenceInYears, differenceInMinutes, } from 'date-fns';
import { localeConfig } from 'shared/domains/languages/config';
import * as Sentry from 'shared/domains/sentry';
export const getUnit = (timeStamp) => {
    const now = new Date();
    const past = new Date(timeStamp);
    const unitMap = [
        { unit: 'years', method: differenceInYears },
        { unit: 'months', method: differenceInMonths },
        { unit: 'weeks', method: differenceInWeeks },
        { unit: 'days', method: differenceInDays },
        { unit: 'hours', method: differenceInHours },
        { unit: 'minutes', method: differenceInMinutes },
        { unit: 'seconds', method: differenceInSeconds },
    ];
    const response = ['seconds', 0];
    unitMap.find(({ unit, method }) => {
        const value = method(now, past);
        if (value) {
            response.splice(0, 2, unit, value);
            return true;
        }
        return false;
    });
    return response;
};
export const localizedDistanceToNow = (str) => {
    const date = new Date(str);
    const valid = isValid(date);
    const futureDate = isFuture(date);
    try {
        if (futureDate) {
            return null;
        }
        if (!valid) {
            throw new Error('invalid date');
        }
        const localeCode = localeConfig.getActiveLocale().code;
        const [unit, diff] = getUnit(str);
        const relativeTimeFormat = new Intl.RelativeTimeFormat(localeCode, { style: 'long' });
        if (diff === 0) {
            return t `just now`;
        }
        return relativeTimeFormat.format(-diff, unit);
    }
    catch (error) {
        Sentry.captureException(error, {
            extra: {
                futureDate,
                date,
                valid,
                param: str,
            },
        });
        return undefined;
    }
};
