import { padding, marginLeft, pxToRem, themeGet } from '@pocketlaw/tetris';
import { breakpoint } from '@pocketlaw/tetris/deprecated';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
export const StyledLink = styled(Link) `
  ${padding(0, 6)};
  height: 100%;
  flex-shrink: 0;
  border-right: ${pxToRem(1)} solid ${themeGet('colors.gray.100')};
  border-radius: 0;

  @media ${breakpoint.palm} {
    padding: 0;
    height: ${pxToRem(60)};
    width: ${pxToRem(60)};
  }
`;
export const Text = styled.span `
  ${marginLeft(2)};

  @media ${breakpoint.lapAndUp} {
    display: inline-flex;
  }
`;
