import gql from 'graphql-tag';
export const AutoTagEntryFragment = gql `
  fragment AutoTagEntryFragment on DocumentMetadataAiAutoTaggingQueueEntry {
    documentId
    status
    attemptCount
    fileSystemDocument {
      id
      name
    }
  }
`;
