import { AvatarWithLabel } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { EmptyContainer } from './styled';
export function Empty(props) {
    const { title, subtitle } = props;
    return (<EmptyContainer>
      <AvatarWithLabel title={title} subtitle={subtitle} size="md" alignment="vertical">
        <Falcon icon="inbox"/>
      </AvatarWithLabel>
    </EmptyContainer>);
}
