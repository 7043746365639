import { Avatar } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { SearchEntityTypeEnum, EntryKind } from 'shared/domains/apollo/generated/types';
export function EntryIcon(props) {
    const { type } = props;
    switch (type) {
        case SearchEntityTypeEnum.Folder:
        case EntryKind.Folder:
            return (<Avatar size="sm" appearance="success">
          <Falcon icon="folder"/>
        </Avatar>);
        case SearchEntityTypeEnum.DocumentUploaded:
        case SearchEntityTypeEnum.DocumentComposed:
        case SearchEntityTypeEnum.DocumentFreetext:
        case EntryKind.Document:
            return (<Avatar size="sm">
          <Falcon icon="file"/>
        </Avatar>);
        default:
            return (<Avatar size="sm">
          <Falcon icon="file-circle-question"/>
        </Avatar>);
    }
}
