var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@apollo/client';
import { useCompanyFeatures } from 'app/domains/companies';
import { GetDefaultSignatoriesQuery } from './query';
export const useDefaultSignatories = (documentId) => {
    var _a, _b, _c, _d;
    const { isFeatureAvailable } = useCompanyFeatures();
    const skip = !isFeatureAvailable('default-signatories');
    const _e = useQuery(GetDefaultSignatoriesQuery, { variables: { documentId }, skip }), { data } = _e, rest = __rest(_e, ["data"]);
    const signatories = ((_b = (_a = data === null || data === void 0 ? void 0 : data.fileSystemDocument) === null || _a === void 0 ? void 0 : _a.defaultSignatories) === null || _b === void 0 ? void 0 : _b.signatories) || [];
    const ccRecipients = ((_d = (_c = data === null || data === void 0 ? void 0 : data.fileSystemDocument) === null || _c === void 0 ? void 0 : _c.defaultSignatories) === null || _d === void 0 ? void 0 : _d.ccRecipients) || [];
    const defaultSignatories = { signatories, ccRecipients };
    return Object.assign({ defaultSignatories }, rest);
};
