import { Falcon } from '@pocketlaw/tetris/macro';
import { isAfter } from 'date-fns';
export function isOverdue(options) {
    const { date, completed } = options;
    if (date && !completed) {
        const today = new Date().toISOString();
        const compareDate = new Date(date).toISOString();
        if (isAfter(today, compareDate)) {
            return {
                icon: <Falcon icon="clock"/>,
                appearance: 'danger',
            };
        }
    }
    return undefined;
}
