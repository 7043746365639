import JsonURL from '@jsonurl/jsonurl';
export function constructSearchURL(data, path = 'all-documents') {
    const { where } = data;
    const stringifiedFilters = JsonURL.stringify(where, { AQF: true });
    if (!stringifiedFilters) {
        return path;
    }
    const url = new URLSearchParams();
    url.set('filters', stringifiedFilters);
    const stringifiedUrl = url.toString();
    return `/${path}?${stringifiedUrl}`;
}
