import { t } from '@lingui/macro';
import { Alert } from '@pocketlaw/tetris';
import { ErrorContainer } from './styled';
export function PleditorError() {
    return (<ErrorContainer>
      <Alert appearance="danger" title={t({
            comment: 'Pleditor document loading error title',
            message: 'Could not load the document',
        })} message={t({
            comment: 'Pleditor document loading error message',
            message: 'Something went wrong while loading the document, please try again',
        })}/>
    </ErrorContainer>);
}
