import { scrollbar, themeGet } from '@pocketlaw/tetris';
import styled, { css } from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;
export const ChatContainer = styled.div `
  ${scrollbar()}

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
  padding: ${themeGet('spacing.xl')};
  padding-bottom: 0;
  margin-right: ${themeGet('spacing.sm')};

  & * {
    overflow-anchor: none;
  }
`;
// https://css-tricks.com/books/greatest-css-tricks/pin-scrolling-to-bottom/
// eslint-disable-next-line no-restricted-syntax
export const ScrollAnchor = styled.div `
  overflow-anchor: auto;
  flex-shrink: 0;
  height: 1px;
`;
export const Thread = styled.div `
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: ${themeGet('spacing.2xl')};
`;
// Shared styled components
export const Message = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${themeGet('spacing.lg')};

  ${({ $direction }) => $direction === 'right' &&
    css `
      align-items: flex-end;
      text-align: right;
    `};
`;
