var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import apolloClient from 'app/domains/apollo';
import { docxToPdfMutation } from './mutation';
import { docxToPdfDocumentData } from './query';
import { getCompanyId } from '../getCompanyId';
export function renderDocxToPdf(options) {
    return __awaiter(this, void 0, void 0, function* () {
        const { documentId, name } = options;
        const companyId = yield getCompanyId();
        const { data } = yield apolloClient.query({
            query: docxToPdfDocumentData,
            variables: {
                documentId,
            },
        });
        const { fileSystemDocument } = data || {};
        const [file] = (fileSystemDocument === null || fileSystemDocument === void 0 ? void 0 : fileSystemDocument.files) || [];
        const render = yield apolloClient.mutate({
            mutation: docxToPdfMutation,
            variables: {
                companyId,
                name,
                mimeType: file.mimeType,
                path: file.path,
                size: file.size,
            },
        });
        const { files } = render.data || {};
        const [renderedFile] = files || [];
        const { signed } = renderedFile || {};
        if (!(signed === null || signed === void 0 ? void 0 : signed.url)) {
            throw Error('No signed url returned from API');
        }
        return signed.url;
    });
}
