import { createContext, useState } from 'react';
export const ComposersTableContext = createContext(null);
export function ComposersTableProvider(props) {
    const { children, composers } = props;
    const [drawerData, setDrawerData] = useState(null);
    const [selectedComposerIds, setSelectedComposerIds] = useState([]);
    const unlockedComposers = composers.filter(({ locked }) => !locked);
    const isComposerSelected = (id) => selectedComposerIds.includes(id);
    const onSetDrawer = (data) => setDrawerData(data);
    const onSelectComposer = (composerId) => {
        if (selectedComposerIds.includes(composerId)) {
            setSelectedComposerIds(selectedComposerIds.filter(id => id !== composerId));
        }
        else {
            setSelectedComposerIds([...selectedComposerIds, composerId]);
        }
    };
    const onSelectAllComposers = () => {
        if (selectedComposerIds.length < unlockedComposers.length) {
            setSelectedComposerIds(unlockedComposers.map(({ id }) => id));
        }
        else {
            setSelectedComposerIds([]);
        }
    };
    const value = {
        allComposersAmount: unlockedComposers.length,
        drawerData,
        selectedComposerIds,
        isComposerSelected,
        onSelectComposer,
        onSelectAllComposers,
        onSetDrawer,
    };
    return <ComposersTableContext.Provider value={value}>{children}</ComposersTableContext.Provider>;
}
