import { BubblePrefix, Container } from './styled';
/**
 * A block with the comment prefix where you can set the direction
 */
export function CommentBlock(props) {
    const { direction, title, children } = props;
    return (<Container $direction={direction}>
      {title && (<BubblePrefix $fontSize="small" $appearance="200" $fontWeight="medium">
          {title}
        </BubblePrefix>)}
      {children}
    </Container>);
}
