export var TasksIds;
(function (TasksIds) {
    TasksIds["completeTask"] = "complete_task";
    TasksIds["title"] = "title";
    TasksIds["status"] = "status";
    TasksIds["dueDate"] = "due_date";
    TasksIds["assignee"] = "assignee";
    TasksIds["dropdownMenu"] = "dropdown_menu";
    TasksIds["createdAt"] = "created_at";
    TasksIds["updatedAt"] = "updated_at";
})(TasksIds || (TasksIds = {}));
export var SortIds;
(function (SortIds) {
    SortIds["title"] = "title";
    SortIds["createdAt"] = "created_at";
    SortIds["updatedAt"] = "updated_at";
    SortIds["dueDate"] = "due_date";
    SortIds["status"] = "status";
})(SortIds || (SortIds = {}));
export var TabState;
(function (TabState) {
    TabState["UNCOMPLETED"] = "uncompleted";
    TabState["COMPLETED"] = "completed";
})(TabState || (TabState = {}));
