import { t, Trans } from '@lingui/macro';
import { Button, Dialog, DialogHeader, DialogContent, DialogFooter, Grid, Column, useToast, Loader, Typography, Avatar, Alert, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useMoveEntry } from 'app/domains/filesystem';
import * as Sentry from 'shared/domains/sentry';
export function QuickMoveDialog(props) {
    const { type, payload, onClose } = props;
    const { sourceId, sourceName, targetId, targetName } = payload;
    const [move, mutation] = useMoveEntry({ kind: type, id: sourceId });
    const { addToast } = useToast();
    const handleClick = () => {
        move(targetId)
            .then(() => {
            addToast({ title: t `Document moved`, appearance: 'success' });
            onClose();
        })
            .catch(error => {
            Sentry.captureException(error);
        });
    };
    if (sourceId === targetId) {
        // Can happen if you move a folder onto itself, no error is expected, just do nothing
        return null;
    }
    return (<Dialog size="sm" onClose={mutation.loading ? undefined : onClose}>
      <DialogHeader title={<Trans comment="Drag-and-drop move file dialog: title of dialog">
            Move: {sourceName}
          </Trans>} media={<Avatar shape="square">
            <Falcon icon="arrow-circle-right"/>
          </Avatar>}/>
      <DialogContent>
        <Grid directions="vertical" spacing={2}>
          <Column width="100%">
            <Typography>
              <Trans comment="Drag-and-drop move file dialog: content of dialog">
                Do you want to move {sourceName} to {targetName}?
              </Trans>
            </Typography>
          </Column>
          {mutation.error ? (<Column width="100%">
              <Alert appearance="danger" message={t({
                message: 'Something went wrong, please try again later',
                comment: 'Drag-and-drop move file dialog: message when an unexpected error happens',
            })}/>
            </Column>) : null}
        </Grid>
      </DialogContent>
      <DialogFooter>
        <Button type="button" appearance="secondary" disabled={mutation.loading} onClick={onClose}>
          <Trans comment="Drag-and-drop move file dialog: cancel button text">Cancel</Trans>
        </Button>
        <Button onClick={handleClick} disabled={mutation.loading}>
          <Trans comment="Drag-and-drop move file dialog: move button text">Move</Trans>
          {mutation.loading && <Loader />}
        </Button>
      </DialogFooter>
    </Dialog>);
}
