import { Avatar, AvatarWithLabel, Radio } from '@pocketlaw/tetris';
import { useMount } from 'react-use';
import Flag from 'shared/domains/languages/components/Flag';
import { useDraftPreview } from '../../../DraftPreviewProvider';
import { Container, LanguageButton } from './styled';
export function SelectLanguage() {
    const { templates, selectedTemplateId, selectTemplate } = useDraftPreview();
    useMount(() => {
        if (templates.length > 0) {
            selectTemplate(templates[0].id);
        }
    });
    return (<Container>
      {templates.map(({ id, name, languageCode }) => (<LanguageButton key={id} $selected={selectedTemplateId === id} onClick={() => selectTemplate(id)}>
          <AvatarWithLabel size="sm" title={name}>
            <Avatar>
              <Flag languageCode={languageCode}/>
            </Avatar>
          </AvatarWithLabel>
          <Radio readOnly checked={selectedTemplateId === id}/>
        </LanguageButton>))}
    </Container>);
}
