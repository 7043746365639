import { defineMessage } from '@lingui/macro';
import { roles } from './roles';
const unknownRole = defineMessage({
    message: 'Unknown',
    comment: 'Name of unknown user role (may happen if there is an error somewhere)',
});
export function getUserRoleName(params) {
    var _a;
    const { role, canManageMember } = params;
    const roleName = (_a = roles.find(({ value }) => {
        if (role === 'owner') {
            return value === 'owner';
        }
        if (role === 'member' && canManageMember) {
            return value === 'admin';
        }
        return value === 'member';
    })) === null || _a === void 0 ? void 0 : _a.label;
    return roleName !== null && roleName !== void 0 ? roleName : unknownRole;
}
