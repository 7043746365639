import { gql } from '@apollo/client';
export const uploadAttachmentMutation = gql `
  mutation uploadAttachment($path: String!, $mimeType: String, $filename: String) {
    signed: signUploadUrl(
      input: { path: $path, entityType: ATTACHMENT, mimeType: $mimeType, filename: $filename }
    ) {
      url
      fields
    }
  }
`;
