import { gql } from '@apollo/client';
export const QUERY = gql `
  query GetApproversInDocumentSettings($id: ID!) {
    document: fileSystemDocument(id: $id) {
      id
      approval {
        id
        type
        approvers {
          id
          approvalOrder
          companyUser {
            id
            user {
              id
              name
            }
          }
        }
      }
    }
  }
`;
