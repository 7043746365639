/**
 * Enum representing the context the assistant is using to provide an answer.
 */
export var AssistantContext;
(function (AssistantContext) {
    AssistantContext["Document"] = "DOCUMENT";
    AssistantContext["Selection"] = "SELECTION";
})(AssistantContext || (AssistantContext = {}));
export var AssistantViewType;
(function (AssistantViewType) {
    AssistantViewType["Chat"] = "CHAT";
    AssistantViewType["Dashboard"] = "DASHBOARD";
    AssistantViewType["Conversations"] = "CONVERSATIONS";
    AssistantViewType["Suggestions"] = "SUGGESTIONS";
})(AssistantViewType || (AssistantViewType = {}));
