import { useMutation } from '@apollo/client';
import { useLastApprovalRequest } from 'app/domains/esigning/hooks/useLastApprovalRequest';
import { useActiveDocument } from 'shared/domains/documents';
import { sendReminderToApprovers } from './mutation';
export const useSendReminderToApprovers = () => {
    const { id: documentId } = useActiveDocument();
    const { lastApprovalRequest } = useLastApprovalRequest(documentId);
    const { id } = lastApprovalRequest || {};
    const approvalRequestId = id !== null && id !== void 0 ? id : '';
    const [mutate, result] = useMutation(sendReminderToApprovers, {
        variables: {
            input: { approvalRequestId },
        },
    });
    const sendReminder = () => mutate();
    return Object.assign({ sendReminder }, result);
};
