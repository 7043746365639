import { Table, TableRow } from '@pocketlaw/tetris';
import styled, { css } from 'styled-components';
export const StyledTable = styled(Table) `
  table-layout: fixed;
`;
export const StyledTableRow = styled(TableRow) `
  ${props => props.$hoverCursor && 'cursor: pointer;'};
  ${props => props.$disabled &&
    css `
      opacity: 0.5;
      cursor: default;
      pointer-events: none;
    `};
`;
export const StyledButton = styled.button `
  display: flex;
  cursor: inherit;
`;
