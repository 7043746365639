import { useId } from 'react';
import { t } from '@lingui/macro';
import { IconButton, LoaderOverlay } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useFileSystemAcls, AccessList } from 'app/domains/filesystem';
import { useFileSystemAccessDrawer } from './useFileSystemAccessDrawer';
import { StyledDrawer, DrawerHeader, StyledAvatarWithLabel } from './styled';
export function FileSystemAccessDrawer() {
    const { open, entryId, setEntryId } = useFileSystemAccessDrawer();
    const { entryName, loading } = useFileSystemAcls({ entryId });
    const drawerId = useId();
    return (<StyledDrawer open={open} onClose={() => setEntryId(undefined)} position="right" id={drawerId}>
      <LoaderOverlay enabled={loading}>
        <DrawerHeader>
          <StyledAvatarWithLabel title={t({ message: 'Manage access', comment: 'Filesystem access drawer title' })} subtitle={entryName} appearance="secondary">
            <Falcon icon="lock"/>
          </StyledAvatarWithLabel>
          <IconButton appearance="ghost" $round onClick={() => setEntryId(undefined)} aria-expanded={open} aria-controls={drawerId} data-testid="close-access-drawer">
            <Falcon icon="xmark"/>
          </IconButton>
        </DrawerHeader>
        <AccessList entryId={entryId}/>
      </LoaderOverlay>
    </StyledDrawer>);
}
