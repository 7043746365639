import { t } from '@lingui/macro';
import { useLastSignRequest } from 'app/domains/esigning/hooks/useLastSignRequest';
import { useRecipientsByStatus } from 'app/domains/esigning/hooks/useRecipientsByStatus';
import { ESignPartyItem } from '../../../ESignPartyItem';
import { SectionList } from '../../../components';
export function PendingNonSigningParties() {
    const { lastSignRequest } = useLastSignRequest();
    const { message: groupMessage } = lastSignRequest || {};
    const { cc: nonSigningParties } = useRecipientsByStatus();
    if (nonSigningParties.length === 0) {
        return null;
    }
    return (<SectionList hideTooManyItems title={t({
            message: 'Non signing parties',
            comment: 'Label for non signing parties list',
        })}>
      {nonSigningParties.map(party => (<ESignPartyItem hideAvatar key={party.id} groupMessage={groupMessage} signatory={Object.assign(Object.assign({}, party), { isSigning: false })}/>))}
    </SectionList>);
}
