import { unit } from '@pocketlaw/tetris';
import { breakpoint } from '@pocketlaw/tetris/deprecated';
import styled from 'styled-components';
export const Actions = styled.div `
  display: flex;
  align-items: center;
  gap: ${unit(2)};

  @media ${breakpoint.palm} {
    width: 100%;
    flex-wrap: wrap;
  }
`;
