import { themeGet, unit } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const StatusContainer = styled.div `
  display: flex;
  align-items: center;
  gap: ${unit('md')};

  [data-icon='falcon'] {
    color: ${themeGet('colors.gray.600')};
  }
`;
