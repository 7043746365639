import { t } from '@lingui/macro';
export const getTextByStatus = (status) => {
    const resolveMessage = t `You can try to reload the page, or contact us if the issue persists.`;
    switch (status) {
        case 'COMPANY_NOT_CONNECTED_TO_PROVIDER':
            return t `There is an issue with the e-signing provider. Please, verify you are connected to a provider. Contact us if the issue persists.`;
        case 'DOCUMENT_DOES_NOT_EXIST':
            return `${t `The document does not seem to exist.`} ${resolveMessage}`;
        case 'RESOURCE_NOT_FOUND':
            return `${t `Could not find the signatory.`} ${resolveMessage}`;
        case 'UNEXPECTED_ERROR':
        default:
            return `${t `Something went wrong.`} ${resolveMessage}`;
    }
};
