import { t } from '@lingui/macro';
import { ButtonView, Plugin, AttachmentStore, InsertSelectAttachmentWidgetCommand, InsertAttachmentWidgetEditing, Users, } from 'pleditor';
import { Paperclip } from './icon';
export class InsertAttachmentWidgetUI extends Plugin {
    static get pluginName() {
        return 'InsertAttachmentWidgetUI';
    }
    static get requires() {
        return [AttachmentStore, InsertAttachmentWidgetEditing, Users];
    }
    constructor(editor) {
        super(editor);
        this.createButton = () => {
            const { button, editor } = this;
            button.set({
                withText: true,
                label: t({ comment: 'Insert attachment into document label', message: 'Insert attachment' }),
                class: 'pleditor-toolbar-fa-icon-button',
                icon: Paperclip,
            });
            const { me: currentUser } = editor.plugins.get(Users);
            button.on('execute', () => {
                editor.commands.execute(InsertSelectAttachmentWidgetCommand.pluginName, currentUser === null || currentUser === void 0 ? void 0 : currentUser.id);
            });
        };
        this.addToFactory = () => {
            this.editor.ui.componentFactory.add('selectAttachmentWidget', () => this.button);
        };
        this.setupListeners = () => {
            const { editor, button } = this;
            const store = editor.plugins.get(AttachmentStore);
            const command = editor.commands.get(InsertSelectAttachmentWidgetCommand.pluginName);
            if (command) {
                button
                    .bind('isEnabled')
                    .to(store, 'attachments', command, 'isEnabled', (attachments, isEnabled) => attachments.length > 0 && isEnabled);
            }
        };
        this.button = new ButtonView();
    }
    init() {
        this.createButton();
        this.addToFactory();
        this.setupListeners();
    }
}
