var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { forwardRef, useCallback } from 'react';
import { t, Trans } from '@lingui/macro';
import { Column } from '@pocketlaw/tetris';
import Action from '../Action';
const CreatePrefilledDraftButton = forwardRef((props, forwardedRef) => {
    const { outdated, onCreateDraft } = props, restProps = __rest(props, ["outdated", "onCreateDraft"]);
    const handleCreateDraft = useCallback(() => {
        onCreateDraft({ withResult: true });
    }, [onCreateDraft]);
    return (<Column width="100%">
        <Action tooltip={outdated
            ? t `The contract template has been updated and the data can therefore not be copied.`
            : t `Choose this option to save the data you have entered in the document. It's possible to change data later.`} buttonText={t `Keep data`} ref={forwardedRef} onClick={handleCreateDraft} {...restProps}>
          <Trans>Keep data</Trans>
        </Action>
      </Column>);
});
export default CreatePrefilledDraftButton;
