import { themeGet } from '@pocketlaw/tetris';
import { css } from 'styled-components';
const olStartAttributeSelectors = Array.from(Array(20).keys())
    .map(i => `ol[start='${i}'] { counter-set: item ${i - 1} }`)
    .join(',');
export const listStyles = css `
  .ck :is(ul, ol, li, menu, dl, dd) {
    margin: revert;
    padding: revert;
  }

  .ck.ck-content {
    ${olStartAttributeSelectors};

    ol {
      counter-reset: item;
      padding: 0;
      margin-left: ${themeGet('spacing.5xl')};

      & > li {
        list-style-type: decimal;
        counter-increment: item;

        &::marker {
          content: counters(item, '.') '. ';
        }
      }

      li {
        margin-top: ${themeGet('spacing.xl')};

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        span {
          margin: 0;
          line-height: normal;
        }

        & > h1,
        & > h2,
        & > h3,
        & > h4,
        & > h5,
        & > h6,
        & > p,
        & > span {
          margin-top: ${themeGet('spacing.xl')};
        }
      }

      table ol {
        counter-reset: nestedItem;

        > li {
          &:before {
            content: counters(nestedItem, '.') '.';
            counter-increment: nestedItem;
          }
        }
      }
    }
  }
`;
