import { Chip } from '@pocketlaw/tetris';
import { getAppearanceByStatus } from './getAppearanceByStatus';
import { getLabelByStatus } from './getLabelByStatus';
export function StatusBadge(props) {
    const { status } = props;
    if (!status) {
        return null;
    }
    const label = getLabelByStatus(status);
    const appearance = getAppearanceByStatus(status);
    if (!label) {
        return null;
    }
    return <Chip size="small" appearance={appearance} label={label}/>;
}
