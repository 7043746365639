import { AclAction } from 'shared/domains/apollo/generated/types';
export function useNormalizeTeam(params) {
    const { teamAcls, parentTeamAcls } = params;
    const normalizeTeam = (team) => {
        var _a;
        const teamAcl = teamAcls.find(acl => acl.teamId === team.id);
        const numberOfTeamMembers = ((_a = team.members) === null || _a === void 0 ? void 0 : _a.length) || 0;
        return {
            id: team.id,
            name: team.name,
            numberOfTeamMembers,
            aclId: teamAcl === null || teamAcl === void 0 ? void 0 : teamAcl.id,
            hasAccessToParentFolder: parentTeamAcls.some(acl => acl.teamId === team.id && !acl.actions.includes(AclAction.Traverse)),
            hasTraverseAccess: teamAcl === null || teamAcl === void 0 ? void 0 : teamAcl.actions.includes(AclAction.Traverse),
        };
    };
    return { normalizeTeam };
}
