import { useQuery } from '@apollo/client';
import { getUserRoleName } from 'app/domains/users';
import { teamMembersQuery } from './teamMembersQuery';
export function useTeamMembers(teamId) {
    var _a, _b;
    const { data, loading, error } = useQuery(teamMembersQuery, {
        variables: {
            teamId,
        },
    });
    const teamMembers = (_b = (_a = data === null || data === void 0 ? void 0 : data.team) === null || _a === void 0 ? void 0 : _a.members) !== null && _b !== void 0 ? _b : [];
    const formattedTeamMembers = teamMembers.map(member => {
        var _a, _b, _c, _d;
        return ({
            id: member.id,
            userId: member.userId,
            userRoleName: getUserRoleName({
                role: (_a = member.companyUser) === null || _a === void 0 ? void 0 : _a.role,
                canManageMember: (_b = member.companyUser) === null || _b === void 0 ? void 0 : _b.canManageMember,
            }),
            teamRole: member.role,
            name: (_c = member.user) === null || _c === void 0 ? void 0 : _c.name,
            email: (_d = member.user) === null || _d === void 0 ? void 0 : _d.email,
        });
    });
    return { teamMembers: formattedTeamMembers, loading, error };
}
