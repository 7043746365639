var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Form, Formik } from 'formik';
import { defaultColumnConfig } from './config/defaultColumnConfig';
import { getActiveColumns } from './getActiveColumns';
import { sortColumns } from './sortColumns';
import { validateOrderBy } from './validateOrderBy';
export function SearchProvider(props) {
    const { children, referrer, userColumns = [], columns, filters, query = '', orderBy, offset = 0, limit = 20, showDirty = false, onSubmit, } = props;
    const columnsToSort = columns || defaultColumnConfig;
    const sortedColumns = sortColumns(columnsToSort, userColumns);
    const activeColumns = getActiveColumns(sortedColumns, userColumns);
    const initialValues = {
        referrer,
        query,
        filters,
        columns: activeColumns,
        orderBy: validateOrderBy(orderBy),
        offset,
        limit,
    };
    const handleSubmit = (values, helpers) => __awaiter(this, void 0, void 0, function* () {
        if (onSubmit) {
            return onSubmit(values, helpers);
        }
        return values;
    });
    return (<Formik initialValues={initialValues} onSubmit={handleSubmit} initialStatus={{ showDirty }}>
      <Form>{children}</Form>
    </Formik>);
}
