import { t } from '@lingui/macro';
import { Plugin, Range } from 'pleditor';
export class ImportWordRTC extends Plugin {
    static get pluginName() {
        return 'ImportWordRTC';
    }
    init() {
        this.editor.on('ready', () => this.setupImportListeners(), { priority: 'low' });
    }
    setupImportListeners() {
        const importWordCommand = this.editor.commands.get('importWord');
        if (importWordCommand) {
            importWordCommand.on('dataInsert', () => this.beforeImport(), { priority: 'highest' });
            importWordCommand.on('dataInsert', () => this.afterImport(), { priority: 'lowest' });
        }
    }
    beforeImport() {
        const { editor } = this;
        const root = editor.model.document.getRoot();
        const revisionTrackerPlugin = editor.plugins.get('RevisionTracker');
        if (!root) {
            return;
        }
        editor.model.change(writer => {
            const range = writer.createRangeIn(root);
            const startRange = editor.model.schema.getNearestSelectionRange(range.start);
            const endRange = editor.model.schema.getNearestSelectionRange(range.end);
            if (!startRange || !endRange) {
                return;
            }
            writer.setSelection(new Range(startRange.start, endRange.end));
            writer.model.deleteContent(writer.model.document.selection);
        });
        revisionTrackerPlugin.update();
        revisionTrackerPlugin.saveRevision({
            name: t({
                comment: 'Pleditor revision history version name after document import',
                message: 'Document import',
            }),
        });
    }
    afterImport() {
        const notificationPlugin = this.editor.plugins.get('Notification');
        notificationPlugin.showSuccess(t({
            comment: 'Pleditor - Import document success message',
            message: 'Successfully imported document',
        }));
    }
}
