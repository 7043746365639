import { SignRequestStatus } from 'shared/domains/apollo/generated/types';
export const getAppearanceByStatus = (status) => {
    switch (status) {
        case SignRequestStatus.Pending:
        case SignRequestStatus.Opened:
            return 'primary';
        case SignRequestStatus.Signed:
            return 'success';
        default:
            return undefined;
    }
};
