import { useGetDistinctValues } from './useGetDistinctValues';
import { withFilterErrorBoundary } from '../FilterErrorBoundary';
import { StringArrayFilter } from '../StringArrayFilter';
/**
 * Generic filter component that will return a searchable list of all distinct values of the given metadata property.
 */
export const DistinctPropertyValueFilter = withFilterErrorBoundary((props) => {
    const { filterKey, label, propertyName } = props;
    const { data, error, loading } = useGetDistinctValues(propertyName);
    const disabled = Boolean(loading || error);
    return <StringArrayFilter name={filterKey} items={data} label={label} loading={disabled}/>;
});
