import { useMount } from 'react-use';
import { useDocumentDrawer } from 'shared/domains/documents';
import { Table } from './styled';
export function SelfExpandingTable(props) {
    const { expand } = useDocumentDrawer();
    useMount(() => {
        expand();
    });
    return <Table {...props}/>;
}
