import gql from 'graphql-tag';
export const QUERY = gql `
  query GetUsersAccess($id: ID!, $where: GetExternalSharingInvitesArgsWhere) {
    entry: fileSystemEntry(id: $id) {
      id
      acls {
        id
        userId
        actions
      }
    }

    companyUsers {
      id
      role
      user {
        id
      }
    }

    invites: externalSharingInvites(where: $where) {
      id
      invitee {
        id
      }
    }
  }
`;
