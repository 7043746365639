import { gql } from '@apollo/client';
export const documentSignRequestQuery = gql `
  query getDocumentSignRequest($documentId: ID!) {
    document: fileSystemDocument(id: $documentId) {
      id
      type
      files {
        id
        name
        path
        size
        companyId
        type: mimeType
      }
    }
  }
`;
