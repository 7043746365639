import { GridCell, GridRow } from '@pocketlaw/tetris';
import { ApprovalDocumentsWidget } from './ApprovalDocumentsWidget';
import { DocumentsCreatedWidget } from './DocumentsCreatedWidget';
import { EsignPendingDocumentsWidget } from './EsignPendingDocumentsWidget';
import { SharedDocumentsWidget } from './SharedDocumentsWidget';
export function DashboardMetrics() {
    return (<GridRow>
      <GridCell width={{ base: 12, sm: 6, lg: 3 }}>
        <DocumentsCreatedWidget />
      </GridCell>
      <GridCell width={{ base: 12, sm: 6, lg: 3 }}>
        <ApprovalDocumentsWidget />
      </GridCell>
      <GridCell width={{ base: 12, sm: 6, lg: 3 }}>
        <SharedDocumentsWidget />
      </GridCell>
      <GridCell width={{ base: 12, sm: 6, lg: 3 }}>
        <EsignPendingDocumentsWidget />
      </GridCell>
    </GridRow>);
}
