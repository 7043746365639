var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Trans, t } from '@lingui/macro';
import { ConfirmDialog, useToast } from '@pocketlaw/tetris';
import { useDeleteEntry } from 'app/domains/filesystem';
export function DeleteDocumentDialog(props) {
    const { cacheId, entityId, typename, onClose } = props;
    const { addToast } = useToast();
    const [deleteEntry, { loading }] = useDeleteEntry(entityId, {
        onUpdate(cache) {
            cache.modify({
                id: cache.identify({ id: cacheId, __typename: typename }),
                fields(_, { DELETE }) {
                    return DELETE;
                },
            });
        },
    });
    const handleConfirm = () => __awaiter(this, void 0, void 0, function* () {
        try {
            yield deleteEntry();
            onClose();
            addToast({
                appearance: 'success',
                title: t({
                    message: 'Document deleted',
                    comment: 'Delete document success toast',
                }),
            });
        }
        catch (_a) {
            addToast({
                appearance: 'danger',
                title: t({
                    message: 'Error occurred while deleting document',
                    comment: 'Delete document error toast',
                }),
            });
        }
    });
    return (<ConfirmDialog heading={<Trans comment="Title for delete document dialog (from document view and files)">
          Delete document
        </Trans>} appearance="danger" size="sm" loading={loading} confirm={<Trans comment="Delete document button label">Delete</Trans>} cancel={<Trans comment="Cancel button label">Cancel</Trans>} onConfirm={handleConfirm} onCancel={onClose}>
      <Trans comment="Warning message for delete document dialog">
        Are you sure you want to delete this document? This action is irreversible.
      </Trans>
    </ConfirmDialog>);
}
