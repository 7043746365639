import { pxToRem, themeGet, unit } from '@pocketlaw/tetris';
import styled, { css } from 'styled-components';
import { themeBoxStyles, themeContentStyles, themeSelectedStyles } from '../styled';
export const Container = styled.div `
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${unit('sm')};
`;
export const LightThemeBox = styled.button `
  ${themeBoxStyles};

  ${props => props.$themeType === 'light' &&
    css `
      border: solid ${pxToRem(1)} ${themeGet('colors.gray.100')};
      background: ${themeGet('colors.gray.50')};
    `};

  ${props => props.$themeType === 'dark' &&
    css `
      border: solid ${pxToRem(1)} ${themeGet('colors.gray.800')};
      background-color: ${themeGet('colors.gray.900')};
    `};
`;
export const LightThemeContent = styled.div `
  ${themeContentStyles};

  ${props => props.$themeType === 'light' &&
    css `
      color: ${themeGet('colors.gray.1000')};
      background-color: ${themeGet('colors.gray.0')};
    `};

  ${props => props.$themeType === 'dark' &&
    css `
      color: ${themeGet('colors.gray.0')};
      background-color: ${themeGet('colors.gray.1000')};
    `};
`;
export const LightThemeSelected = styled.div `
  ${themeSelectedStyles}

  color: ${themeGet('colors.gray.0')};
  background-color: ${themeGet('colors.gray.1000')};
`;
