export function getItemType(usersAndTeams) {
    const { users, teams } = usersAndTeams;
    if (teams.length > 0 && users.length === 0) {
        return 'teams';
    }
    if (users.length > 0 && teams.length === 0) {
        return 'users';
    }
    return 'usersAndTeams';
}
