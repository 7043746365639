import { Alignment, Autosave, Bold, CloudServices, Comments, CommentsArchive, EasyImage, Essentials, ExportWord, Heading, Image, ImageCaption, ImageResize, ImageStyle, ImageToolbar, ImageUpload, ImportWord, Indent, IndentBlock, Italic, Link, List, Notification, PageBreak, Paragraph, Permissions, RealTimeCollaborativeEditing, RealTimeCollaborativeComments, RealTimeCollaborativeRevisionHistory, RealTimeCollaborativeTrackChanges, RestrictedEditingMode, RevisionHistory, RevisionTracker, Strikethrough, Table, TrackChanges, Underline, ComposedHighlight, ListAttributesExtender, TableCellProperties, TableCaption, TableColumnResize, TableProperties, TableToolbar, FontBackgroundColor, FontColor, FontSize, BalloonToolbar, AdjacentListsSupport, AttachmentStore, AttachmentWidgetEditing, InsertAttachmentWidgetEditing, Assistant, HighlightPlugin, } from 'pleditor';
import { AppApiConnector } from '../plugins/AppApiConnector';
import { InsertAttachmentWidgetUI } from '../plugins/Attachments';
import { BlockComment } from '../plugins/BlockComment';
import { CommentsUi } from '../plugins/CommentsUi';
import { ComposedHighlightToolbar } from '../plugins/ComposedHighlightToolbar';
import { DisableTableDragDrop } from '../plugins/DisableTableDragDrop';
import { EditMode } from '../plugins/EditMode';
import { ExternalApiConnector } from '../plugins/ExternalApiConnector';
import { ExternalSuggestions } from '../plugins/ExternalSuggestions';
import { FlexGap } from '../plugins/FlexGap';
import { HandleDocumentFlushedPlugin } from '../plugins/HandleDocumentFlushedPlugin';
import { ImportWordRTC } from '../plugins/ImportWordRTC';
import { InlineComment } from '../plugins/InlineComment';
import { OffsetPlugin } from '../plugins/OffsetPlugin';
import { ReviewPlugin } from '../plugins/Review';
import { RevisionHistoryToolbarHelper } from '../plugins/RevisionHistoryToolbarHelper';
import { SessionUsers } from '../plugins/SessionUsers';
import { ToastHandler } from '../plugins/ToastHandler';
import { ToggleComments } from '../plugins/ToggleComments';
import { TopToolbar } from '../plugins/TopToolbar';
import { UserMentionsRTC } from '../plugins/UserMentionsRTC';
import { UsersManagerRTC } from '../plugins/UsersManagerRTC';
const BASE_PLUGINS = [
    // CKEditor
    Alignment,
    Autosave,
    BalloonToolbar,
    Bold,
    CloudServices,
    Comments,
    CommentsArchive,
    Essentials,
    FontSize,
    FontColor,
    FontBackgroundColor,
    Heading,
    Italic,
    Indent,
    IndentBlock,
    Link,
    List,
    AdjacentListsSupport,
    Notification,
    PageBreak,
    Paragraph,
    Permissions,
    RealTimeCollaborativeEditing,
    RealTimeCollaborativeComments,
    RealTimeCollaborativeTrackChanges,
    RealTimeCollaborativeRevisionHistory,
    RevisionHistory,
    RevisionTracker,
    TrackChanges,
    Strikethrough,
    Table,
    Underline,
    // Custom
    TopToolbar,
    FlexGap,
    CommentsUi,
    OffsetPlugin,
    ToastHandler,
    ToggleComments,
    SessionUsers,
    HandleDocumentFlushedPlugin,
    UserMentionsRTC,
    UsersManagerRTC,
    ComposedHighlight,
    ListAttributesExtender,
    AttachmentStore,
    AttachmentWidgetEditing,
    InsertAttachmentWidgetEditing,
    InsertAttachmentWidgetUI,
    HighlightPlugin,
];
// Composed
const BASE_COMPOSED_PLUGINS = [
    ...BASE_PLUGINS,
    BlockComment,
    ComposedHighlightToolbar,
    DisableTableDragDrop,
    RestrictedEditingMode,
];
export const APP_COMPOSED_PLUGINS = [
    AppApiConnector,
    ...BASE_COMPOSED_PLUGINS,
    Assistant,
    ReviewPlugin,
];
export const EXTERNAL_COMPOSED_PLUGINS = [ExternalApiConnector, ...BASE_COMPOSED_PLUGINS];
// Free Text
const BASE_FREE_TEXT_PLUGINS = [
    ...BASE_PLUGINS,
    InlineComment,
    Image,
    ImageCaption,
    ImageResize,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    EasyImage,
    TableToolbar,
    TableCaption,
    TableProperties,
    TableColumnResize,
    TableCellProperties,
];
export const APP_FREE_TEXT_PLUGINS = [
    AppApiConnector,
    ...BASE_FREE_TEXT_PLUGINS,
    Assistant,
    EditMode,
    ExportWord,
    ImportWord,
    ImportWordRTC,
    RevisionHistoryToolbarHelper,
    ReviewPlugin,
];
export const EXTERNAL_FREE_TEXT_PLUGINS = [
    ExternalApiConnector,
    ...BASE_FREE_TEXT_PLUGINS,
    ExternalSuggestions,
];
