import { DocumentMetadataAiAutoTaggingQueueEntryStatus as QueueStatus } from 'shared/domains/apollo/generated/types';
export function getState(options) {
    const { apiStates, featureAvailability, queue } = options;
    const { autoTagging } = queue || {};
    const [autoTag] = autoTagging || [];
    const { status } = autoTag || {};
    const isLoading = apiStates === null || apiStates === void 0 ? void 0 : apiStates.some(({ loading }) => loading);
    const hasError = apiStates === null || apiStates === void 0 ? void 0 : apiStates.some(({ error }) => error);
    if (featureAvailability === false)
        return 'disabled';
    if (isLoading)
        return 'loading';
    if (hasError)
        return 'error';
    switch (status) {
        case QueueStatus.Pending:
        case QueueStatus.InProgress:
        case QueueStatus.Delayed:
            return 'loading';
        case QueueStatus.Failed:
        case QueueStatus.Canceled:
            return 'error';
        case QueueStatus.Success:
            return 'success';
        default:
            return null;
    }
}
