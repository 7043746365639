import rehypeStringify from 'rehype-stringify';
import remarkBreaks from 'remark-breaks';
import remarkExternalLinks from 'remark-external-links';
import remarkGfm from 'remark-gfm';
import remarkParse from 'remark-parse';
import remarkRehype from 'remark-rehype';
import { unified } from 'unified';
export const getHtmlFromMarkdown = (markdown) => {
    const { value } = unified()
        .use(remarkParse)
        .use(remarkGfm)
        .use(remarkBreaks)
        .use(remarkExternalLinks)
        .use(remarkRehype)
        .use(rehypeStringify)
        .processSync(markdown);
    return value;
};
