var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { PhoneInput } from '@pocketlaw/tetris';
import { useFormikContext, useField } from 'formik';
import { ErrorMessage } from 'shared/domains/forms/components/ErrorMessage';
import { useCountries } from './useCountries';
import { useDefaultCountry } from './useDefaultCountry';
export function PhoneNumberField(props) {
    const { name, label } = props, rest = __rest(props, ["name", "label"]);
    const { isSubmitting } = useFormikContext();
    const [field, meta, helper] = useField(name);
    const error = Boolean(meta.touched && meta.error);
    const defaultCountry = useDefaultCountry(field.value);
    const countries = useCountries();
    const handleChange = (value) => {
        if (value !== '0') {
            helper.setValue(value);
        }
    };
    return (<PhoneInput {...rest} name={name} label={label} value={field.value || '0'} onBlur={field.onBlur} countries={countries} defaultCountry={defaultCountry} onChange={handleChange} disabled={isSubmitting} invalid={error} description={<ErrorMessage name={name}/>}/>);
}
