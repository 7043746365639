import { pxToRem, themeGet, themeGetColor } from '@pocketlaw/tetris';
import styled, { css } from 'styled-components';
const commonHeadingStyles = css `
  margin-top: ${themeGet('spacing.2xl')};
  margin-bottom: ${themeGet('spacing.xl')};
  font-weight: ${themeGet('fontWeight.bold')};
  line-height: 1.25;
`;
export const Heading1 = styled.h1 `
  ${commonHeadingStyles}
  font-size: 2em;
  margin: 0.67em 0;
`;
export const Heading2 = styled.h2 `
  ${commonHeadingStyles}
  font-size: 1.5em;
`;
export const Heading3 = styled.h3 `
  ${commonHeadingStyles}
  font-size: 1.25em;
`;
export const Heading4 = styled.h4 `
  ${commonHeadingStyles}
  font-size: 1em;
`;
export const Heading5 = styled.h5 `
  ${commonHeadingStyles}
  font-size: 0.875em;
`;
export const Heading6 = styled.h6 `
  ${commonHeadingStyles}
  font-size: 0.85em;
`;
export const Paragraph = styled.p `
  margin-top: 0;
  margin-bottom: ${themeGet('spacing.md')};
`;
export const Anchor = styled.a `
  color: ${themeGetColor('blue.500')};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:focus-visible {
    text-decoration: underline;
  }
`;
export const Strong = styled.strong `
  font-weight: ${themeGet('fontWeight.bold')};
`;
export const Emphasis = styled.em `
  font-style: ${themeGet('fontStyle.italic')};
`;
export const Delete = styled.del `
  text-decoration-line: line-through;
`;
export const BlockQuote = styled.blockquote `
  margin: 0;
  padding: 0 1em;
  color: ${themeGetColor('gray.600')};
  border-left: ${themeGet('spacing.sm')} solid ${themeGetColor('gray.200')};
`;
export const Hairline = styled.hr `
  box-sizing: content-box;
  overflow: hidden;
  background: transparent;
  background-color: ${themeGetColor('gray.200')};
  border: 0;
  border-bottom: ${pxToRem(1)} solid ${themeGetColor('gray.200')};
  height: ${themeGet('spacing.xs')};
  padding: 0;
  margin: ${themeGet('spacing.2xl')} 0;

  &:before {
    display: table;
    content: '';
  }

  &::after {
    display: table;
    clear: both;
    content: '';
  }
`;
const commonListStyles = css `
  margin-top: 0;
  margin-bottom: 0;
  padding-left: ${themeGet('spacing.3xl')};

  & ol,
  & ol {
    list-style-type: lower-roman;
  }

  & ul ol,
  & ol ol {
    list-style-type: lower-alpha;
  }
`;
export const UnorderedList = styled.ul `
  ${commonListStyles}
  list-style-type: disc;
`;
export const OrderedList = styled.ol `
  ${commonListStyles}
  list-style-type: decimal;
`;
export const ListItem = styled.li `
  & > p {
    margin-top: ${themeGet('spacing.xl')};
  }

  & + li {
    margin-top: ${themeGet('spacing.sm')};
  }
`;
export const Table = styled.table `
  border-spacing: 0;
  border-collapse: collapse;
  display: block;
  max-width: 100%;
  overflow: auto;
  margin-top: 0;
  margin-bottom: ${themeGet('spacing.xl')};
`;
export const TableRow = styled.tr `
  border-top: ${pxToRem(1)} solid ${themeGetColor('gray.200')};
`;
export const TableHead = styled.th `
  font-weight: ${themeGet('fontWeight.bold')};
  padding: ${themeGet('spacing.sm')} ${themeGet('spacing.md')};
  border: ${pxToRem(1)} solid ${themeGetColor('gray.200')};
`;
export const TableDataCell = styled.td `
  padding: ${themeGet('spacing.sm')} ${themeGet('spacing.md')};
  border: ${pxToRem(1)} solid ${themeGetColor('gray.200')};
`;
