import { t } from '@lingui/macro';
import { ButtonView, Plugin } from 'pleditor';
import { v4 as uuid } from 'uuid';
import { Comment } from './icons';
export class InlineComment extends Plugin {
    static get pluginName() {
        return 'InlineComment';
    }
    init() {
        const { editor } = this;
        const createButton = () => {
            const button = new ButtonView();
            button.set({
                withText: true,
                label: t({ comment: 'Add inline comment button text', message: 'Comment' }),
                icon: Comment,
                class: 'pleditor-toolbar-fa-icon-button',
            });
            button.on('execute', () => {
                const threadId = uuid();
                this.selectBlock();
                editor.execute('addCommentThread', { threadId });
            });
            return button;
        };
        editor.ui.componentFactory.add('inlineComment', () => createButton());
    }
    // If no characters are selected, select the whole block the cursor is currently in.
    selectBlock() {
        const { selection } = this.editor.model.document;
        const [range] = Array.from(selection.getRanges());
        const [block] = Array.from(selection.getSelectedBlocks());
        if (range.isCollapsed) {
            // Select the whole block in the editor
            this.editor.model.change(writer => {
                writer.setSelection(block, 'on');
            });
        }
    }
}
