var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useState } from 'react';
import { TableBodyCell, Typography, Menu, TableCellEmpty } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { CellContent, NotesTextContainer, PopoverContentContainer } from './styled';
export function NotesCell(props) {
    const { text } = props, rest = __rest(props, ["text"]);
    const [open, setOpen] = useState(false);
    if (!text) {
        return (<TableBodyCell {...rest}>
        <TableCellEmpty />
      </TableBodyCell>);
    }
    return (<TableBodyCell {...rest}>
      <Menu open={open} onClose={() => setOpen(false)} target={<CellContent onClick={() => setOpen(true)}>
            <Falcon icon="info-circle"/>
            <NotesTextContainer>
              <Typography $appearance="300">{text}</Typography>
            </NotesTextContainer>
          </CellContent>}>
        <PopoverContentContainer>
          <Typography $appearance="300">{text}</Typography>
        </PopoverContentContainer>
      </Menu>
    </TableBodyCell>);
}
