import { useMutation } from '@apollo/client';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { createDraftMutation } from './mutation';
export function useCreateDraft() {
    var _a, _b;
    const account = useAccount();
    const companyId = account.data && ((_a = account.data.company) === null || _a === void 0 ? void 0 : _a.id);
    const userId = account.data && ((_b = account.data.profile) === null || _b === void 0 ? void 0 : _b.userId);
    const [mutate, state] = useMutation(createDraftMutation);
    const handleMutate = (options) => {
        const { version, templateVersionReference, result, composerId, templateId, templateVersionTemplateId, } = options || {};
        const variables = {
            companyId,
            userId,
            composerId,
            templateId,
            version,
            templateVersionTemplateId,
            templateVersionReference,
            result: result || [],
        };
        return mutate({ variables });
    };
    return { mutate: handleMutate, state };
}
