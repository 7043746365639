import { useRef, useState } from 'react';
import { useFuse } from 'app/domains/search';
import { SearchableMenuContext } from './SearchableMenuContext';
export function SearchableMenuProvider(props) {
    const { items, keys, children, initiallyOpen = false, onClose, fuseOptions } = props;
    const [open, setOpen] = useState(initiallyOpen);
    const { search, result, searchString, reset, active } = useFuse(items, Object.assign({ keys }, fuseOptions));
    const inputRef = useRef(null);
    const itemsToDisplay = active && searchString.length ? result : items;
    const hasResult = Boolean(itemsToDisplay.length);
    const eventMap = {
        reset: [],
    };
    const on = (event, callback) => {
        eventMap[event] = [callback];
    };
    const dispatchEventCallbacks = (event) => {
        const callbacks = eventMap[event];
        callbacks.forEach(callback => callback());
    };
    const withEvent = (event, callback) => () => {
        callback();
        dispatchEventCallbacks(event);
    };
    const resetSearch = withEvent('reset', () => {
        var _a;
        reset();
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    });
    const handleSetOpen = (newState) => {
        setOpen(newState);
        if (newState) {
            reset();
        }
        if (!newState && onClose) {
            onClose();
        }
    };
    const context = {
        sourceItems: items,
        items: itemsToDisplay,
        open,
        setOpen: handleSetOpen,
        searching: active,
        hasResult,
        handleSearch: search,
        resetSearch,
        searchString,
        on,
    };
    return (<SearchableMenuContext.Provider value={context}>{children}</SearchableMenuContext.Provider>);
}
