import { Fragment } from 'react';
import { MenuBody, TextInput } from '@pocketlaw/tetris';
import { useField } from 'formik';
import { FormSubmitButton } from './FormSubmitButton';
export function NumberField() {
    const [field, meta] = useField('value');
    const error = Boolean(meta.touched && meta.error);
    return (<Fragment>
      <MenuBody>
        <TextInput stretch type="number" {...field} invalid={error}/>
      </MenuBody>
      <FormSubmitButton />
    </Fragment>);
}
