var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@apollo/client';
import { ComposersDocument } from './query.gql';
export const useComposers = (composerIds) => {
    const _a = useQuery(ComposersDocument, {
        variables: { composerIds },
        fetchPolicy: 'cache-and-network',
    }), { data } = _a, rest = __rest(_a, ["data"]);
    const { composers: initialComposers = [], defaultAttachments = [] } = data || {};
    const attachmentsMap = defaultAttachments.reduce((acc, next) => {
        const { composerId, attachments } = next;
        acc[composerId] = attachments;
        return acc;
    }, {});
    const transformedComposers = initialComposers.map(composer => (Object.assign(Object.assign({}, composer), { attachments: attachmentsMap[composer.id] || [] })));
    return Object.assign({ composers: transformedComposers }, rest);
};
