import { defineMessage } from '@lingui/macro';
export const namedRoles = {
    default: defineMessage({
        message: 'Unknown',
        comment: 'Name of unknown role, might happen on no data',
    }),
    owner: defineMessage({
        message: 'Owner',
        comment: 'Name of owner role.',
    }),
    member: defineMessage({
        message: 'User',
        comment: 'Name of user role.',
    }),
    admin: defineMessage({
        message: 'Admin',
        comment: 'Name of user role with invitation capabilities.',
    }),
};
