import { breakpoints, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const FilterContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${themeGet('spacing.md')};

  ${breakpoints.sm} {
    flex-direction: row;
    flex-flow: wrap;
    gap: ${themeGet('spacing.md')};
  }
`;
export const ActionContainer = styled.div `
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  gap: ${themeGet('spacing.md')};
`;
