import { Fragment, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { ButtonGroup, IconButton, Menu, MenuBody, MenuItem, MenuList } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { AddMetadataList } from './AddMetadataList';
import { AutoTag } from './AutoTag';
import { RemoveAll } from './RemoveAll';
import { VerifyAll } from './VerifyAll';
import { useDocumentMetadata } from '../DocumentMetadataProvider';
export function MetadataActions() {
    const [open, setOpen] = useState(false);
    const [confirmationModal, setConfirmationModal] = useState(undefined);
    const { activeEntries, data } = useDocumentMetadata();
    const { properties } = data;
    const pairsToVerify = properties.filter(({ pair }) => pair.needsVerification);
    const displayVerifyAllAction = Boolean(pairsToVerify.length);
    const toggle = () => setOpen(!open);
    const displayRemoveAll = () => {
        setConfirmationModal('remove');
        setOpen(false);
    };
    const displayVerifyAll = () => {
        setConfirmationModal('verify');
        setOpen(false);
    };
    const close = () => setConfirmationModal(undefined);
    return (<Fragment>
      <ButtonGroup $stretch>
        <AutoTag />
        <AddMetadataList />
        <Menu open={open} onClose={toggle} target={<IconButton onClick={toggle} size="small" appearance="secondary" aria-label={t({
                message: 'Additional actions',
                comment: 'Metadata list actions: Show more',
            })}>
              <Falcon icon="ellipsis-vertical"/>
            </IconButton>}>
          <MenuBody>
            <MenuList>
              {displayVerifyAllAction && (<MenuItem onClick={displayVerifyAll} disabled={!activeEntries.length}>
                  <Falcon icon="shield-check"/>
                  <Trans comment="Metadata: Verify all metadata pairs">Verify all tags</Trans>
                </MenuItem>)}
              <MenuItem onClick={displayRemoveAll} disabled={!activeEntries.length}>
                <Falcon icon="trash"/>
                <Trans comment="Metadata: Remove all metadata pairs">Remove all</Trans>
              </MenuItem>
            </MenuList>
          </MenuBody>
        </Menu>
      </ButtonGroup>
      <RemoveAll open={confirmationModal === 'remove'} close={close}/>
      <VerifyAll open={confirmationModal === 'verify'} close={close}/>
    </Fragment>);
}
