import { Fragment } from 'react';
import { localeConfig } from 'shared/domains/languages/config';
import Flag from '../Flag';
import { Language } from '../Language';
import { Copy } from './styled';
export function LanguageFlag(props) {
    const { languageCode, alignGlyph = 'left', textInLocalLanguage = false, hideLanguage = false, } = props;
    const locale = localeConfig.getLocale({ languageCode });
    const language = hideLanguage !== true && (<Copy $spacingDirection={alignGlyph}>
      <Language code={locale.code} textInLocalLanguage={textInLocalLanguage}/>
    </Copy>);
    const flagProps = {
        languageCode: locale.languageCode,
        label: hideLanguage ? locale.getName() : undefined,
    };
    const flag = <Flag {...flagProps}/>;
    if (alignGlyph === 'right') {
        return (<Fragment>
        {language}
        {flag}
      </Fragment>);
    }
    return (<Fragment>
      {flag}
      {language}
    </Fragment>);
}
