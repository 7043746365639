import { AssistantContext, useAssistant } from 'app/domains/assistant';
import { usePreview } from 'shared/domains/documents';
import { ContextNotSupported } from './ContextNotSupported';
import { DocumentPrompts } from './DocumentPrompts';
import { SelectionPrompts } from './SelectionPrompts';
export function AssistantContextPrompts() {
    const { context } = useAssistant();
    const { isPdf } = usePreview();
    switch (context) {
        case AssistantContext.Document:
            return <DocumentPrompts />;
        case AssistantContext.Selection:
            return isPdf ? <ContextNotSupported /> : <SelectionPrompts />;
        default:
            return null;
    }
}
