import { t } from '@lingui/macro';
import { AssistantContext } from 'app/domains/assistant';
export const getAssistantContextLabel = (context) => {
    switch (context) {
        case AssistantContext.Document:
            return t({
                comment: 'Document assistant context - document',
                message: 'Document',
            });
        case AssistantContext.Selection:
            return t({
                comment: 'Document assistant context - selection',
                message: 'Selection',
            });
        default:
            return undefined;
    }
};
