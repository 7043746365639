import { unit } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const EmptyContainer = styled.div `
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${unit('3xl')};
  padding: ${unit('7xl')} 0;
`;
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${unit('md')};
`;
