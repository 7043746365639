import { List } from '@pocketlaw/tetris';
import { Job } from './Job';
import { JobItem } from './JobItem';
import { LIMIT } from './useAutoTagNotifications/constants';
export function JobList(props) {
    const { jobs } = props;
    const emptySlotsCount = LIMIT - jobs.length;
    const emptySlots = Array.from({ length: emptySlotsCount }, (_, index) => index);
    return (<List as="nav">
      {jobs.map(job => {
            var _a;
            return (<Job key={job.documentId} name={(_a = job.fileSystemDocument) === null || _a === void 0 ? void 0 : _a.name} status={job.status} documentId={job.documentId}/>);
        })}
      {emptySlots.map(index => (<JobItem key={index} disabled/>))}
    </List>);
}
