import { useState } from 'react';
import { useGetCompanyUsers } from 'app/domains/users/hooks/useGetCompanyUsers';
import { Context } from './CompanyUserContext';
export function CompanyUserProvider(props) {
    const { children } = props;
    const [sort, setSort] = useState({ order: 'asc', id: 'name' });
    const [search, setSearch] = useState('');
    const [searching, setSearching] = useState(false);
    const { companyUsers, loading, error, initialLoading } = useGetCompanyUsers({
        sort,
        search,
    });
    if (searching && !loading) {
        setSearching(false);
    }
    const handleSearch = (value) => {
        setSearch(value);
        setSearching(true);
    };
    const noSearchResults = Boolean(search) && companyUsers.length < 1;
    return (<Context.Provider value={{
            sort,
            setSort,
            companyUsers,
            loading: initialLoading,
            error,
            setSearch: handleSearch,
            noSearchResults,
            searching,
        }}>
      {children}
    </Context.Provider>);
}
