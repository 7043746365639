import { defineMessage } from '@lingui/macro';
export const roles = [
    {
        value: 'member',
        label: defineMessage({
            message: 'User',
            comment: 'Name of user role',
        }),
        description: defineMessage({
            message: 'Only gains access to content specifically shared with them',
            comment: 'Description of user role.',
        }),
    },
    {
        value: 'admin',
        label: defineMessage({
            message: 'Admin',
            comment: 'Name of user who has permission to invite other users',
        }),
        description: defineMessage({
            message: 'Same as user + can manage and invite new users (without owner access).',
            comment: 'Description of admin role',
        }),
    },
    {
        value: 'owner',
        label: defineMessage({
            message: 'Owner',
            comment: 'Name of owner role',
        }),
        description: defineMessage({
            message: 'Can see and edit all content, change account details, and invite new users',
            comment: 'Description of owner role',
        }),
    },
];
