import { pxToRem, themeGetColor } from '@pocketlaw/tetris';
import styled, { keyframes } from 'styled-components';
const fadeAnimation = keyframes `
  0% {
    backdrop-filter: blur(0rem) opacity(0);
  }

  100% {
    backdrop-filter: blur(0.1rem) opacity(1);
  }
`;
const moveDownAnimation = keyframes `
  0% {
    transform: translateY(-${pxToRem(8)});
  }

  100% {
    transform: translateY(0);
  }
`;
export const Container = styled.div `
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${fadeAnimation} 245ms ease-in-out forwards;
  background-color: ${themeGetColor('background.50', 0.85)};
`;
export const EmptyStateContainer = styled.div `
  animation: ${moveDownAnimation} 145ms ease-out forwards;
`;
