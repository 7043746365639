import { fontSize, fontWeight, padding, visuallyHiddenStyles } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.a `
  ${padding(2)};
  ${fontSize('base', 1)};
  ${fontWeight('semibold')};

  &:not(:focus-visible) {
    ${padding(0)};
    ${visuallyHiddenStyles};
  }
`;
