import { TableBodyCell, TableHeadCell, breakpoints } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const MobileTableBodyCell = styled(TableBodyCell) `
  ${breakpoints.sm} {
    display: none;
  }
`;
export const DesktopTableBodyCell = styled(TableBodyCell) `
  ${breakpoints.xs} {
    display: none;
  }
`;
export const MobileTableHeaderCell = styled(TableHeadCell) `
  ${breakpoints.sm} {
    display: none;
  }
`;
export const DesktopTableHeaderCell = styled(TableHeadCell) `
  ${breakpoints.xs} {
    display: none;
  }
`;
