import { t } from '@lingui/macro';
import { IconButton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useDocumentDrawer } from 'shared/domains/documents';
export function AssistantExpandButton() {
    const { expanded, toggleExpanded } = useDocumentDrawer();
    return (<IconButton onClick={() => toggleExpanded()} appearance="ghost" aria-label={expanded
            ? t({
                comment: 'Document Assistant Chat - Collapse button tooltip label',
                message: 'Collapse',
            })
            : t({
                comment: 'Document Assistant Chat - Expand button tooltip label',
                message: 'Expand',
            })} tooltip={{
            position: 'bottom',
        }}>
      <Falcon icon="expand"/>
    </IconButton>);
}
