import { t } from '@lingui/macro';
import { Alert } from '@pocketlaw/tetris';
import { useFileSystemAcls } from 'app/domains/filesystem';
import { AccessList } from './components/AccessList';
/**
 * Same as AccessList but for entries that don't exist yet (so there's no entryId)
 */
export function AccessListNewEntry(props) {
    const { parentEntryId, onChange } = props;
    const { loading, error, usersWithAccess, teamsWithAccess, usersWithoutAccess, teamsWithoutAccess, } = useFileSystemAcls({ parentEntryId: parentEntryId === '0' ? undefined : parentEntryId });
    const accessListProps = {
        onChange,
        initialUsersWithAccess: usersWithAccess,
        initialTeamsWithAccess: teamsWithAccess,
        initialUsersWithoutAccess: usersWithoutAccess,
        initialTeamsWithoutAccess: teamsWithoutAccess,
    };
    if (loading) {
        // AccessList takes users & teams as **initial state** so we should only render it when we have the data
        return null;
    }
    if (error) {
        return (<Alert message={t({
                message: 'Something went wrong. Please try again later',
                comment: 'Error in accesslist for creating a new document/folder',
            })} appearance="danger"/>);
    }
    // "key" is required to force re-render when parentEntryId changes, so that the initialState is correct
    // without the key the initialState could be incorrect if the queries are cached (loading will never be true)
    return <AccessList key={parentEntryId} {...accessListProps}/>;
}
