import { gql } from '@apollo/client';
export const createFileSystemAclsMutation = gql `
  mutation createFileSystemAcls(
    $userAclsToCreate: [FileSystemAclInsertInput!]!
    $teamAclsToCreate: [FileSystemTeamAclInsertInput!]!
    $userAclsToUpdate: [FileSystemAclRelativeSetInput!]!
    $teamAclsToUpdate: [FileSystemTeamAclRelativeSetInput!]!
    $hasUserAclsToCreate: Boolean!
    $hasTeamAclsToCreate: Boolean!
    $hasUserAclsToUpdate: Boolean!
    $hasTeamAclsToUpdate: Boolean!
  ) {
    createFileSystemAcls(input: $userAclsToCreate) @include(if: $hasUserAclsToCreate) {
      id
      userId
      actions
    }
    createFileSystemTeamAcls(input: $teamAclsToCreate) @include(if: $hasTeamAclsToCreate) {
      id
      teamId
      actions
    }
    updateFileSystemAcls(input: $userAclsToUpdate) @include(if: $hasUserAclsToUpdate) {
      id
      userId
      actions
    }
    updateFileSystemTeamAcls(inputs: $teamAclsToUpdate) @include(if: $hasTeamAclsToUpdate) {
      id
      teamId
      actions
    }
  }
`;
