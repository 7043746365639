import { CompanyRoleEnum } from 'shared/domains/apollo/generated/types';
export function makeVariables(options) {
    const { role, email, composers } = options;
    const variablesMap = {
        owner: { role: CompanyRoleEnum.Owner },
        member: { role: CompanyRoleEnum.Member },
        admin: { role: CompanyRoleEnum.Member, can_manage_member: true },
    };
    const data = Object.assign({ email }, variablesMap[role]);
    if (composers.length > 0) {
        Object.assign(data, { allowed_composers: composers });
    }
    return [data];
}
