import { themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const LoaderContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const RadioCardContainer = styled.div `
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${themeGet('spacing.xl')};
`;
