import { Fragment } from 'react';
import { t } from '@lingui/macro';
import { AssistantView, AssistantViewType, useAssistant, AssistantClearHistoryButton, } from 'app/domains/assistant';
import { AssistantExpandButton } from 'app/domains/assistant/components/AssistantExpandButton';
import { DocumentAi, DocumentDrawerHeader } from 'shared/domains/documents';
import { Content, DrawerContainer } from './styled';
export function AssistantDrawer(props) {
    const { onClose } = props;
    const nestedViews = [AssistantViewType.Chat, AssistantViewType.Suggestions];
    const { view, updateView } = useAssistant();
    const title = t({
        comment: 'Document assistant drawer - title',
        message: 'Plai',
    });
    if (nestedViews.includes(view)) {
        return (<DrawerContainer>
        <DocumentDrawerHeader type="nested" title={title} onClose={onClose} actions={<Fragment>
              <AssistantExpandButton />
              <AssistantClearHistoryButton />
            </Fragment>} onGoBack={() => updateView(AssistantViewType.Dashboard)}/>
        <Content>
          <AssistantView />
        </Content>
      </DrawerContainer>);
    }
    return (<DrawerContainer>
      <DocumentDrawerHeader type="root" title={title} onClose={onClose}/>
      <DocumentAi>
        <Content>
          <AssistantView />
        </Content>
      </DocumentAi>
    </DrawerContainer>);
}
