import { gql } from '@apollo/client';
import { TaskFragment } from '../../utils/fragments';
const mutation = gql `
  mutation UpdateTaskWithCreator(
    $id: UUID!, 
    $task: TaskSetInput!, 
    $isPublic: Boolean, 
    $isCreator: Boolean! 
  ) {
    updateOneTask(pk_columns: { id: $id }, _set: $task) {
      ...TaskFragment
    }

    updateTaskOnlyCreator(
      where: { task_id: { _eq: $id } }
      _set: { is_public: $isPublic }
    ) @include(if: $isCreator) {
      returning {
        is_public
        task_id
      }
    }

    ${TaskFragment}
  }
`;
export { mutation };
