import { useEffect } from 'react';
import { createPath } from 'history';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'app/domains/auth/hooks/useAuth';
import { AuthenticatedVar } from 'shared/domains/apollo/generated/types';
function isRedirectingToLogin(data) {
    return (data === null || data === void 0 ? void 0 : data.authenticated) === AuthenticatedVar.NoUser;
}
export default function useAuthRedirect() {
    const auth = useAuth();
    const location = useLocation();
    const redirecting = isRedirectingToLogin(auth.data);
    useEffect(() => {
        if (redirecting) {
            auth.loginWithRedirect({ returnTo: createPath(location) });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [redirecting]);
    return redirecting;
}
