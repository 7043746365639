import { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Typography, SearchInput } from '@pocketlaw/tetris';
import { useField } from 'formik';
import { useDebounce } from 'use-debounce';
import { CategoryFilterButton } from './components/CategoryFilterButton';
import { ComposerTable } from './components/ComposerTable/ComposerTable';
import { OwnerAccessAlert } from '../OwnerAccessAlert';
import { Container, TableFilterContainer } from './styled';
export function ComposerAccessForm() {
    const [categoryIds, setCategoryIds] = useState([]);
    const [searchFilter, setSearchFilter] = useState('');
    const [search] = useDebounce(searchFilter, 500);
    const [field] = useField('role');
    const isOwner = field.value === 'owner';
    return (<Container>
      <Typography>
        <Trans comment="invite user modal - composer step description">
          Select the templates the user should have access to. You can manage this later from your
          template library.
        </Trans>
      </Typography>
      {isOwner && <OwnerAccessAlert role={field.value}/>}
      {!isOwner && (<div>
          <TableFilterContainer>
            <SearchInput stretch placeholder={t({
                message: 'Search template',
                comment: 'user invite modal - search placeholder',
            })} value={searchFilter} onChange={e => setSearchFilter(e.target.value)} onReset={() => setSearchFilter('')} resetLabel={t({
                message: 'Clear search',
                comment: 'describing text for reset button on search input',
            })}/>
            <CategoryFilterButton categoriesFilter={categoryIds} setCategoriesFilter={setCategoryIds}/>
          </TableFilterContainer>
          <ComposerTable filter={{ search, categoryIds }}/>
        </div>)}
    </Container>);
}
