var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { ApolloError } from '@apollo/client';
import { Trans, t } from '@lingui/macro';
import { Alert, Avatar, DialogFooter, DialogHeader, GridCell, GridRow, IconButton, LoaderOverlay, Typography, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { documentFlush, useActiveDocument, usePreview } from 'shared/domains/documents';
import { CancelButton, FormDialog, SubmitButton } from 'shared/domains/forms';
import { MessageField } from './MessageField';
import { useDocumentApproversPermissions } from './useDocumentApproversPermissions';
import { ApprovalErrorSubcodes, APPROVAL_ERROR_SUBCODES } from '../../utils';
import { useApproval } from '../ApprovalProvider';
import { useApprovers } from '../ApproversProvider';
import { StyledDialogContent } from './styled';
export function RequestApprovalDialog(props) {
    const { onClose } = props;
    const { id: documentId } = useActiveDocument();
    const { refetch } = useApprovers();
    const { createRequest } = useApproval();
    const { isRtcAvailable } = usePreview();
    const { approversWithoutPermission, loading: loadingApproversPermissions } = useDocumentApproversPermissions();
    const [isApprovalStatusChanged, setIsApprovalStatusChanged] = useState(false);
    const [alert, setAlert] = useState(null);
    const handleClose = () => {
        if (isApprovalStatusChanged) {
            refetch();
        }
        onClose();
    };
    const approverIdsWithoutPermission = approversWithoutPermission
        .map(approver => approver.userId)
        .filter((id) => id !== undefined || id !== null);
    const handleSubmit = (values) => __awaiter(this, void 0, void 0, function* () {
        const { message } = values;
        setAlert(null);
        if (isRtcAvailable) {
            documentFlush({ state: 'initialized' });
        }
        return createRequest({ documentId, approverIdsWithoutPermission, message })
            .then(handleClose)
            .catch(error => {
            var _a;
            if (isRtcAvailable) {
                documentFlush({ state: 'not-initialized' });
            }
            if (error instanceof ApolloError) {
                const subcode = (_a = error.graphQLErrors[0]) === null || _a === void 0 ? void 0 : _a.extensions.subcode;
                if (subcode && APPROVAL_ERROR_SUBCODES.includes(subcode)) {
                    setIsApprovalStatusChanged(true);
                    if (subcode === ApprovalErrorSubcodes.AlreadyPending) {
                        return setAlert({
                            appearance: 'info',
                            message: t({
                                comment: 'Handle ask for approval request dialog error message - Document approval already pending error',
                                message: 'Someone has already asked for approval for this document',
                            }),
                        });
                    }
                    if (subcode === ApprovalErrorSubcodes.AlreadyApproved) {
                        return setAlert({
                            appearance: 'info',
                            message: t({
                                comment: 'Handle ask for approval request dialog error message - Document approval already approved error',
                                message: 'This document has already been approved.',
                            }),
                        });
                    }
                }
            }
            return setAlert({
                appearance: 'danger',
                message: t({
                    comment: 'Handle ask for approval request dialog error message - Generic error message',
                    message: 'Something went wrong while asking for approval, please try again.',
                }),
            });
        });
    });
    const initialValues = {
        message: undefined,
    };
    return (<FormDialog size="sm" onClose={handleClose} onSubmit={handleSubmit} initialValues={initialValues}>
      <DialogHeader title={t({
            message: 'Ask for approval',
            comment: 'Approval Drawer - Ask for approval dialog - tile',
        })} media={<Avatar shape="square">
            <Falcon icon="thumbs-up"/>
          </Avatar>} action={<IconButton appearance="ghost" onClick={handleClose}>
            <Falcon icon="close"/>
          </IconButton>}/>
      <LoaderOverlay enabled={loadingApproversPermissions} label={t({
            message: 'Loading document approvers...',
            comment: 'Approval Drawer - Ask for approval dialog - Loading document approvers message',
        })}>
        <StyledDialogContent>
          {alert && (<GridRow>
              <GridCell width={12}>
                <Alert {...alert}/>
              </GridCell>
            </GridRow>)}
          <Typography $appearance="200">
            <Trans comment="Approval Drawer - Ask for approval dialog - description">
              When the assigned approvers have approved the document you will be able to send it for
              e-signing.
            </Trans>
          </Typography>
          <MessageField />
        </StyledDialogContent>
      </LoaderOverlay>
      <DialogFooter>
        {isApprovalStatusChanged && (<CancelButton>
            <Trans comment="Approval Drawer - Ask for approval dialog - cancel button label">
              Close
            </Trans>
          </CancelButton>)}
        <SubmitButton disabled={isApprovalStatusChanged || loadingApproversPermissions}>
          <Trans comment="Approval Drawer - Ask for approval dialog - submit button label">
            Submit
          </Trans>
        </SubmitButton>
      </DialogFooter>
    </FormDialog>);
}
