import { useDraggable, useDroppable, TableRow } from '@pocketlaw/tetris';
import T from 'prop-types';

import { useACLCan } from 'app/domains/filesystem';
import { AclAction } from 'shared/domains/apollo/generated/types';

import { ContextMenuCell, EmptyCell, DateCell, AvatarCell, NameCell } from './TableCells';

function FolderTableRow(props) {
  const {
    id,
    type,
    name,
    date,
    acl,
    teamAcls,
    onRename,
    onDelete,
    onEditAccess,
    onQuickMove,
    onMove,
    user,
    ...restProps
  } = props;

  const { canWrite, canDelete } = useACLCan({ userAcls: acl, teamAcls });
  const openUrl = `/files/${id}`;
  const { droppableProps, isDraggingOver } = useDroppable(onQuickMove);
  const { draggableProps, isDragging } = useDraggable(`${type}:${id}`);

  const tableRowProps = {
    $isDraggable: canWrite(),
    $isDragging: isDragging,
    $isDraggingOver: !isDragging && isDraggingOver,
    ...(canWrite() ? draggableProps : {}),
    ...(canWrite() ? droppableProps : {}),
    ...restProps,
  };

  const folderType = canWrite() ? 'folder' : 'folder:locked';

  return (
    <TableRow {...tableRowProps}>
      <NameCell headers="filesystemtable-name" to={openUrl} title={name} type={folderType} />
      <DateCell headers="filesystemtable-updatedAt" date={date} />
      <AvatarCell headers="filesystemtable-createdBy" primary={user?.name || user?.email} />
      <EmptyCell headers="filesystemtable-status" />
      <EmptyCell headers="filesystemtable-signingDate" />
      <ContextMenuCell
        download={false}
        openUrl={openUrl}
        onRename={canWrite() ? onRename : undefined}
        onDelete={canDelete() ? onDelete : undefined}
        onEditAccess={canWrite() ? onEditAccess : undefined}
        onMove={canWrite() ? onMove : undefined}
      />
    </TableRow>
  );
}

FolderTableRow.displayName = 'FolderTableRow';

FolderTableRow.propTypes = {
  id: T.string.isRequired,
  type: T.string.isRequired,
  name: T.string.isRequired,
  date: T.string.isRequired,
  acl: T.arrayOf(
    T.shape({
      userId: T.string.isRequired,
      actions: T.arrayOf(
        T.oneOf([AclAction.Read, AclAction.Write, AclAction.Delete, AclAction.Traverse])
      ),
    })
  ),
  teamAcls: T.arrayOf(
    T.shape({
      teamId: T.string.isRequired,
      actions: T.arrayOf(
        T.oneOf([AclAction.Read, AclAction.Write, AclAction.Delete, AclAction.Traverse])
      ),
    }).isRequired
  ).isRequired,
  onRename: T.func.isRequired,
  onDelete: T.func.isRequired,
  onEditAccess: T.func.isRequired,
  onQuickMove: T.func.isRequired,
  onMove: T.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  user: T.shape({
    name: T.string.isRequired,
    email: T.string.isRequired,
  }),
};

FolderTableRow.defaultProps = {
  acl: [],
  user: undefined,
};

export default FolderTableRow;
