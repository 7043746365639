export function defaultSlotSize(type) {
    switch (type) {
        case 'barchart':
        case 'linechart':
        case 'table':
            return { w: 6, h: 15 };
        case 'count':
            return { w: 4, h: 5 };
        default:
            return { w: 12, h: 15 };
    }
}
