import { CardContent, IconButton, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const WidgetBody = styled(CardContent) `
  display: flex;
  flex-direction: column;
  overflow: auto;
`;
export const AddTaskButton = styled(IconButton).attrs(() => ({
    appearance: 'ghost',
    size: 'medium',
})) `
  margin: 0 auto;
  margin-bottom: ${themeGet('spacing.sm')};
`;
