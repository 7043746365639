import { Trans, t } from '@lingui/macro';
import { AvatarWithLabel } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { CtaButton } from './CtaButton';
import { InformationContainer } from './styled';
export function Information(props) {
    const { state } = props;
    switch (state) {
        case 'disabled':
            return (<InformationContainer>
          <AvatarWithLabel alignment="horizontal" appearance="assistant" shape="circle" size="lg" title={t({ message: 'Upgrade', comment: 'Metadata: Auto tag feature not available' })} subtitle={t({
                    message: 'This is an enterprise feature.',
                    comment: 'Metadata: Auto tag feature not available subtitle',
                })}>
            <Falcon icon="circle-up"/>
          </AvatarWithLabel>
          <CtaButton>
            <Trans comment="Metadata: Support button in CTA when feature is not enabled">
              Get feature
            </Trans>
          </CtaButton>
        </InformationContainer>);
        case 'error':
            return (<InformationContainer>
          <AvatarWithLabel alignment="horizontal" appearance="danger" shape="circle" size="lg" title={t({ message: 'Auto tagging failed', comment: 'Metadata: Auto tag error title' })} subtitle={t({
                    message: 'The previous extraction of metadata tags failed. Please try again.',
                    comment: 'Metadata: Auto tag error subtitle',
                })}>
            <Falcon icon="triangle-exclamation"/>
          </AvatarWithLabel>
          <CtaButton>
            <Trans comment="Metadata: Auto tag error CTA">Contact support</Trans>
          </CtaButton>
        </InformationContainer>);
        case 'success':
            return (<InformationContainer>
          <AvatarWithLabel alignment="horizontal" appearance="success" shape="circle" size="lg" title={t({
                    message: 'Already completed',
                    comment: 'Metadata: Auto tag completed title',
                })} subtitle={t({
                    message: 'You have reached the maximum number of attempts for this document.',
                    comment: 'Metadata: Auto tag completed subtitle',
                })} truncate={false}>
            <Falcon icon="badge-check"/>
          </AvatarWithLabel>
          <CtaButton>
            <Trans comment="Metadata: Auto tag error CTA">Contact support</Trans>
          </CtaButton>
        </InformationContainer>);
        case 'loading':
        default:
            return null;
    }
}
