var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useLingui } from '@lingui/react';
import { TableBodyCell, TableCellEmpty, TableCellText } from '@pocketlaw/tetris';
export function AmountCell(props) {
    const { amount, currency } = props, rest = __rest(props, ["amount", "currency"]);
    const { i18n } = useLingui();
    const { locale } = i18n;
    if (!amount || !currency) {
        return (<TableBodyCell {...rest}>
        <TableCellEmpty />
      </TableBodyCell>);
    }
    const numberFormat = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
        currencyDisplay: 'narrowSymbol',
        unitDisplay: 'short',
    });
    return (<TableBodyCell {...rest}>
      <TableCellText primary={numberFormat.format(amount)} secondary={currency}/>
    </TableBodyCell>);
}
