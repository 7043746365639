import { Typography } from '@pocketlaw/tetris';
import { useTasks } from 'app/domains/tasks/components/TasksProvider';
import { Container } from './styled';
export function Description() {
    const { activeTask } = useTasks();
    if (!(activeTask === null || activeTask === void 0 ? void 0 : activeTask.description)) {
        return null;
    }
    return (<Container>
      <Typography $appearance="200">{activeTask.description}</Typography>
    </Container>);
}
