import { gql, useQuery } from '@apollo/client';
const pendingDocumentsCountQuery = gql `
  query pendingDocumentsCount {
    signRequests: rawSignRequests(where: { status: { _in: [PENDING, OPENED] } }) {
      id
      fileSystemDocument: file_system_document {
        id
      }
    }
  }
`;
export function usePendingDocumentsCount() {
    const { data, loading, error } = useQuery(pendingDocumentsCountQuery, { fetchPolicy: 'cache-and-network' });
    const pendingDocumentsCount = data === null || data === void 0 ? void 0 : data.signRequests.filter(({ fileSystemDocument }) => Boolean(fileSystemDocument)).length;
    return { pendingDocumentsCount, loading, error };
}
