import { pxToRem } from '@pocketlaw/tetris';
import styled from 'styled-components';
import { DocumentPageContainer } from '../../../styled';
export const ListContainer = styled(DocumentPageContainer) `
  padding: ${({ $spacing }) => `0 ${pxToRem($spacing)}`};
`;
export const contentVisibilityStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: 'fit-content',
};
