export const initialValues = {
    title: '',
    description: '',
    isCompleted: false,
    isPublic: false,
    taskAssignees: [],
    dueDate: '',
    reminder: undefined,
    attachments: [],
    addDocumentPermission: [],
};
function getSafeInitialValues(data) {
    if (!data) {
        return initialValues;
    }
    const noNullValues = Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(data).filter(([_, value]) => value !== null));
    const taskAssignees = (noNullValues.assignees || []).map(assignee => assignee.user);
    const mergedData = Object.assign(Object.assign(Object.assign({}, initialValues), noNullValues), { taskAssignees });
    return mergedData;
}
export { getSafeInitialValues };
