import { themeGet, themeGetColor } from '@pocketlaw/tetris';
import styled, { createGlobalStyle } from 'styled-components';
import { DRAGGABLE_HANDLE } from './constants';
export const ResizeContainer = styled.div `
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 10;
  transform: translateX(50%) translateY(50%);
  opacity: 0;

  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 1;
  }
`;
/**
 * This file is a copy of 'react-grid-layout/css/styles.css';
 * It is put here, using styled-components in order to not have to
 * add more loaders, and follow our current patterns.
 */
export const EditModeStyles = createGlobalStyle `
  .${DRAGGABLE_HANDLE} {
    cursor: move;
  }  
`;
/* eslint-disable no-restricted-syntax */
export const GridCSS = createGlobalStyle `

  .react-grid-item.react-grid-placeholder {
    background: ${themeGetColor('gray.200')} !important;
    opacity: 0.2;
    border-radius: ${themeGet('borderRadius.md')};
  }

  .react-grid-layout {
    position: relative;
    transition: height 200ms ease;
  }

  .react-grid-item img {
    pointer-events: none;
    user-select: none;
  }

  .react-grid-item.cssTransforms {
    transition-property: transform, width, height;
  }

  .react-grid-item.resizing {
    transition: none;
    z-index: 1;
    will-change: width, height;
  }

  .react-grid-item.react-draggable-dragging {
    transition: none;
    z-index: 3;
    will-change: transform;
    box-shadow: ${themeGet('dropShadow.400')};
    border-radius: ${themeGet('borderRadius.lg')};
  }

  .react-grid-item.dropping {
    visibility: hidden;
  }

  .react-grid-item.react-grid-placeholder {
    background: red;
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

  .react-grid-item.react-grid-placeholder.placeholder-resizing {
    transition: none;
  }

  .react-grid-item > .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
  }

  .react-grid-item > .react-resizable-handle::after {
    content: "";
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 5px;
    height: 5px;
    border-right: 2px solid rgba(0, 0, 0, 0.4);
    border-bottom: 2px solid rgba(0, 0, 0, 0.4);
  }

  .react-resizable-hide > .react-resizable-handle {
    display: none;
  }

  .react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
    bottom: 0;
    left: 0;
    cursor: sw-resize;
    transform: rotate(90deg);
  }

  .react-grid-item > .react-resizable-handle.react-resizable-handle-se {
    bottom: 0;
    right: 0;
    cursor: se-resize;
  }

  .react-grid-item > .react-resizable-handle.react-resizable-handle-nw {
    top: 0;
    left: 0;
    cursor: nw-resize;
    transform: rotate(180deg);
  }

  .react-grid-item > .react-resizable-handle.react-resizable-handle-ne {
    top: 0;
    right: 0;
    cursor: ne-resize;
    transform: rotate(270deg);
  }

  .react-grid-item > .react-resizable-handle.react-resizable-handle-w,
  .react-grid-item > .react-resizable-handle.react-resizable-handle-e {
    top: 50%;
    margin-top: -10px;
    cursor: ew-resize;
  }

  .react-grid-item > .react-resizable-handle.react-resizable-handle-w {
    left: 0;
    transform: rotate(135deg);
  }

  .react-grid-item > .react-resizable-handle.react-resizable-handle-e {
    right: 0;
    transform: rotate(315deg);
  }

  .react-grid-item > .react-resizable-handle.react-resizable-handle-n,
  .react-grid-item > .react-resizable-handle.react-resizable-handle-s {
    left: 50%;
    margin-left: -10px;
    cursor: ns-resize;
  }
  
  .react-grid-item > .react-resizable-handle.react-resizable-handle-n {
    top: 0;
    transform: rotate(225deg);
  }

  .react-grid-item > .react-resizable-handle.react-resizable-handle-s {
    bottom: 0;
    transform: rotate(45deg);
  }

`;
