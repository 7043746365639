import { themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const CompletedTaskAnimationContainer = styled.div `
  position: relative;
  overflow: hidden;
  flex: 1;
`;
export const Container = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${themeGet('spacing.md')};

  ${({ $empty }) => $empty && 'opacity: 0.5;'};
`;
export const ContentContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${themeGet('spacing.2xl')};
`;
export const LinkFieldContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${themeGet('spacing.lg')};
`;
