import { Calendar } from '@pocketlaw/tetris';
import { useField } from 'formik';
import { safeCalendarDate } from 'app/domains/tasks/utils/safeCalendarDate';
import { localeConfig } from 'shared/domains/languages/config';
import { useOnChange } from '../hooks/useOnChange';
export function DropdownContent() {
    const { handleChange } = useOnChange();
    const TODAYS_DATE = new Date();
    const localeCode = localeConfig.getActiveLocale().code;
    const [dueDateField] = useField('reminder.dueDate');
    return (<Calendar locale={localeCode} value={safeCalendarDate(dueDateField.value)} min={TODAYS_DATE} onChange={handleChange}/>);
}
