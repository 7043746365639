import { Trans, t } from '@lingui/macro';
import { Button, EmptyState } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Container, EmptyStateContainer } from './styled';
export function PickerPermissionWarning(props) {
    const { show, onClose } = props;
    if (show !== 'access:write') {
        return null;
    }
    return (<Container>
      <EmptyStateContainer>
        <EmptyState withAvatar icon={<Falcon icon="warning"/>} title={t({
            comment: 'Picker permission warning - Title',
            message: 'No access',
        })} description={t({
            comment: 'Picker permission  warning - Description',
            message: 'You do not have access to this folder. Please select another folder.',
        })} actions={<Button size="small" appearance="secondary" onClick={onClose}>
              <Trans comment="Picker permission  warning - Close button">Close</Trans>
            </Button>}/>
      </EmptyStateContainer>
    </Container>);
}
