var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { ConvertFileSystemDocumentToFreetextDocument, ConvertFileSystemDocumentToFreetextRtcDocument, } from './mutation.gql';
import { usePreview } from '../usePreview';
export const useConvertDocumentToFreeText = () => {
    const { isRtcAvailable } = usePreview();
    const [mutate] = useMutation(ConvertFileSystemDocumentToFreetextDocument);
    const [mutateRtc] = useMutation(ConvertFileSystemDocumentToFreetextRtcDocument);
    const convertDocumentToFreeText = (id) => __awaiter(void 0, void 0, void 0, function* () {
        if (isRtcAvailable) {
            return mutateRtc({ variables: { id } });
        }
        return mutate({ variables: { id } });
    });
    return [convertDocumentToFreeText];
};
