import { themeGet } from '@pocketlaw/tetris';
import styled, { css } from 'styled-components';
const BUBBLE_OFFSET = '7xl';
const userStyles = css `
  margin-right: ${themeGet(`spacing.${BUBBLE_OFFSET}`)};
  background-color: ${themeGet('colors.background.100')};
  border-top-left-radius: 0;
`;
const assistantStyles = css `
  margin-left: ${themeGet(`spacing.${BUBBLE_OFFSET}`)};
  background-color: ${themeGet('colors.assistant.50')};
  border-top-right-radius: 0;
`;
export const Container = styled.div `
  padding: ${themeGet('spacing.lg')} ${themeGet('spacing.xl')};
  border-radius: ${themeGet('spacing.md')};

  ${({ $direction }) => ($direction === 'left' ? userStyles : assistantStyles)};
  max-width: 100%;
`;
