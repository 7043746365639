import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useRole } from 'app/domains/users/hooks/useRole';
import { useGtm } from 'shared/domains/tracking';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { gtmDataQuery } from './query';
export default function useSendGtmData({ skip = false } = {}) {
    var _a, _b, _c, _d, _e, _f, _g;
    const gtm = useGtm();
    const role = useRole();
    const workspace = useAccount({ skip });
    const companyId = (_b = (_a = workspace.data) === null || _a === void 0 ? void 0 : _a.company) === null || _b === void 0 ? void 0 : _b.id;
    const { userId, email } = (_d = (_c = workspace.data) === null || _c === void 0 ? void 0 : _c.profile) !== null && _d !== void 0 ? _d : {};
    const query = useQuery(gtmDataQuery, {
        variables: {
            companyId,
            includeIndustry: role.can('read:company_industry'),
        },
        skip: !companyId,
    });
    const { industry, industryOther, plan } = (_f = (_e = query.data) === null || _e === void 0 ? void 0 : _e.company) !== null && _f !== void 0 ? _f : {};
    const { planType } = (_g = plan === null || plan === void 0 ? void 0 : plan.plan) !== null && _g !== void 0 ? _g : {};
    const companyIndustry = industryOther || industry;
    const roleName = role.name();
    useEffect(() => {
        if (!skip) {
            gtm.push({
                event: 'user_data',
                userId,
                companyId,
                userEmail: email,
                companyIndustry,
                role: roleName,
                planType,
                environment: process.env.APP_ENV,
            });
        }
    }, [companyId, companyIndustry, email, gtm, planType, roleName, skip, userId]);
}
