import { useLingui } from '@lingui/react';
import { Select } from '@pocketlaw/tetris';
import { teamRoles, useUpdateTeamMember, useTeamRole } from 'app/domains/teams';
import { useRole } from 'app/domains/users';
import { SelectContainer, SelectInnerContainer } from '../styled';
export function TeamRoleSelect(props) {
    const { role, label: selectLabel, teamId, teamMemberId } = props;
    const userRole = useRole();
    const teamRole = useTeamRole(teamId);
    const canUpdateTeamMember = userRole.can('update:team_member') || teamRole.can('update:team_member');
    const { updateTeamMember, loading } = useUpdateTeamMember({ teamId, teamMemberId });
    const { i18n } = useLingui();
    // eslint-disable-next-line no-underscore-dangle
    const _i18n = i18n;
    const isDisabled = loading || !canUpdateTeamMember;
    const handleChange = (e) => {
        const { value } = e.target;
        updateTeamMember(value);
    };
    return (<SelectContainer>
      <SelectInnerContainer>
        <Select stretch size="small" value={role} onChange={handleChange} disabled={isDisabled || loading} label={selectLabel} loading={loading}>
          {teamRoles.map(({ value, label }) => (<option disabled={isDisabled} key={value} value={value}>
              {_i18n._(label)}
            </option>))}
        </Select>
      </SelectInnerContainer>
    </SelectContainer>);
}
