import { themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const TooltipContainer = styled.div `
  display: flex;
  flex-direction: column;
  background-color: ${themeGet('colors.gray.0')};
  box-shadow: ${themeGet('dropShadow.200')};
  color: ${themeGet('color.300')};
  padding: ${themeGet('spacing.md')} ${themeGet('spacing.xl')};
`;
export const Rect = styled.rect `
  &:hover {
    opacity: 0.8;
  }

  transition: opacity 0.2s ease-in-out;

  cursor: pointer;
`;
