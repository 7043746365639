import { AvatarLetter, AvatarWithLabel } from '@pocketlaw/tetris';
import { MessageText } from '../MessageText';
import { Message } from '../styled';
export function UserMessage(props) {
    const { message, userName, writing } = props;
    // Remove clauses from message displayed to end-user
    const parsedMessage = message.replaceAll(/<clause>[\s\S]*?<\/clause>/g, '');
    return (<Message $direction="right">
      <AvatarWithLabel solid size="md" appearance="cyan" title={userName}>
        <AvatarLetter>{userName.slice(0, 1)}</AvatarLetter>
      </AvatarWithLabel>
      <MessageText text={parsedMessage} writing={writing}/>
    </Message>);
}
