var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fragment, useState } from 'react';
import { isApolloError } from '@apollo/client';
import { t } from '@lingui/macro';
import { IconButton, useToast } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import * as Sentry from 'shared/domains/sentry';
import { Dialog } from './Dialog';
import { useDislikeChatMessage } from './useDislikeChatMessage';
import { useLikeChatMessage } from './useLikeChatMessage';
import { DocumentAssistantChatAppraisalErrorSubcodes, } from './types';
export function Feedback(props) {
    const { commentId, feedbackScore } = props;
    const [feedback, setFeedback] = useState(undefined);
    const [dislike, { loading: sendingDislike }] = useDislikeChatMessage();
    const [like, { loading: sendingLike }] = useLikeChatMessage();
    const { addToast } = useToast();
    const loading = sendingDislike || sendingLike;
    const [error, setError] = useState(false);
    const handleLikeClick = () => {
        setFeedback('like');
    };
    const handleDislikeClick = () => {
        setFeedback('dislike');
    };
    const hideModal = () => {
        setFeedback(undefined);
    };
    const handleClose = () => {
        setError(false);
        hideModal();
    };
    const handleFeedbackSent = () => {
        hideModal();
        addToast({
            appearance: 'success',
            title: t({
                message: 'Feedback sent. Thank you!',
                comment: 'Document assistant - feedback dialog - successful toast title',
            }),
        });
    };
    const handleError = (mutationError) => {
        var _a;
        setError(true);
        if (isApolloError(mutationError)) {
            const subcode = (_a = mutationError.graphQLErrors[0]) === null || _a === void 0 ? void 0 : _a.extensions.subcode;
            if (subcode === DocumentAssistantChatAppraisalErrorSubcodes.AlreadyGiven) {
                return addToast({
                    appearance: 'success',
                    title: t({
                        message: 'We already had your feedback for this response. Thank you!',
                        comment: 'Document assistant - feedback dialog - toast title when the feedback had already been given but the user submitted again',
                    }),
                });
            }
        }
        Sentry.captureException(mutationError);
        return addToast({
            appearance: 'danger',
            title: t({
                message: 'Could not send feedback',
                comment: 'Document assistant - feedback dialog - toast title when the feedback failed to submit',
            }),
        });
    };
    const handleLike = (values) => __awaiter(this, void 0, void 0, function* () {
        setError(false);
        return like(values).then(handleFeedbackSent).catch(handleError);
    });
    const handleDislike = (values) => __awaiter(this, void 0, void 0, function* () {
        setError(false);
        return dislike(values).then(handleFeedbackSent).catch(handleError);
    });
    return (<Fragment>
      <Dialog commentId={commentId} feedback={feedback} onClose={handleClose} onDislike={handleDislike} onLike={handleLike} mutationError={error}/>
      {feedbackScore !== -1 && (<IconButton data-testid="assistant-action-like" disabled={!!feedbackScore || loading} appearance="ghost" aria-label={t({
                comment: 'Document assistant - assistant reply feedback - aria label (screen reader) - like answer',
                message: 'Like answer',
            })} onClick={handleLikeClick}>
          <Falcon icon="thumbs-up"/>
        </IconButton>)}
      {feedbackScore !== 1 && (<IconButton data-testid="assistant-action-dislike" onClick={handleDislikeClick} appearance="ghost" disabled={!!feedbackScore || loading} aria-label={t({
                comment: 'Document assistant - assistant reply feedback - aria label (screen reader) - dislike answer',
                message: 'Dislike answer',
            })}>
          <Falcon icon="thumbs-down"/>
        </IconButton>)}
    </Fragment>);
}
