import { MenuItem } from '@pocketlaw/tetris';
import { ColumnLabel } from './ColumnLabel';
import { ColumnToggle } from './ColumnToggle';
export function HiddenItem(props) {
    const { disabled, children, active, onToggle } = props;
    return (<MenuItem as="label" disabled={disabled}>
      <ColumnLabel active={active}>{children}</ColumnLabel>
      <ColumnToggle active={active} onClick={onToggle}/>
    </MenuItem>);
}
