import { Loader } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`;
export const StyledLoader = styled(Loader) `
  position: absolute;
  top: 25%;
`;
