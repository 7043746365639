import { Box, Skeleton } from '@pocketlaw/tetris';
export function LoadingSkeleton() {
    return (<Box flexDirection="column" m="lg" gap="xl">
      <Box alignItems="center" gap="md">
        <Skeleton borderRadius="circle" height="1.5rem" width="1.5rem"/>
        <Box flexDirection="column" gap="md">
          <Skeleton height="1rem" width="25rem"/>
        </Box>
      </Box>
      <Box alignItems="center" gap="md">
        <Skeleton borderRadius="circle" height="1.5rem" width="1.5rem"/>
        <Box flexDirection="column" gap="md">
          <Skeleton height="1rem" width="30rem"/>
        </Box>
      </Box>
      <Box alignItems="center" gap="md">
        <Skeleton borderRadius="circle" height="1.5rem" width="1.5rem"/>
        <Box flexDirection="column" gap="md">
          <Skeleton height="1rem" width="25rem"/>
        </Box>
      </Box>
      <Box alignItems="center" gap="md">
        <Skeleton borderRadius="circle" height="1.5rem" width="1.5rem"/>
        <Box flexDirection="column" gap="md">
          <Skeleton height="1rem" width="30rem"/>
        </Box>
      </Box>
      <Box alignItems="center" gap="md">
        <Skeleton borderRadius="circle" height="1.5rem" width="1.5rem"/>
        <Box flexDirection="column" gap="md">
          <Skeleton height="1rem" width="25rem"/>
        </Box>
      </Box>
    </Box>);
}
