import { fontWeight } from '@pocketlaw/tetris';
import { Text } from '@pocketlaw/tetris/deprecated';
import styled, { css } from 'styled-components';
export const highlightStyles = css `
  background-color: unset;
  color: inherit;
  font-style: normal;
  ${fontWeight('semibold')}
`;
export const wordBreak = css `
  /* "word-break: break-word" is deprecated but will probably still work */
  word-break: break-word;
  /* "overflow-wrap: anywhere" replaces "work-break: break-word" but is has poor browser support at the moment */
  overflow-wrap: anywhere;
`;
export const UppercaseText = styled(Text) `
  text-transform: uppercase;
`;
