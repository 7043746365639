import { useQuery } from '@apollo/client';
import { useTasks } from 'app/domains/tasks/components/TasksProvider';
import { documentNameQuery } from './documentNameQuery';
import { templateNameQuery } from './templateNameQuery';
export function useAttachmentData() {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { activeTask } = useTasks();
    const attachmentId = (_c = (_b = (_a = activeTask === null || activeTask === void 0 ? void 0 : activeTask.attachments) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.attachmentId) !== null && _c !== void 0 ? _c : '';
    const type = (_e = (_d = activeTask === null || activeTask === void 0 ? void 0 : activeTask.attachments) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.type;
    const { data: templateData } = useQuery(templateNameQuery, {
        variables: {
            id: attachmentId,
        },
        skip: !attachmentId || type !== 'template',
    });
    const [composerTemplate] = ((_f = templateData === null || templateData === void 0 ? void 0 : templateData.template) === null || _f === void 0 ? void 0 : _f.composerTemplates) || [];
    const [composerMetadata] = ((_g = composerTemplate === null || composerTemplate === void 0 ? void 0 : composerTemplate.composer) === null || _g === void 0 ? void 0 : _g.metadata) || [];
    const { data: documentData } = useQuery(documentNameQuery, {
        variables: {
            id: attachmentId,
        },
        skip: !attachmentId || type !== 'document',
    });
    const hasAttachment = Boolean(attachmentId);
    const hasAccess = type === 'document' ? Boolean(documentData === null || documentData === void 0 ? void 0 : documentData.fileSystemDocument) : hasAttachment;
    const attachmentData = {
        id: attachmentId,
        name: (composerMetadata === null || composerMetadata === void 0 ? void 0 : composerMetadata.name) || ((_h = documentData === null || documentData === void 0 ? void 0 : documentData.fileSystemDocument) === null || _h === void 0 ? void 0 : _h.name),
        hasAccess,
        type,
    };
    return { attachmentData };
}
