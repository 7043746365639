import { t } from '@lingui/macro';
import { EmptyState } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
export function ErrorState() {
    return (<EmptyState withAvatar icon={<Falcon icon="circle-exclamation"/>} title={t({
            message: 'Something went wrong',
            comment: 'Review drawer - Failed to fetch review error state - title',
        })} description={t({
            message: 'There was an error while fetching the review for this document, please try again.',
            comment: 'Review drawer - Failed to fetch review error state - description',
        })}/>);
}
