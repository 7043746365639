import { AvatarWithLabel, Drawer, pxToRem, themeGet, unit } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const StyledDrawer = styled(Drawer) `
  width: 100%;
  max-width: ${pxToRem(360)};
  background-color: ${themeGet('colors.background.50')};
`;
export const ContentContainer = styled.div `
  padding: ${unit('xl')};
`;
export const DrawerHeader = styled.div `
  display: flex;
  flex-direction: row;
  padding: ${unit('xl')};
`;
export const StyledAvatarWithLabel = styled(AvatarWithLabel) `
  flex: 1;
`;
