import { fontWeight, unit } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Strong = styled.strong `
  ${fontWeight('semibold')}
`;
export const Content = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${unit('md')};
`;
