/**
 * This function will sort columns by the order of the sortBy array.
 *
 * @param columns to be sorted
 * @param sortBy array of column names to sort by
 */
export function sortColumns(columns, sortBy) {
    if (!sortBy || sortBy.length === 0) {
        return columns;
    }
    return columns.toSorted((a, b) => {
        if (a.id === 'action' || b.id === 'action') {
            return a.id === 'action' ? 1 : -1;
        }
        const indexA = sortBy.indexOf(a.id);
        const indexB = sortBy.indexOf(b.id);
        if (indexA === -1 && indexB === -1) {
            return 0;
        }
        if (indexA === -1) {
            return 1;
        }
        if (indexB === -1) {
            return -1;
        }
        return indexA - indexB;
    });
}
