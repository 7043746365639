import { Fragment } from 'react';
import { Trans } from '@lingui/macro';
import { useAccessState } from './useAccessState';
import { AccessListItem } from '../AccessListItem';
import { AddFileSystemAclsButton } from '../AddFileSystemAclsButton';
import { StyledList, StyledTypography, TitleContainer } from './styled';
export function AccessList(props) {
    const { onChange, initialUsersWithAccess, initialTeamsWithAccess, initialUsersWithoutAccess, initialTeamsWithoutAccess, } = props;
    const { usersWithAccess, usersWithoutAccess, teamsWithAccess, teamsWithoutAccess, onAddAcls, onRemoveUserAcl, onRemoveTeamAcl, } = useAccessState({
        onChange,
        initialUsersWithAccess,
        initialTeamsWithAccess,
        initialUsersWithoutAccess,
        initialTeamsWithoutAccess,
    });
    return (<Fragment>
      <TitleContainer>
        <StyledTypography $fontWeight="medium">
          <Trans comment="Filesystem access drawer list title">Users & teams with access</Trans>
        </StyledTypography>
        <AddFileSystemAclsButton usersWithoutAccess={usersWithoutAccess} teamsWithoutAccess={teamsWithoutAccess} onAdd={onAddAcls}/>
      </TitleContainer>
      <StyledList>
        {teamsWithAccess.map(({ id, name, numberOfTeamMembers, aclId, hasAccessToParentFolder }) => (<AccessListItem key={id} id={id} type="team" numberOfTeamMembers={numberOfTeamMembers} name={name} aclId={aclId} onRemove={onRemoveTeamAcl} hasAccessToParentFolder={hasAccessToParentFolder}/>))}
        {usersWithAccess.map(({ id, name, role, roleName, aclId, isSelf, hasAccessToParentFolder }) => (<AccessListItem key={id} id={id} type="user" roleName={roleName} name={name} aclId={aclId} onRemove={role !== 'owner' ? onRemoveUserAcl : undefined} hasAccessToParentFolder={hasAccessToParentFolder} isSelf={isSelf}/>))}
      </StyledList>
    </Fragment>);
}
