import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Alert } from '@pocketlaw/tetris';
import { namedRoles } from 'app/domains/users/hooks/useRole/namedRoles';
export function OwnerAccessAlert(props) {
    const { role } = props;
    const translatedRole = namedRoles[role];
    const { i18n } = useLingui();
    const tmpI18n = i18n;
    return (<Alert appearance="info" title={t({
            message: `${tmpI18n._(translatedRole)} have full access`,
            comment: 'invite user modal - owner role has full access alert title',
        })} message={t({
            message: 'This role already has full access to all templates',
            comment: 'invite user modal - owner role has full access alert message',
        })}/>);
}
