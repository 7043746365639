export var ReminderOperation;
(function (ReminderOperation) {
    ReminderOperation["create"] = "CREATE";
    ReminderOperation["update"] = "UPDATE";
    ReminderOperation["remove"] = "REMOVE";
})(ReminderOperation || (ReminderOperation = {}));
export function getReminderOperation(reminder) {
    const createReminder = reminder && !reminder.id && reminder.dueDate;
    const updateReminder = reminder && reminder.id && reminder.dueDate;
    const removeReminder = reminder && reminder.id && !reminder.dueDate;
    if (createReminder) {
        return ReminderOperation.create;
    }
    if (updateReminder) {
        return ReminderOperation.update;
    }
    if (removeReminder) {
        return ReminderOperation.remove;
    }
    return undefined;
}
