import { Tooltip, Typography } from '@pocketlaw/tetris';
import { UserAvatarList } from 'app/domains/users';
import { useDraftPreview } from '../../../DraftPreviewProvider';
import { AvatarContainer, TooltipContainer } from './styled';
export function MetadataApprovers() {
    const { approvers } = useDraftPreview();
    if (!approvers.length) {
        return null;
    }
    const users = approvers.map(approver => ({
        id: (approver === null || approver === void 0 ? void 0 : approver.id) || '',
        name: (approver === null || approver === void 0 ? void 0 : approver.name) || '',
    }));
    const avatars = users.map(user => ({
        id: user.id,
        title: user.name,
    }));
    const tooltipElement = (<TooltipContainer>
      {users.map(user => (<Typography key={user.id} $appearance="light" $fontSize="small">
          {user.name}
        </Typography>))}
    </TooltipContainer>);
    return (<Tooltip title={tooltipElement}>
      <AvatarContainer>
        <UserAvatarList avatars={avatars}/>
      </AvatarContainer>
    </Tooltip>);
}
