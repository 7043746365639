import { Fragment } from 'react';
import { t, Trans } from '@lingui/macro';
import { Button, PageHeader } from '@pocketlaw/tetris';
import { Link } from 'react-router-dom';
import { useACLCan } from 'app/domains/filesystem';
import { constructSearchURL } from 'app/domains/global-search';
import { FileSystemBreadcrumbs } from './Breadcrumbs';
import { ContextMenuButton } from '../ContextMenuButton';
import { useActiveWorkspace } from '../FileSystemProvider';
export function Header(props) {
    const { onUploadFile, onCreateFolder, onUploadFolder } = props;
    const { fileSystemData } = useActiveWorkspace();
    const { folder } = fileSystemData || {};
    const { mpath = '' } = folder || {};
    const { canWrite } = useACLCan({ userAcls: folder === null || folder === void 0 ? void 0 : folder.acl, teamAcls: folder === null || folder === void 0 ? void 0 : folder.teamAcls });
    const rootName = t({
        message: 'Files',
        comment: 'Page header title for file system root',
    });
    const path = constructSearchURL({
        where: {
            metadataEntryPath: {
                startsWith: mpath,
            },
        },
    });
    const searchPath = mpath ? path : '/all-documents';
    return (<PageHeader heading={(folder === null || folder === void 0 ? void 0 : folder.name) || rootName} breadcrumbs={<FileSystemBreadcrumbs />} actions={<Fragment>
          <ContextMenuButton disabled={folder ? !canWrite() : false} onUploadFile={onUploadFile} onCreateFolder={onCreateFolder} onUploadFolder={onUploadFolder}/>
          <Button appearance="secondary" as={Link} to={searchPath}>
            <Trans comment="filter and search button text">Filter & Search</Trans>
          </Button>
        </Fragment>}/>);
}
