import { t } from '@lingui/macro';
import { Box, EmptyState } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
export function ErrorFallback() {
    const title = t({ message: 'Error', comment: 'Insights Visualization: Error title' });
    const description = t({
        message: 'Something went wrong',
        comment: 'Insights Visualization: Error description',
    });
    return (<Box flexDirection="column" justifyContent="center" width="100%">
      <EmptyState withAvatar title={title} description={description} icon={<Falcon icon="chart-line-up"/>}/>
    </Box>);
}
