export function applyTax(options) {
    const { amountInCents, taxRate } = options;
    if (!amountInCents) {
        return undefined;
    }
    if (!taxRate) {
        return amountInCents;
    }
    if (taxRate.inclusive === false) {
        return amountInCents + (amountInCents * taxRate.percentage) / 100;
    }
    return amountInCents;
}
export function getTaxAmount(options) {
    const { amountInCents, taxRate } = options;
    if (!amountInCents) {
        return undefined;
    }
    if (!taxRate) {
        return amountInCents;
    }
    if (taxRate.inclusive === false) {
        return (amountInCents * taxRate.percentage) / 100;
    }
    return amountInCents - amountInCents / (1 + taxRate.percentage / 100);
}
export function applyCoupon(options) {
    const { amountInCents, coupon } = options;
    if (!amountInCents) {
        return undefined;
    }
    if (!coupon) {
        return amountInCents;
    }
    if (coupon.amountOff !== null) {
        return Math.max(0, amountInCents - coupon.amountOff);
    }
    if (coupon.percentOff !== null) {
        return amountInCents * (1 - coupon.percentOff / 100);
    }
    return amountInCents;
}
export function getDiscountAmount(options) {
    const { amountInCents, coupon } = options;
    const amountWithDiscount = applyCoupon({ amountInCents, coupon });
    if (!amountWithDiscount || !amountInCents) {
        return 0;
    }
    return amountWithDiscount - amountInCents;
}
export function formatPrice(options) {
    const { amountInCents, currency, language } = options;
    if (!amountInCents || !currency || !language) {
        return undefined;
    }
    const numberFormat = new Intl.NumberFormat(language, {
        style: 'currency',
        currency,
        currencyDisplay: 'code',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    });
    return numberFormat.format(amountInCents / 100);
}
export const isYearlyPrice = (options) => {
    const { interval, intervalCount } = options;
    return intervalCount === 1 && interval === 'YEAR';
};
export const isMonthlyPrice = (options) => {
    const { interval, intervalCount } = options;
    return intervalCount === 1 && interval === 'MONTH';
};
