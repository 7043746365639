import { useMemo } from 'react';
import { isValid } from 'date-fns';
import { useLocation } from 'react-router-dom';
const safeDate = (date) => date && isValid(new Date(date)) ? new Date(date) : undefined;
function fromURL(url, defaults) {
    const dueDatesStartDate = safeDate(url.get('dueDates.startDate'));
    const dueDatesEndDate = safeDate(url.get('dueDates.endDate'));
    const createdAtDatesStartDate = safeDate(url.get('createdAtDates.startDate'));
    const createdAtDatesEndDate = safeDate(url.get('createdAtDates.endDate'));
    const creators = url.getAll('creators');
    const assignees = url.getAll('assignees');
    const status = url.getAll('status');
    const data = Object.assign({}, defaults);
    if (dueDatesStartDate) {
        data.dueDates = Object.assign(Object.assign({}, data.dueDates), { startDate: dueDatesStartDate });
    }
    if (dueDatesEndDate) {
        data.dueDates = Object.assign(Object.assign({}, data.dueDates), { endDate: dueDatesEndDate });
    }
    if (createdAtDatesStartDate) {
        data.createdAtDates = Object.assign(Object.assign({}, data.createdAtDates), { startDate: createdAtDatesStartDate });
    }
    if (createdAtDatesEndDate) {
        data.createdAtDates = Object.assign(Object.assign({}, data.createdAtDates), { endDate: createdAtDatesEndDate });
    }
    if (creators.length) {
        data.creators = creators;
    }
    if (assignees.length) {
        data.assignees = assignees;
    }
    if (status.length) {
        data.status = status.map(statusEnum => statusEnum);
    }
    return data;
}
function toURL(filters) {
    var _a, _b, _c, _d, _e, _f, _g;
    const url = new URLSearchParams();
    if ((_a = filters.dueDates) === null || _a === void 0 ? void 0 : _a.startDate) {
        url.append('dueDates.startDate', filters.dueDates.startDate.toISOString());
    }
    if ((_b = filters.dueDates) === null || _b === void 0 ? void 0 : _b.endDate) {
        url.append('dueDates.endDate', filters.dueDates.endDate.toISOString());
    }
    if ((_c = filters.createdAtDates) === null || _c === void 0 ? void 0 : _c.startDate) {
        url.append('createdAtDates.startDate', filters.createdAtDates.startDate.toISOString());
    }
    if ((_d = filters.createdAtDates) === null || _d === void 0 ? void 0 : _d.endDate) {
        url.append('createdAtDates.endDate', filters.createdAtDates.endDate.toISOString());
    }
    if ((_e = filters.creators) === null || _e === void 0 ? void 0 : _e.length) {
        filters.creators.forEach(id => url.append('creators', id));
    }
    if ((_f = filters.assignees) === null || _f === void 0 ? void 0 : _f.length) {
        filters.assignees.forEach(id => url.append('assignees', id));
    }
    if ((_g = filters.status) === null || _g === void 0 ? void 0 : _g.length) {
        filters.status.forEach(status => url.append('status', status));
    }
    return url.toString();
}
export function useURLFilters() {
    const { search } = useLocation();
    const url = useMemo(() => new URLSearchParams(search), [search]);
    const fromUrl = (defaults) => fromURL(url, defaults || {});
    const toUrl = (filters) => toURL(filters);
    return {
        fromUrl,
        toUrl,
    };
}
