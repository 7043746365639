var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { AvatarLetter, AvatarList } from '@pocketlaw/tetris';
import { getColor } from './getColor';
export function UserAvatarList(props) {
    const { avatars } = props, restProps = __rest(props, ["avatars"]);
    const avatarsWithColors = avatars.map((_a) => {
        var { title } = _a, avatarRest = __rest(_a, ["title"]);
        return (Object.assign(Object.assign({}, avatarRest), { appearance: getColor(title), tooltip: { title }, component: <AvatarLetter>{(title === null || title === void 0 ? void 0 : title.slice(0, 1)) || ''}</AvatarLetter> }));
    });
    return <AvatarList solid avatars={avatarsWithColors} {...restProps}/>;
}
