import { gql } from '@apollo/client';
export const createExternalSharingInviteMutation = gql `
  mutation createExternalSharingInvite($documentId: String!, $email: String!) {
    createExternalSharingInvite(
      input: { fileSystemDocumentId: $documentId, inviteeEmail: $email }
    ) {
      id
      inviteeEmail
    }
  }
`;
