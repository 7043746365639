import { gql } from '@apollo/client';
export const CACHE_WRITE_QUERY = gql `
  query ComposersQuery($id: Unstable__UUID!) {
    composer: unstable__composer(id: $id) {
      id
      defaultSignatories {
        composerId
        signatories {
          email
          firstName
          lastName
          label
          mobile
          createdAt
          updatedAt
          authenticationMode
        }
        ccRecipients {
          email
          firstName
          lastName
          label
          createdAt
          updatedAt
        }
      }
    }
  }
`;
