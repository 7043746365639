import { pxToRem, unit } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const List = styled.nav `
  display: flex;
  flex-direction: column;
`;
export const Item = styled.button `
  background: none;
  border: none;
  text-align: left;
  padding: ${unit('md')} 0;
`;
export const Square = styled.span `
  display: inline-block;
  width: ${pxToRem(14)};
  height: ${pxToRem(14)};
  background-color: ${props => props.$fill};
  margin-right: ${unit('md')};
`;
