import { padding, unit, TableBodyCell } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const LoadingContainer = styled.div `
  display: flex;
  ${padding(4)};
  align-items: center;
  justify-content: center;
`;
export const MediumTableBodyCell = styled(TableBodyCell) `
  max-width: ${unit(100)};
`;
export const TitleWrapper = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${unit(4)};
`;
