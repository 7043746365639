import { Checkbox, MenuItem } from '@pocketlaw/tetris';
import { useFormikContext } from 'formik';
import { EntryIcon } from './EntryIcon';
import { RightAlign } from './styled';
export function MultiselectItem(props) {
    const { id, children, type, disabled = false, handleSelect } = props;
    const { values } = useFormikContext();
    const { value = [] } = values || {};
    const checked = value === null || value === void 0 ? void 0 : value.includes(id);
    return (<MenuItem as="label" disabled={disabled}>
      <EntryIcon type={type}/>
      {children}
      <RightAlign>
        <Checkbox checked={checked} onChange={() => handleSelect(id)}/>
      </RightAlign>
    </MenuItem>);
}
