import { AclAction } from 'shared/domains/apollo/generated/types';
export class ItemRendererClassNamesManager {
    constructor() {
        this.isUserWithAccessRendered = false;
        this.isUserWithoutAccessRendered = false;
        this.ITEM_CLASS_NAME = 'ck-custom-mention-item';
        this.FIRST_ITEM_WITH_ACCESS_CLASS_NAME = 'ck-custom-mention-first-item-with-access';
        this.FIRST_ITEM_WITHOUT_ACCESS_CLASS_NAME = 'ck-custom-mention-first-item-without-access';
        this.FIRST_ITEM_EXTERNAL_CLASS_NAME = 'ck-custom-mention-first-item-external';
    }
    getItemClassNames(actions, isExternal) {
        const canRead = actions.includes(AclAction.Read);
        const classNames = [this.ITEM_CLASS_NAME];
        if (canRead && !this.isUserWithAccessRendered) {
            this.isUserWithAccessRendered = true;
            classNames.push(this.FIRST_ITEM_WITH_ACCESS_CLASS_NAME);
        }
        if (!canRead && !this.isUserWithoutAccessRendered) {
            this.isUserWithoutAccessRendered = true;
            classNames.push(this.FIRST_ITEM_WITHOUT_ACCESS_CLASS_NAME);
        }
        if (canRead && isExternal) {
            classNames.push(this.FIRST_ITEM_EXTERNAL_CLASS_NAME);
        }
        return classNames;
    }
}
