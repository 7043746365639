import { Children } from 'react';
import { Typography } from '@pocketlaw/tetris';
import { EmptyContainer, List } from './styled';
export function TemplateDrawerItemList(props) {
    const { children, emptyMessage } = props;
    const amount = Children.count(children);
    if (amount === 0) {
        return (<EmptyContainer>
        <Typography $fontSize="tiny" $appearance="100">
          {emptyMessage}
        </Typography>
      </EmptyContainer>);
    }
    return <List>{children}</List>;
}
