import { MenuItem, pxToRem, themeGet } from '@pocketlaw/tetris';
import styled, { css } from 'styled-components';
const draggingStyles = css `
  &:active:not(:disabled, [disabled]) {
    box-shadow: ${themeGet('dropShadow.100')};
    border: ${pxToRem(1)} solid ${themeGet('colors.border.100')};
    background: ${themeGet('colors.background.50')};
  }
`;
export const ColumnItemContainer = styled(MenuItem) `
  cursor: default;
  ${draggingStyles}
`;
