import { TemplateCard } from '../../../../TemplateCard';
import { Container } from './styled';
export function Item(props) {
    const { composer, onClick } = props;
    const { id, metadata, category, locked } = composer;
    const { name } = metadata[0];
    const categoryTitle = (category === null || category === void 0 ? void 0 : category.metadata[0].title) || '';
    return (<Container>
      <TemplateCard onClick={() => onClick(id)} composerId={id} title={name} category={categoryTitle} locked={locked}/>
    </Container>);
}
