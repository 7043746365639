import { Fragment } from 'react';
import { useField } from 'formik';
import { SearchAggregationType } from 'shared/domains/apollo/generated/types';
import { DateHistogramInput } from './DateHistogramInput';
import { DateRangeInput } from './DateRangeInput';
import { MetricInput } from './MetricInput';
import { TermsInput } from './TermsInput';
import { TopHitsInput } from './TopHitsInput';
export function DataPoints(props) {
    const { name, isSubAggregation = false } = props;
    const [field, , helpers] = useField(name);
    const { value = [] } = field;
    const handleRemoveDataPoint = () => {
        helpers.setValue([]);
    };
    const getProps = (index) => ({
        pathBase: `${field.name}[${index}]`,
        remove: handleRemoveDataPoint,
        isSubAggregation,
        key: field.value[index].name,
    });
    const getComponent = (type) => {
        switch (type) {
            case SearchAggregationType.Terms:
                return TermsInput;
            case SearchAggregationType.DateHistogram:
                return DateHistogramInput;
            case SearchAggregationType.DateRange:
                return DateRangeInput;
            case SearchAggregationType.Metric:
                return MetricInput;
            case SearchAggregationType.TopHits:
                return TopHitsInput;
            default:
                return Fragment;
        }
    };
    const dataPoints = value.map((aggregation, index) => ({
        component: getComponent(aggregation.type),
        componentProps: getProps(index),
    }));
    return dataPoints.map(({ component: Component, componentProps }) => (<Component {...componentProps}/>));
}
