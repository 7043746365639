import { t } from '@lingui/macro';
import { SearchInput } from '@pocketlaw/tetris';
import { usePromptLibrary } from '../../../PromptLibraryProvider';
export function Search() {
    const { searchQuery, setSearchQuery } = usePromptLibrary();
    const handleChange = (event) => setSearchQuery(event.target.value);
    const handleReset = () => setSearchQuery('');
    return (<SearchInput placeholder={t({
            comment: 'Select prompt dialog - Search prompts placeholder',
            message: 'Search prompts',
        })} resetLabel={t({
            comment: 'Select prompt dialog - Reset search prompt input label',
            message: 'Clear search',
        })} value={searchQuery} onReset={handleReset} onChange={handleChange}/>);
}
