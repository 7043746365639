import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled, { css, keyframes } from 'styled-components';
export const chatLoaderDotStyles = css `
  display: inline-block;
  width: ${pxToRem(12)};
  height: ${pxToRem(12)};
  margin-left: ${themeGet('spacing.md')};
  border-radius: ${themeGet('borderRadius.circle')};
  background-image: ${themeGet('colors.ai.500')};
`;
const pulseAnimation = keyframes `
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.25);
  }
`;
export const ChatLoader = styled.div `
  ${chatLoaderDotStyles};

  animation: ${pulseAnimation} 1s infinite ease-in-out alternate;
`;
