import { plural } from '@lingui/macro';
import { Alert } from '@pocketlaw/tetris';
import { useLastApprovalRequest } from 'app/domains/esigning/hooks/useLastApprovalRequest';
import { useActiveDocument } from 'shared/domains/documents';
import { getRemainingDays } from './utils';
export function AutomaticReminderAlert() {
    const { id: documentId } = useActiveDocument();
    const { lastApprovalRequest } = useLastApprovalRequest(documentId);
    const { createdAt } = lastApprovalRequest || {};
    if (!createdAt) {
        return null;
    }
    const remainingDays = getRemainingDays(new Date(createdAt), new Date());
    const message = plural(remainingDays, {
        one: 'Automatic reminder will be sent out to pending approvers in # day.',
        other: 'Automatic reminder will be sent out to pending approvers in # days.',
    });
    return <Alert data-testid="automatic-reminder-alert" appearance="info" message={message}/>;
}
