import { Chip } from '@pocketlaw/tetris';
import { DesktopTableBodyCell } from '../styled';
import { getStatusLabel } from '../utils/getStatusLabel';
export function StatusCell(props) {
    const { expired } = props;
    const label = getStatusLabel(expired);
    return (<DesktopTableBodyCell headers="expired">
      <Chip variant="outline" appearance={expired ? 'danger' : 'secondary'} label={label}/>
    </DesktopTableBodyCell>);
}
