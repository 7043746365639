import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useActiveDocument } from 'shared/domains/documents';
import { QUERY } from './query';
export function useAssistantSuggestions() {
    const { id: documentId } = useActiveDocument();
    const [suggestions, setSuggestions] = useState([]);
    const [getSuggestions, { loading: fetchingSuggestions }] = useLazyQuery(QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            documentId,
        },
    });
    const clearSuggestions = () => {
        setSuggestions([]);
    };
    const requestSuggestions = () => {
        clearSuggestions();
        getSuggestions().then(({ data }) => {
            if (data === null || data === void 0 ? void 0 : data.suggestions) {
                setSuggestions(data.suggestions);
            }
        });
    };
    return { suggestions, clearSuggestions, fetchingSuggestions, requestSuggestions };
}
