import { breakpoints } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const ShowMobileCol = styled.col `
  ${breakpoints.sm} {
    display: none;
  }
`;
export const HideMobileCol = styled.col `
  ${breakpoints.xs} {
    display: none;
  }
`;
