import { themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${themeGet('spacing.md')};
`;
export const TextContainer = styled.div `
  text-align: center;
`;
