import { MenuHeader, MenuItem, pxToRem, themeGet, themeGetColor } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const StyledMenuHeader = styled(MenuHeader) `
  border-bottom: ${pxToRem(1)} solid ${themeGetColor('border.100')};
  padding-top: ${themeGet('spacing.sm')};
  padding-bottom: ${themeGet('spacing.sm')};
`;
export const StickyMenuItem = styled(MenuItem) `
  ${({ $selected }) => $selected &&
    `
      position: sticky;
      top:0;
      bottom:0;
  `};
`;
export const SearchInput = styled.input `
  display: flex;
  flex-grow: 1;
  font-size: ${themeGet('fontSize.medium')};
  line-height: ${themeGet('lineHeight.default')};
  color: ${themeGet('colors.text.300')};
  padding: ${themeGet('spacing.md')};

  outline: none;
  border: none;
`;
