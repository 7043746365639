import { createContext, useState } from 'react';
import { t } from '@lingui/macro';
import { ROOT, useFileBrowserContext } from 'app/domains/filesystem';
import { EntryKind } from 'shared/domains/apollo/generated/types';
import { useInitialFolder } from './useInitialFolder';
export const PickerContext = createContext(null);
export function PickerProvider(props) {
    const { initialEntryId, children, onSubmit } = props;
    const { location, selected, folderPermission } = useFileBrowserContext();
    const [showRootWarning, setShowRootWarning] = useState();
    const initialFolder = useInitialFolder(initialEntryId);
    const isRootSelected = !location || location === ROOT;
    const rootFolder = {
        id: ROOT,
        kind: EntryKind.Folder,
        disabled: false,
        name: t({
            message: 'Root',
            comment: 'Root folder label',
        }),
    };
    const handleSubmit = () => {
        const missingAccess = !folderPermission.canWrite() && folderPermission.canTraverse();
        if (missingAccess && !isRootSelected) {
            setShowRootWarning('access:write');
            return;
        }
        if (!showRootWarning && isRootSelected) {
            setShowRootWarning('warning:root');
        }
        else if (isRootSelected) {
            onSubmit(rootFolder);
        }
        else if (selected) {
            onSubmit(selected);
        }
        else if (initialFolder) {
            onSubmit(initialFolder);
        }
        else {
            onSubmit(rootFolder);
        }
    };
    const hideRootWarning = () => setShowRootWarning(undefined);
    const value = {
        showRootWarning,
        hideRootWarning,
        submit: handleSubmit,
    };
    return <PickerContext.Provider value={value}>{children}</PickerContext.Provider>;
}
