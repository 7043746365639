import { useEffect, useState } from 'react';
import { useSearchContext } from '../SearchProvider';
export function useSelected(results) {
    const [state, setState] = useState([]);
    const { values } = useSearchContext();
    const { offset } = values;
    const handleSetState = (action) => {
        const { type, payload } = action;
        if (type === 'select-all') {
            setState(results);
            return;
        }
        if (type === 'reset') {
            setState([]);
            return;
        }
        if (type === 'select' && payload) {
            const stateWithoutPayload = state.filter(item => item.entityId !== payload.entityId);
            if (stateWithoutPayload.length === state.length) {
                setState([...state, payload]);
            }
            else {
                setState(stateWithoutPayload);
            }
        }
    };
    useEffect(() => {
        setState([]);
    }, [offset]);
    return [state, handleSetState];
}
