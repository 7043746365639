import { t, Trans } from '@lingui/macro';
import { Box, Typography, Button } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { emails } from 'shared/domains/config';
import { IllustrationLeft } from '../PreReviewState/IllustrationLeft';
import { IllustrationLoader } from '../PreReviewState/IllustrationLoader';
import { IllustrationRight } from '../PreReviewState/IllustrationRight';
import { IllustrationContainer, StyledList, StyledListItem } from '../PreReviewState/styled';
export function NotAvailableState() {
    const listTexts = [
        t({
            comment: 'Document contract review - Review feature not available - List item 1',
            message: 'Playbook comparison',
        }),
        t({
            comment: 'Document contract review - Review feature not available - List item 1',
            message: 'Feedback via Pocketlaw ai assistant (Plai)',
        }),
        t({
            comment: 'Document contract review - Review feature not available - List item 1',
            message: 'Smart, intuitive clause rewriting',
        }),
    ];
    const emailHref = `mailto:${emails.support}`;
    return (<Box flexDirection="column" gap="xl" p="xl">
      <IllustrationContainer $loading={false}>
        <IllustrationLeft />
        <IllustrationRight />
        <IllustrationLoader />
      </IllustrationContainer>
      <Box flexDirection="column" gap="xl">
        <Box flexDirection="column" gap="lg">
          <Typography $appearance="300" $fontSize="large" $fontWeight="semibold">
            <Trans comment="Document contract review - Review feature not available - Title">
              8x speed on your reviews
            </Trans>
          </Typography>
          <Typography>
            <Trans comment="Document contract review - Review not started - Description">
              Get instant feedback and input on your contracts with Pocketlaw automated contract
              review.
            </Trans>
          </Typography>
          <StyledList>
            {listTexts.map(text => (<StyledListItem key={text}>
                <Typography>{text}</Typography>
              </StyledListItem>))}
          </StyledList>
        </Box>
        <Button appearance="primary" size="small" as="a" href={emailHref}>
          <Falcon icon="comment"/>
          <Trans comment="Document contract review - Review feature not available - Contact sales button label">
            Contact sales
          </Trans>
        </Button>
      </Box>
    </Box>);
}
