import { t } from '@lingui/macro';
import { useGetCategories } from './useGetCategories';
import { withFilterErrorBoundary } from '../FilterErrorBoundary';
import { SearchStringFilter } from '../SearchStringFilter';
export const templateCategoryFilterLabel = () => t({ message: 'Template category', comment: 'Insights filter: Template category' });
export const TemplateCategoryFilter = withFilterErrorBoundary(() => {
    const { data, error, loading } = useGetCategories();
    const disabled = Boolean(loading || error);
    return (<SearchStringFilter name="categoryId" items={data} label={templateCategoryFilterLabel()} loading={disabled}/>);
});
