import { useReactiveVar } from '@apollo/client';
import { Trans, t } from '@lingui/macro';
import { Dialog, DialogHeader, DialogContent, DialogFooter, Button } from '@pocketlaw/tetris';
import { useGoBack } from './useErrorActions';
import { documentState } from '../../documentState';
export function UnauthorizedError() {
    const state = useReactiveVar(documentState);
    const { goBack } = useGoBack();
    if (state !== 'unauthorized') {
        return null;
    }
    return (<Dialog>
      <DialogHeader title={t({
            message: 'Unauthorized',
            comment: 'Pleditor: Unauthorized error title in dialog',
        })}/>
      <DialogContent>
        <Trans comment="Pleditor: Unauthorized error text in dialog">
          You are not authorized to edit or view this document. Please contact the owner of the
          document.
        </Trans>
      </DialogContent>
      <DialogFooter>
        <Button appearance="danger" onClick={goBack}>
          <Trans comment="Pleditor: Unauthorized error go back button.">Go back</Trans>
        </Button>
      </DialogFooter>
    </Dialog>);
}
