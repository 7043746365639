export function getAggregationBuckets(data) {
    var _a;
    if (!data) {
        return [];
    }
    const searchResponseBucketedAggregation = (_a = data.search.aggregations) === null || _a === void 0 ? void 0 : _a.find(
    // eslint-disable-next-line no-underscore-dangle
    aggregation => aggregation.__typename === 'SearchResponseBucketedAggregation');
    // eslint-disable-next-line no-underscore-dangle
    if ((searchResponseBucketedAggregation === null || searchResponseBucketedAggregation === void 0 ? void 0 : searchResponseBucketedAggregation.__typename) !== 'SearchResponseBucketedAggregation') {
        return [];
    }
    return searchResponseBucketedAggregation.buckets;
}
