import { Trans } from '@lingui/macro';
import { TableHead, TableRow, TableHeadCell, TableHeaderText, TableBody } from '@pocketlaw/tetris';
import { useTemplateBrowserContext } from 'app/domains/templates';
import { Row } from './Row';
import { SkeletonRow } from './SkeletonRow';
import { PickerContent } from '../PickerContent';
import { StyledTable } from './styled';
export function PickerTableTemplates(props) {
    const { selectedTemplateId } = props;
    const { templates, loading } = useTemplateBrowserContext();
    const loadingTemplates = Array(50)
        .fill(null)
        .map((_, index) => index);
    const availableTemplates = templates.filter(template => !template.locked);
    return (<PickerContent>
      <StyledTable dense>
        <colgroup>
          <col width="auto"/>
          <col width="180"/>
          <col width="40"/>
        </colgroup>
        <TableHead>
          <TableRow>
            <TableHeadCell id="cell:name">
              <TableHeaderText>
                <Trans comment="Table column header: Title">Title</Trans>
              </TableHeaderText>
            </TableHeadCell>
            <TableHeadCell id="cell:language">
              <TableHeaderText>
                <Trans comment="Table column header: Language">Language</Trans>
              </TableHeaderText>
            </TableHeadCell>
            <TableHeadCell id="cell:select"/>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading
            ? loadingTemplates.map(index => <SkeletonRow key={index}/>)
            : availableTemplates.map(template => (<Row key={template.id} template={template} selectedTemplateId={selectedTemplateId}/>))}
        </TableBody>
      </StyledTable>
    </PickerContent>);
}
