import { IconButton, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { AlignRight, Container, Spacer } from './styled';
export function Header(props) {
    const { type, title, onClose, actions } = props;
    const closeButtonElement = (<IconButton $round size="medium" appearance="ghost" onClick={onClose}>
      <Falcon icon="xmark"/>
    </IconButton>);
    if (type === 'nested') {
        const { onGoBack } = props;
        return (<Container>
        <IconButton $round size="medium" appearance="ghost" onClick={onGoBack}>
          <Falcon icon="chevron-left"/>
        </IconButton>
        {title && (<Typography $appearance="300" $fontSize="large" $fontWeight="medium" $lineHeight="neutralized">
            {title}
          </Typography>)}
        <AlignRight>
          <Spacer>
            {actions}
            {closeButtonElement}
          </Spacer>
        </AlignRight>
      </Container>);
    }
    return (<Container>
      {title && (<Typography $appearance="300" $fontSize="large">
          {title}
        </Typography>)}
      <AlignRight>
        <Spacer>
          {actions}
          {closeButtonElement}
        </Spacer>
      </AlignRight>
    </Container>);
}
