import { Table, TableBody, unit } from '@pocketlaw/tetris';
import { EntriesTableHead } from './EntriesTableHead';
import { Row } from './Row';
import { useFileBrowserContext } from '../FileBrowserProvider';
import { NoSelectableFileMessage } from '../NoSelectableFileMessage';
/**
 * Displays the file browser table.
 */
export function FileBrowserTable() {
    const { entries, onSelect, selfId, loading, disabledAll, selected } = useFileBrowserContext();
    const handleSelect = (entry) => {
        const isSelectable = selfId !== entry.id && !entry.disabled;
        if (isSelectable) {
            onSelect(entry);
        }
    };
    if (!entries.length) {
        return <NoSelectableFileMessage />;
    }
    return (<Table dense>
      <colgroup>
        <col width="auto"/>
        <col width={unit(4)}/>
      </colgroup>
      <EntriesTableHead />
      <TableBody>
        {entries.map(entry => {
            var _a, _b, _c;
            return (<Row key={entry.id} entry={entry} isSelf={selfId === entry.id} disabled={loading ||
                    selfId === entry.id ||
                    entry.disabled ||
                    disabledAll ||
                    ((_a = entry.documentAi) === null || _a === void 0 ? void 0 : _a.enabled) === false} documentAiDisabledReason={(_c = (_b = entry.documentAi) === null || _b === void 0 ? void 0 : _b.disabledReason) !== null && _c !== void 0 ? _c : undefined} onSelect={handleSelect} selectedId={selected === null || selected === void 0 ? void 0 : selected.id} status={entry.status}/>);
        })}
      </TableBody>
    </Table>);
}
