import { t } from '@lingui/macro';
import { TableHead, VisuallyHidden } from '@pocketlaw/tetris';
import { HiddenTableRow, HiddenTableHeadCell } from './styled';
export function FolderPickerTableHead() {
    const iconLabel = t({
        message: `Icon`,
        comment: 'FolderPicker Dialog: (screen reader) table column label: icon',
    });
    const nameLabel = t({
        message: `Name`,
        comment: 'FolderPicker Dialog: (screen reader) table column label: name',
    });
    const selectLabel = t({
        message: `Select`,
        comment: 'FolderPicker Dialog: (screen reader) table column label: select (radio button)',
    });
    return (<TableHead>
      <HiddenTableRow>
        <HiddenTableHeadCell id="folderPickerIcon">
          <VisuallyHidden>{iconLabel}</VisuallyHidden>
        </HiddenTableHeadCell>
        <HiddenTableHeadCell id="folderPickerName">
          <VisuallyHidden>{nameLabel}</VisuallyHidden>
        </HiddenTableHeadCell>
        <HiddenTableHeadCell id="folderPickerSelect">
          <VisuallyHidden>{selectLabel}</VisuallyHidden>
        </HiddenTableHeadCell>
      </HiddenTableRow>
    </TableHead>);
}
