import { fromNavigator, fromUrl, multipleDetect } from '@lingui/detect-locale';
import { defaultLocale } from 'shared/domains/languages/config';
function getClosestMatchingLocale(localeCode, availableLocales) {
    return availableLocales.find(({ languageCode }) => localeCode.toLowerCase().startsWith(languageCode));
}
const lastDetectedLocaleStorageKey = 'lastDetectedLocale';
export function detectLocale(supportedLocales, preferredLocale) {
    var _a;
    const matchingLocales = multipleDetect(fromUrl('lang'), localStorage.getItem(lastDetectedLocaleStorageKey), preferredLocale, fromNavigator(), defaultLocale.code)
        .map(localeCode => getClosestMatchingLocale(localeCode, supportedLocales))
        .filter(locale => locale !== undefined);
    const detectedLocale = (_a = matchingLocales[0]) !== null && _a !== void 0 ? _a : supportedLocales[0];
    localStorage.setItem(lastDetectedLocaleStorageKey, detectedLocale.code);
    return detectedLocale;
}
