import { gql } from '@apollo/client';
export const removeFileSystemAclMutation = gql `
  mutation removeFileSystemAcl($aclId: ID!, $isUser: Boolean!, $isTeam: Boolean!) {
    deleteFileSystemAcl(id: $aclId) @include(if: $isUser) {
      affected
    }
    deleteFileSystemTeamAcl(id: $aclId) @include(if: $isTeam) {
      affected
    }
  }
`;
