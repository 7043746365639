import { useEffect } from 'react';
import { useWorker } from 'shared/domains/workers';
const workerInstance = new Worker(new URL('./preview.worker', import.meta.url));
export const usePreviewWorker = (params) => {
    const { keys, values, astBody } = params;
    const [postMessage, state] = useWorker(workerInstance);
    useEffect(() => {
        postMessage({ keys, values, astBody });
    }, [keys, values, astBody, postMessage]);
    return state;
};
