import { t, Trans } from '@lingui/macro';
import { CardHeader, Button, TabNav, Tab } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Link } from 'react-router-dom';
import { useTasks } from '../TasksProvider';
import { AddTaskButton } from '../styled';
import { TabState } from '../types';
import { ButtonWrapper } from './styled';
export function WidgetHeader(props) {
    const { title, hideLink } = props;
    const { displayAdd, setDisplayAdd } = useTasks();
    return (<CardHeader title={title} action={<ButtonWrapper>
          {!hideLink && (<Button as={Link} appearance="ghost" to="/tasks">
              <Trans comment="Link to task table page">Show all</Trans>
            </Button>)}
          <AddTaskButton aria-label={t `Add task`} onClick={() => setDisplayAdd(true)} disabled={displayAdd} $round>
            <Falcon icon="plus" $size={16}/>
          </AddTaskButton>
        </ButtonWrapper>}>
      <TabNav>
        <Tab data-testid={`task-tab-${TabState.UNCOMPLETED}`} id={TabState.UNCOMPLETED}>
          <Trans>To-do</Trans>
        </Tab>
        <Tab data-testid={`task-tab-${TabState.COMPLETED}`} id={TabState.COMPLETED}>
          <Trans>Completed</Trans>
        </Tab>
      </TabNav>
    </CardHeader>);
}
