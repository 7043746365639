import { gql } from '@apollo/client';
export const updateTaskCompletedMutation = gql `
  mutation updateTaskCompleted($id: UUID!, $isCompleted: Boolean!, $status: TaskStatusEnumEnum!) {
    updateOneTask(pk_columns: { id: $id }, _set: { is_completed: $isCompleted, status: $status }) {
      id
      isCompleted: is_completed
      status
    }
  }
`;
