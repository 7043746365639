import { pxToRem } from '@pocketlaw/tetris';
const sideways = (viewport) => viewport.rotation % 180 !== 0;
const top = ({ viewport, transform, scale }) => {
    const [, , offsetX, offsetY, x, y] = transform;
    const [, yMin, , yMax] = viewport.viewBox;
    if (sideways(viewport)) {
        return `${pxToRem((x + offsetX + yMin) * scale)}`;
    }
    return `${pxToRem((yMax - (y + offsetY)) * scale)}`;
};
const left = ({ viewport, transform, scale }) => {
    const [, , , , x, y] = transform;
    const [xMin] = viewport.viewBox;
    if (sideways(viewport)) {
        return `${pxToRem((y - xMin) * scale)}`;
    }
    return `${pxToRem((x - xMin) * scale)}`;
};
const fontSize = ({ viewport, transform, scale }) => {
    const [fontHeight, fontWidth] = transform;
    if (sideways(viewport)) {
        return `${pxToRem(fontWidth * scale)}`;
    }
    return `${pxToRem(fontHeight * scale)}`;
};
const width = (props) => pxToRem(props.width * props.scale);
const height = (props) => pxToRem(props.height * props.scale);
export const deriveStyle = (props) => ({
    top: top(props),
    left: left(props),
    width: width(props),
    height: height(props),
    fontSize: fontSize(props),
});
