import { TextArea } from '@pocketlaw/tetris';
import { useField } from 'formik';
import { useEsigning } from 'app/domains/esigning/components/EsigningProvider';
export function MessageField() {
    const { submittingCreation } = useEsigning();
    const [message, , { setValue }] = useField('message');
    const handleBlur = (event) => {
        setValue(event.target.value.trim());
    };
    return <TextArea stretch disabled={submittingCreation} {...message} onBlur={handleBlur}/>;
}
