import { gql } from '@apollo/client';
export const updatePreferredLocaleMutation = gql `
  mutation updatePreferredLocale($userId: String!, $locale: String) {
    updateUser(_set: { preferred_locale: $locale }, where: { id: { _eq: $userId } }) {
      returning {
        id
      }
    }
  }
`;
