import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Table, TableHead, TableRow, TableHeaderText, TableBody, TableHeadCell, TableBodyCell, Typography, } from '@pocketlaw/tetris';
import { useField } from 'formik';
import { namedRoles } from 'app/domains/users/hooks/useRole/namedRoles';
export function SummarizeRoleTable() {
    const [field] = useField('role');
    const { i18n } = useLingui();
    const tmpI18n = i18n;
    const translatedRole = namedRoles[field.value];
    return (<Table border>
      <TableHead>
        <TableRow>
          <TableHeadCell id="role">
            <TableHeaderText>
              {t({ message: 'Role', comment: 'user invite modal - summarize role title' })}
            </TableHeaderText>
          </TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableBodyCell headers="role">
            <Typography $appearance="300">{tmpI18n._(translatedRole)}</Typography>
          </TableBodyCell>
        </TableRow>
      </TableBody>
    </Table>);
}
