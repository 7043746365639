import { useRef } from 'react';
import { ChipAction } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { ChipDropdown } from 'shared/domains/common-ui';
import { formatDateLabel } from './utils/formatDateLabel';
import { CalendarDropdown } from '../CalendarDropdown';
export function DateFilter(props) {
    const { onSubmit, clear, label, value } = props;
    const dropdownRef = useRef();
    const formattedDateLabel = formatDateLabel(label, [value === null || value === void 0 ? void 0 : value.startDate, value === null || value === void 0 ? void 0 : value.endDate]);
    const handleSelect = (dateRange) => {
        var _a;
        onSubmit(dateRange);
        (_a = dropdownRef.current) === null || _a === void 0 ? void 0 : _a.toggleDropdown();
    };
    return (<ChipDropdown ref={dropdownRef} label={formattedDateLabel} action={value ? (<ChipAction onClick={clear}>
            <Falcon icon="remove"/>
          </ChipAction>) : undefined} dropdownContent={<CalendarDropdown initialValue={value} onSubmit={handleSelect}/>}/>);
}
