var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useMemo } from 'react';
import { findLocalizedTemplateId } from './findLocalizedTemplateId';
function useNormalizedComposers(composers, currentPlan) {
    return useMemo(() => composers.map(composer => {
        var _a, _b, _c;
        const { composerPlans, metadata, category, templates, marketCode } = composer, rest = __rest(composer, ["composerPlans", "metadata", "category", "templates", "marketCode"]);
        const id = findLocalizedTemplateId(templates !== null && templates !== void 0 ? templates : []);
        const marketLocale = (templates === null || templates === void 0 ? void 0 : templates.map(({ marketLocaleCode }) => marketLocaleCode).sort((a, b) => (a > b ? 1 : -1))) || [];
        return Object.assign(Object.assign({}, rest), { locked: !((_a = composerPlans === null || composerPlans === void 0 ? void 0 : composerPlans.map(({ plan }) => plan)) === null || _a === void 0 ? void 0 : _a.find(plan => (plan === null || plan === void 0 ? void 0 : plan.id) === (currentPlan === null || currentPlan === void 0 ? void 0 : currentPlan.id))), name: ((_b = metadata[0]) === null || _b === void 0 ? void 0 : _b.name) || '', defaultTemplateId: id, marketLocale, jurisdiction: marketCode, category: {
                title: ((_c = category === null || category === void 0 ? void 0 : category.metadata[0]) === null || _c === void 0 ? void 0 : _c.title) || '',
                themeId: (category === null || category === void 0 ? void 0 : category.themeId) || '',
            } });
    }), [composers, currentPlan]);
}
export { useNormalizedComposers };
