import { breakpoints, pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const StyledSection = styled.div `
  flex-direction: row;
  display: flex;
  gap: ${themeGet('spacing.3xl')};
  padding: ${themeGet('spacing.xl')} 0;
  flex-wrap: wrap;

  ${breakpoints.sm} {
    flex-wrap: nowrap;
  }
`;
export const NoteContainer = styled.div `
  flex: 1 0 ${pxToRem(350)};
  max-width: ${pxToRem(350)};
  display: flex;
  flex-direction: column;
  gap: ${themeGet('spacing.2xl')};
`;
export const RadioCardContainer = styled.div `
  max-width: calc(100% - ${pxToRem(350)} - ${themeGet('spacing.3xl')});
  display: flex;
  flex-direction: column;
  gap: ${themeGet('spacing.xl')};
`;
