import { t } from '@lingui/macro';
import { IconButton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { AttachmentItem } from './AttachmentItem';
export function AttachmentWidget(props) {
    const { attachment } = props;
    if (!attachment) {
        return null;
    }
    const handlePreview = () => {
        window.open(attachment.url, '_blank');
    };
    return (<AttachmentItem isEmbedded attachment={attachment} endElement={<IconButton $round size="small" appearance="ghost" aria-label={t({
                comment: 'Preview attachment button tooltip',
                message: 'Preview attachment',
            })} onClick={handlePreview}>
          <Falcon icon="eye"/>
        </IconButton>}/>);
}
