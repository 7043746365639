import { useMutation } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { REMOVE_INVITE } from './mutation';
export function useRemoveInvite() {
    const { addToast } = useToast();
    const [mutate, { loading, error }] = useMutation(REMOVE_INVITE, {
        onCompleted() {
            addToast({
                appearance: 'success',
                title: t({
                    message: 'Successfully removed invite',
                    comment: 'Success message when removing invite',
                }),
            });
        },
        onError() {
            addToast({
                appearance: 'danger',
                title: t({
                    message: 'Failed to remove invite',
                    comment: 'Message when removing invite fails',
                }),
            });
        },
        update(cache, { data }) {
            const { updateUserInvite } = data || {};
            if (updateUserInvite) {
                const { id, __typename } = updateUserInvite.returning[0];
                cache.modify({
                    id: cache.identify({ id, __typename }),
                    fields(_, { DELETE }) {
                        return DELETE;
                    },
                });
            }
        },
    });
    const removeInvite = (id) => {
        mutate({ variables: { id } });
    };
    return { removeInvite, loading, error };
}
