import { useEffect } from 'react';
import { t } from '@lingui/macro';
import { Box } from '@pocketlaw/tetris';
import { usePlai } from 'app/domains/review';
import { ReviewStatus } from 'shared/domains/apollo/generated/types';
import { IllustrationLeft } from './IllustrationLeft';
import { IllustrationLoader } from './IllustrationLoader';
import { IllustrationRight } from './IllustrationRight';
import { NoReviewYetState } from './NoReviewYetState';
import { ReviewInProgressState } from './ReviewInProgressState';
import { IllustrationContainer } from './styled';
const POLL_INTERVAL = 5000;
export function PreReviewState(props) {
    const { noReviewYet, reviewInProgress } = props;
    const { review, reviewing, requestReview, requestingReview, startPollingReview, stopPollingReview, cancelDocumentReview, cancellingReview, } = usePlai();
    const showLoading = reviewing || requestingReview || cancellingReview;
    const cancelButtonDisabled = cancellingReview || (review === null || review === void 0 ? void 0 : review.status) !== ReviewStatus.Loading;
    useEffect(() => {
        if (reviewing) {
            startPollingReview(POLL_INTERVAL);
            return () => {
                stopPollingReview();
            };
        }
        return undefined;
    }, [reviewing, startPollingReview, stopPollingReview]);
    const handleCancelReview = () => {
        if (review === null || review === void 0 ? void 0 : review.id) {
            cancelDocumentReview(review.id);
        }
    };
    const listTexts = [
        t({
            comment: 'Document contract review - Review not started - List item 1',
            message: 'Playbook comparison',
        }),
        t({
            comment: 'Document contract review - Review not started - List item 1',
            message: 'Feedback via Pocketlaw ai assistant (Plai)',
        }),
        t({
            comment: 'Document contract review - Review not started - List item 1',
            message: 'Smart, intuitive clause rewriting',
        }),
    ];
    return (<Box flexDirection="column" gap="xl" p="xl">
      <IllustrationContainer $loading={showLoading}>
        <IllustrationLeft />
        <IllustrationRight />
        <IllustrationLoader />
      </IllustrationContainer>
      {noReviewYet && <NoReviewYetState listTexts={listTexts} onStartReview={requestReview}/>}
      {reviewInProgress && (<ReviewInProgressState disabled={cancelButtonDisabled} onCancelReview={handleCancelReview}/>)}
    </Box>);
}
