export const normalizePdf = (filesystemFile) => {
    const { size, name, path, type, documentId, companyId } = filesystemFile;
    return {
        documentId,
        companyId,
        size,
        name,
        path,
        mimeType: type,
    };
};
