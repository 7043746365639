import { object, string, array, mixed, boolean } from 'yup';
import { AclAction } from 'shared/domains/apollo/generated/types';
const actions = array()
    .of(mixed().oneOf([AclAction.Read, AclAction.Write, AclAction.Delete]))
    .required();
export const FileSystemManagerSchema = object({
    name: string().required(),
    parentId: string().nullable(),
    autoTag: boolean().required(),
    userAcls: array()
        .ensure()
        .of(object().shape({
        userId: string().required(),
        actions,
    }))
        .required(),
    teamAcls: array()
        .ensure()
        .of(object().shape({
        teamId: string().required(),
        actions,
    }))
        .required(),
});
