import { Trans, t } from '@lingui/macro';
import { Box, Button, Chip, Menu, MenuBody, MenuList, Typography, pxToRem, } from '@pocketlaw/tetris';
import { AttachmentItem } from './AttachmentItem';
import { AttachmentPlaceholder } from './styled';
export function SelectAttachmentWidget(props) {
    const { root, attachments, modelElement, hideMenu, onClose, onSelect, isEmbedded } = props;
    const handleClose = () => {
        root.unmount();
        onClose(modelElement);
    };
    const handleInsert = (attachment) => {
        root.unmount();
        onSelect(attachment);
    };
    if (hideMenu) {
        return <AttachmentPlaceholder />;
    }
    return (<Menu open closeOnOutsideClick onClose={handleClose} target={<AttachmentPlaceholder />} options={{ placement: 'bottom' }}>
      <MenuBody>
        <MenuList>
          <Box width={pxToRem(480)} flexDirection="column" p="xl" gap="md">
            <Typography $fontSize="small" $appearance="100">
              <Trans comment="Select attachment to insert into document label">
                Select attachment
              </Trans>
            </Typography>
            {attachments.map(attachment => (<AttachmentItem key={attachment.id} attachment={attachment} endElement={<Box alignItems="center" gap="md">
                    {isEmbedded(attachment.id) && (<Chip size="small" appearance="info" label={t({
                        comment: 'Document attachment embedded label',
                        message: 'Embedded',
                    })}/>)}
                    <Button size="xs" appearance="secondary" onClick={() => handleInsert(attachment)}>
                      <Trans comment="Insert attachment into document label">Insert</Trans>
                    </Button>
                  </Box>}/>))}
          </Box>
        </MenuList>
      </MenuBody>
    </Menu>);
}
