import { Trans } from '@lingui/macro';
import { Chip } from '@pocketlaw/tetris';
import { ApprovalRequestApproverStatus } from 'shared/domains/apollo/generated/types';
export const getApproverStatusBadge = (status) => {
    switch (status) {
        case ApprovalRequestApproverStatus.Approved: {
            return (<Chip appearance="success" size="small" label={<Trans comment="Document approver status badge - Approved">Approved</Trans>}/>);
        }
        case ApprovalRequestApproverStatus.Rejected: {
            return (<Chip appearance="danger" size="small" label={<Trans comment="Document approver status badge - Rejected">Rejected</Trans>}/>);
        }
        case ApprovalRequestApproverStatus.Pending: {
            return (<Chip appearance="secondary" size="small" label={<Trans comment="Document approver status badge - Pending">Pending</Trans>}/>);
        }
        default: {
            return null;
        }
    }
};
