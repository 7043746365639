import { Trans } from '@lingui/macro';
import { Table, TableBody, TableBodyCell, TableCellEmpty, TableCellText, TableHead, TableHeadCell, TableHeaderText, TableRow, } from '@pocketlaw/tetris';
import { TERMS_AGGREGATION_MISSING_VALUE_NAME } from '../../../constants';
import { EmptyVisualization } from '../EmptyVisualization';
import { VisualizationMeta } from '../VisualizationMeta';
import { Container } from './styled';
export function TableVisualization(props) {
    const { entries, size } = props;
    const missing = entries.find(entry => entry.name === TERMS_AGGREGATION_MISSING_VALUE_NAME);
    const entriesWithoutMissing = entries.filter(entry => entry.name !== TERMS_AGGREGATION_MISSING_VALUE_NAME);
    const fillCount = (size || entriesWithoutMissing.length) - entries.length;
    const fillRows = Array.from({ length: fillCount }).map((_, index) => ({ id: index }));
    const showVisualizationMeta = Boolean(missing === null || missing === void 0 ? void 0 : missing.value);
    if (!entriesWithoutMissing.length) {
        return <EmptyVisualization />;
    }
    return (<Container>
      {showVisualizationMeta && <VisualizationMeta missing={missing === null || missing === void 0 ? void 0 : missing.value}/>}
      <Table dense>
        <TableHead>
          <TableRow>
            <TableHeadCell id="name">
              <TableHeaderText>
                <Trans comment="Insights: Table widget: Name column">Name</Trans>
              </TableHeaderText>
            </TableHeadCell>
            <TableHeadCell id="value" maxWidth={100}>
              <TableHeaderText>
                <Trans comment="Insights: Table widget: Value column">Value</Trans>
              </TableHeaderText>
            </TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {entriesWithoutMissing.map(entry => (<TableRow key={entry.name}>
              <TableBodyCell headers="name" maxWidth={300}>
                <TableCellText primary={entry.name}/>
              </TableBodyCell>
              <TableBodyCell headers="value" maxWidth={100}>
                <TableCellText primary={`${entry.value}`}/>
              </TableBodyCell>
            </TableRow>))}
          {fillRows.map(row => (<TableRow key={row.id}>
              <TableBodyCell headers="name">
                <TableCellEmpty />
              </TableBodyCell>
              <TableBodyCell headers="value" maxWidth={100}>
                <TableCellEmpty />
              </TableBodyCell>
            </TableRow>))}
        </TableBody>
      </Table>
    </Container>);
}
