import { unit } from '@pocketlaw/tetris';
import { Text } from '@pocketlaw/tetris/deprecated';
import styled from 'styled-components';
import { highlightStyles, wordBreak } from 'app/domains/global-search/components/styled';
export const Container = styled.div `
  display: flex;
  gap: ${unit(2)};
`;
export const StyledText = styled(Text) `
  display: block;
  ${wordBreak}

  mark {
    ${highlightStyles}
  }
`;
