var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useRef, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Box, Button, Loader, Popover, TextInput, useToast } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { ROOT, useFileBrowserContext } from 'app/domains/filesystem';
import { useCreateFolder } from './useCreateFolder';
export function CreateFolderPopover() {
    const [open, setOpen] = useState(false);
    const [folderName, setFolderName] = useState('');
    const [createFolder, { loading }] = useCreateFolder();
    const { location, folderPermission } = useFileBrowserContext();
    const { addToast } = useToast();
    const inputRef = useRef(null);
    const disabled = !folderPermission.canWrite();
    const close = () => {
        setOpen(false);
        setFolderName('');
    };
    const toggle = () => {
        if (open) {
            close();
        }
        else {
            setOpen(true);
        }
    };
    const handleChangeName = (event) => {
        setFolderName(event.target.value);
    };
    const handleCreateFolder = () => __awaiter(this, void 0, void 0, function* () {
        var _a;
        try {
            yield createFolder({ name: folderName, parentId: location || ROOT });
            addToast({
                appearance: 'success',
                title: t({ comment: 'Folder created success message', message: 'Folder created' }),
            });
            setFolderName('');
            close();
        }
        catch (_b) {
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            addToast({
                appearance: 'danger',
                title: t({ comment: 'Folder created error message', message: 'Could not create folder' }),
            });
        }
    });
    return (<Popover open={open} onClose={close} target={<Button size="small" appearance="secondary" onClick={toggle} disabled={disabled}>
          <Trans comment="Create folder label">Create folder</Trans>
        </Button>}>
      <Box alignItems="center" gap="md">
        <TextInput ref={inputRef} placeholder={t({ comment: 'Folder name placeholder label', message: 'Folder name...' })} adornmentLeft={<Falcon icon="folder"/>} value={folderName} onChange={handleChangeName}/>
        <Button disabled={loading} onClick={handleCreateFolder}>
          <Trans comment="Create folder button label">Create</Trans>
          {loading && <Loader />}
        </Button>
      </Box>
    </Popover>);
}
