export const transformResultsToInputs = (results) => results.map(({ uploadedFile, originalFile }) => ({
    name: originalFile.name,
    relativePath: originalFile.webkitRelativePath,
    file: {
        name: uploadedFile.name,
        path: uploadedFile.path,
        size: uploadedFile.size,
        mimeType: uploadedFile.type,
    },
}));
