var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { MUTATION } from './mutation';
export const useRemoveTaskAssignees = () => {
    const [mutation, { error, loading }] = useMutation(MUTATION);
    const removeTaskAssignees = (options) => __awaiter(void 0, void 0, void 0, function* () {
        const { taskId, assigneeIds } = options;
        if (assigneeIds.length === 0) {
            return;
        }
        yield mutation({
            variables: { ids: assigneeIds, taskId },
            update: (cache, { data }) => {
                const { returning } = (data === null || data === void 0 ? void 0 : data.deleteTaskAssignee) || {};
                if (taskId && returning) {
                    const typename = 'Task';
                    const id = cache.identify({
                        __typename: typename,
                        id: taskId,
                    });
                    cache.modify({
                        id,
                        fields: {
                            assignees(existing = [], { readField }) {
                                const newAssignees = existing.filter((assignee) => !returning.find(removed => removed.id === readField('id', assignee)));
                                return [...newAssignees];
                            },
                        },
                    });
                }
            },
        });
    });
    return { removeTaskAssignees, error, loading };
};
