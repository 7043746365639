var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { isComposedDocument, isFreeTextDocument, isUploadedDocument, } from 'shared/domains/documents';
import { MimeTypesByExtension, getFiletypeName, } from 'shared/domains/filesystem/utils/fileExtensions';
import { downloadSignedFile } from './downloadSignedFile';
import { renderDocxToPdf } from './renderDocxToPdf';
import { renderHtmlToPdf } from './renderHtmlToPdf';
import { renderPlastToDocx } from './renderPlastToDocx';
import { isSentForEsigning, getFilename } from './utils';
export function useDownloadOptions(options) {
    const filetypeName = getFiletypeName();
    const { document, onError, onSuccess } = options;
    const { type, lastSignRequest, id, name, files } = document || {};
    const [loading, setLoading] = useState(false);
    const documentId = id;
    const generatedDocument = isFreeTextDocument({ type }) || isComposedDocument({ type });
    const uploadedDocument = isUploadedDocument({ type });
    const isDocxFile = Boolean(files === null || files === void 0 ? void 0 : files.find(file => file.mimeType === MimeTypesByExtension.Docx));
    const activeEsigning = isSentForEsigning(lastSignRequest === null || lastSignRequest === void 0 ? void 0 : lastSignRequest.status);
    const filename = getFilename(name);
    const renderArguments = { documentId, name: filename };
    const [file] = files || [];
    function handleFetch(fetchFunc) {
        return __awaiter(this, void 0, void 0, function* () {
            setLoading(true);
            try {
                const signedUrl = yield fetchFunc(renderArguments);
                onSuccess(signedUrl);
            }
            catch (error) {
                onError();
            }
            setLoading(false);
        });
    }
    const htmlToPDF = {
        type: MimeTypesByExtension.Pdf,
        name: filetypeName[MimeTypesByExtension.Pdf],
        fetch: () => handleFetch(renderHtmlToPdf),
    };
    const plastToDocx = {
        type: MimeTypesByExtension.Docx,
        name: filetypeName[MimeTypesByExtension.Docx],
        fetch: () => handleFetch(renderPlastToDocx),
    };
    const docxToPDF = {
        type: MimeTypesByExtension.Pdf,
        name: filetypeName[MimeTypesByExtension.Pdf],
        fetch: () => handleFetch(renderDocxToPdf),
    };
    const docxDownload = {
        type: MimeTypesByExtension.Docx,
        name: filetypeName[MimeTypesByExtension.Docx],
        fetch: () => handleFetch(() => downloadSignedFile(Object.assign(Object.assign({}, renderArguments), { mimeType: MimeTypesByExtension.Docx }))),
    };
    const signedFileDownload = {
        type: MimeTypesByExtension.Pdf,
        name: filetypeName[MimeTypesByExtension.Pdf],
        fetch: () => handleFetch(() => downloadSignedFile(Object.assign(Object.assign({}, renderArguments), { mimeType: MimeTypesByExtension.Pdf }))),
    };
    const downloadMethods = [];
    if (activeEsigning) {
        downloadMethods.push(signedFileDownload);
    }
    else {
        if (generatedDocument) {
            downloadMethods.push(htmlToPDF, plastToDocx);
        }
        if (uploadedDocument && isDocxFile) {
            downloadMethods.push(docxToPDF, docxDownload);
        }
        if (uploadedDocument && isDocxFile === false) {
            const mimeType = file.mimeType;
            downloadMethods.push({
                type: mimeType,
                name: filetypeName[mimeType],
                fetch: () => handleFetch(() => downloadSignedFile(Object.assign(Object.assign({}, renderArguments), { mimeType }))),
            });
        }
    }
    return { loading, files: downloadMethods };
}
