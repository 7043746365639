import { useHistory } from 'react-router-dom';
import { usePreview } from 'shared/domains/documents/hooks/usePreview';
export const useGoBack = () => {
    const { document } = usePreview();
    const { folderId } = document || {};
    const history = useHistory();
    const goBack = () => {
        history.push(`/files/${folderId}`);
    };
    const reload = () => {
        window.location.reload();
    };
    return { goBack, reload };
};
