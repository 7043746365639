import { useQuery } from '@apollo/client';
import { getRole } from 'app/domains/users/utils';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { userRoleQuery } from './query';
export const useGetRole = (id) => {
    const account = useAccount();
    const currentUserId = account.data.id;
    const userId = id || currentUserId;
    const { data, loading, error } = useQuery(userRoleQuery, {
        skip: !userId,
        variables: {
            id: userId || '',
        },
    });
    const { role, canManageMember } = (data === null || data === void 0 ? void 0 : data.companyUser) || {};
    const userRole = getRole(role, canManageMember);
    return {
        role: userRole,
        loading,
        error,
    };
};
