import { t } from '@lingui/macro';
export function getStatusLabel(expired) {
    const expiredLabel = t({
        message: 'Expired',
        comment: 'invites table: chip label for expired invites',
    });
    const pendingLabel = t({
        message: 'Pending',
        comment: 'invites table: chip label for pending invites',
    });
    if (expired) {
        return expiredLabel;
    }
    return pendingLabel;
}
