var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { setContext } from '@apollo/client/link/context';
import { createBrowserHistory } from 'history';
import * as auth from 'external/domains/auth/passwordless/client';
const history = createBrowserHistory();
/**
 * If not possible to renew session from Auth0 we will
 * push the user to the authentication screen.
 */
function handleAuthError() {
    return history.push({
        pathname: '/auth',
        search: 'error=session_expired',
    });
}
/**
 * Attach JWT to Authorization header on each request.
 * Check if session has expired, if so try to refresh
 * it with .checkSession()
 */
const authHeaders = setContext(() => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const expiredSession = auth.expiredSession();
    if (expiredSession) {
        try {
            yield auth.checkSession();
        }
        catch (error) {
            return handleAuthError();
        }
    }
    const authState = auth.state.get();
    const headers = {
        Authorization: `Bearer ${(_a = authState.credentials) === null || _a === void 0 ? void 0 : _a.accessToken}`,
    };
    return {
        headers,
    };
}));
export default authHeaders;
