import React from 'react';
import { useMobile } from 'shared/domains/common-ui';
import { useDocumentDrawer } from 'shared/domains/documents';
import { DrawerContainer, DrawerContainerOffset, DrawerInnerContainer, StyledDrawer, } from './styled';
export const TEST_ID_MOBILE = 'document-drawer-mobile';
export const TEST_ID_DESKTOP = 'document-drawer-desktop';
export function Drawer() {
    const { activeComponent, expanded } = useDocumentDrawer();
    const isOpen = Boolean(activeComponent);
    const isMobile = useMobile();
    if (isMobile) {
        return (<StyledDrawer data-testid={TEST_ID_MOBILE} open={isOpen} position="right">
        {activeComponent}
      </StyledDrawer>);
    }
    return (<React.Fragment>
      <DrawerContainer $expanded={expanded} $open={isOpen}>
        {isOpen && (<DrawerInnerContainer data-testid={TEST_ID_DESKTOP}>
            {activeComponent}
          </DrawerInnerContainer>)}
      </DrawerContainer>
      <DrawerContainerOffset $open={isOpen}/>
    </React.Fragment>);
}
