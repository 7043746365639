var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Trans, t } from '@lingui/macro';
import { ConfirmDialog, Avatar, useToast, LoaderOverlay } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useDeleteEntry } from 'app/domains/filesystem';
import { Entries } from './Entries';
import { useFolderEntries } from './useFolderEntries';
export function DeleteFolderDialog(props) {
    const { onDeleted, payload, onClose } = props;
    const { addToast } = useToast();
    const [deleteEntry, { loading }] = useDeleteEntry(payload.id, { onDeleted });
    const { data, loading: loadingEntries } = useFolderEntries(payload.id);
    const { entries, folderName } = data;
    const handleConfirm = () => __awaiter(this, void 0, void 0, function* () {
        try {
            yield deleteEntry();
            addToast({
                appearance: 'success',
                title: t({
                    comment: 'Delete folder dialog - successfully deleted folder toast title',
                    message: 'Folder deleted',
                }),
                message: t({
                    comment: 'Delete folder dialog - successfully deleted folder toast message',
                    message: 'The folder and its content has been deleted',
                }),
            });
            onClose();
        }
        catch (e) {
            addToast({
                appearance: 'danger',
                title: t({
                    comment: 'Delete folder dialog - error when trying to delete folder toast title',
                    message: 'Could not delete folder',
                }),
                message: t({
                    comment: 'Delete folder dialog - error when trying to delete folder toast message',
                    message: 'Something went wrong when trying to delete the folder, please try again',
                }),
            });
        }
    });
    const handleCancel = () => onClose();
    return (<ConfirmDialog appearance="danger" size="sm" loading={loading} heading={<Trans comment="Delete folder dialog - dialog header label">
          Do you want to delete this folder?
        </Trans>} confirm={<Trans comment="Delete folder dialog - delete button label">Delete</Trans>} cancel={<Trans comment="Delete folder dialog - cancel button label">Cancel</Trans>} onConfirm={handleConfirm} onCancel={handleCancel} media={<Avatar appearance="danger" shape="square" size="lg">
          <Falcon icon="folder-xmark"/>
        </Avatar>}>
      <LoaderOverlay enabled={loadingEntries} label={t({
            comment: 'Delete folder dialog - loading folder entries label',
            message: 'Loading folder entries...',
        })}>
        <Entries folderName={folderName} entries={entries}/>
      </LoaderOverlay>
    </ConfirmDialog>);
}
