import { CompanyFeaturesProvider } from 'app/domains/companies';
import { AppVersionAlert } from 'shared/domains/common-ui';
import { FeatureFlagSettings } from 'shared/domains/featureFlag';
export function GlobalProviders(props) {
    const { children } = props;
    return (<CompanyFeaturesProvider>
      {children}
      <AppVersionAlert />
      <FeatureFlagSettings />
    </CompanyFeaturesProvider>);
}
