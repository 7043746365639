import { useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { CalendarContainer, Calendar, Typography, Button } from '@pocketlaw/tetris';
import { DateButton } from '../DateButton';
import { Wrapper } from './styled';
export function CalendarDropdown(props) {
    const { onSubmit, initialValue } = props;
    const [dateField, setDateField] = useState(undefined);
    const [dateRange, setDateRange] = useState(initialValue);
    if (dateField) {
        const handleChange = (date) => {
            setDateRange(Object.assign(Object.assign({}, dateRange), { [dateField]: date }));
            setDateField(undefined);
        };
        return (<CalendarContainer>
        <Calendar value={dateRange === null || dateRange === void 0 ? void 0 : dateRange[dateField]} min={dateField === 'endDate' ? dateRange === null || dateRange === void 0 ? void 0 : dateRange.startDate : undefined} max={dateField === 'startDate' ? dateRange === null || dateRange === void 0 ? void 0 : dateRange.endDate : undefined} onChange={date => handleChange(date)}/>
      </CalendarContainer>);
    }
    return (<Wrapper>
      <Typography>
        <Trans comment="Tasks filter - title to select dates to filter on">
          Select a start- and/or end date to apply
        </Trans>
      </Typography>
      <DateButton date={dateRange === null || dateRange === void 0 ? void 0 : dateRange.startDate} label={t({
            message: 'Date from',
            comment: 'tasks filter - placeholder text for date from',
        })} onClick={() => setDateField('startDate')}/>
      <DateButton date={dateRange === null || dateRange === void 0 ? void 0 : dateRange.endDate} label={t({
            message: 'Date to',
            comment: 'tasks filter - placeholder text for date to',
        })} onClick={() => setDateField('endDate')}/>
      <Button size="small" appearance="primary" onClick={() => onSubmit(dateRange)}>
        <Trans comment="tasks filter - button to apply filter">Apply</Trans>
      </Button>
    </Wrapper>);
}
