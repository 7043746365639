import { t } from '@lingui/macro';
import { Chip } from '@pocketlaw/tetris';
import { ComponentErrorBoundary } from '../ComponentErrorBoundary';
function FilterErrorFallback() {
    const label = t({ message: 'Error', comment: 'Insights: Error filter' });
    const secondary = t({ message: 'Filter had a problem', comment: 'Insights: Error filter' });
    return <Chip appearance="danger" size="large" label={label} secondary={secondary}/>;
}
export function withFilterErrorBoundary(FilterComponent) {
    return function wrappedFilterComponent(props) {
        return (<ComponentErrorBoundary fallback={<FilterErrorFallback />}>
        <FilterComponent {...props}/>
      </ComponentErrorBoundary>);
    };
}
