import { t } from '@lingui/macro';
import { Alert } from '@pocketlaw/tetris';
import { useFileBrowserContext } from 'app/domains/filesystem';
import { AlertContainer } from './styled';
export function FolderPermissionWarning() {
    const { folderPermission } = useFileBrowserContext();
    const { canWrite, canTraverse } = folderPermission;
    const onlyTraverseAccess = !canWrite() && canTraverse();
    if (onlyTraverseAccess) {
        return (<AlertContainer>
        <Alert appearance="warning" title={t({
                message: 'Missing access',
                comment: 'Alert title - folder picker when lacking permission to write',
            })} message={t({
                message: 'You do not have write access to this folder. You can only see the content that has been shared with you.',
                comment: 'Alert body - folder picker when lacking permission to write',
            })}/>
      </AlertContainer>);
    }
    return null;
}
