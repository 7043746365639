import { Falcon } from '@pocketlaw/tetris/macro';
import { IllustrationLoaderContainer } from '../styled';
import { SpinnerIcon, SpinnerWrapper, SpinnerBackground, SpinnerForeground } from './styled';
export function IllustrationLoader() {
    return (<IllustrationLoaderContainer>
      <SpinnerWrapper>
        <SpinnerIcon>
          <Falcon $size={22} icon="sparkles"/>
        </SpinnerIcon>
        <SpinnerBackground />
        <SpinnerForeground />
      </SpinnerWrapper>
    </IllustrationLoaderContainer>);
}
