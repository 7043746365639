import { useEsigningProviderMetadata } from 'app/domains/esigning/hooks/useEsigningProviderMetadata';
import { EsigningProviderAuthenticationModeSlug } from 'shared/domains/apollo/generated/types';
export const useSignatoryFieldFallbacks = () => {
    const { properties } = useEsigningProviderMetadata();
    const { canUseTitleField, canUseSigningOrder, canUseEsigningMessages, advancedAuthenticationModes, defaultAuthenticationMode, } = properties;
    const getOrderFallback = (signatory) => {
        if (canUseSigningOrder) {
            return signatory;
        }
        return Object.assign(Object.assign({}, signatory), { order: undefined });
    };
    const getTitleFallback = (signatory) => {
        if (canUseTitleField) {
            return signatory;
        }
        return Object.assign(Object.assign({}, signatory), { title: undefined });
    };
    const getMessageFallback = (signatory) => {
        if (canUseEsigningMessages) {
            return signatory;
        }
        return Object.assign(Object.assign({}, signatory), { message: undefined });
    };
    const getMobileFallback = (signatory) => {
        const supportsMobile = advancedAuthenticationModes.includes(EsigningProviderAuthenticationModeSlug.SmsOtp);
        if (supportsMobile) {
            return signatory;
        }
        return Object.assign(Object.assign({}, signatory), { mobile: undefined });
    };
    const getAuthenticationModeFallback = (signatory) => {
        const { isSigning, authenticationMode } = signatory;
        if (!isSigning) {
            return signatory;
        }
        const isAuthSupported = advancedAuthenticationModes.includes(authenticationMode);
        if (isAuthSupported) {
            return signatory;
        }
        return Object.assign(Object.assign({}, signatory), { authenticationMode: defaultAuthenticationMode });
    };
    const getFieldFallbacksForSignatories = (signatories) => signatories
        .map(getOrderFallback)
        .map(getTitleFallback)
        .map(getMobileFallback)
        .map(getMessageFallback)
        .map(getAuthenticationModeFallback);
    const getFieldFallbacksForSignatory = (signatory) => getFieldFallbacksForSignatories([signatory])[0];
    return { getFieldFallbacksForSignatory, getFieldFallbacksForSignatories };
};
