import { t } from '@lingui/macro';
import { AvatarWithLabel, Box } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
export function EmptyVisualization() {
    const title = t({ message: 'Nothing here', comment: 'Insights: No data title' });
    const subtitle = t({
        message: 'There is no activity to report yet.',
        comment: 'Insights: No data subtitle',
    });
    return (<Box width="100%" flexDirection="column" alignItems="center">
      <AvatarWithLabel alignment="vertical" title={title} subtitle={subtitle}>
        <Falcon icon="inbox"/>
      </AvatarWithLabel>
    </Box>);
}
