import { Trans, t } from '@lingui/macro';
import { Alert, Button } from '@pocketlaw/tetris';
import { useEsigning } from 'app/domains/esigning/components/EsigningProvider';
import { useLastApprovalRequest } from 'app/domains/esigning/hooks/useLastApprovalRequest';
export function NoApprovalError() {
    const { documentId } = useEsigning();
    const { refetch } = useLastApprovalRequest(documentId);
    const handleClick = () => {
        refetch();
    };
    return (<Alert appearance="danger" message={t({
            message: 'The document has not been approved.',
            comment: 'Error message for when user tries to send out document for signing but the document is not approved any longer',
        })}>
      <Button appearance="secondary" onClick={handleClick}>
        <Trans comment="Button label for fetching the correct document approval status when an error has ocurred">
          Reload document approval
        </Trans>
      </Button>
    </Alert>);
}
