import gql from 'graphql-tag';
export const MUTATION = gql `
  mutation setAssignees($objects: [TaskAssigneeInsertInput!]!) {
    createTaskAssignee(objects: $objects) {
      returning {
        id
        user {
          id
          name
        }
      }
    }
  }
`;
