import { useState } from 'react';
import { t } from '@lingui/macro';
import { Table, TableHead, TableRow, TableHeadCell, TableHeaderText, TableBody, TableBodyCell, Checkbox, Loader, Alert, Typography, } from '@pocketlaw/tetris';
import { useField } from 'formik';
import { EmptyComposer } from './components/EmptyComposer/EmptyComposer';
import { sortComposers } from './utils/sortComposers';
import { useGetComposers } from '../../hooks/useGetComposers';
import { Wrapper, LoadingContainer, TableHeadCheckboxContainer } from './styled';
export function ComposerTable(props) {
    var _a;
    const { filter } = props;
    const [sortOrder, setSortOrder] = useState('asc');
    const { data, error, loading } = useGetComposers({
        categoryIds: filter.categoryIds,
        search: filter.search,
    });
    const { composers = [] } = data || {};
    const sortedComposers = sortComposers({ composers, sortOrder });
    const filteredComposers = sortedComposers.map(({ id, metadata }) => {
        var _a;
        return ({
            id,
            title: ((_a = metadata[0]) === null || _a === void 0 ? void 0 : _a.name) || '',
        });
    });
    const [field, , helpers] = useField('composers');
    const toggleSortOrder = () => {
        if (sortOrder === 'asc') {
            setSortOrder('desc');
        }
        else {
            setSortOrder('asc');
        }
    };
    if (loading) {
        return (<Wrapper>
        <LoadingContainer>
          <Loader size={16}/>
        </LoadingContainer>
      </Wrapper>);
    }
    if (error) {
        return (<Wrapper>
        <Alert appearance="danger" title={t({
                message: 'Could not fetch templates',
                comment: 'error title - fetching composers for user invite modal',
            })} message={t({
                message: 'Please try again later',
                comment: 'error message - fetching composers for user invite modal',
            })}/>
      </Wrapper>);
    }
    return (<Table border sortId="name" sortOrder={sortOrder} onSort={toggleSortOrder}>
      <colgroup>
        <col width={0}/>
      </colgroup>
      <TableHead>
        <TableRow>
          <TableHeadCell id="composer-select">
            <TableHeadCheckboxContainer>
              <Checkbox checked={filteredComposers.length > 0 && filteredComposers.length === ((_a = field.value) === null || _a === void 0 ? void 0 : _a.length)} onChange={e => {
            if (e.target.checked) {
                helpers.setValue(filteredComposers);
            }
            else {
                helpers.setValue([]);
            }
        }}/>
            </TableHeadCheckboxContainer>
          </TableHeadCell>
          <TableHeadCell id="composer-title">
            <TableHeaderText sortId="name">
              {t({ message: 'Title', comment: 'user invite composer table - title' })}
            </TableHeaderText>
          </TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <EmptyComposer totalComposers={sortedComposers.length}/>
        {sortedComposers.map(({ id, metadata }) => {
            var _a, _b;
            return (<TableRow key={id}>
            <TableBodyCell headers="composer-select">
              <Checkbox checked={!!((_a = field.value) === null || _a === void 0 ? void 0 : _a.find(composer => composer.id === id))} onChange={e => {
                    var _a;
                    const { checked } = e.target;
                    const tmp = field.value || [];
                    if (checked) {
                        helpers.setValue([...tmp, { id, title: ((_a = metadata[0]) === null || _a === void 0 ? void 0 : _a.name) || '' }]);
                    }
                    else {
                        const newValue = tmp.filter(composer => composer.id !== id);
                        helpers.setValue(newValue);
                    }
                }}/>
            </TableBodyCell>
            <TableBodyCell headers="composer-title">
              <Typography $appearance="300">{(_b = metadata[0]) === null || _b === void 0 ? void 0 : _b.name}</Typography>
            </TableBodyCell>
          </TableRow>);
        })}
      </TableBody>
    </Table>);
}
