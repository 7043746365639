import { gql } from '@apollo/client';
export const folderEntriesQuery = gql `
  query FolderEntries($folderId: ID!) {
    folder: fileSystemFolder(id: $folderId) {
      id
      name
      depth
    }
    entries: fileSystemEntries(where: { parentId: { eq: $folderId } }, orderBy: { name: asc })
      @connection(key: "filesystem", filter: ["where"]) {
      id
      name
      kind
    }
  }
`;
