var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { Typography } from '@pocketlaw/tetris';
import { useMount } from 'react-use';
import { ROOT } from 'app/domains/filesystem';
import { getProgressIcon, getProgressLabel, wait } from './utils';
import { useUpload } from '../UploadProvider';
import { useUploadDocuments } from '../useUploadDocuments';
import { CIRCLE_DASH_ARRAY, Container, CircleBackground, CircleProgress, SvgContainer, ProgressContainer, ProgressIconBackground, ProgressIcon, } from './styled';
export function UploadingState() {
    const { selectedFiles, selectedFolder, onUploadSuccess, onUploadError } = useUpload();
    const [status, setStatus] = useState('uploading');
    const selectedFolderId = (selectedFolder === null || selectedFolder === void 0 ? void 0 : selectedFolder.id) || ROOT;
    const handleOnFolderUploadComplete = (params) => __awaiter(this, void 0, void 0, function* () {
        const { type, entryIds } = params;
        setStatus(type);
        yield wait(1000);
        if (type === 'success') {
            onUploadSuccess(entryIds);
        }
        else {
            onUploadError();
        }
    });
    const { uploadFolder, loading, uploading, uploadProgress } = useUploadDocuments(handleOnFolderUploadComplete);
    useMount(() => {
        uploadFolder(selectedFiles, selectedFolderId);
    });
    useEffect(() => {
        if (loading && !uploading) {
            setStatus('finalizing');
        }
    }, [loading, uploading]);
    /**
     * Transforms the progress percentage into the correct `stroke-dashoffset`
     * @param percentage Between `0-1`
     */
    const transformProgress = (percentage) => ((100 - percentage * 100) / 100) * CIRCLE_DASH_ARRAY;
    return (<Container>
      <ProgressContainer $status={status}>
        <SvgContainer>
          <CircleBackground />
          <CircleProgress $progress={transformProgress(uploadProgress)}/>
        </SvgContainer>
        <ProgressIconBackground>
          <ProgressIcon>{getProgressIcon(status)}</ProgressIcon>
        </ProgressIconBackground>
      </ProgressContainer>
      <Typography $appearance="200" $fontSize="small">
        {getProgressLabel(status)}
      </Typography>
    </Container>);
}
