import { gql } from '@apollo/client';
const mutation = gql `
  mutation DeleteTask($id: UUID!) {
    updateTask(_set: { deleted_at: "now()" }, where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;
export { mutation };
