import { nullToUndefined } from 'app/domains/esigning/utils';
export const mapDialogSignatory = (signatory) => ({
    key: signatory.id,
    name: signatory.name,
    firstName: signatory.firstName,
    lastName: signatory.lastName,
    email: signatory.email,
    title: signatory.title,
    mobile: nullToUndefined(signatory.mobile),
    label: nullToUndefined(signatory.label),
    message: nullToUndefined(signatory.message),
    isSigning: Boolean(signatory.authenticationMode),
    authenticationMode: signatory.authenticationMode,
    isDefault: false,
    saveSignee: false,
});
