import { padding, pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.section `
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  background-color: ${themeGet('colors.gray.0')};
`;
export const Header = styled.header `
  display: flex;
  position: sticky;
  top: 0;

  align-items: center;
  ${padding(4)};

  background-color: ${themeGet('colors.gray.0')};
  box-shadow:
    0 0 ${pxToRem(0.8)} rgba(0, 0, 0, 0.028),
    0 0 ${pxToRem(2.7)} rgba(0, 0, 0, 0.042),
    0 0 ${pxToRem(12)} rgba(0, 0, 0, 0.07);
`;
export const Main = styled.main `
  display: flex;
  flex: 1;
  flex-direction: column;
  ${padding(4)};
`;
export const Footer = styled.footer `
  display: flex;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  ${padding(2, 4)};

  background-color: ${themeGet('colors.gray.0')};
  box-shadow:
    0 0 ${pxToRem(0.8)} rgba(0, 0, 0, 0.028),
    0 0 ${pxToRem(2.7)} rgba(0, 0, 0, 0.042),
    0 0 ${pxToRem(12)} rgba(0, 0, 0, 0.07);
`;
export const LoadingContainer = styled.div `
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
