import { t } from '@lingui/macro';
import { v4 as uuid } from 'uuid';
import { DE } from 'shared/domains/languages/components/FlagGlyphs/DE';
import { Global } from 'shared/domains/languages/components/FlagGlyphs/Global';
import { NO } from 'shared/domains/languages/components/FlagGlyphs/NO';
import { SE } from 'shared/domains/languages/components/FlagGlyphs/SE';
import { UK } from 'shared/domains/languages/components/FlagGlyphs/UK';
import { localeConfig, supportedLocales } from 'shared/domains/languages/config';
const svSe = localeConfig.getLocale({ code: 'sv-se' });
const enGb = localeConfig.getLocale({ code: 'en-gb' });
const nbNo = localeConfig.getLocale({ code: 'nb-no' });
const de = localeConfig.getLocale({ code: 'de-de' });
const allMarketsIncludingUnsupported = {
    se: {
        id: uuid(),
        code: 'se',
        defaultLocale: enGb,
        marketLocales: [svSe, enGb],
        templateLocales: [enGb, svSe],
        getName: () => t({ message: 'Sweden', id: 'market_name_sweden', comment: 'Name of the SE market' }),
        flag: SE,
    },
    uk: {
        id: uuid(),
        code: 'uk',
        defaultLocale: enGb,
        marketLocales: [enGb],
        templateLocales: [enGb],
        getName: () => t({ message: 'United Kingdom', id: 'market_name_uk', comment: 'Name of the UK market' }),
        flag: UK,
    },
    no: {
        id: uuid(),
        code: 'no',
        defaultLocale: enGb,
        marketLocales: [enGb],
        templateLocales: [enGb, nbNo],
        getName: () => t({ message: 'Norway', id: 'market_name_norway', comment: 'Name of the NO market' }),
        flag: NO,
    },
    de: {
        id: uuid(),
        code: 'de',
        defaultLocale: enGb,
        marketLocales: [enGb, de],
        templateLocales: [enGb, de],
        getName: () => t({ message: 'Germany', id: 'market_name_germany', comment: 'Name of the DE market' }),
        flag: DE,
    },
};
/**
 * NOTE: This special global market is only used in signup. It is the same as the German market.
 * We have this because we want users to be able to select 'other' market when their country is not
 * supported yet.
 */
export const globalMarket = {
    id: uuid(),
    code: 'global',
    defaultLocale: enGb,
    marketLocales: [enGb],
    templateLocales: [enGb, de],
    getName: () => t({ message: 'Global market', id: 'market_name_global', comment: 'Name of the global market' }),
    flag: Global,
};
if (process.env.APP_ENV !== 'production') {
    allMarketsIncludingUnsupported.no.marketLocales.push(nbNo);
}
const { uk, se: sweden, no: norway, de: germany } = allMarketsIncludingUnsupported;
export const supportedMarkets = [uk, sweden, norway, germany];
export const supportedMarketCodes = supportedMarkets.map(item => item.code);
export const defaultMarket = sweden;
let internalStateCurrenMarket = defaultMarket;
/**
 * This is not guaranteed to re-render React components automatically, but we should reload the page when changing market anyway
 */
export function getCurrentMarket() {
    return internalStateCurrenMarket;
}
export function getAllMarketCodes() {
    const marketCodes = supportedMarkets.map(market => market.code);
    const marketCode = getCurrentMarket().code;
    const ALL_MARKETS_ENV = ['local'];
    return ALL_MARKETS_ENV.includes(process.env.APP_ENV) ? marketCodes : [marketCode];
}
export function getMarketByCode(marketCode) {
    return allMarketsIncludingUnsupported[marketCode];
}
/**
 * This should run BEFORE the app is rendered
 * currentMarket is not guaranteed to re-render React components automatically
 * If app has already rendered you should reload the page when changing currentMarket
 */
export function dangerouslySetCurrentMarket(marketCode) {
    internalStateCurrenMarket = getMarketByCode(marketCode);
}
/**
 * Get all templateLocales for the current market
 * This can be simplified after releasing global market
 */
export function getCurrentMarketTemplateLocales() {
    const { templateLocales } = getCurrentMarket();
    const ALL_MARKETS_ENV = ['local'];
    return ALL_MARKETS_ENV.includes(process.env.APP_ENV)
        ? supportedLocales
        : templateLocales;
}
