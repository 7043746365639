import { Dialog, DialogContent } from '@pocketlaw/tetris';
import { PromptLibrarySelect } from '../PromptLibrarySelect';
export function PromptLibraryDialog(props) {
    const { onClose } = props;
    return (<Dialog size="xl" height="80dvh" onClose={onClose}>
      <DialogContent disablePadding>
        <PromptLibrarySelect />
      </DialogContent>
    </Dialog>);
}
