import { Trans } from '@lingui/macro';
import { Button } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useTasks } from 'app/domains/tasks';
export function AddTaskButton() {
    const { setDisplayAdd } = useTasks();
    return (<Button appearance="secondary" type="button" onClick={() => setDisplayAdd(true)}>
      <Falcon icon="plus"/>
      <Trans comment="add new task button">New task</Trans>
    </Button>);
}
