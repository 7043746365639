import { TableBodyCell, Typography } from '@pocketlaw/tetris';
import { localeConfig } from 'shared/domains/languages/config';
import { getLocalizedDate, getLocalizedTime } from 'shared/domains/languages/utils';
export function RequestedAtCell(props) {
    const { timestamp } = props;
    const locale = localeConfig.getActiveLocale().code;
    const formattedDate = getLocalizedDate(timestamp, locale);
    const formattedTime = getLocalizedTime(timestamp, locale);
    return (<TableBodyCell headers="requestedAtCell">
      <Typography>
        {formattedDate} {formattedTime}
      </Typography>
    </TableBodyCell>);
}
