var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { useActiveDocument } from 'shared/domains/documents';
import { RemoveDocumentDefaultAttachmentDocument } from './remove.gql';
import { RestoreDocumentDefaultAttachmentDocument } from './restore.gql';
export const useDefaultDocumentAttachments = () => {
    const { id: documentId } = useActiveDocument();
    const handleUpdate = (cache, isExcluded) => {
        if (isExcluded === undefined) {
            return;
        }
        cache.modify({
            fields: {
                attachmentsCount(currentCount = 0) {
                    return isExcluded ? currentCount - 1 : currentCount + 1;
                },
            },
        });
    };
    const [restoreMutation, { loading: restoringAttachment }] = useMutation(RestoreDocumentDefaultAttachmentDocument, {
        update(cache, { data: restoreData }) {
            const { isExcluded } = (restoreData === null || restoreData === void 0 ? void 0 : restoreData.restoreDocumentDefaultAttachment) || {};
            handleUpdate(cache, isExcluded);
        },
    });
    const [removeMutation, { loading: removingAttachment }] = useMutation(RemoveDocumentDefaultAttachmentDocument, {
        update(cache, { data: restoreData }) {
            const { isExcluded } = (restoreData === null || restoreData === void 0 ? void 0 : restoreData.removeDocumentDefaultAttachment) || {};
            handleUpdate(cache, isExcluded);
        },
    });
    const restoreDefaultAttachment = (attachmentId) => __awaiter(void 0, void 0, void 0, function* () { return restoreMutation({ variables: { documentId, attachmentId } }); });
    const removeDefaultAttachment = (attachmentId) => __awaiter(void 0, void 0, void 0, function* () { return removeMutation({ variables: { documentId, attachmentId } }); });
    const isAttachmentLoading = restoringAttachment || removingAttachment;
    return {
        restoreDefaultAttachment,
        removeDefaultAttachment,
        isAttachmentLoading,
    };
};
