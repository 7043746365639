import gql from 'graphql-tag';
export const DocumentMetadataPairFragment = gql `
  fragment DocumentMetadataPairFragment on DocumentMetadataPair {
    id
    value
    documentId
    lastSetBy
    property {
      id
      name
      type
    }
  }
`;
export const createMetadataPairMutation = gql `
  mutation CreateDocumentMetadataPair($documentId: ID!, $propertyName: String!, $value: JSON!) {
    createDocumentMetadataPair(
      input: { documentId: $documentId, propertyName: $propertyName, value: $value }
    ) {
      ...DocumentMetadataPairFragment
    }
  }
  ${DocumentMetadataPairFragment}
`;
export const updateMetadataPairMutation = gql `
  mutation UpdateDocumentMetadataPair($id: UUID!, $input: UpdateDocumentMetadataPairInput!) {
    updateDocumentMetadataPair(id: $id, input: $input) {
      ...DocumentMetadataPairFragment
    }
  }
  ${DocumentMetadataPairFragment}
`;
export const deleteMetadataPairMutation = gql `
  mutation DeleteDocumentMetadataPair($id: UUID!) {
    deleteDocumentMetadataPair(id: $id) {
      id
    }
  }
`;
export const verifyMetadataPairMutation = gql `
  mutation VerifyDocumentMetadataPair(
    $documentIds: [ID!]
    $pairIds: [UUID!]
    $allApplicable: Boolean
  ) {
    approveDocumentMetadataPairs(
      documentIds: $documentIds
      ids: $pairIds
      allApplicable: $allApplicable
      input: { isApproved: true }
    )
  }
`;
