import { Button, Icon, marginBottom, unit, themeGet } from '@pocketlaw/tetris';
import styled, { keyframes } from 'styled-components';
const overlayAnimationDuration = 200;
const iconContainerAnimationDuration = 400;
const iconContainerAnimationDelay = overlayAnimationDuration;
const iconAnimationDuration = 400;
const iconAnimationDelay = iconContainerAnimationDelay + iconContainerAnimationDuration - 100;
const overlayAnimation = keyframes `
  0% {
    opacity: 0.5;
    width: 20%;
    height: 20%;
    border-radius: 50%;
  }

  50% {
    border-radius: 50%;
  }

  100% {
    width: 100%;
    height: 100%;
    border-radius: 0;
    opacity: 0.8;
  }
`;
const iconAnimation = keyframes `
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
`;
const iconContainerAnimation = keyframes `
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
`;
export const StyledButton = styled(Button) `
  ${marginBottom(4)};
`;
export const CompletedOverlay = styled.div `
  background-color: ${themeGet('colors.primary.50')};
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  border-top: ${unit(0.25)} solid ${themeGet('colors.primary.200')};
  border-bottom: ${unit(0.25)} solid ${themeGet('colors.primary.200')};
  animation: ${overlayAnimation} ${overlayAnimationDuration}ms linear forwards;
`;
export const CompletedIconContainer = styled.div `
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transform: scale(0);
  border-radius: 50%;
  width: ${unit(23)};
  height: ${unit(23)};
  background-color: ${themeGet('colors.primary.200')};
  animation: ${iconContainerAnimation} ${iconContainerAnimationDuration}ms ease-in-out forwards;
  animation-delay: ${iconContainerAnimationDelay}ms;
`;
export const CompletedIcon = styled(Icon) `
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transform: scale(0);
  animation: ${iconAnimation} ${iconAnimationDuration}ms ease-in-out forwards;
  animation-delay: ${iconAnimationDelay}ms;

  &::after {
    /* Ensure the transparent "check" shape gets a white background */
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border-radius: 50%;
    width: 95%;
    height: 95%;
    background-color: ${themeGet('colors.gray.0')};
    z-index: -1;
  }
`;
