import { Typography, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Spacer = styled.div `
  display: flex;
  flex-flow: column nowrap;
  gap: ${themeGet('spacing.xl')};
`;
export const StyledTypography = styled(Typography) `
  display: block;
  margin-bottom: ${themeGet('spacing.md')};
`;
export const Container = styled.div `
  padding: ${themeGet('spacing.lg')} ${themeGet('spacing.xl')};
`;
