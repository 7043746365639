import { Falcon } from '@pocketlaw/tetris/macro';
import { Container, Crumb, BackCrumb, BackWrapper } from './styled';
export function Breadcrumbs(props) {
    const { breadcrumbs, onSelect, disabled } = props;
    const [currentCrumb, prevCrumb] = [...breadcrumbs].reverse().splice(0, 2);
    if (!breadcrumbs.length) {
        return null;
    }
    return (<Container>
      <BackWrapper>
        {prevCrumb && (<BackCrumb aria-label={prevCrumb.name} disabled={disabled} onClick={() => onSelect(prevCrumb)}>
            <Falcon icon="chevron-left" $size={16}/>
            {currentCrumb.name}
          </BackCrumb>)}
      </BackWrapper>
      {breadcrumbs.map((crumb, index) => (<Crumb key={crumb.id} disabled={disabled || index === breadcrumbs.length - 1} onClick={() => onSelect(crumb)}>
          {crumb.name}
          {index < breadcrumbs.length - 1 && <Falcon icon="chevron-right" $size={12}/>}
        </Crumb>))}
    </Container>);
}
