import { t } from '@lingui/macro';
import { EmptyState } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { DocumentDrawerHeader } from 'shared/domains/documents';
import { DrawerContainer } from '../../../styled';
import { getTitle } from '../../../utils';
export function NotSupported(props) {
    const { onClose, onGoBack } = props;
    const label = t({
        message: 'Document not supported',
        comment: 'Title for unsupported external sharing document information message',
    });
    const message = t({
        message: 'This document type is not supported for being sent out for external sharing',
        comment: 'Message for unsupported external sharing document information message',
    });
    return (<DrawerContainer>
      <DocumentDrawerHeader type="nested" title={getTitle('external-sharing')} onClose={onClose} onGoBack={onGoBack}/>
      <EmptyState withAvatar icon={<Falcon icon="circle-info"/>} title={label} description={message}/>
    </DrawerContainer>);
}
