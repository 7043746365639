import { gql } from '@apollo/client';
export const CreateTeam = gql `
  mutation CreateTeamMutation($input: CreateTeamInput!) {
    createTeam(input: $input) {
      id
      name
      createdAt
      members {
        id
      }
    }
  }
`;
