import { t } from '@lingui/macro';
import { Box } from '@pocketlaw/tetris';
import { constructSearchURL } from 'app/domains/global-search';
import { Count } from './Count';
import { allowedPropertiesTranslations, getMetricTypeTranslations, } from '../../WidgetForm/MetricInput/translations';
export function MetricCount(props) {
    const { data, aggregation, filters } = props;
    const { metricInput } = aggregation || {};
    const { field, type } = metricInput || {};
    const value = Math.round((data === null || data === void 0 ? void 0 : data.value) || 0);
    const fieldTranslation = allowedPropertiesTranslations(field);
    const typeTranslation = getMetricTypeTranslations(type);
    const url = field &&
        constructSearchURL({
            where: Object.assign(Object.assign({}, filters), { [field]: { isNull: false } }),
        });
    const tooltip = t({
        message: `Click to see all contracts with ${fieldTranslation}`,
        comment: 'Insights - Count widget link tooltip',
    });
    return (<Box width="100%" alignSelf="flex-start">
      <Count totalCount={value} entries={[data]} subtitle={`${typeTranslation} ${fieldTranslation}`} to={url} tooltip={tooltip}/>
    </Box>);
}
