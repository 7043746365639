import { gql } from '@apollo/client';
export const FileSystemDocumentApprovalFragment = gql `
  fragment FileSystemDocumentApprovalFragment on FileSystemDocument {
    approval {
      id
      approvers {
        id
        companyUser {
          id
          user {
            id
            name
          }
        }
      }
    }
  }
`;
