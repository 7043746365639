import { t, Trans } from '@lingui/macro';
import { Typography } from '@pocketlaw/tetris';
import { TeamRoleSelect } from './TeamRoleSelect';
import { UserRoleChip } from './UserRoleChip';
import { TableRowCollapseContentContainer, TableRowCollapseContentItem } from '../styled';
export function TableRowCollapseContent(props) {
    const teamRoleLabel = t({
        message: 'Team role',
        comment: 'team members table (mobile) - team role select label',
    });
    const { teamRole, teamId, teamMemberId, userRoleName } = props;
    return (<TableRowCollapseContentContainer>
      <TableRowCollapseContentItem>
        <Typography $appearance="300" $fontSize="small">
          <Trans comment="team members table (mobile) - user role chip label">User role</Trans>
        </Typography>
        <UserRoleChip userRoleName={userRoleName}/>
      </TableRowCollapseContentItem>
      <TableRowCollapseContentItem>
        <TeamRoleSelect role={teamRole} label={teamRoleLabel} teamMemberId={teamMemberId} teamId={teamId}/>
      </TableRowCollapseContentItem>
    </TableRowCollapseContentContainer>);
}
