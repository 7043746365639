import { unit, Typography } from '@pocketlaw/tetris';
import styled, { css } from 'styled-components';
const resetButtonStyles = css `
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;

  text-align: left;

  appearance: none;
  cursor: pointer;
`;
export const StyledButton = styled.button `
  ${resetButtonStyles};
  display: flex;
  gap: ${unit(2)};
  align-items: center;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover,
  &:focus-visible {
    text-decoration: underline;
  }
`;
export const StyledTypography = styled(Typography) `
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
