import { v4 as uuid } from 'uuid';
import { SearchAggregationDateInterval, SearchAggregationType, } from 'shared/domains/apollo/generated/types';
export const getDefaultDateHistogramValue = (field) => ({
    type: SearchAggregationType.DateHistogram,
    name: uuid(),
    dateHistogramInput: {
        field,
        interval: SearchAggregationDateInterval.Month,
    },
});
