export function getApplicableCoupon(options) {
    const { product, coupons = [] } = options;
    if (!(product === null || product === void 0 ? void 0 : product.id)) {
        return undefined;
    }
    return coupons.find(coupon => {
        var _a;
        const { valid, appliesToProducts } = coupon || {};
        if (!valid) {
            return false;
        }
        const availableForAllProducts = !(appliesToProducts === null || appliesToProducts === void 0 ? void 0 : appliesToProducts.length);
        const availableForRequestedProduct = (_a = appliesToProducts === null || appliesToProducts === void 0 ? void 0 : appliesToProducts.some(productId => productId === product.id)) !== null && _a !== void 0 ? _a : false;
        return availableForAllProducts || availableForRequestedProduct;
    });
}
