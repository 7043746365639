import { constructSearchURL } from 'app/domains/global-search';
export function sliceToURL(slice, key, filters) {
    const { breakdownPayloads, payload } = slice;
    const urlOptions = {
        where: filters || {},
    };
    if (payload && payload.field) {
        urlOptions.where = Object.assign(Object.assign({}, urlOptions.where), payload.filter);
        const breakdown = breakdownPayloads.find(b => b.id === key);
        if (breakdown && breakdown.field) {
            urlOptions.where = Object.assign(Object.assign({}, urlOptions.where), breakdown.filter);
        }
    }
    return constructSearchURL(urlOptions);
}
