import { t } from '@lingui/macro';
import { Alert } from '@pocketlaw/tetris';
import { PageLoader } from 'shared/domains/common-ui';
import { SearchResultsListItem } from '../SearchResultsListItem';
import { SearchResultsNoMatch } from '../SearchResultsNoMatch';
import { SearchResultsPagination } from '../SearchResultsPagination';
import { Container } from './styled';
export function SearchResultsList(props) {
    const { results = [], loading, error, resultsLimit = 0, totalResultsCount = 0 } = props;
    if (loading) {
        return <PageLoader delay={0}/>;
    }
    if (error) {
        return (<Alert appearance="danger" message={t({
                message: 'Something went wrong, please try again later',
                comment: 'Error alert message',
            })}/>);
    }
    if ((results === null || results === void 0 ? void 0 : results.length) === 0) {
        return <SearchResultsNoMatch />;
    }
    return (<Container>
      <SearchResultsPagination disabled={loading} limit={resultsLimit} totalCount={totalResultsCount}/>
      {results.map(result => (<SearchResultsListItem key={result.id} result={result}/>))}
    </Container>);
}
