import { useState } from 'react';
import { Context } from './Context';
import { useInvites } from '../useInvites';
export function UserInvitesProvider(props) {
    const { children } = props;
    const [sort, setSort] = useState({ order: 'asc', id: 'email' });
    const [search, setSearch] = useState('');
    const [searching, setSearching] = useState(false);
    const { invites, initialLoading, error, refetch, loading } = useInvites({ sort, search });
    const noSearchResults = Boolean(search) && invites.length < 1;
    if (searching && !loading) {
        setSearching(false);
    }
    const handleSearch = (value) => {
        setSearch(value);
        setSearching(true);
    };
    const value = {
        invites,
        refetch,
        loading: initialLoading,
        error,
        sort,
        setSort,
        setSearch: handleSearch,
        noSearchResults,
        searching,
    };
    return <Context.Provider value={value}>{children}</Context.Provider>;
}
