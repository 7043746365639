import { useQuery } from '@apollo/client';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { normalizeUsers } from './normalizeUsers';
import { getCompanyUsers } from './query';
export const useGetCompanyUsers = (options) => {
    var _a;
    const { sort, search = '' } = options;
    const account = useAccount();
    const { userId } = ((_a = account.data) === null || _a === void 0 ? void 0 : _a.profile) || {};
    const variables = {
        search: `%${search}%`,
    };
    if ((sort === null || sort === void 0 ? void 0 : sort.id) === 'created_at') {
        Object.assign(variables, {
            orderBy: { created_at: sort.order },
        });
    }
    if ((sort === null || sort === void 0 ? void 0 : sort.id) === 'name') {
        Object.assign(variables, {
            orderBy: { user: { name: sort.order } },
        });
    }
    const { data, error, loading, called, previousData } = useQuery(getCompanyUsers, {
        variables,
    });
    const companyUsers = called && loading
        ? normalizeUsers((previousData === null || previousData === void 0 ? void 0 : previousData.companyUsers) || [], userId)
        : normalizeUsers((data === null || data === void 0 ? void 0 : data.companyUsers) || [], userId);
    return {
        companyUsers,
        error,
        initialLoading: !previousData && loading,
        loading,
    };
};
