import { useState } from 'react';
import { t } from '@lingui/macro';
import { Box, FilterButton, Menu, MenuBody, TextInput } from '@pocketlaw/tetris';
import { getNumberDisplayValue } from './utils';
import { useFilter } from '../useFilter';
export function NumberRangeOperator(props) {
    var _a, _b, _c, _d;
    const { name } = props;
    const filter = useFilter(name);
    const [open, setOpen] = useState(filter.hasValue);
    const displayValue = getNumberDisplayValue((_a = filter.value) === null || _a === void 0 ? void 0 : _a.gte, (_b = filter.value) === null || _b === void 0 ? void 0 : _b.lte);
    const handleSetField = (key) => (ev) => {
        const { value } = ev.target;
        const numberValue = parseInt(value, 10);
        filter.setValue(Object.assign(Object.assign({}, (filter.value || {})), { [key]: numberValue }));
    };
    return (<Menu open={open} onClose={() => setOpen(!open)} target={<FilterButton type="button" onClick={() => setOpen(!open)} appearance={filter.isTouched ? 'draft' : 'default'}>
          {displayValue}
        </FilterButton>}>
      <MenuBody>
        <Box gap="md">
          <TextInput placeholder={t({ message: 'From', comment: 'Insights: Number range filter: From' })} type="number" value={((_c = filter.value) === null || _c === void 0 ? void 0 : _c.gte) || ''} onChange={handleSetField('gte')}/>
          <TextInput placeholder={t({ message: 'To', comment: 'Insights: Number range filter: To' })} type="number" value={((_d = filter.value) === null || _d === void 0 ? void 0 : _d.lte) || ''} onChange={handleSetField('lte')}/>
        </Box>
      </MenuBody>
    </Menu>);
}
