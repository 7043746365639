import { gql } from '@apollo/client';
export const getTeams = gql `
  query getTeams($orderBy: OrderByTeamInput, $search: String) {
    teams(orderBy: $orderBy, where: { name: { ilike: $search } }) {
      id
      name
      createdAt
      members {
        id
        user {
          id
          name
        }
      }
    }
  }
`;
