import { Fragment, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Button, Menu, MenuBody, MenuItem, MenuList } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { DocumentDrawerWrapper, useDocumentDrawer } from 'shared/domains/documents';
import { ReviewDrawer } from '../../ReviewDrawer';
export function PlaiDropdownButton() {
    const { openDrawer, closeDrawer, isDrawerOpen } = useDocumentDrawer();
    const [open, setOpen] = useState(false);
    const closeMenu = () => setOpen(false);
    const toggleMenu = () => setOpen(!open);
    const isKeyDrawerOpen = (key) => isDrawerOpen(key);
    const handleClose = (key) => closeDrawer(key);
    const handleClick = (key) => {
        if (isKeyDrawerOpen(key)) {
            closeDrawer(key);
        }
        else {
            openDrawer(key);
        }
        closeMenu();
    };
    return (<Fragment>
      <Menu open={open} onClose={closeMenu} target={<Button appearance="ai" onClick={toggleMenu} aria-label={t({
                comment: 'Document view - top bar - PLAI dropdown - aria label (for screen reader)',
                message: 'Show more PLAI features',
            })}>
            {open ? (<Falcon icon="chevron-up" $size={16}/>) : (<Falcon icon="chevron-down" $size={16}/>)}
          </Button>}>
        <MenuBody>
          <MenuList>
            <MenuItem onClick={() => handleClick('app:assistant')}>
              <Falcon icon="sparkles" $size={16}/>
              <Trans comment="LDocument view - top bar - PLAI dropdown - menu item - assistant">
                Plai
              </Trans>
            </MenuItem>
            <MenuItem onClick={() => handleClick('app:review')}>
              <Falcon icon="bolt" $size={16}/>
              <Trans comment="Document view - top bar - PLAI dropdown - menu item - review">
                Review
              </Trans>
            </MenuItem>
          </MenuList>
        </MenuBody>
      </Menu>
      {isKeyDrawerOpen('app:review') && (<DocumentDrawerWrapper key="app:review" onUnmount={() => handleClose('app:review')}>
          <ReviewDrawer onClose={() => handleClose('app:review')}/>
        </DocumentDrawerWrapper>)}
    </Fragment>);
}
