import { Fragment, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Button, Menu, MenuBody, MenuItem, MenuList, useToast } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useHistory } from 'react-router-dom';
import { useEsigning } from 'app/domains/esigning';
import { EditDocumentDialog, documentFlush, useActiveDocument, useDocument, usePreview, } from 'shared/domains/documents';
import { EditApprovalRequestedButton } from './EditApprovalRequestedButton';
import { useStartEditingWithTemplate } from './useStartEditingWithTemplate';
export function EditButton() {
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const { id } = useActiveDocument();
    const { data } = useDocument(id);
    const { isDocx, isFreeText, isComposed, isRtcAvailable } = usePreview();
    const { startEditingWithTemplate } = useStartEditingWithTemplate();
    const { addToast } = useToast();
    const { unknown, pending, creating, cancelling, submittingCreation, submittingCancellation, approvalWorkflow, } = useEsigning();
    const [openEditDocumentDialog, setEditDocumentDialogState] = useState(false);
    const showEditDocumentDialog = () => setEditDocumentDialogState(true);
    const hideEditDocumentDialog = () => setEditDocumentDialogState(false);
    const toggleMenu = () => setOpen(!open);
    const { approvalRequested } = approvalWorkflow;
    const disabled = pending || creating || cancelling || submittingCreation || submittingCancellation || unknown;
    const { document } = data || {};
    const { draftId, draft } = document || {};
    const hasTemplateAccess = Boolean(draft === null || draft === void 0 ? void 0 : draft.templateVersion);
    const allowEdit = isComposed || isDocx;
    const handleEditAsFreeText = () => {
        showEditDocumentDialog();
        toggleMenu();
    };
    const handleTemplateEdit = () => {
        history.push(`/draft/${draftId}`);
        if (!isRtcAvailable) {
            return;
        }
        try {
            documentFlush({ state: 'initialized' });
            startEditingWithTemplate(id);
        }
        catch (_a) {
            addToast({
                appearance: 'danger',
                title: t({
                    comment: 'Edit document with template error - The document is deleted - toast title',
                    message: 'Could not edit document',
                }),
                message: t({
                    comment: 'Edit document with template error - The document is deleted - toast message',
                    message: 'The document has been deleted',
                }),
            });
        }
    };
    if (isFreeText && approvalRequested)
        return <EditApprovalRequestedButton />;
    if (!allowEdit)
        return null;
    if (approvalRequested)
        return <EditApprovalRequestedButton />;
    return (<Fragment>
      <Menu open={open} onClose={toggleMenu} target={<Button type="button" appearance="primary" disabled={disabled} onClick={toggleMenu}>
            <Falcon icon="pen-to-square" $size={16}/>
            <Trans comment="Label for edit document">Edit</Trans>
            {open ? (<Falcon icon="chevron-up" $size={12}/>) : (<Falcon icon="chevron-down" $size={12}/>)}
          </Button>}>
        <MenuBody>
          <MenuList>
            {isComposed && (<MenuItem onClick={handleTemplateEdit} disabled={!hasTemplateAccess}>
                <Falcon icon="list" $size={16}/>
                <Trans comment="Label for edit document with template">Edit with template</Trans>
              </MenuItem>)}
            <MenuItem onClick={handleEditAsFreeText}>
              <Falcon icon="a" $size={16}/>
              <Trans comment="Label for edit document as free-text">Edit as free-text</Trans>
            </MenuItem>
          </MenuList>
        </MenuBody>
      </Menu>
      {openEditDocumentDialog && <EditDocumentDialog onClose={hideEditDocumentDialog}/>}
    </Fragment>);
}
