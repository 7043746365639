import { Trans, t } from '@lingui/macro';
import { DialogContent, DialogHeader, DialogFooter } from '@pocketlaw/tetris';
import * as yup from 'yup';
import { CancelButton, FormDialog, SubmitButton, TextField } from 'shared/domains/forms';
export function DashboardForm(props) {
    const { onSubmit, onClose, initialValues } = props;
    const defaultValues = {
        label: '',
    };
    const validationSchema = yup.object({
        label: yup
            .string()
            .required(t({ message: 'Label is required', comment: 'Insights dashboard form: Label validation' })),
    });
    const title = initialValues
        ? t({ message: 'Edit dashboard', comment: 'Insights dashboard form: Header' })
        : t({ message: 'New dashboard', comment: 'Insights dashboard form: Header' });
    return (<FormDialog initialValues={initialValues || defaultValues} onSubmit={onSubmit} onClose={onClose} validationSchema={validationSchema}>
      <DialogHeader title={title}/>
      <DialogContent>
        <TextField name="label" autoComplete="off" label={t({ message: 'Name', comment: 'Insights dashboard form: name' })}/>
      </DialogContent>
      <DialogFooter>
        <CancelButton onClick={onClose}>
          <Trans comment="Insights dashboard form: Cancel button">Cancel</Trans>
        </CancelButton>
        <SubmitButton>
          <Trans comment="Insights dashboard form: Submit button">Submit</Trans>
        </SubmitButton>
      </DialogFooter>
    </FormDialog>);
}
