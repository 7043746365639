import { fontSize, fontWeight, marginRight, padding, marginBottom, marginLeft, pxToRem, Avatar, themeGet, } from '@pocketlaw/tetris';
import { breakpoint, Text } from '@pocketlaw/tetris/deprecated';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { RoleBadge } from '../RoleBadge';
export const Container = styled.li `
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  ${padding(3, 0)};
  border-bottom: ${pxToRem(1)} solid ${themeGet('colors.gray.50')};
  transition: background 0.25s ease;
`;
export const StyledAvatar = styled(Avatar) `
  ${marginRight(2)};
  flex: 0 0 auto;

  span {
    ${fontSize('base')};
  }

  @media ${breakpoint.palm} {
    display: none;
  }
`;
const titleStyles = css `
  display: block;
  ${marginBottom(1)};
  ${fontSize('base', 1.2)};
  color: ${themeGet('colors.text.300')};

  &:only-child {
    margin-bottom: 0;
  }
`;
export const Title = styled.span `
  ${titleStyles};
`;
export const StyledLink = styled(Link) `
  ${titleStyles};
  display: inline-block;
  text-decoration: none;
  ${fontWeight('bold')};

  &:hover {
    text-decoration: underline;
  }
`;
const getColorStylesFromAppearance = (props) => {
    switch (props.$appearance) {
        case 'orange':
            return themeGet('colors.orange.500');
        case 'neutral':
        case 'default':
        default:
            return themeGet('colors.gray.700');
    }
};
export const Subtitle = styled(Text).attrs(() => ({ size: 'tiny' })) `
  display: block;
  line-height: 1.2;
  color: ${getColorStylesFromAppearance};
`;
export const StyledRoleBadge = styled(RoleBadge) `
  margin-left: auto;
`;
export const YouText = styled(Text) `
  line-height: 1;
`;
export const InfoContainer = styled.div `
  width: 100%;
  ${marginRight(2)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const InputContainer = styled.div `
  ${marginLeft(4)};
`;
