import { useState } from 'react';
import { t } from '@lingui/macro';
import { FilterButton, Menu, MenuBody, MenuList } from '@pocketlaw/tetris';
import { MenuSingleSelectItem } from 'shared/domains/common-ui';
import { useFilter } from '../useFilter';
export function NullOperator(props) {
    const { name } = props;
    const filter = useFilter(name);
    const [open, setOpen] = useState(!filter.hasValue);
    const items = [
        {
            id: 'not-set',
            value: { isNull: true },
            title: t({ message: 'No value', comment: 'Insights: Boolean filter - has no value set' }),
        },
        {
            id: 'set',
            value: { isNull: false },
            title: t({
                message: 'Any value',
                comment: 'Insights: Boolean filter - has any value set',
            }),
        },
    ];
    const selectedItem = items.find(item => {
        const currentValue = JSON.stringify(filter.value);
        const itemValue = JSON.stringify(item.value);
        return currentValue === itemValue;
    });
    const selectedValue = (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.title) ||
        t({ message: 'Select value', comment: 'Search filter: NULL operator placeholder' });
    return (<Menu open={open} onClose={() => setOpen(!open)} target={<FilterButton type="button" appearance={filter.isTouched ? 'draft' : 'default'} onClick={() => setOpen(!open)}>
          {selectedValue}
        </FilterButton>}>
      <MenuBody>
        <MenuList>
          {items.map(item => (<MenuSingleSelectItem key={item.id} selected={(selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.id) === item.id} onClick={() => filter.setValue(item.value)}>
              {item.title}
            </MenuSingleSelectItem>))}
        </MenuList>
      </MenuBody>
    </Menu>);
}
