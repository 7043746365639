import { pxToRem, scrollbar, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
import { PleditorContainer, PleditorSidebar } from '../../../styled';
export const RHContainer = styled(PleditorContainer) `
  display: none;
  overflow: hidden;
  padding-bottom: 0;
  height: 100%;
  width: 100%;

  .ck.ck-editor__top.ck-reset_all {
    display: none;
  }

  .ck.ck-editor__main {
    min-width: ${pxToRem(823)}; // Taken from the document's rendered width
    min-height: 100%;
    height: 100%;
    overflow: auto;

    ${scrollbar({ trackMargin: 'xl' })};

    & > .ck-content {
      border-color: transparent;
    }
  }
`;
export const RHContentContainer = styled.div `
  width: fit-content;
  display: block;
  margin: 0 auto;
  height: 100%;
`;
export const RHContentInnerContainer = styled.div `
  display: inline-flex;
  justify-content: space-between;
  height: 100%;
`;
export const RHSidebar = styled(PleditorSidebar) `
  height: 100%;
  overflow: auto;
  color: ${themeGet('colors.text.300')};

  ${scrollbar({ trackMargin: 'xl' })};

  // Revision History Sidebar Header
  .ck-revision-history-sidebar__header {
    border-bottom: unset;
    padding-left: ${themeGet('spacing.2xl')};
  }

  // Revision History Sidebar
  .ck.ck-revision-history-sidebar {
    height: 100%;
  }

  // Revision History Sidebar Revision Items Wrapper
  .ck.ck-revision-history-sidebar .ck-revision-history-sidebar__timeline {
    padding-left: ${themeGet('spacing.2xl')};
    padding-right: ${themeGet('spacing.2xl')};
  }

  // Revision History Sidebar Time Period
  .ck.ck-revision-history-sidebar__time-period .ck-revision-history-sidebar__time-period__label {
    text-transform: capitalize;
    padding: ${themeGet('spacing.sm')} ${themeGet('spacing.md')};
    font-size: ${themeGet('fontSize.small')};
    font-weight: ${themeGet('fontWeight.medium')};
    border-radius: ${themeGet('borderRadius.md')};
    color: ${themeGet('colors.secondary.500')};
    background-color: ${themeGet('colors.secondary.50')};
  }

  // Revision History Sidebar Revision Item
  .ck .ck-revision-history-sidebar__revision-wrapper .ck-revision-history-sidebar__revision {
    border-radius: ${themeGet('borderRadius.md')};
    border: solid ${pxToRem(1)} ${themeGet('colors.gray.50')};
    border-left: solid ${pxToRem(3)} ${themeGet('colors.secondary.500')};
    box-shadow: unset;
    cursor: pointer;
    transition:
      box-shadow 150ms ease,
      transform 300ms ease;

    &:hover {
      box-shadow: ${themeGet('dropShadow.400')};
    }
  }

  // Revision History Sidebar Revision Item Selected
  .ck
    .ck-revision-history-sidebar__revision-wrapper
    .ck-revision-history-sidebar__revision.ck-revision-history-sidebar__revision_selected {
    border: unset;
    box-shadow: ${themeGet('dropShadow.400')};
  }

  // Revision History Sidebar Revision Item Dropdown Button
  .ck
    .ck-revision-history-sidebar__revision
    .ck-revision-history-sidebar__revision__actions
    .ck-dropdown__button {
    background: transparent;
    border-radius: ${themeGet('borderRadius.md')};
  }

  // Revision History Sidebar Revision Item Dropdown Button Icon
  .ck
    .ck-revision-history-sidebar__revision
    .ck-revision-history-sidebar__revision__actions
    .ck-dropdown__button
    .ck-button__icon {
    transform: scale(1);
  }

  // Revision History Sidebar Revision Item Dropdown Item
  .ck .ck-revision-history-sidebar__revision .ck-dropdown__panel {
    color: ${themeGet('colors.text.300')};
  }
`;
export const RHEditor = styled.div ``;
