import { gql } from '@apollo/client';
export const documentsSentForEsigningCountQuery = gql `
  query documentsSentForEsigningCountQuery {
    documentsSentForEsigning: search(
      query: ""
      limit: 0
      where: {
        metadataDocumentEsigningStatus: { in: ["PENDING", "OPENED"] }
        entityType: { in: [document_uploaded, document_freetext, document_composed] }
      }
    ) {
      totalHitsCount
    }
  }
`;
