import { t } from '@lingui/macro';
import { Alert, DialogContent, TextArea } from '@pocketlaw/tetris';
import { Field, useFormikContext } from 'formik';
import { Spacer } from './styled';
export function Content(props) {
    const { children, error } = props;
    const { isSubmitting } = useFormikContext();
    return (<DialogContent>
      {error && (<Alert appearance="danger" message={t({
                comment: 'Document assistant - feedback dialog - error alert title',
                message: 'An error occured when submitting your feedback. Please, try again later.',
            })}/>)}
      <Spacer>
        <Field as={TextArea} label={t({
            message: 'Your feedback',
            comment: 'Document assistant - feedback modal - textarea label',
        })} name="feedback" disabled={isSubmitting}/>
        {children}
      </Spacer>
    </DialogContent>);
}
