import { Fragment } from 'react';
import { Trans } from '@lingui/macro';
import { Button } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { AssistantContext, useAssistant } from 'app/domains/assistant';
import { AssistantDrawer } from 'app/domains/documents';
import { DocumentDrawerWrapper, useDocumentDrawer } from 'shared/domains/documents';
export function AssistantButton() {
    const { isDrawerOpen } = useDocumentDrawer();
    const { openAssistantDrawer, closeAssistantDrawer } = useAssistant();
    const open = isDrawerOpen('app:assistant');
    const handleClose = () => {
        closeAssistantDrawer();
    };
    const handleClick = () => {
        if (open) {
            closeAssistantDrawer();
        }
        else {
            openAssistantDrawer(AssistantContext.Document);
        }
    };
    return (<Fragment>
      <Button appearance="ai" onClick={handleClick}>
        <Falcon icon="sparkles"/>
        <Trans comment="Document view - top bar - PLAI button label">Plai</Trans>
      </Button>
      {open && (<DocumentDrawerWrapper key="app:assistant" onUnmount={handleClose}>
          <AssistantDrawer onClose={handleClose}/>
        </DocumentDrawerWrapper>)}
    </Fragment>);
}
