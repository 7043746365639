import { Loader, Chip } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { DocumentMetadataAiAutoTaggingQueueEntryStatus } from 'shared/domains/apollo/generated/types';
export function StatusChip(props) {
    const { status } = props;
    switch (status) {
        case DocumentMetadataAiAutoTaggingQueueEntryStatus.Success:
            return <Chip appearance="success" icon={<Falcon icon="check-circle"/>} label="Completed"/>;
        case DocumentMetadataAiAutoTaggingQueueEntryStatus.Failed:
        case DocumentMetadataAiAutoTaggingQueueEntryStatus.Canceled:
            return <Chip appearance="danger" label="Failed"/>;
        case DocumentMetadataAiAutoTaggingQueueEntryStatus.InProgress:
            return <Chip appearance="assistant" icon={<Loader />} label="Tagging"/>;
        case DocumentMetadataAiAutoTaggingQueueEntryStatus.Delayed:
        case DocumentMetadataAiAutoTaggingQueueEntryStatus.Pending:
            return <Chip appearance="secondary" label="Pending"/>;
        default:
            return <Chip appearance="secondary" label="Unknown"/>;
    }
}
