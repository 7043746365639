import { t } from '@lingui/macro';
import { ReminderIntervalEnum } from 'shared/domains/apollo/generated/types';
export function getIntervals() {
    const intervalLabelMap = {
        [ReminderIntervalEnum.Daily]: t({
            message: 'Daily',
            comment: 'Tasks Repeating Reminders Option - Daily',
        }),
        [ReminderIntervalEnum.Weekly]: t({
            message: 'Weekly',
            comment: 'Tasks Repeating Reminders Option - Weekly',
        }),
        [ReminderIntervalEnum.EverySecondWeek]: t({
            message: 'Every second week',
            comment: 'Tasks Repeating Reminders Option - Every second week',
        }),
        [ReminderIntervalEnum.Monthly]: t({
            message: 'Monthly',
            comment: 'Tasks Repeating Reminders Option - Monthly',
        }),
        [ReminderIntervalEnum.EverySecondMonth]: t({
            message: 'Every second month',
            comment: 'Tasks Repeating Reminders Option - Every second month',
        }),
        [ReminderIntervalEnum.EveryFourthMonth]: t({
            message: 'Every forth month',
            comment: 'Tasks Repeating Reminders Option - Every forth month',
        }),
        [ReminderIntervalEnum.EverySixthMonth]: t({
            message: 'Every sixth month',
            comment: 'Tasks Repeating Reminders Option - Every sixth month',
        }),
        [ReminderIntervalEnum.Yearly]: t({
            message: 'Yearly',
            comment: 'Tasks Repeating Reminders Option - Yearly',
        }),
    };
    const intervals = [
        {
            value: undefined,
            label: t({ message: 'Never', comment: 'Tasks Repeating Reminders Option - Never' }),
        },
        {
            value: ReminderIntervalEnum.Daily,
            label: intervalLabelMap[ReminderIntervalEnum.Daily],
        },
        {
            value: ReminderIntervalEnum.Weekly,
            label: intervalLabelMap[ReminderIntervalEnum.Weekly],
        },
        {
            value: ReminderIntervalEnum.EverySecondWeek,
            label: intervalLabelMap[ReminderIntervalEnum.EverySecondWeek],
        },
        {
            value: ReminderIntervalEnum.Monthly,
            label: intervalLabelMap[ReminderIntervalEnum.Monthly],
        },
        {
            value: ReminderIntervalEnum.EverySecondMonth,
            label: intervalLabelMap[ReminderIntervalEnum.EverySecondMonth],
        },
        {
            value: ReminderIntervalEnum.EveryFourthMonth,
            label: intervalLabelMap[ReminderIntervalEnum.EveryFourthMonth],
        },
        {
            value: ReminderIntervalEnum.EverySixthMonth,
            label: intervalLabelMap[ReminderIntervalEnum.EverySixthMonth],
        },
        {
            value: ReminderIntervalEnum.Yearly,
            label: intervalLabelMap[ReminderIntervalEnum.Yearly],
        },
    ];
    return { intervalLabelMap, intervals };
}
