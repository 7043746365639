var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import apollo from 'app/domains/apollo';
import * as Types from 'shared/domains/apollo/generated/types';
import { useAddUserAccess } from 'shared/domains/users';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { createTaskMutation } from './mutation';
import { TaskFragment } from '../../utils/fragments';
import { getTasksQuery } from '../useGetTasks/query';
import { useSetTaskAssignees } from '../useSetTaskAssignees';
import { cleanTaskData } from '../utils/cleanTaskData';
function useCreateTask() {
    const account = useAccount();
    const addUserAccess = useAddUserAccess();
    const { setTaskAssignees } = useSetTaskAssignees();
    const [createMutation, createState] = useMutation(createTaskMutation, {
        update: (cache, { data }) => {
            if (data === null || data === void 0 ? void 0 : data.task) {
                cache.modify({
                    fields: {
                        tasks(existingTasks = []) {
                            const newTaskRef = cache.writeFragment({
                                data: data.task,
                                fragment: TaskFragment,
                            });
                            return [newTaskRef, ...existingTasks];
                        },
                    },
                });
            }
        },
        onQueryUpdated(observableQuery) {
            return observableQuery.refetch();
        },
    });
    const companyId = account.data.companyId;
    const userId = account.data.userId;
    const mutate = (values) => __awaiter(this, void 0, void 0, function* () {
        var _a, _b;
        const { title, description, isCompleted, isPublic, dueDate, reminder, attachments, taskAssignees = [], } = cleanTaskData(values);
        const newTask = {
            title,
            description,
            is_completed: isCompleted,
            is_public: isPublic,
            company_id: companyId,
            creator_id: userId,
            due_date: dueDate,
        };
        if (reminder === null || reminder === void 0 ? void 0 : reminder.dueDate) {
            newTask.reminders = {
                data: [
                    {
                        due_date: reminder.dueDate,
                        interval: reminder.interval,
                    },
                ],
            };
        }
        if (attachments === null || attachments === void 0 ? void 0 : attachments.length) {
            const [{ type, attachmentId }] = attachments;
            if (type === 'document') {
                newTask.task_documents = {
                    data: [
                        {
                            document_id: attachmentId,
                        },
                    ],
                };
            }
            if (type === 'template') {
                newTask.task_templates = {
                    data: [
                        {
                            template_id: attachmentId,
                        },
                    ],
                };
            }
        }
        const result = yield createMutation({
            variables: { task: newTask },
        });
        const assignees = taskAssignees.map(assignee => {
            var _a, _b;
            return ({
                task_id: (_b = (_a = result.data) === null || _a === void 0 ? void 0 : _a.task) === null || _b === void 0 ? void 0 : _b.id,
                assignee_id: assignee.id,
            });
        });
        yield setTaskAssignees(assignees);
        if ((_a = values.addDocumentPermission) === null || _a === void 0 ? void 0 : _a.length) {
            const users = values.addDocumentPermission;
            const filteredAttachments = ((_b = values.attachments) === null || _b === void 0 ? void 0 : _b.filter(({ type }) => type === 'document')) || [];
            yield Promise.all(filteredAttachments.map(({ attachmentId }) => addUserAccess.mutate(attachmentId, {
                users,
                actions: [Types.AclAction.Read],
            })));
        }
        apollo.refetchQueries({
            include: [getTasksQuery],
            onQueryUpdated(observableQuery) {
                return observableQuery.refetch();
            },
        });
        return result;
    });
    return Object.assign({ mutate }, createState);
}
export { useCreateTask };
