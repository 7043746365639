import { useQuery } from '@apollo/client';
import { ROOT } from 'app/domains/filesystem';
import { CountFolderPickerEntriesQueryDocument } from './CountFileSystemEntriesQuery.gql';
import { FolderPickerQueryDocument } from './FolderPickerQuery.gql';
export function useFolderPicker(options) {
    var _a;
    const { entryId, parentId, skip, limit, offset, showSignRequestStatus = false, includeDocumentAi = false, } = options || {};
    const countResponse = useQuery(CountFolderPickerEntriesQueryDocument, {
        fetchPolicy: 'cache-and-network',
        variables: {
            parentId: parentId || ROOT,
        },
    });
    const folderQuery = useQuery(FolderPickerQueryDocument, {
        fetchPolicy: 'cache-and-network',
        skip,
        variables: {
            skipAncestors: Boolean(parentId),
            entryId: entryId || ROOT,
            withEntry: Boolean(entryId),
            parentId: parentId || ROOT,
            limit,
            offset,
            showSignRequestStatus,
            includeDocumentAi,
        },
    });
    const count = ((_a = countResponse.data) === null || _a === void 0 ? void 0 : _a.count) || 0;
    const loading = folderQuery.loading || countResponse.loading;
    const error = folderQuery.error || countResponse.error;
    return { data: Object.assign(Object.assign({}, folderQuery.data), { count }), error, loading };
}
