import { gql } from '@apollo/client';
export const ProfileFragment = gql `
  fragment ProfileFragment on Profile {
    userId
    email
    name
    picture
    verified
  }
`;
export const AccountFragment = gql `
  fragment AccountFragment on CompanyUser {
    id
    role
    userId: user_id
    companyId: company_id
    canManageMember: can_manage_member
    company {
      id
      name
      marketCode: market_code
      ssoEnterprise: sso_enterprise
    }
  }
`;
