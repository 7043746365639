import { Trans } from '@lingui/macro';
import { Button, DialogFooter as TetrisDialogFooter } from '@pocketlaw/tetris';
import { useFormikContext } from 'formik';
export function DialogFooter(props) {
    const { submitLabel, onClose } = props;
    const { handleSubmit } = useFormikContext();
    const handleClick = () => handleSubmit();
    return (<TetrisDialogFooter>
      <Button appearance="secondary" type="button" onClick={onClose}>
        <Trans comment="Label for canceling add party action">Cancel</Trans>
      </Button>
      <Button appearance="primary" type="submit" onClick={handleClick}>
        {submitLabel}
      </Button>
    </TetrisDialogFooter>);
}
