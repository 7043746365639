import { gql } from '@apollo/client';
export const lastApprovalRequestQuery = gql `
  query lastApprovalRequest($documentId: ID!) {
    fileSystemDocument: fileSystemDocument(id: $documentId) {
      id
      lastApprovalRequest {
        id
        status
        createdAt
        requesterCompanyUserId
        approvers {
          id
          companyUserId
          status
        }
      }
    }
  }
`;
