import { t } from '@lingui/macro';
import { Chip, Tooltip } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useEsigning } from 'app/domains/esigning/';
import { useDocumentDrawer } from 'shared/domains/documents';
import { useFeatureFlag } from 'shared/domains/featureFlag';
export function ApprovalChip() {
    const { openDrawer } = useDocumentDrawer();
    const { approvalWorkflow } = useEsigning();
    const { url } = useRouteMatch();
    const history = useHistory();
    const isApprovalEnabled = useFeatureFlag('feature_advanced_approval_workflow');
    const { approvalWorkflowAvailable, approvalNotRequested, approvalPending, approvalApproved, approvalAmount, currentUser, } = approvalWorkflow;
    const icon = <Falcon icon="pen-swirl"/>;
    const position = 'bottom';
    const handleClick = () => {
        history.push(isApprovalEnabled ? `${url}/approval` : `${url}/esigning`);
        openDrawer('app:settings');
    };
    if (!approvalWorkflowAvailable) {
        return null;
    }
    const secondary = t({
        comment: 'Document approval pending - chip label - amount of approvers that have approved',
        message: `${approvalAmount.current} out of ${approvalAmount.max} approved`,
    });
    if (approvalNotRequested) {
        return (<Tooltip position={position} title={t({
                comment: 'Document approval required - chip tooltip',
                message: 'Click to ask for approval',
            })}>
        <Chip icon={icon} appearance="info" onClick={handleClick} label={t({
                comment: 'Document approval required - chip label',
                message: 'Approval needed',
            })}/>
      </Tooltip>);
    }
    if (approvalPending && !currentUser.isApprover) {
        return (<Tooltip position={position} title={t({
                comment: 'Document approval pending - chip tooltip',
                message: 'This document is sent for approval. Until approved or cancelled the document cannot be edited.',
            })}>
        <Chip icon={icon} appearance="info" onClick={handleClick} label={t({
                comment: 'Document approval pending - chip label',
                message: 'Pending approval',
            })} secondary={secondary}/>
      </Tooltip>);
    }
    if (approvalPending && currentUser.isApprover && !currentUser.hasApproved) {
        return (<Tooltip position={position} title={t({
                comment: 'Document approval needed by the current user - chip tooltip',
                message: 'This document requires approval from you. Click to go to approval.',
            })}>
        <Chip icon={icon} appearance="info" onClick={handleClick} label={t({
                comment: 'Document approval needed by the current user - chip label',
                message: 'Your approval is needed',
            })}/>
      </Tooltip>);
    }
    if (approvalPending && currentUser.isApprover && currentUser.hasApproved) {
        return (<Tooltip position={position} title={t({
                comment: 'Document approval needed - chip tooltip',
                message: 'You have approved this document. Until fully approved or cancelled the document cannot be edited.',
            })}>
        <Chip icon={icon} appearance="info" onClick={handleClick} label={t({
                comment: 'Document approval needed - chip label',
                message: 'Approval needed',
            })} secondary={secondary}/>
      </Tooltip>);
    }
    if (approvalApproved) {
        return (<Chip icon={icon} appearance="success" label={t({
                comment: 'Document approval approved - chip label',
                message: 'Document approved',
            })}/>);
    }
    return null;
}
