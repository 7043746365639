import { themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${themeGet('spacing.xl')};
`;
export const FilesContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${themeGet('spacing.md')};
`;
