import { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { PageLayout } from 'app/domains/common-ui';
import { AuthRoutes, PublicRoute, SuspenseRoute } from 'app/domains/urls';
import { PageLoader } from 'shared/domains/common-ui';
import CustomerSuccess from './App/screens/CustomerSuccess';
import { workspaceToDashboardRedirect, workspaceToFilesRedirect, } from './App/screens/Dashboard/utils';
const SignedOutPage = lazy(() => import('./SignedOut'));
const NotFound = lazy(() => import('./App/screens/NotFound'));
const SettingsRoute = lazy(() => import('./App/screens/Settings'));
const LogoutPage = lazy(() => import('./Logout'));
const SignupPage = lazy(() => import('./Signup'));
const CompanyCreationPage = lazy(() => import('./CompanyCreation'));
const InvitePage = lazy(() => import('./Invite'));
const VerifyPage = lazy(() => import('./Verify'));
const DraftPage = lazy(() => import('./App/screens/Draft'));
const DraftEditorPage = lazy(() => import('./App/screens/DraftEditor'));
const ContractsPage = lazy(() => import('./App/screens/Contracts'));
const DashboardPage = lazy(() => import('./App/screens/Dashboard'));
const InsightsPage = lazy(() => import('./App/screens/Insights'));
const FilesSystemPage = lazy(() => import('./App/screens/FileSystem'));
const DocumentPage = lazy(() => import('./App/screens/Document'));
const PlansPage = lazy(() => import('./App/screens/Plans'));
const CheckoutPage = lazy(() => import('./App/screens/Checkout'));
const AddCompanyPage = lazy(() => import('./App/screens/AddCompany'));
const AddCompanyFinishedPage = lazy(() => import('./App/screens/AddCompanyFinished'));
const SearchPage = lazy(() => import('./App/screens/Search'));
const UsersTeamsPage = lazy(() => import('./App/screens/UsersTeams'));
const TeamProfilePage = lazy(() => import('./App/screens/TeamProfile'));
const SharedWithMePage = lazy(() => import('./App/screens/SharedWithMe'));
const TasksPage = lazy(() => import('./App/screens/Tasks'));
const AllDocumentsPage = lazy(() => import('./App/screens/AllDocuments'));
const SavedViewPage = lazy(() => import('./App/screens/SavedView'));
const NoticeDatesPage = lazy(() => import('./App/screens/NoticeDates'));
const AwaitingApprovalsPage = lazy(() => import('./App/screens/AwaitingApprovalsDocuments'));
const AwaitingSignaturesPage = lazy(() => import('./App/screens/AwaitingSignaturesDocuments'));
const SharedDocumentsPage = lazy(() => import('./App/screens/SharedDocuments'));
const DraftsPage = lazy(() => import('./App/screens/Drafts'));
const UnverifiedTagsPage = lazy(() => import('./App/screens/UnverifiedTags'));
export function Routes() {
    return (<Suspense fallback={<PageLoader delay={50}/>}>
      <Switch>
        <Redirect from="/sv-se/*" to="/*"/>
        <Redirect exact from="/" to="/dashboard"/>
        <Redirect exact path="/login" to="/"/>

        {/* make sure to edit `isPublicRoute` function if you add/remove public routes */}
        <PublicRoute exact path="/logout" component={LogoutPage}/>
        <PublicRoute exact path="/signup" component={SignupPage}/>
        <PublicRoute exact path="/signed-out" component={SignedOutPage}/>
        <PublicRoute exact path="/invite/:id" component={InvitePage}/>
        <PublicRoute exact path="/company-creation" component={CompanyCreationPage}/>

        <AuthRoutes>
          <SuspenseRoute path="/verify" component={VerifyPage}/>
          <SuspenseRoute path="/plans" component={PlansPage}/>
          <SuspenseRoute path="/checkout" component={CheckoutPage}/>
          <SuspenseRoute exact path="/add-company" component={AddCompanyPage}/>
          <SuspenseRoute exact path="/add-company/finished" component={AddCompanyFinishedPage}/>

          <SuspenseRoute exact path="/draft/:id" component={DraftPage}/>
          <SuspenseRoute exact path="/draft/:id/chapter/:ref" component={DraftEditorPage}/>

          <PageLayout>
            <Switch>
              <SuspenseRoute exact path="/contracts" component={ContractsPage}/>

              <Route exact path="/workspaces" render={routeProps => <Redirect to={workspaceToDashboardRedirect(routeProps)}/>}/>

              <SuspenseRoute exact path="/dashboard" component={DashboardPage}/>
              <SuspenseRoute path="/insights" component={InsightsPage}/>

              <Route path={['/workspaces/:workspaceId/folders/:folderId', '/workspaces/:workspaceId']} render={routeProps => <Redirect to={workspaceToFilesRedirect(routeProps)}/>}/>

              <SuspenseRoute path="/document/:id" component={DocumentPage}/>

              <SuspenseRoute path="/files/:folderId?" component={FilesSystemPage}/>

              <SuspenseRoute path="/search" component={SearchPage}/>
              <SuspenseRoute path="/all-documents" component={AllDocumentsPage}/>
              <SuspenseRoute path="/saved-view/:viewId" component={SavedViewPage}/>
              <SuspenseRoute path="/unverified-tags" component={UnverifiedTagsPage}/>
              <SuspenseRoute path="/drafts" component={DraftsPage}/>
              <SuspenseRoute path="/shared-with-me:folderId?" component={SharedWithMePage}/>
              <SuspenseRoute exact path="/documents/awaiting-signatures" component={AwaitingSignaturesPage}/>
              <SuspenseRoute exact path="/documents/awaiting-approvals" component={AwaitingApprovalsPage}/>
              <SuspenseRoute exact path="/documents/shared" component={SharedDocumentsPage}/>
              <SuspenseRoute exact path="/documents/notice-dates" component={NoticeDatesPage}/>

              <Redirect exact from="/settings" to="/settings/profile"/>

              <SuspenseRoute path="/settings/:activeTab" component={SettingsRoute}/>
              <SuspenseRoute exact path="/users-teams" component={UsersTeamsPage}/>
              <SuspenseRoute exact path="/users-teams/team/:teamId" component={TeamProfilePage}/>
              <SuspenseRoute exact path="/tasks" component={TasksPage}/>
              <SuspenseRoute exact path="/customer-success" component={CustomerSuccess}/>
              <SuspenseRoute>
                <NotFound />
              </SuspenseRoute>
            </Switch>
          </PageLayout>
        </AuthRoutes>
      </Switch>
    </Suspense>);
}
