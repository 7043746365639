import { Fragment } from 'react';
import { AssistantContextSwitcher, useAssistant } from 'app/domains/assistant';
import { ConversationList } from './ConversationList';
import { EmptyList } from './EmptyList';
export function AssistantConversations() {
    const { thread } = useAssistant();
    return (<Fragment>
      <AssistantContextSwitcher />
      {thread.length > 0 ? <ConversationList /> : <EmptyList />}
    </Fragment>);
}
