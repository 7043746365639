import { gql } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import auth from 'app/domains/auth/client';
import { RECOVERABLE_ERRORS } from 'app/domains/auth/constants';
import link from 'shared/domains/apollo/link';
import { authenticatedVar } from 'shared/domains/apollo/utils/cacheVars';
import { localeConfig } from 'shared/domains/languages/config';
const accountCompanyIdQuery = gql `
  query accountCompanyId {
    account @client {
      companyId: company_id
    }
  }
`;
function getTenantURL(context) {
    var _a;
    const { cache, withCompany } = context;
    if (withCompany !== null && withCompany !== void 0 ? withCompany : true) {
        const data = cache.readQuery({ query: accountCompanyIdQuery });
        if ((_a = data.account) === null || _a === void 0 ? void 0 : _a.companyId) {
            return new URL(data.account.companyId, process.env.API_HTTP_URL);
        }
    }
    return new URL(process.env.API_HTTP_URL || '');
}
function getAccessToken() {
    function questionablyResetSession() {
        authenticatedVar(null);
    }
    function handleTokenError(error) {
        if (error && RECOVERABLE_ERRORS.includes(error.error)) {
            return questionablyResetSession();
        }
        throw error;
    }
    return auth.getTokenSilently().catch(handleTokenError);
}
const contextLink = setContext((request, context) => Promise.all([getAccessToken(), getTenantURL(context)]).then(([accessToken, url]) => {
    const headers = {};
    const { code } = localeConfig.getActiveLocale();
    if (accessToken) {
        headers.Authorization = `Bearer ${accessToken}`;
    }
    headers['Accept-Language'] = code;
    return { uri: url.toString(), headers };
}));
const httpLink = contextLink.concat(link);
export default httpLink;
