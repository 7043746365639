var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { documentState, useDocumentState } from '../../../documentState';
import { hasSubcode } from '../../RTCPleditor/hasSubcode';
export const useGetTokenWrapper = () => {
    const currentState = useDocumentState();
    const getTokenWrapper = (tokenCallback) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const unrecoverableError = currentState === 'error' || currentState === 'unauthorized';
            if (unrecoverableError) {
                throw Error('Unrecoverable error');
            }
            const { token, error } = yield tokenCallback();
            if (error) {
                throw error;
            }
            if (!token) {
                throw new Error('Pleditor token missing');
            }
            documentState('ready');
            return token;
        }
        catch (error) {
            // Fatal error that should destroy the editor
            if (error instanceof Error && error.message === 'Pleditor token missing') {
                documentState('error');
                throw error;
            }
            // Fatal error that should destroy the editor
            if (hasSubcode(error, 'USER_NOT_AUTHORIZED')) {
                documentState('unauthorized');
                throw error;
            }
            // Error that should destroy the editor
            // App handles showing a session expired dialog
            if (hasSubcode(error, 'USER_NOT_AUTHENTICATED')) {
                documentState('unauthenticated');
                throw error;
            }
            // Non-fatal error, will display a dialog while reconnecting.
            // Re-throw to make CKEditor retry the request.
            documentState('reconnecting');
            throw error;
        }
    });
    return getTokenWrapper;
};
