import { t } from '@lingui/macro';
import { Box, IconToggleButton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useField } from 'formik';
import { SearchInsightsWidgetTypeEnum } from 'shared/domains/apollo/generated/types';
export function ChartSelection() {
    const [field, , helpers] = useField('type');
    const [isHorizontalField, , isHorizontalHelpers] = useField('isHorizontal');
    const handleOnCHange = (newValue, isHorizontal) => {
        helpers.setValue(newValue);
        if (isHorizontal === undefined) {
            return;
        }
        isHorizontalHelpers.setValue(isHorizontal);
    };
    const visuals = [
        {
            name: t({ message: 'Doughnut', comment: 'Insights - Name of type of visualization' }),
            type: SearchInsightsWidgetTypeEnum.Doughnut,
            icon: <Falcon $size={18} icon="chart-pie-simple"/>,
            isHorizontal: false,
        },
        {
            name: t({
                message: 'Bar Chart - horizontal',
                comment: 'Insights - Name of type of visualization',
            }),
            type: SearchInsightsWidgetTypeEnum.Barchart,
            icon: <Falcon $size={18} icon="chart-column"/>,
            isHorizontal: true,
        },
        {
            name: t({
                message: 'Bar Chart - vertical',
                comment: 'Insights - Name of type of visualization',
            }),
            type: SearchInsightsWidgetTypeEnum.Barchart,
            icon: <Falcon $size={18} icon="chart-bar"/>,
            isHorizontal: false,
        },
        {
            name: t({ message: 'Line Chart', comment: 'Insights - Name of type of visualization' }),
            type: SearchInsightsWidgetTypeEnum.Linechart,
            icon: <Falcon $size={18} icon="chart-line"/>,
            isHorizontal: false,
        },
        {
            name: t({ message: 'Table', comment: 'Insights - Name of type of visualization' }),
            type: SearchInsightsWidgetTypeEnum.Table,
            icon: <Falcon $size={18} icon="table"/>,
            isHorizontal: false,
        },
        {
            name: t({ message: 'Count', comment: 'Insights - Name of type of visualization' }),
            type: SearchInsightsWidgetTypeEnum.Count,
            icon: <Falcon $size={18} icon="input-numeric"/>,
            isHorizontal: false,
        },
    ];
    return (<Box gap="lg">
      {visuals.map(visual => (<IconToggleButton key={visual.type} aria-label={visual.name} $toggled={visual.type === field.value && visual.isHorizontal === isHorizontalField.value} onClick={() => handleOnCHange(visual.type, visual.isHorizontal)}>
          {visual.icon}
        </IconToggleButton>))}
    </Box>);
}
