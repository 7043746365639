var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Formik, Form as FormikForm } from 'formik';
import { Fieldset } from '../Fieldset';
const Form = function Form(props) {
    const { children, initialValues, disabled, disableOnSubmit = true, className } = props, rest = __rest(props, ["children", "initialValues", "disabled", "disableOnSubmit", "className"]);
    return (<Formik initialValues={initialValues} {...rest}>
      <FormikForm className={className}>
        <Fieldset disabled={disabled} disableOnSubmit={disableOnSubmit}>
          {children}
        </Fieldset>
      </FormikForm>
    </Formik>);
};
export { Form };
