/* eslint-disable @typescript-eslint/no-explicit-any */
export class CustomError extends Error {
    constructor(message, options) {
        super(message, { cause: options === null || options === void 0 ? void 0 : options.cause });
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, CustomError);
        }
        this.data = options === null || options === void 0 ? void 0 : options.data;
    }
}
