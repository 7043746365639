import { useState } from 'react';
import { useFolderPicker, getBreadcrumbs, useACLCan } from 'app/domains/filesystem';
import { DocumentType, EntryKind } from 'shared/domains/apollo/generated/types';
import { ACTIVE_STATUSES } from 'shared/domains/documents';
import { MimeTypesByExtension } from 'shared/domains/filesystem/utils/fileExtensions';
export function useFileBrowser(options) {
    var _a, _b, _c, _d, _e;
    const { onSelect, selectable, parentId, selfId, limit, offset, showSignRequestStatus, includeDocumentAi, } = options;
    const [selected, setSelected] = useState(undefined);
    const [location, setLocation] = useState(parentId);
    const filesystem = useFolderPicker({
        parentId: location,
        limit,
        offset,
        showSignRequestStatus,
        includeDocumentAi,
    });
    const folderPermission = useACLCan({
        userAcls: (_b = (_a = filesystem.data) === null || _a === void 0 ? void 0 : _a.folder) === null || _b === void 0 ? void 0 : _b.acl,
        teamAcls: (_d = (_c = filesystem.data) === null || _c === void 0 ? void 0 : _c.folder) === null || _d === void 0 ? void 0 : _d.teamAcls,
    });
    const fileSystemEntries = ((_e = filesystem.data) === null || _e === void 0 ? void 0 : _e.children) || [];
    const entries = fileSystemEntries.map(entry => {
        const defaultEntry = {
            id: entry.id,
            name: entry.name,
            kind: entry.kind,
            disabled: false,
            status: undefined,
            updatedAt: entry.updatedAt,
            owner: entry.user,
        };
        // We should never disable folders, since we want to be able to navigate them
        if (selectable && defaultEntry.kind === EntryKind.Document) {
            defaultEntry.disabled = !selectable(defaultEntry);
        }
        if ('lastSignRequest' in entry) {
            const { status } = entry.lastSignRequest || {};
            const isSentForEsigning = Boolean(status && ACTIVE_STATUSES.includes(status));
            if (isSentForEsigning) {
                defaultEntry.status = status;
            }
        }
        if ('documentAi' in entry) {
            defaultEntry.documentAi = entry.documentAi;
        }
        if ('type' in entry) {
            const entryWithType = Object.assign(Object.assign({}, defaultEntry), { type: entry.type });
            if ('files' in entry) {
                const entryWithTypeAndFiles = Object.assign(Object.assign({}, entryWithType), { files: entry.files });
                if (entry.type === DocumentType.Uploaded) {
                    const file = entry.files.find(({ mimeType }) => mimeType !== MimeTypesByExtension.Pdf) ||
                        entry.files[0];
                    return Object.assign(Object.assign({}, entryWithTypeAndFiles), { mimeType: file.mimeType });
                }
                return entryWithTypeAndFiles;
            }
            return entryWithType;
        }
        return defaultEntry;
    });
    const selectRoot = () => {
        setLocation(undefined);
    };
    const selectFolder = (id) => {
        setLocation(id || undefined);
    };
    const handleSelect = (entry) => {
        setSelected(entry);
        if (!entry || !entry.kind || entry.kind === 'WORKSPACE') {
            selectRoot();
            return;
        }
        if (entry.kind === EntryKind.Folder && entry.id) {
            selectFolder(entry.id);
        }
        const shouldCallback = selectable ? selectable(entry) : true;
        if (shouldCallback && onSelect) {
            onSelect(entry);
        }
    };
    const fileCrumbs = getBreadcrumbs(filesystem.data);
    const { count } = filesystem.data;
    return {
        entries,
        selected,
        breadcrumbs: fileCrumbs,
        onSelect: handleSelect,
        selectRoot,
        error: filesystem.error,
        loading: filesystem.loading,
        selfId,
        count,
        location,
        folderPermission,
    };
}
