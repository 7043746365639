import { onError } from '@apollo/client/link/error';
import * as Sentry from 'shared/domains/sentry';
import { AuthenticatedVar } from '../generated/types';
import { authenticatedVar } from '../utils/cacheVars';
function checkAuth(graphQLErrors) {
    if (graphQLErrors.some(gqlError => typeof gqlError.extensions.subcode === 'string' &&
        gqlError.extensions.subcode === 'USER_NOT_AUTHENTICATED')) {
        return 'USER_NOT_AUTHENTICATED';
    }
    return undefined;
}
const consideredWarningCodes = [
    'USER_NOT_AUTHORIZED',
    'USER_NOT_AUTHENTICATED',
    'FORBIDDEN',
    'NOT_FOUND',
];
// Log any GraphQL errors or network error that occurred to Sentry
// https://www.apollographql.com/docs/react/api/link/apollo-link-error/
const errorLink = onError(options => {
    const { graphQLErrors, networkError, operation } = options;
    const { sentry } = operation.getContext();
    const ignoreCodes = Array.isArray(sentry === null || sentry === void 0 ? void 0 : sentry.ignore) ? sentry.ignore : [];
    if (networkError) {
        const { message, name } = networkError;
        Sentry.captureMessage(`[Network error]: Name: "${name}". ${message}`, {
            level: 'error',
            tags: {
                error_type: 'network_error',
                operation_name: operation.operationName,
            },
        });
    }
    if (graphQLErrors) {
        if (checkAuth(graphQLErrors) === 'USER_NOT_AUTHENTICATED') {
            authenticatedVar(AuthenticatedVar.NotAuthenticated);
        }
        graphQLErrors.forEach(error => {
            const { message, extensions } = error;
            const errorCode = ((extensions === null || extensions === void 0 ? void 0 : extensions.code) || '');
            const errorSubcode = ((extensions === null || extensions === void 0 ? void 0 : extensions.subcode) || '');
            const shouldIgnore = ignoreCodes.some(code => errorCode === code || errorSubcode === code);
            if (shouldIgnore) {
                return;
            }
            let sentryMessage = '';
            if (sentry && typeof sentry.message === 'function') {
                sentryMessage = sentry.message({ error, operation });
            }
            else {
                sentryMessage = message;
            }
            const isConsideredWarning = consideredWarningCodes.some(code => errorCode === code || errorSubcode === code);
            Sentry.captureMessage(`[GraphQL error]: In "${operation.operationName}". ${sentryMessage}`, {
                level: isConsideredWarning ? 'warning' : 'error',
                tags: {
                    error_type: 'graphql_error',
                    operation_name: operation.operationName,
                    error_code: errorCode,
                    error_subcode: errorSubcode,
                },
            });
        });
    }
});
export { errorLink };
