export function ScriveIcon() {
    return (<svg viewBox="0 0 448.76 116.04" fill="#27282d">
      <path d="M430.71,89.08A8.86,8.86,0,0,1,421.88,98a9.34,9.34,0,0,1-9.22-9,9.17,9.17,0,0,1,9-9A9.32,9.32,0,0,1,430.71,89.08Z"/>
      <path d="M411.38,63.22a46.78,46.78,0,0,0,.45-5c0-22-17.45-40.17-38.79-40.17s-38.65,17.79-38.65,39.8S351.7,98,373,98c17.3,0,32.07-12,37-28H397c-4.3,8.86-13.38,15.79-23.93,15.79C360,85.78,349.22,75.62,347,63.22ZM373,30.86a26.7,26.7,0,0,1,25.73,19.72H347.44A26.56,26.56,0,0,1,373,30.86Z"/>
      <path d="M324.85,18.08l-22.39,58.3L290.72,45.8h0c-5.71-15-17.3-27.71-38-27.71v12c16.48,0,23.19,11.62,27.63,23.3h0L297.54,98h10l30.51-79.87Z"/>
      <rect x="229.69" y="18.09" width="12.64" height="79.87"/>
      <path d="M218.55,18.09c-.41,0-1,0-1.36,0-17.65,0-38,15.18-38,40h0V98h12.63V59.61h0c0-20,13.71-29,25.4-29,.41,0,.95,0,1.36,0Z"/>
      <path d="M159.75,70.05a26,26,0,0,1-24.22,15.57c-14.74,0-26.69-12.38-26.69-27.58s12-27.45,26.69-27.45c10.57,0,19.9,7.06,24.22,15.94h12.63c-4.94-16-19.5-28.4-36.85-28.4C114.13,18.13,96.78,36,96.78,58s17.35,40,38.75,40c17.35,0,31.91-12,36.85-28Z"/>
      <path d="M65.81,65.49a30.31,30.31,0,0,1,7.75,2,7.19,7.19,0,0,1,3.61,3,8.46,8.46,0,0,1,1,4.33,10.49,10.49,0,0,1-1.9,5.92,12.35,12.35,0,0,1-4.64,3.53c-4.64,2.1-11,2.68-16,2.72a40.15,40.15,0,0,1-11.24-1.43A20.1,20.1,0,0,1,37.15,82a13.69,13.69,0,0,1-3.8-4.85A14,14,0,0,1,32,71.84h-14A29.19,29.19,0,0,0,21,83a22,22,0,0,0,6.21,7.54,31.21,31.21,0,0,0,8.61,4.59,47.49,47.49,0,0,0,9.9,2.32A77,77,0,0,0,56,98.07c10.92,0,25-2.14,32.06-11.44a22.14,22.14,0,0,0,4.24-13.42,18,18,0,0,0-1.61-7.79A19.41,19.41,0,0,0,86.3,59.5a22.36,22.36,0,0,0-6.42-4,29.8,29.8,0,0,0-7.67-2.1c-8.44-1.13-27.29-2.86-32.65-5.29a7.29,7.29,0,0,1-3.66-3,7.86,7.86,0,0,1-.95-4.3,8.82,8.82,0,0,1,1.72-5.52,12.75,12.75,0,0,1,4.6-3.52,26,26,0,0,1,6.36-1.84,42.51,42.51,0,0,1,6.86-.55,34.12,34.12,0,0,1,12.24,1.95,17.44,17.44,0,0,1,7.34,5,12,12,0,0,1,2.64,7.08h14a27.83,27.83,0,0,0-2.53-10.13,24.49,24.49,0,0,0-6.72-8,32.07,32.07,0,0,0-11-5.18A60.08,60.08,0,0,0,54.6,18.15a52.16,52.16,0,0,0-18,2.9,25.31,25.31,0,0,0-11.65,8.24,20.86,20.86,0,0,0-4.16,13,17.9,17.9,0,0,0,1.68,7.8A18.14,18.14,0,0,0,26.85,56a22.59,22.59,0,0,0,6.42,4,29.05,29.05,0,0,0,7.6,2.06C49.23,63.25,57.52,64.39,65.81,65.49Z"/>
    </svg>);
}
