import { Trans } from '@lingui/macro';
import { Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { getIntervals } from '../../ReminderField/RepeatReminderDropdown/intervals';
import { PreviewText } from '../PreviewText';
export function Repeat(props) {
    const { intervalLabelMap } = getIntervals();
    const { interval } = props;
    const intervalFormatted = interval ? intervalLabelMap[interval] : null;
    return (<PreviewText empty={!intervalFormatted} icon={<Falcon icon="repeat"/>}>
      {intervalFormatted ? (<Trans comment="tasks preview modal reminder interval field - When task reminder interval is set">
          Repeating reminder <Typography $appearance="100">{intervalFormatted}</Typography>
        </Trans>) : (<Trans comment="tasks preview modal reminder interval field - When task reminder interval is not set">
          Repeating reminder <Typography $appearance="100">Never</Typography>
        </Trans>)}
    </PreviewText>);
}
