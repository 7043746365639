import { useState } from 'react';
import { t } from '@lingui/macro';
import { IconButton, Menu } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useFileSystemAcls, useCreateFileSystemAcls } from 'app/domains/filesystem';
import { useFuse } from 'app/domains/search';
import { UserAndTeamSelect } from 'app/domains/users';
import { AclAction } from 'shared/domains/apollo/generated/types';
export function AddFileSystemAclsButton(props) {
    const submitButtonLabel = t({
        message: 'Give access',
        comment: 'filesystem give access dropdown: confirm button text',
    });
    const buttonLabel = t({
        message: 'Give access',
        comment: 'filesystem give access button label (tooltip or screen reader only. button will open dropdown of users and teams to give access to)',
    });
    const { entryId } = props;
    const [open, setOpen] = useState(false);
    const { createFileSystemAcls } = useCreateFileSystemAcls({ entryId });
    const actions = [AclAction.Read, AclAction.Write, AclAction.Delete];
    const [search, setSearch] = useState('');
    const { teamsWithoutAccess, usersWithoutAccess, loading } = useFileSystemAcls({
        entryId,
    });
    const usersSearchWithoutAccess = useFuse(usersWithoutAccess, {
        keys: ['name', 'email'],
        ignoreLocation: true,
        threshold: 0,
    });
    const teamsSearchWithoutAccess = useFuse(teamsWithoutAccess, {
        keys: ['name'],
        ignoreLocation: true,
        threshold: 0,
    });
    const handleSearch = (value) => {
        setSearch(value);
        usersSearchWithoutAccess.search(value);
        teamsSearchWithoutAccess.search(value);
    };
    const handleSubmit = (data) => {
        const { selectedUserIds: userIds, selectedTeamIds: teamIds } = data;
        return createFileSystemAcls({ actions, userIds, teamIds }).then(() => {
            setOpen(false);
        });
    };
    return (<Menu target={
        // TODO: add tooltip without causing the dropdown to position incorrectly or having the menu aria attributes on non-interactive div elements
        <IconButton appearance="ghost" $round onClick={() => setOpen(!open)} aria-label={buttonLabel}>
          <Falcon icon="add"/>
        </IconButton>} onClose={() => setOpen(false)} open={open}>
      <UserAndTeamSelect searching={loading} teams={teamsSearchWithoutAccess.searchString
            ? teamsSearchWithoutAccess.result
            : teamsWithoutAccess} users={usersSearchWithoutAccess.searchString
            ? usersSearchWithoutAccess.result
            : usersWithoutAccess} search={search} onSearch={handleSearch} onSubmit={handleSubmit} submitButtonLabel={submitButtonLabel}/>
    </Menu>);
}
