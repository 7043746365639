import { pxToRem, themeGet, unit } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Content = styled.div `
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: auto;
  padding: ${unit('2xl')};
  border-top: solid ${pxToRem(1)} ${themeGet('colors.gray.100')};
`;
