import { t } from '@lingui/macro';
export const getLabels = (approved, origin) => {
    const genericLabels = {
        requestError: t({
            comment: 'Handle cancel approval request dialog error message - Cancel approval request error',
            message: 'Something went wrong while cancelling the approval request, please try again.',
        }),
        alreadyCancelled: t({
            comment: 'Handle cancel approval request dialog error message - Already cancelled error',
            message: 'This document has already been cancelled by someone else.',
        }),
        alreadyRejected: t({
            comment: 'Handle cancel approval request dialog error message - Already rejected error',
            message: 'This document has already been rejected by someone else.',
        }),
        close: t({
            comment: 'Handle cancel approval request dialog - Close button label',
            message: 'Close',
        }),
        cancel: t({
            comment: 'Handle cancel approval request dialog - Cancel button label',
            message: 'Cancel',
        }),
        confirm: t({
            comment: 'Handle cancel approval request dialog - Confirm button label',
            message: 'Confirm',
        }),
        checkbox: t({
            comment: 'Handle cancel approval request dialog - Checkbox label',
            // eslint-disable-next-line lingui/text-restrictions
            message: 'I’m aware that the approval request will be cancelled.',
        }),
    };
    const editCancelPendingLabels = Object.assign(Object.assign({}, genericLabels), { title: t({
            comment: 'Handle cancel approval request dialog - Edit origin, pending document - Title',
            message: 'Edit document',
        }), message: t({
            comment: 'Handle cancel approval request dialog - Edit origin, pending document - Body',
            message: 'If you edit the document the pending approval request will be cancelled.',
        }) });
    const editCancelApprovedLabels = Object.assign(Object.assign({}, genericLabels), { title: t({
            comment: 'Handle cancel approval request dialog - Edit origin, approved document - Title',
            message: 'Edit document',
        }), message: t({
            comment: 'Handle cancel approval request dialog - Edit origin, approved document - Body',
            message: 'If you edit the document it will need a new approval before being sent for e-signing.',
        }) });
    const cancelLabels = Object.assign(Object.assign({}, genericLabels), { title: t({
            comment: 'Handle cancel approval request dialog - Sidebar origin, pending document - Title',
            message: 'Cancel approval request',
        }), message: t({
            comment: 'Handle cancel approval request dialog - Sidebar origin, pending document - Body',
            message: 'Confirm that you wish to cancel the approval request.',
        }) });
    if (origin === 'settings') {
        return cancelLabels;
    }
    return approved ? editCancelApprovedLabels : editCancelPendingLabels;
};
