import { t } from '@lingui/macro';
import { SignRequestStatus } from 'shared/domains/apollo/generated/types';
export const getLabelByStatus = (status) => {
    switch (status) {
        case SignRequestStatus.Pending:
        case SignRequestStatus.Opened:
            return t({
                comment: 'Status for a pending sign request in the attachment browser',
                message: 'Pending',
            });
        case SignRequestStatus.Signed:
            return t({
                comment: 'Status for a signed sign request in the attachment browser',
                message: 'Signed',
            });
        default:
            return null;
    }
};
