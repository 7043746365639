import { breakpoints, unit, pxToRem } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: ${unit('sm')};
`;
export const SelectContainer = styled.div `
  width: 100%;
  min-width: ${unit(35)};
  max-width: ${pxToRem(150)};

  ${breakpoints.xs} {
    max-width: ${pxToRem(264)};
  }
`;
