import { t } from '@lingui/macro';
import { Alert, AvatarLetter, AvatarWithLabel } from '@pocketlaw/tetris';
import { List } from './styled';
export function ApproversPermissionList(props) {
    const { approvers } = props;
    return (<List>
      <Alert appearance="warning" message={t({
            comment: 'Warning message that appears if the document has approvers without access to the document itself when the user is asking for approval',
            message: 'The following approvers do not have access to this document, by asking for approval they will gain access',
        })}/>
      {approvers.map(({ companyUserId, name }) => name && (<AvatarWithLabel key={companyUserId} title={name} size="md">
              <AvatarLetter>{name[0]}</AvatarLetter>
            </AvatarWithLabel>))}
    </List>);
}
