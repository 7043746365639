import { gql } from '@apollo/client';
export const updateUserAccessMutation = gql `
  mutation UpdateUserAccess($input: [FileSystemAclInsertInput!]!) {
    createdACL: createFileSystemAcls(input: $input) {
      id
      userId
      actions
      user {
        id
        name
        email
      }
    }
  }
`;
