export const filterUsersByRoles = (users, acceptedUserRoles) => {
    if (!acceptedUserRoles) {
        return users;
    }
    const filteredUsers = [];
    const owners = users.filter(user => user.role === 'owner');
    const admins = users.filter(user => user.canManageMember);
    const members = users.filter(user => !user.canManageMember && user.role === 'member');
    if (acceptedUserRoles.includes('owner')) {
        filteredUsers.push(...owners);
    }
    if (acceptedUserRoles.includes('admin')) {
        filteredUsers.push(...admins);
    }
    if (acceptedUserRoles.includes('member')) {
        filteredUsers.push(...members);
    }
    const uniqueUsers = Array.from(new Set(filteredUsers));
    const sortedUsers = uniqueUsers.sort((a, b) => a.name.localeCompare(b.name));
    return sortedUsers;
};
