var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { useMoveEntry } from 'app/domains/filesystem';
import { EntryKind } from 'shared/domains/apollo/generated/types';
import { PickFolderDialog } from 'shared/domains/common-ui';
export function MoveActionDialog(props) {
    const { onClose, entityId, parentId } = props;
    const { addToast } = useToast();
    const [move, { loading }] = useMoveEntry({
        kind: EntryKind.Document,
        id: entityId,
    });
    const handleSubmit = (entry) => __awaiter(this, void 0, void 0, function* () {
        try {
            yield move(entry.id);
            onClose();
            addToast({
                appearance: 'success',
                title: t({
                    message: `Document moved to ${entry.name}`,
                    comment: 'Move document success toast',
                }),
            });
        }
        catch (_a) {
            addToast({
                appearance: 'danger',
                title: t({
                    message: 'Error occurred while moving document',
                    comment: 'Move document error toast',
                }),
            });
        }
    });
    return (<PickFolderDialog initialFolderId={parentId} dialogTitle={t({ comment: 'Move document dialog title', message: 'Move document' })} dialogSubtitle={t({
            comment: 'Move document dialog subtitle',
            message: 'Choose where you want to move the document',
        })} submitLabel={t({ comment: 'Move document dialog submit label', message: 'Move' })} loaderOverlay={{
            loading,
            title: t({
                comment: 'Move document dialog loading title',
                message: 'Moving document',
            }),
            description: t({
                comment: 'Move document dialog loading description',
                message: 'Please wait while we are moving the document...',
            }),
        }} onSubmit={handleSubmit} onClose={onClose}/>);
}
