export var FormStatus;
(function (FormStatus) {
    FormStatus["Uploading"] = "UPLOADING";
})(FormStatus || (FormStatus = {}));
export var ApprovalErrorSubcodes;
(function (ApprovalErrorSubcodes) {
    ApprovalErrorSubcodes["LacksApproval"] = "ERROR_DOCUMENT_LACKS_APPROVAL";
    ApprovalErrorSubcodes["AlreadyPending"] = "ERROR_APPROVAL_REQUEST_ALREADY_PENDING";
    ApprovalErrorSubcodes["AlreadyApproved"] = "ERROR_APPROVAL_REQUEST_ALREADY_APPROVED";
    ApprovalErrorSubcodes["AlreadyRejected"] = "ERROR_APPROVAL_REQUEST_ALREADY_REJECTED";
    ApprovalErrorSubcodes["AlreadyCancelled"] = "ERROR_APPROVAL_REQUEST_ALREADY_CANCELLED";
})(ApprovalErrorSubcodes || (ApprovalErrorSubcodes = {}));
export const APPROVAL_IGNORE_CODES = [
    ApprovalErrorSubcodes.AlreadyPending,
    ApprovalErrorSubcodes.AlreadyApproved,
    ApprovalErrorSubcodes.AlreadyRejected,
    ApprovalErrorSubcodes.AlreadyCancelled,
];
