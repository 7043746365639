import { t } from '@lingui/macro';
import { Box } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useField } from 'formik';
import { SelectDataPoint } from './SelectDataPoint';
export function AddDataPoint() {
    const [field] = useField('aggregations');
    if (field.value.length) {
        return null;
    }
    return (<Box>
      <SelectDataPoint icon={<Falcon icon="plus"/>} name="aggregations[0]" label={t({
            message: 'Add',
            comment: 'Insights widget form: Add data point',
        })}/>
    </Box>);
}
