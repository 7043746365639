import { Alert } from '@pocketlaw/tetris';
import { useFormikContext } from 'formik';
import { getTextByStatus } from './utils';
export function AlertByStatus() {
    const { status } = useFormikContext();
    if (!status) {
        return null;
    }
    const message = getTextByStatus(status);
    return <Alert appearance="danger" message={message}/>;
}
