import { gql } from '@apollo/client';
export const getDocumentTypes = gql `
  query getDocumentTypes($documentId: ID!) {
    document: fileSystemDocument(id: $documentId) {
      id
      type
      files {
        mimeType
      }
    }
  }
`;
