import { t } from '@lingui/macro';
import { AiBorderStackingOrderGuard, AvatarWithLabel } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
export function ReviewHeader(props) {
    const { reviewCompleted, playbookName } = props;
    const title = reviewCompleted
        ? t({
            comment: 'Plai review progress status - progress completed - title',
            message: 'Review completed',
        })
        : t({ comment: 'Plai review progress status - in progress - title', message: 'Review status' });
    const subtitle = playbookName
        ? t({
            comment: 'Plai review progress status - playbook name subtitle',
            message: `Playbook: ${playbookName}`,
        })
        : undefined;
    return (<AiBorderStackingOrderGuard guard>
      <AvatarWithLabel size="lg" title={title} subtitle={subtitle} appearance={reviewCompleted ? 'success' : 'ai'}>
        {reviewCompleted ? <Falcon icon="check"/> : <Falcon icon="sparkles"/>}
      </AvatarWithLabel>
    </AiBorderStackingOrderGuard>);
}
