import { bucketAggregationToFilter } from '../bucketAggregationToFilter';
import { fieldDisplayValue } from '../fieldDisplayValue';
import { getSelectedField } from '../getSelectedField';
export function getKeyedBucketFilterPayload(bucket, aggregation) {
    const { name } = bucket;
    const field = getSelectedField(aggregation);
    const formattedPrimaryName = fieldDisplayValue(field, name);
    const filter = bucketAggregationToFilter(bucket, aggregation);
    return {
        id: formattedPrimaryName,
        field,
        filter,
    };
}
