import { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useEsigning } from 'app/domains/esigning';
import { useLastSignRequest } from 'app/domains/esigning/hooks/useLastSignRequest';
const ONE_SECOND = 1000;
const POLL_INTERVAL = 1.5 * ONE_SECOND;
export const usePollLastSignRequest = () => {
    const { creating, cancelling } = useEsigning();
    const { startPolling, stopPolling } = useLastSignRequest();
    const location = useLocation();
    const match = matchPath(location.pathname, {
        path: `/document/:id`,
        exact: true,
    });
    const shouldPoll = (creating || cancelling) && Boolean(match);
    useEffect(() => {
        if (shouldPoll) {
            startPolling(POLL_INTERVAL);
            return () => stopPolling();
        }
        return undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldPoll]);
};
