var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useApolloClient, useMutation } from '@apollo/client';
import { SetDefaultSignatories } from './mutation';
import { CACHE_WRITE_QUERY } from './query';
export const useSetDefaultSignatories = () => {
    const [mutate] = useMutation(SetDefaultSignatories);
    const client = useApolloClient();
    const writeComposerCache = (composerId, newDefaultSignatories) => {
        const typename = 'Unstable__Composer';
        client.writeQuery({
            query: CACHE_WRITE_QUERY,
            data: {
                composer: {
                    __typename: typename,
                    id: composerId,
                    defaultSignatories: newDefaultSignatories,
                },
            },
            variables: {
                id: composerId,
            },
        });
    };
    const setDefaultSignatories = (options) => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield mutate({ variables: options });
        const { setDefaultSignatories: defaultSignatories = [] } = result.data || {};
        if (defaultSignatories.length === 0) {
            options.composerIds.forEach(composerId => {
                writeComposerCache(composerId, null);
            });
        }
        else {
            defaultSignatories.forEach(defaultSignatory => {
                writeComposerCache(defaultSignatory.composerId, defaultSignatory);
            });
        }
    });
    return { setDefaultSignatories };
};
