import { Trans, t } from '@lingui/macro';
import { Alert, Button } from '@pocketlaw/tetris';
import { useHistory } from 'react-router-dom';
import { useRole } from 'app/domains/users/hooks/useRole';
export function NoCreditsAlert() {
    const history = useHistory();
    const role = useRole();
    const handleClick = () => {
        history.push('/settings/esigning');
    };
    return (<Alert appearance="info" title={t({
            comment: 'Alert title for the user if the company has no more e-sign credits',
            message: 'Out of credits',
        })} message={t({
            comment: 'Alert message for the user if the company has no more e-sign credits',
            message: 'You are out of e-sign credits',
        })}>
      {role.can('manage:settings') && (<Button appearance="secondary" onClick={handleClick}>
          <Trans comment="Message for the user on how to add new e-sign credits to the company">
            Contact our support to add new credits
          </Trans>
        </Button>)}
    </Alert>);
}
