import { Box } from '@pocketlaw/tetris';
import { IllustrationPageLeft } from '../styled';
import { HeaderLine, Line, Paragraph, SubheaderLine } from './styled';
export function IllustrationLeft() {
    return (<IllustrationPageLeft>
      <Box flexDirection="column" gap="lg" p="2xl">
        <HeaderLine $width={70}/>
        <Paragraph>
          <Line $width={80}/>
          <Line $width={90}/>
          <Line $width={60} $redlined/>
          <Line $width={40} $redlined/>
          <Line $width={70}/>
        </Paragraph>
        <SubheaderLine $width={40}/>
        <Paragraph>
          <Line $width={50}/>
          <Line $width={90}/>
          <Line $width={30} $redlined/>
          <Line $width={60} $redlined/>
          <Line $width={70} $redlined/>
          <Line $width={40} $redlined/>
          <Line $width={50}/>
        </Paragraph>
      </Box>
    </IllustrationPageLeft>);
}
