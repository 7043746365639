import { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { IconButton, Menu, MenuHeader, LoaderOverlay, MenuFooter } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import * as yup from 'yup';
import { useGetTeamDetails, useTeamRole, useUpdateTeam } from 'app/domains/teams';
import { useRole } from 'app/domains/users';
import { Form, SubmitButton, TextField, TextAreaField } from 'shared/domains/forms';
import { Content } from './styled';
export function EditTeamButton(props) {
    var _a;
    const label = t({
        message: 'Edit team',
        comment: 'Edit team button label (screen readers)',
    });
    const headingLabel = t({
        message: 'Edit team details',
        comment: 'team profile: Edit team dropdown title',
    });
    const teamNameInputLabel = t({
        message: 'Team name',
        comment: 'team profile: Edit team dropdown, team name input label',
    });
    const teamDescriptionInputLabel = t({
        message: 'Description',
        comment: 'team profile: Edit team dropdown, team description input label',
    });
    const nameMessage = t({
        message: 'Name is required',
        comment: 'Error message when name is empty',
    });
    const updateTeamSchema = yup.object().shape({
        name: yup.string().required(nameMessage),
        description: yup.string(),
    });
    const { teamId } = props;
    const [open, setOpen] = useState(false);
    const role = useRole();
    const teamRole = useTeamRole(teamId);
    const canUpdateTeam = role.can('update:team') || teamRole.can('update:team');
    const { team, loading } = useGetTeamDetails(teamId);
    const { updateTeam } = useUpdateTeam(teamId);
    const initialValues = {
        name: (team === null || team === void 0 ? void 0 : team.name) || '',
        description: (_a = team === null || team === void 0 ? void 0 : team.description) !== null && _a !== void 0 ? _a : undefined,
    };
    if (!canUpdateTeam) {
        return null;
    }
    const handleSubmit = (values) => updateTeam(values).then(() => setOpen(false));
    return (<Menu target={<IconButton aria-label={label} appearance="secondary" onClick={() => setOpen(!open)}>
          <Falcon icon="pen"/>
        </IconButton>} onClose={() => setOpen(false)} open={open}>
      <Form onSubmit={handleSubmit} initialValues={initialValues} validationSchema={updateTeamSchema} enableReinitialize>
        <LoaderOverlay enabled={loading} transparent>
          <MenuHeader action={<IconButton size="small" $round aria-label={headingLabel} appearance="ghost" onClick={() => setOpen(false)}>
                <Falcon icon="close"/>
              </IconButton>}>
            <Trans comment="team profile edit dropdown title">Edit team details</Trans>
          </MenuHeader>

          <Content>
            <TextField name="name" label={teamNameInputLabel}/>
            <TextAreaField label={teamDescriptionInputLabel} name="description"/>
          </Content>

          <MenuFooter>
            <SubmitButton $stretch>
              <Trans comment="team profile edit dropdown: update button label">Update</Trans>
            </SubmitButton>
          </MenuFooter>
        </LoaderOverlay>
      </Form>
    </Menu>);
}
