import { makeVar } from '@apollo/client';
/**
 * makeVar is opted to use because we need a way to gain
 * access to this data outside of react components / hooks.
 * But we still need to sync the values between Apollo - React.
 */
const authState = makeVar({
    credentials: null,
    user: null,
});
function isAuthenticated(state) {
    const { credentials } = state;
    const authenticated = Boolean(credentials === null || credentials === void 0 ? void 0 : credentials.accessToken);
    return authenticated;
}
function set(data) {
    return authState(data);
}
function get() {
    const state = authState();
    const { credentials, user } = state;
    const { name, email, picture } = user || {};
    const authenticated = isAuthenticated(state);
    const preferredLocale = user === null || user === void 0 ? void 0 : user['https://pocketlaw.com/preferred_locale'];
    const sub = user === null || user === void 0 ? void 0 : user.sub;
    const profile = {
        name,
        email,
        preferredLocale,
        picture,
        sub,
    };
    return {
        authenticated,
        credentials,
        user: profile,
    };
}
export { set, get };
