import { gql } from '@apollo/client';
export const documentsCreatedLastTwoMonthsMetricsQuery = gql `
  query documentsCreatedLastTwoMonthsMetrics($oneMonthAgo: DateTime!, $twoMonthsAgo: DateTime!) {
    search(
      query: ""
      limit: 0
      where: { entityType: { in: [document_uploaded, document_freetext, document_composed] } }
      aggregations: {
        type: dateRange
        name: "documentsCreatedMonthly"
        dateRangeInput: {
          field: createdAt
          ranges: [
            {
              fromDate: $oneMonthAgo
              toNow: true
              fromOffset: { value: 0, timeUnit: day, roundingTimeUnit: day }
            }
            {
              fromDate: $twoMonthsAgo
              toDate: $oneMonthAgo
              fromOffset: { value: 0, timeUnit: day, roundingTimeUnit: day }
              toOffset: { value: 0, timeUnit: day, roundingTimeUnit: day }
            }
          ]
        }
      }
    ) {
      aggregations {
        ... on SearchResponseBucketedAggregation {
          __typename
          name
          sumOtherCount
          buckets {
            id: key
            name: key
            value: count
            from
            to
          }
        }
      }
    }
  }
`;
