import { Trans } from '@lingui/macro';
import { ConfirmDialog } from '@pocketlaw/tetris';
import { useDeleteEntry } from 'app/domains/filesystem';
export function DeleteDocumentDialog(props) {
    const { id, onDeleted, onClose } = props;
    const [deleteEntry, { loading }] = useDeleteEntry(id, { onDeleted });
    const handleConfirm = () => {
        deleteEntry().then(onClose);
    };
    return (<ConfirmDialog heading={<Trans comment="Title for delete document dialog (from document view and files)">
          Delete document
        </Trans>} appearance="danger" size="sm" loading={loading} confirm={<Trans>Delete</Trans>} cancel={<Trans>Cancel</Trans>} onConfirm={handleConfirm} onCancel={onClose}>
      <Trans>Are you sure you want to delete this document? This action is irreversible.</Trans>
    </ConfirmDialog>);
}
