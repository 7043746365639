import { Trans } from '@lingui/macro';
import { localizedFormatDate } from 'shared/domains/common-utils';
import { DueDateText } from './styled';
function DueDate(props) {
    const { date } = props;
    const formattedDate = localizedFormatDate(date, {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    });
    if (!formattedDate) {
        return null;
    }
    return (<DueDateText $fontSize="tiny" $appearance="100" $lineHeight="neutralized">
      <Trans comment="Task list item due date">Due {formattedDate}</Trans>
    </DueDateText>);
}
export { DueDate };
