import { Avatar, IconButton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { StyledDialogHeader } from './styled';
export function DialogHeader(props) {
    const { title, onClose } = props;
    return (<StyledDialogHeader title={title} media={<Avatar shape="square">
          <Falcon icon="user"/>
        </Avatar>} action={<IconButton $round size="small" appearance="ghost" onClick={onClose}>
          <Falcon icon="xmark"/>
        </IconButton>}/>);
}
