import { forwardRef } from 'react';
import { t } from '@lingui/macro';
import { Table, TableBody, TableRowCollapse, LoaderOverlay, EmptyState } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { ContextCell } from './components/ContextCell';
import { DateCell } from './components/DateCell';
import { DropdownContent } from './components/DropdownContent';
import { ErrorAlert } from './components/ErrorAlert';
import { MembersCell } from './components/MembersCell';
import { NameCell } from './components/NameCell';
import { TeamsHeader } from './components/TeamsHeader';
import { useTeamsContext } from '../TeamsProvider/useTeamsContext';
import { HideMobileCol, ShowMobileCol } from './styled';
export const TeamsTable = forwardRef((props, ref) => {
    const { teams, setSort, sort, loading, error, noSearchResults } = useTeamsContext();
    if (loading) {
        return <LoaderOverlay />;
    }
    if (error) {
        return <ErrorAlert />;
    }
    if (noSearchResults) {
        return (<EmptyState withAvatar title={t({ message: 'No teams found', comment: 'teams table - empty search title' })} description={t({
                message: 'There are no teams that match your current search.',
                comment: 'teams table - empty search subtitle',
            })} icon={<Falcon icon="search"/>}/>);
    }
    if (teams.length < 1) {
        return (<EmptyState withAvatar title={t({ message: 'Create teams', comment: 'teams table - no teams title' })} description={t({
                message: 'Give team access to certain files & templates.',
                comment: 'teams table - no teams subtitle',
            })} icon={<Falcon icon="people-group"/>}/>);
    }
    return (<Table ref={ref} onSort={(id, order) => setSort({ id, order })} sortId={sort.id} sortOrder={sort.order}>
      <colgroup>
        <ShowMobileCol width={1}/>
        <col width="auto"/>
        <HideMobileCol width="auto"/>
        <HideMobileCol width="auto"/>
        <col width={1}/>
      </colgroup>
      <TeamsHeader />
      <TableBody>
        {teams.map(({ id, name, createdAt, members }) => (<TableRowCollapse key={id} colSpan={3} expandibleHeaders="dropdown" dropdownContent={<DropdownContent createdAt={createdAt} members={members}/>} hideDropdownOnBreakpoint="sm">
            <NameCell teamId={id} name={name}/>
            <DateCell createdAt={createdAt}/>
            <MembersCell members={members}/>
            <ContextCell id={id} name={name}/>
          </TableRowCollapse>))}
      </TableBody>
    </Table>);
});
