import { Trans } from '@lingui/macro';
import { MenuList, MenuSubheader } from '@pocketlaw/tetris';
import { useFileBrowserContext, NoSelectableFileMessage } from 'app/domains/filesystem';
import { EntryKind } from 'shared/domains/apollo/generated/types';
import { MultiselectItem } from './MultiselectItem';
import { FolderButton } from './styled';
export function FileBrowserEntries(props) {
    const { handleSelect } = props;
    const { entries, onSelect, selfId } = useFileBrowserContext();
    const handleFolderSelect = (entry) => {
        const isSelectable = selfId !== entry.id && !entry.disabled;
        if (isSelectable) {
            onSelect(entry);
        }
    };
    if (!entries.length) {
        return <NoSelectableFileMessage />;
    }
    return (<MenuList>
      <MenuSubheader>
        <Trans comment="Metadata filebrowser subheader">Files</Trans>
      </MenuSubheader>
      {entries.map(entry => (<MultiselectItem id={entry.id} type={entry.kind} disabled={entry.disabled} handleSelect={handleSelect}>
          {entry.kind === EntryKind.Folder ? (<FolderButton type="button" onClick={() => handleFolderSelect(entry)}>
              {entry.name}
            </FolderButton>) : (entry.name)}
        </MultiselectItem>))}
    </MenuList>);
}
