import { Box, Skeleton, TableBodyCell, TableRow, pxToRem } from '@pocketlaw/tetris';
export function SkeletonRow() {
    const getNumberBetween = (min, max) => pxToRem(Math.floor(Math.random() * (max - min + 1) + min));
    const nameWidth = getNumberBetween(120, 220);
    const languageWidth = getNumberBetween(60, 80);
    return (<TableRow>
      <TableBodyCell headers="cell:name">
        <Box alignItems="center" gap="md">
          <Skeleton width={pxToRem(24)} height={pxToRem(24)} borderRadius="md"/>
          <Skeleton width={nameWidth} height={pxToRem(16)} borderRadius="md"/>
        </Box>
      </TableBodyCell>
      <TableBodyCell headers="cell:language">
        <Box alignItems="center" gap="md">
          <Skeleton width={pxToRem(24)} height={pxToRem(24)} borderRadius="circle"/>
          <Skeleton width={languageWidth} height={pxToRem(16)} borderRadius="md"/>
        </Box>
      </TableBodyCell>
      <TableBodyCell headers="cell:select"/>
    </TableRow>);
}
