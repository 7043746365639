import { unit } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${unit('xl')};
  padding: ${unit('md')};
`;
export const Ul = styled.ul `
  display: flex;
  flex-direction: column;
  gap: ${unit('md')};
`;
export const Li = styled.li `
  display: flex;
  flex-direction: row;
  gap: ${unit('sm')};
  align-items: flex-end;
`;
