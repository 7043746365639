var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Trans, t } from '@lingui/macro';
import { Alert, Box, Button, Dialog, DialogContent, EmptyState, Loader } from '@pocketlaw/tetris';
import { useHistory } from 'react-router-dom';
import { useMount } from 'react-use';
import { useCloneFreeTextDocument } from 'shared/domains/documents';
export function DuplicatingFreetextLoader(props) {
    const { documentId, documentName, onClose } = props;
    const [cloneFreeTextDocument, { loading, error }] = useCloneFreeTextDocument();
    const history = useHistory();
    const handleClose = () => {
        if (!loading) {
            onClose();
        }
    };
    const duplicateDocument = () => __awaiter(this, void 0, void 0, function* () {
        const name = t({
            comment: 'Copy document - New document name',
            message: `${documentName} (Copy)`,
        });
        cloneFreeTextDocument({ id: documentId, name }).then(response => {
            if (response.data) {
                history.push(`/document/${response.data.cloneFromFileSystemDocument.id}`);
            }
        });
    });
    useMount(duplicateDocument);
    return (<Dialog size="sm" onClose={handleClose}>
      <DialogContent>
        {error ? (<Box flexDirection="column" gap="lg" pt="2xl">
            <Alert appearance="danger" title={t({
                comment: 'Duplicate freetext document - Error title',
                message: 'Could not copy document',
            })} message={t({
                comment: 'Duplicate freetext document - Error message',
                message: 'Something went wrong, please try again',
            })}/>
            <Box gap="md" justifyContent="flex-end">
              <Button appearance="secondary" onClick={handleClose}>
                <Trans comment="Close label">Close</Trans>
              </Button>
              <Button appearance="secondary" onClick={duplicateDocument}>
                <Trans comment="Try again label">Try again</Trans>
              </Button>
            </Box>
          </Box>) : (<EmptyState icon={<Loader size={12}/>} title={t({
                message: 'Copying document',
                comment: 'Duplicating freetext document label',
            })} description={t({
                message: 'Please wait while we are copying the document',
                comment: 'Duplicating freetext document label',
            })}/>)}
      </DialogContent>
    </Dialog>);
}
