var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment } from 'react';
import { Avatar } from '@pocketlaw/tetris';
import { HtmlStringToReact } from 'app/domains/global-search/components/HtmlStringToReact';
import { getSearchEntityIcon } from 'app/domains/global-search/utils/getSearchEntityIcon';
import { getSearchHighlightContext } from 'app/domains/global-search/utils/getSearchHighlightContext';
import { Container, StyledText } from './styled';
export function Match(props) {
    const { name, highlight, type } = props, restProps = __rest(props, ["name", "highlight", "type"]);
    const icon = getSearchEntityIcon(type);
    const { highlightText, showNameOnly } = getSearchHighlightContext(name, highlight);
    return (<Container {...restProps}>
      <div>
        <Avatar appearance="light" size="sm">
          {icon}
        </Avatar>
      </div>
      <div>
        {showNameOnly ? (<StyledText size="base">
            <HtmlStringToReact allowedTags={['mark']} text={highlightText}/>
          </StyledText>) : (<Fragment>
            <StyledText size="base">{name}</StyledText>
            <StyledText appearance="neutral" size="tiny">
              … <HtmlStringToReact allowedTags={['mark']} text={highlightText}/> …
            </StyledText>
          </Fragment>)}
      </div>
    </Container>);
}
