import { t } from '@lingui/macro';
import { Alert } from '@pocketlaw/tetris';
import { ErrorAlertContainer } from '../styled';
export function ErrorAlert() {
    const errorMessage = t({
        message: 'Something went wrong. Please try again.',
        comment: 'team profile: add user dropdown error message after trying to add users or teams',
    });
    return (<ErrorAlertContainer>
      <Alert message={errorMessage} appearance="danger"/>
    </ErrorAlertContainer>);
}
