import { Loader, pxToRem, themeGet, unit } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const StyledLoader = styled(Loader) `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
export const Container = styled.div `
  height: 100%;
  display: flex;
  flex-direction: column;
`;
export const MessageList = styled.div `
  padding: ${unit('xl')};
  flex: 1 1 auto;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;
export const InputContainer = styled.div `
  padding: ${unit('xl')};
  flex: 0 0 auto;
  border-top: solid ${pxToRem(1)} ${themeGet('colors.gray.100')};
`;
export const EmptyContainer = styled.div `
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;
