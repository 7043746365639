export function sortByUpdatedAt(a, b) {
    return (new Date(b.fileSystemDocument.updatedAt).valueOf() -
        new Date(a.fileSystemDocument.updatedAt).valueOf());
}
export function transformData(data) {
    const sharedDocumentsObject = {};
    data === null || data === void 0 ? void 0 : data.externalSharingInvites.forEach(invite => {
        var _a, _b, _c;
        const { inviteeEmail, messageThreads, fileSystemDocument } = invite;
        const { unreadMessagesCount = 0, messages } = messageThreads[0] || {};
        const totalMessagesCount = (_a = messages === null || messages === void 0 ? void 0 : messages.length) !== null && _a !== void 0 ? _a : 0;
        const invitee = {
            id: ((_b = invite.invitee) === null || _b === void 0 ? void 0 : _b.id) || inviteeEmail,
            name: (_c = invite.invitee) === null || _c === void 0 ? void 0 : _c.name,
            email: inviteeEmail,
        };
        if (!sharedDocumentsObject[fileSystemDocument.id]) {
            sharedDocumentsObject[fileSystemDocument.id] = {
                fileSystemDocument,
                unreadMessagesCount,
                invitees: [invitee],
                totalMessagesCount,
            };
        }
        else {
            sharedDocumentsObject[fileSystemDocument.id].unreadMessagesCount += unreadMessagesCount;
            sharedDocumentsObject[fileSystemDocument.id].invitees.push(invitee);
            sharedDocumentsObject[fileSystemDocument.id].totalMessagesCount += totalMessagesCount;
        }
    });
    return Object.values(sharedDocumentsObject);
}
