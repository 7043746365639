import { getAllowedProperties } from './getAllowedProperties';
export const isHeader = (item) => item.header === true;
export const isItem = (item) => !!item.value;
export const allowedProperties = () => getAllowedProperties().reduce((acc, current) => {
    const { values, type, label: propertyLabel } = current;
    const header = {
        type,
        id: type,
        value: undefined,
        label: propertyLabel,
        header: true,
    };
    const formattedValues = values.map(value => ({
        type,
        id: `${type}.${value.value}`,
        value: value.value,
        label: value.label,
        header: false,
    }));
    return [...acc, header, ...formattedValues];
}, []);
