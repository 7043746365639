import { t } from '@lingui/macro';
import { AttachmentErrors } from '../Provider/types';
export const getErrorMessage = (error) => {
    switch (error) {
        case AttachmentErrors.NoFileProvided:
            return t({
                comment: 'Error message when no file was provided when uploading an attachment',
                message: `No file was provided. Please, try again.`,
            });
        case AttachmentErrors.NoFileFound:
            return t({
                comment: 'Error message when we could not find a file associated with the entry selected in workspace',
                message: `We could not retrieve and attach the selected file. Please, try again later.`,
            });
        case AttachmentErrors.UploadFailed:
            return t({
                comment: 'Error message when the upload has failed when uploading an attachment',
                message: `Something went wrong. Please, try again later.`,
            });
        case AttachmentErrors.GetPdfFailed:
            return t({
                comment: 'Error message when selecting and converting a document as e-signing attachment',
                message: `Something went wrong while fetching the document. Please, try again later.`,
            });
        case AttachmentErrors.InvalidFormat:
            return t({
                comment: 'Error message when the file format is not supported when uploading an attahcment',
                message: `The file you tried to upload is not supported. Please try again with another file.`,
            });
        default:
            return null;
    }
};
