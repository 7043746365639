import { themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${themeGet('spacing.md')};
`;
export const LinkContainer = styled.div `
  display: flex;
  flex-direction: row;
  gap: ${themeGet('spacing.sm')};
`;
