import { useSearchQuery } from 'app/domains/global-search';
import { SearchEntityTypeEnum } from 'shared/domains/apollo/generated/types';
export function useAllDocumentsSearchResults() {
    return useSearchQuery({
        where: {
            entityType: {
                in: [
                    SearchEntityTypeEnum.DocumentComposed,
                    SearchEntityTypeEnum.DocumentFreetext,
                    SearchEntityTypeEnum.DocumentUploaded,
                ],
            },
        },
    });
}
