import { useQuery } from '@apollo/client';
import { useEsigningProviderMetadata } from 'app/domains/esigning/hooks/useEsigningProviderMetadata';
import { QUERY } from './query';
export function useGetAvailableEsigningCredits() {
    var _a;
    const { properties } = useEsigningProviderMetadata();
    const query = useQuery(QUERY, {
        skip: !properties.showCreditBalance,
        fetchPolicy: 'network-only',
    });
    const availableCredits = ((_a = query.data) === null || _a === void 0 ? void 0 : _a.esigningCreditCounts.available) || 0;
    return Object.assign(Object.assign({}, query), { availableCredits });
}
