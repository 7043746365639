import { t } from '@lingui/macro';
import { localeConfig } from 'shared/domains/languages/config';
const msToMinutes = (ms) => {
    if (ms === null || ms === undefined) {
        return undefined;
    }
    return Math.round(ms / 1000 / 60);
};
export function transformData(data, currentPlan) {
    var _a, _b, _c, _d;
    const { composer } = data || {};
    const { templates = [], composerPlans = [], approval } = composer || {};
    const { approvers = [] } = approval || {};
    const [composerMetadata] = (composer === null || composer === void 0 ? void 0 : composer.metadata) || [];
    const [categoryMetadata] = ((_a = composer === null || composer === void 0 ? void 0 : composer.category) === null || _a === void 0 ? void 0 : _a.metadata) || [];
    const composerPlansData = composerPlans.map(({ plan }) => plan) || [];
    const locked = Boolean(composerPlans) && !composerPlansData.find(plan => (plan === null || plan === void 0 ? void 0 : plan.id) === (currentPlan === null || currentPlan === void 0 ? void 0 : currentPlan.id));
    const fallbackDescription = t({
        message: 'No description available for this template',
        comment: 'Fallback description for composer when displaying information about template before creating a draft.',
    });
    const fallbackName = t({
        message: 'Untitled',
        comment: 'Fallback name for composer  when displaying information about template before creating a draft.',
    });
    const transformedTemplates = (templates === null || templates === void 0 ? void 0 : templates.map(template => {
        var _a, _b;
        return ({
            id: template.id,
            body: template.body,
            name: localeConfig.getLocale({ code: (_a = template.marketLocale) === null || _a === void 0 ? void 0 : _a.locale }).getName(),
            languageCode: ((_b = template.marketLocale) === null || _b === void 0 ? void 0 : _b.code) || '',
            latestVersion: template.latestVersion,
        });
    })) || [];
    const category = {
        id: (_b = composer === null || composer === void 0 ? void 0 : composer.category) === null || _b === void 0 ? void 0 : _b.id,
        title: categoryMetadata === null || categoryMetadata === void 0 ? void 0 : categoryMetadata.title,
    };
    const transformedComposer = {
        id: composer === null || composer === void 0 ? void 0 : composer.id,
        name: (composerMetadata === null || composerMetadata === void 0 ? void 0 : composerMetadata.name) || fallbackName,
        descriptionBody: (composerMetadata === null || composerMetadata === void 0 ? void 0 : composerMetadata.description) || fallbackDescription,
        treeVersion: (_c = composer === null || composer === void 0 ? void 0 : composer.tree) === null || _c === void 0 ? void 0 : _c.version,
        tree: composer === null || composer === void 0 ? void 0 : composer.tree,
        attachments: ((_d = composer === null || composer === void 0 ? void 0 : composer.defaultAttachments) === null || _d === void 0 ? void 0 : _d.attachments) || [],
    };
    const transformedApprovers = approvers.map(approver => { var _a; return (_a = approver.companyUser) === null || _a === void 0 ? void 0 : _a.user; });
    return {
        composer: transformedComposer,
        marketCode: composer === null || composer === void 0 ? void 0 : composer.marketCode,
        estimatedTime: msToMinutes(composer === null || composer === void 0 ? void 0 : composer.estimatedTime),
        templates: transformedTemplates,
        approvers: transformedApprovers,
        locked,
        category,
    };
}
