import { useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Button, Tooltip } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { usePlaiDrawer } from 'app/domains/documents';
import { usePlai } from '../../../PlaiProvider';
import { PlaybookReferenceDialog } from '../../../PlaybookReferenceDialog';
import { Container } from './styled';
export function ChatActions(props) {
    const { feedback, isStreaming, onRewriteClick } = props;
    const { acceptFeedback, feedbackHighlightsData } = usePlai();
    const { documentHighlights = [], loading } = feedbackHighlightsData || {};
    const { closeFeedbackChat } = usePlaiDrawer();
    const [renderReferenceDialog, setRenderReferenceDialog] = useState(false);
    const isRewriteAvailable = documentHighlights.length > 0 && !loading;
    const showDialog = () => setRenderReferenceDialog(true);
    const hideDialog = () => setRenderReferenceDialog(false);
    const handleResolve = () => {
        acceptFeedback(feedback.feedback.id);
        closeFeedbackChat();
    };
    const buttonProps = {
        appearance: 'secondary',
        size: 'small',
    };
    const tooltipProps = {
        position: 'top',
    };
    return (<Container>
      {renderReferenceDialog && (<PlaybookReferenceDialog feedback={feedback} onClose={hideDialog}/>)}

      {isRewriteAvailable && (<Tooltip {...tooltipProps} title={t({
                comment: 'Review chat - user actions - rewrite button tooltip',
                message: 'Rewrite the clause',
            })}>
          <Button {...buttonProps} disabled={isStreaming} onClick={onRewriteClick}>
            <Falcon icon="pen-to-square"/>
            <Trans comment="Review chat - user actions - rewrite button label">Rewrite</Trans>
          </Button>
        </Tooltip>)}

      <Tooltip {...tooltipProps} title={t({
            comment: 'Review chat - user actions - resolve button tooltip',
            message: 'Resolve the issue',
        })}>
        <Button {...buttonProps} disabled={isStreaming} onClick={handleResolve}>
          <Falcon icon="check"/>
          <Trans comment="Review chat - user actions - resolve button label">Resolve</Trans>
        </Button>
      </Tooltip>

      <Tooltip {...tooltipProps} title={t({
            comment: 'Review chat - user actions - playbook button tooltip',
            message: 'See playbook reference',
        })}>
        <Button {...buttonProps} onClick={showDialog}>
          <Falcon icon="book"/>
          <Trans comment="Review chat - user actions - playbook button label">Playbook</Trans>
        </Button>
      </Tooltip>
    </Container>);
}
