import { gql } from '@apollo/client';
export const pollBulkUploadQuery = gql `
  query PollBulkUploadStatus($id: ID!) {
    documentUploadJob(id: $id) {
      id
      status
      entries {
        id
        kind
      }
    }
  }
`;
