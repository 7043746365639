import { css } from 'styled-components';
export const editorSidebarStyles = css `
  .ck.ck-sidebar {
    position: relative;
    height: 100%;
  }

  &:has(.ck-sidebar:empty) {
    width: 0;
  }
`;
