export const MessageFragmentFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MessageFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatMessage' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'streamToken' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'feedbackScore' } },
                ],
            },
        },
    ],
};
export const ChatFragmentFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ChatFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DocumentChat' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'resolved' } },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'resolutionStatus' },
                        name: { kind: 'Name', value: 'chatResolutionStatus' },
                    },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'messages' },
                        name: { kind: 'Name', value: 'items' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'createdAt' },
                                            value: { kind: 'EnumValue', value: 'desc' },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'IntValue', value: '2' },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MessageFragment' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MessageFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatMessage' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'streamToken' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'feedbackScore' } },
                ],
            },
        },
    ],
};
export const GetLastDocumentChatDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetLastDocumentChat' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'documentId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'messagesLimit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                    defaultValue: { kind: 'IntValue', value: '100' },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'messagesWhere' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatMessagesWhereInput' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'documentChatsByUser' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'IntValue', value: '1' },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'createdAt' },
                                            value: { kind: 'EnumValue', value: 'desc' },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'documentId' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'eq' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'documentId' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'resolved' } },
                                {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'resolutionStatus' },
                                    name: { kind: 'Name', value: 'chatResolutionStatus' },
                                },
                                {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'messages' },
                                    name: { kind: 'Name', value: 'items' },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'orderBy' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'createdAt' },
                                                        value: { kind: 'EnumValue', value: 'desc' },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'limit' },
                                            value: { kind: 'Variable', name: { kind: 'Name', value: 'messagesLimit' } },
                                        },
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'where' },
                                            value: { kind: 'Variable', name: { kind: 'Name', value: 'messagesWhere' } },
                                        },
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MessageFragment' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MessageFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatMessage' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'streamToken' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'feedbackScore' } },
                ],
            },
        },
    ],
};
