import { useState } from 'react';
import { t } from '@lingui/macro';
import { Menu, MenuBody, MenuItem, MenuList, defaultFloatingPositionMiddleware, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useField } from 'formik';
import { getIntervals } from './intervals';
import { LinkField } from '../../LinkField';
export function RepeatReminderDropdown() {
    const { intervals } = getIntervals();
    const [isOpen, setOpen] = useState(false);
    const [reminderDueDateField] = useField('reminder.dueDate');
    const [reminderIntervalField, , helpers] = useField('reminder.interval');
    const dueDate = reminderDueDateField.value;
    const interval = reminderIntervalField.value || undefined;
    const { label = '' } = intervals.find(({ value }) => value === interval) || {};
    const handleChange = (value) => {
        helpers.setValue(value);
    };
    if (!dueDate) {
        return null;
    }
    return (<Menu open={isOpen} onClose={() => setOpen(false)} options={{
            middleware: [...defaultFloatingPositionMiddleware],
        }} target={<LinkField name="reminder.interval" icon={<Falcon icon="repeat"/>} onClick={() => setOpen(!isOpen)} onRemove={() => handleChange(undefined)} linkPrefix={t({
                message: 'Repeat reminder',
                comment: 'Task repeat reminder field when set',
            })} linkText={interval
                ? label
                : t({ message: 'Add repeater', comment: 'Task repeat reminder field no repeat set' })}/>}>
      <MenuBody>
        <MenuList>
          {intervals.map(item => (<MenuItem key={item.value} $selected={item.value === interval} onClick={() => handleChange(item.value)}>
              {item.label}
            </MenuItem>))}
        </MenuList>
      </MenuBody>
    </Menu>);
}
