import { memo } from 'react';
import { Text } from '@pocketlaw/tetris/deprecated';
import useSetAsRead from 'shared/domains/feedback/hooks/useSetAsRead';
import { Container, MessageBubble, MessageNotification, StyledText } from './styled';
function MessageContainer(props) {
    const { message, hideSender, hideTimestamp } = props;
    const { id, type, message: text, sender, createdAt, isRead } = message;
    const { name } = sender;
    useSetAsRead(message);
    const showNotification = type === 'receiver' && !isRead;
    const incomingMessage = type === 'receiver';
    return (<Container key={id} $incoming={incomingMessage} $hideTimestamp={hideTimestamp}>
      {!hideSender && (<StyledText $incoming={incomingMessage} size="small" weight="semibold">
          {name}
        </StyledText>)}
      {!hideTimestamp && (<Text size="tiny" appearance="neutral">
          {createdAt}
        </Text>)}
      <MessageBubble $incoming={incomingMessage}>
        <Text size="small">{text}</Text>
        {showNotification && <MessageNotification />}
      </MessageBubble>
    </Container>);
}
export const Message = memo(MessageContainer, (prev, next) => prev.message.id === next.message.id);
