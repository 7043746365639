import { t } from '@lingui/macro';
import { AssistantContext } from 'app/domains/assistant/types';
export function getTitleFromContext(context) {
    switch (context) {
        case AssistantContext.Selection:
            return t({
                comment: 'Document assistant - chat thread - intro disclaimer - title',
                message: 'New selection',
            });
        case AssistantContext.Document:
        default:
            return t({
                comment: 'Document assistant - chat thread - intro disclaimer - title',
                message: 'New conversation',
            });
    }
}
