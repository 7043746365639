import { useState } from 'react';
import { t } from '@lingui/macro';
import { Table, Alert, Box, LoaderOverlay } from '@pocketlaw/tetris';
import { SearchResultTableBody } from './SearchResultsTableBody';
import { SearchResultsTableHead } from './SearchResultsTableHead';
import { TableContext } from './TableContext';
import { useSelected } from './useSelected';
import { useSearchContext } from '../SearchProvider';
import { SearchResultsNoMatch } from '../SearchResultsNoMatch';
import { SearchResultsPagination } from '../SearchResultsPagination';
import { SearchTableColumnToggle } from '../SearchTableColumnToggle';
export function SearchResultsTable(props) {
    const { results = [], loading, error, totalHitsCount = 0, limit = 0, actions } = props;
    const [selected, setSelected] = useSelected(results);
    const { values, setOrderBy } = useSearchContext();
    const [activeAction, setActiveAction] = useState();
    const { orderBy } = values;
    const [sortId, sortOrder] = orderBy ? Object.entries(orderBy)[0] : [];
    const resultCount = results.length;
    const noResults = resultCount === 0 && !loading;
    const hasBulkActions = Boolean(actions);
    const handleSort = (newSortId, newSortState) => {
        if (newSortId) {
            setOrderBy({ [newSortId]: newSortState });
        }
    };
    if (error) {
        return (<Alert appearance="danger" message={t({
                message: 'Something went wrong, please try again later',
                comment: 'Error alert message',
            })}/>);
    }
    if (noResults) {
        return <SearchResultsNoMatch />;
    }
    return (<TableContext.Provider value={{
            activeAction,
            setActiveAction,
            selected,
            setSelected,
            results,
            actions: hasBulkActions,
        }}>
      <Box flexDirection="column" gap="lg">
        <Box gap="lg" alignItems="center">
          {actions}

          <Box ml="auto" gap="md">
            <SearchResultsPagination disabled={loading} totalCount={totalHitsCount} limit={limit}/>
            <SearchTableColumnToggle />
          </Box>
        </Box>

        <LoaderOverlay transparent enabled={loading}>
          <Table sortId={sortId} sortOrder={sortOrder} onSort={handleSort}>
            <SearchResultsTableHead />
            <SearchResultTableBody />
          </Table>
        </LoaderOverlay>
      </Box>
    </TableContext.Provider>);
}
