import { t } from '@lingui/macro';
import { AvatarWithLabel, DialogHeader } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { FormDialog } from 'shared/domains/forms';
import { Checkboxes } from './Checkboxes';
import { Content } from '../Content';
import { Footer } from '../Footer';
export function DislikeDialog(props) {
    const { commentId, onClose, onDislike, mutationError } = props;
    const initialValues = {
        id: commentId,
        feedback: '',
        harmful: false,
        incorrect: false,
        unhelpful: false,
    };
    return (<FormDialog size="sm" onClose={onClose} onSubmit={onDislike} initialValues={initialValues}>
      <DialogHeader media={<AvatarWithLabel truncate title={t({
                message: 'Provide feedback',
                comment: 'Document assistant - feedback dialog - title',
            })} subtitle={t({
                message: 'Let us know how we can improve',
            })} shape="circle">
            <Falcon icon="thumbs-down"/>
          </AvatarWithLabel>}/>
      <Content error={mutationError}>
        <Checkboxes />
      </Content>
      <Footer onClose={onClose}/>
    </FormDialog>);
}
