import { themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const ContentSpacer = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${themeGet('spacing.xl')};
`;
export const RadioCardContainer = styled.div `
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: ${themeGet('spacing.xl')};
`;
