export var DialogType;
(function (DialogType) {
    DialogType[DialogType["None"] = 0] = "None";
    DialogType[DialogType["EditEmail"] = 1] = "EditEmail";
    DialogType[DialogType["EditMobile"] = 2] = "EditMobile";
    DialogType[DialogType["EditSignatory"] = 3] = "EditSignatory";
    DialogType[DialogType["RemoveSignatory"] = 4] = "RemoveSignatory";
    DialogType[DialogType["RemoveSignatoryScrive"] = 5] = "RemoveSignatoryScrive";
})(DialogType || (DialogType = {}));
export var ErrorSubcodes;
(function (ErrorSubcodes) {
    ErrorSubcodes["CannotModifySignedSigner"] = "ERROR_CANNOT_MODIFY_SIGNED_SIGNER";
})(ErrorSubcodes || (ErrorSubcodes = {}));
