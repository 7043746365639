var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fragment, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { IconButton, Menu, MenuList, MenuItem, MenuBody, useToast } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useDeleteDashboardWidget } from '../../hooks/useDeleteDashboardWidget';
import { useUpdateDashboardWidget } from '../../hooks/useUpdateDashboardWidget';
import { useInsights } from '../InsightsProvider';
import { WidgetForm } from '../WidgetForm';
export function WidgetMenu(props) {
    const { widget, slotId } = props;
    const { id } = useInsights();
    const [updateWidget] = useUpdateDashboardWidget();
    const { removeWidget } = useDeleteDashboardWidget();
    const { addToast } = useToast();
    const [editing, setEditing] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const toggleMenu = () => setMenuOpen(!menuOpen);
    const handleOnCloseForm = () => setEditing(false);
    const handleOnCloseMenu = () => setMenuOpen(false);
    const disableRemove = !(widget === null || widget === void 0 ? void 0 : widget.id);
    const handleEdit = () => {
        setEditing(true);
        setMenuOpen(false);
    };
    const handleRemove = () => {
        if (!(widget === null || widget === void 0 ? void 0 : widget.id)) {
            return;
        }
        try {
            removeWidget({
                dashboardId: id,
                slotId,
                widgetId: widget.id,
            });
            addToast({
                title: t({
                    message: 'Widget removed',
                    comment: 'Insights: Toast message when widget is removed',
                }),
                appearance: 'success',
            });
        }
        catch (_a) {
            addToast({
                title: t({
                    message: 'Widget removal failed',
                    comment: 'Insights: Toast message when widget removal failed',
                }),
                appearance: 'danger',
            });
        }
        setMenuOpen(false);
    };
    const handleUpdate = (updatedWidget) => __awaiter(this, void 0, void 0, function* () {
        try {
            if (!(widget === null || widget === void 0 ? void 0 : widget.id)) {
                throw Error('Insights: A widget UUID was not provided when trying to save a widget');
                return;
            }
            yield updateWidget(widget.id, updatedWidget);
            addToast({
                title: t({
                    message: 'Widget updated',
                    comment: 'Insights: Toast message when widget is updated',
                }),
                appearance: 'success',
            });
            setEditing(false);
        }
        catch (_a) {
            addToast({
                title: t({
                    message: 'Widget update failed',
                    comment: 'Insights: Toast message when widget update failed',
                }),
                appearance: 'danger',
            });
        }
    });
    return (<Fragment>
      {widget && editing && (<WidgetForm initialValues={widget} onClose={handleOnCloseForm} onSubmit={handleUpdate}/>)}
      <Menu open={menuOpen} onClose={handleOnCloseMenu} target={<IconButton appearance="ghost" size="small" $round onClick={toggleMenu}>
            <Falcon icon="ellipsis-v"/>
          </IconButton>}>
        <MenuBody>
          <MenuList>
            <MenuItem onClick={handleEdit}>
              <Falcon icon="pen"/>
              <Trans comment="Insights: Widget menu item - Edit">Edit</Trans>
            </MenuItem>
            <MenuItem disabled={disableRemove} onClick={handleRemove}>
              <Falcon icon="trash-can"/>
              <Trans comment="Insights: Widget menu item - Remove">Remove</Trans>
            </MenuItem>
          </MenuList>
        </MenuBody>
      </Menu>
    </Fragment>);
}
