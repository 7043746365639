import { plural, select, t } from '@lingui/macro';
import { Loader, ListItem, Tooltip } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useRemoveFileSystemAcl } from 'app/domains/filesystem';
import { TeamAvatarWithLabel } from 'app/domains/teams';
import { UserAvatarWithLabel } from 'app/domains/users';
import { StyledIconButton, IconContainer, AvatarContainer } from './styled';
export function AccessListItem(props) {
    const { name, roleName, numberOfTeamMembers = 0, aclId, type, hasAccessToParentFolder, isSelf, } = props;
    const buttonTooltip = t({
        message: 'Revoke access',
        comment: 'Filesystem access drawer list item remove access button tooltip',
    });
    const { removeFileSystemAcl, loading } = useRemoveFileSystemAcl();
    const teamSubtitle = t({
        message: plural(numberOfTeamMembers, {
            one: '# member',
            other: '# members',
        }),
        comment: 'Filesystem access drawer list item subtitle for teams',
    });
    const tooltipTitle = t({
        comment: 'info tooltip in access list for existing folder/document: explanation why you can not remove access for current user/team',
        message: select(type, {
            user: `You can not remove access for this user because they have access to a parent folder`,
            team: `You can not remove access for this team because they have access to a parent folder`,
            other: `You can not remove access for this user/team because they have access to a parent folder`,
        }),
    });
    const handleClick = (aclIdToRemove) => {
        removeFileSystemAcl({ type, aclId: aclIdToRemove });
    };
    return (<ListItem>
      <AvatarContainer>
        {type === 'user' && (<UserAvatarWithLabel size="md" title={name !== null && name !== void 0 ? name : undefined} subtitle={roleName} truncate/>)}
        {type === 'team' && (<TeamAvatarWithLabel size="md" title={name !== null && name !== void 0 ? name : undefined} subtitle={teamSubtitle} truncate/>)}
      </AvatarContainer>
      {hasAccessToParentFolder && !isSelf && (<Tooltip title={tooltipTitle}>
          <IconContainer>
            <Falcon icon="info-circle"/>
          </IconContainer>
        </Tooltip>)}
      {!hasAccessToParentFolder && aclId && !isSelf && (<Tooltip title={buttonTooltip}>
          <StyledIconButton appearance="ghost" $round size="small" onClick={() => handleClick(aclId)} disabled={loading}>
            {loading ? <Loader /> : <Falcon icon="xmark"/>}
          </StyledIconButton>
        </Tooltip>)}
    </ListItem>);
}
