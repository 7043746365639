import { useState } from 'react';
import { t } from '@lingui/macro';
import { Button, Menu, MenuBody, defaultFloatingPositionMiddleware, List, ListItem, parentWidth, useIsMobile, } from '@pocketlaw/tetris';
import { Text } from '@pocketlaw/tetris/deprecated';
import { Falcon } from '@pocketlaw/tetris/macro';
import { keyExtractor } from 'shared/domains/common-utils';
import { useTasks } from '../TasksProvider/useTasks';
import { SortIds } from '../types';
import { StyledButtonContent, StyledMenuContainer } from './styled';
function SortSelector() {
    const { sortBy, setSortBy } = useTasks();
    const [isOpen, setIsOpen] = useState(false);
    const isMobile = useIsMobile();
    const options = isMobile
        ? {}
        : { middleware: [...defaultFloatingPositionMiddleware, parentWidth()] };
    const sortOptions = Object.freeze({
        [SortIds.createdAt]: {
            asc: t({
                comment: 'Option text to sort tasks by created at date oldest first',
                message: 'Created (oldest)',
            }),
            desc: t({
                comment: 'Option text to sort tasks by created at date newest first',
                message: 'Created (newest)',
            }),
        },
        [SortIds.updatedAt]: {
            asc: t({
                comment: 'Option text to sort tasks by updated on date oldest first',
                message: 'Updated (oldest)',
            }),
            desc: t({
                comment: 'Option text to sort tasks by updated on date newest first',
                message: 'Updated (newest)',
            }),
        },
        [SortIds.dueDate]: {
            asc: t({
                comment: 'Option text to sort tasks by due date oldest first',
                message: 'Due date (oldest)',
            }),
            desc: t({
                comment: 'Option text to sort tasks by due date newest first',
                message: 'Due date (newest)',
            }),
        },
        [SortIds.title]: {
            asc: t({
                comment: 'Option text to sort tasks by title alphabetically',
                message: 'Title (A-Z)',
            }),
            desc: t({
                comment: 'Option text to sort tasks by title reverse alphabetically',
                message: 'Title (Z-A)',
            }),
        },
        [SortIds.status]: {
            asc: t({
                comment: 'Option text to sort tasks by status alphabetically',
                message: 'Status (A-Z)',
            }),
            desc: t({
                comment: 'Option text to sort tasks by status reverse alphabetically',
                message: 'Status (Z-A)',
            }),
        },
    });
    const currentSort = sortOptions[sortBy.sortId];
    const sortIds = keyExtractor(sortOptions);
    // NOTE: remove when status is available in task widget
    const filteredSortIds = sortIds.filter(id => id !== SortIds.status);
    const flattenedSortOptions = filteredSortIds.flatMap(id => {
        const value = sortOptions[id];
        const sortOrders = keyExtractor(value);
        return sortOrders.map(sortOrder => {
            const selected = sortBy.sortId === id && sortBy.sortOrder === sortOrder;
            return { sortId: id, sortOrder, text: value[sortOrder], selected };
        });
    });
    return (<StyledMenuContainer>
      <Menu options={options} target={<Button appearance="secondary" size="small" onClick={() => setIsOpen(!isOpen)} $stretch>
            <StyledButtonContent>
              <Falcon icon="filter-list" $size={16}/>
              {currentSort[sortBy.sortOrder]}
              {isOpen ? (<Falcon icon="chevron-up" $size={16}/>) : (<Falcon icon="chevron-down" $size={16}/>)}
            </StyledButtonContent>
          </Button>} open={isOpen} onClose={() => setIsOpen(false)}>
        <MenuBody>
          <List aria-labelledby="button-list" as="nav" role="menu">
            {flattenedSortOptions.map(({ sortId, sortOrder, text, selected }) => (<ListItem key={`${sortId}-${sortOrder}`} as="button" onClick={() => {
                setSortBy({ sortId, sortOrder });
                setIsOpen(false);
            }} $selected={selected}>
                <Text>{text}</Text>
              </ListItem>))}
          </List>
        </MenuBody>
      </Menu>
    </StyledMenuContainer>);
}
export { SortSelector };
