import { themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const StyledSearchInput = styled.input `
  border: none;
  outline: none;
  :focus {
    outline: none;
    border: none;
  }
  width: 100%;
  font-size: ${themeGet('fontSize.large')};
  flex: 1;
  background-color: transparent;
  color: ${themeGet('colors.text.300')};
`;
export const InputContainer = styled.div `
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${themeGet('spacing.md')};
  padding: ${themeGet('spacing.sm')} ${themeGet('spacing.lg')};
`;
