import { gql } from '@apollo/client';
export const SetDefaultSignatories = gql `
  mutation SetDefaultSignatories(
    $composerIds: [UUID!]!
    $signatories: [DefaultSignatoryInput!]!
    $ccRecipients: [DefaultCarbonCopyRecipientInput!]!
  ) {
    setDefaultSignatories(
      input: { composerIds: $composerIds, signatories: $signatories, ccRecipients: $ccRecipients }
    ) {
      composerId
      signatories {
        email
        firstName
        lastName
        label
        title
        mobile
        createdAt
        updatedAt
        authenticationMode
      }
      ccRecipients {
        email
        firstName
        lastName
        label
        createdAt
        updatedAt
      }
    }
  }
`;
