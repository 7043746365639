import { ApproversProvider } from 'app/domains/approval';
import { DocumentDrawerHeader } from 'shared/domains/documents';
import { Content } from './Content';
import { DrawerContainer } from '../../styled';
import { getTitle } from '../../utils';
export function Approval(props) {
    const { onClose, onGoBack } = props;
    return (<DrawerContainer>
      <DocumentDrawerHeader type="nested" title={getTitle('approval')} onClose={onClose} onGoBack={onGoBack}/>
      <ApproversProvider>
        <Content />
      </ApproversProvider>
    </DrawerContainer>);
}
