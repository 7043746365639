import { themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Scroller = styled.div `
  overflow-y: auto;
  margin-top: auto;
`;
export const Thread = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: ${themeGet('spacing.xl')};
`;
