import { IconButton, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useAssistant } from 'app/domains/assistant';
import { getMessageByStatus, getTitleByStatus } from './utils';
import { Actions, Container, Spacer } from './styled';
export function ResolvedChatActions() {
    const { resolutionStatus, clearThread } = useAssistant();
    const title = getTitleByStatus(resolutionStatus);
    const message = getMessageByStatus(resolutionStatus);
    return (<Container>
      <Spacer>
        <Typography $fontWeight="bold">{title}</Typography>
        <Typography>{message}</Typography>
        <Actions>
          <IconButton onClick={clearThread} appearance="secondary">
            <Falcon icon="trash"/>
          </IconButton>
        </Actions>
      </Spacer>
    </Container>);
}
