import { Box } from '@pocketlaw/tetris';
import { LegendItem } from './LegendItem';
export function Legend(props) {
    const { items } = props;
    if (items.length === 0) {
        return null;
    }
    return (<Box alignItems="center" gap="xl">
      {items.map(([type, amount]) => (<LegendItem key={type} type={type} currentAmount={amount}/>))}
    </Box>);
}
