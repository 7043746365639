import { Fragment } from 'react';
import { MediaQuery } from 'shared/domains/common-ui';
import { Desktop } from './Desktop';
import { ErrorMessage } from './ErrorMessage';
import { LinkToSearchPage } from './LinkToSearchPage';
import { Mobile } from './Mobile';
import { useUniversalSearch } from '../useUniversalSearch';
export function SearchPopupResults() {
    const { error } = useUniversalSearch();
    if (error) {
        return <ErrorMessage />;
    }
    return (<Fragment>
      <MediaQuery breakpoint="palm">
        <Mobile />
        <LinkToSearchPage />
      </MediaQuery>
      <MediaQuery breakpoint="lapAndUp">
        <Desktop />
      </MediaQuery>
    </Fragment>);
}
