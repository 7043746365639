import { Fragment } from 'react';
import { IconButton } from '@pocketlaw/tetris';
import { DocumentDrawerWrapper, useDocumentDrawer } from 'shared/domains/documents';
import { SettingsDrawer } from './SettingsDrawer';
import { Arrow, Background, Container } from './styled';
export function SettingsButton() {
    const { isDrawerOpen, toggleDrawer, closeDrawer } = useDocumentDrawer();
    const isOpen = isDrawerOpen('app:settings');
    const handleClick = () => toggleDrawer('app:settings');
    const handleClose = () => closeDrawer('app:settings');
    return (<Fragment>
      <IconButton appearance="secondary" onClick={handleClick}>
        <Container $open={isOpen}>
          <Background />
          <Arrow />
        </Container>
      </IconButton>
      {isOpen && (<DocumentDrawerWrapper key="app:settings">
          <SettingsDrawer onClose={handleClose}/>
        </DocumentDrawerWrapper>)}
    </Fragment>);
}
