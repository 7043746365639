var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMemo } from 'react';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { WidgetGridLayout } from './WidgetGridLayout';
import { useUpdateDashboardSlot } from '../../hooks/useUpdateDashboardSlot';
import { useInsights } from '../InsightsProvider';
import { Widget } from '../Widget';
import { WidgetMenu } from '../Widget/WidgetMenu';
export function WidgetGrid() {
    const { slots, filters } = useInsights();
    const [updateSlots] = useUpdateDashboardSlot();
    const { addToast } = useToast();
    const onLayoutChange = (_, __, newItem) => __awaiter(this, void 0, void 0, function* () {
        const { i, h, w, x, y } = newItem;
        try {
            yield updateSlots({
                slotId: i,
                input: {
                    x,
                    y,
                    w,
                    h,
                },
            });
        }
        catch (_a) {
            addToast({
                title: t({
                    message: 'Widget update failed',
                    comment: 'Insights: Toast message when widget update failed',
                }),
                appearance: 'danger',
            });
        }
    });
    const layouts = useMemo(() => ({
        lg: slots.map(slot => ({
            i: slot.id,
            x: slot.x,
            y: slot.y,
            w: slot.w,
            h: slot.h,
            widgetId: slot.widgetId,
        })),
    }), [slots]);
    return (<WidgetGridLayout onLayoutChange={onLayoutChange} editMode layouts={layouts}>
      {slots.map(slot => (<div key={slot.id}>
          <Widget widget={slot.widget} filters={filters} editMode actions={<WidgetMenu widget={slot.widget} slotId={slot.id}/>}/>
        </div>))}
    </WidgetGridLayout>);
}
