import { Plugin } from 'pleditor';
export class OffsetPlugin extends Plugin {
    init() {
        this.useOffset();
    }
    useOffset() {
        this.editor.on('ready', () => {
            var _a;
            const [offset] = ((_a = this.editor.ui.element) === null || _a === void 0 ? void 0 : _a.getClientRects()) || [];
            if (offset) {
                this.editor.ui.viewportOffset = { top: offset.top };
            }
        });
    }
}
