import { useEsigning } from 'app/domains/esigning/components/EsigningProvider';
import { useDefaultSignatories } from 'app/domains/esigning/hooks/useDefaultSignatories';
import { useEsigningProviderMetadata } from 'app/domains/esigning/hooks/useEsigningProviderMetadata';
import { useLastSignRequest } from 'app/domains/esigning/hooks/useLastSignRequest';
import { useSignatoryFieldFallbacks } from 'app/domains/esigning/utils';
import { getFormSignatories, filterSignatoryNameFormats, getFormCcRecipients } from './utils';
export function useSignatoriesInForm() {
    const { documentId } = useEsigning();
    const { lastSignRequest, loading: loadingLastSignRequest } = useLastSignRequest();
    const { defaultSignatories, loading: loadingDefaultSignatories } = useDefaultSignatories(documentId);
    const { getFieldFallbacksForSignatories } = useSignatoryFieldFallbacks();
    const { properties: providerProperties } = useEsigningProviderMetadata();
    const { nameFormat } = providerProperties;
    const loading = loadingLastSignRequest || loadingDefaultSignatories;
    const signatories = getFormSignatories(lastSignRequest.signatories, defaultSignatories.signatories);
    const ccRecipients = getFormCcRecipients(lastSignRequest.ccRecipients, defaultSignatories.ccRecipients);
    const filteredSignatories = filterSignatoryNameFormats(signatories, nameFormat);
    const filteredCcRecipients = filterSignatoryNameFormats(ccRecipients, nameFormat);
    // Don't include or rely on ids here, because the form (consumer of this hook) will add new items without ids
    // We have ids for signing default signatories so we can update their order if needed
    const formSignatories = getFieldFallbacksForSignatories([
        ...filteredSignatories,
        ...filteredCcRecipients,
    ]);
    return { formSignatories, loading };
}
