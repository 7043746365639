import { gql } from '@apollo/client';
export const QUERY = gql `
  query pollingLastSignRequest($documentId: ID!) {
    document: fileSystemDocument(id: $documentId) {
      id
      lastSignRequest {
        id
        status
        statusSubcode: status_subcode
        signatories {
          id
          status
        }
      }
    }
  }
`;
