import { Typography } from '@pocketlaw/tetris';
import { StatusContainer } from './styled';
export function PartyStatusLabel(props) {
    const { timestamps, smallIcon } = props;
    if (!timestamps) {
        return null;
    }
    const { date, time } = timestamps;
    const typographyProps = {
        $appearance: '100',
        $fontSize: 'tiny',
        $fontWeight: 'regular',
        $lineHeight: 'neutralized',
    };
    const dot = '\u2022';
    return (<StatusContainer>
      {smallIcon}
      <Typography {...typographyProps}>{date}</Typography>
      <Typography {...typographyProps}>{dot}</Typography>
      <Typography {...typographyProps}>{time}</Typography>
    </StatusContainer>);
}
