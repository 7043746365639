import { t } from '@lingui/macro';
import { Locale, ButtonView, createDropdown } from 'pleditor';
import { PenLine, PenField } from './icons';
import { featureAvailability } from '../../components/FeatureAvailability';
import { PleditorPlugin } from '../PleditorPlugin';
export class EditMode extends PleditorPlugin {
    init() {
        const { editor } = this;
        editor.ui.componentFactory.add('editMode', () => {
            const trackChangeCommand = editor.commands.get('trackChanges');
            const feature = editor.config.get('featureAvailability');
            const suggestingButtonConfig = {
                label: t({ message: 'Suggesting', comment: 'Button text to enable suggestion mode' }),
                withText: true,
                icon: PenField,
                class: 'pleditor-toolbar-suggestion-mode-button',
            };
            const editingButtonConfig = {
                label: t({ message: 'Editing', comment: 'Button text to enable editing mode' }),
                withText: true,
                icon: PenLine,
                class: 'pleditor-toolbar-suggestion-mode-button',
            };
            const editModeButton = new ButtonView();
            editModeButton.set(Object.assign({}, ((trackChangeCommand === null || trackChangeCommand === void 0 ? void 0 : trackChangeCommand.value) ? editingButtonConfig : suggestingButtonConfig)));
            const locale = new Locale();
            const dropdown = createDropdown(locale);
            editModeButton.on('execute', () => {
                if (feature.suggestions) {
                    editor.execute('trackChanges');
                }
                else {
                    featureAvailability('suggestions');
                }
                dropdown.panelView.set('isVisible', false);
            });
            dropdown.panelView.children.add(editModeButton);
            dropdown.buttonView.set(Object.assign(Object.assign({}, ((trackChangeCommand === null || trackChangeCommand === void 0 ? void 0 : trackChangeCommand.value) ? suggestingButtonConfig : editingButtonConfig)), { class: 'pleditor-toolbar-fa-icon-button' }));
            trackChangeCommand === null || trackChangeCommand === void 0 ? void 0 : trackChangeCommand.on('change:value', (e, data, value) => {
                editModeButton.set(value ? editingButtonConfig : suggestingButtonConfig);
                dropdown.buttonView.set(value ? suggestingButtonConfig : editingButtonConfig);
            });
            return dropdown;
        });
    }
}
