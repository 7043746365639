import { pxToRem, themeGet, themeGetColor } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const OverlayButton = styled.button `
  background-color: transparent;
  padding: ${themeGet('spacing.lg')} ${themeGet('spacing.xl')};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  border-radius: ${themeGet('borderRadius.lg')};
  cursor: pointer;
  box-sizing: border-box;
  transition: background-color 0.2s ease-in-out;

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 ${pxToRem(4)} ${themeGet('colors.focus.primary')};
  }
`;
export const HoverContent = styled.div `
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${themeGet('spacing.xl')};
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: ${themeGet('spacing.lg')} ${themeGet('spacing.xl')};
`;
export const ImageContainer = styled.div `
  position: relative;
  background-image: url(${props => props.$src});
  background-size: cover;
  background-position: center center;
  height: ${pxToRem(150)};
  border-radius: ${themeGet('borderRadius.lg')};
  margin-bottom: ${themeGet('spacing.lg')};
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05);

    ${OverlayButton} {
      background-color: ${themeGetColor('gray.1000', 0.05)};
    }

    ${HoverContent} {
      opacity: 1;
    }
  }
`;
export const TitleContainer = styled.div `
  padding-inline: ${themeGet('spacing.md')};
`;
export const AlignRight = styled.div `
  display: flex;
  margin-left: auto;
`;
