import { t } from '@lingui/macro';
import { Box, Loader } from '@pocketlaw/tetris';
import { DocumentAiDisabledReason } from 'shared/domains/apollo/generated/types';
export function getDescription(reason) {
    switch (reason) {
        case DocumentAiDisabledReason.TextExtractionFailed: {
            return t({
                comment: 'Document assistant drawer - when AI is currently not available on a document - subtitle (text extraction failed)',
                message: 'Document AI features are currently not available on this document because we failed to extract the text from the document - contact customer support for help',
            });
        }
        case DocumentAiDisabledReason.TextExtractionPending: {
            return t({
                comment: 'Document assistant drawer - when AI is currently not available on a document - subtitle (text extraction pending)',
                message: 'Document AI features are currently not available on this document because we are processing things in the background - check back later',
            });
        }
        default: {
            return t({
                comment: 'Document assistant drawer - when AI is currently not available on a document - subtitle (generic)',
                message: 'Document AI features are currently not available on this document - check back later or contact support if the problem persists',
            });
        }
    }
}
export function getActions(reason) {
    switch (reason) {
        case DocumentAiDisabledReason.TextExtractionPending: {
            return (<Box flexDirection="column" alignItems="center" gap="2xl">
          <Loader size={12}/>
        </Box>);
        }
        default: {
            return null;
        }
    }
}
