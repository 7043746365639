import { usePlai } from 'app/domains/review';
import { Loader } from './Loader';
import { NoHighlights } from './NoHighlights';
import { Stepper } from './Stepper';
import { Container } from '../styled';
export function Highlights() {
    const { feedbackHighlightsData } = usePlai();
    const { loading, documentHighlights = [] } = feedbackHighlightsData || {};
    const highlightsAvailable = !loading && documentHighlights.length > 0;
    if (loading) {
        return (<Container>
        <Loader />
      </Container>);
    }
    if (highlightsAvailable) {
        return (<Container>
        <Stepper docHighlights={documentHighlights}/>
      </Container>);
    }
    return (<Container>
      <NoHighlights />
    </Container>);
}
