export const FORMATTING_COMMANDS = [
    'undo',
    'redo',
    'bold',
    'italic',
    'strikethrough',
    'underline',
    'alignment',
    'fontColor',
    'fontBackgroundColor',
];
