import { Trans, t } from '@lingui/macro';
import { Button } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { AssistantContext, useAssistant } from 'app/domains/assistant';
import { StyledEmptyState } from './styled';
export function ContextNotSupported() {
    const { changeContext } = useAssistant();
    const handleClick = () => {
        changeContext(AssistantContext.Document);
    };
    return (<StyledEmptyState withAvatar icon={<Falcon icon="file-slash"/>} title={t({
            comment: 'Document assistant - context view - when the selected context is not available for a document type - title',
            message: 'Selection not available',
        })} description={t({
            comment: 'Document assistant - context view - when the selected context is not available for a document type - description',
            message: 'This document type does not support AI assistant on selection.',
        })} actions={<Button appearance="secondary" type="button" onClick={handleClick}>
          <Trans comment="Document assistant - context view - when the selected context is not available for a document type - action button label">
            Use full context
          </Trans>
        </Button>}/>);
}
