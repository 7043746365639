import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled, { css } from 'styled-components';
import { TREE_BRANCH_COLOR, TREE_NODE_ICON_SIZE, TREE_NODE_MARGIN, TREE_NODE_MARGIN_HALF, } from '../constants';
const verticalLineStyles = css `
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: calc(${TREE_NODE_ICON_SIZE} + ${TREE_NODE_MARGIN} * 3);
    bottom: calc((${TREE_NODE_ICON_SIZE} + ${TREE_NODE_MARGIN}) / 2 + ${TREE_NODE_MARGIN});
    left: calc((${TREE_NODE_ICON_SIZE} + ${TREE_NODE_MARGIN}) / 2);
    display: block;
    width: ${pxToRem(1)};
    background-color: ${TREE_BRANCH_COLOR};

    ul & {
      top: ${TREE_NODE_MARGIN_HALF};
    }
  }
`;
export const Container = styled.ul `
  ${verticalLineStyles};

  ul {
    padding-left: ${TREE_NODE_ICON_SIZE};
  }

  ${props => props.$isLastItem &&
    css `
      &::after {
        content: '';
        position: absolute;
        height: 100%;
        width: ${pxToRem(1)};
        top: calc(-${TREE_NODE_ICON_SIZE} + ${TREE_NODE_MARGIN_HALF} * 5 + ${pxToRem(1)});
        left: calc(-${TREE_NODE_ICON_SIZE} / 2 + ${TREE_NODE_MARGIN_HALF});
        background-color: ${themeGet('colors.background.50')};
      }
    `}
`;
