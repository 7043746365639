import { useEffect } from 'react';
import { useAuth } from 'app/domains/auth/hooks/useAuth';
import { AuthenticatedVar } from 'shared/domains/apollo/generated/types';
import { authenticatedVar, useAuthenticatedState } from 'shared/domains/apollo/utils/cacheVars';
const ONE_MINUTE = 60000;
export function useIsAccessTokenExpired() {
    const auth = useAuth();
    const authState = useAuthenticatedState();
    useEffect(() => {
        const interval = setInterval(() => {
            auth.getAccessTokenSilently().catch(() => {
                authenticatedVar(AuthenticatedVar.NotAuthenticated);
            });
        }, ONE_MINUTE);
        return () => clearInterval(interval);
    }, [auth]);
    const isAccessTokenExpired = authState === AuthenticatedVar.NotAuthenticated;
    return { isAccessTokenExpired };
}
