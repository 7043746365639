import { Button, pxToRem, themeGet, themeGetColor, unit } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const ListContainer = styled.div `
  display: flex;
  flex-direction: column;
`;
export const ListTitle = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${pxToRem(66)};
  padding: ${unit('xl')};
  background-color: ${themeGetColor('background.100', 0.5)};
  border-bottom: solid ${pxToRem(1)} ${themeGet('colors.gray.100')};
`;
export const StyledButton = styled(Button) `
  border-bottom: solid ${pxToRem(1)} ${themeGet('colors.gray.100')};
`;
