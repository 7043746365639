import { useState } from 'react';
import { AclAction } from 'shared/domains/apollo/generated/types';
const actions = [AclAction.Read, AclAction.Write, AclAction.Delete];
export function useAccessState(params) {
    const { onChange, initialUsersWithAccess, initialTeamsWithAccess, initialUsersWithoutAccess, initialTeamsWithoutAccess, } = params;
    const [usersWithAccess, setUsersWithAccess] = useState(initialUsersWithAccess);
    const [teamsWithAccess, setTeamsWithAccess] = useState(initialTeamsWithAccess);
    const [usersWithoutAccess, setUsersWithoutAccess] = useState(initialUsersWithoutAccess);
    const [teamsWithoutAccess, setTeamsWithoutAccess] = useState(initialTeamsWithoutAccess);
    function filterAndFormatUserAcls(users) {
        return users
            .map(user => ({
            userId: user.id,
            actions,
        }))
            .filter(user => !initialUsersWithAccess.some(initialUser => initialUser.id === user.userId));
    }
    function filterAndFormatTeamAcls(teams) {
        return teams
            .map(team => ({
            teamId: team.id,
            actions,
        }))
            .filter(team => !initialTeamsWithAccess.some(initialTeam => initialTeam.id === team.teamId));
    }
    const getUserAcls = (ids) => {
        const usersToAdd = usersWithoutAccess.filter(user => ids.includes(user.id));
        const newUsersWithAccess = [...usersWithAccess, ...usersToAdd];
        const newUsersWithoutAccess = usersWithoutAccess.filter(user => !ids.includes(user.id));
        setUsersWithAccess(newUsersWithAccess);
        setUsersWithoutAccess(newUsersWithoutAccess);
        return filterAndFormatUserAcls(newUsersWithAccess);
    };
    const getTeamAcls = (ids) => {
        const teamsToAdd = teamsWithoutAccess.filter(team => ids.includes(team.id));
        const newTeamsWithAccess = [...teamsWithAccess, ...teamsToAdd];
        const newTeamsWithoutAccess = teamsWithoutAccess.filter(team => !ids.includes(team.id));
        setTeamsWithAccess(newTeamsWithAccess);
        setTeamsWithoutAccess(newTeamsWithoutAccess);
        return filterAndFormatTeamAcls(newTeamsWithAccess);
    };
    const handleAddAcl = (addAcls) => {
        const { userIds, teamIds } = addAcls;
        const teamAcls = getTeamAcls(teamIds);
        const userAcls = getUserAcls(userIds);
        onChange({ userAcls, teamAcls });
    };
    const handleRemoveUserAcl = (id) => {
        const userToRemove = usersWithAccess.find(user => user.id === id);
        // initialUsersWithAccess is the list of users that have access (because they have access to the parent entry or are owners)
        const userHasAccessFromParentEntry = initialUsersWithAccess.some(user => user.id === id);
        if (!userToRemove || userHasAccessFromParentEntry) {
            return;
        }
        const newUsersWithoutAccess = [...usersWithoutAccess, userToRemove];
        const newUsersWithAccess = usersWithAccess.filter(user => user.id !== id);
        setUsersWithoutAccess(newUsersWithoutAccess);
        setUsersWithAccess(newUsersWithAccess);
        onChange({
            userAcls: filterAndFormatUserAcls(newUsersWithAccess),
            teamAcls: filterAndFormatTeamAcls(teamsWithAccess),
        });
    };
    const handleRemoveTeamAcl = (id) => {
        const teamToRemove = teamsWithAccess.find(team => team.id === id);
        // initialTeamsWithAccess is the list of teams that have access (because they have access to the parent entry)
        const teamHasAccessFromParentEntry = initialTeamsWithAccess.some(team => team.id === id);
        if (!teamToRemove || teamHasAccessFromParentEntry) {
            return;
        }
        const newTeamsWithoutAccess = [...teamsWithoutAccess, teamToRemove];
        const newTeamsWithAccess = teamsWithAccess.filter(team => team.id !== id);
        setTeamsWithoutAccess(newTeamsWithoutAccess);
        setTeamsWithAccess(newTeamsWithAccess);
        onChange({
            userAcls: filterAndFormatUserAcls(usersWithAccess),
            teamAcls: filterAndFormatTeamAcls(newTeamsWithAccess),
        });
    };
    return {
        usersWithAccess,
        usersWithoutAccess,
        teamsWithAccess,
        teamsWithoutAccess,
        onAddAcls: handleAddAcl,
        onRemoveUserAcl: handleRemoveUserAcl,
        onRemoveTeamAcl: handleRemoveTeamAcl,
    };
}
