import { gql } from '@apollo/client';
export const getDocumentFeatures = gql `
  query GetDocumentFeatures($companyId: UUID!) {
    fileSystemCompanyFeatures(where: { companyId: { eq: $companyId } }) {
      id
      companyId
      feature
    }
  }
`;
