import { Button, pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  padding: ${themeGet('spacing.lg')} ${themeGet('spacing.xl')};
  gap: ${themeGet('spacing.2xl')};
  border-radius: ${themeGet('borderRadius.md')};
  background-color: ${({ $selected }) => themeGet($selected ? 'colors.assistant.50' : 'colors.gray.50')};
`;
export const Copy = styled.div `
  flex-direction: column;
  display: flex;
  width: 65%;
`;
export const SwitchContainer = styled.div `
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: ${themeGet('spacing.md')};
`;
export const Row = styled.div `
  display: flex;
  align-items: center;
  gap: ${themeGet('spacing.2xl')};
`;
export const Notice = styled.div `
  display: flex;
  align-items: center;
  gap: ${themeGet('spacing.md')};
  background-color: ${themeGet('colors.background.50')};
  padding: ${themeGet('spacing.xl')};
  border-radius: ${themeGet('borderRadius.md')};
  border: ${pxToRem(1)} solid ${themeGet('colors.border.100')};

  ${Button} {
    margin-left: auto;
    flex-shrink: 0;
  }
`;
