import { useContext } from 'react';
import { Context } from './context';
/**
 * Hook to interact with a documents metadata fields.
 * This hook has to be used within the `DocumentMetadataProvider`.
 */
export function useDocumentMetadata() {
    const context = useContext(Context);
    if (!context) {
        throw new Error('useDocumentMetadata is used outside <DocumentMetadataProvider />');
    }
    return context;
}
