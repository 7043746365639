import { gql } from '@apollo/client';
export const getInviteComposers = gql `
  query GetInviteComposers($where: Unstable__WhereComposerInput) {
    composers: unstable__composers(where: $where) {
      id
      metadata {
        name
        marketLocaleCode
      }
    }
  }
`;
