import { t } from '@lingui/macro';
import { SwitchButtonView } from 'pleditor';
import { CommentCheck } from './icons';
import { PleditorPlugin } from '../PleditorPlugin';
export class ToggleComments extends PleditorPlugin {
    static get pluginName() {
        return 'ToggleComments';
    }
    init() {
        const { editor } = this;
        editor.ui.componentFactory.add('toggleComments', () => {
            const button = new SwitchButtonView();
            button.set({
                label: t({
                    comment: 'Pleditor - Toggle comments visibility tooltip',
                    message: 'Comments Visible',
                }),
                tooltip: true,
                icon: CommentCheck,
                isOn: true,
                class: 'pl-icon-toggle-button',
            });
            button.on('execute', () => {
                if (button.isOn) {
                    this.hideComments();
                    button.set({ isOn: false });
                }
                else {
                    this.showComments();
                    button.set({ isOn: true });
                }
            });
            const commentsRepository = editor.plugins.get('CommentsRepository');
            commentsRepository.on('addCommentThread', () => {
                this.showComments();
                button.set({ isOn: true });
            });
            return button;
        });
    }
    showComments() {
        var _a;
        const { editor } = this;
        const sidebar = (_a = editor.config.get('sidebar')) === null || _a === void 0 ? void 0 : _a.container;
        sidebar === null || sidebar === void 0 ? void 0 : sidebar.style.setProperty('width', '25%');
        sidebar === null || sidebar === void 0 ? void 0 : sidebar.style.setProperty('opacity', '1');
    }
    hideComments() {
        var _a;
        const { editor } = this;
        const sidebar = (_a = editor.config.get('sidebar')) === null || _a === void 0 ? void 0 : _a.container;
        sidebar === null || sidebar === void 0 ? void 0 : sidebar.style.setProperty('width', '0');
        sidebar === null || sidebar === void 0 ? void 0 : sidebar.style.setProperty('opacity', '0');
    }
}
