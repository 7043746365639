var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { Trans } from '@lingui/macro';
import { Button, Loader, Menu } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Information } from './Information';
import { useAutoTagging } from './useAutoTagging';
import { useDocumentMetadata } from '../../DocumentMetadataProvider';
const MAX_ATTEMPTS = 3;
export function AutoTag() {
    const { documentId, data } = useDocumentMetadata();
    const [open, setOpen] = useState(false);
    const { autoTaggingFeatureAvailable } = data;
    const tags = useAutoTagging(documentId, {
        featureAvailable: autoTaggingFeatureAvailable,
        onCompleted: () => data.refetch(),
    });
    const disabled = tags.state === 'loading';
    const handleTrigger = () => __awaiter(this, void 0, void 0, function* () {
        if (tags.attemptCount >= MAX_ATTEMPTS) {
            setOpen(!open);
            return;
        }
        if (tags.state === 'disabled' || tags.state === 'error') {
            setOpen(!open);
            return;
        }
        yield tags.extract();
    });
    return (<Menu open={open} onClose={() => setOpen(false)} target={<Button type="button" $stretch size="small" disabled={disabled} appearance="secondary" onClick={handleTrigger}>
          <Falcon icon="sparkles"/>
          <Trans comment="Metadata - document: Auto tag">Auto tag</Trans>
          {tags.state === 'loading' && <Loader />}
        </Button>}>
      {open && <Information state={tags.state}/>}
    </Menu>);
}
