import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled, { css, keyframes } from 'styled-components';
export const Container = styled.div `
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
const backgroundAnimation = keyframes `
  0% {
    opacity: 0.75;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 0.75;
  }
`;
export const Header = styled.div `
  flex-shrink: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding: ${themeGet('spacing.2xl')};
  height: ${pxToRem(172)};

  background-color: ${themeGet('colors.gray.100')}; // Fallback while background-image is loading
  background-image: ${props => !props.$loading && `url(${props.$background})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  animation: ${props => props.$loading &&
    css `
      ${backgroundAnimation} 2s ease-in infinite
    `};
`;
export const Content = styled.div `
  flex: 1 1 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;
export const TitleContainer = styled.div `
  display: flex;
  align-items: center;
  gap: ${themeGet('spacing.lg')};
  padding: ${themeGet('spacing.2xl')} ${themeGet('spacing.7xl')};
`;
