import { useField } from 'formik';
import { OrderedSigningParties } from './OrderedSigningParties';
import { UnorderedSigningParties } from './UnorderedSigningParties';
export function SigningParties() {
    const [{ value: useSigningOrder }] = useField('settings.useSigningOrder');
    if (useSigningOrder) {
        return <OrderedSigningParties />;
    }
    return <UnorderedSigningParties />;
}
