import { useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Button, EmptyState } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { ConnectEsigningProviderDialog } from 'app/domains/esigning/components/ConnectEsigningProviderDialog';
import { useEsigningProviders } from 'app/domains/esigning/hooks/useEsigningProviders';
import { ExpiredProviderAlert } from './ExpiredProviderAlert';
import { SectionContent } from '../components';
import { AlertContainer } from './styled';
export function ESignConnectProvider() {
    const { isConnectedProviderExpired } = useEsigningProviders();
    const [showConnectDialog, setShowConnectDialog] = useState(false);
    const showDialog = () => setShowConnectDialog(true);
    const hideDialog = () => setShowConnectDialog(false);
    return (<SectionContent>
      {showConnectDialog && <ConnectEsigningProviderDialog onClose={hideDialog}/>}
      {isConnectedProviderExpired && (<AlertContainer>
          <ExpiredProviderAlert />
        </AlertContainer>)}
      <EmptyState withAvatar icon={<Falcon icon="paper-plane"/>} title={t({
            comment: 'No e-signing provider has been selected - title',
            message: 'Select e-signing provider',
        })} description={t({
            comment: 'No e-signing provider has been selected - message',
            message: 'To start using e-signing you need to select an e-signing provider',
        })} actions={<Button onClick={showDialog}>
            <Trans comment="No e-signing provider has been selected - call to action label">
              Select provider
            </Trans>
          </Button>}/>
    </SectionContent>);
}
