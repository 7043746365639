import { useMutation } from '@apollo/client';
import { touchDocumentAssistantPrompt } from './query';
export function useTrackPrompt() {
    const [mutate] = useMutation(touchDocumentAssistantPrompt, {
        update(cache, { data }) {
            const newPromptUsage = data === null || data === void 0 ? void 0 : data.touchDocumentAssistantPrompt;
            if (newPromptUsage) {
                cache.modify({
                    fields: {
                        promptUsages(existing = [], { toReference }) {
                            const reference = toReference(newPromptUsage);
                            if (reference) {
                                return [
                                    reference,
                                    // eslint-disable-next-line no-underscore-dangle
                                    ...existing.filter(({ __ref }) => reference.__ref !== __ref),
                                ];
                            }
                            return existing;
                        },
                    },
                });
            }
        },
    });
    return mutate;
}
