import { useContext } from 'react';
import { Context } from './Context';
export { GtmProvider } from './GtmProvider';
export function useGtm() {
    const context = useContext(Context);
    if (!context) {
        throw new Error('useGtm must be used within a GtmProvider');
    }
    return context;
}
