import 'content-visibility';
import { Item } from './Item';
import { useStrictPDFData } from '../usePDFData';
import { ListContainer, contentVisibilityStyle } from './styled';
export function VirtualizedList() {
    const { spacing, pageMap, listRef, setScrollDistance } = useStrictPDFData();
    const handleScroll = (event) => {
        setScrollDistance(event.currentTarget.scrollTop);
    };
    return (<ListContainer $spacing={spacing} ref={listRef} onScroll={handleScroll}>
      <content-visibility style={contentVisibilityStyle}>
        {Object.entries(pageMap).map(([pageNumber, page]) => page && <Item key={pageNumber} page={page} pageNumber={Number(pageNumber)}/>)}
      </content-visibility>
    </ListContainer>);
}
