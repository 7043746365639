import { FileBrowserBreadcrumbs } from './FileBrowserBreadcrumbs';
import { FileBrowserPagination } from './FileBrowserPagination';
import { FileBrowserProvider } from './FileBrowserProvider';
import { FileBrowserTable } from './FileBrowserTable';
/**
 * Filesystem browser component. It provides a file browser with pagination and breadcrumbs.
 * For additional customization, you can use the `FileBrowserProvider` component and the `useFileBrowser` hook.
 */
export function FileBrowser(props) {
    return (<FileBrowserProvider {...props}>
      <FileBrowserBreadcrumbs />
      <FileBrowserTable />
      <FileBrowserPagination />
    </FileBrowserProvider>);
}
