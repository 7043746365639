var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback } from 'react';
import { Dialog, DialogHeader, DialogContent, DialogFooter, Typography, AvatarWithLabel, useToast, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Formik, Form } from 'formik';
import { useSignatory } from 'app/domains/esigning/hooks/useSignatory';
import { Actions } from './Actions';
import { AlertByStatus } from './AlertByStatus';
import { getFormData } from './utils';
import { getValidationSchema } from './validationSchema';
import { useUpdateSignRequestSignatory } from '../useUpdateSignRequestSignatory';
import { ERROR_CODES } from '../useUpdateSignRequestSignatory/constants';
import { Content } from './styled';
export function EditSignatoryDialog(props) {
    const { signatoryId, formKey, onClose } = props;
    const { name = '', [formKey]: formValue } = useSignatory(signatoryId) || {};
    const initialValues = { [formKey]: formValue };
    const [updateSignRequestSignatory] = useUpdateSignRequestSignatory(signatoryId);
    const { title, message, successToast, notEditedError, Field } = getFormData(formKey, name);
    const { addToast } = useToast();
    const handleSubmit = useCallback((values, formikHelpers) => __awaiter(this, void 0, void 0, function* () {
        const { [formKey]: newValue } = values;
        const { setStatus } = formikHelpers;
        setStatus(undefined);
        return updateSignRequestSignatory({ [formKey]: newValue })
            .then(() => {
            addToast({ appearance: 'success', title: successToast });
            onClose();
        })
            .catch(error => {
            const expectedError = ERROR_CODES.find(code => code === (error === null || error === void 0 ? void 0 : error.message));
            if (expectedError) {
                setStatus(expectedError);
            }
            else {
                setStatus('UNEXPECTED_ERROR');
            }
        });
    }), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formKey, onClose]);
    const validate = useCallback((values) => {
        const { [formKey]: validateValue } = values;
        const errors = {};
        if (formValue === validateValue) {
            errors[formKey] = notEditedError;
        }
        return errors;
    }, [formKey, formValue, notEditedError]);
    return (<Formik validationSchema={getValidationSchema(formKey)} initialValues={initialValues} onSubmit={handleSubmit} validate={validate}>
      <Dialog as={Form} size="sm" onClose={onClose}>
        <DialogHeader media={<AvatarWithLabel size="xl" shape="square" title={title}>
              <Falcon icon="pen"/>
            </AvatarWithLabel>}/>
        <DialogContent>
          <Content>
            <Typography>{message}</Typography>
            <Field />
            <AlertByStatus />
          </Content>
        </DialogContent>
        <DialogFooter>
          <Actions onClose={onClose}/>
        </DialogFooter>
      </Dialog>
    </Formik>);
}
