import { t } from '@lingui/macro';
import { Chip } from '@pocketlaw/tetris';
import { SignStatusEnumEnum } from 'shared/domains/apollo/generated/types';
export function ESigningStatusChip(props) {
    const { status } = props;
    switch (status) {
        case SignStatusEnumEnum.Signed:
            return (<Chip variant="outline" appearance="success" label={t({
                    comment: 'Esigning status - Signed',
                    message: 'Completed',
                })}/>);
        case SignStatusEnumEnum.Rejected:
            return (<Chip variant="outline" appearance="danger" label={t({
                    comment: 'Esigning status - Rejected',
                    message: 'Rejected',
                })}/>);
        case SignStatusEnumEnum.Pending:
            return (<Chip variant="outline" appearance="info" label={t({
                    comment: 'Esigning status - Pending',
                    message: 'Pending',
                })}/>);
        default:
            return null;
    }
}
