import { useQuery } from '@apollo/client';
import { getCurrentMarket } from 'app/domains/markets/config';
import { getPlanFromType } from './query';
export function useGetPlanIdFromType(planType) {
    var _a;
    const marketCode = getCurrentMarket().code;
    const { data, error, loading } = useQuery(getPlanFromType, {
        skip: !planType,
        variables: {
            planType: planType,
            marketCode,
        },
    });
    const planId = (_a = data === null || data === void 0 ? void 0 : data.plans[0]) === null || _a === void 0 ? void 0 : _a.id;
    return { planId, error, loading };
}
