var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { useEsigning } from 'app/domains/esigning/components/EsigningProvider';
import { lastApprovalRequestQuery } from 'app/domains/esigning/hooks/useLastApprovalRequest';
import { APPROVAL_IGNORE_CODES } from 'app/domains/esigning/utils';
import * as Sentry from 'shared/domains/sentry';
import { MUTATION } from './mutation';
export const useApproveApprovalRequest = () => {
    const { approvalWorkflow } = useEsigning();
    const { currentUser } = approvalWorkflow;
    const { addToast } = useToast();
    const [mutate] = useMutation(MUTATION);
    const approveApprovalRequest = (message) => __awaiter(void 0, void 0, void 0, function* () {
        const approvalRequestApproverId = currentUser.approverId;
        if (!approvalRequestApproverId) {
            Sentry.captureMessage('Approve approval request was called by a non approver.');
            throw new Error();
        }
        yield mutate({
            variables: { input: { approvalRequestApproverId, statusMessage: message } },
            refetchQueries: [lastApprovalRequestQuery],
            context: {
                sentry: {
                    ignore: APPROVAL_IGNORE_CODES,
                },
            },
        });
        addToast({
            appearance: 'success',
            title: t({
                comment: 'Approve approval request success message',
                message: 'You have approved the documents approval request',
            }),
        });
    });
    return { approveApprovalRequest };
};
