import { Container } from './Container';
import { useAssistant } from '../AssistantProvider';
/**
 * This component represents the comment block in a thread, including the prefix, the message, the prompt and other actions such as like/dislike
 */
export function AssistantComment(props) {
    const { fuzzySearchTextNodes } = useAssistant();
    return <Container {...props} fuzzySearchTextNodes={fuzzySearchTextNodes}/>;
}
export { CommentBubble as AssistantCommentBubble } from './CommentBubble';
export { CommentBlock as AssistantCommentBlock } from './CommentBlock';
