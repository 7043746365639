import { useQuery } from '@apollo/client';
import { isPaidPlan } from 'app/domains/companies';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { QUERY } from './query';
export const useIsPaidPlan = () => {
    const account = useAccount();
    const { companyId } = account.data || {};
    const { data } = useQuery(QUERY, {
        variables: {
            companyId,
        },
    });
    const { company } = data || {};
    const { plans = [] } = company || {};
    const companyPlan = plans[0];
    if (companyPlan) {
        return isPaidPlan(companyPlan.plan);
    }
    return false;
};
