import { useRef, useCallback, Fragment, useState } from 'react';
import { Trans } from '@lingui/macro';
import { Button, Menu, MenuBody, MenuItem, MenuList } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { SelectTemplateDialog } from 'app/domains/templates';
import { FileUpload } from 'app/domains/upload';
export function ContextMenuButton(props) {
    const { disabled = false, onUploadFile, onCreateFolder, onUploadFolder } = props;
    const [open, setOpen] = useState(false);
    const [renderTemplateDialog, setRenderTemplateDialog] = useState(false);
    const fileUploadRef = useRef(null);
    const closeMenu = () => setOpen(false);
    const toggleMenu = () => setOpen(!open);
    const showTemplateDialog = () => setRenderTemplateDialog(true);
    const hideTemplateDialog = () => setRenderTemplateDialog(false);
    const handleFileUpload = () => {
        var _a;
        closeMenu();
        (_a = fileUploadRef.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    const handleFolderUpload = () => {
        closeMenu();
        if (onUploadFolder) {
            onUploadFolder();
        }
    };
    const handleCreateFolder = () => {
        closeMenu();
        if (onCreateFolder) {
            onCreateFolder();
        }
    };
    const handleCreateContract = () => {
        closeMenu();
        showTemplateDialog();
    };
    const handleFileChange = useCallback((event) => {
        const { files } = event.target;
        if (onUploadFile && files) {
            onUploadFile(Array.from(files));
        }
    }, [onUploadFile]);
    return (<Fragment>
      <Menu open={open} onClose={closeMenu} target={<Button disabled={disabled} appearance="primary" onClick={toggleMenu}>
            <Falcon icon="plus"/>
            <Trans>Add</Trans>
          </Button>}>
        <MenuBody>
          <MenuList>
            {onUploadFile && (<MenuItem onClick={handleFileUpload}>
                <Falcon icon="file-upload"/>
                <Trans comment="Filesystem Menu action item - upload a document to Pocketlaw">
                  Upload document
                </Trans>
              </MenuItem>)}
            {onUploadFolder && (<MenuItem onClick={handleFolderUpload}>
                <Falcon icon="folder-upload"/>
                <Trans comment="Filesystem Menu action item - upload documents to Pocketlaw">
                  Upload documents
                </Trans>
              </MenuItem>)}
            <MenuItem onClick={handleCreateContract}>
              <Falcon icon="file-alt"/>
              <Trans comment="Filesystem Menu action item - create a new contract using Pocketlaw">
                New contract
              </Trans>
            </MenuItem>
            {onCreateFolder && (<MenuItem onClick={handleCreateFolder}>
                <Falcon icon="folder"/>
                <Trans comment="Filesystem Menu action item - create a new folder">
                  New folder
                </Trans>
              </MenuItem>)}
          </MenuList>
        </MenuBody>
      </Menu>
      <FileUpload onChange={handleFileChange} ref={fileUploadRef} multiple/>
      {renderTemplateDialog && <SelectTemplateDialog onClose={hideTemplateDialog}/>}
    </Fragment>);
}
