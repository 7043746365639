var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@apollo/client';
import { query } from './query';
export const useCompanyUsersWithRoles = () => {
    var _a;
    const _b = useQuery(query), { data } = _b, rest = __rest(_b, ["data"]);
    const users = ((_a = data === null || data === void 0 ? void 0 : data.companyUsers) === null || _a === void 0 ? void 0 : _a.map(companyUser => {
        var _a, _b;
        return ({
            name: (_a = companyUser.user) === null || _a === void 0 ? void 0 : _a.name,
            userId: (_b = companyUser.user) === null || _b === void 0 ? void 0 : _b.id,
            companyUserId: companyUser.id,
            role: companyUser.role,
            canManageMember: companyUser.canManageMember,
        });
    })) || [];
    return Object.assign({ data: users }, rest);
};
