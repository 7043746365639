import { useMutation } from '@apollo/client';
import { updatePreferredLocaleMutation } from './mutation';
export function useUpdatePreferredLocale() {
    const [mutate, state] = useMutation(updatePreferredLocaleMutation);
    const handleMutation = (options) => {
        const { locale, userId } = options;
        return mutate({
            variables: { locale, userId },
            context: {
                withCompany: false,
            },
        });
    };
    return {
        mutate: handleMutation,
        state,
    };
}
