import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled, { css } from 'styled-components';
export const highlightStyles = css `
  color: ${themeGet('colors.text.300')};

  &:not(:empty) {
    padding: ${pxToRem(1)};
    background-color: ${themeGet('colors.yellow.200')};
    border-radius: ${pxToRem(1)};
  }
`;
export const Container = styled.span `
  ${highlightStyles};
`;
