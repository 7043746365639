import image1 from './images/template_cover_0001.jpg';
import image2 from './images/template_cover_0002.jpg';
import image3 from './images/template_cover_0003.jpg';
import image4 from './images/template_cover_0004.jpg';
import image5 from './images/template_cover_0005.jpg';
import image6 from './images/template_cover_0006.jpg';
import image7 from './images/template_cover_0007.jpg';
import image8 from './images/template_cover_0008.jpg';
import image9 from './images/template_cover_0009.jpg';
import image10 from './images/template_cover_0010.jpg';
import image11 from './images/template_cover_0011.jpg';
import image12 from './images/template_cover_0012.jpg';
import image13 from './images/template_cover_0013.jpg';
import image14 from './images/template_cover_0014.jpg';
import image15 from './images/template_cover_0015.jpg';
import image16 from './images/template_cover_0016.jpg';
import image17 from './images/template_cover_0017.jpg';
import image18 from './images/template_cover_0018.jpg';
import image19 from './images/template_cover_0019.jpg';
import image20 from './images/template_cover_0020.jpg';
const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
    image14,
    image15,
    image16,
    image17,
    image18,
    image19,
    image20,
];
/**
 * Get image src for composer
 * Same composerId should ALWAYS return the same image
 * Different composerIds CAN return the same image
 * Images should be somewhat randomly distributed
 */
export function getTemplateImage(composerId) {
    const uuidPrefix = (composerId === null || composerId === void 0 ? void 0 : composerId.substring(0, 4)) || '0000'; // substring to avoid too large number for js to handle
    const number = parseInt(uuidPrefix, 16);
    const index = Math.abs(number) % images.length;
    return images[index];
}
