import { t } from '@lingui/macro';
import { SignatoryStatus } from 'shared/domains/apollo/generated/types';
export const getAppearanceByStatus = (status) => {
    switch (status) {
        case SignatoryStatus.Opened:
            return 'info';
        case SignatoryStatus.Rejected:
            return 'danger';
        case SignatoryStatus.Signed:
            return 'success';
        case SignatoryStatus.Pending:
        default:
            return 'secondary';
    }
};
export const getLabelByStatus = (status) => {
    switch (status) {
        case SignatoryStatus.Pending:
        case SignatoryStatus.Opened:
            return t({
                message: 'Pending',
                comment: 'Label for pending signing party badge',
            });
        case SignatoryStatus.Rejected:
            return t({
                message: 'Rejected',
                comment: 'Label for rejected signing party badge',
            });
        case SignatoryStatus.Signed:
            return t({
                message: 'Signed',
                comment: 'Label for signed signing party badge',
            });
        default:
            return null;
    }
};
