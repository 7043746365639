import { Fragment } from 'react';
import { Trans } from '@lingui/macro';
import { Box, Typography } from '@pocketlaw/tetris';
import { RangeOffset } from './RangeOffset';
export function Range(props) {
    const { name } = props;
    return (<Fragment>
      <Box flexDirection="column">
        <Typography>
          <Trans comment="Insights widget form: From now switch description">
            Relative from offset
          </Trans>
        </Typography>
        <RangeOffset name={`${name}.fromOffset`}/>
      </Box>

      <Box flexDirection="column">
        <Typography>
          <Trans comment="Insights widget form: To now switch description">
            Relative to offset
          </Trans>
        </Typography>
        <RangeOffset name={`${name}.toOffset`}/>
      </Box>
    </Fragment>);
}
