import { t } from '@lingui/macro';
import { SearchAggregationsAllowedPropertiesForTermsAggregation as AllowedProperties } from 'shared/domains/apollo/generated/types';
export function allowedPropertiesTranslations(key) {
    const translations = {
        [AllowedProperties.CategoryId]: t({
            message: 'Template categories',
            comment: 'Insights: Terms allowed field: CategoryId',
        }),
        [AllowedProperties.CreatedByUserId]: t({
            message: 'Contract creator',
            comment: 'Insights: Terms allowed field: CreatedByUserId',
        }),
        [AllowedProperties.EntityType]: t({
            message: 'Type of document',
            comment: 'Insights: Terms allowed field: EntityType',
        }),
        [AllowedProperties.IsPlGenerated]: t({
            message: 'isPlGenerated',
            comment: 'Insights: Terms allowed field: IsPlGenerated',
        }),
        [AllowedProperties.MarketCode]: t({
            message: 'Market',
            comment: 'Insights: Terms allowed field: MarketCode',
        }),
        [AllowedProperties.MarketLocaleCode]: t({
            message: 'Market locale',
            comment: 'Insights: Terms allowed field: MarketLocaleCode',
        }),
        [AllowedProperties.MetadataDocumentActive]: t({
            message: 'Active',
            comment: 'Insights: Terms allowed field: MetadataDocumentActive',
        }),
        [AllowedProperties.MetadataDocumentAutomaticRenewal]: t({
            message: 'Automatic renewal',
            comment: 'Insights: Terms allowed field: MetadataDocumentAutomaticRenewal',
        }),
        [AllowedProperties.MetadataDocumentContractOwner]: t({
            message: 'Contract owner',
            comment: 'Insights: Terms allowed field: MetadataDocumentContractOwner',
        }),
        [AllowedProperties.MetadataDocumentCurrency]: t({
            message: 'Currency',
            comment: 'Insights: Terms allowed field: MetadataDocumentCurrency',
        }),
        [AllowedProperties.MetadataDocumentDeviateFromStandard]: t({
            message: 'Deviate from standard',
            comment: 'Insights: Terms allowed field: MetadataDocumentDeviateFromStandard',
        }),
        [AllowedProperties.MetadataDocumentDiscount]: t({
            message: 'Discount',
            comment: 'Insights: Terms allowed field: MetadataDocumentDiscount',
        }),
        [AllowedProperties.MetadataDocumentDocumentCategory]: t({
            message: 'Document categories',
            comment: 'Insights: Terms allowed field: MetadataDocumentDocumentCategory',
        }),
        [AllowedProperties.MetadataDocumentDocumentType]: t({
            message: 'Document type',
            comment: 'Insights: Terms allowed field: MetadataDocumentDocumentType',
        }),
        [AllowedProperties.MetadataDocumentTerminationForCause]: t({
            message: 'Early termination',
            comment: 'Insights: Terms allowed field: MetadataDocumentTerminationForCause',
        }),
        [AllowedProperties.MetadataDocumentEsigningStatus]: t({
            message: 'eSigning status',
            comment: 'Insights: Terms allowed field: MetadataDocumentEsigningStatus',
        }),
        [AllowedProperties.MetadataDocumentFeeAdjustmentClause]: t({
            message: 'Fee adjustment clause',
            comment: 'Insights: Terms allowed field: MetadataDocumentFeeAdjustmentClause',
        }),
        [AllowedProperties.MetadataDocumentGoverningLaw]: t({
            message: 'Governing law',
            comment: 'Insights: Terms allowed field: MetadataDocumentGoverningLaw',
        }),
        [AllowedProperties.MetadataDocumentIndemnity]: t({
            message: 'Indemnity',
            comment: 'Insights: Terms allowed field: MetadataDocumentIndemnity',
        }),
        [AllowedProperties.MetadataDocumentIsSharedExternally]: t({
            message: 'Shared externally',
            comment: 'Insights: Terms allowed field: MetadataDocumentIsSharedExternally',
        }),
        [AllowedProperties.MetadataDocumentLimitationOfLiability]: t({
            message: 'Limitation of liability',
            comment: 'Insights: Terms allowed field: MetadataDocumentLimitationOfLiability',
        }),
        [AllowedProperties.MetadataDocumentMaterialAgreement]: t({
            message: 'Material agreement',
            comment: 'Insights: Terms allowed field: MetadataDocumentMaterialAgreement',
        }),
        [AllowedProperties.MetadataDocumentOutsourcing]: t({
            message: 'Outsourcing',
            comment: 'Insights: Terms allowed field: MetadataDocumentOutsourcing',
        }),
        [AllowedProperties.MetadataDocumentPostTerminationObligations]: t({
            message: 'Post termination obligations',
            comment: 'Insights: Terms allowed field: MetadataDocumentPostTerminationObligations',
        }),
        [AllowedProperties.MetadataDocumentProbationaryPeriod]: t({
            message: 'Probationary period',
            comment: 'Insights: Terms allowed field: MetadataDocumentProbationaryPeriod',
        }),
        [AllowedProperties.MetadataDocumentProcessingOfPersonalData]: t({
            message: 'Processing of personal data',
            comment: 'Insights: Terms allowed field: MetadataDocumentProcessingOfPersonalData',
        }),
        [AllowedProperties.MetadataDocumentStatus]: t({
            message: 'Document status',
            comment: 'Insights: Terms allowed field: MetadataDocumentStatus',
        }),
        [AllowedProperties.MetadataDocumentTerminationForConvenience]: t({
            message: 'Termination for convenience',
            comment: 'Insights: Terms allowed field: MetadataDocumentTerminationForConvenience',
        }),
        [AllowedProperties.MetadataDocumentType]: t({
            message: 'Document type',
            comment: 'Insights: Terms allowed field: MetadataDocumentType',
        }),
        [AllowedProperties.MetadataEntryPath]: t({
            message: 'Entry path',
            comment: 'Insights: Terms allowed field: MetadataEntryPath',
        }),
        [AllowedProperties.MetadataTemplateComposerId]: t({
            message: 'Template composer',
            comment: 'Insights: Terms allowed field: MetadataTemplateComposerId',
        }),
        [AllowedProperties.MetadataTemplateMarketLocaleCode]: t({
            message: 'Template market code',
            comment: 'Insights: Terms allowed field: MetadataTemplateMarketLocaleCode',
        }),
        [AllowedProperties.MetadataUserEmail]: t({
            message: 'User email',
            comment: 'Insights: Terms allowed field: MetadataUserEmail',
        }),
        [AllowedProperties.MetadataUserRole]: t({
            message: 'User role',
            comment: 'Insights: Terms allowed field: MetadataUserRole',
        }),
        [AllowedProperties.MetadataDocumentLiabilityCapScope]: t({
            message: 'Liability cap scope',
            comment: 'Insights: Terms allowed field: MetadataUserUserId',
        }),
        [AllowedProperties.MetadataDocumentParties]: t({
            message: 'Parties',
            comment: 'Insights: Terms allowed field: MetadataDocumentParties',
        }),
    };
    return translations[key] || key;
}
