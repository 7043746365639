import { useField } from 'formik';
import { AutoTagSwitchField } from 'app/domains/metadata';
export function AutoTag() {
    const [field, , helpers] = useField({
        name: 'autoTag',
        type: 'checkbox',
    });
    const handleOnChange = (value) => {
        helpers.setValue(value);
    };
    return <AutoTagSwitchField enabled={field.value} onChange={handleOnChange}/>;
}
