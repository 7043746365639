var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useState } from 'react';
import { useCompanyFeatures } from 'app/domains/companies';
import { ReviewStatus } from 'shared/domains/apollo/generated/types';
import { usePleditor } from 'shared/domains/documents';
import { getReviewPluginMarkerRep, highlightInDoc, redlineInDoc, } from 'shared/domains/documents/components/Preview/Pleditor/plugins/Review';
import { elementIndexChunky } from 'shared/domains/documents/components/Preview/Pleditor/plugins/Review/doc';
import { useGetDocumentHighlightEntries, } from './useGetDocumentHighlightEntries';
import { useCurrentReview } from '../useCurrentReview';
export const useDocumentHighlights = () => {
    const { getDocumentHighlightEntries } = useGetDocumentHighlightEntries();
    const { isFeatureAvailable } = useCompanyFeatures();
    const { pleditor } = usePleditor();
    const { review } = useCurrentReview();
    const isContractReviewAvailable = isFeatureAvailable('review-agent:basic');
    const isReviewDone = (review === null || review === void 0 ? void 0 : review.status) === ReviewStatus.Done;
    const [documentChunks, setDocumentChunks] = useState([]);
    const [feedbackHighlightsMap, setFeedbackHighlightsMap] = useState({});
    const setIsDocumentChanged = () => {
        const updateFeedbackData = (map, [feedbackId, feedbackData]) => {
            if (!feedbackData) {
                return map;
            }
            return Object.assign(Object.assign({}, map), { [feedbackId]: Object.assign(Object.assign({}, feedbackData), { isDocumentChanged: true }) });
        };
        setFeedbackHighlightsMap(prevMap => Object.entries(prevMap).reduce(updateFeedbackData, prevMap));
    };
    const getDocumentChunks = useCallback((isDocumentChanged = false) => {
        const root = pleditor === null || pleditor === void 0 ? void 0 : pleditor.model.document.getRoot();
        if (!root) {
            return [];
        }
        const chunks = elementIndexChunky(root);
        setDocumentChunks(chunks);
        if (isDocumentChanged) {
            setIsDocumentChanged();
        }
        return chunks;
    }, [pleditor]);
    /**
     *
     * Returns the document highlights for a feedback item.
     *
     * The document highlights returned are filtered to only include highlights that are still in the document.
     */
    const getFeedbackHighlightsData = (feedbackId) => {
        const data = feedbackHighlightsMap[feedbackId];
        if (!data) {
            return undefined;
        }
        const filteredDocumentHighlights = data.documentHighlights.filter(highlight => documentChunks.some(chunk => chunk.node === highlight.node));
        return Object.assign(Object.assign({}, data), { documentHighlights: filteredDocumentHighlights });
    };
    const primeDocumentHighlightEntries = useCallback(() => {
        const chunks = getDocumentChunks();
        if (!chunks.length) {
            return;
        }
        const texts = chunks.map(chunk => chunk.text);
        getDocumentHighlightEntries('', texts);
    }, [getDocumentChunks, getDocumentHighlightEntries]);
    const transformChunkyEntryToHighlight = (editor, entryId, chunk) => {
        const { node, text } = chunk;
        const markerRep = getReviewPluginMarkerRep('highlight');
        const isActive = () => editor.model.markers.get(markerRep.name) !== null;
        const removeMarker = () => editor.model.change(writer => writer.removeMarker(markerRep.name));
        const highlightOff = () => {
            if (isActive()) {
                removeMarker();
            }
        };
        const highlightOn = (scroll = true) => {
            if (isActive())
                return () => { };
            highlightInDoc(node, markerRep);
            if (scroll) {
                if (node.is('element')) {
                    const viewNode = editor.editing.mapper.toViewElement(node);
                    const dom = editor.editing.view.domConverter.viewToDom(viewNode);
                    dom.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
                }
            }
            return removeMarker;
        };
        const addSuggestionToDoc = (suggestion, type) => {
            if (isActive()) {
                removeMarker();
            }
            redlineInDoc(node, suggestion, type);
        };
        return [
            {
                id: entryId,
                node,
                text,
                isActive,
                highlightOff,
                highlightOn,
                addSuggestionToDoc,
            },
        ];
    };
    const getDocumentHighlights = (feedback) => __awaiter(void 0, void 0, void 0, function* () {
        if (!pleditor) {
            return;
        }
        const { id, name, notes, content } = feedback.feedback;
        const feedbackData = getFeedbackHighlightsData(id);
        // If the document has not changed, do not fetch highlights
        if (feedbackData && !feedbackData.isDocumentChanged) {
            return;
        }
        setFeedbackHighlightsMap(prevMap => {
            var _a;
            const prevHighlights = ((_a = prevMap[feedback.feedback.id]) === null || _a === void 0 ? void 0 : _a.documentHighlights) || [];
            return Object.assign(Object.assign({}, prevMap), { [feedback.feedback.id]: {
                    documentHighlights: prevHighlights,
                    isDocumentChanged: false,
                    loading: true,
                } });
        });
        const query = [name, notes, content].join('\n');
        const texts = documentChunks.map(chunk => chunk.text);
        const highlightEntries = yield getDocumentHighlightEntries(query, texts);
        const tokenizeTextFilter = (highlightEntry) => {
            const chunk = documentChunks[highlightEntry.index];
            // Split by any whitespace character or punctuation, including parentheses
            const tokens = chunk.text.split(/[\s,.!?()]+/).filter(Boolean);
            return chunk.text.length > 50 && tokens.length > 5;
        };
        const highlights = [...highlightEntries]
            .filter(tokenizeTextFilter) // Remove short highlights such as titles etc
            .flatMap(entry => transformChunkyEntryToHighlight(pleditor, entry.id, documentChunks[entry.index]))
            .slice(0, 5); // Limit to top 5 highlights
        setFeedbackHighlightsMap(prevMap => (Object.assign(Object.assign({}, prevMap), { [feedback.feedback.id]: {
                documentHighlights: highlights,
                isDocumentChanged: false,
                loading: false,
            } })));
    });
    useEffect(() => {
        if (pleditor && isContractReviewAvailable && isReviewDone) {
            getDocumentChunks();
            pleditor.model.document.on('change:data', () => {
                getDocumentChunks(true);
            });
        }
    }, [getDocumentChunks, pleditor, isContractReviewAvailable, isReviewDone]);
    return {
        getDocumentHighlights,
        getFeedbackHighlightsData,
        primeDocumentHighlightEntries,
    };
};
