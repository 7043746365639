import { AvatarWithLabel } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Link, useRouteMatch } from 'react-router-dom';
import { useSettingsDrawer } from '../../hook';
import { getTitle, getSubtitle } from '../../utils';
import { StyledListItem, Icon, AlignRight } from './styled';
export function ListButton(props) {
    const { settingsKey, icon, endElement } = props;
    const { onSelectSetting } = useSettingsDrawer();
    const { url } = useRouteMatch();
    const title = getTitle(settingsKey);
    const subtitle = getSubtitle(settingsKey);
    return (<StyledListItem as={Link} to={`${url}/${settingsKey}`} onClick={() => onSelectSetting(settingsKey)}>
      <AvatarWithLabel appearance="light" shape="circle" size="lg" title={title} subtitle={subtitle}>
        {icon}
      </AvatarWithLabel>
      <AlignRight>
        {endElement}
        <Icon>
          <Falcon icon="chevron-right"/>
        </Icon>
      </AlignRight>
    </StyledListItem>);
}
