import { DocumentType, SearchEntityTypeEnum } from 'shared/domains/apollo/generated/types';
export function isDocument(type) {
    switch (type) {
        case SearchEntityTypeEnum.DocumentComposed:
        case SearchEntityTypeEnum.DocumentFreetext:
        case SearchEntityTypeEnum.DocumentUploaded:
            return true;
        default:
            return false;
    }
}
export function mapSearchType(type) {
    switch (type) {
        case SearchEntityTypeEnum.DocumentComposed:
            return DocumentType.Composed;
        case SearchEntityTypeEnum.DocumentFreetext:
            return DocumentType.Freetext;
        case SearchEntityTypeEnum.DocumentUploaded:
            return DocumentType.Uploaded;
        default:
            return DocumentType.Unknown;
    }
}
