import { useDesktopNavigation } from '../../DesktopNavigation';
import { Body } from '../Body';
import { Footer } from '../Footer';
import { Header } from '../Header';
import { StyledSidebarMenu } from './styled';
export function Desktop() {
    const { minimized, setMinimized } = useDesktopNavigation();
    const toggleMinimized = () => setMinimized(!minimized);
    return (<StyledSidebarMenu minimized={minimized} onToggle={toggleMinimized}>
      <Header />
      <Body />
      <Footer />
    </StyledSidebarMenu>);
}
