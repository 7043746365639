import { t } from '@lingui/macro';
export const getUnsupportedTooltip = (reason) => {
    switch (reason) {
        case 'fileSize':
            return t({
                message: 'This file size is too big',
                comment: 'Tooltip for a file with a too large file size in a selected folder for upload',
            });
        case 'fileType':
            return t({
                message: 'This file type is not supported',
                comment: 'Tooltip for a file with an unsupported file type in a selected folder for upload',
            });
        default:
            return null;
    }
};
