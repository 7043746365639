import { useContext } from 'react';
import { PreviewContext } from './context';
export const usePreview = () => useContext(PreviewContext);
export const useActiveDocument = () => {
    const { document, company } = useContext(PreviewContext);
    const id = document === null || document === void 0 ? void 0 : document.id;
    const name = document === null || document === void 0 ? void 0 : document.name;
    return {
        id,
        name,
        company,
    };
};
