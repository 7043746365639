import { gql } from '@apollo/client';
export const downloadSignedFileQuery = gql `
  query DownloadSignedFileQuery($documentId: ID!, $name: String!) {
    fileSystemDocument(id: $documentId) {
      id
      files {
        id
        mimeType
        signed(name: $name) {
          url
        }
      }
    }
  }
`;
