import { Menu, pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const StyledMenu = styled(Menu) `
  width: ${pxToRem(300)};
  max-width: 100%;
  padding: ${themeGet('spacing.sm')} ${themeGet('spacing.md')};
`;
export const Divider = styled.div `
  height: ${pxToRem(1)};
  width: 100%;
  border: 0;
  background-color: ${themeGet('colors.border.100')};
`;
export const AccountInfoSectionLinkContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${themeGet('spacing.md')};
`;
export const AccountInfoSectionContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${themeGet('spacing.xl')};
  padding: 0 ${themeGet('spacing.md')} ${themeGet('spacing.md')};
`;
export const FlagAvatar = styled.div `
  height: ${pxToRem(21)};
  width: ${pxToRem(21)};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  svg {
    path {
      height: ${pxToRem(28)};
    }
  }
`;
