import { Fragment, useState } from 'react';
import { Trans } from '@lingui/macro';
import { Button } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { CancelApprovalRequestDialog } from 'app/domains/esigning';
export function EditApprovalRequestedButton() {
    const [openCancelApprovalDialog, setCancelApprovalDialogState] = useState(false);
    const showCancelApprovalDialog = () => setCancelApprovalDialogState(true);
    const hideCancelApprovalDialog = () => setCancelApprovalDialogState(false);
    return (<Fragment>
      <Button type="button" appearance="secondary" onClick={showCancelApprovalDialog}>
        <Falcon icon="pen" $size={16}/>
        <Trans comment="Label for edit document">Edit</Trans>
      </Button>
      {openCancelApprovalDialog && (<CancelApprovalRequestDialog origin="edit" onClose={hideCancelApprovalDialog}/>)}
    </Fragment>);
}
