import { z } from 'zod';
export const zFollowUpSuggestionPayload = z.object({
    followUpQuestions: z.array(z.object({
        title: z.string().describe('A title for the follow up question'),
        questionPrompt: z.string().describe('The prompt for the follow up question'),
    })),
});
export const zRewriteSuggestionPayload = z.object({
    id: z.string().describe('The id of the highlighted text, used as context for the rewrite'),
    type: z
        .enum(['append', 'prepend', 'modify', 'remove', 'unknown'])
        .default('append')
        .describe('The type of the rewrite suggestion'),
    title: z.string().describe('A title for the rewrite suggestion'),
    suggestion: z.string().describe('The rewrite suggestion'),
});
export const zServerSentMessage = z.tuple([
    z.string(),
    z.tuple([z.string(), z.string()]),
]);
export const zAction = z.discriminatedUnion('type', [
    z.object({
        type: z.literal('followUpSuggestions'),
        payload: zFollowUpSuggestionPayload,
    }),
    z.object({
        type: z.literal('rewriteSuggestions'),
        payload: zRewriteSuggestionPayload,
    }),
]);
