import { useState, useEffect } from 'react';
const cachedScripts = [];
export function useScript(src, options) {
    const { disabled = false } = options !== null && options !== void 0 ? options : {};
    const [state, setState] = useState({
        loaded: false,
        error: false,
    });
    useEffect(() => {
        if (disabled) {
            return undefined;
        }
        if (cachedScripts.includes(src)) {
            setState({ loaded: true, error: false });
            return undefined;
        }
        cachedScripts.push(src);
        const script = document.createElement('script');
        script.src = src;
        script.type = 'application/javascript';
        script.async = true;
        const handleLoad = () => {
            setState({ loaded: true, error: false });
        };
        const handleError = () => {
            const index = cachedScripts.indexOf(src);
            if (index >= 0) {
                cachedScripts.splice(index, 1);
            }
            document.body.removeChild(script);
            setState({ loaded: true, error: true });
        };
        script.addEventListener('load', handleLoad);
        script.addEventListener('error', handleError);
        document.body.appendChild(script);
        return () => {
            script.removeEventListener('load', handleLoad);
            script.removeEventListener('error', handleError);
        };
    }, [src, disabled]);
    return state;
}
