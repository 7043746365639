import { gql } from '@apollo/client';
export const fileSystemManagerQuery = gql `
  query FileSystemManager($parentId: ID!, $withParentId: Boolean!) {
    selectedFolder: fileSystemFolder(id: $parentId) @include(if: $withParentId) {
      id
      categoryId
      name
      acl: acls {
        id
        userId
        actions
      }
      parent {
        id
        name
      }
    }
  }
`;
