var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Text } from '@pocketlaw/tetris/deprecated';
import { getSearchEntityTypeText } from 'app/domains/global-search/utils/getSearchEntityTypeText';
import { StyledChip } from '../styled';
export function Type(props) {
    const { type } = props, restProps = __rest(props, ["type"]);
    return (<Text {...restProps} appearance="neutral" size="tiny">
      <StyledChip appearance="primary" label={getSearchEntityTypeText(type)}/>
    </Text>);
}
