import { unit } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const EmptyContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${unit('2xl')} 0;
  text-transform: uppercase;
`;
export const List = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${unit('md')};
  padding: ${unit('md')} 0;
`;
