import gql from 'graphql-tag';
export const QUERY = gql `
  query GetEntryLastSignRequest($id: ID!) {
    document: fileSystemDocument(id: $id) {
      id
      lastSignRequest {
        id
        status
      }
    }
  }
`;
