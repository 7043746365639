import { pxToRem, themeGet, unit } from '@pocketlaw/tetris';
import { css } from 'styled-components';
export const themeStyles = css `
  --ck-icon-size: ${pxToRem(16)};

  /* -- Overrides the border radius setting in the theme. ------------------------------------- */

  --ck-border-radius: ${pxToRem(0)};

  /* -- Overrides the default font size in the theme. ----------------------------------------- */

  --ck-font-size-base: ${themeGet('fontSize.small')};

  /* -- Helper variables to avoid duplication in the colors. ---------------------------------- */

  --ck-custom-background: ${themeGet('colors.background.50')};
  --ck-custom-foreground: ${themeGet('colors.background.50')};
  --ck-custom-border: ${themeGet('colors.gray.100')};
  --ck-custom-white: ${themeGet('colors.background.50')};

  /* -- Overrides generic colors. ------------------------------------------------------------- */

  --ck-color-base-foreground: var(--ck-custom-background);
  --ck-color-focus-border: ${themeGet('colors.gray.500')};
  --ck-color-text: ${themeGet('colors.text.200')};

  /* -- Overrides shadows ---------------------------------------------------------------------- */

  --ck-drop-shadow: ${themeGet('dropShadow.400')};
  --ck-drop-shadow-active: ${themeGet('dropShadow.400')};

  /* -- Overrides spacing ---------------------------------------------------------------------- */

  --ck-spacing-unit: 0.6em;
  --ck-spacing-large: calc(var(--ck-spacing-unit) * 1.5);
  --ck-spacing-standard: var(--ck-spacing-unit);
  --ck-spacing-medium: calc(var(--ck-spacing-unit) * 0.8);
  --ck-spacing-small: ${unit('md')};
  --ck-spacing-tiny: ${unit('md')};
  --ck-spacing-extra-tiny: ${unit('xs')};

  /* -- Overrides the default .ck-button class colors. ---------------------------------------- */

  --ck-color-button-on-color: ${themeGet('colors.primary.500')};

  --ck-color-button-default-background: var(--ck-custom-background);
  --ck-color-button-default-hover-background: ${themeGet('colors.gray.50')};
  --ck-color-button-default-active-background: ${themeGet('colors.gray.100')};
  --ck-color-button-default-active-shadow: none;
  --ck-color-button-default-disabled-background: var(--ck-custom-background);

  --ck-color-button-on-background: var(--ck-custom-background);
  --ck-color-button-on-hover-background: ${themeGet('colors.gray.50')};
  --ck-color-button-on-active-background: ${themeGet('colors.gray.100')};
  --ck-color-button-on-active-shadow: none;
  --ck-color-button-on-disabled-background: var(--ck-custom-foreground);

  --ck-color-button-action-background: var(--ck-custom-background);
  --ck-color-button-action-hover-background: ${themeGet('colors.gray.50')};
  --ck-color-button-action-active-background: ${themeGet('colors.gray.100')};
  --ck-color-button-action-active-shadow: none;
  --ck-color-button-action-disabled-background: var(--ck-custom-foreground);
  --ck-color-button-action-text: var(--ck-color-text);

  --ck-color-button-save: ${themeGet('colors.text.success')};
  --ck-color-button-cancel: ${themeGet('colors.text.danger')};

  /* -- Overrides the default .ck-dropdown class colors. -------------------------------------- */

  --ck-color-dropdown-panel-background: var(--ck-custom-background);
  --ck-color-dropdown-panel-border: ${themeGet('colors.gray.100')};

  /* -- Overrides the default .ck-split-button class colors. ----------------------------------- */

  --ck-color-split-button-hover-background: var(--ck-color-button-default-hover-background);
  --ck-color-split-button-hover-border: var(--ck-custom-foreground);

  /* -- Overrides the default .ck-input class colors. ----------------------------------------- */

  --ck-color-input-background: var(--ck-custom-background);
  --ck-color-input-border: hsl(257, 3%, 43%);
  --ck-color-input-text: hsl(0, 0%, 98%);
  --ck-color-input-disabled-background: hsl(255, 4%, 21%);
  --ck-color-input-disabled-border: hsl(250, 3%, 38%);
  --ck-color-input-disabled-text: hsl(0, 0%, 78%);

  /* -- Overrides the default .ck-labeled-field-view class colors. ---------------------------- */

  --ck-color-labeled-field-label-background: var(--ck-custom-background);

  /* -- Overrides the default .ck-list class colors. ------------------------------------------ */

  --ck-color-list-background: var(--ck-custom-background);
  --ck-color-list-button-hover-background: ${themeGet('colors.gray.50')};
  --ck-color-list-button-on-background: ${themeGet('colors.blue.400')};
  --ck-color-list-button-on-background-focus: ${themeGet('colors.blue.400')};
  --ck-color-list-button-on-text: var(--ck-color-base-background);

  /* -- Overrides the default .ck-balloon-panel class colors. --------------------------------- */

  --ck-color-panel-background: var(--ck-custom-background);
  --ck-color-panel-border: var(--ck-custom-border);

  /* -- Overrides the default .ck-toolbar class colors. --------------------------------------- */

  --ck-color-toolbar-background: var(--ck-custom-background);
  --ck-color-toolbar-border: ${themeGet('colors.gray.100')};

  /* -- Overrides the default .ck-comment class colors. --------------------------------------- */

  --ck-color-comment-separator: ${themeGet('colors.background.100')};
  --ck-color-comment-background: ${themeGet('colors.background.50')};

  /* -- Overrides the default .ck-annotation class colors. ------------------------------------ */

  --ck-color-annotation-wrapper-background: ${themeGet('colors.background.50')};
  --ck-color-annotation-wrapper-drop-shadow: none;

  /* -- Overrides the default .ck-tooltip class colors. --------------------------------------- */

  --ck-color-tooltip-background: ${themeGet('colors.blue.900')};
  --ck-color-tooltip-text: ${themeGet('colors.text.light')};

  /* -- Overrides the default .ck-table ------------------------------------------------------- */
  --ck-insert-table-dropdown-box-width: ${pxToRem(18)};

  /* -- Overrides the default .ck-user avatar colors ------------------------------------------ */
  --ck-user-avatar-background: ${themeGet('colors.primary.50')};
  --ck-user-avatar-color: ${themeGet('colors.primary.500')};
  --ck-user-avatar-size: ${pxToRem(32)};

  /* -- Overrides the default toolbar dropdown  ----------------------------------------------- */
  --ck-toolbar-dropdown-max-width: ${pxToRem(320)};

  /* -- Overrides the suggestion marker and widget colors */
  --ck-color-suggestion-marker-insertion-border: ${themeGet('colors.green.500')};
  --ck-color-suggestion-marker-insertion-border-active: ${themeGet('colors.green.300')};
  --ck-color-suggestion-marker-insertion-background: ${themeGet('colors.green.50')};
  --ck-color-suggestion-marker-insertion-background-active: ${themeGet('colors.green.100')};

  --ck-color-suggestion-box-insertion-border: ${themeGet('colors.green.500')};

  --ck-color-suggestion-marker-deletion-border: ${themeGet('colors.red.50')};
  --ck-color-suggestion-marker-deletion-border-active: ${themeGet('colors.red.300')};
  --ck-color-suggestion-marker-deletion-background: ${themeGet('colors.red.50')};
  --ck-color-suggestion-marker-deletion-background-active: ${themeGet('colors.red.100')};

  --ck-color-suggestion-box-deletion-border: ${themeGet('colors.red.500')};

  --ck-color-suggestion-marker-deletion-stroke: ${themeGet('colors.red.500')};

  --ck-color-suggestion-marker-format-border: ${themeGet('colors.blue.500')};
  --ck-color-suggestion-marker-format-border-active: ${themeGet('colors.blue.300')};

  --ck-color-suggestion-box-format-border: ${themeGet('colors.blue.500')};

  --ck-color-text: ${themeGet('colors.text.200')};

  --ck-color-comment-marker: ${themeGet('colors.indigo.100')};
  --ck-color-comment-marker-active: ${themeGet('colors.indigo.200')};
  --ck-color-comment-box-border: ${themeGet('colors.indigo.500')};

  --ck-color-base-action: ${themeGet('colors.indigo.600')};
  --ck-color-button-save: ${themeGet('colors.indigo.500')};

  --ck-z-modal: 1;

  /* -- Overrides the revision history colors  ----------------------------------------------- */
  --ck-revision-history-revision-selected-text-color: ${themeGet('colors.gray.0')};
  --ck-revision-history-revision-selected-background-color: ${themeGet('colors.secondary.500')};
`;
