import { DocumentType } from 'shared/domains/apollo/generated/types';
/**
 * This function is deprecated. Use FileSystemDocument.type to
 * check what type a document is.
 * @deprecated
 */
export const isUnknownDocument = (document) => {
    const { type } = document || {};
    const knownTypes = Object.values(DocumentType).filter(value => value !== DocumentType.Unknown);
    return type === DocumentType.Unknown || !type || !knownTypes.includes(type);
};
