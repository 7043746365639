import { useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { AvatarWithLabel, IconButton, Loader, Menu, MenuBody, MenuItem, MenuList, Typography, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { AssistantViewType, useAssistant } from 'app/domains/assistant';
import { Container, AlignRight, Row } from './styled';
export function ConversationList() {
    var _a;
    const { updateView, clearThread, thread, clearing } = useAssistant();
    const [showMenu, setShowMenu] = useState(false);
    const toggleMenu = () => setShowMenu(value => !value);
    const hideMenu = () => setShowMenu(false);
    const latestMessagePreview = ((_a = thread[thread.length - 1]) === null || _a === void 0 ? void 0 : _a.content) || undefined;
    return (<Container>
      <Typography $fontWeight="medium" $fontSize="small">
        <Trans comment="Document assistant - conversations - active section">Active</Trans>
      </Typography>
      <Row>
        <AvatarWithLabel appearance="assistant" shape="circle" size="md" 
    // subtitle="TODO: insert date here,ie 'Yesterday 11:45 PM'"
    title={latestMessagePreview}>
          <Falcon icon="comment"/>
        </AvatarWithLabel>
        <AlignRight>
          <Menu open={showMenu} onClose={hideMenu} target={<IconButton aria-label={t({
                comment: 'Document assistant - conversations - dropdown menu - aria label (accessibility)',
                message: 'More options',
            })} appearance="ghost" size="medium" onClick={toggleMenu}>
                <Falcon icon="ellipsis-v"/>
              </IconButton>}>
            <MenuBody>
              <MenuList>
                <MenuItem onClick={() => updateView(AssistantViewType.Chat)}>
                  <Falcon icon="eye"/>
                  <Trans comment="Document assistant - conversations - dropdown menu - view conversation item">
                    View
                  </Trans>
                </MenuItem>
                <MenuItem onClick={clearThread} disabled={clearing}>
                  {clearing ? <Loader /> : <Falcon icon="trash"/>}
                  <Trans comment="Document assistant - conversations - dropdown menu - delete conversation item">
                    Delete
                  </Trans>
                </MenuItem>
              </MenuList>
            </MenuBody>
          </Menu>
        </AlignRight>
      </Row>
    </Container>);
}
