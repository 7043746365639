import { makeVar, useReactiveVar } from '@apollo/client';
/**
 * NOTE: Auth vars here should be moved somewhere else.
 *
 * AUTHENTICATED - user is authenticated
 * NOT_AUTHENTICATED - user is not authenticated
 * NO_USER - user is not authenticated and there is no user in the cache
 * NULL - authentication process is not finished or started
 */
export const authenticatedVar = makeVar(null);
export const useAuthenticatedState = () => useReactiveVar(authenticatedVar);
export const isMobileVar = makeVar(false);
