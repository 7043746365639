import { Typography } from '@pocketlaw/tetris';
import { Container, ItemContainer, TextContainer } from './styled';
export function SectionAccordionListItem(props) {
    const { icon, label, text } = props;
    if (!text) {
        return null;
    }
    return (<Container>
      <ItemContainer>{icon}</ItemContainer>
      <TextContainer>
        <Typography $fontSize="small" $fontWeight="medium" $appearance="300">
          {label}
        </Typography>
        <Typography $fontSize="small" $appearance="200">
          {text}
        </Typography>
      </TextContainer>
    </Container>);
}
