import { gql } from '@apollo/client';
export const esigningProvidersQuery = gql `
  query esigningProviders($companyId: UUID!) {
    company(id: $companyId) {
      id
      esigningProvider {
        id
        companyId
        esigningProviderId
        expiresAt
      }
    }
    esigningProviders {
      id
      name
      authenticationModes {
        id
        slug
      }
    }
  }
`;
