import { useQuery, gql } from '@apollo/client';
export const latestTemplateVersionQuery = gql `
  query latestTemplateVersion($id: Unstable__UUID!) {
    templateVersions: unstable__templateVersions(
      where: { templateId: { eq: $id } }
      orderBy: { createdAt: desc }
      limit: 1
    ) {
      templateId
      reference
    }
  }
`;
export default function useLatestTemplateVersion(options) {
    const { id, skip } = options || {};
    return useQuery(latestTemplateVersionQuery, {
        skip,
        variables: { id },
    });
}
