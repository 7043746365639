import { addDays, differenceInDays, isAfter, set } from 'date-fns';
const NUMBER_OF_DAYS_BEFORE_REMINDER = 7;
export const getRemainingDays = (createdDate, todayDate) => {
    // Signicat sends out the reminders 03:00 seven days after the send out has been made
    const reminderDate = set(addDays(createdDate, NUMBER_OF_DAYS_BEFORE_REMINDER), {
        milliseconds: 0,
        seconds: 0,
        minutes: 0,
        hours: 3,
    });
    // If the reminder date is in the past we redo the process but we
    // pass the reminder date instead of the created date
    if (isAfter(todayDate, reminderDate)) {
        return getRemainingDays(reminderDate, todayDate);
    }
    const dayDifference = Math.abs(differenceInDays(todayDate, reminderDate));
    const remainingDays = dayDifference % NUMBER_OF_DAYS_BEFORE_REMINDER;
    if (remainingDays === 0) {
        return isAfter(todayDate, reminderDate) ? NUMBER_OF_DAYS_BEFORE_REMINDER : 1;
    }
    return remainingDays;
};
