import { themeGet, breakpoints } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${themeGet('spacing.md')};

  ${breakpoints.xs} {
    flex-direction: column;
    align-items: flex-start;
  }
`;
