const LS_KEY_MINIMIZED = 'pl.sidebar.minimized';
const LS_KEY_STATES = 'pl.sidebar.states';
const LS_MINIMIZED_DEFAULT_VALUE = false;
const LS_STATES_DEFAULT_VALUE = {
    insights: false,
    repository: false,
    savedViews: false,
    settings: false,
    customerSuccess: false,
};
/**
 * If we ever change the keys of the `CollapsibleStates` interface this function
 * will remove any keys that are not present in the new interface and add new ones.
 */
const cleanupStates = (partialStates) => {
    const validKeys = Object.keys(LS_STATES_DEFAULT_VALUE);
    const cleanedStates = Object.keys(partialStates).reduce((acc, _key) => {
        const key = _key;
        if (validKeys.includes(key)) {
            acc[key] = partialStates[key];
        }
        return acc;
    }, partialStates);
    return Object.assign(Object.assign({}, LS_STATES_DEFAULT_VALUE), cleanedStates);
};
export const useLocalStorage = () => {
    const getLsMinimized = () => {
        try {
            const lsData = localStorage.getItem(LS_KEY_MINIMIZED);
            if (lsData) {
                const value = JSON.parse(lsData);
                return value;
            }
            return LS_MINIMIZED_DEFAULT_VALUE;
        }
        catch (e) {
            return LS_MINIMIZED_DEFAULT_VALUE;
        }
    };
    const getLsStates = () => {
        try {
            const lsData = localStorage.getItem(LS_KEY_STATES);
            if (lsData) {
                const value = JSON.parse(lsData);
                return cleanupStates(value);
            }
            return cleanupStates(LS_STATES_DEFAULT_VALUE);
        }
        catch (e) {
            return cleanupStates(LS_STATES_DEFAULT_VALUE);
        }
    };
    const setLsMinimized = (minimized) => {
        localStorage.setItem(LS_KEY_MINIMIZED, JSON.stringify(minimized));
    };
    const setLsStates = (states) => {
        localStorage.setItem(LS_KEY_STATES, JSON.stringify(cleanupStates(states)));
    };
    return {
        getLsMinimized,
        getLsStates,
        setLsMinimized,
        setLsStates,
    };
};
