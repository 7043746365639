import { t } from '@lingui/macro';
import { Falcon } from '@pocketlaw/tetris/macro';
import { StyledButton, StyledTypography } from './styled';
export function DocumentName(props) {
    const { locked, name, onClick } = props;
    const ariaLabel = t `Contract template is not available for the current plan.`;
    return (<StyledButton type="button" onClick={onClick}>
      {locked && (<Falcon data-testid="locked-icon" aria-label={ariaLabel} icon="lock-alt" $color="cyan.400" $size={16}/>)}
      <StyledTypography $appearance="300" $fontSize="medium" $lineHeight="default">
        {name}
      </StyledTypography>
    </StyledButton>);
}
