import { gql } from '@apollo/client';
import { AccountFragment } from 'shared/domains/users/utils/fragments';
export const createCompany = gql `
  mutation createCompany(
    $planId: UUID!
    $companyName: String!
    $companyReferrerId: UUID
    $companyMarketCode: String!
    $companyWebDomain: String!
    $userId: String!
    $userName: String!
    $userPhoneNumber: String!
    $userPreferredLocale: String!
  ) {
    users: updateUser(
      where: { id: { _eq: $userId } }
      _set: {
        name: $userName
        phone_number: $userPhoneNumber
        preferred_locale: $userPreferredLocale
      }
    ) {
      returning {
        id
        preferredLocale: preferred_locale
      }
    }

    accounts: createCompanyUser(
      objects: {
        role: owner
        user_id: $userId
        company: {
          data: {
            name: $companyName
            referrer_id: $companyReferrerId
            market_code: $companyMarketCode
            web_domain: $companyWebDomain
            plans: { data: { plan_id: $planId } }
          }
        }
      }
    ) {
      returning {
        ...AccountFragment
      }
    }
  }
  ${AccountFragment}
`;
export const createCompanyAccountsQuery = gql `
  query createCompanyAccounts {
    accounts @client {
      ...AccountFragment
    }
    account @client {
      ...AccountFragment
    }
  }
  ${AccountFragment}
`;
