var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { DocumentAttachmentItem, useDefaultDocumentAttachments, usePleditor, } from 'shared/domains/documents';
export function DefaultAttachment(props) {
    const { attachment, disabled, embeddedCount, onDownload, onScrollToAttachment, onEmbed } = props;
    const { plugins } = usePleditor();
    const { addToast } = useToast();
    const { restoreDefaultAttachment, removeDefaultAttachment, isAttachmentLoading } = useDefaultDocumentAttachments();
    const include = () => __awaiter(this, void 0, void 0, function* () {
        var _a;
        try {
            yield restoreDefaultAttachment(attachment.id);
            (_a = plugins.attachmentStore) === null || _a === void 0 ? void 0 : _a.add(attachment);
        }
        catch (_b) {
            addToast({
                appearance: 'danger',
                title: t({
                    comment: 'Document attachments - error when including default attachment toast title',
                    message: 'Could not include attachment',
                }),
            });
        }
    });
    const exclude = () => __awaiter(this, void 0, void 0, function* () {
        var _c;
        try {
            yield removeDefaultAttachment(attachment.id);
            (_c = plugins.attachmentStore) === null || _c === void 0 ? void 0 : _c.remove(attachment.id);
        }
        catch (_d) {
            addToast({
                appearance: 'danger',
                title: t({
                    comment: 'Document attachments - error when excluding default attachment toast title',
                    message: 'Could not exclude attachment',
                }),
            });
        }
    });
    const handleDownloadAttachment = () => {
        onDownload(attachment.id);
    };
    const handleScrollToAttachment = () => {
        onScrollToAttachment(attachment.id);
    };
    const handleToggleExcluded = () => {
        if (attachment.isExcluded) {
            include();
        }
        else {
            exclude();
        }
    };
    return (<DocumentAttachmentItem embeddedCount={embeddedCount} disabled={disabled || isAttachmentLoading} attachment={attachment} onDownload={handleDownloadAttachment} onToggleExcluded={handleToggleExcluded} onScrollToAttachment={handleScrollToAttachment} onEmbed={onEmbed}/>);
}
