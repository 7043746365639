import { themeGet, breakpoints, scrollbar } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const PageWrapper = styled.div `
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  max-height: 100vh;
`;
export const PageContainer = styled.div `
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
`;
export const PageContent = styled.div `
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
export const DocumentContainer = styled.div `
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
export const DocumentPageContainer = styled.div `
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  overflow: auto;
  margin: 0;
  height: 100%;
  width: 100%;
  padding: ${themeGet('spacing.lg')};

  ${breakpoints.sm} {
    padding: ${themeGet('spacing.md')};

    ${scrollbar({ trackMargin: 'xl' })};
  }

  ${breakpoints.lg} {
    padding: ${themeGet('spacing.lg')} ${themeGet('spacing.md')};
  }
`;
