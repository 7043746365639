import { unit } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const AlertsContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${unit('xl')};
  padding: ${unit('xl')};
  padding-bottom: 0;

  &:empty {
    display: none;
  }
`;
export const FooterContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${unit('xl')};
`;
