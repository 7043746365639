var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useLazyQuery } from '@apollo/client';
import { GetEditorTokenDocument } from './query.gql';
import { useGetTokenWrapper } from '../useGetTokenWrapper';
export const useGetEditorToken = (documentId) => {
    const [getTokenLazy] = useLazyQuery(GetEditorTokenDocument, {
        variables: { documentId },
        fetchPolicy: 'network-only',
    });
    const getTokenWrapper = useGetTokenWrapper();
    const getToken = () => __awaiter(void 0, void 0, void 0, function* () {
        const { data, error } = yield getTokenLazy();
        const { token } = (data === null || data === void 0 ? void 0 : data.documentEditorToken) || {};
        return { token, error };
    });
    const getEditorToken = () => __awaiter(void 0, void 0, void 0, function* () { return getTokenWrapper(getToken); });
    return getEditorToken;
};
