import { Falcon } from '@pocketlaw/tetris/macro';
export const getBadgePropsFromEventType = (type) => {
    switch (type) {
        case 'cancel':
            return {
                icon: <Falcon icon="ban"/>,
                appearance: 'warning',
            };
        case 'comment':
            return {
                icon: <Falcon icon="comment"/>,
                appearance: 'primary',
            };
        case 'approve':
            return {
                icon: <Falcon icon="thumbs-up"/>,
                appearance: 'success',
            };
        case 'reject':
            return {
                icon: <Falcon icon="circle-xmark"/>,
                appearance: 'danger',
            };
        case 'request':
            return {
                icon: <Falcon icon="hand-wave"/>,
                appearance: 'primary',
            };
        default:
            return undefined;
    }
};
