import { gql } from '@apollo/client';
export const TaskFragment = gql `
  fragment TaskFragment on Task {
    id
    title
    description
    isCompleted: is_completed
    isPublic: is_public
    createdAt: created_at
    updatedAt: updated_at
    companyId: company_id
    dueDate: due_date
    status

    reminders {
      id
      interval
      dueDate: due_date
      taskId: task_id
    }

    assignees {
      id
      user {
        id
        name
      }
    }

    taskDocuments: task_documents {
      id
      documentId: document_id
      taskId: task_id
    }

    taskTemplates: task_templates {
      id
      templateId: template_id
      taskId: task_id
    }

    creator {
      id
      name
    }
  }
`;
