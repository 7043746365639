import { useRef } from 'react';
import { Trans, t } from '@lingui/macro';
import { Typography, useToast } from '@pocketlaw/tetris';
import { getFiletypeName } from 'shared/domains/filesystem/utils/fileExtensions';
import { UploadFilesInput } from './UploadFilesInput';
import { UploadFolderInput } from './UploadFolderInput';
import { UploadIcon } from './UploadIcon';
import { useDropZone } from './useDropZone';
import { mapDropZoneFiles, mapFileWrapper, removeDotPrefixedFiles } from './utils';
import { DropContainer, DropContainerContent, StyledLink, StyledTypography } from './styled';
export function DropZone(props) {
    const filetypeName = getFiletypeName();
    const { onChange } = props;
    const { addToast } = useToast();
    const dropZoneRef = useRef(null);
    const uploadFilesInputRef = useRef(null);
    const uploadFolderInputRef = useRef(null);
    const supportedFormats = Object.values(filetypeName).sort().join(', ');
    const handleBrowseFiles = () => { var _a; return (_a = uploadFilesInputRef.current) === null || _a === void 0 ? void 0 : _a.click(); };
    const handleBrowseFolders = () => { var _a; return (_a = uploadFolderInputRef.current) === null || _a === void 0 ? void 0 : _a.click(); };
    const handleChange = (files) => {
        onChange(files.map(mapFileWrapper).filter(removeDotPrefixedFiles).map(mapDropZoneFiles));
    };
    const handleDrop = data => {
        const { status, files } = data;
        if (status === 'success') {
            onChange(files.filter(removeDotPrefixedFiles).map(mapDropZoneFiles));
        }
        else {
            addToast({
                appearance: 'danger',
                title: t({
                    comment: 'Upload document - Dropping files in drop zone error title',
                    message: 'Something went wrong',
                }),
                message: t({
                    comment: 'Upload document - Dropping files in drop zone error message',
                    message: 'Could not read the selected files, please try again',
                }),
            });
        }
    };
    const { dragging, draggingOver } = useDropZone({ ref: dropZoneRef, onDrop: handleDrop });
    return (<DropContainer ref={dropZoneRef} $dragging={dragging} $draggingOver={draggingOver}>
      <DropContainerContent $dragging={dragging}>
        <UploadIcon draggingOver={draggingOver}/>
        <Typography>
          <Trans comment="Upload documents dialog - Drag and drop or browse">
            Drag & Drop, or browse <StyledLink onClick={handleBrowseFiles}>files</StyledLink> /{' '}
            <StyledLink onClick={handleBrowseFolders}>folders</StyledLink>
          </Trans>
        </Typography>
        <StyledTypography $appearance="100" $fontSize="tiny">
          <Trans comment="Upload documents dialog - Supported formats string">
            Supported formats: {supportedFormats}
          </Trans>
        </StyledTypography>
        <UploadFilesInput ref={uploadFilesInputRef} onChange={handleChange}/>
        <UploadFolderInput ref={uploadFolderInputRef} onChange={handleChange}/>
      </DropContainerContent>
    </DropContainer>);
}
