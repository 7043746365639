import { Trans, t } from '@lingui/macro';
import { Button, useToast } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
export function Copy(props) {
    const { message = '' } = props;
    const { addToast } = useToast();
    const handleCopy = () => {
        if (message) {
            navigator.clipboard.writeText(message).then(() => {
                addToast({
                    title: t({
                        message: 'Copied to clipboard!',
                        comment: 'document assistant - assistant reply action - toast when reply has been copied to clipboard',
                    }),
                    appearance: 'success',
                });
            });
        }
    };
    return (<Button appearance="ghost" onClick={handleCopy} data-testid="assistant-action-copy">
      <Falcon icon="copy"/>
      <Trans comment="document assistant - assistant reply action - copy">Copy</Trans>
    </Button>);
}
