import { themeGetColor } from '@pocketlaw/tetris';
import { VERTICAL_Y_AXIS_WIDTH } from './constants';
import { RECHARTS_FONT_SIZE } from '../constants';
export function VerticalTick(props) {
    const { y, payload } = props;
    return (<g transform={`translate(${0},${y})`}>
      <defs>
        <linearGradient id="barchart-vertical-text-tick-bg" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop stopColor={themeGetColor('background.50')} offset="70%" stopOpacity={0}/>
          <stop stopColor={themeGetColor('background.50')} offset="90%" stopOpacity={1}/>
        </linearGradient>
      </defs>

      <clipPath id="clip-text">
        <rect x={0} y={-(RECHARTS_FONT_SIZE * 2) / 2} width={VERTICAL_Y_AXIS_WIDTH} height={RECHARTS_FONT_SIZE * 2}/>
      </clipPath>

      <text x={0} y={0} fill={themeGetColor('text.200')} textAnchor="start" alignmentBaseline="middle" fontSize={RECHARTS_FONT_SIZE} clipPath="url(#clip-text)">
        {payload.value}
      </text>

      <rect x={0} y={-(RECHARTS_FONT_SIZE * 2) / 2} width={VERTICAL_Y_AXIS_WIDTH} height={RECHARTS_FONT_SIZE * 2} fill="url(#barchart-vertical-text-tick-bg)"/>
    </g>);
}
