import { useLayoutEffect, useRef } from 'react';
import { pxToRem } from '@pocketlaw/tetris';
import * as Sentry from 'shared/domains/sentry';
import { Container } from './styled';
export function CanvasLayer(props) {
    const { page, scale = 1 } = props;
    const ref = useRef(null);
    useLayoutEffect(() => {
        if (!ref.current) {
            return undefined;
        }
        const canvas = ref.current;
        const outputScale = window.devicePixelRatio || 1;
        const viewport = page.getViewport({ scale: scale * outputScale });
        canvas.width = viewport.width;
        canvas.height = viewport.height;
        canvas.style.width = `${pxToRem(Math.floor(viewport.width / outputScale))}`;
        canvas.style.height = `${pxToRem(Math.floor(viewport.height / outputScale))}`;
        const render = page.render({
            viewport,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            get canvasContext() {
                return canvas.getContext('2d');
            },
        });
        render.promise.catch(error => {
            if (error.name !== 'RenderingCancelledException') {
                Sentry.captureException(error);
                throw error;
            }
        });
        return () => render.cancel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);
    return <Container ref={ref} dir="ltr"/>;
}
