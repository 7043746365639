import { breakpoints, pxToRem, unit, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const FieldWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${unit('sm')};
  width: 100%;

  &:empty {
    display: none;
  }
`;
export const ContainerDivider = styled.div `
  margin-top: ${unit('2xl')};
  border-top: solid ${pxToRem(1)} ${themeGet('colors.gray.100')};
`;
export const ColumnsContainer = styled.div `
  display: flex;
  gap: ${unit('xl')};
  padding-top: ${unit('2xl')};

  ${breakpoints.xs} {
    flex-wrap: wrap;
  }
`;
export const LeftColumn = styled.div `
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: ${unit('md')};
  width: ${pxToRem(300)};

  ${breakpoints.xs} {
    flex: 1 1 auto;
  }
`;
export const RightColumn = styled.div `
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  gap: ${unit('xl')};
`;
