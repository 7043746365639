import { useField, useFormikContext } from 'formik';
import { useCurrentDropdown } from './useCurrentDropdown';
export function useOnChange() {
    const { toggleDropdown } = useCurrentDropdown();
    const { values, setFieldValue } = useFormikContext();
    const [, dueDateMeta, dueDateHelpers] = useField('dueDate');
    const handleChange = (date) => {
        const isoDate = date.toISOString();
        const { reminder } = values;
        if (dueDateMeta && !dueDateMeta.touched) {
            // second argument false because it's revalidated with reminder setValue
            dueDateHelpers.setTouched(true, false);
        }
        setFieldValue('reminder', {
            id: reminder === null || reminder === void 0 ? void 0 : reminder.id,
            dueDate: isoDate,
            taskId: values.id,
        }, true);
        toggleDropdown();
    };
    return { handleChange };
}
