var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { createExternalSharingInviteMutation } from './query';
import { useConvertDocumentToFreeText } from '../../../hooks/useConvertDocumentToFreeText';
import { externalSharingInvitesQuery } from '../../../hooks/useExternalSharingInvites';
import { useActiveDocument } from '../../../hooks/usePreview';
export function useCreateExternalSharingInvite() {
    const { id: documentId } = useActiveDocument();
    const { addToast } = useToast();
    const [convertDocumentToFreetext] = useConvertDocumentToFreeText();
    const [mutate, { data, loading, error }] = useMutation(createExternalSharingInviteMutation, {
        refetchQueries: [
            {
                query: externalSharingInvitesQuery,
                variables: {
                    documentId,
                },
            },
        ],
    });
    const handleConvertDocument = () => __awaiter(this, void 0, void 0, function* () {
        yield convertDocumentToFreetext(documentId);
        addToast({
            appearance: 'success',
            title: t({
                comment: 'Share document dialog - Successfully converted document to free-text',
                message: 'Converted to Free-text document',
            }),
        });
    });
    const createInvite = (email, convertDocument = false) => mutate({
        variables: { documentId, email },
        onCompleted: () => {
            if (convertDocument) {
                handleConvertDocument();
            }
        },
    });
    return { createInvite, data, loading, error };
}
