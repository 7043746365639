import { themeGet } from '@pocketlaw/tetris';
import { css } from 'styled-components';
export const reviewHighlightStyles = css `
  .review-redline-highlight {
    position: relative;
    color: ${themeGet('colors.red.600')};
    background-color: ${themeGet('colors.red.100')};
  }

  .review-highlight {
    position: relative;
    color: ${themeGet('colors.assistant.700')};
    background-color: ${themeGet('colors.assistant.100')};
  }
`;
