import { getDateRangeBucketFilterPayload } from './getDateRangeBucketFilterPayload';
import { getKeyedBucketFilterPayload } from './getKeyedBucketFilterPayload';
import { fieldDisplayValue } from '../fieldDisplayValue';
import { getSelectedField } from '../getSelectedField';
import { isDateRangeBucket } from '../typeGuards';
function transformDateRangeBucket(bucket, aggregation) {
    const { value, from, to } = bucket;
    const formattedValue = value !== null && value !== void 0 ? value : 0;
    const primaryField = getSelectedField(aggregation);
    const formattedFrom = fieldDisplayValue(primaryField, from);
    const formattedTo = fieldDisplayValue(primaryField, to);
    const payload = getDateRangeBucketFilterPayload(bucket, aggregation);
    return {
        name: `${formattedFrom} - ${formattedTo}`,
        total: formattedValue,
        payload,
        breakdownPayloads: [],
    };
}
function transformKeyedBucket(bucket, aggregation) {
    const { name, value } = bucket;
    const formattedValue = value !== null && value !== void 0 ? value : 0;
    const primaryField = getSelectedField(aggregation);
    const formattedPrimaryName = fieldDisplayValue(primaryField, name);
    const payload = getKeyedBucketFilterPayload(bucket, aggregation);
    return {
        name: formattedPrimaryName,
        total: formattedValue,
        payload,
        breakdownPayloads: [],
    };
}
export function getBucketData(bucket, aggregation) {
    if (isDateRangeBucket(bucket)) {
        return transformDateRangeBucket(bucket, aggregation);
    }
    return transformKeyedBucket(bucket, aggregation);
}
