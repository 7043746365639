import { useMemo } from 'react';
import { SignatoryStatus } from 'shared/domains/apollo/generated/types';
import { useCcRecipients } from './useCcRecipients';
import { useSignatories } from './useSignatories';
import { SignatoryPreviewType } from './types';
export const useRecipientsByStatus = () => {
    const signatories = useSignatories();
    const ccRecipients = useCcRecipients();
    const signed = signatories.filter(signatory => signatory.status === SignatoryStatus.Signed);
    const rejected = signatories.filter(signatory => signatory.status === SignatoryStatus.Rejected);
    const pending = signatories.filter(({ status }) => status === SignatoryStatus.Pending || status === SignatoryStatus.Opened);
    const allSignatories = [...signatories, ...ccRecipients];
    return useMemo(() => ({
        [SignatoryPreviewType.Signed]: signed,
        [SignatoryPreviewType.Rejected]: rejected,
        [SignatoryPreviewType.Pending]: pending,
        [SignatoryPreviewType.CC]: ccRecipients,
        allSignatories,
    }), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [signed, rejected, pending]);
};
