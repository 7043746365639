import { t } from '@lingui/macro';
import { Button } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useAssistant } from 'app/domains/assistant';
export function CancelButton() {
    const { cancelStreaming, isCancellingStreaming } = useAssistant();
    return (<Button type="button" appearance="ai" disabled={isCancellingStreaming} title={t({
            comment: 'document assistant - assistant action - cancel output',
            message: 'Cancel',
        })} $stretch onClick={cancelStreaming}>
      <Falcon icon="circle-stop"/>
    </Button>);
}
