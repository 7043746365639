import { t } from '@lingui/macro';
import useAccount from 'shared/domains/users/hooks/useAccount';
export function useUserName() {
    var _a;
    const account = useAccount();
    const { name, email } = ((_a = account.data) === null || _a === void 0 ? void 0 : _a.profile) || {};
    const fallbackName = t({
        message: 'You',
        comment: "Review chat - user name fallback when the company user doesn't have a name or an email",
    });
    return name || email || fallbackName;
}
