import { t } from '@lingui/macro';
import { SearchAggregationType, } from 'shared/domains/apollo/generated/types';
import { allowedPropertiesTranslations } from './translations';
const values = [
    'metadataDocumentLiabilityCap',
    'metadataDocumentNoticePeriodDays',
    'metadataDocumentNoticePeriodMonths',
    'metadataDocumentOriginalTermMonths',
    'metadataDocumentPaymentTermsDaysFromInvoiceDate',
    'metadataDocumentRenewalTermMonths',
    'metadataDocumentTotalContractCost',
    'metadataDocumentTotalContractValue',
];
export const getMetricAllowedProperties = () => ({
    type: SearchAggregationType.Metric,
    label: t({ message: 'Metric', comment: 'Insights - Metric aggregation' }),
    values: values.map(value => ({ value, label: allowedPropertiesTranslations(value) })),
});
