var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { isValidElement, Children } from 'react';
import { useFocusManager } from 'app/domains/templates';
import { Container } from './styled';
function Variable(props) {
    const { children, id } = props, rest = __rest(props, ["children", "id"]);
    const { focusRef, focusedFieldId } = useFocusManager();
    const primitiveTypes = ['string', 'number', 'boolean'];
    const isValidChild = (child) => isValidElement(child) || primitiveTypes.includes(typeof child);
    return (<Container ref={id === focusedFieldId ? focusRef : undefined} {...rest} tabIndex={-1}>
      {Children.map(children, child => (isValidChild(child) ? child : ''))}
    </Container>);
}
export default Variable;
