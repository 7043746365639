import { Context } from './Context';
import { useGetUserMentionsWithoutAccess } from './useGetUserMentionsWithoutAccess';
export function Provider(props) {
    const { children } = props;
    const { loading, usersMentionedWithoutAccess } = useGetUserMentionsWithoutAccess();
    const value = {
        loading,
        hasMentionsWithoutAccess: usersMentionedWithoutAccess.length > 0,
        usersMentionedWithoutAccess,
    };
    return <Context.Provider value={value}>{children}</Context.Provider>;
}
