import { SidebarMenuCollapsibleItem } from '@pocketlaw/tetris';
import { useHistory } from 'react-router-dom';
import { useLinkMatch } from '../useLinkMatch';
export function CollapsibleItemLink(props) {
    const { to, label, matchPath, matchExact = false } = props;
    const active = useLinkMatch({ matchPath, matchExact });
    const history = useHistory();
    const handleClick = () => {
        history.push(to);
    };
    return <SidebarMenuCollapsibleItem label={label} active={active} onClick={handleClick}/>;
}
