import { Fragment } from 'react';
import { Trans } from '@lingui/macro';
import { useFileSystemAcls } from 'app/domains/filesystem';
import { AccessListItem } from './components/AccessListItem';
import { AddFileSystemAclsButton } from './components/AddFileSystemAclsButton';
import { StyledList, StyledTypography, TitleContainer } from './styled';
export function AccessList(props) {
    const { entryId } = props;
    const { usersWithAccess, teamsWithAccess } = useFileSystemAcls({ entryId });
    return (<Fragment>
      <TitleContainer>
        <StyledTypography $fontWeight="medium">
          <Trans comment="Filesystem access drawer list title">Users & teams with access</Trans>
        </StyledTypography>
        <AddFileSystemAclsButton entryId={entryId}/>
      </TitleContainer>
      <StyledList>
        {teamsWithAccess.map(({ id, name, numberOfTeamMembers, aclId, hasAccessToParentFolder }) => (<AccessListItem key={id} type="team" numberOfTeamMembers={numberOfTeamMembers} name={name} aclId={aclId} hasAccessToParentFolder={hasAccessToParentFolder}/>))}
        {usersWithAccess.map(({ id, name, role, roleName, aclId, isSelf, hasAccessToParentFolder }) => (<AccessListItem key={id} type="user" roleName={roleName} name={name} aclId={aclId} hasAccessToParentFolder={hasAccessToParentFolder && role !== 'owner'} isSelf={isSelf}/>))}
      </StyledList>
    </Fragment>);
}
