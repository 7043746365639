var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { DocumentAttachmentItem, useDocumentAttachments, usePleditor, } from 'shared/domains/documents';
export function Attachment(props) {
    const { attachment, disabled, embeddedCount, onDownload, onScrollToAttachment, onEmbed } = props;
    const { addToast } = useToast();
    const { updateAttachment, removeAttachment, isAttachmentLoading } = useDocumentAttachments();
    const { plugins } = usePleditor();
    const handleRemoveAttachment = () => __awaiter(this, void 0, void 0, function* () {
        var _a;
        try {
            yield removeAttachment(attachment.id);
            (_a = plugins.attachmentStore) === null || _a === void 0 ? void 0 : _a.remove(attachment.id);
        }
        catch (_b) {
            addToast({
                appearance: 'danger',
                title: t({
                    comment: 'Document attachments - error when removing attachment toast title',
                    message: 'Could not remove attachment',
                }),
            });
        }
    });
    const handleRenameAttachment = (newName) => __awaiter(this, void 0, void 0, function* () {
        var _c;
        try {
            yield updateAttachment({ attachmentId: attachment.id, name: newName });
            (_c = plugins.attachmentStore) === null || _c === void 0 ? void 0 : _c.update(Object.assign(Object.assign({}, attachment), { name: newName }));
        }
        catch (_d) {
            addToast({
                appearance: 'danger',
                title: t({
                    comment: 'Document attachments - error when renaming attachment toast title',
                    message: 'Could not rename attachment',
                }),
            });
        }
    });
    const handleDownloadAttachment = () => {
        onDownload(attachment.id);
    };
    const handleScrollToAttachment = () => {
        onScrollToAttachment(attachment.id);
    };
    return (<DocumentAttachmentItem embeddedCount={embeddedCount} disabled={disabled || isAttachmentLoading} attachment={attachment} onRemove={handleRemoveAttachment} onRename={handleRenameAttachment} onDownload={handleDownloadAttachment} onScrollToAttachment={handleScrollToAttachment} onEmbed={onEmbed}/>);
}
