import { pxToRem, themeGet } from '@pocketlaw/tetris';
import { css } from 'styled-components';
export const suggestionStyles = css `
  .ck.ck-annotation-wrapper {
    border-radius: ${themeGet('borderRadius.md')};

    .ck-suggestion-wrapper {
      border-radius: inherit;
      border: solid ${pxToRem(1)} ${themeGet('colors.gray.50')};
      border-left: none;
    }

    .ck-thread {
      color: ${themeGet('colors.text.300')};
      border-radius: inherit;
      background-color: inherit;
      border: solid ${pxToRem(1)} ${themeGet('colors.gray.50')};
      border-left: none;
    }
  }
`;
