import { useState } from 'react';
import { plural } from '@lingui/macro';
import { AvatarWithLabel, Avatar, IconButton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useFilesPreview } from '../../../hook';
import { getAllFolderItems, isFile } from '../../../utils';
import { Tree } from '../../Tree';
import { TreeBranch } from '../../TreeBranch';
import { AlignRight, Node, NodeContent } from '../styled';
export function FolderNode(props) {
    const { id, isLastItem } = props;
    const { getFolder, removeFolder } = useFilesPreview();
    const folder = getFolder(id);
    const [showChildren, setShowChildren] = useState(false);
    const handleToggleChildren = () => setShowChildren(!showChildren);
    const handleRemove = (event) => {
        event.stopPropagation();
        event.preventDefault();
        removeFolder(id);
    };
    if (!folder) {
        return null;
    }
    const { name, children } = folder;
    const childFiles = getAllFolderItems(folder).filter(isFile);
    const documentsLabel = plural(childFiles.length, {
        one: '# document',
        other: '# documents',
    });
    return (<Node>
      <TreeBranch />
      <NodeContent $folder onClick={handleToggleChildren}>
        <AvatarWithLabel size="md" appearance="primary" title={name} subtitle={documentsLabel}>
          <Avatar size="md" appearance="primary">
            <Falcon icon="folder"/>
          </Avatar>
        </AvatarWithLabel>
        <AlignRight>
          <IconButton $round size="small" appearance="ghost">
            {showChildren ? <Falcon icon="chevron-down"/> : <Falcon icon="chevron-up"/>}
          </IconButton>
          <IconButton $round size="small" appearance="ghost" onClick={handleRemove}>
            <Falcon icon="xmark"/>
          </IconButton>
        </AlignRight>
      </NodeContent>
      {showChildren && <Tree items={children} isLastItem={isLastItem}/>}
    </Node>);
}
