import { msg } from '@lingui/macro';
import { FileSystemDocumentStatus } from 'shared/domains/apollo/generated/types';
export function getDocumentStatusTranslations(value) {
    const translations = {
        [FileSystemDocumentStatus.AgreedStage]: msg({
            message: 'AgreedStage',
            comment: 'Document status label text',
        }),
        [FileSystemDocumentStatus.AgreedStageAgreedForm]: msg({
            message: 'AgreedStageAgreedForm',
            comment: 'Document status label text',
        }),
        [FileSystemDocumentStatus.DraftStage]: msg({
            message: 'DraftStage',
            comment: 'Document status label text',
        }),
        [FileSystemDocumentStatus.Inconsistent]: msg({
            message: 'Inconsistent',
            comment: 'Document status label text',
        }),
        [FileSystemDocumentStatus.ReviewStage]: msg({
            message: 'ReviewStage',
            comment: 'Document status label text',
        }),
        [FileSystemDocumentStatus.ReviewStageApproved]: msg({
            message: 'ReviewStageApproved',
            comment: 'Document status label text',
        }),
        [FileSystemDocumentStatus.ReviewStageCancelled]: msg({
            message: 'ReviewStageCancelled',
            comment: 'Document status label text',
        }),
        [FileSystemDocumentStatus.ReviewStagePending]: msg({
            message: 'ReviewStagePending',
            comment: 'Document status label text',
        }),
        [FileSystemDocumentStatus.ReviewStageRejected]: msg({
            message: 'ReviewStageRejected',
            comment: 'Document status label text',
        }),
        [FileSystemDocumentStatus.SigningStage]: msg({
            message: 'SigningStage',
            comment: 'Document status label text',
        }),
        [FileSystemDocumentStatus.SigningStageCancelled]: msg({
            message: 'SigningStageCancelled',
            comment: 'Document status label text',
        }),
        [FileSystemDocumentStatus.SigningStageFailed]: msg({
            message: 'SigningStageFailed',
            comment: 'Document status label text',
        }),
        [FileSystemDocumentStatus.SigningStagePending]: msg({
            message: 'SigningStagePending',
            comment: 'Document status label text',
        }),
        [FileSystemDocumentStatus.SigningStagePendingNotYetSigned]: msg({
            message: 'SigningStagePendingNotYetSigned',
            comment: 'Document status label text',
        }),
        [FileSystemDocumentStatus.SigningStagePendingPartiallySigned]: msg({
            message: 'SigningStagePendingPartiallySigned',
            comment: 'Document status label text',
        }),
        [FileSystemDocumentStatus.SigningStageRejected]: msg({
            message: 'SigningStageRejected',
            comment: 'Document status label text',
        }),
        [FileSystemDocumentStatus.SigningStageSigned]: msg({
            message: 'SigningStageSigned',
            comment: 'Document status label text',
        }),
        [FileSystemDocumentStatus.Unknown]: msg({
            message: 'Unknown',
            comment: 'Document status label text',
        }),
    };
    const translation = translations[value || FileSystemDocumentStatus.Unknown];
    return translation;
}
