import { themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const InformationContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${themeGet('spacing.lg')};
  padding: ${themeGet('spacing.lg')};

  box-shadow: ${themeGet('dropShadow.200')};
  border-radius: ${themeGet('borderRadius.md')};
  background-color: ${themeGet('colors.gray.0')};

  max-width: 20rem;
`;
