import { useQuery } from '@apollo/client';
import { subMonths } from 'date-fns';
import { localeConfig } from 'shared/domains/languages/config';
import { getAggregationBuckets } from './getAggregationBuckets';
import { getBucketValueByDate } from './getBucketValueByDate';
import { getDifferenceInPercent } from './getDifferenceInPercent';
import { getTrend } from './getTrend';
import { documentsCreatedLastTwoMonthsMetricsQuery } from './query';
const today = new Date();
const oneMonthAgo = subMonths(today, 1).toISOString();
const twoMonthsAgo = subMonths(today, 2).toISOString();
export function useDocumentsCreatedLastTwoMonthsMetrics() {
    const currentLocale = localeConfig.getActiveLocale();
    const { data, loading, error } = useQuery(documentsCreatedLastTwoMonthsMetricsQuery, { variables: { oneMonthAgo, twoMonthsAgo } });
    const buckets = getAggregationBuckets(data);
    const thisMonth = getBucketValueByDate(today, buckets) || 0;
    const lastMonth = getBucketValueByDate(subMonths(today, 1), buckets) || 0;
    const diffPercentage = getDifferenceInPercent(thisMonth, lastMonth, currentLocale.code);
    const trend = getTrend(thisMonth, lastMonth);
    return { thisMonth, lastMonth, diffPercentage, trend, loading, error };
}
