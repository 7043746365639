import { Trans, t } from '@lingui/macro';
import { DialogFooter } from '@pocketlaw/tetris';
import { SubmitButton, CancelButton } from 'shared/domains/forms';
import { BackButton } from './components/BackButton';
import { useWizardContext } from '../WizardContext';
export function FormFooter(props) {
    const { onClose } = props;
    const { isLastStep } = useWizardContext();
    const SubmitText = isLastStep
        ? t({ message: 'Invite', comment: 'invite user modal - submit button text' })
        : t({ message: 'Next', comment: 'invite user modal - next step button text' });
    return (<DialogFooter description={<BackButton />}>
      <CancelButton onClick={onClose}>
        <Trans>Cancel</Trans>
      </CancelButton>
      <SubmitButton>{SubmitText}</SubmitButton>
    </DialogFooter>);
}
