import { t } from '@lingui/macro';
import { ESignPollingState } from '../ESignPollingState';
import { OverlayLoader } from '../components/OverlayLoader';
export function ESignCancellingState(props) {
    const { polling = true } = props;
    const title = t({
        message: 'Cancelling e-signing',
        comment: 'Message when the document sent for for e-signing is being cancelled',
    });
    const message = t({
        message: 'Waiting for the e-signing provider. This may take a moment.',
        comment: 'Descriptive message when the document sent for for e-signing is being cancelled',
    });
    if (polling) {
        return <ESignPollingState title={title} message={message}/>;
    }
    return <OverlayLoader title={title} message={message}/>;
}
