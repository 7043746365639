import { TableBodyCell, Button, margin, padding, Typography, pxToRem } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const StyledTableCell = styled(TableBodyCell) `
  max-width: ${pxToRem(500)};
`;
export const Container = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const StyledButton = styled(Button) `
  flex: 1;
  padding-left: 0;
  padding-right: 0;
  justify-content: flex-start;
  ${margin(1, 0)};
  ${padding(1.25, 0)};

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const StyledCellText = styled(Typography) `
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
