import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  min-width: ${pxToRem(320)};
  width: 100%;
  margin: 0 auto;
`;
export const CardBody = styled.div `
  display: flex;
  flex-direction: column;
  margin-top: ${themeGet('spacing.xl')};
  margin-left: ${themeGet('spacing.xl')};
  margin-right: ${themeGet('spacing.xl')};
  margin-bottom: ${themeGet('spacing.md')};
  justify-content: left;
  overflow: hidden;
  min-height: ${pxToRem(120)};
  max-height: ${props => (props.$expanded ? pxToRem(600) : pxToRem(120))};
  transition: max-height 0.3s ease-in-out;
  word-wrap: break-word;

  &:hover {
    cursor: pointer;
  }
`;
export const CardContent = styled.div `
  border: solid ${pxToRem(1)} ${themeGet('colors.border.100')};
  border-radius: ${themeGet('borderRadius.md')};
  display: flex;
  flex-direction: column;
  width: 100%;

  &:hover {
    border-color: ${themeGet('colors.primary.1000')};
  }
`;
