var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { AvatarLetter, AvatarWithLabel } from '@pocketlaw/tetris';
/**
 * AvatarWithLabel with preset props for teams
 * Uses <AvatarLetter> based on the title's first character
 */
export function TeamAvatarWithLabel(props) {
    const { title } = props, restProps = __rest(props, ["title"]);
    const firstLetter = (title === null || title === void 0 ? void 0 : title.charAt(0).toUpperCase()) || '';
    return (<AvatarWithLabel appearance="magenta" title={title} {...restProps}>
      <AvatarLetter>{firstLetter}</AvatarLetter>
    </AvatarWithLabel>);
}
