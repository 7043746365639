import { Button, ListItem, Typography, themeGet } from '@pocketlaw/tetris';
import styled, { css } from 'styled-components';
export const StyledListItem = styled(ListItem) `
  justify-content: space-between;
`;
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${themeGet('spacing.xl')};
  padding: ${themeGet('spacing.xl')} ${themeGet('spacing.md')};
`;
export const ExpandButton = styled(Button) `
  margin-left: auto;
  margin-right: auto;
`;
export const Description = styled(Typography) `
  ${({ $expanded }) => !$expanded &&
    css `
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;
