import { t } from '@lingui/macro';
export const getTooltipFromReason = (reason) => {
    switch (reason) {
        case 'esigning:signed':
            return t({
                comment: 'Document locked chip - Document has been signed tooltip message',
                message: 'This document has been signed and cannot be edited.',
            });
        case 'esigning:pending':
            return t({
                comment: 'Document locked chip - Document has been sent for e-signing tooltip message',
                message: 'This document has been sent for eSigning and cannot be edited.',
            });
        case 'approval:approved':
            return t({
                comment: 'Document locked chip - Document has been approved tooltip message',
                message: 'This document has been approved and cannot be edited.',
            });
        case 'approval:pending':
            return t({
                comment: 'Document locked chip - Document has been asked for approval tooltip message',
                message: 'This document has been sent for approval and cannot be edited.',
            });
        default:
            return null;
    }
};
