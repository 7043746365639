import { useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Box, Button, ChatInput, IconButton, Tooltip } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { PromptLibraryModal, PromptLibraryProvider } from 'app/domains/prompt-library';
import { useFeatureFlag } from 'shared/domains/featureFlag';
import { Container } from './styled';
export function ChatBox(props) {
    const { userName, isStreaming, onSendMessage } = props;
    const [userMessage, setUserMessage] = useState('');
    const [promptLibraryOpen, setPromptLibraryOpen] = useState(false);
    const isPromptLibraryAvailable = useFeatureFlag('feature_prompt_library');
    const isSendDisabled = isStreaming || userMessage === '';
    const handleOpenPromptLibrary = () => setPromptLibraryOpen(true);
    const handleClosePromptLibrary = () => setPromptLibraryOpen(false);
    const handleSelectPrompt = (prompt) => {
        setUserMessage(prompt);
        handleClosePromptLibrary();
    };
    const sendMessage = () => {
        onSendMessage(userMessage);
        setUserMessage('');
    };
    const handleChange = (event) => {
        setUserMessage(event.target.value);
    };
    const handleKeyDown = (event) => {
        if (isSendDisabled) {
            return;
        }
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            sendMessage();
        }
    };
    return (<Container>
      {isPromptLibraryAvailable && promptLibraryOpen && (<PromptLibraryProvider defaultView="library" onSelect={handleSelectPrompt} onClose={handleClosePromptLibrary}>
          <PromptLibraryModal />
        </PromptLibraryProvider>)}
      <ChatInput autoFocus maxHeight={200} userName={userName} value={userMessage} onChange={handleChange} onKeyDown={handleKeyDown} placeholder={t({ message: 'Ask a question', comment: 'Review chat - textarea - label' })}/>
      <Box alignItems="center" justifyContent="space-between">
        {isPromptLibraryAvailable && (<Tooltip position="top" title={t({
                comment: 'Review chat - prompt library - button label',
                message: 'Access your prompt library',
            })}>
            <Button size="small" appearance="secondary" onClick={handleOpenPromptLibrary} aria-label={t({
                message: 'Send',
                comment: 'Review chat - prompt library - aria label',
            })}>
              <Falcon icon="book-bookmark"/>
              <Trans comment="Review chat - prompt library - button label">Prompts</Trans>
            </Button>
          </Tooltip>)}
        <IconButton size="small" appearance="primary" onClick={sendMessage} disabled={isSendDisabled} aria-label={t({
            message: 'Send',
            comment: 'Review chat - textarea - send button - aria label',
        })}>
          <Falcon icon="arrow-right"/>
        </IconButton>
      </Box>
    </Container>);
}
