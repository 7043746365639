import { t } from '@lingui/macro';
import { TasksIds } from '../types';
export const initialColumns = () => [
    {
        id: TasksIds.title,
        label: t({ message: 'Title', comment: 'Title column for task table toggle' }),
        active: true,
        disabled: true,
    },
    {
        id: TasksIds.status,
        label: t({ message: 'Status', comment: 'Status column for task table toggle' }),
        active: true,
        disabled: false,
    },
    {
        id: TasksIds.createdAt,
        label: t({ message: 'Created at', comment: 'Created at column for task table toggle' }),
        active: false,
        disabled: false,
    },
    {
        id: TasksIds.updatedAt,
        label: t({ message: 'Updated at', comment: 'updated at column for task table toggle' }),
        active: false,
        disabled: false,
    },
    {
        id: TasksIds.dueDate,
        label: t({ message: 'Due date', comment: 'Due date column for task table toggle' }),
        active: true,
        disabled: false,
    },
    {
        id: TasksIds.assignee,
        label: t({ message: 'Assignee', comment: 'Assignee column for task table toggle' }),
        active: true,
        disabled: false,
    },
];
