import { Falcon } from '@pocketlaw/tetris/macro';
import { ChatMessageType } from 'shared/domains/apollo/generated/types';
import { AssistantPromptButton } from '../../AssistantPromptButton';
import { useAssistant } from '../../AssistantProvider';
import { Container } from './styled';
/**
 * Suggestions are generated by the assistant
 * They differ from the AssistantComment as they are clickable, using AssistantPromptButton
 */
export function AssistantSuggestion(props) {
    const { message } = props;
    const { addComment, loading, clearSuggestions } = useAssistant();
    const handleClick = () => {
        addComment({
            message,
            type: ChatMessageType.DocumentAskWithQuotes,
        }, { onCompleted: () => clearSuggestions() });
    };
    if (!message) {
        return null;
    }
    return (<Container>
      <AssistantPromptButton leftIcon={<Falcon icon="question"/>} title={message} align="right" onClick={handleClick} disabled={loading}/>
    </Container>);
}
