import { gql } from '@apollo/client';
export const metadataFilesystemEntries = gql `
  query MetadataFilesystemEntries($ids: [ID!]) {
    fileSystemEntries(where: { id: { in: $ids }, kind: { neq: GUIDANCE } }) {
      id
      name
      type: kind
    }
  }
`;
