import { Text } from '@pocketlaw/tetris/deprecated';
import styled from 'styled-components';
import { highlightStyles, wordBreak } from 'app/domains/global-search/components/styled';
export const StyledText = styled(Text) `
  display: block;
  line-height: 1.4;
  ${wordBreak}

  mark {
    ${highlightStyles}
  }
`;
