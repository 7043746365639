import gql from 'graphql-tag';
export const QUERY = gql `
  query getCompanyPlanForEsigningProviders($companyId: UUID!) {
    company(id: $companyId) {
      id
      plans {
        id
        plan {
          id
          type
          ordinal
        }
      }
    }
  }
`;
