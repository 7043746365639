import { useState } from 'react';
import { Context } from './context';
export function MobileNavigationProvider(props) {
    const { children } = props;
    const [isOpen, setOpen] = useState(false);
    const open = () => setOpen(true);
    const close = () => setOpen(false);
    const value = { isOpen, open, close };
    return <Context.Provider value={value}>{children}</Context.Provider>;
}
