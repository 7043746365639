import { themeGetColor } from '@pocketlaw/tetris';
import { CartesianGrid as RechartCartesianGrid } from 'recharts';
export function CartesianGrid(props) {
    const { topPadding = 0, bottomPadding = 0, numberOfLines = 5 } = props;
    const horizontalCoordinatesGenerator = (gridProps) => {
        const { height, offset } = gridProps;
        const topGridLine = topPadding;
        const bottomGridLine = height - bottomPadding - (offset.bottom || 0);
        const gridHeight = bottomGridLine - topGridLine;
        const gridSpacing = gridHeight / (numberOfLines - 1);
        return Array.from({ length: numberOfLines }, (_, index) => topGridLine + index * gridSpacing);
    };
    return (<RechartCartesianGrid horizontalCoordinatesGenerator={horizontalCoordinatesGenerator} vertical={false} strokeDasharray="10 0" stroke={themeGetColor('border.100', 0.5)}/>);
}
