var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fragment, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Button, Badge, useToast } from '@pocketlaw/tetris';
import { useHistory } from 'react-router-dom';
import { PickFolderDialog } from 'shared/domains/common-ui';
import { useFeatureFlag } from 'shared/domains/featureFlag';
import { useBulkMoveFiles } from './useBulkMoveFiles';
import { useTableContext } from '../SearchResultsTable/TableContext';
import { useCreateActionEventHandlers } from '../SearchResultsTable/useCreateActionEventHandlers';
import { canMoveDocument } from '../SearchResultsTable/utils';
export function BulkMove() {
    const [open, setOpen] = useState(false);
    const { setActiveAction, selected, setSelected } = useTableContext();
    const { addToast } = useToast();
    const history = useHistory();
    const featureFlag = useFeatureFlag('feature_bulk_move_action');
    const handleSuccess = (entry) => {
        setOpen(false);
        setActiveAction(undefined);
        setSelected({ type: 'reset' });
        addToast({
            appearance: 'info',
            action: {
                label: t({ message: 'View folder', comment: 'Move documents success toast undo text' }),
                onClick: () => {
                    history.push(`/files/${entry.id}`);
                },
            },
            title: t({
                message: `Done!`,
                comment: 'Move documents success toast title',
            }),
            message: t({
                message: `Documents moved to ${entry.name}`,
                comment: 'Move documents success toast message',
            }),
        });
    };
    const handleError = () => {
        setActiveAction(undefined);
    };
    const { moveFiles, state } = useBulkMoveFiles({ onSuccess: handleSuccess, onError: handleError });
    const eventHandlers = useCreateActionEventHandlers('move-document', () => setOpen(true));
    const handleOnClose = () => {
        setOpen(false);
        setActiveAction(undefined);
    };
    const handleMoveDocuments = (entry) => __awaiter(this, void 0, void 0, function* () {
        moveFiles(entry, selected);
    });
    const selectedCount = selected.length;
    const eligibleDocuments = selected.filter(canMoveDocument);
    const eligibleDocumentsCount = eligibleDocuments.length;
    const dialogTitle = t({
        message: 'Move documents',
        comment: 'Bulk action: Move dialog title',
    });
    const dialogSubtitle = t({
        message: 'Select a folder to move the documents to',
        comment: 'Bulk action: Move dialog subtitle',
    });
    const getLoaderOverlayOptions = () => {
        if (state === 'moving') {
            return {
                loading: true,
                title: t({
                    message: 'Moving files',
                    comment: 'Bulk move files: Loading label',
                }),
                description: t({
                    message: 'Please wait while we are moving your files',
                    comment: 'Bulk move files: Loading description',
                }),
            };
        }
        if (state === 'synchronizing') {
            return {
                loading: true,
                title: t({
                    message: 'Synchronizing files',
                    comment: 'Bulk move files: Validating bulk move and synchronizing label',
                }),
                description: t({
                    message: 'Please wait while we are synchronizing your files',
                    comment: 'Bulk move files: Validating bulk move and synchronizing description',
                }),
            };
        }
        return undefined;
    };
    if (!featureFlag) {
        return null;
    }
    return (<Fragment>
      <Button type="button" disabled={!selectedCount} size="small" appearance="secondary" {...eventHandlers}>
        <Trans comment="Bulk action: move">Move</Trans>
        {eligibleDocumentsCount > 0 && <Badge value={eligibleDocumentsCount} size="small"/>}
      </Button>
      {open && (<PickFolderDialog dialogTitle={dialogTitle} onSubmit={handleMoveDocuments} onClose={handleOnClose} submitLabel={t({ message: 'Move', comment: 'Bulk action: Move button label' })} dialogSubtitle={dialogSubtitle} loaderOverlay={getLoaderOverlayOptions()}/>)}
    </Fragment>);
}
