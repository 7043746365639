import { t } from '@lingui/macro';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Container, SpinnerIcon, SpinnerWrapper, SpinnerBackground, SpinnerForeground, StyledTypographyTitle, StyledTypographyMessage, } from './styled';
export function OverlayLoader(props) {
    const { title, message, overlay = false } = props;
    const fallbackTitle = t({
        message: 'Loading...',
        comment: 'Message when the document assistant is initiating',
    });
    return (<Container $overlay={overlay}>
      <SpinnerWrapper>
        <SpinnerIcon>
          <Falcon icon="hourglass"/>
        </SpinnerIcon>
        <SpinnerBackground />
        <SpinnerForeground />
      </SpinnerWrapper>
      <StyledTypographyTitle>{title || fallbackTitle}</StyledTypographyTitle>
      {message && <StyledTypographyMessage $fontSize="small">{message}</StyledTypographyMessage>}
    </Container>);
}
