var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { TaskStatusEnumEnum } from 'shared/domains/apollo/generated/types';
import { mutation } from './mutation';
function useToggleCompleteState() {
    const [updateMutation, { loading }] = useMutation(mutation);
    const { addToast } = useToast();
    const mutate = (options) => __awaiter(this, void 0, void 0, function* () {
        const { id, isCompleted, status } = options;
        const completed = isCompleted || status === TaskStatusEnumEnum.Completed;
        const newState = completed
            ? { isCompleted: false, status: TaskStatusEnumEnum.Todo }
            : { isCompleted: true, status: TaskStatusEnumEnum.Completed };
        try {
            yield updateMutation({
                variables: Object.assign({ id }, newState),
            });
            const finishedTask = t({
                message: 'Task finished',
                comment: 'Toast title when task is completed',
            });
            const unfinishedTask = t({
                message: 'Task set to unfinished',
                comment: 'Toast title when task is marked unfinished',
            });
            const toastTitle = completed ? unfinishedTask : finishedTask;
            addToast({
                title: toastTitle,
                appearance: 'success',
            });
        }
        catch (error) {
            addToast({
                title: t `An error occurred`,
                appearance: 'danger',
            });
        }
    });
    return {
        mutate,
        loading,
    };
}
export { useToggleCompleteState };
