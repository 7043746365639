import { Fragment, useState } from 'react';
import { UPLOAD_DOCUMENT_SUPPORTED_MIME_TYPES } from 'app/domains/upload';
import { UploadFileSystemDocumentProvider, useUploadFileSystemDocument, } from './UploadFileSystemDocumentProvider';
import { UploadDocumentDialog } from '../UploadDocumentDialog';
import { Input } from './styled';
export { UploadFileSystemDocumentProvider, useUploadFileSystemDocument };
/**
 * This component will expose functionality to easily trigger a `fileSystemEntry` file upload.
 */
export function UploadFileSystemDocument(props) {
    const { onCreated, onClose, withRedirect = true } = props;
    const { ref } = useUploadFileSystemDocument();
    const [selectedFile, setSelectedFile] = useState();
    const handleOnChange = (event) => {
        const { files } = event.target;
        if (files === null || files === void 0 ? void 0 : files.length) {
            const multipleFiles = Array.from(files);
            const [file] = multipleFiles;
            setSelectedFile(file);
        }
        else {
            setSelectedFile(undefined);
        }
        // eslint-disable-next-line no-param-reassign
        event.target.value = '';
    };
    const clearFiles = () => setSelectedFile(undefined);
    const handleOnClose = () => {
        clearFiles();
        if (onClose) {
            onClose();
        }
    };
    const handleOnCreated = (response) => {
        clearFiles();
        if (onCreated) {
            onCreated(response);
        }
    };
    const displayDialog = Boolean(selectedFile);
    const acceptFiles = UPLOAD_DOCUMENT_SUPPORTED_MIME_TYPES.join(',');
    return (<Fragment>
      <Input type="file" readOnly name="filesystemUpload" onChange={handleOnChange} ref={ref} accept={acceptFiles} tabIndex={-1}/>
      {displayDialog && (<UploadDocumentDialog files={[selectedFile]} onClose={handleOnClose} onCreated={handleOnCreated} withRedirect={withRedirect}/>)}
    </Fragment>);
}
