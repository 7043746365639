import { t } from '@lingui/macro';
import { Chip, TableBodyCell } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { SignRequestStatus } from 'shared/domains/apollo/generated/types';
import { EmptyCell } from './EmptyCell';
const STATES_WITHOUT_STATUS = [
    SignRequestStatus.Cancelling,
    SignRequestStatus.Cancelled,
    SignRequestStatus.Creating,
];
const getIconFromStatus = (status) => {
    switch (status) {
        case SignRequestStatus.Opened:
        case SignRequestStatus.Pending:
            return <Falcon icon="circle"/>;
        case SignRequestStatus.Failed:
        case SignRequestStatus.Rejected:
            return <Falcon icon="circle-xmark"/>;
        case SignRequestStatus.Unknown:
            return <Falcon icon="circle-exclamation"/>;
        case SignRequestStatus.Signed:
            return <Falcon icon="circle-check"/>;
        default:
            return <Falcon icon="circle-exclamation"/>;
    }
};
const getAppearanceFromStatus = (status) => {
    switch (status) {
        case SignRequestStatus.Opened:
        case SignRequestStatus.Pending:
            return 'info';
        case SignRequestStatus.Failed:
        case SignRequestStatus.Rejected:
            return 'danger';
        case SignRequestStatus.Unknown:
            return 'warning';
        case SignRequestStatus.Signed:
            return 'success';
        default:
            return 'secondary';
    }
};
export function StatusCell(props) {
    const { status } = props;
    const headers = 'filesystemtable-status';
    const statusLabels = new Map([
        [SignRequestStatus.Opened, t({ message: `Pending`, comment: 'Filesystem esigning status' })],
        [SignRequestStatus.Pending, t({ message: `Pending`, comment: 'Filesystem esigning status' })],
        [SignRequestStatus.Rejected, t({ message: `Declined`, comment: 'Filesystem esigning status' })],
        [SignRequestStatus.Signed, t({ message: `Signed`, comment: 'Filesystem esigning status' })],
        [SignRequestStatus.Failed, t({ message: `Failed`, comment: 'Filesystem esigning status' })],
        [SignRequestStatus.Unknown, t({ message: `Unknown`, comment: 'Filesystem esigning status' })],
    ]);
    if (!status || STATES_WITHOUT_STATUS.includes(status)) {
        return <EmptyCell headers={headers}/>;
    }
    const statusLabel = statusLabels.get(status);
    return (<TableBodyCell headers={headers}>
      <Chip variant="outline" label={statusLabel} icon={getIconFromStatus(status)} appearance={getAppearanceFromStatus(status)}/>
    </TableBodyCell>);
}
