var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { TextInput, Menu, MenuBody, MenuHeader, defaultFloatingPositionMiddleware, parentWidth, MenuList, MenuItem, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useField } from 'formik';
import { useFuse } from 'app/domains/search/hooks/useFuse';
import { HeaderCloseButton } from 'shared/domains/forms/components/Autocomplete';
import { ErrorMessage } from 'shared/domains/forms/components/ErrorMessage';
import { useGetSignatoryValues } from './useGetSignatoryValues';
export function Autocomplete(props) {
    var _a;
    const { signatoryKey, name, label, onAutoFill } = props;
    const signatoryValues = useGetSignatoryValues();
    const fuse = useFuse(signatoryValues.data, {
        keys: [signatoryKey],
    });
    const [field, meta] = useField(name);
    const { value, onChange } = field, rest = __rest(field, ["value", "onChange"]);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOnChange = (e) => {
        fuse.search(e.target.value);
        onChange(e);
        if (!open) {
            setOpen(true);
        }
    };
    const handleAutofill = (signatoryValue) => {
        onAutoFill(signatoryValue);
        handleClose();
    };
    const error = Boolean(meta.touched && meta.error);
    const suggestions = fuse.result;
    const showSuggestions = Boolean(open && (suggestions === null || suggestions === void 0 ? void 0 : suggestions.length) && field.value);
    const autoFillGlyph = ((_a = signatoryValues.data) === null || _a === void 0 ? void 0 : _a.length) ? (<Falcon icon="rectangle-list" $color="gray.600"/>) : undefined;
    return (<Fragment>
      <Menu open={showSuggestions} target={<TextInput stretch label={label} autoComplete="off" onFocus={handleOpen} invalid={error} adornmentRight={autoFillGlyph} onChange={handleOnChange} value={value} {...rest}/>} onClose={handleClose} options={{
            middleware: [...defaultFloatingPositionMiddleware, parentWidth()],
        }}>
        <MenuHeader title={t `Previously used`} action={<HeaderCloseButton onClick={handleClose}>
              <Trans>Close</Trans>
            </HeaderCloseButton>}/>
        <MenuBody>
          <MenuList>
            {suggestions.map(item => (<MenuItem key={item.id} onClick={() => handleAutofill(item)}>
                {item[signatoryKey]}
              </MenuItem>))}
          </MenuList>
        </MenuBody>
      </Menu>
      <ErrorMessage name={name}/>
    </Fragment>);
}
