import { useMutation } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { deleteTeam } from './mutation';
export function useDeleteTeam() {
    const { addToast } = useToast();
    const [mutate, { error, loading }] = useMutation(deleteTeam, {
        onCompleted: () => {
            addToast({
                title: t({
                    message: 'Team successfully deleted!',
                    comment: 'success toast title when team was removed',
                }),
                appearance: 'success',
            });
        },
        onError: () => {
            addToast({
                title: t({
                    message: 'Something went wrong, try again',
                    comment: 'error toast title when team failed to be removed',
                }),
                appearance: 'danger',
            });
        },
        update(cache, { data }) {
            const { id } = (data === null || data === void 0 ? void 0 : data.deleteTeam) || {};
            if (id) {
                cache.modify({
                    fields: {
                        teams(existing = [], { readField }) {
                            return existing.filter((teamRef) => id !== readField('id', teamRef));
                        },
                    },
                });
            }
        },
    });
    const removeTeam = (id) => mutate({ variables: { id } });
    return { loading, error, removeTeam };
}
