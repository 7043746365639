import { Trans } from '@lingui/macro';
import { ButtonGroup, ToggleButton } from '@pocketlaw/tetris';
import { useAssistant, getAssistantContextLabel, AssistantContext, AssistantViewType, } from 'app/domains/assistant';
import { Container } from './styled';
export function AssistantContextSwitcher() {
    const { changeContext, context, view, updateView } = useAssistant();
    return (<Container>
      <ButtonGroup>
        {Object.values(AssistantContext).map(value => (<ToggleButton key={value} $stretch $toggled={context === value} appearance="primary" onClick={() => {
                updateView(AssistantViewType.Dashboard);
                changeContext(value);
            }}>
            {getAssistantContextLabel(value)}
          </ToggleButton>))}
        <ToggleButton $stretch appearance="primary" $toggled={view === AssistantViewType.Conversations} onClick={() => updateView(AssistantViewType.Conversations)}>
          <Trans comment="Document assistant context - conversations">Conversations</Trans>
        </ToggleButton>
      </ButtonGroup>
    </Container>);
}
