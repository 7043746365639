var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useLazyQuery } from '@apollo/client';
import { htmlToPlast } from 'shared/domains/documents';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { QUERY } from './query';
export function useGetDocumentRenderInput() {
    var _a, _b;
    const account = useAccount();
    const companyId = (_b = (_a = account.data) === null || _a === void 0 ? void 0 : _a.company) === null || _b === void 0 ? void 0 : _b.id;
    const [query] = useLazyQuery(QUERY);
    const getDocumentRenderInput = (id) => __awaiter(this, void 0, void 0, function* () {
        const response = yield query({
            variables: {
                companyId,
                id,
            },
        });
        const { fileSystemDocument, company } = response.data || {};
        const { logoUploaded, logoPath } = company || {};
        const { strippedHtmlBody } = fileSystemDocument || {};
        const body = yield htmlToPlast(strippedHtmlBody || '');
        const logoUrl = logoUploaded && logoPath ? `${process.env.CDN_URL}/${logoPath}` : undefined;
        return {
            body,
            logoUrl,
        };
    });
    return getDocumentRenderInput;
}
