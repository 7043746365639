import { useQuery } from '@apollo/client';
import { useActiveDocument } from 'shared/domains/documents';
import { getSignRequestAttachments } from './query';
export const useGetSignRequestAttachments = () => {
    var _a, _b;
    const { id } = useActiveDocument();
    const { data, loading, error } = useQuery(getSignRequestAttachments, {
        variables: {
            id,
        },
    });
    const attachments = ((_b = (_a = data === null || data === void 0 ? void 0 : data.document) === null || _a === void 0 ? void 0 : _a.lastSignRequest) === null || _b === void 0 ? void 0 : _b.attachments) || [];
    return {
        loading,
        error,
        attachments,
    };
};
