import { Fragment, useState } from 'react';
import { Trans } from '@lingui/macro';
import { IconButton, Menu, MenuItem, MenuDivider, MenuList, MenuBody } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { ESignSignatoryFormDialog } from 'app/domains/esigning/components/ESignSignatoryFormDialog';
import { ConfirmDeleteDialog } from './ConfirmDeleteDialog';
import { useDefaultSignatories } from '../../useDefaultSignatories';
export function FormDefaultSignatoryActions(props) {
    const { signatory } = props;
    const { signatories, editSignatory, removeSignatory } = useDefaultSignatories();
    const [renderMenu, setShowMenu] = useState(false);
    const [renderEdit, setShowEdit] = useState(false);
    const [renderRemove, setShowRemove] = useState(false);
    const showMenu = () => setShowMenu(true);
    const hideMenu = () => setShowMenu(false);
    const showEdit = () => setShowEdit(true);
    const hideEdit = () => setShowEdit(false);
    const showRemove = () => setShowRemove(true);
    const hideRemove = () => setShowRemove(false);
    const handleEdit = () => {
        showEdit();
        hideMenu();
    };
    const handleRemove = () => {
        showRemove();
        hideMenu();
    };
    const handleConfirmRemove = () => {
        removeSignatory(signatory);
        hideRemove();
    };
    return (<Fragment>
      {renderEdit && (<ESignSignatoryFormDialog type="DefaultSignatory" initialValues={signatory} allSignatories={signatories} onClose={hideEdit} onSubmit={editSignatory}/>)}
      {renderRemove && (<ConfirmDeleteDialog onCancel={hideRemove} onConfirm={handleConfirmRemove}/>)}
      <Menu open={renderMenu} onClose={hideMenu} target={<IconButton $round size="small" appearance="ghost" onClick={showMenu}>
            <Falcon icon="ellipsis-v"/>
          </IconButton>}>
        <MenuBody>
          <MenuList>
            <MenuItem onClick={handleEdit}>
              <Falcon icon="pen"/>
              <Trans comment="Label for editing signing contact">Edit</Trans>
            </MenuItem>
            <MenuDivider />
            <MenuItem onClick={handleRemove}>
              <Falcon icon="trash-can"/>
              <Trans comment="Label for removing signing contact">Remove</Trans>
            </MenuItem>
          </MenuList>
        </MenuBody>
      </Menu>
    </Fragment>);
}
