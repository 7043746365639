import { List, Typography, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const TitleContainer = styled.div `
  display: flex;
  flex-direction: row;
  padding: 0 ${themeGet('spacing.xl')};
  align-items: center;
`;
export const StyledList = styled(List) `
  padding-left: ${themeGet('spacing.xl')};
  padding-right: ${themeGet('spacing.xl')};
`;
export const StyledTypography = styled(Typography) `
  flex: 1;
`;
