import { useMutation } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { deleteTeamMemberMutation } from './deleteTeamMemberMutation';
export function useDeleteTeamMember() {
    const successMessage = t({
        message: 'Member successfully removed from the team',
        comment: 'team member removed from a team successfully toast',
    });
    const errorMessage = t({
        message: 'Something went wrong, try again',
        comment: 'team member removed from a team error toast',
    });
    const [mutate, { loading, error }] = useMutation(deleteTeamMemberMutation);
    const { addToast } = useToast();
    const deleteTeamMember = (params) => {
        const { memberId, teamId } = params;
        mutate({
            variables: {
                memberId,
                teamId,
            },
            update(cache) {
                cache.modify({
                    id: cache.identify({ id: memberId, __typename: 'TeamMember' }),
                    fields(fieldValue, { DELETE }) {
                        return DELETE;
                    },
                });
            },
            onCompleted() {
                addToast({
                    title: successMessage,
                    appearance: 'success',
                });
            },
            onError() {
                addToast({
                    title: errorMessage,
                    appearance: 'danger',
                });
            },
        });
    };
    return { deleteTeamMember, loading, error };
}
