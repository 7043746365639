import { t } from '@lingui/macro';
import { ButtonGroup, Skeleton } from '@pocketlaw/tetris';
import { ClearFilter } from './components/ClearFilter';
import { MultiselectOperator } from './components/MultiselectOperator';
import { NullOperator } from './components/NullOperator';
import { OperatorSelector } from './components/OperatorSelector';
import { useFilter } from './useFilter';
/**
 * Filter to use for SearchStringFilter typeof filters.
 */
export function SearchStringFilter(props) {
    const { items, label, loading = false, name } = props;
    const filter = useFilter(name);
    return (<Skeleton loading={loading}>
      <ButtonGroup>
        <OperatorSelector name={name} label={label} operators={[
            {
                id: 'in',
                menuItem: t({ message: 'Is', comment: 'Search: Filter operator - is' }),
                label: t({ message: 'is', comment: 'Search: Filter operator - is' }),
                onSelect: () => { var _a; return filter.setValue({ in: (_a = filter.value) === null || _a === void 0 ? void 0 : _a.notIn }); },
            },
            {
                id: 'notIn',
                menuItem: t({ message: 'Is not', comment: 'Search: Filter operator - is not' }),
                label: t({ message: 'is not', comment: 'Search: Filter operator - is not' }),
                onSelect: () => { var _a; return filter.setValue({ notIn: (_a = filter.value) === null || _a === void 0 ? void 0 : _a.in }); },
            },
            {
                id: 'isNull',
                menuItem: t({ message: 'Has value', comment: 'Search: Filter operator - has value' }),
                label: t({ message: 'has', comment: 'Search: Filter operator - has value' }),
                onSelect: () => filter.setValue({ isNull: undefined }),
            },
        ]}/>

        {!loading && (filter.operator === 'in' || filter.operator === 'notIn') && (<MultiselectOperator items={items} name={name}/>)}

        {!loading && filter.operator === 'isNull' && <NullOperator name={name}/>}

        <ClearFilter name={name}/>
      </ButtonGroup>
    </Skeleton>);
}
