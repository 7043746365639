import { useCallback } from 'react';
import { pxToRem, themeGet, themeGetColor, Tooltip } from '@pocketlaw/tetris';
import styled from 'styled-components';
import { useMarkdown } from './MarkdownProvider';
const CONTAINER_PX_SIZE = 16;
const Container = styled.sup `
  display: inline-flex;
  align-items: center;
  justify-content: center;
  top: ${pxToRem(-4)};
  width: ${pxToRem(CONTAINER_PX_SIZE)};
  height: ${pxToRem(CONTAINER_PX_SIZE)};
  font-weight: ${themeGet('fontWeight.bold')};
  font-size: ${themeGet('fontSize.tiny')};
  border-radius: ${pxToRem(CONTAINER_PX_SIZE / 2)};
  background-color: ${themeGet('colors.assistant.900')};
  color: ${themeGetColor('text.light')};

  & ~ & {
    margin-left: ${pxToRem(4)};
  }
`;
export function Superscript(props) {
    const { 'data-footnote-definition': sourceQuote, children } = props;
    const { handleFootnoteClick } = useMarkdown();
    const handleClick = useCallback(() => {
        if (sourceQuote && handleFootnoteClick) {
            handleFootnoteClick(sourceQuote);
        }
    }, [handleFootnoteClick, sourceQuote]);
    if (sourceQuote) {
        return (<Tooltip title={sourceQuote} width={200}>
        <Container onClick={handleClick}>{children}</Container>
      </Tooltip>);
    }
    return <span>{children}</span>;
}
