import { useState } from 'react';
import { Trans } from '@lingui/macro';
import { IconButton, Menu, MenuBody, MenuItem, MenuList } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
function AttachmentDropDown(props) {
    const { onEdit, onDelete } = props;
    const [open, setOpen] = useState(false);
    const handleCallback = (callback) => () => {
        setOpen(false);
        callback();
    };
    return (<Menu open={open} onClose={() => setOpen(false)} target={<IconButton size="xs" appearance="ghost" $round onClick={() => setOpen(!open)}>
          <Falcon icon="ellipsis-v"/>
        </IconButton>}>
      <MenuBody>
        <MenuList>
          <MenuItem onClick={handleCallback(onEdit)}>
            <Falcon icon="arrows-rotate"/>
            <Trans comment="Attachment dropdown menu replace document">Replace</Trans>
          </MenuItem>
          <MenuItem onClick={handleCallback(onDelete)}>
            <Falcon icon="remove"/>
            <Trans comment="Attachment dropdown menu remove document">Remove</Trans>
          </MenuItem>
        </MenuList>
      </MenuBody>
    </Menu>);
}
export { AttachmentDropDown };
