var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createContext, useContext, useState } from 'react';
import { useGetPlanId } from 'app/domains/companies';
import { useRole } from 'app/domains/users/hooks/useRole';
import { ComposerAccessForm } from './ComposerAccessForm';
import { InviteUserForm, InviteUserFormSchema } from './InviteUserForm';
import { Summarize } from './Summarize';
const Context = createContext(undefined);
export const useWizardContext = () => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error('useWizardContext must be used within a provider.');
    }
    return context;
};
const steps = [
    {
        component: InviteUserForm,
        schema: InviteUserFormSchema,
        access: ['admin', 'owner'],
    },
    {
        component: ComposerAccessForm,
        access: ['owner'],
    },
    {
        component: Summarize,
        access: ['admin', 'owner'],
    },
];
function filterStepsOnRole(role) {
    return steps.filter(step => step.access.includes(role));
}
export function WizardProvider(props) {
    const { children, values } = props;
    const { onSubmit } = values, rest = __rest(values, ["onSubmit"]);
    const planId = useGetPlanId();
    const role = useRole();
    const currentUserRole = role.is();
    const [step, setStep] = useState(0);
    const wizardSteps = filterStepsOnRole(currentUserRole);
    const isLastStep = step >= wizardSteps.length - 1;
    const handleNextStep = (formValues, handlers) => __awaiter(this, void 0, void 0, function* () {
        if (!isLastStep) {
            setStep(step + 1);
            return;
        }
        onSubmit(formValues, handlers);
    });
    const handlePrevStep = () => {
        if (step > 0) {
            setStep(step - 1);
        }
    };
    return (<Context.Provider value={Object.assign(Object.assign({}, rest), { step, steps: wizardSteps, isLastStep,
            handleNextStep,
            handlePrevStep,
            planId })}>
      {children}
    </Context.Provider>);
}
