import { useLazyQuery } from '@apollo/client';
import { metadataFilesystemEntriesSearch } from './query';
export function useSearchQuery() {
    const [searchQuery, { error, loading, data, previousData, variables, called }] = useLazyQuery(metadataFilesystemEntriesSearch, {});
    const handleSearch = (str) => searchQuery({ variables: { query: str } });
    const { search } = data || {};
    const { results } = search || {};
    const searchResult = results || (previousData === null || previousData === void 0 ? void 0 : previousData.search.results);
    const isSearching = Boolean(called && (variables === null || variables === void 0 ? void 0 : variables.query));
    return {
        search: handleSearch,
        result: searchResult,
        isSearching,
        error,
        loading,
    };
}
