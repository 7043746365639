import { t } from '@lingui/macro';
import { Button } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useFormikContext } from 'formik';
import { useAssistant } from 'app/domains/assistant';
export function SubmitButton() {
    const { isValid } = useFormikContext();
    const { cannotSubmitUserMessage } = useAssistant();
    const disabled = !isValid || cannotSubmitUserMessage;
    return (<Button type="submit" appearance="ai" disabled={disabled} title={t({
            comment: 'Document assistant chat - title of submit question button',
            message: 'Send',
        })} $stretch>
      <Falcon icon="arrow-right"/>
    </Button>);
}
