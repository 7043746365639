import { t } from '@lingui/macro';
import { SwitchButtonView, Plugin } from 'pleditor';
import { HighlightIcon } from './icons';
import { getIsOnValue, setIsOnValue } from './utils';
export class ComposedHighlightToolbar extends Plugin {
    static get pluginName() {
        return 'ComposedHighlightToolbar';
    }
    static get requires() {
        return ['ComposedHighlight'];
    }
    constructor(editor) {
        super(editor);
        this.button = new SwitchButtonView();
    }
    init() {
        this.button.on('change:isOn', (_evt, _propertyName, newValue) => {
            this.setValue(newValue);
        });
        this.toolbarItem();
    }
    setValue(isOn) {
        var _a;
        setIsOnValue(isOn);
        (_a = this.button) === null || _a === void 0 ? void 0 : _a.set({ isOn });
        this.editor.editing.view.change(writer => {
            this.editor.editing.view.document.roots.forEach(root => {
                if (isOn) {
                    writer.addClass('pleditor-composer-highlight', root);
                }
                else {
                    writer.removeClass('pleditor-composer-highlight', root);
                }
            });
        });
    }
    toolbarItem() {
        const { editor, button } = this;
        const defaultIsOn = this.editor.config.get('composerHighlightsInitialIsOn');
        const isOn = defaultIsOn !== undefined ? defaultIsOn : getIsOnValue();
        editor.ui.componentFactory.add('toggleComposedHighlights', () => {
            button.set({
                label: t({
                    message: 'Toggle highlights',
                    comment: 'Pleditor - Toggle composer highlights',
                }),
                tooltip: true,
                icon: HighlightIcon,
                isOn,
                class: 'pl-icon-toggle-button',
            });
            button.on('execute', () => {
                button.isOn = !button.isOn;
            });
            return button;
        });
    }
}
