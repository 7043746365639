import { Trans, t } from '@lingui/macro';
import { Button, EmptyState } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { usePlai } from 'app/domains/review';
export function ReviewFailedState() {
    const { review, requestReview } = usePlai();
    const handleClick = () => {
        if (review === null || review === void 0 ? void 0 : review.playbookId) {
            requestReview(review.playbookId);
        }
    };
    return (<EmptyState withAvatar icon={<Falcon icon="circle-exclamation"/>} title={t({
            message: 'Something went wrong',
            comment: 'Review drawer - Review error state - title',
        })} description={t({
            message: 'There was an error while generating the review for this document, please try again.',
            comment: 'Review drawer - Review error state - description',
        })} actions={<Button onClick={handleClick}>
          <Trans comment="Review drawer - Review error state - start review button label">
            Try again
          </Trans>
        </Button>}/>);
}
