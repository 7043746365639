import { SignStatusEnumEnum } from 'shared/domains/apollo/generated/types';
import { MimeTypesByExtension } from 'shared/domains/filesystem/utils/fileExtensions';
export const UNASSIGNED_SIGNING_ORDER = -1;
export const PROVIDER_IDS = {
    SCRIVE: '3eedcbaf-af27-47e6-a938-7ac200a153d0',
    DOCUSIGN: 'bb2f4618-ba66-490d-a30f-d31863b94127',
    POCKETLAW: '28f16c5a-5981-47cd-a891-9b2dcf21b35e',
};
export const ACTIVE_SIGN_REQUEST_STATUSES = [
    SignStatusEnumEnum.Signed,
    SignStatusEnumEnum.Pending,
    SignStatusEnumEnum.Opened,
    SignStatusEnumEnum.Creating,
];
export const ESIGNING_PL_FILES_ATTACHMENT_SUPPORTED_MIME_TYPES = [
    MimeTypesByExtension.Pdf,
    MimeTypesByExtension.Docx,
];
export const ESIGNING_UPLOAD_ATTACHMENT_SUPPORTED_MIME_TYPES = [MimeTypesByExtension.Pdf];
export const ESIGNING_SENDOUT_SUPPORTED_MIME_TYPES = [
    MimeTypesByExtension.Pdf,
    MimeTypesByExtension.Docx,
];
