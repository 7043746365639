import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const CellContent = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${themeGet('spacing.md')};
  max-width: ${pxToRem(135)};
  cursor: pointer;
`;
export const NotesTextContainer = styled.div `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const PopoverContentContainer = styled.div `
  display: flex;
  flex-direction: column;
  position: fixed;
  max-height: 100vh;
  overflow-y: auto;
  z-index: 1;
  max-width: ${pxToRem(244)};
  padding: ${themeGet('spacing.xl')};

  box-shadow: ${themeGet('dropShadow.200')};
  border-radius: ${themeGet('borderRadius.md')};
  background-color: ${themeGet('colors.gray.0')};
`;
