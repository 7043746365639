import { gql } from '@apollo/client';
export const GetDefaultSignatoriesQuery = gql `
  query DefaultSignatories($documentId: ID!) {
    fileSystemDocument(id: $documentId) {
      defaultSignatories {
        composerId
        signatories {
          id
          email
          firstName
          lastName
          label
          title
          mobile
          createdAt
          updatedAt
          authenticationMode
          order
        }
        ccRecipients {
          email
          firstName
          lastName
          label
          createdAt
          updatedAt
        }
      }
    }
  }
`;
