import { gql } from '@apollo/client';
export const createUploadedDocumentMutation = gql `
  mutation createUploadedDocument($document: FileSystemDocumentInsertInput!) {
    document: createFileSystemDocument(input: $document) {
      id
      parentId
      categoryId
      name
      date: updatedAt
      depth
      draftId
      marketCode
      signed
      type
      kind

      acl: acls {
        id
        userId
        actions
        user {
          id
          name
          email
        }
      }

      user {
        id
        name
        email
      }

      lastSignRequest {
        id
        status
      }
    }
  }
`;
