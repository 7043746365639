import { t } from '@lingui/macro';
import { EntryKind } from 'shared/domains/apollo/generated/types';
export function getBreadcrumbs(filesystem) {
    var _a, _b, _c;
    if (!filesystem) {
        return [];
    }
    const workspace = {
        id: '',
        name: t({ message: 'Files', comment: 'Files breadcrumbs root title' }),
        kind: 'WORKSPACE',
    };
    const tree = (filesystem.ancestors &&
        ((_a = [...filesystem.ancestors]) === null || _a === void 0 ? void 0 : _a.sort((a, b) => (a.depth || 0) - (b.depth || 0)).map(ancestor => ({
            id: ancestor.id,
            name: ancestor.name,
            kind: EntryKind.Folder,
        })))) ||
        [];
    const currentFolder = {
        id: (_b = filesystem === null || filesystem === void 0 ? void 0 : filesystem.folder) === null || _b === void 0 ? void 0 : _b.id,
        name: (_c = filesystem === null || filesystem === void 0 ? void 0 : filesystem.folder) === null || _c === void 0 ? void 0 : _c.name,
        kind: EntryKind.Folder,
    };
    const breadcrumbs = [workspace, ...tree];
    if (currentFolder.id) {
        breadcrumbs.push(currentFolder);
    }
    return breadcrumbs.filter(Boolean);
}
