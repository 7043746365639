import { Fragment } from 'react';
import { t } from '@lingui/macro';
import { Box, ChipAction, IconButton, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useField } from 'formik';
import { ChipDropdown } from 'shared/domains/common-ui';
import { allowedPropertiesTranslations } from './translations';
import { Breakdown } from '../Breakdown';
import { SelectDataPoint } from '../SelectDataPoint';
import { FieldInputContainer } from '../styled';
export function TermsInput(props) {
    var _a;
    const { pathBase, remove, isSubAggregation = false } = props;
    const currentPath = `${pathBase}.termsInput`;
    const [field, , helpers] = useField(currentPath);
    const hasMissingValue = Boolean(field.value.missing);
    const size = ((_a = field.value) === null || _a === void 0 ? void 0 : _a.size) || 0;
    const sizeToDisplay = hasMissingValue ? size - 1 : size;
    const disableDecrement = sizeToDisplay <= 1;
    const handleIncrement = () => helpers.setValue(Object.assign(Object.assign({}, field.value), { size: size + 1 }));
    const handleDecrement = () => {
        const newSize = size - 1;
        if (newSize) {
            helpers.setValue(Object.assign(Object.assign({}, field.value), { size: newSize }));
        }
    };
    return (<Fragment>
      <FieldInputContainer>
        <SelectDataPoint label={t({ message: 'Field', comment: 'Insights widget form: Terms Field' })} selectedValue={allowedPropertiesTranslations(field.value.field)} name={pathBase} action={<ChipAction onClick={remove}>
              <Falcon icon="close"/>
            </ChipAction>}/>
        <ChipDropdown label={t({ message: 'Config', comment: 'Insights widget form: Terms configuration' })} secondary={sizeToDisplay || undefined} appearance="secondary" variant="outline" dropdownContent={<Box p="md" alignItems="center" gap="lg">
              <IconButton appearance="secondary" size="small" onClick={handleDecrement} disabled={disableDecrement} title={t({
                message: 'Decrement entries',
                comment: 'Insights widget form: Terms configuration - decrement entries',
            })}>
                <Falcon icon="minus"/>
              </IconButton>
              <Typography>{sizeToDisplay}</Typography>
              <IconButton appearance="secondary" size="small" onClick={handleIncrement} title={t({
                message: 'Increment entries',
                comment: 'Insights widget form: Terms configuration - increment entries',
            })}>
                <Falcon icon="plus"/>
              </IconButton>
            </Box>}/>
      </FieldInputContainer>

      {!isSubAggregation && <Breakdown pathBase={currentPath}/>}
    </Fragment>);
}
