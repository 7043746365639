import { Typography, pxToRem, themeGet, unit, themeGetColor } from '@pocketlaw/tetris';
import styled, { css, keyframes } from 'styled-components';
const SPINNER_SIZE = pxToRem(72);
const SPINNER_WIDTH = pxToRem(6);
const SPINNER_COLOR = themeGet('colors.primary.500');
const spinAnimation = keyframes `
  100% {
    transform: rotate(360deg);
  }
`;
const containerOverlayStyle = css `
  z-index: 1;
  position: absolute;
  backdrop-filter: blur(0.2rem);
  background-color: ${themeGetColor('primary.50', 0.56)};
`;
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${unit('lg')};
  width: 100%;
  height: 100%;
  padding: ${unit('2xl')};
  text-align: center;
  background-color: ${themeGet('colors.primary.50')};

  ${({ $overlay }) => $overlay && containerOverlayStyle};
`;
export const StyledTypographyTitle = styled(Typography) `
  color: ${themeGet('colors.indigo.900')};
`;
export const StyledTypographyMessage = styled(Typography) `
  color: ${themeGet('colors.indigo.800')};
`;
export const SpinnerWrapper = styled.div `
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${SPINNER_SIZE};
  height: ${SPINNER_SIZE};
`;
export const SpinnerIcon = styled.div `
  position: absolute;
  width: ${pxToRem(30)};
  height: ${pxToRem(30)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${SPINNER_COLOR};

  [data-icon='falcon'] {
    color: ${themeGet('colors.background.50')};
  }
`;
const Spinner = styled.div `
  position: absolute;
  width: ${SPINNER_SIZE};
  height: ${SPINNER_SIZE};
  border-radius: 50%;
  border-style: solid;
  border-width: ${SPINNER_WIDTH};
`;
export const SpinnerBackground = styled(Spinner) `
  border-color: rgba(0, 0, 0, 0.1);
`;
export const SpinnerForeground = styled(Spinner) `
  border-color: ${SPINNER_COLOR} transparent transparent transparent;
  animation: ${spinAnimation} 1.15s infinite ease-in-out;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: ${SPINNER_WIDTH};
    width: ${SPINNER_WIDTH};
    height: ${SPINNER_WIDTH};
    background: ${SPINNER_COLOR};
    border-radius: 50%;
  }

  &:before {
    left: 0;
    transform: translate(75%, -50%);
  }

  &:after {
    right: 0;
    transform: translate(-50%, -25%);
  }
`;
