import { TableBodyCell, Avatar } from '@pocketlaw/tetris';
import { getIcon, getAppearance } from './getIconStyle';
export function IconCell(props) {
    const { type, disabled } = props;
    const icon = getIcon(type);
    const appearance = disabled ? 'secondary' : getAppearance(type);
    return (<TableBodyCell headers="folderPickerIcon">
      <Avatar size="md" appearance={appearance}>
        {icon}
      </Avatar>
    </TableBodyCell>);
}
