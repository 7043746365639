import { Avatar, CardContent, Metric, MetricTitle } from '@pocketlaw/tetris';
import { localeConfig } from 'shared/domains/languages/config';
import { MetricCardTitle } from './components/MetricCardTitle';
import { StyledCard, Container } from './styled';
export function MetricCard(props) {
    const { value, title, suffix, icon, tooltip, to } = props;
    const currentLocale = localeConfig.getActiveLocale();
    const numberFormat = new Intl.NumberFormat(currentLocale.code);
    return (<StyledCard>
      <CardContent>
        <Container>
          <Avatar size="md" appearance="secondary" shape="circle">
            {icon}
          </Avatar>
          <Metric title={<MetricCardTitle to={to}>
                <MetricTitle>{title}</MetricTitle>
              </MetricCardTitle>} value={numberFormat.format(value)} suffix={suffix} tooltip={tooltip}/>
        </Container>
      </CardContent>
    </StyledCard>);
}
