import { useApolloClient } from '@apollo/client';
import { DocumentType } from 'shared/domains/apollo/generated/types';
import { SetDocumentTypeDocument } from './query.gql';
export const useSetDocumentTypeToFreeText = () => {
    const { cache } = useApolloClient();
    const setDocumentTypeToFreeText = (documentId) => {
        cache.writeQuery({
            query: SetDocumentTypeDocument,
            data: {
                fileSystemDocument: {
                    __typename: 'FileSystemDocument',
                    id: documentId,
                    type: DocumentType.Freetext,
                },
            },
        });
    };
    return { setDocumentTypeToFreeText };
};
