import { unit } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${unit('lg')};
`;
export const ItemsContainer = styled.ul `
  display: flex;
  flex-direction: column;
  gap: inherit;
`;
