import { paddingBottom, marginBottom, pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Item = styled.div `
  ${paddingBottom(4)};

  &:not(:last-child) {
    ${marginBottom(4)};
  }

  border-bottom: solid ${pxToRem(1)} ${themeGet('colors.gray.100')};
`;
export const TitleContainer = styled.div `
  display: flex;
  align-items: center;

  ${marginBottom(2)};
`;
export const ActionsContainer = styled.div `
  margin-left: auto;
`;
