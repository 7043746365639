import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { transformThread } from 'shared/domains/feedback/utils/transformThread';
import { query } from './query';
import { getUniqueThreads } from './utils';
const isExternalApp = () => process.env.WEB_CLIENT === 'external';
function useGetThreads(options) {
    const { excludeUserId, id } = options || {};
    const { id: paramId } = useParams();
    const { data, error, loading, refetch } = useQuery(query, {
        variables: {
            documentId: paramId || id,
        },
    });
    const { externalSharingInvites = [] } = data || {};
    const threads = externalSharingInvites.flatMap(invite => {
        const { invitee, inviter, messageThreads = [], documentId, id: inviteId } = invite || {};
        const inviteInterlocutors = messageThreads.map(thread => transformThread({ thread, inviteId, documentId }));
        const inviterAdded = inviteInterlocutors.find(interlocutor => inviter && inviter.id === interlocutor.interlocutor.id);
        const inviteeAdded = inviteInterlocutors.find(interlocutor => invitee && invitee.id === interlocutor.interlocutor.id);
        const isExternal = isExternalApp();
        if (isExternal && inviter && !inviterAdded && inviter.id !== excludeUserId) {
            inviteInterlocutors.push({
                interlocutor: inviter,
                unreadMessagesCount: 0,
                messages: [],
                documentId,
                inviteId,
            });
        }
        if (!isExternal && invitee && !inviteeAdded && invitee.id !== excludeUserId) {
            inviteInterlocutors.push({
                interlocutor: invitee,
                unreadMessagesCount: 0,
                messages: [],
                documentId,
                inviteId,
            });
        }
        return inviteInterlocutors;
    });
    const uniqueThreads = getUniqueThreads(threads);
    return {
        data: uniqueThreads,
        error,
        loading,
        refetch,
    };
}
export default useGetThreads;
