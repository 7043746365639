import { gql } from '@apollo/client';
export const QUERY = gql `
  query documentRenderInputForEsigningAttachment($id: ID!, $companyId: UUID!) {
    fileSystemDocument: fileSystemDocument(id: $id) {
      id
      strippedHtmlBody
    }
    company(id: $companyId) {
      id
      logoPath: logo_path
      logoUploaded: logo_uploaded
    }
  }
`;
