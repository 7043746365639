import { plural } from '@lingui/macro';
import { Alert } from '@pocketlaw/tetris';
import { useField } from 'formik';
export function PermissionAlert() {
    const [permissionField] = useField('addDocumentPermission');
    const permissions = permissionField.value.length;
    const message = plural(permissions, {
        one: '# user does not have access to this document. By assigning them this task, they will be given access.',
        other: '# users do not have access to this document. By assigning them this task, they will be given access.',
    });
    return <Alert appearance="warning" message={message}/>;
}
