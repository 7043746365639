const PATH_PROPERTY = 'webkitRelativePath';
const getUpdatedPath = (path, name, extension) => path.slice(0, path.lastIndexOf('/') + 1) + name + extension;
export const updateFile = (previewFile, fileName) => {
    const { name, type, path, extension, originalFile } = previewFile;
    const { lastModified } = originalFile;
    const updatedName = fileName || name;
    const updatedOriginalFile = new File([originalFile], updatedName + extension, {
        type,
        lastModified,
    });
    const updatedPath = path ? getUpdatedPath(path, updatedName, extension) : path;
    Object.defineProperty(updatedOriginalFile, PATH_PROPERTY, {
        writable: true,
        value: updatedPath,
    });
    return Object.assign(Object.assign({}, previewFile), { originalFile: updatedOriginalFile, name: updatedName, path: updatedPath });
};
