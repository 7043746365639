var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/no-array-index-key */
import { Fragment } from 'react';
import { useTetrisTheme } from '@pocketlaw/tetris';
import { useFieldErrors } from '../../hooks/useFieldError';
import { FieldMessage } from '../FieldMessage';
function ErrorMessage(props) {
    const { name, show } = props, rest = __rest(props, ["name", "show"]);
    const { themeGet } = useTetrisTheme();
    const errors = useFieldErrors(name);
    const hasError = Boolean(errors.length);
    return (<FieldMessage {...rest} color={themeGet('colors.red.500')} show={show || hasError}>
      {errors.map((error, i) => (<Fragment key={i}>{error}</Fragment>))}
    </FieldMessage>);
}
export { ErrorMessage };
