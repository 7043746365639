import { gql } from '@apollo/client';
export const moveDocumentEntryMutation = gql `
  mutation moveDocumentEntryMutation($id: ID!, $parentId: ID) {
    entries: updateFileSystemDocument(id: $id, input: { parentId: $parentId }) {
      id
      parentId
      categoryId
      name
      date: updatedAt
      depth
      acl: acls {
        id
        userId
        actions
        user {
          id
          name
          email
        }
      }

      draftId
      signed

      lastSignRequest {
        id
        status
      }
    }
  }
`;
export const moveFolderEntryMutation = gql `
  mutation moveFolderEntryMutation($id: ID!, $parentId: ID) {
    entries: updateFileSystemFolder(id: $id, input: { parentId: $parentId }) {
      id
      parentId
      categoryId
      name
      date: updatedAt
      depth
      acl: acls {
        id
        userId
        actions
        user {
          id
          name
          email
        }
      }
    }
  }
`;
