import { gql } from '@apollo/client';
export const composersQuery = gql `
  query Composers($filters: Unstable__WhereComposerInput!) {
    composers: unstable__composers(where: $filters) {
      id
      estimatedTime
      marketCode

      companyComposers {
        id
      }

      metadata {
        id
        name
        description
        marketLocaleCode
      }

      category {
        id
        metadata {
          title
          marketLocaleCode: market_locale_code
        }
        themeId: theme_id
      }

      language: marketLocale {
        locale
        marketCode: market_code
      }

      composerPlans {
        id
        plan {
          id
        }
      }

      templates {
        id
        marketLocaleCode
      }
    }
  }
`;
