import { Trans } from '@lingui/macro';
import { Button } from '@pocketlaw/tetris';
import { useHistory } from 'react-router-dom';
export function ConfirmButton(props) {
    const { id } = props;
    const history = useHistory();
    const handleClick = () => {
        history.push(`/document/${id}/esigning`);
    };
    return (<Button onClick={handleClick} disabled={id === null}>
      <Trans comment="Dashboard: send for e-signing modal - select button">Select</Trans>
    </Button>);
}
