import { Trans } from '@lingui/macro';
import { Button, Column, Grid } from '@pocketlaw/tetris';
import { useNewAppRelease } from './useNewAppRelease';
import { Container, Content, Copy, Header, Title } from './styled';
export function AppVersionAlert() {
    const newAppRelease = useNewAppRelease();
    if (!newAppRelease) {
        return null;
    }
    return (<Container data-testid="new-app-version-alert" role="dialog" aria-modal="true">
      <Content>
        <Grid spacing={3}>
          <Column width="100%">
            <Header>
              <Title noMargin>
                <Trans comment="New app update alert Title">New Update available</Trans>
              </Title>
            </Header>
          </Column>
          <Column width="100%">
            <Copy>
              <Trans comment="New app update alert description">
                There is a new version available for the app. please reload the page
              </Trans>
            </Copy>
            <Button data-ui-event="New app version alert button" $stretch appearance="primary" onClick={() => {
            window.location.reload();
        }}>
              <Trans comment="Reload button action text">Reload</Trans>
            </Button>
          </Column>
        </Grid>
      </Content>
    </Container>);
}
