import { t } from '@lingui/macro';
import { Menu, MenuBody, defaultFloatingPositionMiddleware } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useField } from 'formik';
import { localizedFormatDate } from 'shared/domains/common-utils';
import { DropdownContent } from './DropdownContent';
import { MenuItems } from './MenuItems';
import { WarningAndErrors } from './WarningAndErrors';
import { LinkField } from '../../LinkField';
import { useCurrentDropdown } from '../hooks/useCurrentDropdown';
import { Container } from './styled';
export function RemainderDropdown() {
    const { currentDropdown, isOpen, closeDropdown, toggleDropdown } = useCurrentDropdown();
    const [dueDateField, , dueDateHelpers] = useField('reminder.dueDate');
    const [, , intervalHelpers] = useField('reminder.interval');
    const formatted = localizedFormatDate(dueDateField.value, {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    });
    const handleRemoveReminder = () => {
        dueDateHelpers.setValue('');
        intervalHelpers.setValue(undefined);
        closeDropdown();
    };
    return (<Menu open={isOpen} onClose={closeDropdown} options={{
            middleware: [...defaultFloatingPositionMiddleware],
        }} target={<Container>
          <LinkField name="reminder.dueDate" icon={<Falcon icon="alarm-clock"/>} onClick={toggleDropdown} onRemove={handleRemoveReminder} linkPrefix={t({
                message: 'Reminder will be sent on',
                comment: 'Task reminder field link text when reminder is set',
            })} linkText={formatted ||
                t({
                    message: 'Add reminder',
                    comment: 'Task Reminder field link text when no reminder is set',
                })}/>
          <WarningAndErrors />
        </Container>}>
      <MenuBody>
        {currentDropdown === 'calendar' && <DropdownContent />}
        {currentDropdown === 'simple' && <MenuItems />}
      </MenuBody>
    </Menu>);
}
