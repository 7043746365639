var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useEsigning } from 'app/domains/esigning/components/EsigningProvider';
import { useDocumentForEsigning } from 'app/domains/esigning/hooks/useDocumentForEsigning';
import { useEsigningLoadingState } from 'app/domains/esigning/hooks/useEsigningLoadingState';
import { useEsigningProviders } from 'app/domains/esigning/hooks/useEsigningProviders';
import { ApprovalPendingState } from '../ApprovalPendingState';
import { ApprovalRequestState } from '../ApprovalRequestState';
import { ESignCancellingState } from '../ESignCancellingState';
import { ESignConnectProvider } from '../ESignConnectProvider';
import { ESignCreatingState } from '../ESignCreatingState';
import { ESignDocumentNotSupportedState } from '../ESignDocumentNotSupportedState';
import { ESignFormState } from '../ESignFormState';
import { ESignLoadingProviderError } from '../ESignLoadingProviderError';
import { ESignPendingState } from '../ESignPendingState';
import { ESignSignedState } from '../ESignSignedState';
import { OverlayLoader } from '../components/OverlayLoader';
export const useComponent = () => {
    const { error: loadingConnectedProviderError, shouldConnectProvider } = useEsigningProviders();
    const { documentSupportsEsigning } = useDocumentForEsigning();
    const _a = useEsigning(), { approvalWorkflow } = _a, esigningData = __rest(_a, ["approvalWorkflow"]);
    const { signed, cancelling, creating, pending, submittingCancellation } = esigningData;
    const { approvalLoading, approvalNotRequested, approvalPending } = approvalWorkflow;
    const loading = useEsigningLoadingState();
    if (loading || approvalLoading) {
        return <OverlayLoader />;
    }
    if (!documentSupportsEsigning) {
        return <ESignDocumentNotSupportedState />;
    }
    if (signed) {
        return <ESignSignedState />;
    }
    if (loadingConnectedProviderError) {
        return <ESignLoadingProviderError />;
    }
    if (shouldConnectProvider) {
        return <ESignConnectProvider />;
    }
    if (cancelling) {
        return <ESignCancellingState />;
    }
    if (submittingCancellation) {
        return <ESignCancellingState polling={false}/>;
    }
    if (creating) {
        return <ESignCreatingState />;
    }
    if (pending) {
        return <ESignPendingState />;
    }
    if (approvalNotRequested) {
        return <ApprovalRequestState />;
    }
    if (approvalPending) {
        return <ApprovalPendingState />;
    }
    return <ESignFormState />;
};
