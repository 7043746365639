export function getDifferenceInPercent(thisMonthCount, previousMonthCount, locale) {
    const difference = thisMonthCount - previousMonthCount;
    const differenceInPercent = difference / previousMonthCount;
    const formattedDifferenceInPercent = Number.isNaN(differenceInPercent) ? 0 : differenceInPercent;
    const numberFormat = new Intl.NumberFormat(locale, {
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
    });
    return numberFormat.format(formattedDifferenceInPercent);
}
