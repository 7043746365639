import { Fragment, useEffect, useRef } from 'react';
import { t, Trans } from '@lingui/macro';
import { Loader, MenuHeader, MenuBody, MenuList, SearchInput } from '@pocketlaw/tetris';
import { UserItem } from '../UserItem';
import { useSearchUsers } from '../useSearchUsers';
import { useSelectUsersContext } from '../useSelectUsersContext';
import { CustomContainer, LoaderContainer } from './styled';
export function UserList() {
    const { acceptedUserRoles } = useSelectUsersContext();
    const { users, loading, handleReset, handleSearch, searchValue } = useSearchUsers(acceptedUserRoles);
    const searchInputRef = useRef(null);
    useEffect(() => {
        var _a;
        (_a = searchInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, []);
    if (loading) {
        return (<MenuBody>
        <MenuList>
          <LoaderContainer>
            <Loader size={8}/>
          </LoaderContainer>
        </MenuList>
      </MenuBody>);
    }
    return (<Fragment>
      <MenuHeader sticky>
        <SearchInput stretch variant="line" ref={searchInputRef} onChange={e => handleSearch(e.target.value)} value={searchValue} onReset={handleReset} placeholder={t({
            message: 'Search users..',
            comment: 'Search users input field placeholder text',
        })} resetLabel={t({
            message: 'Reset search',
            comment: 'Search users input field reset button label',
        })}/>
      </MenuHeader>

      <MenuBody>
        <MenuList>
          {users.length > 0 ? (users.map(user => <UserItem key={user.userId} user={user}/>)) : (<CustomContainer>
              <Trans comment="Message for not finding any users when searching">
                Could not find any users
              </Trans>
            </CustomContainer>)}
        </MenuList>
      </MenuBody>
    </Fragment>);
}
