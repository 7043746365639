import { useRef } from 'react';
import { t } from '@lingui/macro';
import { Box, IconButton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useSearchableMenu } from './SearchableMenuContext';
import { SearchInput, StyledMenuHeader } from './styled';
export function SearchableMenuSearchHeader(props) {
    const { placeholder } = props;
    const { searching, handleSearch, resetSearch, searchString, on } = useSearchableMenu();
    const inputRef = useRef(null);
    on('reset', () => { var _a; return (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus(); });
    const handleOnChange = (event) => handleSearch(event.target.value);
    const placeholderText = placeholder || t({ message: 'Search', comment: 'Searchable menu search input placeholder' });
    return (<StyledMenuHeader sticky>
      <Box alignItems="center" justifyContent="space-around" width="100%">
        <Falcon icon="magnifying-glass" $color="text.100"/>
        <SearchInput autoFocus placeholder={placeholderText} onChange={handleOnChange} value={searchString} ref={inputRef}/>
        <IconButton $round disabled={!searching} appearance="ghost" size="xs" onClick={resetSearch}>
          <Falcon icon="close"/>
        </IconButton>
      </Box>
    </StyledMenuHeader>);
}
