import { useReactiveVar } from '@apollo/client';
import { Trans, t } from '@lingui/macro';
import { Dialog, DialogHeader, DialogContent, DialogFooter, Button } from '@pocketlaw/tetris';
import { useGoBack } from './useErrorActions';
import { documentState } from '../../documentState';
export function CriticalError() {
    const state = useReactiveVar(documentState);
    const { goBack, reload } = useGoBack();
    if (state !== 'error') {
        return null;
    }
    return (<Dialog>
      <DialogHeader title={t({ message: 'Error', comment: 'Pleditor: Unrecoverable error title in dialog' })}/>
      <DialogContent>
        <Trans comment="Pleditor: Unrecoverable error text in dialog">
          A unrecoverable error occurred and we need to reload the document.
        </Trans>
      </DialogContent>
      <DialogFooter>
        <Button appearance="secondary" onClick={goBack}>
          <Trans comment="Pleditor: Unrecoverable error go back button">Go back</Trans>
        </Button>
        <Button appearance="danger" onClick={reload}>
          <Trans comment="Pleditor: Unrecoverable error reload button">Reload</Trans>
        </Button>
      </DialogFooter>
    </Dialog>);
}
