import { Fragment, useState } from 'react';
import { Trans } from '@lingui/macro';
import { Box } from '@pocketlaw/tetris';
import { FileBrowserProvider, ROOT } from 'app/domains/filesystem';
import { TemplateBrowserProvider } from 'app/domains/templates';
import { EntryKind } from 'shared/domains/apollo/generated/types';
import { PickerBreadcrumbs } from './PickerBreadcrumbs';
import { PickerDialog } from './PickerDialog';
import { PickerFooter } from './PickerFooter';
import { PickerHeader } from './PickerHeader';
import { PickerTableFilesystem } from './PickerTableFilesystem';
import { PickerTableTemplates } from './PickerTableTemplates';
import { ToggleTabs, ToggleTabsButtonsContainer, ToggleTabsButton, ToggleTabsContent, } from '../ToggleTabs';
export function PickFileTemplateDialog(props) {
    const { dialogTitle, dialogSubtitle, submitLabel, selfId, initialFolderId = ROOT, selectedEntryAlert, onClose, onSubmitDocument, onSubmitTemplate, } = props;
    const [activeTab, setActiveTab] = useState('filesystem');
    const [selectedDocument, setSelectedDocument] = useState();
    const [selectedTemplate, setSelectedTemplate] = useState();
    const showPagination = activeTab === 'filesystem';
    const showTemplates = onSubmitTemplate !== undefined;
    const disabled = (activeTab === 'filesystem' && !selectedDocument) ||
        (activeTab === 'templates' && !selectedTemplate);
    const toggleTabsSpacing = activeTab === 'filesystem' ? 'xl' : '2xl';
    const handleSubmit = () => {
        if (activeTab === 'filesystem' && selectedDocument) {
            onSubmitDocument(selectedDocument);
        }
        else if (activeTab === 'templates' && showTemplates && selectedTemplate) {
            onSubmitTemplate(selectedTemplate);
        }
    };
    const filesystemContent = (<Fragment>
      <PickerBreadcrumbs />
      <PickerTableFilesystem selectType="document" selectedEntryId={selectedDocument === null || selectedDocument === void 0 ? void 0 : selectedDocument.id} selectedEntryAlert={selectedEntryAlert}/>
    </Fragment>);
    const selectable = (entry) => entry.kind === EntryKind.Document;
    if (showTemplates) {
        return (<FileBrowserProvider customLoadingState showSignRequestStatus selectable={selectable} parentId={initialFolderId} onSelect={setSelectedDocument}>
        <TemplateBrowserProvider onSelect={setSelectedTemplate}>
          <PickerDialog>
            <PickerHeader title={dialogTitle} subtitle={dialogSubtitle} onClose={onClose}/>
            <ToggleTabs activeTab={activeTab} onSelectTab={setActiveTab}>
              <Box pl="2xl" pr="2xl" pb={toggleTabsSpacing}>
                <ToggleTabsButtonsContainer stretch size="small">
                  <ToggleTabsButton tabKey="filesystem">
                    <Trans comment="Documents label">Documents</Trans>
                  </ToggleTabsButton>
                  <ToggleTabsButton tabKey="templates">
                    <Trans comment="Templates label">Templates</Trans>
                  </ToggleTabsButton>
                </ToggleTabsButtonsContainer>
              </Box>
              <ToggleTabsContent tabKey="filesystem">{filesystemContent}</ToggleTabsContent>
              <ToggleTabsContent tabKey="templates">
                <PickerTableTemplates selectedTemplateId={selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.id}/>
              </ToggleTabsContent>
            </ToggleTabs>
            <PickerFooter showPagination={showPagination} submitLabel={submitLabel} disabled={disabled} onSubmit={handleSubmit}/>
          </PickerDialog>
        </TemplateBrowserProvider>
      </FileBrowserProvider>);
    }
    return (<FileBrowserProvider customLoadingState showSignRequestStatus selectable={selectable} selfId={selfId} parentId={initialFolderId} onSelect={setSelectedDocument}>
      <PickerDialog>
        <PickerHeader title={dialogTitle} subtitle={dialogSubtitle} onClose={onClose}/>
        {filesystemContent}
        <PickerFooter showPagination submitLabel={submitLabel} disabled={disabled} onSubmit={handleSubmit}/>
      </PickerDialog>
    </FileBrowserProvider>);
}
