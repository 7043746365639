/* eslint-disable lingui/t-call-in-function */
import { t } from '@lingui/macro';
export const getFeedbackLabel = (type) => {
    const feedbackLabelMap = {
        resolved: t({ comment: 'Plai review progress - legend key - resolved', message: 'Resolved' }),
        rejected: t({ comment: 'Plai review progress - legend key - discarded', message: 'Discarded' }),
        critical: t({ comment: 'Plai review progress - legend key - critical', message: 'Critical' }),
        notable: t({ comment: 'Plai review progress - legend key - notable', message: 'Notable' }),
        minor: t({ comment: 'Plai review progress - legend key - Minor', message: 'Minor' }),
    };
    return feedbackLabelMap[type];
};
