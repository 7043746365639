import { useTasks } from 'app/domains/tasks';
import { TaskStatusEnumEnum } from 'shared/domains/apollo/generated/types';
import { TasksIds } from '../../../types';
import { StyledTypography, StyledTableBodyCell } from './styled';
export function TitleCell(props) {
    const { title, taskId, completed, status } = props;
    const isCompleted = completed || status === TaskStatusEnumEnum.Completed;
    const { handleSelectTask } = useTasks();
    return (<StyledTableBodyCell headers={TasksIds.title} onClick={() => handleSelectTask(taskId)}>
      <StyledTypography $completed={isCompleted} $appearance="300">
        {title}
      </StyledTypography>
    </StyledTableBodyCell>);
}
