import { themeGet, themeGetColor } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Item = styled.div `
  padding: ${themeGet('spacing.sm')} ${themeGet('spacing.lg')};
  border-radius: ${themeGet('borderRadius.md')};

  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: ${themeGetColor('background.75')};
  }
`;
