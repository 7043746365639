import { canUseLocalStorage } from 'shared/domains/common-utils';
const LOCAL_STORAGE_KEY = 'pl.account_id';
function get() {
    if (canUseLocalStorage()) {
        const accountId = window.localStorage.getItem(LOCAL_STORAGE_KEY);
        if (accountId && typeof accountId === 'string') {
            return accountId;
        }
    }
    return null;
}
function set(id) {
    if (!canUseLocalStorage()) {
        return;
    }
    window.localStorage.setItem(LOCAL_STORAGE_KEY, id);
}
export const activeAccount = {
    get,
    set,
};
