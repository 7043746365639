import { gql } from '@apollo/client';
export const query = gql `
  query CategoriesForMetadata {
    categories {
      id
      metadata {
        id
        title
        marketLocaleCode: market_locale_code
      }
    }
  }
`;
