import { useState } from 'react';
import { useTetrisTheme } from '@pocketlaw/tetris';
import { PieChart, Pie } from 'recharts';
import { useMobile } from 'shared/domains/common-ui';
import { EmptyVisualization } from './EmptyVisualization';
import { Legend } from './Legend';
import { SliceInfo } from './SliceInfo';
import { useGetDefaultColorScale } from './useGetDefaultColorScale';
import { Container, ChartContainer } from './styled';
export function DoughnutChart(props) {
    const { data, onClick } = props;
    const { themeGet } = useTetrisTheme();
    const isMobile = useMobile();
    const [active, setActive] = useState();
    const handleSelect = (slice) => {
        const { id } = slice || {};
        setActive(id);
    };
    const handleOnClick = (slice) => {
        const { id } = slice || {};
        if (onClick && id) {
            onClick(id);
        }
    };
    const showLegend = data.total > 0 && !isMobile;
    const noData = [{ name: '', value: 1, fill: themeGet('colors.gray.50') }];
    const entries = useGetDefaultColorScale(data.entries);
    if (!data.total) {
        return <EmptyVisualization />;
    }
    return (<Container>
      <ChartContainer>
        <SliceInfo data={entries} active={active} total={data.total}/>
        <PieChart width={250} height={250} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
          <Pie dataKey="value" cy="50%" cx="50%" outerRadius="95%" data={data.total ? entries : noData} isAnimationActive={false} innerRadius="70%" onMouseOver={handleSelect} onMouseOut={() => handleSelect()} onClick={handleOnClick} minAngle={5}/>
        </PieChart>
      </ChartContainer>

      {showLegend && <Legend data={entries} onSelect={handleSelect} onClick={onClick}/>}
    </Container>);
}
