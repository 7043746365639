import { forwardRef } from 'react';
import { t } from '@lingui/macro';
import { Table, TableBody, LoaderOverlay, EmptyState } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { UserTableRow, UserTableHead, ErrorAlert } from './components';
import { useCompanyUserContext } from '../CompanyUserProvider';
import { HideMobileCol, ShowMobileCol } from './styled';
export const UsersTable = forwardRef((_, ref) => {
    const { companyUsers, loading, error, sort, setSort, noSearchResults } = useCompanyUserContext();
    if (loading) {
        return <LoaderOverlay />;
    }
    if (error) {
        return <ErrorAlert />;
    }
    if (noSearchResults) {
        return (<EmptyState withAvatar title={t({ message: 'No users found', comment: 'users table - empty search title' })} description={t({
                message: 'There are no users that match your current filter.',
                comment: 'users table - empty search subtitle',
            })} icon={<Falcon icon="search"/>}/>);
    }
    if (companyUsers.length < 1) {
        return (<EmptyState withAvatar title={t({ message: 'Add users', comment: 'users table - no users title' })} description={t({
                message: 'Start by inviting some new users in here.',
                comment: 'users table - no users subtitle',
            })} icon={<Falcon icon="person-circle-plus"/>}/>);
    }
    return (<Table ref={ref} sortId={sort.id} sortOrder={sort.order} onSort={(id, order) => setSort({ id, order })}>
      <colgroup>
        <ShowMobileCol width={1}/>
        <col width="auto"/>
        <HideMobileCol width="auto"/>
        <HideMobileCol width="auto"/>
        <col width={1}/>
      </colgroup>
      <UserTableHead />
      <TableBody>
        {companyUsers.map(user => (<UserTableRow key={user.id} {...user}/>))}
      </TableBody>
    </Table>);
});
