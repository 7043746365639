const localThemeSetup = (key) => {
    const getAppTheme = () => localStorage.getItem(key);
    const setAppTheme = (theme) => {
        localStorage.setItem(key, theme);
    };
    const getTetrisTheme = () => {
        const isSystemDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
        const appTheme = getAppTheme();
        if (!appTheme) {
            return 'light';
        }
        if (appTheme === 'system') {
            return isSystemDark ? 'dark' : 'light';
        }
        return appTheme;
    };
    return { getAppTheme, setAppTheme, getTetrisTheme };
};
export const localTheme = localThemeSetup('pl_theme');
