var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Trans, t } from '@lingui/macro';
import { Button, Dialog, useToast, DialogHeader, DialogContent, Typography, DialogFooter, Loader, } from '@pocketlaw/tetris';
import { useMetadataActions } from '../../hooks/useMetadataActions';
import { useDocumentMetadata } from '../DocumentMetadataProvider';
import { StyledAlert } from './styled';
export function VerifyAll(props) {
    const { open, close } = props;
    const { addToast } = useToast();
    const { data } = useDocumentMetadata();
    const { properties } = data;
    const pairsToVerify = properties.filter(({ pair }) => pair.needsVerification);
    const action = useMetadataActions();
    const disabled = action.loading;
    const handleVerifyAll = () => __awaiter(this, void 0, void 0, function* () {
        try {
            const pairIds = pairsToVerify.map(({ pair }) => pair.id);
            yield action.verify({
                pairIds,
            });
            addToast({
                title: t({
                    message: 'All metadata pairs verified',
                    comment: 'Metadata: Toast message when all metadata pairs are verified',
                }),
                appearance: 'success',
            });
            close();
        }
        catch (_a) {
            addToast({
                title: t({
                    message: 'Verifying metadata pairs failed',
                    comment: 'Metadata: Toast message when verifying metadata pairs fails',
                }),
                appearance: 'danger',
            });
        }
    });
    if (!open) {
        return null;
    }
    return (<Dialog onClose={close}>
      <DialogHeader title={t({
            message: 'Verify all metadata tags',
            comment: 'Dialog header when verifying all tags',
        })}/>
      <DialogContent>
        <Typography>
          <Trans comment="Metadata verify all: Information text">
            Are you sure you want to verify all metadata pairs?
          </Trans>
        </Typography>

        {action.error && (<StyledAlert appearance="warning" message={t({
                message: 'An error occurred when verifying metadata tags. Not all tags could be verified.',
                comment: 'Metadata: Error message when removing metadata tags fails',
            })}/>)}
      </DialogContent>
      <DialogFooter>
        <Button appearance="secondary" onClick={close} disabled={disabled}>
          <Trans comment="Metadata remove all: Cancel">Cancel</Trans>
        </Button>
        <Button appearance="primary" onClick={handleVerifyAll} disabled={disabled}>
          {action.loading && <Loader />}
          <Trans comment="Metadata remove all: Confirm">Verify all</Trans>
        </Button>
      </DialogFooter>
    </Dialog>);
}
