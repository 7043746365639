import { Fragment, useRef, useState, useLayoutEffect } from 'react';
import { Trans } from '@lingui/macro';
import { AvatarWithLabel, Box, Chip, IconButton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { getFeedbackChipAppearance, getFeedbackIcon, getFeedbackLabel, getFeedbackType, } from 'app/domains/review/utils';
import { Actions } from './Actions';
import { usePlai } from '../../PlaiProvider';
import { Container, Description, ExpandButton, StyledListItem } from './styled';
export function Feedback(props) {
    const { feedback } = props;
    const { activeFeedback, updateActiveFeedback } = usePlai();
    const [isTruncated, setIsTruncated] = useState(true);
    const descriptionRef = useRef(null);
    const feedbackType = getFeedbackType(feedback.feedback);
    const open = (activeFeedback === null || activeFeedback === void 0 ? void 0 : activeFeedback.id) === feedback.feedback.id && (activeFeedback === null || activeFeedback === void 0 ? void 0 : activeFeedback.open);
    const expanded = (activeFeedback === null || activeFeedback === void 0 ? void 0 : activeFeedback.id) === feedback.feedback.id && (activeFeedback === null || activeFeedback === void 0 ? void 0 : activeFeedback.expanded);
    const handleToggle = () => {
        if (open) {
            updateActiveFeedback(null);
        }
        else {
            updateActiveFeedback({
                id: feedback.feedback.id,
                open: true,
                expanded: false,
                feedback,
            });
        }
    };
    const handleExpand = () => {
        updateActiveFeedback({
            id: feedback.feedback.id,
            open: true,
            expanded: !expanded,
            feedback,
        });
    };
    useLayoutEffect(() => {
        if (open && !expanded) {
            const observeTruncate = () => {
                const { offsetHeight = 0, scrollHeight = 0 } = descriptionRef.current || {};
                setIsTruncated(offsetHeight < scrollHeight);
            };
            observeTruncate();
            window.addEventListener('resize', observeTruncate);
            return () => window.removeEventListener('resize', observeTruncate);
        }
        return undefined;
    }, [open, expanded]);
    return (<Fragment>
      <StyledListItem onClick={handleToggle} $selected={open}>
        <AvatarWithLabel size="md" shape="circle" appearance="light" title={feedback.feedback.name}>
          {getFeedbackIcon(feedbackType)}
        </AvatarWithLabel>
        <Box gap="md" alignItems="center">
          <Chip size="small" label={getFeedbackLabel(feedbackType)} appearance={getFeedbackChipAppearance(feedbackType)}/>
          <IconButton $round size="small" appearance="ghost" onClick={handleToggle}>
            {open ? <Falcon icon="chevron-up"/> : <Falcon icon="chevron-down"/>}
          </IconButton>
        </Box>
      </StyledListItem>
      {open && (<Container>
          <Description $appearance="300" $fontSize="medium" $expanded={expanded} ref={descriptionRef}>
            {feedback.feedback.content}
          </Description>
          {isTruncated && (<ExpandButton appearance="ghost" size="small" onClick={handleExpand}>
              {expanded ? (<Fragment>
                  <Trans comment="Plai review - issue description - button to collapse the description - label">
                    Collapse description
                  </Trans>
                  <Falcon icon="chevron-up"/>
                </Fragment>) : (<Fragment>
                  <Trans comment="Plai review - issue description - button to expand the description - label">
                    Full description
                  </Trans>
                  <Falcon icon="chevron-down"/>
                </Fragment>)}
            </ExpandButton>)}
          <Actions feedback={feedback}/>
        </Container>)}
    </Fragment>);
}
