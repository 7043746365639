import { Avatar, Typography } from '@pocketlaw/tetris';
import { Container } from '../styled';
export function PreviewText(props) {
    const { icon, children, empty = false } = props;
    return (<Container $empty={empty}>
      <Avatar size="sm" appearance="light">
        {icon}
      </Avatar>
      <Typography $appearance="300">{children}</Typography>
    </Container>);
}
