import { TableBodyCell } from '@pocketlaw/tetris';
import { Link } from 'react-router-dom';
import { TypeAvatarWithLabel } from 'shared/domains/common-ui';
export function NameCell(props) {
    const { to, title, type, headers } = props;
    return (<TableBodyCell headers={headers} maxWidth={400}>
      <Link to={to}>
        <TypeAvatarWithLabel truncate title={title} type={type}/>
      </Link>
    </TableBodyCell>);
}
