import { useApolloClient } from '@apollo/client';
import { ChatMessageRole, ChatMessageStatus } from 'shared/domains/apollo/generated/types';
import { ChatFragment, MessageFragment } from '../useGetLastDocumentChat';
const MESSAGE_TYPENAME = 'ChatMessage';
const CHAT_TYPENAME = 'DocumentChat';
const TEMPORARY_ID = 'temporary-caching';
const TEMPORARY_CHAT_ID = `${CHAT_TYPENAME}:${TEMPORARY_ID}`;
const TEMPORARY_USER_MESSAGE_ID = `${MESSAGE_TYPENAME}:${TEMPORARY_ID}-user`;
const TEMPORARY_ASSISTANT_MESSAGE_ID = `${MESSAGE_TYPENAME}:${TEMPORARY_ID}-assistant`;
const TEMPORARY_MESSAGES_ID = [TEMPORARY_USER_MESSAGE_ID, TEMPORARY_ASSISTANT_MESSAGE_ID];
export function useCacheOperations() {
    const { cache } = useApolloClient();
    const deleteTemporaryMessages = () => {
        TEMPORARY_MESSAGES_ID.forEach(id => {
            cache.evict({ id });
        });
    };
    const deleteTemporaryChat = () => {
        cache.evict({ id: TEMPORARY_CHAT_ID });
    };
    const deleteTemporaryMessageRefOnDocumentChat = (chatId) => {
        if (!chatId) {
            return;
        }
        cache.modify({
            id: `${CHAT_TYPENAME}:${chatId}`,
            fields: {
                messages(existing = []) {
                    return existing.filter((message) => 
                    // eslint-disable-next-line no-underscore-dangle
                    (message === null || message === void 0 ? void 0 : message.__ref) && TEMPORARY_MESSAGES_ID.indexOf(message.__ref) < 0);
                },
            },
        });
    };
    const clearTemporaryItems = (input) => {
        const { chatId } = input;
        deleteTemporaryMessageRefOnDocumentChat(chatId);
        deleteTemporaryChat();
        deleteTemporaryMessages();
        cache.gc();
    };
    const addTemporaryChat = () => cache.writeFragment({
        id: TEMPORARY_CHAT_ID,
        data: {
            id: TEMPORARY_ID,
            messages: [],
            resolved: true,
            resolutionStatus: null,
            __typename: CHAT_TYPENAME,
        },
        fragment: ChatFragment,
        fragmentName: 'ChatFragment',
    });
    const addTemporaryUserMessage = (input) => {
        const { message, type } = input;
        return cache.writeFragment({
            id: TEMPORARY_USER_MESSAGE_ID,
            data: {
                id: `${TEMPORARY_ID}-user`,
                role: ChatMessageRole.User,
                content: message || null,
                type,
                status: ChatMessageStatus.Resolved,
                streamToken: null,
                feedbackScore: null,
                __typename: MESSAGE_TYPENAME,
            },
            fragment: MessageFragment,
            fragmentName: 'MessageFragment',
        });
    };
    const addTemporaryAssistantMessage = (input) => {
        const { type } = input;
        return cache.writeFragment({
            id: TEMPORARY_ASSISTANT_MESSAGE_ID,
            data: {
                id: `${TEMPORARY_ID}-assistant`,
                role: ChatMessageRole.Assistant,
                content: null,
                type,
                status: ChatMessageStatus.Pending,
                streamToken: null,
                feedbackScore: null,
                __typename: MESSAGE_TYPENAME,
            },
            fragment: MessageFragment,
            fragmentName: 'MessageFragment',
        });
    };
    const addTemporaryMessagesRefOnDocumentChat = (input) => {
        const { chatId, temporaryMessages } = input;
        if (!chatId) {
            addTemporaryChat();
        }
        cache.modify({
            id: cache.identify({ id: chatId || TEMPORARY_ID, __typename: CHAT_TYPENAME }),
            fields: {
                messages(existing) {
                    if (temporaryMessages) {
                        return [...temporaryMessages, ...existing];
                    }
                    return existing;
                },
            },
        });
    };
    const addTemporaryMessages = (input) => {
        const { chatId } = input;
        const temporaryUserMessage = addTemporaryUserMessage(input);
        const temporaryAssistantAnswer = addTemporaryAssistantMessage({ type: input.type });
        const temporaryMessages = [temporaryAssistantAnswer, temporaryUserMessage].filter((message) => !!message);
        addTemporaryMessagesRefOnDocumentChat({
            chatId,
            temporaryMessages,
        });
    };
    return {
        addTemporaryMessages,
        clearTemporaryItems,
    };
}
