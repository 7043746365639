import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${themeGet('spacing.lg')};
`;
export const TreeContainer = styled.div `
  width: 100%;
  padding: ${themeGet('spacing.md')};
  border-radius: ${themeGet('borderRadius.md')};
  border: solid ${pxToRem(1)} ${themeGet('colors.gray.100')};
`;
