var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import { sanitize, defaultSchema } from 'hast-util-sanitize';
import { toMdast, defaultHandlers } from 'hast-util-to-mdast';
import rehypeDomParse from 'rehype-dom-parse';
import rehypeRemark from 'rehype-remark';
import { unified } from 'unified';
import { visit, CONTINUE } from 'unist-util-visit';
/**
 * Replace a node at a specific index
 * @param parent Parent node
 * @param node The new child node
 * @param index The index of the child node in the array of children
 */
function replaceNodeAtIndex(parent, node, index) {
    if (parent && Array.isArray(parent.children)) {
        // eslint-disable-next-line no-param-reassign
        parent.children[index] = node;
    }
}
/** List element transformer */
function listTransformer() {
    return (root) => {
        visit(root, 'list', (node, index, parent) => {
            const { id, type, children, ordered = false } = node;
            const newNode = { type, children, ordered };
            if (id) {
                Object.assign(newNode, { id });
            }
            replaceNodeAtIndex(parent, newNode, index);
            return CONTINUE;
        });
    };
}
const hastProcessor = unified().use(rehypeDomParse).use(rehypeRemark);
const plastProcessor = unified().use(listTransformer);
const schema = Object.assign(defaultSchema, {
    clobberPrefix: undefined,
});
if (Array.isArray((_a = schema === null || schema === void 0 ? void 0 : schema.attributes) === null || _a === void 0 ? void 0 : _a['*'])) {
    schema.attributes['*'].push('className');
}
const isHtmlPageBreak = (element) => {
    var _a, _b, _c;
    return element.type === 'element' &&
        element.tagName === 'div' &&
        Array.isArray((_a = element.properties) === null || _a === void 0 ? void 0 : _a.className) &&
        ((_c = (_b = element.properties) === null || _b === void 0 ? void 0 : _b.className) === null || _c === void 0 ? void 0 : _c.includes('page-break'));
};
export function htmlToPlast(html) {
    return __awaiter(this, void 0, void 0, function* () {
        const hast = hastProcessor().parse(html);
        const sanitizedHast = sanitize(hast, schema);
        /**
         * Convert to MDAST and preserve all potential ID's on the nodes
         */
        const mdast = toMdast(sanitizedHast, {
            handlers: Object.keys(defaultHandlers).reduce((handlers, handler) => (Object.assign(Object.assign({}, handlers), { [handler]: (state, element) => {
                    if (isHtmlPageBreak(element)) {
                        return {
                            type: 'pageBreak',
                        };
                    }
                    const { properties } = element;
                    const { id } = properties || {};
                    const defaultHandler = defaultHandlers[handler];
                    const mdastNode = defaultHandler(state, element);
                    if (id && mdastNode) {
                        Object.assign(mdastNode, {
                            id,
                        });
                    }
                    return mdastNode;
                } })), {}),
        });
        const plast = yield plastProcessor().run(mdast);
        return plast;
    });
}
