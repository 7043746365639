import { themeGet } from '@pocketlaw/tetris';
import styled, { css } from 'styled-components';
export const Node = styled.li `
  position: relative;
  display: flex;
  flex-direction: column;
`;
export const AlignRight = styled.div `
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: ${themeGet('spacing.md')};
`;
export const NodeContent = styled.div `
  display: flex;
  align-items: center;
  padding: ${themeGet('spacing.sm')};
  border-radius: ${themeGet('borderRadius.md')};
  transition: background-color 150ms ease-in-out;

  ${({ $file }) => $file &&
    css `
      &:hover {
        background-color: ${themeGet('colors.gray.50')};
      }
    `}

  ${({ $folder }) => $folder &&
    css `
      cursor: pointer;

      &:hover {
        background-color: ${themeGet('colors.blue.50')};
      }
    `}
`;
