import { useMutation } from '@apollo/client';
import { ReviewFeedbackStatus } from 'shared/domains/apollo/generated/types';
import { DISCARD_FEEDBACK_MUTATION } from './mutation';
export function useDiscardFeedback() {
    const [mutate] = useMutation(DISCARD_FEEDBACK_MUTATION);
    const discardFeedback = (feedbackId) => mutate({
        variables: { feedbackId },
        optimisticResponse: {
            rejectReviewFeedback: {
                __typename: 'ReviewFeedback',
                id: feedbackId,
                status: ReviewFeedbackStatus.Discarded,
            },
        },
    });
    return { discardFeedback };
}
