import { t } from '@lingui/macro';
import { useTaskActions } from '../../hooks/useTaskActions';
import { TaskDialog } from '../TaskDialog';
import { useTasks } from '../TasksProvider';
export function EditTask() {
    const { handleCancelEdit, activeTask, isEditing } = useTasks();
    const { initialValues, handleUpdate, error } = useTaskActions({
        task: activeTask,
        onClose: handleCancelEdit,
    });
    if (!activeTask || !isEditing) {
        return null;
    }
    return (<TaskDialog title={t({ message: 'Task details', comment: 'Edit task modal title' })} onSubmit={handleUpdate} onClose={handleCancelEdit} error={error} initialValues={initialValues} buttonTitle={t({ message: 'Save', comment: 'Edit task modal save button' })}/>);
}
