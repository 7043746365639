import { Trans } from '@lingui/macro';
import { DialogHeader, Dialog, DialogFooter, Button, DialogContent } from '@pocketlaw/tetris';
import { TaskStatusEnumEnum } from 'shared/domains/apollo/generated/types';
import { Assignee } from './Assignee';
import { Attachment } from './Attachment';
import { AttachmentHeading } from './AttachmentHeading';
import { CompleteTaskButton } from './CompleteTaskButton';
import { CreatedBy } from './CreatedBy';
import { Description } from './Description';
import { DueDate } from './DueDate';
import { PreviewMenuButton } from './PreviewMenuButton';
import { Public } from './Public';
import { Reminder } from './Reminder';
import { Repeat } from './Repeat';
import { Status } from './Status';
import { useTasks } from '../TasksProvider';
import { ContentContainer, LinkFieldContainer, CompletedTaskAnimationContainer } from './styled';
export function PreviewTaskDialog() {
    var _a, _b, _c;
    const { handleUnselectTask, activeTask } = useTasks();
    if (!activeTask) {
        return null;
    }
    const isCompleted = activeTask.isCompleted || activeTask.status === TaskStatusEnumEnum.Completed;
    return (<Dialog size="sm" onClose={handleUnselectTask}>
      <DialogHeader title={activeTask.title} action={<PreviewMenuButton taskId={activeTask.id} completed={isCompleted}/>}/>

      <CompletedTaskAnimationContainer>
        <DialogContent>
          <ContentContainer>
            <Description />
            <LinkFieldContainer>
              <Public isPublic={activeTask.isPublic}/>
              <Status status={activeTask.status} taskId={activeTask.id} dueDate={activeTask.dueDate}/>
              <DueDate dueDate={activeTask.dueDate}/>
              <Reminder reminder={(_a = activeTask.reminder) === null || _a === void 0 ? void 0 : _a.dueDate}/>
              <Repeat interval={(_b = activeTask.reminder) === null || _b === void 0 ? void 0 : _b.interval}/>
              <CreatedBy name={(_c = activeTask.creator) === null || _c === void 0 ? void 0 : _c.name}/>
              <Assignee />
            </LinkFieldContainer>

            <AttachmentHeading>
              <Attachment />
            </AttachmentHeading>

            <CompleteTaskButton />
          </ContentContainer>
        </DialogContent>
      </CompletedTaskAnimationContainer>

      <DialogFooter>
        <Button appearance="secondary" onClick={handleUnselectTask}>
          <Trans comment="close task preview button">Close</Trans>
        </Button>
      </DialogFooter>
    </Dialog>);
}
