import { Fragment, useState } from 'react';
import { t } from '@lingui/macro';
import { ConfirmDialog, IconButton, Loader, Tooltip } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useDeleteTeamMember } from 'app/domains/teams';
export function RemoveTeamMemberButton(props) {
    const label = t({
        message: 'Remove from team',
        comment: 'team members table - remove user from team: icon button label',
    });
    const dialogTitle = t({
        message: 'Remove team member',
        comment: 'team members table - remove user from team: dialog title',
    });
    const confirmLabel = t({
        message: 'Remove',
        comment: 'team members table - remove user from team: dialog confirm button label',
    });
    const cancelLabel = t({
        message: 'Cancel',
        comment: 'team members table - remove user from team: dialog cancel button label',
    });
    const { memberId, memberName, teamId } = props;
    const [showDialog, setShowDialog] = useState(false);
    const { deleteTeamMember, loading } = useDeleteTeamMember();
    const teamMemberName = memberName || '';
    const handleConfirm = () => {
        deleteTeamMember({ memberId, teamId });
    };
    const dialogMessage = t({
        message: `Are you sure you want to remove ${teamMemberName} from the team?`,
        comment: 'team members table - remove user from team: dialog message',
    });
    return (<Fragment>
      <Tooltip title={label}>
        <IconButton appearance="ghost" size="small" $round onClick={() => setShowDialog(true)} disabled={loading}>
          {loading ? <Loader /> : <Falcon icon="remove"/>}
        </IconButton>
      </Tooltip>
      {showDialog && (<ConfirmDialog onClose={() => setShowDialog(false)} appearance="danger" heading={dialogTitle} confirm={confirmLabel} cancel={cancelLabel} onConfirm={handleConfirm} onCancel={() => setShowDialog(false)} loading={loading}>
          {dialogMessage}
        </ConfirmDialog>)}
    </Fragment>);
}
