import { useQuery } from '@apollo/client';
import { useActiveDocument } from 'shared/domains/documents';
import { QUERY } from './query';
export function useGetApprovers() {
    var _a;
    const { id } = useActiveDocument();
    const { data, loading, refetch } = useQuery(QUERY, {
        variables: {
            id,
        },
    });
    const { approvers = [], type } = ((_a = data === null || data === void 0 ? void 0 : data.document) === null || _a === void 0 ? void 0 : _a.approval) || {};
    const transformedApprovers = approvers.map(approver => {
        var _a, _b, _c, _d, _e;
        return ({
            name: ((_b = (_a = approver.companyUser) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.name) || '',
            userId: ((_d = (_c = approver.companyUser) === null || _c === void 0 ? void 0 : _c.user) === null || _d === void 0 ? void 0 : _d.id) || '',
            companyUserId: ((_e = approver.companyUser) === null || _e === void 0 ? void 0 : _e.id) || '',
        });
    });
    return { loading, approvers: transformedApprovers, type, refetch };
}
