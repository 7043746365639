import { Fragment } from 'react';
import { Trans } from '@lingui/macro';
import { Button, Loader } from '@pocketlaw/tetris';
import { useEsigning } from 'app/domains/esigning/components/EsigningProvider';
export function Actions(props) {
    const { onClose } = props;
    const { submittingCreation } = useEsigning();
    return (<Fragment>
      <Button type="button" appearance="secondary" disabled={submittingCreation} onClick={onClose}>
        <Trans comment="Label for cancelling edit">Cancel</Trans>
      </Button>
      <Button type="submit" disabled={submittingCreation}>
        {submittingCreation ? <Loader /> : <Trans comment="Label for saving edit">Save</Trans>}
      </Button>
    </Fragment>);
}
