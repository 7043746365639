var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Avatar, TableBodyCell, TableCellEmpty } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
export function BooleanCell(props) {
    const { value } = props, rest = __rest(props, ["value"]);
    if (typeof value !== 'boolean') {
        return (<TableBodyCell {...rest}>
        <TableCellEmpty />
      </TableBodyCell>);
    }
    return (<TableBodyCell {...rest}>
      <Avatar size="sm" shape="circle" appearance={value ? 'success' : 'danger'}>
        {value ? <Falcon icon="check"/> : <Falcon icon="minus"/>}
      </Avatar>
    </TableBodyCell>);
}
