import { Fragment, useState } from 'react';
import { t } from '@lingui/macro';
import { AvatarWithLabel } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useField } from 'formik';
import { useHistory } from 'react-router-dom';
import { useGetDocumentsData } from './useGetDocumentsData';
import { AttachmentBrowser } from '../../AttachmentBrowser';
import { AttachmentDropDown } from '../../AttachmentDropDown';
import { useTasks } from '../../TasksProvider';
import { LinkButton, AttachmentContainer } from '../styled';
export function DocumentField(props) {
    var _a;
    const { attachment, index, remove, replace } = props;
    const history = useHistory();
    const { documentId } = useTasks();
    const [completedField] = useField('isCompleted');
    const attachmentData = useGetDocumentsData(attachment.attachmentId);
    const [displayBrowser, setDisplayBrowser] = useState(false);
    const hasAccess = Boolean(attachmentData.data);
    const isInDocumentView = documentId === attachment.attachmentId;
    const disabled = isInDocumentView || !hasAccess || attachmentData.loading || completedField.value;
    const attachmentName = (_a = attachmentData.data) === null || _a === void 0 ? void 0 : _a.name;
    const handleRemove = () => {
        remove(index);
    };
    const handleReplace = (newAttachment) => {
        replace(index, {
            attachmentId: newAttachment.id,
            type: newAttachment.type,
        });
        setDisplayBrowser(false);
    };
    return (<Fragment>
      <AttachmentContainer>
        <LinkButton type="button" onClick={() => history.push(`/document/${attachment.attachmentId}`)} disabled={disabled}>
          <AvatarWithLabel title={hasAccess
            ? attachmentName
            : t({
                message: "You don't have access to this attachment",
                comment: 'No access text to task document attachment',
            })} solid size="sm" truncate={false}>
            <Falcon icon="file"/>
          </AvatarWithLabel>
        </LinkButton>
        {!disabled && (<AttachmentDropDown onEdit={() => setDisplayBrowser(true)} onDelete={handleRemove}/>)}
      </AttachmentContainer>
      {displayBrowser && (<AttachmentBrowser onAdd={handleReplace} onCancel={() => setDisplayBrowser(false)}/>)}
    </Fragment>);
}
