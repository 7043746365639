import { addDays, addMonths, addWeeks, addYears, formatDistanceStrict, subDays, subMonths, subWeeks, subYears, } from 'date-fns';
import { SearchTimeUnit, } from 'shared/domains/apollo/generated/types';
function formatDateMathOffset(dateOffset) {
    const { value, timeUnit } = dateOffset || {};
    if (!timeUnit || value == null) {
        return undefined;
    }
    if (value === 0) {
        return undefined;
    }
    const getCalculator = {
        [SearchTimeUnit.Day]: {
            sub: subDays,
            add: addDays,
        },
        [SearchTimeUnit.Week]: {
            sub: subWeeks,
            add: addWeeks,
        },
        [SearchTimeUnit.Month]: {
            sub: subMonths,
            add: addMonths,
        },
        [SearchTimeUnit.Year]: {
            sub: subYears,
            add: addYears,
        },
    };
    const getUnit = (unit) => {
        switch (unit) {
            case SearchTimeUnit.Day:
            case SearchTimeUnit.Month:
            case SearchTimeUnit.Year:
                return unit;
            case SearchTimeUnit.Week:
            default:
                return undefined;
        }
    };
    try {
        const timeCalculatorFn = getCalculator[timeUnit][value < 0 ? 'sub' : 'add'];
        const today = new Date();
        return formatDistanceStrict(timeCalculatorFn(today, Math.abs(value)), today, {
            addSuffix: true,
            unit: getUnit(timeUnit),
        });
    }
    catch (_a) {
        return undefined;
    }
}
export function formatSearchDateTimeFilter(dateOffset) {
    const startDate = formatDateMathOffset(dateOffset === null || dateOffset === void 0 ? void 0 : dateOffset.rgte);
    const endDate = formatDateMathOffset(dateOffset === null || dateOffset === void 0 ? void 0 : dateOffset.rlte);
    if (startDate && endDate) {
        return `${startDate} - ${endDate}`;
    }
    if (startDate) {
        return startDate;
    }
    if (endDate) {
        return endDate;
    }
    return undefined;
}
