import { Auth0Client } from '@auth0/auth0-spa-js';
const defaultRedirectURI = `${window.location.origin}/auth/callback`;
const defaultAudience = 'com.pocketlaw.app-back-end';
const defaultScope = 'openid email profile';
export default new Auth0Client({
    domain: process.env.AUTH_DOMAIN,
    client_id: process.env.AUTH_CLIENT_ID,
    redirect_uri: defaultRedirectURI,
    audience: defaultAudience,
    scope: defaultScope,
});
