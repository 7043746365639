import { AvatarWithLabel, pxToRem, themeGet, unit } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const HeaderContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${unit('xl')};
  padding: ${unit('xl')};
  border-bottom: solid ${pxToRem(1)} ${themeGet('colors.gray.100')};
`;
export const HeaderLeftContainer = styled.div `
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  gap: ${unit('md')};
  min-width: 0;
`;
export const HeaderRightContainer = styled.div `
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: ${unit('md')};
  min-width: 0;
`;
export const AccordionContainer = styled.div `
  padding: ${unit('xl')};
  border-bottom: solid ${pxToRem(1)} ${themeGet('colors.gray.100')};
`;
export const StyledAvatarWithLabel = styled(AvatarWithLabel) `
  overflow: hidden;
  line-break: anywhere;
`;
export const LabelsContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${unit('xs')};
  overflow: hidden;
  line-break: anywhere;
`;
export const Container = styled.div `
  display: flex;
  flex-direction: column;

  ${HeaderContainer} {
    background-color: ${({ $open }) => $open && themeGet('colors.primary.50')};
  }
`;
