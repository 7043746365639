import { useField } from 'formik';
import { useFuse } from 'app/domains/search/hooks/useFuse';
import { useCompanyUsers } from 'app/domains/users';
export const useAssigneesField = () => {
    const { companyUsers } = useCompanyUsers();
    const [assigneesField, , helpers] = useField('taskAssignees');
    const [completedField] = useField('isCompleted');
    const { value } = assigneesField;
    const disabled = completedField.value;
    const formattedUsers = companyUsers.reduce((prev, current) => {
        const { userId, name } = current;
        if (userId) {
            return [...prev, { id: userId, name: name || '' }];
        }
        return prev;
    }, []);
    const { search, result } = useFuse(formattedUsers, {
        keys: ['name'],
        ignoreLocation: true,
        threshold: 0,
        minMatchCharLength: 1,
    });
    const searchResult = result.filter(user => !value.some(({ id }) => user.id === id));
    const unselectedAssignees = formattedUsers.filter(user => !value.some(({ id }) => user.id === id));
    const addAssignee = (assignee) => {
        helpers.setValue([...value, assignee]);
    };
    const removeAssignee = (id) => {
        helpers.setValue(value.filter(assignee => assignee.id !== id));
    };
    const removeAllAssignees = () => {
        helpers.setValue([]);
    };
    return {
        selectedAssignees: value,
        unselectedAssignees,
        disabled,
        addAssignee,
        removeAssignee,
        removeAllAssignees,
        search,
        searchResult,
    };
};
