import { select } from '@lingui/macro';
import { Typography } from '@pocketlaw/tetris';
import { useAttachmentData } from 'app/domains/tasks/components/PreviewTask/useAttachmentData';
import { Container } from './styled';
export function AttachmentHeading(props) {
    var _a;
    const { children } = props;
    const { attachmentData } = useAttachmentData();
    const attachmentType = (_a = attachmentData === null || attachmentData === void 0 ? void 0 : attachmentData.type) !== null && _a !== void 0 ? _a : '';
    if (!attachmentData.id) {
        return null;
    }
    return (<Container>
      <Typography $fontSize="small" $fontWeight="medium" $appearance="200">
        {select(attachmentType, {
            template: 'Linked template',
            document: 'Linked document',
            other: 'Linked attachment',
        })}
      </Typography>
      {children}
    </Container>);
}
