var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import apolloClient from 'app/domains/apollo';
import { downloadSignedFileQuery } from './query';
export function downloadSignedFile(options) {
    return __awaiter(this, void 0, void 0, function* () {
        const { documentId, name, mimeType } = options;
        const { data } = yield apolloClient.query({
            query: downloadSignedFileQuery,
            variables: {
                documentId,
                name,
            },
        });
        const { files } = data.fileSystemDocument || {};
        const { signed } = (files === null || files === void 0 ? void 0 : files.find(file => file.mimeType === mimeType)) || {};
        if (!(signed === null || signed === void 0 ? void 0 : signed.url)) {
            throw Error('No signed url returned from API');
        }
        return signed.url;
    });
}
