import { useEffect } from 'react';
import { useQuery, useApolloClient } from '@apollo/client';
import { DocumentAiDisabledReason } from 'shared/domains/apollo/generated/types';
import { GetDocumentAiDocument } from './GetDocumentAi.gql';
export const useDocumentAi = (documentId) => {
    var _a, _b;
    const client = useApolloClient();
    const cachedData = client.readQuery({
        query: GetDocumentAiDocument,
        variables: {
            documentId,
        },
    });
    const { data, error, loading, startPolling, stopPolling } = useQuery(GetDocumentAiDocument, {
        fetchPolicy: ((_a = cachedData === null || cachedData === void 0 ? void 0 : cachedData.documentAi) === null || _a === void 0 ? void 0 : _a.enabled) === true ? 'cache-only' : 'network-only',
        variables: {
            documentId,
        },
    });
    const { disabledReason = null, enabled = true } = (_b = data === null || data === void 0 ? void 0 : data.documentAi) !== null && _b !== void 0 ? _b : {};
    useEffect(() => {
        if (disabledReason === DocumentAiDisabledReason.TextExtractionPending) {
            startPolling(5000);
        }
        return () => {
            stopPolling();
        };
    }, [disabledReason, startPolling, stopPolling]);
    return {
        data: {
            disabledReason,
            enabled,
        },
        error,
        loading,
    };
};
