import { gql } from '@apollo/client';
export const mutation = gql `
  mutation createFileSystemFolder(
    $companyId: UUID!
    $parentId: ID
    $name: String!
    $userAcls: [FileSystemAclRelativeInsertInput!]!
    $teamAcls: [FileSystemTeamAclRelativeInsertInput!]!
  ) {
    folder: createFileSystemFolder(
      input: {
        companyId: $companyId
        parentId: $parentId
        name: $name
        acls: $userAcls
        teamAcls: $teamAcls
      }
    ) {
      id
      parentId
      categoryId
      name
      date: updatedAt
      marketCode
      depth
      kind
      user {
        id
        name
        email
      }
      acl: acls {
        id
        userId
        actions
        user {
          id
          name
          email
        }
      }
    }
  }
`;
