import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import * as Sentry from 'shared/domains/sentry';
import mutation from './mutation';
function useSetAsRead(message) {
    const [setAsRead] = useMutation(mutation);
    const shouldSetAsRead = message.type === 'receiver' && message.isRead === false;
    useEffect(() => {
        if (!shouldSetAsRead) {
            return;
        }
        setAsRead({
            variables: {
                ids: [message.id],
            },
        }).catch(error => {
            Sentry.captureException(error);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
export default useSetAsRead;
