var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { DialogHeader, DialogFooter, useToast, Alert, AvatarWithLabel, DialogContent, Typography, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useHistory } from 'react-router-dom';
import { object, string } from 'yup';
import { FormDialog, SubmitButton, CancelButton } from 'shared/domains/forms';
import { OptionField } from './components/OptionField';
import { ValidationError } from './components/ValidationError';
import { getDialogNote, getDialogSubtitle, getDialogTitle, getEditCopyFieldDescription, getEditCopyFieldTitle, getEditOriginalFieldDescription, getEditOriginalFieldTitle, } from './copy';
import { waitFor } from './utils';
import { useCloneFreeTextDocument } from '../../hooks/useCloneFreeTextDocument';
import { useConvertDocumentToFreeText } from '../../hooks/useConvertDocumentToFreeText';
import { useDocument } from '../../hooks/useDocument';
import { useActiveDocument, usePreview } from '../../hooks/usePreview';
import { documentFlush } from '../Preview/Pleditor';
import { NoteContainer, StyledSection, RadioCardContainer } from './styled';
const schema = object().shape({
    document: string().oneOf(['original', 'copy']).required(),
});
const initialValues = {
    document: 'original',
};
export function EditDocumentDialog({ onClose }) {
    const { id: documentId } = useActiveDocument();
    const { data: documentData } = useDocument(documentId);
    const { isComposed, isRtcAvailable } = usePreview();
    const { document } = documentData || {};
    const history = useHistory();
    const [error, setError] = useState(false);
    const { addToast } = useToast();
    const [cloneFreeTextDocument] = useCloneFreeTextDocument();
    const [convertDocumentToFreeText] = useConvertDocumentToFreeText();
    const handleSubmit = (values) => __awaiter(this, void 0, void 0, function* () {
        setError(false);
        try {
            if (!document) {
                throw Error();
            }
            const { name } = document;
            const copyName = t `${name} (Copy)`;
            if (values.document === 'copy') {
                const { data } = yield cloneFreeTextDocument({ id: documentId, name: copyName });
                if (!data) {
                    throw Error();
                }
                addToast({ title: t `Free-text document created`, appearance: 'success' });
                yield waitFor(500);
                history.push(`/document/${data.cloneFromFileSystemDocument.id}`);
            }
            if (values.document === 'original') {
                if (isRtcAvailable) {
                    documentFlush({ state: 'initialized' });
                }
                yield convertDocumentToFreeText(documentId);
                addToast({ title: t `Converted to Free-text document`, appearance: 'success' });
                if (isRtcAvailable) {
                    return;
                }
                yield waitFor(500);
                history.go(0);
            }
        }
        catch (_a) {
            setError(true);
        }
    });
    return (<FormDialog initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit} size="lg" onClose={onClose}>
      <DialogHeader media={<AvatarWithLabel size="xl" shape="square" title={getDialogTitle(isComposed)} subtitle={getDialogSubtitle(isComposed)}>
            <Falcon icon="file-export"/>
          </AvatarWithLabel>}/>

      <DialogContent>
        <StyledSection>
          <NoteContainer>
            <Typography>{getDialogNote(isComposed)}</Typography>
          </NoteContainer>

          <RadioCardContainer>
            <OptionField value="original" label={getEditOriginalFieldTitle(isComposed)} description={getEditOriginalFieldDescription(isComposed)}/>

            <OptionField value="copy" label={getEditCopyFieldTitle(isComposed)} description={getEditCopyFieldDescription(isComposed)}/>
          </RadioCardContainer>
        </StyledSection>

        <ValidationError />

        {error && (<Alert appearance="danger" message={t({
                message: 'Something went wrong, please try again later',
                comment: 'Error alert when failed to edit/copy document',
            })}/>)}
      </DialogContent>

      <DialogFooter>
        <CancelButton onClick={onClose}>
          <Trans>Cancel</Trans>
        </CancelButton>

        <SubmitButton data-ui-event="Convert document to free-text submit">
          <Trans>Get started</Trans>
        </SubmitButton>
      </DialogFooter>
    </FormDialog>);
}
