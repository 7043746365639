import { useEffect, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useGetData } from './useGetData';
import { isLoading, resolveAccount, isCachingAccount, isExcludedRoute } from './utils';
import { useCreateOneCompanyActivity } from '../useCreateOneCompanyActivity/hook';
let haveRecordedActivity = false;
export default function useInit({ skip = false }) {
    var _a, _b, _c, _d;
    const history = useHistory();
    const { pathname, state } = useLocation();
    const [createOneCompanyActivity, mutationState] = useCreateOneCompanyActivity();
    const query = useGetData({ skip });
    const hasCompanyId = (_b = (_a = query.data) === null || _a === void 0 ? void 0 : _a.account) === null || _b === void 0 ? void 0 : _b.companyId;
    const { verified } = (_d = (_c = query.data) === null || _c === void 0 ? void 0 : _c.profile) !== null && _d !== void 0 ? _d : {};
    const excludedRoute = isExcludedRoute(pathname);
    const loading = mutationState.loading || query.loading;
    useEffect(() => {
        if (haveRecordedActivity || skip || excludedRoute || !hasCompanyId || !verified || loading) {
            return;
        }
        haveRecordedActivity = true;
        createOneCompanyActivity();
    }, [createOneCompanyActivity, excludedRoute, hasCompanyId, skip, verified, loading]);
    useEffect(() => {
        if (state === null || state === void 0 ? void 0 : state.addCompanyActivity) {
            history.replace({ state: undefined });
            createOneCompanyActivity();
        }
    }, [state, createOneCompanyActivity, history]);
    useEffect(() => {
        var _a, _b;
        if (isCachingAccount(query)) {
            const data = query.data;
            const accounts = (_a = data.companyUsers) !== null && _a !== void 0 ? _a : [];
            const account = resolveAccount(accounts, (_b = query.data) === null || _b === void 0 ? void 0 : _b.storedAccountId);
            query.updateQuery(() => (Object.assign(Object.assign({}, data), { account, accounts })));
        }
    }, [query]);
    return useMemo(() => (Object.assign(Object.assign({}, query), { loading: isLoading(query) })), [query]);
}
