import { gql } from '@apollo/client';
export const getCompanyPlanId = gql `
  query GetCompanyPlanId($companyId: UUID!) {
    company(id: $companyId) {
      plan {
        planId: plan_id
      }
    }
  }
`;
