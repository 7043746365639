import { t } from '@lingui/macro';
import { LoaderOverlay } from '@pocketlaw/tetris';
import { Content } from '../styled';
export function LoadingState() {
    return (<Content>
      <LoaderOverlay label={t({
            comment: 'Select template dialog - Loading message',
            message: 'Loading templates...',
        })}/>
    </Content>);
}
