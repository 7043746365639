import { t } from '@lingui/macro';
import { Loader } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
export const wait = (milliseconds) => new Promise(resolve => {
    setTimeout(resolve, milliseconds);
});
export const getProgressIcon = (status) => {
    switch (status) {
        case 'uploading':
            return <Falcon data-animation="uploading" icon="arrow-up" $size={20}/>;
        case 'finalizing':
            return <Loader data-animation="finalizing" size={8}/>;
        case 'success':
            return <Falcon data-animation="success" icon="check" $size={20}/>;
        case 'error':
            return <Falcon data-animation="error" icon="exclamation" $size={20}/>;
        default:
            return null;
    }
};
export const getProgressLabel = (status) => {
    switch (status) {
        case 'uploading':
            return t({
                comment: 'Upload folder progress bar - uploading label',
                message: 'Uploading folder...',
            });
        case 'finalizing':
            return t({
                comment: 'Upload folder progress bar - finalizing label',
                message: 'Finalizing upload...',
            });
        case 'success':
            return t({
                comment: 'Upload folder progress bar - success label',
                message: 'Successfully uploaded folder',
            });
        case 'error':
            return t({
                comment: 'Upload folder progress bar - error label',
                message: 'Could not upload folder',
            });
        default:
            return null;
    }
};
