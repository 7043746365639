import { Box } from '@pocketlaw/tetris';
import { ReviewFeedbackStatus } from 'shared/domains/apollo/generated/types';
import { Accept } from './Accept';
import { Discard } from './Discard';
import { FollowUp } from './FollowUp';
import { PlaybookReference } from './PlaybookReference';
import { Recover } from './Recover';
const RESOLVED_STATUSES = [ReviewFeedbackStatus.Accepted, ReviewFeedbackStatus.Discarded];
export function Actions(props) {
    const { feedback } = props;
    const resolved = RESOLVED_STATUSES.includes(feedback.feedback.status);
    if (resolved) {
        return (<Box alignItems="center" justifyContent="space-between">
        <Recover feedbackId={feedback.feedback.id}/>
        <PlaybookReference feedback={feedback}/>
      </Box>);
    }
    return (<Box alignItems="center" justifyContent="space-between">
      <FollowUp feedbackId={feedback.feedback.id}/>
      <Box gap="md">
        <Accept feedbackId={feedback.feedback.id}/>
        <Discard feedbackId={feedback.feedback.id}/>
        <PlaybookReference feedback={feedback}/>
      </Box>
    </Box>);
}
