export function sortComposers(options) {
    const { composers, sortOrder } = options;
    const sortedComposers = [...composers];
    sortedComposers.sort((a, b) => {
        const [valueA] = (a === null || a === void 0 ? void 0 : a.metadata) || [];
        const [valueB] = (b === null || b === void 0 ? void 0 : b.metadata) || [];
        if ((valueA === null || valueA === void 0 ? void 0 : valueA.name) === (valueB === null || valueB === void 0 ? void 0 : valueB.name)) {
            return 0;
        }
        if (sortOrder === 'asc') {
            return (valueA === null || valueA === void 0 ? void 0 : valueA.name) > (valueB === null || valueB === void 0 ? void 0 : valueB.name) ? 1 : -1;
        }
        return (valueA === null || valueA === void 0 ? void 0 : valueA.name) > (valueB === null || valueB === void 0 ? void 0 : valueB.name) ? -1 : 1;
    });
    return sortedComposers;
}
