import { ListItem } from '../ListItem';
export function Invitee(props) {
    const { thread, inviteId, onClick, onRemove } = props;
    const { interlocutor, unreadMessagesCount } = thread;
    const { name, email } = interlocutor;
    const handleClickInvitee = () => {
        onClick(thread);
    };
    const handleRemoveInvitee = () => {
        onRemove({ id: inviteId, email });
    };
    return (<ListItem label={name} description={email} unreadMessages={unreadMessagesCount} onRemove={handleRemoveInvitee} onClick={handleClickInvitee}/>);
}
