import { GridContainer, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const MainContent = styled(GridContainer) `
  margin: ${themeGet('spacing.2xl')} auto;
`;
export const PageContainer = styled.div `
  display: flex;
  flex-direction: row;
  height: 100vh;
  justify-content: flex-start;
  width: 100%;
  overflow: hidden;
`;
export const Content = styled.div `
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
`;
