import { Fragment, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Box, Button, Calendar, Typography } from '@pocketlaw/tetris';
import { localeConfig } from 'shared/domains/languages/config';
import { DateButton } from './DateButton';
import { useFilter } from '../../useFilter';
export function DateRangeForm(props) {
    const { initialValue, onSubmit, name } = props;
    const filter = useFilter(name);
    const [currentCalendar, setCurrentCalendar] = useState(null);
    const localeCode = localeConfig.getActiveLocale().code;
    const [dateRange, setDateRange] = useState(initialValue);
    const showCalendar = (calendar) => setCurrentCalendar(calendar);
    const hideCalendar = () => setCurrentCalendar(null);
    const getDateByCalendar = () => {
        if (!currentCalendar) {
            return undefined;
        }
        return dateRange[currentCalendar];
    };
    const handleChange = (date) => {
        if (!currentCalendar) {
            return;
        }
        setDateRange(Object.assign(Object.assign({}, dateRange), { [currentCalendar]: date }));
        setCurrentCalendar(null);
    };
    const handleReset = (field) => {
        setDateRange(Object.assign(Object.assign({}, dateRange), { [field]: undefined }));
    };
    const handleSubmit = () => {
        var _a, _b;
        filter.setValue({
            gte: (_a = dateRange.gte) === null || _a === void 0 ? void 0 : _a.toISOString(),
            lte: (_b = dateRange.lte) === null || _b === void 0 ? void 0 : _b.toISOString(),
        });
        onSubmit();
    };
    const hasValue = dateRange.gte || dateRange.lte;
    if (currentCalendar) {
        return (<Fragment>
        <Calendar min={currentCalendar === 'lte' ? dateRange === null || dateRange === void 0 ? void 0 : dateRange.gte : undefined} value={getDateByCalendar()} onChange={handleChange} locale={localeCode}/>
        <Box flexDirection="column" p="md" pt="0">
          <Button size="small" $stretch onClick={hideCalendar} appearance="ghost">
            <Trans comment="Date range filter: Close calendar button label">Cancel</Trans>
          </Button>
        </Box>
      </Fragment>);
    }
    return (<Box flexDirection="column" p="md">
      <Box flexDirection="column" mb="lg" gap="md">
        <Typography>
          <Trans comment="Date range filter: Date input filter help text">
            Select a start- and/or end date to apply
          </Trans>
        </Typography>
        <DateButton date={dateRange.gte} label={t({
            message: 'Date from',
            comment: 'Range filter: "Date from" input label',
        })} onClick={() => showCalendar('gte')} onReset={() => handleReset('gte')}/>
        <DateButton date={dateRange.lte} label={t({ message: 'Date to', comment: 'Date Range filter: "Date to" input label' })} onClick={() => showCalendar('lte')} onReset={() => handleReset('lte')}/>
      </Box>
      <Button type="button" size="small" $stretch onClick={handleSubmit} disabled={!hasValue}>
        <Trans comment="Apply filter button label">Apply</Trans>
      </Button>
    </Box>);
}
