import { Context } from './context';
import { useGetDocumentMetadata } from './useGetDocumentMetadata';
import { useToggleEntryVisibility } from './useToggleEntryVisibility';
/**
 * To be used when interacting with a single documents metadata. This provider will
 * expose all data needed for a single documents metadata  fields
 * Both user-defined metadata (properties) and system-defined (systemData) data
 * is provided.
 */
export function DocumentMetadataProvider(props) {
    const { documentId, children, loadingFallback } = props;
    const { error, loading, properties, systemData, autoTaggingFeatureAvailable, refetch } = useGetDocumentMetadata(documentId);
    const { activeEntries, toggleActiveEntry } = useToggleEntryVisibility(properties);
    if (loading) {
        return loadingFallback;
    }
    const value = {
        documentId,
        activeEntries,
        toggleActiveEntry,
        data: {
            error,
            loading,
            properties,
            systemData,
            autoTaggingFeatureAvailable,
            refetch,
        },
    };
    return <Context.Provider value={value}>{children}</Context.Provider>;
}
