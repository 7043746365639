import { useMount, useUnmount } from 'react-use';
import { useDocumentDrawer } from 'shared/domains/documents';
export function DrawerWrapper(props) {
    const { children, onUnmount } = props;
    const { setActiveComponent } = useDocumentDrawer();
    useMount(() => {
        setActiveComponent(children);
    });
    useUnmount(() => {
        if (onUnmount) {
            onUnmount();
        }
    });
    return null;
}
