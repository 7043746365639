export function addLineBreaks(value) {
    const threeLineBreaksOrMoreRegex = /\n{3,}/g;
    return value
        .trim()
        .replace(threeLineBreaksOrMoreRegex, '\n\n')
        .split('\n')
        .flatMap((text, index, array) => {
        const textNode = { type: 'text', value: text };
        const lineBreak = { type: 'break' };
        if (index === array.length - 1) {
            return [textNode];
        }
        return [textNode, lineBreak];
    });
}
