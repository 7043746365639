import { gql } from '@apollo/client';
export const externalSharingInvitesQuery = gql `
  query getExternalInvites($documentId: String) {
    externalSharingInvites(where: { fileSystemDocumentId: { eq: $documentId } }) {
      id
      inviteeEmail
      fileSystemDocumentId

      invitee {
        id
        name
      }
    }
  }
`;
