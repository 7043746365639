import { t } from '@lingui/macro';
import { IconButton, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useStrictPDFData } from '../../usePDFData';
import { Container, TextContainer } from './styled';
export function Pagination() {
    const { currentPage, nrOfPages, scrollToPage } = useStrictPDFData();
    const scrollToPrev = () => scrollToPage(currentPage - 1);
    const scrollToNext = () => scrollToPage(currentPage + 1);
    const buttonProps = {
        $round: true,
        size: 'small',
        appearance: 'ghost',
    };
    const paginationText = `${currentPage} / ${nrOfPages}`;
    return (<Container>
      <IconButton aria-label={t({
            message: 'Scroll to previous page',
            comment: 'Aria label for scrolling to the previous page button',
        })} disabled={currentPage === 1} onClick={scrollToPrev} {...buttonProps}>
        <Falcon icon="chevron-left"/>
      </IconButton>
      <TextContainer>
        <Typography aria-label={t({
            message: 'Current page',
            comment: 'Aria label for the  current page text',
        })}>
          {paginationText}
        </Typography>
      </TextContainer>
      <IconButton aria-label={t({
            message: 'Scroll to previous page',
            comment: 'Aria label for scrolling to the next page button',
        })} disabled={currentPage === nrOfPages} onClick={scrollToNext} {...buttonProps}>
        <Falcon icon="chevron-right"/>
      </IconButton>
    </Container>);
}
