import { Dialog, DialogContent } from '@pocketlaw/tetris';
import { ComposerDataProvider } from './ComposerDataProvider';
import { Root } from './Root';
export function SelectTemplateDialog(props) {
    const { initialComposerId, onClose } = props;
    return (<Dialog size="xl" height="80dvh" onClose={onClose}>
      <DialogContent disablePadding>
        <ComposerDataProvider initialComposerId={initialComposerId} onClose={onClose}>
          <Root />
        </ComposerDataProvider>
      </DialogContent>
    </Dialog>);
}
