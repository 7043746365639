import { Loader, unit, padding, pxToRem, themeGet } from '@pocketlaw/tetris';
import { breakpoint } from '@pocketlaw/tetris/deprecated';
import styled from 'styled-components';
const TRANSITION_DURATION = '120ms';
export const PageManagers = styled.div `
  display: flex;
  align-items: flex-end;
  position: sticky;
  width: 100%;
  bottom: 0;
  left: 0;
  height: ${pxToRem(1)};

  ${padding(4)};

  @media ${breakpoint.palm} {
    position: fixed;
    bottom: ${unit(18)};
  }
`;
export const Content = styled.div `
  display: flex;
  flex-flow: nowrap row;
  min-width: fit-content;

  justify-content: center;
  min-height: 100%;
  flex-shrink: 0;

  @media ${breakpoint.lapAndUp} {
    ${padding(10)};
  }
`;
export const PaperLimit = styled.div `
  background-color: ${themeGet('colors.gray.0')};
  width: ${({ $dimensions }) => $dimensions.width !== null && `${pxToRem($dimensions.width)}`};
  min-height: ${({ $dimensions }) => $dimensions.height !== null && pxToRem($dimensions.height)};

  box-sizing: content-box;

  transition: ${props => props.$shouldAnimate
    ? `width ${TRANSITION_DURATION}, height ${TRANSITION_DURATION}`
    : undefined};
`;
export const Paper = styled.section `
  min-height: 356mm;

  @media ${breakpoint.lapAndUp} {
    width: 216mm;
    min-height: 356mm;
  }

  transform-origin: 0 0;
  transform: ${({ $zoom }) => `scale(${$zoom})`};
  transition: ${props => (props.$shouldAnimate ? `transform ${TRANSITION_DURATION}` : undefined)};
`;
export const StyledLoader = styled(Loader) `
  display: block;
  margin-left: auto;
  margin-right: auto;
  ${padding(10, 0)};
`;
export const EmptyContainer = styled.div `
  ${padding(60, 0, 0)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
