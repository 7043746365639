import { Fragment } from 'react';
import { i18n } from '@lingui/core';
import { Trans, t } from '@lingui/macro';
import { Button, GridCell, GridRow } from '@pocketlaw/tetris';
import { dashboardTemplates, useInsightsTemplates } from './Context';
import { TemplateCard } from './TemplateCard';
import emptyDashboard from '../../assets/empty_dashboard.svg';
import generalDashboard from '../../assets/general_dashboard.svg';
import { EmptyButton } from './styled';
export function TemplatesList() {
    const { setSelected, createDashboard } = useInsightsTemplates();
    const selectTemplate = (id) => {
        setSelected(id);
        createDashboard();
    };
    return (<GridRow>
      <GridCell width={{ base: 12, md: 4 }}>
        <EmptyButton onClick={createDashboard}>
          <TemplateCard bg={emptyDashboard} label={t({
            message: 'Empty dashboard',
            comment: 'Insights templates - Empty dashboard template label',
        })} description={t({
            message: 'Start from scratch with a clean canvas.',
            comment: 'Insights templates - Empty dashboard template description',
        })}/>
        </EmptyButton>
      </GridCell>
      {dashboardTemplates.map(template => (<GridCell width={{ base: 12, md: 4 }}>
          <TemplateCard key={template.id} label={i18n._(template.label)} description={i18n._(template.description)} bg={generalDashboard} actions={<Fragment>
                <Button $stretch size="small" appearance="secondary" onClick={() => setSelected(template.id)}>
                  <Trans comment="Insight dashboard template: Preview button">Preview</Trans>
                </Button>
                <Button $stretch size="small" onClick={() => selectTemplate(template.id)}>
                  <Trans comment="Insight dashboard template: Use button">Use</Trans>
                </Button>
              </Fragment>}/>
        </GridCell>))}
    </GridRow>);
}
