import { createContext } from 'react';
export const ToggleTabsCtx = createContext({});
export function ToggleTabs(props) {
    const { children, activeTab, onSelectTab } = props;
    const value = {
        activeTab,
        setActiveTab: onSelectTab,
    };
    return <ToggleTabsCtx.Provider value={value}>{children}</ToggleTabsCtx.Provider>;
}
