import { Button, pxToRem, themeGet } from '@pocketlaw/tetris';
import { breakpoint } from '@pocketlaw/tetris/deprecated';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  border-bottom: ${pxToRem(1)} solid ${themeGet('colors.gray.100')};
`;
const StyledButton = styled(Button).attrs(() => ({
    type: 'button',
    appearance: 'ghost',
})) `
  color: ${themeGet('colors.gray.700')};
  align-items: center;

  padding: 0;
  margin-right: ${themeGet('spacing.md')};
  margin-bottom: ${themeGet('spacing.md')};

  &:disabled {
    color: ${themeGet('colors.gray.800')};
  }
`;
export const BackWrapper = styled.div `
  color: ${themeGet('colors.gray.800')};
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${breakpoint.lapAndUp} {
    display: none;
  }
`;
export const BackCrumb = styled(StyledButton) `
  color: ${themeGet('colors.gray.700')};

  &:focus-visible,
  &:hover {
    color: ${themeGet('colors.gray.800')};
  }
`;
export const Crumb = styled(StyledButton) `
  @media ${breakpoint.palm} {
    display: none;
  }
`;
