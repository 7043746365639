import { gql } from '@apollo/client';
import { TaskFragment } from '../../utils/fragments';
export const createTaskMutation = gql `
  mutation CreateTask($task: TaskInsertInput!) {
    task: createOneTask(object: $task) {
      ...TaskFragment
    }

    ${TaskFragment}
  }
`;
