var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { t, Trans } from '@lingui/macro';
import { ConfirmDialog, Alert, Avatar, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useDeleteTeam } from 'app/domains/teams';
export function ConfirmDeleteTeamDialog(props) {
    const { id, name, onClose } = props;
    const { removeTeam, loading, error } = useDeleteTeam();
    const handleConfirm = () => __awaiter(this, void 0, void 0, function* () {
        const response = yield removeTeam(id);
        if (!response.errors) {
            onClose();
        }
    });
    return (<ConfirmDialog appearance="danger" size="sm" loading={loading} media={<Avatar appearance="danger" shape="square" size="lg">
          <Falcon icon="triangle-exclamation"/>
        </Avatar>} heading={t({ message: `Delete ${name}`, comment: 'delete team dialog heading' })} confirm={t({ message: 'Delete', comment: 'delete team dialog confirm button' })} cancel={t({ message: 'Cancel', comment: 'delete team dialog cancel button' })} onConfirm={handleConfirm} onCancel={onClose} onClose={onClose}>
      <Typography>
        <Trans comment="delete team dialog message">
          Are you sure you want to delete this team? This team will be permanently deleted and can
          not be recovered.
        </Trans>
      </Typography>
      {error && (<Alert appearance="danger" message={t({
                message: 'Something went wrong, please try again later',
                comment: 'error alert message delete user',
            })}/>)}
    </ConfirmDialog>);
}
