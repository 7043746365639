import { gql } from '@apollo/client';
export const QUERY = gql `
  query GetCompanyUsers($companyId: UUID!) {
    company(id: $companyId) {
      id
      companyUsers: company_users {
        id
        user {
          id
          name
        }
      }
    }
  }
`;
