import { t } from '@lingui/macro';
import { Falcon } from '@pocketlaw/tetris/macro';
import { StyledEmptyState } from './styled';
export function EmptyList() {
    return (<StyledEmptyState withAvatar icon={<Falcon icon="comment"/>} title={t({
            comment: 'Document assistant - conversations view - empty list - title',
            message: 'Start a conversation',
        })} description={t({
            comment: 'Document assistant - conversations view - empty list - description',
            message: 'Ask a question on documentation or selection level. All threads will be displayed here.',
        })}/>);
}
