import { v4 } from 'uuid';
import { useEsigningProviderMetadata } from 'app/domains/esigning/hooks/useEsigningProviderMetadata';
import { useDefaultAuthenticationMode } from './useDefaultAuthenticationMode';
export const useGetEmptySignatory = (type, isSigning) => {
    const { properties } = useEsigningProviderMetadata();
    const { nameFormat, canUseTitleField } = properties;
    const authenticationMode = useDefaultAuthenticationMode(isSigning);
    const title = canUseTitleField ? '' : undefined;
    const base = {
        authenticationMode,
        isSigning,
        title,
        key: v4(),
        email: '',
        label: '',
        mobile: '',
    };
    const defaultSignatoryValues = Object.assign(Object.assign({}, base), { firstName: '', lastName: '' });
    const signatoryName = nameFormat === 'firstNameLastName' ? { firstName: '', lastName: '' } : { name: '' };
    const signatoryValues = Object.assign(Object.assign(Object.assign({}, base), signatoryName), { saveSignee: false });
    return (type === 'DefaultSignatory' ? defaultSignatoryValues : signatoryValues);
};
