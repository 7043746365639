import { Fragment, useState } from 'react';
import { Trans } from '@lingui/macro';
import { Button, Loader } from '@pocketlaw/tetris';
import { CancelApprovalDialog, useApproval } from 'app/domains/approval';
export function CancelApprovalRequestButton() {
    const { cancellingRequest } = useApproval();
    const [open, setOpen] = useState(false);
    const showDialog = () => setOpen(true);
    const hideDialog = () => setOpen(false);
    return (<Fragment>
      {open && <CancelApprovalDialog origin="settings" onClose={hideDialog}/>}
      <Button appearance="secondary" $stretch onClick={showDialog} disabled={cancellingRequest}>
        <Trans comment="Approval drawer - cancel sign request - button label">Cancel request</Trans>
        {cancellingRequest && <Loader />}
      </Button>
    </Fragment>);
}
