import gql from 'graphql-tag';
export const MUTATION = gql `
  mutation removeAssignees($ids: [UUID!]!, $taskId: UUID!) {
    deleteTaskAssignee(where: { id: { _in: $ids }, task_id: { _eq: $taskId } }) {
      returning {
        id
      }
    }
  }
`;
