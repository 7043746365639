import { DialogContent, DialogHeader, pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Wrapper = styled.div `
  width: 100%;
  overflow: auto;

  max-height: ${props => (props.$hasResults ? pxToRem(800) : pxToRem(300))};
  transition: max-height 0.2s ease-in-out;
`;
export const StyledCardContent = styled(DialogContent) `
  padding: ${themeGet('spacing.md')};

  overflow: visible;
  max-width: ${pxToRem(780)};
  box-sizing: border-box;
`;
export const StyledDialogHeader = styled(DialogHeader) `
  border-bottom: ${pxToRem(1)} solid ${themeGet('colors.border.100')};
  padding: ${themeGet('spacing.md')};
`;
