import { gql } from '@apollo/client';
export const query = gql `
  query composer($composerId: Unstable__UUID!) {
    composer: unstable__composer(id: $composerId) {
      id

      tree {
        id
        version
      }

      templates {
        id
        marketLocale {
          locale
          code
        }

        latestVersion {
          templateId
          reference
        }
      }
    }
  }
`;
