import { Trans } from '@lingui/macro';
import { padding, unit, fontSize, fontWeight, visuallyHiddenStyles, themeGet, } from '@pocketlaw/tetris';
import styled from 'styled-components';
const Container = styled.a `
  ${padding(0)};
  ${fontSize('base', 1)};
  ${fontWeight('semibold')};
  ${visuallyHiddenStyles};
  text-decoration: none;
  text-transform: uppercase;
  color: ${themeGet('colors.primary.500')};

  &:focus-visible {
    ${padding(2)};
    position: fixed;
    top: ${unit(4)};
    left: ${unit(4)};
    width: auto;
    height: auto;
    z-index: 100;
    background: ${themeGet('colors.gray.0')};
    clip: auto;
    outline: none;
  }
`;
export function SkipNav(props) {
    const { target } = props;
    return (<Container href={`#${target}`}>
      <Trans>Skip to content</Trans>
    </Container>);
}
