var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@apollo/client';
import { addMinutes } from 'date-fns';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { esigningProvidersQuery } from './query';
export const useEsigningProviders = () => {
    const { data: accountData } = useAccount();
    const { companyId } = accountData;
    const _a = useQuery(esigningProvidersQuery, {
        variables: { companyId },
        skip: !companyId,
    }), { data } = _a, rest = __rest(_a, ["data"]);
    const { company, esigningProviders = [] } = data || {};
    const { esigningProvider: companyEsigningProvider } = company || {};
    function isProviderExpired(dateString) {
        if (!dateString) {
            return false;
        }
        const dateMinusOneMinute = addMinutes(new Date(dateString), -1);
        return dateMinusOneMinute < new Date();
    }
    const providers = esigningProviders.map(provider => {
        const companyProvider = (companyEsigningProvider === null || companyEsigningProvider === void 0 ? void 0 : companyEsigningProvider.esigningProviderId) === provider.id
            ? companyEsigningProvider
            : undefined;
        return Object.assign(Object.assign({}, provider), { connected: Boolean(companyProvider), expired: isProviderExpired(companyProvider === null || companyProvider === void 0 ? void 0 : companyProvider.expiresAt) });
    });
    const connectedProvider = providers.find(({ connected }) => connected);
    const shouldConnectProvider = !connectedProvider || connectedProvider.expired;
    const isConnectedProviderExpired = Boolean(connectedProvider === null || connectedProvider === void 0 ? void 0 : connectedProvider.expired);
    return Object.assign(Object.assign({}, rest), { providers,
        connectedProvider,
        shouldConnectProvider,
        isConnectedProviderExpired });
};
