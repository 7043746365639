import { pxToRem, themeGet, themeGetColor } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const SuggestionsContainer = styled.div `
  position: relative;
`;
export const SuggestionsList = styled.ul `
  position: relative;
  top: ${pxToRem(4)};
  border: ${pxToRem(1)} solid ${themeGetColor('gray.100')};
  border-radius: ${pxToRem(4)};
  list-style: none;
  margin-top: 0;
  max-height: ${pxToRem(300)};
  overflow-y: auto;
  width: 100%;
  font-size: ${themeGet('fontSize.medium')};
  color: ${themeGetColor('text.300')};
  background-color: ${themeGetColor('gray.0')};
`;
export const SuggestionItem = styled.li `
  padding: ${themeGet('spacing.md')};
  background-color: ${({ $isActive }) => ($isActive ? themeGetColor('gray.50') : 'transparent')};
  cursor: ${({ $isActive }) => ($isActive ? 'pointer' : 'default')};

  &:hover {
    cursor: pointer;
  }
`;
export const LabelsHeading = styled.div `
  flex: 1;
  min-width: 0;
  padding: ${themeGet('spacing.md')} 0;
  font-size: ${themeGet('fontSize.small')};
  font-weight: ${themeGet('fontWeight.medium')};
  color: ${themeGetColor('text.200')};
`;
export const LabelsContainer = styled.div `
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: ${themeGet('spacing.lg')};
`;
export const CreateNewLabelContainer = styled.div `
  font-size: ${pxToRem(12)};
  margin-top: ${themeGet('spacing.lg')};
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: ${themeGet('spacing.sm')};
`;
