var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { TableBodyCell, TableCellEmpty, TableCellText } from '@pocketlaw/tetris';
import { localizedFormatDate } from 'shared/domains/common-utils';
export function DateCell(props) {
    const { date } = props, rest = __rest(props, ["date"]);
    if (!date) {
        return (<TableBodyCell {...rest}>
        <TableCellEmpty />
      </TableBodyCell>);
    }
    const localizedDate = localizedFormatDate(date, {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
    });
    return (<TableBodyCell {...rest}>
      <TableCellText primary={localizedDate}/>
    </TableBodyCell>);
}
