import { t } from '@lingui/macro';
import { SearchAggregationType, } from 'shared/domains/apollo/generated/types';
import { allowedPropertiesTranslations } from './translations';
const values = [
    'createdAt',
    'metadataDocumentDateSigned',
    'metadataDocumentNoticeDate',
    'metadataDocumentProbationaryPeriodExpirationDate',
    'metadataDocumentStartDate',
    'metadataDocumentTerminationDate',
    'updatedAt',
];
export const getDateRangeAllowedProperties = () => ({
    type: SearchAggregationType.DateRange,
    label: t({ message: 'Date range', comment: 'Insights - Date range aggregation' }),
    values: values.map(value => ({ value, label: allowedPropertiesTranslations(value) })),
});
