import { useQuery } from '@apollo/client';
import { getTeams } from './query';
export function useTeams(options) {
    const { sort, search = '' } = options;
    const sortId = sort.id || 'name';
    const { data, loading, error, called, previousData } = useQuery(getTeams, {
        variables: {
            orderBy: {
                [sortId]: sort.order,
            },
            search: `%${search}%`,
        },
    });
    const teams = called && loading ? (previousData === null || previousData === void 0 ? void 0 : previousData.teams) || [] : (data === null || data === void 0 ? void 0 : data.teams) || [];
    const teamsWithFormattedMembers = teams.map(team => {
        var _a;
        return (Object.assign(Object.assign({}, team), { members: (_a = team.members) === null || _a === void 0 ? void 0 : _a.map(member => {
                var _a;
                return (Object.assign(Object.assign({}, member), { name: (_a = member.user) === null || _a === void 0 ? void 0 : _a.name }));
            }) }));
    });
    return {
        teams: teamsWithFormattedMembers,
        initialLoading: !previousData && loading,
        loading,
        error,
    };
}
