import { Fragment, useState } from 'react';
import { Trans } from '@lingui/macro';
import { CheckCircleSolidGlyph, Loader, useTetrisTheme } from '@pocketlaw/tetris';
import { ErrorAlert } from 'app/domains/tasks/components/PreviewTask/CompleteTaskButton/ErrorAlert';
import { useTasks } from 'app/domains/tasks/components/TasksProvider';
import { TaskStatusEnumEnum } from 'shared/domains/apollo/generated/types';
import { useUpdateTaskCompleted } from './useUpdateTaskCompleted';
import { StyledButton, CompletedOverlay, CompletedIcon, CompletedIconContainer } from './styled';
export function CompleteTaskButton() {
    const { themeGet } = useTetrisTheme();
    const { activeTask, handleUnselectTask } = useTasks();
    const { updateTaskCompleted, loading, error } = useUpdateTaskCompleted();
    const [animationRunning, setAnimationRunning] = useState(false);
    if (!activeTask) {
        return null;
    }
    const isCompleted = activeTask.isCompleted || activeTask.status === TaskStatusEnumEnum.Completed;
    const handleUncompleteTask = () => {
        updateTaskCompleted({
            id: activeTask.id,
            isCompleted: false,
            status: TaskStatusEnumEnum.Todo,
        }).then(handleUnselectTask);
    };
    const handleCompleteTask = () => {
        updateTaskCompleted({
            id: activeTask.id,
            isCompleted: true,
            status: TaskStatusEnumEnum.Completed,
        }).then(() => setAnimationRunning(true));
    };
    const handleAnimationEnd = () => {
        setTimeout(() => {
            handleUnselectTask();
        }, 1000);
    };
    if (isCompleted && !animationRunning) {
        return (<Fragment>
        <StyledButton appearance="secondary" $stretch onClick={handleUncompleteTask} disabled={loading}>
          {loading && <Loader />}
          <Trans>Mark as not complete</Trans>
        </StyledButton>
        <ErrorAlert hasError={Boolean(error)}/>
      </Fragment>);
    }
    return (<Fragment>
      {animationRunning && (<CompletedOverlay>
          <CompletedIconContainer>
            <CompletedIcon glyph={CheckCircleSolidGlyph} primaryColor={themeGet('colors.primary.500')} size={19} onAnimationEnd={handleAnimationEnd}/>
          </CompletedIconContainer>
        </CompletedOverlay>)}
      <StyledButton $stretch size="medium" onClick={handleCompleteTask} disabled={loading}>
        {loading && <Loader />}
        <Trans>Complete task</Trans>
      </StyledButton>
      <ErrorAlert hasError={Boolean(error)}/>
    </Fragment>);
}
