import { createContext, useContext, useMemo } from 'react';
const MarkdownContext = createContext({
    handleFootnoteClick: undefined,
});
export const useMarkdown = () => useContext(MarkdownContext);
export function MarkdownProvider(props) {
    const { children, handleFootnoteClick } = props;
    const value = useMemo(() => ({
        handleFootnoteClick,
    }), [handleFootnoteClick]);
    return <MarkdownContext.Provider value={value}>{children}</MarkdownContext.Provider>;
}
