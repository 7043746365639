import { t } from '@lingui/macro';
import { EmptyState } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
export function ESignDocumentNotSupportedState() {
    return (<EmptyState withAvatar icon={<Falcon icon="circle-info"/>} title={t({
            message: 'Document not supported',
            comment: 'Title for unsupported e-signing document information message',
        })} description={t({
            message: 'This document type is not supported for being sent out for e-signing',
            comment: 'Message for unsupported e-signing document information message',
        })}/>);
}
