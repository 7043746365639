import { Fragment } from 'react';
import { AssistantContext, useAssistant, AssistantChatActions, AssistantContextSwitcher, AssistantContextPrompts, } from 'app/domains/assistant';
import { usePreview } from 'shared/domains/documents';
import { Container } from './styled';
export function AssistantDashboard() {
    const { isPdf } = usePreview();
    const { context } = useAssistant();
    const showChat = !(isPdf && context === AssistantContext.Selection);
    return (<Fragment>
      <Container>
        <AssistantContextSwitcher />
        <AssistantContextPrompts />
      </Container>
      {showChat && <AssistantChatActions />}
    </Fragment>);
}
