import { makeVar } from '@apollo/client';
/**
 * Represents the reason for a document flush.
 *
 * - 'not-initialized' - The document flush is not initialized.
 * - 'initialized' - The document flush is initialized by the current user.
 * - 'loading' - The document flush reason is loading.
 * - 'done' - The document flush is done.
 */
export const documentFlush = makeVar({ state: 'not-initialized' });
