import { Breadcrumb, Breadcrumbs } from '@pocketlaw/tetris';
import { useHistory } from 'react-router-dom';
import { useGetWorkspaceBreadcrumbs } from './useGetWorkspaceBreadcrumbs';
export function FileSystemBreadcrumbs() {
    const history = useHistory();
    const breadcrumbs = useGetWorkspaceBreadcrumbs();
    const handleOnClick = (link) => link && history.push(link);
    return (<Breadcrumbs>
      {breadcrumbs.map(({ id, name, link }) => (<Breadcrumb key={id} onClick={() => handleOnClick(link)}>
          {name}
        </Breadcrumb>))}
    </Breadcrumbs>);
}
