import { PleditorPlugin } from '../PleditorPlugin';
export class DisableTableDragDrop extends PleditorPlugin {
    static get pluginName() {
        return 'DisableTableDragDrop';
    }
    init() {
        this.editor.model.document.selection.on('change', () => {
            const selectedBlocks = [...this.editor.model.document.selection.getSelectedBlocks()];
            // table can be contained within a highlight block from composer
            const highlightBlocks = selectedBlocks.filter(block => (block === null || block === void 0 ? void 0 : block.name) === 'pl-highlight-block');
            // at least one selected highlight blocks in the selection contains a table
            const blockContainsTable = highlightBlocks
                .flatMap(block => [...block.getChildren()])
                .some(node => {
                if (node.is('element')) {
                    return node.name === 'table';
                }
                return false;
            });
            // there is at least one selected table in the selection
            const blockIsTable = selectedBlocks.filter(block => (block === null || block === void 0 ? void 0 : block.name) === 'table').length > 0;
            if (blockContainsTable || blockIsTable) {
                this.editor.plugins.get('DragDrop').forceDisabled('pleditor');
            }
            else {
                this.editor.plugins.get('DragDrop').clearForceDisabled('pleditor');
            }
        });
    }
}
