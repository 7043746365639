import { mergeArrayByField } from 'shared/domains/apollo/cache/mergeArrayByField';
import { metadataConfigObject } from '../utils/metadataConfig';
export const typePolicies = {
    Query: {
        fields: {
            documentMetadataProperties: {
                read(existing, { readField }) {
                    return existing === null || existing === void 0 ? void 0 : existing.filter((ref) => Boolean(metadataConfigObject[readField('name', ref)]));
                },
            },
            documentMetadataAiAutoTaggingQueueEntries: {
                merge: mergeArrayByField('documentId'),
            },
        },
    },
};
