import { TextArea } from '@pocketlaw/tetris';
import { useField, useFormikContext } from 'formik';
import { ErrorMessage } from 'shared/domains/forms/components/ErrorMessage';
import { AnimateHeightContainer } from '../AnimateHeightContainer';
export function TextAreaField(props) {
    const { name, label } = props;
    const { isSubmitting } = useFormikContext();
    const [field, meta] = useField(name);
    const error = Boolean(meta.touched && meta.error);
    const disabled = isSubmitting;
    return (<div>
      <TextArea disabled={disabled} stretch autosizeHeight preventLinebreaks minHeight={15} label={label} {...field} invalid={error}/>
      <AnimateHeightContainer isOpen={error}>
        <ErrorMessage name={name} show/>
      </AnimateHeightContainer>
    </div>);
}
