import { addDays, differenceInDays, isAfter } from 'date-fns';
const NUMBER_OF_DAYS_BEFORE_REMINDER = 7;
export const getRemainingDays = (createdDate, todayDate) => {
    // We send out a reminder 7 days after the approval request was made
    const reminderDate = addDays(createdDate, NUMBER_OF_DAYS_BEFORE_REMINDER);
    const dayDifference = Math.abs(differenceInDays(todayDate, reminderDate));
    const remainingDays = dayDifference % NUMBER_OF_DAYS_BEFORE_REMINDER;
    if (remainingDays === 0) {
        return isAfter(todayDate, reminderDate) ? NUMBER_OF_DAYS_BEFORE_REMINDER : 1;
    }
    return remainingDays;
};
