import { plural, t } from '@lingui/macro';
import { differenceInDays, differenceInMinutes, isThisMonth, isYesterday } from 'date-fns';
import { localizedFormatDate } from 'shared/domains/common-utils';
const DATE_FORMAT = Object.freeze({
    year: 'numeric',
    month: 'short',
    day: 'numeric',
});
export const getTimePassedFromNow = (date) => {
    if (!date) {
        return '';
    }
    const NOW = new Date();
    const days = differenceInDays(NOW, date);
    const minutes = differenceInMinutes(NOW, date);
    if (minutes < 1) {
        return t({
            message: 'Less than a minute ago',
            comment: 'Approval Drawer - History tab - Event time - less than a minute',
        });
    }
    if (minutes < 60) {
        return t({
            comment: 'Approval Drawer - History tab - Event time - minutes ago',
            message: plural(minutes, {
                one: '# minute ago',
                other: '# minutes ago',
            }),
        });
    }
    if (minutes >= 60 && days < 1) {
        const hours = Math.floor(minutes / 60);
        return t({
            comment: 'Approval Drawer - History tab - Event time - hours ago',
            message: plural(hours, {
                one: '# hour ago',
                other: '# hours ago',
            }),
        });
    }
    if (isYesterday(date)) {
        return t({
            comment: 'Approval Drawer - History tab - Event time - yesterday',
            message: 'Yesterday',
        });
    }
    if (isThisMonth(date)) {
        return t({
            comment: 'Approval Drawer - History tab - Event time - days ago',
            message: plural(days, {
                one: '# day ago',
                other: '# days ago',
            }),
        });
    }
    return localizedFormatDate(date, DATE_FORMAT);
};
