import { Fragment } from 'react';
import { useDocumentMetadata } from './DocumentMetadataProvider';
import { MetadataField } from './MetadataField';
export function MetadataList() {
    const { data, activeEntries, documentId } = useDocumentMetadata();
    const entries = data.properties.filter(({ property }) => activeEntries.includes(property.name));
    return (<Fragment>
      {entries.map(({ property, pair }) => (<MetadataField key={property.id} documentId={documentId} property={property} pair={pair}/>))}
    </Fragment>);
}
