import { breakpoints, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const ChartContainer = styled.div `
  position: relative;
  display: block;
`;
export const Container = styled.div `
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 100%;
  gap: ${themeGet('spacing.2xl')};

  ${breakpoints.lg} {
    flex-direction: row;
  }
`;
