import { useState } from 'react';
import { AvatarLetter, IconButton, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { AccordionContainer, Container, HeaderContainer, HeaderLeftContainer, HeaderRightContainer, LabelsContainer, StyledAvatarWithLabel, } from './styled';
export function SectionListItem(props) {
    const { icon, primary, secondary, tertiary, endElement, accordionElement, hideAvatar = false, shape = 'circle', } = props;
    const [open, setOpen] = useState(false);
    const letter = primary ? primary[0] : '';
    const useAccordion = Boolean(accordionElement);
    const handleToggleAccordion = () => setOpen(!open);
    return (<Container $open={open}>
      <HeaderContainer>
        <HeaderLeftContainer>
          {useAccordion && (<IconButton $round size="small" appearance="ghost" onClick={handleToggleAccordion}>
              {open ? <Falcon icon="chevron-up"/> : <Falcon icon="chevron-down"/>}
            </IconButton>)}
          {tertiary || hideAvatar ? (<LabelsContainer>
              <Typography $appearance="300" $fontSize="medium" $fontWeight="medium" $lineHeight="neutralized">
                {primary}
              </Typography>
              <Typography $appearance="100" $fontSize="tiny" $fontWeight="regular" $lineHeight="neutralized">
                {secondary}
              </Typography>
              {tertiary}
            </LabelsContainer>) : (<StyledAvatarWithLabel appearance="primary" size="md" shape={shape} subtitle={secondary} title={primary}>
              {icon || <AvatarLetter>{letter}</AvatarLetter>}
            </StyledAvatarWithLabel>)}
        </HeaderLeftContainer>
        <HeaderRightContainer>{endElement}</HeaderRightContainer>
      </HeaderContainer>
      {useAccordion && open && <AccordionContainer>{accordionElement}</AccordionContainer>}
    </Container>);
}
