import { Falcon } from '@pocketlaw/tetris/macro';
import { AssistantContext } from 'app/domains/assistant/types';
export const getIconFromContext = (context) => {
    switch (context) {
        case AssistantContext.Selection:
            return <Falcon icon="square-dashed"/>;
        case AssistantContext.Document:
            return <Falcon icon="sparkles"/>;
        default:
            return null;
    }
};
