var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@apollo/client';
import { t } from '@lingui/macro';
import { AvatarLetter, AvatarList, Loader, TableBodyCell, TableCellEmpty } from '@pocketlaw/tetris';
import { UserAvatarWithLabel } from 'app/domains/users';
import { GetUsersDocument } from './users.gql';
export function ContractOwnerCell(props) {
    const { userIds = [] } = props, rest = __rest(props, ["userIds"]);
    const { data, loading } = useQuery(GetUsersDocument, {
        variables: {
            ids: userIds,
        },
        skip: !(userIds === null || userIds === void 0 ? void 0 : userIds.length),
    });
    const { users = [] } = data || {};
    const safeUsers = users.map(({ id, name }) => ({
        id,
        name: name || t({ message: 'Unknown user', comment: 'Search: Unknown user' }),
    }));
    if (loading) {
        return (<TableBodyCell {...rest}>
        <Loader />
      </TableBodyCell>);
    }
    if (!safeUsers.length) {
        return (<TableBodyCell {...rest}>
        <TableCellEmpty />
      </TableBodyCell>);
    }
    return (<TableBodyCell {...rest}>
      {safeUsers.length === 1 && <UserAvatarWithLabel solid size="sm" title={safeUsers[0].name}/>}
      {safeUsers.length > 1 && (<AvatarList size="sm" solid avatars={safeUsers.map(user => ({
                id: user.id,
                tooltip: { title: user.name },
                component: user.name && <AvatarLetter>{user.name[0]}</AvatarLetter>,
            }))}/>)}
    </TableBodyCell>);
}
