import styled from 'styled-components';
export const DrawerContainer = styled.div `
  display: flex;
  flex-direction: column;
  height: 100%;
`;
export const Content = styled.div `
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden auto;
`;
