import JsonURL from '@jsonurl/jsonurl';
function parseUrl(str) {
    if (!str) {
        return undefined;
    }
    try {
        return JsonURL.parse(str, { AQF: true });
    }
    catch (_a) {
        return undefined;
    }
}
export function useGetSearchURLData() {
    const { search } = window.location;
    const searchParams = new URLSearchParams(search);
    const filters = parseUrl(searchParams.get('filters')) || {};
    const query = searchParams.get('query') || '';
    return {
        filters,
        query,
    };
}
