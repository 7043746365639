import { useContext } from 'react';
import { PDFDataContext } from './PDFDataProvider';
export const usePDFData = () => useContext(PDFDataContext);
export const useStrictPDFData = () => {
    const context = usePDFData();
    if (!context) {
        throw new Error('usePDFData must be used as a child to <PDFDataProvider>');
    }
    return context;
};
