import { memo, useCallback, useRef } from 'react';
import { t } from '@lingui/macro';
import { Radio, Tooltip, TableRow, TableBodyCell } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import T from 'prop-types';

import { useACLCan } from 'app/domains/filesystem';
import { EntryKind, AclAction } from 'shared/domains/apollo/generated/types';

import { IconCell } from './IconCell';
import { TitleCell } from './TitleCell';
import { TooltipButton } from './styled';

function FolderPickerTableRow(props) {
  const {
    acl,
    teamAcls,
    id,
    name,
    type,
    selected,
    onSelect,
    onChange,
    movedEntryId,
    ...restProps
  } = props;

  const radioRef = useRef();
  const { canWrite, canTraverse } = useACLCan({ userAcls: acl, teamAcls });
  const isBeingMoved = movedEntryId === id;
  const hasWriteAccess = canWrite();
  const hasTraverseAccess = canTraverse();
  const isFolder = type === EntryKind.Folder;

  const disabled = (!hasWriteAccess && !hasTraverseAccess) || !isFolder || isBeingMoved;

  function getTooltipMessage() {
    if (isBeingMoved) {
      return t`This is the folder you are currently moving.`;
    }

    if (!hasWriteAccess) {
      return t`You don't have permission to move into this folder.`;
    }

    return undefined;
  }

  const handleClick = useCallback(
    event => {
      event.stopPropagation();
      onSelect(id);
    },
    [id, onSelect]
  );

  const handleChange = useCallback(
    event => {
      onChange(event.target.value);
    },
    [onChange]
  );

  return (
    <TableRow disabled={disabled} $selected={selected} {...restProps}>
      <IconCell disabled={disabled} type={type} />
      <TitleCell
        headers="folderPickerName"
        title={name}
        disabled={disabled}
        onClick={handleClick}
      />
      <TableBodyCell headers="folderPickerSelect">
        {isFolder && disabled && (
          <Tooltip title={getTooltipMessage()}>
            <TooltipButton appearance="ghost">
              <Falcon icon="info-circle" />
            </TooltipButton>
          </Tooltip>
        )}
        {!disabled && hasWriteAccess && (
          <Radio
            ref={radioRef}
            name="selected"
            value={id}
            checked={selected}
            onChange={handleChange}
          />
        )}
      </TableBodyCell>
    </TableRow>
  );
}

FolderPickerTableRow.propTypes = {
  acl: T.arrayOf(
    T.shape({
      userId: T.string.isRequired,
      actions: T.arrayOf(
        T.oneOf([AclAction.Read, AclAction.Write, AclAction.Delete, AclAction.Traverse])
      ).isRequired,
    })
  ),
  teamAcls: T.arrayOf(
    T.shape({
      teamId: T.string.isRequired,
      actions: T.arrayOf(
        T.oneOf([AclAction.Read, AclAction.Write, AclAction.Delete, AclAction.Traverse])
      ).isRequired,
    })
  ),
  id: T.string.isRequired,
  name: T.string.isRequired,
  type: T.oneOf([EntryKind.Folder, EntryKind.Document]).isRequired,
  selected: T.bool.isRequired,
  onSelect: T.func.isRequired,
  onChange: T.func.isRequired,
  movedEntryId: T.string,
};

FolderPickerTableRow.defaultProps = {
  acl: undefined,
  teamAcls: undefined,
  movedEntryId: undefined,
};

const Memoized = memo(FolderPickerTableRow);
Memoized.displayName = 'FolderPickerTableRow';

export default Memoized;
