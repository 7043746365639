import { t } from '@lingui/macro';
import { ChatResolutionStatus } from 'shared/domains/apollo/generated/types';
export const getMessageByStatus = (status) => {
    switch (status) {
        case ChatResolutionStatus.ChatNotSupported:
            return t({
                message: 'To start a new action or chat, please delete this conversation.',
                comment: 'Document assistant - when the chat has been resolved after requesting a summary - title',
            });
        case ChatResolutionStatus.ContentLengthExceeded:
            return t({
                message: 'You have reached the chat limit. Please, delete this conversation and start a new one',
                comment: 'Document assistant - when the chat has been resolved - token limit has been reached - text',
            });
        case ChatResolutionStatus.Unknown:
        default:
            return t({
                message: 'The chat is closed. Please, delete this conversation and start a new one.',
                comment: 'Document assistant - when the chat has been resolved - general message - text',
            });
    }
};
