import { gql } from '@apollo/client';
export const initialQuery = gql `
  query DownloadFilesystemDocumentData($documentId: ID!) {
    fileSystemDocument(id: $documentId) {
      id
      type
      name
      lastSignRequest {
        id
        status
      }
      files {
        id
        mimeType
      }
    }
  }
`;
