import { themeGet } from '@pocketlaw/tetris';
import styled, { css } from 'styled-components';
const activeStyles = css `
  background-color: ${themeGet('colors.background.100')};
`;
export const UserCardWrapper = styled.button `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${themeGet('spacing.lg')};
  border-radius: ${themeGet('borderRadius.md')};
  width: 100%;
  cursor: pointer;

  ${({ $isActive }) => $isActive && activeStyles}

  &:hover {
    ${activeStyles}
  }
`;
