import { useMemo, useCallback, forwardRef } from 'react';
import { useGeneratedId } from '@pocketlaw/tetris';
import { UPLOAD_DOCUMENT_SUPPORTED_MIME_TYPES } from 'app/domains/upload';
import { Input } from './styled';
export const FileUpload = forwardRef((props, ref) => {
    const { id: controlledId, name = 'files', accept, onChange, multiple, disabled } = props;
    const generatedId = useGeneratedId('fileInput');
    const id = useMemo(() => controlledId || generatedId, [controlledId, generatedId]);
    const handleChange = useCallback((event) => {
        onChange(event);
        // eslint-disable-next-line no-param-reassign
        event.target.value = '';
    }, [onChange]);
    return (<Input ref={ref} accept={(accept || UPLOAD_DOCUMENT_SUPPORTED_MIME_TYPES).join(',')} id={id} tabIndex={-1} type="file" name={name} onChange={handleChange} readOnly multiple={multiple} disabled={disabled}/>);
});
