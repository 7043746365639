import { IconButton, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
import { Node } from '../styled';
export const StyledIconButton = styled(IconButton) `
  display: none;
  margin-left: ${themeGet('spacing.md')};
`;
export const StyledNode = styled(Node) `
  &:hover {
    ${StyledIconButton} {
      display: flex;
    }
  }
`;
