import { DropdownView } from 'pleditor';
import { PleditorPlugin } from '../PleditorPlugin';
export class TopToolbar extends PleditorPlugin {
    constructor(editor) {
        super(editor);
        this.updateToolbarDropdownPanelPositions = () => {
            const editor = this.editor;
            // Sets the dropdown panel position anchor to the bottom right corner for each nested dropdown.
            // Then we remove the listener because we only have to do it once.
            const onNestedDropdownOpen = ({ source }) => {
                if (source instanceof DropdownView && source.toolbarView) {
                    source.toolbarView.items.forEach(item => {
                        if (item instanceof DropdownView) {
                            item.set('panelPosition', 'sw');
                            source.off('change:isOpen', onNestedDropdownOpen);
                        }
                    });
                }
            };
            // Sets the dropdown panel position anchor to the bottom right corner.
            // We also listen to the first time a nested dropdown is opened.
            //
            // We do it this way because for some reason the item is not the correct class (?) in this forEach
            // but it is correct in the 'change:isOpen' callback
            editor.ui.view.toolbar.items.forEach(item => {
                if (item instanceof DropdownView) {
                    item.set('panelPosition', 'sw');
                    item.on('change:isOpen', onNestedDropdownOpen);
                }
            });
        };
        this.editor.config.define('balloonToolbar', this.editor.config.get('toolbar'));
    }
    init() {
        const editor = this.editor;
        editor.on('ready', () => {
            var _a, _b, _c;
            const toolbarContainer = document.getElementById('pleditor-toolbar');
            const toolbarUi = (_c = (_b = (_a = editor.ui) === null || _a === void 0 ? void 0 : _a.view) === null || _b === void 0 ? void 0 : _b.toolbar) === null || _c === void 0 ? void 0 : _c.element;
            if (toolbarContainer && toolbarUi) {
                toolbarContainer.appendChild(toolbarUi);
            }
        }, { priority: 'lowest' });
        editor.on('ready', this.updateToolbarDropdownPanelPositions, { priority: 'lowest' });
    }
    destroy() {
        var _a, _b, _c;
        const editor = this.editor;
        const toolbarContainer = document.getElementById('pleditor-toolbar');
        const toolbarUi = (_c = (_b = (_a = editor.ui) === null || _a === void 0 ? void 0 : _a.view) === null || _b === void 0 ? void 0 : _b.toolbar) === null || _c === void 0 ? void 0 : _c.element;
        if (toolbarUi && (toolbarContainer === null || toolbarContainer === void 0 ? void 0 : toolbarContainer.contains(toolbarUi))) {
            toolbarContainer === null || toolbarContainer === void 0 ? void 0 : toolbarContainer.removeChild(toolbarUi);
        }
    }
}
