import { select, t } from '@lingui/macro';
import { AvatarWithLabel, AvatarLetter, List } from '@pocketlaw/tetris';
import { useAccessByComposers } from '../../hooks/useAccessByComposers';
import { AccessMenuButton } from '../AccessMenuButton';
import { Empty } from '../Empty';
import { Error } from '../Error';
import { Loading } from '../Loading';
import { UpdateTeamAccessMenuItem } from '../UpdateTeamAccessMenuItem';
import { StyledListItem } from './styled';
export function TeamAccessList(props) {
    const { teams, accessDropdownPermission } = props;
    const { loading, error } = useAccessByComposers();
    const canAddPermission = accessDropdownPermission === 'noAccess' || accessDropdownPermission === 'partialAccess';
    const canRemovePermission = accessDropdownPermission === 'access' || accessDropdownPermission === 'partialAccess';
    if (error) {
        return (<Error title={t({
                message: 'Error',
                comment: 'template permission drawer: failed to load teams alert title',
            })} message={t({
                message: 'Failed to load teams. Please try again later.',
                comment: 'template permission drawer: failed to load teams alert message',
            })}/>);
    }
    if (loading) {
        return <Loading />;
    }
    if (teams.length < 1) {
        return (<Empty title={t({
                comment: 'Contract access tab empty content: Title',
                message: 'Empty in here.',
            })} subtitle={t({
                comment: 'Contract team access tab empty content: Subtitle',
                message: select(accessDropdownPermission, {
                    access: 'No teams with access.',
                    partialAccess: 'No teams with partial access.',
                    noAccess: 'No teams with no access.',
                    other: 'No teams with no access.',
                }),
            })}/>);
    }
    return (<List $density="large">
      {teams.map(({ id, name }) => {
            const firstLetter = name.charAt(0);
            return (<StyledListItem key={id}>
            <AvatarWithLabel appearance="magenta" size="md" title={name} subtitle={t({
                    comment: 'This is the word for team',
                    message: 'Team',
                })}>
              <AvatarLetter>{firstLetter}</AvatarLetter>
            </AvatarWithLabel>
            <AccessMenuButton>
              {canAddPermission && <UpdateTeamAccessMenuItem type="add" teamIds={[id]}/>}
              {canRemovePermission && <UpdateTeamAccessMenuItem type="remove" teamIds={[id]}/>}
            </AccessMenuButton>
          </StyledListItem>);
        })}
    </List>);
}
