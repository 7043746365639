import { useMemo } from 'react';
const isTopLevelGroupField = (field) => 
// eslint-disable-next-line no-underscore-dangle
field.__typename === 'GroupField' && 'fields' in field;
const getChildFieldValues = (fields, values) => fields.map(field => ({
    [field.refId]: values[field.refId],
}));
const getFieldValues = (fields, values) => (fields === null || fields === void 0 ? void 0 : fields.filter(isTopLevelGroupField).flatMap(field => getChildFieldValues(field.fields, values))) || [];
export const useBranchValues = (branch, values) => useMemo(() => {
    const branchValues = {};
    const fieldValues = getFieldValues(branch === null || branch === void 0 ? void 0 : branch.fields, values);
    fieldValues.forEach(fieldValue => {
        Object.entries(fieldValue).forEach(([key, value]) => {
            branchValues[key] = value;
        });
    });
    return branchValues;
}, [branch, values]);
