import { Fragment } from 'react';
import { IconButton, Loader, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Content, Header, HeaderTexts, LoaderContainer, StyledDrawer } from './styled';
export function TemplateDrawer(props) {
    const { header, subheader, children, loading, onClose } = props;
    return (<StyledDrawer open position="right">
      {loading ? (<LoaderContainer>
          <Loader size={8}/>
        </LoaderContainer>) : (<Fragment>
          <Header>
            <HeaderTexts>
              <Typography $fontSize="large" $appearance="300">
                {header}
              </Typography>
              <Typography $fontSize="small" $appearance="100">
                {subheader}
              </Typography>
            </HeaderTexts>
            <IconButton $round size="medium" appearance="ghost" onClick={onClose}>
              <Falcon icon="xmark"/>
            </IconButton>
          </Header>
          <Content>{children}</Content>
        </Fragment>)}
    </StyledDrawer>);
}
