import { gql } from '@apollo/client';
export const docxToPdfDocumentData = gql `
  query DocxToPdfDocumentData($documentId: ID!) {
    fileSystemDocument(id: $documentId) {
      id
      files {
        id
        mimeType
        path
        size
      }
    }
  }
`;
