import { t, select } from '@lingui/macro';
import { getItemType } from './getItemType';
export function getSearchPlaceholder(usersAndTeams) {
    const { users, teams } = usersAndTeams;
    const itemType = getItemType({ users, teams });
    return t({
        message: select(itemType, {
            usersAndTeams: 'Search users & teams',
            users: 'Search users',
            teams: 'Search teams',
            other: 'Search users & teams',
        }),
        comment: 'users & team select: placeholder for search input depending on if the list contains users, teams, or both',
    });
}
