import { themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  z-index: 1;
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${themeGet('spacing.2xl')};
  background-color: ${themeGet('colors.background.50')};
`;
export const HeaderTexts = styled.div `
  display: flex;
  flex-direction: column;
`;
