import { gql } from '@apollo/client';
export const createMutation = gql `
  mutation UpsertTemplateAttachment($objects: [TaskTemplateInsertInput!]!) {
    createTaskTemplate(objects: $objects) {
      returning {
        id
        taskId: task_id
        templateId: template_id
      }
    }
  }
`;
export const removeMutation = gql `
  mutation DeleteTemplateAttachment($where: TaskTemplateBoolExp!) {
    deleteTaskTemplate(where: $where) {
      affected_rows
    }
  }
`;
