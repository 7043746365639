import { t } from '@lingui/macro';
import { Box, EmptyState } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
export function Unsupported() {
    const title = t({
        message: 'Unsupported',
        comment: 'Insights: Unsupported aggregation used for chart title',
    });
    const description = t({
        message: 'The type of data point used is not supported by this chart.',
        comment: 'Insights: Unsupported aggregation used for chart - description',
    });
    return (<Box flexDirection="column" justifyContent="center" width="100%">
      <EmptyState withAvatar title={title} description={description} icon={<Falcon icon="chart-line-up"/>}/>
    </Box>);
}
