import { Fragment } from 'react';
import { localeConfig } from 'shared/domains/languages/config';
export function Language(props) {
    const { code, textInLocalLanguage = false } = props;
    const locale = localeConfig.getLocale({ code });
    if (!locale) {
        return null;
    }
    if (textInLocalLanguage) {
        return <Fragment>{locale.getNameInLocalLanguage()}</Fragment>;
    }
    return <Fragment>{locale.getName()}</Fragment>;
}
