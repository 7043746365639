import { Pagination } from './Pagination';
import { Zoom } from './Zoom';
import { Container, LeftContainer, MiddleContainer, RightContainer } from './styled';
export function Toolbar() {
    return (<Container>
      <LeftContainer>
        <Zoom />
      </LeftContainer>
      <MiddleContainer />
      <RightContainer>
        <Pagination />
      </RightContainer>
    </Container>);
}
