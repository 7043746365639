import { TableBodyCell, TableRow, Typography } from '@pocketlaw/tetris';
import { useField } from 'formik';
import { OwnerAccessAlert } from '../../../OwnerAccessAlert';
import { AlertContainer } from './styled';
export function ComposerBody(props) {
    const { composers } = props;
    const [field] = useField('role');
    const isRoleOwner = field.value === 'owner';
    if (isRoleOwner) {
        return (<TableRow>
        <TableBodyCell headers="composers">
          <AlertContainer>
            <OwnerAccessAlert role={field.value}/>
          </AlertContainer>
        </TableBodyCell>
      </TableRow>);
    }
    return composers.map(composer => (<TableRow key={composer.id}>
      <TableBodyCell headers="composers">
        <Typography $appearance="300">{composer.title}</Typography>
      </TableBodyCell>
    </TableRow>));
}
