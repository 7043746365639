import { Fragment } from 'react';
import { IconButton, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useMobile } from 'shared/domains/common-ui';
import { getLabels } from './utils';
import { useComposerData } from '../../ComposerDataProvider';
import { Filters } from '../Filters';
import { Container, HeaderTexts } from './styled';
export function Header() {
    const { closeDialog, searchQuery, templatesFilter } = useComposerData();
    const { title, description } = getLabels(searchQuery, templatesFilter);
    const isMobile = useMobile();
    return (<Fragment>
      <Container>
        <HeaderTexts>
          <Typography $appearance="300" $fontSize="large" $fontWeight="semibold">
            {title}
          </Typography>
          {!isMobile && (<Typography $appearance="200" $fontSize="small" $lineHeight="neutralized">
              {description}
            </Typography>)}
        </HeaderTexts>
        <IconButton $round appearance="ghost" onClick={closeDialog}>
          <Falcon icon="xmark"/>
        </IconButton>
      </Container>
      {isMobile && <Filters />}
    </Fragment>);
}
