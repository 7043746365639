import { ButtonGroup } from '@pocketlaw/tetris';
import { useRouteMatch } from 'react-router-dom';
import { useMount } from 'react-use';
import { useMobile } from 'shared/domains/common-ui';
import { useDocumentDrawer, usePreview, ActiveUsers } from 'shared/domains/documents';
import { AssistantButton } from './AssistantButton';
import { EditButton } from './EditButton';
import { PlaiDropdownButton } from './PlaiDropdownButton';
import { SandboxButton } from './SandboxButton';
import { SettingsButton } from './SettingsButton';
import { Actions } from './styled';
export function DocumentActions() {
    const { openDrawer } = useDocumentDrawer();
    const { isComposed, isFreeText, isPdf, isDocx } = usePreview();
    const isMobile = useMobile();
    const isAssistantSupported = isComposed || isFreeText || isPdf || isDocx;
    const isReviewAgentSupported = isComposed || isFreeText || isDocx;
    const assistantMatch = useRouteMatch('/document/:id/assistant');
    useMount(() => {
        if (assistantMatch) {
            openDrawer('app:assistant');
        }
        else if (!isMobile) {
            openDrawer('app:settings');
        }
    });
    return (<Actions>
      <ActiveUsers />
      <EditButton />
      <SandboxButton />
      {(isAssistantSupported || isReviewAgentSupported) && (<ButtonGroup>
          {isAssistantSupported && <AssistantButton />}
          {isReviewAgentSupported && <PlaiDropdownButton />}
        </ButtonGroup>)}
      <SettingsButton />
    </Actions>);
}
