import { t } from '@lingui/macro';
import { TableHead, TableRow, TableHeadCell, TableHeaderText, VisuallyHidden, } from '@pocketlaw/tetris';
import { DesktopTableHeaderCell, MobileTableHeaderCell } from '../styled';
export function TeamsHeader() {
    return (<TableHead>
      <TableRow>
        <MobileTableHeaderCell id="dropdown">
          <VisuallyHidden>
            {t({
            message: 'Dropdown',
            comment: 'Teams table header column dropdown - screen reader text',
        })}
          </VisuallyHidden>
        </MobileTableHeaderCell>
        <TableHeadCell id="name">
          <TableHeaderText sortId="name">
            {t({ message: 'Name', comment: 'Teams table header column name' })}
          </TableHeaderText>
        </TableHeadCell>
        <DesktopTableHeaderCell id="createdAt">
          <TableHeaderText sortId="createdAt">
            {t({ message: 'Date added', comment: 'Teams table header column created at' })}
          </TableHeaderText>
        </DesktopTableHeaderCell>
        <DesktopTableHeaderCell id="members">
          <TableHeaderText>
            {t({ message: 'Members', comment: 'Teams table header column members' })}
          </TableHeaderText>
        </DesktopTableHeaderCell>
        <TableHeadCell id="menu">
          <VisuallyHidden>
            {t({ message: 'menu', comment: 'Teams table header column menu - screen reader text' })}
          </VisuallyHidden>
        </TableHeadCell>
      </TableRow>
    </TableHead>);
}
