import { useEffect, useRef } from 'react';
import { Form, Formik } from 'formik';
import { usePrevious } from 'react-use';
import { AssistantViewType, useAssistant } from 'app/domains/assistant';
import { ChatMessageStatus } from 'shared/domains/apollo/generated/types';
import { ChatInput } from './ChatInput';
import { useDefaultChatType } from './hooks';
import { schema } from './schema';
import { Container } from './styled';
export function AssistantChatActions() {
    const { context, addComment, view, thread } = useAssistant();
    const textInputRef = useRef(null);
    const isAssistantReplying = Boolean(thread.find(message => message.status === ChatMessageStatus.Pending));
    const wasAssistantReplying = usePrevious(isAssistantReplying);
    const isAssistantDone = wasAssistantReplying && !isAssistantReplying;
    useEffect(() => {
        var _a;
        if (isAssistantDone) {
            (_a = textInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [isAssistantDone]);
    const initialValues = {
        message: '',
    };
    const defaultChatType = useDefaultChatType(context);
    const handleSubmit = (values, { resetForm }) => {
        const type = view === AssistantViewType.Chat ? null : defaultChatType;
        resetForm();
        const comment = {
            message: values.message,
            type,
        };
        addComment(comment, {});
    };
    return (<Container>
      <Formik validateOnMount validationSchema={schema} initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <ChatInput ref={textInputRef}/>
        </Form>
      </Formik>
    </Container>);
}
