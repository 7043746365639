import { t } from '@lingui/macro';
import { string, array, boolean, number } from 'yup';
/**
 * Converts a JSON schema (MetadataPropertySchema) into a Yup validation schema
 * @param schema The JSON schema object
 */
export function convertJsonSchemaToYup(schema) {
    switch (schema.type) {
        case 'string': {
            let stringSchema = string();
            if (schema.minLength !== undefined) {
                const { minLength } = schema;
                stringSchema = stringSchema.min(minLength, t({
                    message: `Minimum length is ${minLength}`,
                    comment: 'Metadata: Minimum length error message',
                }));
            }
            if (schema.maxLength !== undefined) {
                const { maxLength } = schema;
                stringSchema = stringSchema.max(maxLength, t({
                    message: `Maximum length is ${maxLength}`,
                    comment: 'Metadata: Maximum length error message',
                }));
            }
            if (schema.pattern) {
                stringSchema = stringSchema.matches(new RegExp(schema.pattern), t({
                    message: 'Invalid string pattern',
                    comment: 'Metadata: The string does not match the expected regex pattern',
                }));
            }
            if (schema.enum) {
                stringSchema = stringSchema.oneOf(schema.enum, t({
                    message: 'Please select a valid value',
                    comment: 'Metadata: The string is not a valid enum value',
                }));
            }
            return stringSchema;
        }
        case 'number': {
            const numberSchema = number();
            return numberSchema;
        }
        case 'boolean': {
            return boolean();
        }
        case 'array': {
            let arraySchema = array();
            if (schema.items) {
                const itemSchema = convertJsonSchemaToYup(schema.items); // Recursive conversion for items
                arraySchema = arraySchema.of(itemSchema);
            }
            if (schema.minItems !== undefined) {
                const { minItems } = schema;
                arraySchema = arraySchema.min(minItems, t({
                    message: `Minimum items is ${minItems}`,
                    comment: 'Metadata: Minimum items error message',
                }));
            }
            return arraySchema;
        }
        default:
            throw new Error(`Unsupported type: ${schema.type}`);
    }
}
