import { Falcon } from '@pocketlaw/tetris/macro';
import { isFreeTextDocument, isUploadedDocument } from 'shared/domains/documents';
export function getDocumentIcon(document) {
    if (isFreeTextDocument(document)) {
        return <Falcon icon="file-edit"/>;
    }
    if (isUploadedDocument(document)) {
        return <Falcon icon="file-upload"/>;
    }
    return <Falcon icon="file-alt"/>;
}
