import { t } from '@lingui/macro';
import { CreateTeamErrorCodes } from 'app/domains/teams';
export function CreateTeamErrorMessages(error) {
    const [gqlError] = (error === null || error === void 0 ? void 0 : error.graphQLErrors) || [];
    const { subcode } = (gqlError === null || gqlError === void 0 ? void 0 : gqlError.extensions) || {};
    switch (subcode) {
        case CreateTeamErrorCodes.DUPLICATE_ENTITY:
            return t({
                message: 'Team name already exists. Team names must be unique.',
                comment: 'Create new team modal - error message when team name already exists',
            });
        default:
            return t({
                message: 'Something went wrong, please try again!',
                comment: 'Alert error message when create team fails',
            });
    }
}
