import { t } from '@lingui/macro';
import { ConfirmDialog } from '@pocketlaw/tetris';
export function ConfirmRemoveDialog(props) {
    const { onCancel, onConfirm } = props;
    const heading = t({
        comment: 'Remove signing party confirm dialog - title',
        message: 'Remove party',
    });
    const confirm = t({
        comment: 'Remove signing party confirm dialog - confirm button label',
        message: 'Remove',
    });
    const cancel = t({
        comment: 'Remove signing party confirm dialog - cancel button label',
        message: 'Cancel',
    });
    const message = t({
        comment: 'Remove signing party confirm dialog - message',
        message: 'Are you sure you want to remove this party?',
    });
    return (<ConfirmDialog size="sm" appearance="danger" heading={heading} confirm={confirm} cancel={cancel} onConfirm={onConfirm} onCancel={onCancel}>
      {message}
    </ConfirmDialog>);
}
