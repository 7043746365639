import { themeGet, pxToRem } from '@pocketlaw/tetris';
import { createGlobalStyle } from 'styled-components';
export const ComposedHighlightStyles = createGlobalStyle `
  .ck.pleditor-composer-highlight [data-highlight] {
    color: ${themeGet('colors.text.300')};

    &:not(:empty) {
      padding: ${pxToRem(1)};
      background-color: ${themeGet('colors.yellow.200')};
      border-radius: ${pxToRem(1)};

      &:focus-visible {
        position: relative;
        z-index: 1;
        outline: none;
        box-shadow: 0 0 0 ${pxToRem(4)} ${themeGet('colors.yellow.300')};
      }
    }

    > * {
      background: transparent;
    }
  }

  .ck.pleditor-composer-highlight div[data-highlight]:not(:empty) {
    background-color: ${themeGet('colors.yellow.50')};
  }
`;
