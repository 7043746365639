var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { plural } from '@lingui/macro';
import { TableBodyCell, TableCellEmpty, TableCellText } from '@pocketlaw/tetris';
export function PartiesCell(props) {
    const { data } = props, rest = __rest(props, ["data"]);
    const items = Array.isArray(data) ? data : [];
    const itemsCount = items.length;
    if (!itemsCount) {
        return (<TableBodyCell {...rest}>
        <TableCellEmpty />
      </TableBodyCell>);
    }
    const secondary = plural(itemsCount, {
        one: '1 party',
        other: '# parties',
    });
    return (<TableBodyCell maxWidth={200} {...rest}>
      <TableCellText primary={items.join(', ')} secondary={secondary}/>
    </TableBodyCell>);
}
