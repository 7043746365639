import { t } from '@lingui/macro';
export const getLabels = (type) => {
    const genericLabels = {
        alreadyApproved: t({
            comment: 'Handle approval request dialog error message - Already approved error',
            message: 'This document has already been approved by someone else.',
        }),
        alreadyCancelled: t({
            comment: 'Handle approval request dialog error message - Already cancelled error',
            message: 'This document has already been cancelled by someone else.',
        }),
        alreadyRejected: t({
            comment: 'Handle approval request dialog error message - Already rejected error',
            message: 'This document has already been rejected by someone else.',
        }),
        close: t({
            comment: 'Handle approval request dialog - Close button label',
            message: 'Close',
        }),
        cancel: t({
            comment: 'Handle approval request dialog - Cancel button label',
            message: 'Cancel',
        }),
        confirm: t({
            comment: 'Handle approval request dialog - Confirm button label',
            message: 'Confirm',
        }),
    };
    const approveLabels = Object.assign(Object.assign({}, genericLabels), { requestError: t({
            comment: 'Approve approval request dialog error message - Approve request error',
            message: 'Something went wrong while approving the approval request, please try again.',
        }), title: t({
            comment: 'Approve approval request dialog - Dialog title',
            message: 'Approve approval request',
        }), body: t({
            comment: 'Approve approval request dialog - Dialog body',
            message: 'If you continue the pending approval request will be approved.',
        }), checkbox: t({
            comment: 'Approve approval request dialog - Dialog checkbox label',
            message: 'Yes I want to approve the approval request.',
        }) });
    const rejectLabels = Object.assign(Object.assign({}, genericLabels), { requestError: t({
            comment: 'Reject approval request dialog error message - Reject request error',
            message: 'Something went wrong while rejecting the approval request, please try again.',
        }), title: t({
            comment: 'Reject approval request dialog - Dialog title',
            message: 'Reject approval request',
        }), body: t({
            comment: 'Reject approval request dialog - Dialog body',
            message: 'If you continue the pending approval request will be cancelled.',
        }), checkbox: t({
            comment: 'Reject approval request dialog - Dialog checkbox label',
            message: 'Yes I want to reject the approval request.',
        }) });
    return type === 'approve' ? approveLabels : rejectLabels;
};
