import gql from 'graphql-tag';
export const query = gql `
  query pendingInvitesAfterInviteSent {
    pendingInvites: findUserInvitesByExpired(
      args: { expired: false }
      where: { accepted_by_user_id: { _is_null: true } }
      order_by: [{ email: asc }, { created_at: desc }]
      distinct_on: [email]
    ) {
      id
      email
    }
  }
`;
