import { Drawer, SidebarMenu, IconButton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useMobileNavigation } from '../../MobileNavigation';
import { Body } from '../Body';
import { Footer } from '../Footer';
import { Header } from '../Header';
export function Mobile() {
    const { isOpen, close } = useMobileNavigation();
    return (<Drawer open={isOpen} position="left">
      <SidebarMenu>
        <Header mobileAction={<IconButton appearance="ghost" size="small" onClick={close}>
              <Falcon icon="close"/>
            </IconButton>}/>
        <Body />
        <Footer />
      </SidebarMenu>
    </Drawer>);
}
