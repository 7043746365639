import { createContext, useContext } from 'react';
import { insightsDashboardMock } from '../../mocks';
export const dashboardTemplates = [insightsDashboardMock('general')];
export const Context = createContext(undefined);
export const useInsightsTemplates = () => {
    const context = useContext(Context);
    if (!context) {
        throw new Error('useInsightsTemplates must be used within InsightsTemplateProvider');
    }
    return context;
};
