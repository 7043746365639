import { useState } from 'react';
const initialState = {
    sortId: 'name',
    sortOrder: 'asc',
};
export default () => {
    const [state, setState] = useState(initialState);
    const sort = (sortId, sortOrder) => {
        setState({ sortId, sortOrder });
    };
    return [state, { sort }];
};
