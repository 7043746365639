import { TextArea } from '@pocketlaw/tetris';
import { useField } from 'formik';
import { useEsigning } from 'app/domains/esigning/components/EsigningProvider';
import { FieldContainer } from './styled';
export function GroupMessageField() {
    const { submittingCreation } = useEsigning();
    const [message, , { setValue }] = useField('globals.groupMessage');
    const handleBlur = (event) => {
        setValue(event.target.value.trim());
    };
    return (<FieldContainer>
      <TextArea disabled={submittingCreation} {...message} onBlur={handleBlur}/>
    </FieldContainer>);
}
