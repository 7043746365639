import { t } from '@lingui/macro';
import { Dialog } from '@pocketlaw/tetris';
import { Formik } from 'formik';
import { DialogContent } from './DialogContent';
import { DialogFooter } from './DialogFooter';
import { DialogHeader } from './DialogHeader';
import { useGetEmptySignatory } from './useGetEmptySignatory';
import { useGetSignatorySchema } from './useGetSignatorySchema';
import { isEmailDuplicate } from './utils';
export function ESignSignatoryFormDialog(props) {
    const { type, initialValues, allSignatories, hideSaveContact = false, newSignatoryIsNonSigning = false, onClose, onSubmit, } = props;
    const emptyInitialValues = useGetEmptySignatory(type, !newSignatoryIsNonSigning);
    const isEdit = initialValues !== undefined;
    const schema = useGetSignatorySchema();
    const handleSubmit = (values, helpers) => {
        if (isEmailDuplicate(values, allSignatories)) {
            helpers.setSubmitting(false);
            helpers.setFieldError('email', t({
                comment: 'Error message for the user when they add a signing party with the same email as another signing party',
                message: 'You have already added another signing party with this email',
            }));
        }
        else {
            onSubmit(values);
            onClose();
        }
    };
    const label = isEdit
        ? t({ message: 'Edit party', comment: 'Title for edit party dialog' })
        : t({ message: 'Add party', comment: 'Title for add party dialog' });
    return (<Formik validationSchema={schema} initialValues={initialValues || emptyInitialValues} onSubmit={handleSubmit}>
      <Dialog size="lg">
        <DialogHeader title={label} onClose={onClose}/>
        <DialogContent type={type} hideSaveContact={hideSaveContact}/>
        <DialogFooter submitLabel={label} onClose={onClose}/>
      </Dialog>
    </Formik>);
}
