var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { TableBodyCell } from '@pocketlaw/tetris';
import { SearchResultActionMenu } from 'app/domains/global-search';
import { ACTION_CELL_WIDTH } from '../../constants';
export function ActionCell(props) {
    const { result } = props, rest = __rest(props, ["result"]);
    return (<TableBodyCell maxWidth={ACTION_CELL_WIDTH} {...rest}>
      <SearchResultActionMenu cacheId={result.id} entityId={result.entityId} entityType={result.entityType} 
    // eslint-disable-next-line no-underscore-dangle
    typename={result.__typename || ''}/>
    </TableBodyCell>);
}
