import { useQuery } from '@apollo/client';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { DuplicateDocumentDialogDocument } from './query.gql';
export function useDuplicateDocumentDialog(id) {
    var _a;
    const account = useAccount();
    const companyId = (_a = account.data) === null || _a === void 0 ? void 0 : _a.companyId;
    return useQuery(DuplicateDocumentDialogDocument, {
        variables: { id, companyId },
    });
}
