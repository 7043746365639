import { plural } from '@lingui/macro';
import { Alert } from '@pocketlaw/tetris';
import { useEsigningProviderMetadata } from 'app/domains/esigning/hooks/useEsigningProviderMetadata';
import { useLastSignRequest } from 'app/domains/esigning/hooks/useLastSignRequest';
import { getRemainingDays } from './utils';
export function AutomaticReminderAlert() {
    const { properties: providerProperties } = useEsigningProviderMetadata();
    const { canUseAutomaticReminder } = providerProperties;
    const { lastSignRequest } = useLastSignRequest();
    const { createdAt } = lastSignRequest || {};
    if (!createdAt || !canUseAutomaticReminder) {
        return null;
    }
    const remainingDays = getRemainingDays(new Date(createdAt), new Date());
    const message = plural(remainingDays, {
        one: 'Automatic reminder will be sent out to unsigned parties in # day.',
        other: 'Automatic reminder will be sent out to unsigned parties in # days.',
    });
    return <Alert data-testid="automatic-reminder-alert" appearance="info" message={message}/>;
}
