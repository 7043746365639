export const swedishSystemPrompts = [
    {
        id: '4be11bbe-756b-4ccb-8e84-1c874cf571a1',
        name: 'Summera sekretessavtal (NDA)',
        content: 'I 200 ord, vänligen summera:\nAlla straff för brott; alla icke-solicitering klausuler; och löptiden för detta avtal',
        labels: ['NDA', 'Swedish'],
    },
    {
        id: '9ff5b010-8c2c-4df9-8395-daaea2c23059',
        name: 'Skapa ett utkast för ett e-post till vår IT direktör',
        content: 'Skriv ett brev till vår IT-direktör, fråga hur deras vecka går och summera deras ansvarsområden om de har signerat detta avtal för företaget på 150 ord',
        labels: ['NDA', 'Email', 'Swedish'],
    },
    {
        id: 'c9cd29d9-42e4-4f94-918e-697f5288b785',
        name: 'Borde vårt legal team granska detta avtal?',
        content: 'Avtalet kräver en fullständig eller delvis granskning av juridiska teamet där:\n1. Totalvärdet av avtalet överstiger £100,000 (till exempel ett avtal för £40,000 per år för 3 år skulle uppfylla detta, men inte om längden bara är 2 år);\n2. Avtalet är längre än 3 år;\n3. Avtalet innehåller någon förordning om intellektuell egendom;\n4. Avtalet är för försäljning eller köp av mark (inklusive lån)\n\nGranska detta avtal och berätta för mig i 50 ord eller mindre om och varför detta avtal ska granskas av juridiska teamet?',
        labels: ['Supplier Review', 'Swedish'],
    },
    {
        id: 'ad85b740-85e8-4e50-b67f-dabe83d96a68',
        name: 'Generell granskning av viktiga datum',
        content: 'Vänligen granska dokumentet och sammanfatta viktiga datum i en enkel punktlista. Gör detta på max 120 ord.',
        labels: ['Supplier Review', 'Swedish'],
    },
    {
        id: '6985add2-08c0-46f0-919d-6ec156132680',
        name: 'Generell granskning av leverantörsvillkor',
        content: 'Vänligen granska dokumentet fr följande och fetmarkera varje rubrik i ditt svar:\n\nBetalningsvillkor: Betalningsvillkoren måste vara minst 60 dagar från mottagandet av fakturan. Ange i 10 ord: uppfyller detta avtal kravet?\n\nLptid: Om det inte är mjligt att avsluta avtalet fr bekvämlighet rekommenderar vi inte att teckna ett avtal som är längre än 12 månader. Ange i 35 ord: vad gäller enligt detta avtal och vad borde vi då gra?\n\nAnsvar: Vänligen granska ansvarsbegränsningar i detta dokument och sammanfatta potentiella risker kring det i punktform, på hgst 100 ord.\n\nUppsägning: Vänligen specificera mjligheterna till uppsägning av detta avtal i enkla punktlistor på max 100 ord.\n\nServicenivåavtal (SLA): Vilka SLA går leverantren med på i detta avtal? Om det inte finns några åtaganden om servicenivå (SLA), vänligen notera det. Svara på detta med max 50 ord.\n\nEventuella ovanliga klausuler: Finns det något utver vad jag redan har frågat som är ovanligt fr ett leverantrsavtal, som jag bр överväga att granska?',
        labels: ['Supplier Review', 'Swedish'],
    },
    {
        id: 'c7c7880f-711f-47cf-aa2b-2b0af6a6d87e',
        name: 'Allmän granskning av ansvar',
        content: 'Vänligen kontrollera om detta avtal skulle uppfylla vårt krav om ansvarsbegränsning på minst SEK5,000,000, och om det inte gör det, vänligen utforma en klausul som adresserar detta.',
        labels: ['Supplier Review', 'Swedish'],
    },
    {
        id: '21fdb010-0546-42ae-86a2-bc9442166453',
        name: 'E-post till intern avtalsägare',
        content: 'Vänligen utforma ett e-post till avtalets ägare att markera följande information:\nHuvudsakliga ansvarsområden ur ett ekonomiskt perspektiv om vi kper från denna leverantr SLA och leveranser som måste utfras Licensiering, immateriella rättigheter och användning av logotyper Uppsägning och vikiga datum\n\nGrà detta på 150 ord och fråga dem hur de mår i början.',
        labels: ['Supplier Review', 'Email', 'Swedish'],
    },
].map(props => (Object.assign(Object.assign({ isSystemPrompt: true, isPublic: true }, props), { __typename: 'DocumentAssistantPrompt' })));
