import { useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { AvatarWithLabel, Box, Button, Calendar, LoaderOverlay, Menu } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useDocumentNoticeDate } from 'app/domains/metadata';
import { TasksProvider } from 'app/domains/tasks';
import { useActiveDocument } from 'shared/domains/documents';
import { localeConfig } from 'shared/domains/languages/config';
import { StyledCard } from './styled';
export function NoticeDate() {
    const { id } = useActiveDocument();
    const [open, setOpen] = useState(false);
    const localeCode = localeConfig.getActiveLocale().code;
    const { data, savingData, update } = useDocumentNoticeDate({
        documentId: id,
        onCompleted: () => setOpen(false),
    });
    const { localizedDate, value, warning } = data;
    if (!warning) {
        return null;
    }
    return (<TasksProvider documentId={id}>
      <Box mb="xl">
        <StyledCard>
          <Box p="2xl" flexDirection="column" gap="xl">
            <AvatarWithLabel solid title={t({
            message: 'Upcoming notice date',
            comment: 'Actionable insights: Document notice date title',
        })} subtitle={t({
            message: `Notice date is set to ${localizedDate}`,
            comment: 'Actionable insights: Document notice date title',
        })} size="xl" appearance="warning" alignment="vertical">
              <Falcon icon="circle-exclamation"/>
            </AvatarWithLabel>
            <Box alignItems="center" justifyContent="center" gap="xl">
              <Menu open={open} onClose={() => setOpen(false)} target={<Button size="small" appearance="ghost" onClick={() => setOpen(!open)}>
                    <Trans comment="Actionable insights: Update notice date">Update</Trans>
                  </Button>}>
                <LoaderOverlay transparent enabled={savingData}>
                  <Calendar value={value} locale={localeCode} onChange={update}/>
                </LoaderOverlay>
              </Menu>
            </Box>
          </Box>
        </StyledCard>
      </Box>
    </TasksProvider>);
}
