function isSameEmail(email) {
    return (invite) => invite.email === email;
}
function isSameEmailAndNotExpired(email) {
    return (invite) => invite.email === email && invite.expired === false;
}
export function removeDuplicates(invites) {
    return invites.filter((invite, index) => {
        const emailHasNonExpiredInviteIndex = invites.findIndex(isSameEmailAndNotExpired(invite.email));
        // findIndex will always find first item in the array, preventing duplicates when compared with index
        if (emailHasNonExpiredInviteIndex !== -1) {
            // prioritize non-expired invites as they're more important to see than expired ones
            return index === invites.findIndex(isSameEmailAndNotExpired(invite.email));
        }
        return index === invites.findIndex(isSameEmail(invite.email));
    });
}
