import { CardContent, pxToRem, themeGet, breakpoints, Typography } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const StyledCardContent = styled(CardContent) `
  padding-top: ${themeGet('spacing.2xl')};
`;
export const Container = styled.div `
  margin: 0 auto;

  ${breakpoints.md} {
    margin: 0;
  }
`;
export const Divider = styled.hr `
  height: ${pxToRem(1)};
  width: ${pxToRem(32)};
  border: 0;
  background-color: ${themeGet('colors.border.100')};
  margin: ${themeGet('spacing.2xl')} 0;
`;
export const List = styled.ul `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: ${themeGet('spacing.lg')};
`;
export const ListItem = styled(Typography).attrs({
    as: 'li',
    $appearance: '100',
    $fontSize: 'small',
    $fontWeight: 'regular',
    $lineHeight: 'default',
}) `
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: ${themeGet('spacing.md')};

  [data-icon='falcon'] {
    color: ${themeGet('colors.gray.600')};
  }
`;
