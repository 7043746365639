import { pxToRem, themeGet, unit } from '@pocketlaw/tetris';
import styled from 'styled-components';
const SectionBase = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${unit('xl')};
`;
export const SectionContent = styled(SectionBase) `
  flex: 1 1 auto;
  height: 100%;
  width: 100%;
  overflow: hidden auto;
`;
export const SectionFooter = styled(SectionBase) `
  flex-shrink: 0;
  width: 100%;
  padding: ${unit('xl')};
  border-top: solid ${pxToRem(1)} ${themeGet('colors.gray.100')};
`;
