import { useState } from 'react';
import { useFuse } from 'app/domains/search/hooks/useFuse';
import { filterUsersByRoles } from './utils';
import { useCompanyUsersWithRoles } from '../useCompanyUsersWithRoles';
export const useSearchUsers = (acceptedUserRoles) => {
    const [searchValue, setSearchValue] = useState('');
    const { data, error, loading } = useCompanyUsersWithRoles();
    const { search, result, reset, searchString } = useFuse(data, { keys: ['name'] });
    const handleSearch = (value) => {
        setSearchValue(value);
        search(value);
    };
    const handleReset = () => {
        setSearchValue('');
        reset();
    };
    const usersResult = searchString ? result : data;
    const users = filterUsersByRoles(usersResult, acceptedUserRoles);
    return { users, error, loading, handleSearch, handleReset, searchValue };
};
