import { t } from '@lingui/macro';
import { withFilterErrorBoundary } from './FilterErrorBoundary';
import { SearchStringFilter } from './SearchStringFilter';
import { useGetUsers } from './useGetUsers/useGetUsers';
export const contractCreatorFilterLabel = () => t({ message: 'Contract creator', comment: 'Insights filter: Contract creator' });
export const ContractCreatorFilter = withFilterErrorBoundary(() => {
    const { data, error, loading } = useGetUsers();
    const disabled = Boolean(loading || error);
    return (<SearchStringFilter name="createdByUserId" items={data} label={contractCreatorFilterLabel()} loading={disabled}/>);
});
