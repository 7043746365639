import { useLazyQuery } from '@apollo/client';
import { ImportDocumentToCcsStatus } from 'shared/domains/apollo/generated/types';
import { getImportDocumentToCcsJob } from './query';
const POLL_INTERVAL_MS = 2000;
export const useGetImportDocumentToCcsJob = () => {
    const [query, result] = useLazyQuery(getImportDocumentToCcsJob);
    const startPollingJob = (jobId, onCompletedCallback) => query({
        variables: { jobId },
        pollInterval: POLL_INTERVAL_MS,
        notifyOnNetworkStatusChange: true,
        onCompleted(data) {
            var _a;
            onCompletedCallback((_a = data.importDocumentToCcsJob) === null || _a === void 0 ? void 0 : _a.status);
        },
        onError() {
            onCompletedCallback(ImportDocumentToCcsStatus.Failed);
        },
    });
    const stopPollingJob = () => result.stopPolling();
    return { startPollingJob, stopPollingJob };
};
