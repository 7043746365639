import { useLocation, useRouteMatch } from 'react-router-dom';
export const useLinkMatch = (options) => {
    const { matchPath, matchExact } = options;
    const { pathname, search } = useLocation();
    const match = useRouteMatch(matchPath);
    const currentUrl = `${pathname}${search}`;
    const fullUrlMatch = matchPath === currentUrl;
    const routeMatch = Boolean(matchExact ? match && match.isExact : match);
    const linkMatch = fullUrlMatch || routeMatch;
    return linkMatch;
};
