var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { saveSigneeMutation } from './mutation';
export function useAddSignatoryValue(documentId) {
    const [mutation, state] = useMutation(saveSigneeMutation);
    const handleMutation = (values) => __awaiter(this, void 0, void 0, function* () {
        return mutation({
            variables: {
                objects: values.map(value => (Object.assign(Object.assign({}, value), { file_system_document_id: documentId }))),
            },
            update(cache, { data }) {
                var _a;
                const newData = ((_a = data === null || data === void 0 ? void 0 : data.createEsigningSignatoryValue) === null || _a === void 0 ? void 0 : _a.returning) || [];
                cache.modify({
                    fields: {
                        esigningSignatoryValues(existing = []) {
                            return [...existing, ...newData];
                        },
                    },
                });
            },
        });
    });
    return {
        addSignatoryValue: handleMutation,
        state,
    };
}
