import { memo } from 'react';
import { Typography } from '@pocketlaw/tetris';
import { useTransitionState } from 'shared/domains/common-ui';
import { Overlay, SliceInfoContainer } from './styled';
export const SliceInfoItem = memo((props) => {
    const { active, name, value } = props;
    const state = useTransitionState(active, 300);
    return (<Overlay>
      <SliceInfoContainer $state={state}>
        <Typography>{name}</Typography>
        <Typography $fontSize="huge" $fontWeight="bold" $appearance="300" $lineHeight="neutralized">
          {value}
        </Typography>
      </SliceInfoContainer>
    </Overlay>);
});
