import { useQuery } from '@apollo/client';
import { ROOT } from 'app/domains/filesystem';
import { ActiveFileSystemDocument } from './ActiveFileSystemQuery.gql';
export function useQueryFileSystem(folderId) {
    return useQuery(ActiveFileSystemDocument, {
        variables: {
            folderId: folderId || ROOT,
            includeAncestors: Boolean(folderId),
        },
    });
}
