import { MimeTypesByExtension } from 'shared/domains/filesystem/utils/fileExtensions';
export const MAX_LOGO_UPLOAD_SIZE_BYTES = 100000;
export const MAX_ATTACHMENT_UPLOAD_SIZE_BYTES = 10000000;
export const MAX_FILE_UPLOAD_SIZE_BYTES = 2000000000;
export const MAX_BULK_UPLOAD_FILES_AMOUNT = 100;
export const UPLOAD_DOCUMENT_SUPPORTED_MIME_TYPES = [
    MimeTypesByExtension.Pdf,
    MimeTypesByExtension.Doc,
    MimeTypesByExtension.Docx,
    MimeTypesByExtension.Xlsx,
    MimeTypesByExtension.Ppt,
    MimeTypesByExtension.Pptx,
    MimeTypesByExtension.Key,
    MimeTypesByExtension.Pages,
    MimeTypesByExtension.Numbers,
];
export const UPLOAD_LOGO_SUPPORTED_MIME_TYPES = [
    MimeTypesByExtension.Png,
    MimeTypesByExtension.Jpeg,
];
