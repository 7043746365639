import { gql } from '@apollo/client';
export const GET_USERS = gql `
  query getUsers {
    companyUsers {
      id
      user {
        id
        name
      }
    }
  }
`;
