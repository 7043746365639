import { forwardRef } from 'react';
import { UPLOAD_DOCUMENT_SUPPORTED_MIME_TYPES } from 'app/domains/upload';
import { FolderInput } from './styled';
export const UploadFolderInput = forwardRef((props, ref) => {
    const { onChange } = props;
    const accept = UPLOAD_DOCUMENT_SUPPORTED_MIME_TYPES.join(',');
    const handleChange = (event) => {
        onChange(Array.from(event.target.files || []));
        // eslint-disable-next-line no-param-reassign
        event.target.value = '';
    };
    return (<FolderInput readOnly multiple type="file" name="upload-input" directory="true" webkitdirectory="true" mozdirectory="true" ref={ref} tabIndex={-1} accept={accept} onChange={handleChange}/>);
});
