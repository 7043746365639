import { SearchEntityTypeEnum } from 'shared/domains/apollo/generated/types';
export function canChangeDocumentStatus(entry) {
    const isUploaded = (entry === null || entry === void 0 ? void 0 : entry.entityType) === SearchEntityTypeEnum.DocumentUploaded;
    const isUnknown = (entry === null || entry === void 0 ? void 0 : entry.metadataDocumentStatus) === 'UNKNOWN';
    return isUploaded && isUnknown;
}
export function canMoveDocument(entry) {
    if (!entry || !(entry === null || entry === void 0 ? void 0 : entry.entityType)) {
        return false;
    }
    const documents = [
        SearchEntityTypeEnum.DocumentComposed,
        SearchEntityTypeEnum.DocumentFreetext,
        SearchEntityTypeEnum.DocumentUploaded,
    ];
    return documents.includes(entry.entityType);
}
