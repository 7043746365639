import { Trans } from '@lingui/macro';
import { Box, Button, IconButton, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { FieldArray, useField } from 'formik';
import { Range } from './Range';
export function Ranges(props) {
    const { name } = props;
    const [field] = useField(`${name}.ranges`);
    // TODO: Give possibility to add preset data like `Upcoming 30/60/90 days`
    const defaultRangeData = {
        fromNow: true,
        fromOffset: { value: 0, timeUnit: 'day', roundingTimeUnit: 'day' },
        toNow: true,
        toOffset: { value: 31, timeUnit: 'day', roundingTimeUnit: 'day' },
    };
    return (<FieldArray name={`${name}.ranges`}>
      {({ push, remove }) => (<Box p="lg" flexDirection="column" gap="lg">
          <Button onClick={() => push(defaultRangeData)}>Add range</Button>
          {Boolean(field.value.length) && (<Box gap="lg">
              {field.value.map((_, index) => (<Box 
                // eslint-disable-next-line react/no-array-index-key
                key={index} flexDirection="column" gap="lg" bg="background.75" p="lg" borderRadius="md">
                  <Box alignItems="center" justifyContent="space-between">
                    <Typography $fontWeight="bold">
                      <Trans comment="Insights widget form: Label for range">
                        Range {index + 1}
                      </Trans>
                    </Typography>
                    <IconButton $round size="xs" appearance="secondary" onClick={() => remove(index)}>
                      <Falcon icon="trash-can"/>
                    </IconButton>
                  </Box>
                  <Range name={`${name}.ranges[${index}]`}/>
                </Box>))}
            </Box>)}
        </Box>)}
    </FieldArray>);
}
