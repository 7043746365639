import { Tree } from './Tree';
import { Container, TreeContainer } from './styled';
export function Preview(props) {
    const { item } = props;
    return (<Container>
      <TreeContainer>
        <Tree items={[item]} isLastItem={false}/>
      </TreeContainer>
    </Container>);
}
