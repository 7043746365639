import { useQuery } from '@apollo/client';
import { EntryKind } from 'shared/domains/apollo/generated/types';
import { GetFolderDocument } from './query.gql';
export const useInitialFolder = (folderId = '') => {
    const { data } = useQuery(GetFolderDocument, { variables: { folderId }, skip: !folderId });
    if (!(data === null || data === void 0 ? void 0 : data.folder)) {
        return undefined;
    }
    const initialFolder = {
        id: data.folder.id,
        name: data.folder.name,
        kind: EntryKind.Folder,
        disabled: false,
    };
    return initialFolder;
};
