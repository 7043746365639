import { gql } from '@apollo/client';
export const renameFolderMutation = gql `
  mutation renameFolder($id: ID!, $name: String!) {
    entries: updateFileSystemFolder(id: $id, input: { name: $name }) {
      id
      name
    }
  }
`;
export const renameDocumentMutation = gql `
  mutation renameDocument($id: ID!, $name: String!) {
    entries: updateFileSystemDocument(id: $id, input: { name: $name }) {
      id
      name
    }
  }
`;
