export const PACKAGE_BIG = {
    type: 'big',
};
export const PACKAGE_MEDIUM = {
    type: 'medium',
};
export const PACKAGE_SMALL = {
    type: 'small',
};
export const PACKAGE_PLUS = {
    type: 'smallplus',
};
export const PACKAGE_FREE = {
    type: 'free',
};
export function isFreePlan(plan) {
    return (plan === null || plan === void 0 ? void 0 : plan.type) === PACKAGE_FREE.type;
}
export function isPaidPlan(plan) {
    if (!plan) {
        return false;
    }
    return !isFreePlan(plan);
}
const comparePlans = (firstPlan, secondPlan) => firstPlan.ordinal - secondPlan.ordinal;
export function sortPlansByOrdinal(plans) {
    if (!plans) {
        return undefined;
    }
    const plansInOrder = [...plans].filter(isPaidPlan).sort(comparePlans);
    return plansInOrder;
}
export function getFirstPlanSortedByOrdinal(plans) {
    if (!plans) {
        return undefined;
    }
    const existingPlans = plans.filter(Boolean);
    const firstPlanSortedByOrdinal = sortPlansByOrdinal(existingPlans);
    return firstPlanSortedByOrdinal === null || firstPlanSortedByOrdinal === void 0 ? void 0 : firstPlanSortedByOrdinal[0];
}
export function getNextPlan(plans, currentPlan) {
    if (!plans) {
        return undefined;
    }
    if (!currentPlan) {
        return getFirstPlanSortedByOrdinal(plans);
    }
    const sortedPlans = sortPlansByOrdinal(plans);
    return sortedPlans === null || sortedPlans === void 0 ? void 0 : sortedPlans.find(({ ordinal }) => ordinal > (currentPlan === null || currentPlan === void 0 ? void 0 : currentPlan.ordinal));
}
export function findFreePlanId(plans) {
    var _a;
    return (_a = plans === null || plans === void 0 ? void 0 : plans.find(plan => plan.type === PACKAGE_FREE.type)) === null || _a === void 0 ? void 0 : _a.id;
}
