import { gql } from '@apollo/client';
export const signatoryValuesQuery = gql `
  query GetSignatoryValues {
    esigningSignatoryValues {
      id
      name
      firstName: firstname
      lastName: lastname
      email
      label
      mobile
      title
      isSigning: is_signing
      documentId: file_system_document_id
    }
  }
`;
