import * as Sentry from 'shared/domains/sentry';
export function setDocumentSentryTags(data) {
    const scope = Sentry.getCurrentScope();
    const normalizedData = Object.entries(data).reduce((acc, [key, value]) => {
        if (value) {
            return Object.assign(Object.assign({}, acc), { [key]: value });
        }
        return acc;
    }, {});
    Object.entries(normalizedData).forEach(([key, value]) => {
        scope.setTag(key, value);
    });
    const cleanup = () => {
        const cleanupScope = Sentry.getCurrentScope();
        Object.keys(normalizedData).forEach(key => {
            cleanupScope.setTag(key, undefined);
        });
    };
    return cleanup;
}
