import { useLazyQuery } from '@apollo/client';
import { useUnmount } from 'react-use';
import { DocumentUploadJobStatus } from 'shared/domains/apollo/generated/types';
import { getDocumentEntries } from './getDocumentEntries';
import { pollBulkUploadQuery } from './query';
const POLLING_INTERVAL = 1500;
export const usePollUploadJob = (onUploadCallback) => {
    const [query, result] = useLazyQuery(pollBulkUploadQuery);
    const { stopPolling } = result;
    const pollUploadJob = (id) => query({
        variables: { id },
        pollInterval: POLLING_INTERVAL,
        notifyOnNetworkStatusChange: true,
        onError: () => {
            stopPolling();
            onUploadCallback({ status: 'error' });
        },
        onCompleted(newData) {
            const { documentUploadJob } = newData || {};
            const { status, entries } = documentUploadJob || {};
            const fileSystemDocuments = getDocumentEntries(entries);
            if (status === DocumentUploadJobStatus.Done) {
                stopPolling();
                onUploadCallback({ status: 'success', entries: fileSystemDocuments });
            }
            if (status === DocumentUploadJobStatus.Failed) {
                stopPolling();
                onUploadCallback({ status: 'error' });
            }
        },
    });
    useUnmount(() => {
        stopPolling();
    });
    return { pollUploadJob };
};
