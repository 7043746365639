import { Trans } from '@lingui/macro';
import { Typography, useTetrisTheme } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Container, LightThemeBox, LightThemeContent, LightThemeSelected } from './styled';
export function LightThemeButton(props) {
    const { selected, onClick } = props;
    const { themeType } = useTetrisTheme();
    return (<Container>
      <LightThemeBox $themeType={themeType} onClick={onClick}>
        <LightThemeContent $themeType={themeType}>Aa</LightThemeContent>
        {selected && (<LightThemeSelected $themeType={themeType}>
            <Falcon icon="check" $size={12}/>
          </LightThemeSelected>)}
      </LightThemeBox>
      <Typography $fontSize="small" $appearance="100">
        <Trans comment="App theme selector - Light theme label">Light</Trans>
      </Typography>
    </Container>);
}
