import { TableHeadCell, breakpoints } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const DesktopTableHeadCell = styled(TableHeadCell) `
  ${breakpoints.xs} {
    display: none;
  }
`;
export const MobileTableHeadCell = styled(TableHeadCell) `
  ${breakpoints.sm} {
    display: none;
  }
`;
