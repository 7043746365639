var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { DislikeDialog } from './DislikeDialog';
import { LikeDialog } from './LikeDialog';
export function Dialog(props) {
    const { feedback } = props, rest = __rest(props, ["feedback"]);
    switch (feedback) {
        case 'dislike':
            return <DislikeDialog {...rest}/>;
        case 'like':
            return <LikeDialog {...rest}/>;
        default:
            return null;
    }
}
