import { SUPPORTED_LANGUAGES } from 'app/domains/auth/constants';
function toLowerCase(subject) {
    return String(subject).toLocaleLowerCase();
}
export function getLocale(code) {
    if (typeof code !== 'string') {
        return undefined;
    }
    if (SUPPORTED_LANGUAGES.includes(code)) {
        return code;
    }
    const [language] = code.split(/[_-]/).map(toLowerCase);
    if (SUPPORTED_LANGUAGES.includes(language)) {
        return language;
    }
    return undefined;
}
