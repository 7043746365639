import { Trans, t } from '@lingui/macro';
import { LoaderOverlay, Tab, TabContent, Tabs } from '@pocketlaw/tetris';
import { useApprovers } from 'app/domains/approval';
import { ActivityTab } from './ActivityTab';
import { ApproversTab } from './ApproversTab';
import { StyledTabNav } from './styled';
import { ApprovalTabs } from './types';
export function Content() {
    const { loading } = useApprovers();
    if (loading) {
        return (<LoaderOverlay label={t({
                comment: 'Loading message when the approval drawer is fetching approvers - title',
                message: 'Loading',
            })}/>);
    }
    return (<Tabs>
      <StyledTabNav>
        <Tab id={ApprovalTabs.Approvers}>
          <Trans comment="Approval drawer - tab title - approvers">Approvers</Trans>
        </Tab>
        <Tab id={ApprovalTabs.Activity}>
          <Trans comment="Approval drawer - tab title - activity">Activity</Trans>
        </Tab>
      </StyledTabNav>
      <TabContent tabId={ApprovalTabs.Approvers}>
        <ApproversTab />
      </TabContent>
      <TabContent tabId={ApprovalTabs.Activity}>
        <ActivityTab />
      </TabContent>
    </Tabs>);
}
