export var MimeTypesByExtension;
(function (MimeTypesByExtension) {
    MimeTypesByExtension["Pdf"] = "application/pdf";
    MimeTypesByExtension["Doc"] = "application/msword";
    MimeTypesByExtension["Docx"] = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    MimeTypesByExtension["Xlsx"] = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    MimeTypesByExtension["Ppt"] = "application/vnd.ms-powerpoint";
    MimeTypesByExtension["Pptx"] = "application/vnd.openxmlformats-officedocument.presentationml.presentation";
    MimeTypesByExtension["Key"] = "application/x-iwork-keynote-sffkey";
    MimeTypesByExtension["Pages"] = "application/x-iwork-pages-sffpages";
    MimeTypesByExtension["Numbers"] = "application/x-iwork-numbers-sffnumbers";
    MimeTypesByExtension["Png"] = "image/png";
    MimeTypesByExtension["Jpeg"] = "image/jpeg";
})(MimeTypesByExtension || (MimeTypesByExtension = {}));
