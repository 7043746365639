import { breakpoints, pxToRem, themeGet, unit } from '@pocketlaw/tetris';
import styled, { css, keyframes } from 'styled-components';
const ANIMATION_STATES = ['saving', 'synchronizing'];
const rotationAnimation = keyframes `
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;
const getIconStyles = (state) => {
    switch (state) {
        case 'waiting':
        case 'saving':
        case 'synchronizing':
            return css `
        background-color: ${themeGet('colors.gray.100')};

        [data-icon='falcon'] {
          color: ${themeGet('colors.gray.800')};
        }
      `;
        case 'error': {
            return css `
        background-color: ${themeGet('colors.red.400')};

        [data-icon='falcon'] {
          color: ${themeGet('colors.gray.0')};
        }
      `;
        }
        case 'synchronized':
        default:
            return css `
        background-color: ${themeGet('colors.green.400')};

        [data-icon='falcon'] {
          color: ${themeGet('colors.gray.0')};
        }
      `;
    }
};
export const Container = styled.div `
  display: flex;
  align-items: center;
  gap: ${unit('sm')};

  ${breakpoints.xs} {
    padding-left: ${pxToRem(10)};
  }
`;
export const IconContainer = styled.div `
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxToRem(20)};
  height: ${pxToRem(20)};
  border-radius: 50%;
  transition: background-color 175ms ease-in-out;

  [data-icon='falcon'] {
    transition: color 175ms ease-in-out;
  }

  ${props => getIconStyles(props.$state)};

  ${({ $state }) => $state &&
    ANIMATION_STATES.includes($state) &&
    css `
      animation: ${rotationAnimation} 1s linear infinite;
    `}
`;
