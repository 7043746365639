import { Trans, t } from '@lingui/macro';
import { Alert, Column, Grid, Typography } from '@pocketlaw/tetris';
import EmailInput from './EmailInput';
import { usePreview } from '../../hooks/usePreview';
import { AlertContainer } from './styled';
export function Body() {
    const { isComposed } = usePreview();
    return (<Grid directions="vertical" spacing={2}>
      <Column width="100%">
        <Typography>
          <Trans comment="Share document dialog - Descriptive information message">
            Share document outside of organization. Recipients can leave and respond to comments but
            are not able to edit the document.
          </Trans>
        </Typography>
      </Column>
      <Column width="100%">
        <EmailInput />
      </Column>
      {isComposed && (<Column width="100%">
          <AlertContainer>
            <Alert appearance="warning" message={t({
                comment: 'Share document dialog - Convert document to free-text info',
                message: 'If you want to share this document it will be converted to a free-text document. This means that you cannot go back and edit it in composed mode later.',
            })}/>
          </AlertContainer>
        </Column>)}
    </Grid>);
}
