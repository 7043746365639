import { v4 as uuid } from 'uuid';
import { SearchAggregationType, } from 'shared/domains/apollo/generated/types';
import { TERMS_AGGREGATION_MISSING_VALUE_NAME } from '../../../constants';
function getMissingConfigurationValue(field) {
    switch (field) {
        case 'categoryId':
        case 'marketCode':
        case 'marketLocaleCode':
        case 'metadataDocumentDocumentCategory':
        case 'metadataDocumentCurrency':
        case 'metadataDocumentDocumentType':
        case 'metadataDocumentEsigningStatus':
        case 'metadataDocumentGoverningLaw':
        case 'metadataDocumentType':
            return TERMS_AGGREGATION_MISSING_VALUE_NAME;
        default:
            return null;
    }
}
export const getDefaultTermsValue = (field) => ({
    type: SearchAggregationType.Terms,
    name: uuid(),
    termsInput: {
        field,
        size: 10,
        missing: getMissingConfigurationValue(field),
    },
});
