import { useState } from 'react';
import { t } from '@lingui/macro';
import { IconButton, Menu } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import Fuse from 'fuse.js';
import { UserAndTeamSelect } from 'app/domains/users';
export function AddFileSystemAclsButton(props) {
    const submitButtonLabel = t({
        message: 'Give access',
        comment: 'filesystem give access dropdown: confirm button text',
    });
    const buttonLabel = t({
        message: 'Give access',
        comment: 'filesystem give access button label (tooltip or screen reader only. button will open dropdown of users and teams to give access to)',
    });
    const { onAdd, usersWithoutAccess, teamsWithoutAccess } = props;
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');
    const usersSearch = new Fuse(usersWithoutAccess, {
        keys: ['name'],
        ignoreLocation: true,
        threshold: 0,
    });
    const teamsSearch = new Fuse(teamsWithoutAccess, {
        keys: ['name'],
        ignoreLocation: true,
        threshold: 0,
    });
    const usersSearchResult = usersSearch.search(search).map(({ item }) => item);
    const teamsSearchResult = teamsSearch.search(search).map(({ item }) => item);
    const users = search ? usersSearchResult : usersWithoutAccess;
    const teams = search ? teamsSearchResult : teamsWithoutAccess;
    const handleSearch = (searchString) => {
        setSearch(searchString);
    };
    const handleSubmit = (data) => {
        const { selectedUserIds: userIds, selectedTeamIds: teamIds } = data;
        onAdd({ userIds, teamIds });
        setOpen(false);
        return Promise.resolve(); // return promise to match typescript requirements
    };
    return (<Menu target={
        // TODO: add tooltip without causing the dropdown to position incorrectly or having the menu aria attributes on non-interactive div elements
        <IconButton appearance="ghost" $round onClick={() => setOpen(!open)} aria-label={buttonLabel}>
          <Falcon icon="add"/>
        </IconButton>} onClose={() => setOpen(false)} open={open}>
      <UserAndTeamSelect teams={teams} users={users} search={search} onSearch={handleSearch} onSubmit={handleSubmit} submitButtonLabel={submitButtonLabel}/>
    </Menu>);
}
