import { useEffect, useRef } from 'react';
import lodashThrottle from 'lodash.throttle';
import { Disclaimer } from './Disclaimer';
import { Scroller, Thread } from './styled';
/**
 * Basic functional thread view with automatic scroll and anchor.
 * Does not contain any messages
 */
export function AssistantThreadView(props) {
    const { children, dependency } = props;
    const scrollerRef = useRef(null);
    const threadRef = useRef(null);
    const autoscroll = useRef(true);
    useEffect(() => {
        // scroll down to the bottom when opening an old chat or when posting a new message.
        const scroller = scrollerRef.current;
        if (scroller !== null) {
            scroller.scrollTop = scroller.scrollHeight - scroller.clientHeight;
        }
    }, [dependency]);
    useEffect(() => {
        // auto-scroll to the bottom (if at the "bottom") when streaming content.
        const scroller = scrollerRef.current;
        const thread = threadRef.current;
        if (thread === null || scroller === null) {
            return () => { };
        }
        const callback = () => {
            if (autoscroll.current) {
                scroller.scrollTop = scroller.scrollHeight - scroller.clientHeight;
            }
        };
        const observer = new ResizeObserver(
        // throttle to make it less jerky in firefox and safari.
        /chrome/i.test(navigator.userAgent) ? callback : lodashThrottle(callback, 200));
        observer.observe(thread, {
            box: 'border-box',
        });
        return () => {
            observer.unobserve(thread);
        };
    }, []);
    return (<Scroller ref={scrollerRef} onScroll={evt => {
            // snap out of auto-scroll if user scrolls up - we need a bit of tolerance because the scroll
            // position (scrollTop) jumps a bit due to markdown being converted to React components.
            if (evt.target instanceof HTMLElement) {
                const tolerance = 75;
                const { clientHeight, scrollHeight, scrollTop } = evt.target;
                autoscroll.current = scrollHeight - (clientHeight + scrollTop) < tolerance;
            }
        }}>
      <Thread ref={threadRef}>
        <Disclaimer />
        {children}
      </Thread>
    </Scroller>);
}
