import { Fragment, useState } from 'react';
import { t } from '@lingui/macro';
import { IconButton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useField } from 'formik';
import { ESignSignatoryFormDialog } from 'app/domains/esigning/components/ESignSignatoryFormDialog';
import { useEsigningSignatoriesFormField } from 'app/domains/esigning/components/EsigningForm';
import { UNASSIGNED_SIGNING_ORDER, getOrderedSignatoriesMap } from 'app/domains/esigning/utils';
import { ESignPartyItem } from '../../../ESignPartyItem';
import { SectionList } from '../../../components';
import { PartyActions } from '../PartyActions';
import { PartySigningOrder } from '../PartySigningOrder';
export function OrderedSigningParties() {
    const [{ value: groupMessage }] = useField('globals.groupMessage');
    const { signatories, addSignatory } = useEsigningSignatoriesFormField();
    const [showAddPartyModal, setShowAddPartyModal] = useState(false);
    const addedSignatories = signatories.filter(({ isSigning }) => isSigning);
    const unassignedSignatories = addedSignatories.filter(({ order }) => order === UNASSIGNED_SIGNING_ORDER);
    const assignedSignatories = addedSignatories.filter(({ order }) => order !== undefined && order > UNASSIGNED_SIGNING_ORDER);
    const showModal = () => setShowAddPartyModal(true);
    const hideModal = () => setShowAddPartyModal(false);
    if (unassignedSignatories.length === 0 && assignedSignatories.length === 0) {
        return null;
    }
    const orderMap = getOrderedSignatoriesMap(assignedSignatories);
    const unassignedLabel = t({
        message: 'Unassigned parties',
        comment: 'Label for signing parties without order',
    });
    const getAssignedLabel = (order) => {
        const assignedLabel = t({
            message: 'Signing order',
            comment: 'Label for signing parties with order selected',
        });
        return `${assignedLabel} ${order + 1}`;
    };
    const addButton = (<IconButton $round data-id="add-signing-party-button" size="small" appearance="ghost" onClick={showModal}>
      <Falcon icon="plus"/>
    </IconButton>);
    return (<Fragment>
      {showAddPartyModal && (<ESignSignatoryFormDialog type="Signatory" allSignatories={signatories} onClose={hideModal} onSubmit={addSignatory}/>)}
      {unassignedSignatories.length > 0 && (<SectionList hideTooManyItems title={unassignedLabel} endElement={addButton}>
          {unassignedSignatories.map(signatory => (<ESignPartyItem hideAvatar key={signatory.key} signatory={signatory} groupMessage={groupMessage} endElement={<Fragment>
                  <PartySigningOrder signatoryKey={signatory.key}/>
                  <PartyActions signatory={signatory}/>
                </Fragment>}/>))}
        </SectionList>)}
      {Object.entries(orderMap).map(([order, orderedSignatories]) => (<SectionList hideTooManyItems key={order} title={getAssignedLabel(+order)} endElement={addButton}>
          {orderedSignatories.map(signatory => (<ESignPartyItem hideAvatar key={signatory.key} signatory={signatory} groupMessage={groupMessage} endElement={<Fragment>
                  <PartySigningOrder signatoryKey={signatory.key}/>
                  <PartyActions signatory={signatory}/>
                </Fragment>}/>))}
        </SectionList>))}
    </Fragment>);
}
