import { DocumentType } from 'shared/domains/apollo/generated/types';
export function getFileType(type) {
    switch (type) {
        case DocumentType.Composed:
            return 'file:composed';
        case DocumentType.Freetext:
            return 'file:freetext';
        case DocumentType.Uploaded:
            return 'file:uploaded';
        default:
            return 'file';
    }
}
