import { t } from '@lingui/macro';
import { useField, useFormikContext } from 'formik';
import { SelectFolder } from 'shared/domains/common-ui';
export function SelectFolderField(props) {
    const { folder } = props;
    const [, , helpers] = useField('parentId');
    const { isSubmitting } = useFormikContext();
    const isRootSelected = folder === null;
    const getFolderName = () => {
        if (isRootSelected) {
            return t({
                message: 'Root',
                comment: 'Root folder label',
            });
        }
        if (folder === null || folder === void 0 ? void 0 : folder.name) {
            return folder.name;
        }
        return undefined;
    };
    const handleSubmit = (newFolder) => {
        helpers.setValue(newFolder.id);
    };
    return (<SelectFolder folderName={getFolderName()} initialFolderId={folder === null || folder === void 0 ? void 0 : folder.id} disabled={isSubmitting} onSelect={handleSubmit}/>);
}
