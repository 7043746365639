import { Typography, pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Left = styled.div ``;
export const Right = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${themeGet('spacing.xs')};
  justify-content: center;
`;
export const Container = styled.div `
  display: flex;
  flex-flow: row nowrap;
  gap: ${themeGet('spacing.lg')};
  padding: ${themeGet('spacing.lg')} ${themeGet('spacing.md')};
`;
export const FirstLine = styled(Typography) `
  &:not(:only-child) {
    margin-top: ${themeGet('spacing.sm')};
  }
`;
export const ThirdLine = styled(Typography) `
  margin-top: ${themeGet('spacing.xs')};
`;
export const BadgeContainer = styled.div `
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(${pxToRem(6)}, ${pxToRem(6)});
`;
export const AvatarContainer = styled.div `
  position: relative;
`;
