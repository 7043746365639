import { t } from '@lingui/macro';
export const getStatusTranslations = () => ({
    todo: {
        label: t({ message: 'Todo', comment: 'Status of a task' }),
        appearance: 'secondary',
    },
    in_progress: {
        label: t({ message: 'In progress', comment: 'Status of a task' }),
        appearance: 'primary',
    },
    completed: {
        label: t({ message: 'Completed', comment: 'Status of a task' }),
        appearance: 'success',
    },
});
