import { Box, Checkbox, MenuItem } from '@pocketlaw/tetris';
export function MenuMultiSelectItem(props) {
    const { disabled, selected = false, onClick, children } = props;
    return (<MenuItem as="label" disabled={disabled}>
      {children}
      <Box ml="auto">
        <Checkbox checked={selected} onChange={onClick}/>
      </Box>
    </MenuItem>);
}
