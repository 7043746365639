import { ChatMessageStatus } from 'shared/domains/apollo/generated/types';
import { ResolvedActions } from './ResolvedActions';
export function Actions(props) {
    const { message, commentId, feedbackScore, status } = props;
    switch (status) {
        case ChatMessageStatus.Pending:
        case ChatMessageStatus.Resolved:
            return (<ResolvedActions message={message} commentId={commentId} feedbackScore={feedbackScore} hidden={status === ChatMessageStatus.Pending}/>);
        case ChatMessageStatus.Failed:
        default:
            return null;
    }
}
