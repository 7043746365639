import { t } from '@lingui/macro';
import { FilterButton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useFilter } from '../useFilter';
export function ClearFilter(props) {
    const { name } = props;
    const filter = useFilter(name);
    const label = t({ message: 'Clear filter', comment: 'Insights: Clear filter' });
    return (<FilterButton type="button" onClick={filter.resetValue} appearance={filter.isTouched ? 'draft' : 'default'} aria-label={label}>
      <Falcon icon="remove"/>
    </FilterButton>);
}
