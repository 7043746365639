import { t } from '@lingui/macro';
import { SearchInput } from '@pocketlaw/tetris';
import { useComposerData } from '../../../ComposerDataProvider';
export function Search() {
    const { searchQuery, setSearchQuery } = useComposerData();
    const handleChange = (event) => setSearchQuery(event.target.value);
    const handleReset = () => setSearchQuery('');
    return (<SearchInput placeholder={t({
            comment: 'Select template dialog - Search templates placeholder',
            message: 'Search templates',
        })} resetLabel={t({
            comment: 'Select template dialog - Reset search template input label',
            message: 'Clear search',
        })} value={searchQuery} onReset={handleReset} onChange={handleChange}/>);
}
