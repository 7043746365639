import { useMutation } from '@apollo/client';
import { useLastSignRequest } from 'app/domains/esigning/hooks/useLastSignRequest';
import { removeSignRequestSignatoryMutation } from './mutation';
export const useRemoveSignRequestSignatory = (id) => {
    const { lastSignRequest } = useLastSignRequest();
    const { id: signRequestId } = lastSignRequest || {};
    return useMutation(removeSignRequestSignatoryMutation, {
        variables: {
            signRequestId: signRequestId || '',
            signatoryId: id || '',
        },
    });
};
