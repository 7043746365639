import { useRef, useState } from 'react';
import { usePreview } from 'shared/domains/documents';
import { useFeatureFlag } from 'shared/domains/featureFlag';
import { PleditorContext } from './context';
import { FORMATTING_COMMANDS } from './formatting';
import { tryGetPlugin } from './utils';
import { useSearchTexts } from '../hooks/useSearchTexts';
export function PleditorProvider(props) {
    const { children } = props;
    const { isFreeText } = usePreview();
    const isSandboxEnabled = useFeatureFlag('feature_pleditor_sandbox');
    const [state, setState] = useState('initializing');
    const [editorName, setEditorName] = useState('');
    const [sandbox, setSandbox] = useState(false);
    const pleditorRef = useRef(null);
    const setPleditor = (name, editor, lockedDocument = false) => {
        pleditorRef.current = editor;
        setState('initialized');
        setEditorName(name);
        if (lockedDocument) {
            FORMATTING_COMMANDS.forEach(command => {
                editor.plugins.get('RestrictedEditingModeEditing').enableCommand(command);
            });
        }
    };
    const unsetPleditor = () => {
        pleditorRef.current = null;
        setState('initializing');
    };
    const sandboxMode = isSandboxEnabled && sandbox && isFreeText;
    const sandboxModeAvailable = isSandboxEnabled && isFreeText;
    const { fuzzySearchTextNodes, resetFuzzySearch } = useSearchTexts(pleditorRef.current);
    const baseValue = {
        setPleditor,
        unsetPleditor,
        sandboxMode,
        sandboxModeAvailable,
        setSandboxMode: setSandbox,
        fuzzySearchTextNodes,
        resetFuzzySearch,
    };
    if (state === 'initialized' && pleditorRef.current !== null) {
        const value = Object.assign(Object.assign({}, baseValue), { pleditorState: state, pleditor: pleditorRef.current, plugins: {
                attachmentStore: tryGetPlugin(pleditorRef.current, 'AttachmentStore'),
            }, dev: {
                editorName,
                bundleVersion: process.env.PLEDITOR_BUNDLE_VERSION,
                packageVersion: window.CKEDITOR_VERSION,
            } });
        return <PleditorContext.Provider value={value}>{children}</PleditorContext.Provider>;
    }
    const value = Object.assign(Object.assign({}, baseValue), { pleditorState: 'initializing', pleditor: null, plugins: {}, dev: null });
    return <PleditorContext.Provider value={value}>{children}</PleditorContext.Provider>;
}
export { usePleditor } from './hook';
