import { t } from '@lingui/macro';
import { UpdateTeamErrorCodes } from './types';
export function UpdateTeamErrorMessages(error) {
    const [gqlError] = (error === null || error === void 0 ? void 0 : error.graphQLErrors) || [];
    const { subcode } = (gqlError === null || gqlError === void 0 ? void 0 : gqlError.extensions) || {};
    switch (subcode) {
        case UpdateTeamErrorCodes.DUPLICATE_ENTITY:
            return t({
                message: 'Team name already exists. Team names must be unique.',
                comment: 'Update team modal - error message when team name already exists',
            });
        default:
            return t({
                message: "We couldn't update the team. Please try again later.",
                comment: 'Alert error message when update team fails',
            });
    }
}
