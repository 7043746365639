import { AssistantComment, useAssistant, AssistantThreadView, AssistantSuggestions, } from 'app/domains/assistant';
import { ChatMessageRole, ChatMessageType } from 'shared/domains/apollo/generated/types';
/**
 * Thread containing the suggestions generated by the assistant.
 * Note that this is not a chat.
 */
export function AssistantSuggestionsThread() {
    const { suggestions } = useAssistant();
    return (<AssistantThreadView dependency={suggestions.length}>
      <AssistantComment id="suggestions.user.prompt" role={ChatMessageRole.User} type={ChatMessageType.DocumentAskWithQuotes}/>
      <AssistantSuggestions />
    </AssistantThreadView>);
}
