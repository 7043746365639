import { ComponentChildren } from './ComponentChildren';
import { SearchableMenuPopover } from './SearchableMenuPopover';
import { SearchableMenuProvider } from './SearchableMenuProvider';
import { SearchableMenuSearchHeader } from './SearchableMenuSearchHeader';
export function SearchableMenu(props) {
    const { renderTarget, items, keys, placeholder, children, initiallyOpen = false, onClose, fuseOptions, } = props;
    return (<SearchableMenuProvider items={items} keys={keys} fuseOptions={fuseOptions} initiallyOpen={initiallyOpen} onClose={onClose}>
      <SearchableMenuPopover renderTarget={renderTarget}>
        <SearchableMenuSearchHeader placeholder={placeholder}/>
        <ComponentChildren>{children}</ComponentChildren>
      </SearchableMenuPopover>
    </SearchableMenuProvider>);
}
