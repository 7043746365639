import { Button, themeGet, inputStyles } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const StyledButton = styled(Button) `
  ${inputStyles};
  padding: ${themeGet('spacing.md')};
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:not(:disabled):hover {
    text-decoration: none;
  }
`;
