import gql from 'graphql-tag';
export const QUERY = gql `
  query GetUsersDataAndComments($id: ID!) {
    comments: fileSystemDocumentComments(where: { documentId: { eq: $id } }) {
      id
      body
      resolved: isResolved
      replies {
        id
        body
      }
    }

    entry: fileSystemEntry(id: $id) {
      id
      acls {
        id
        userId
        actions
      }
    }

    companyUsers {
      id
      role
      user {
        id
        name
        email
      }
    }
  }
`;
