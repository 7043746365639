import React, { useState, useRef, Fragment } from 'react';
import { Trans } from '@lingui/macro';
import { Box, Button, Chip, ChipAction, TextInput, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useField } from 'formik';
import { SuggestionItem, SuggestionsContainer, SuggestionsList, CreateNewLabelContainer, LabelsContainer, LabelsHeading, } from './styled';
const MAX_LABEL_LENGTH = 40;
export function AddLabelAutoComplete(props) {
    const { suggestions } = props;
    const [field, , { setValue }] = useField('labels');
    const [activeSuggestion, setActiveSuggestion] = useState(0);
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [userInput, setUserInput] = useState('');
    const addButtonRef = useRef(null);
    const onChange = (e) => {
        const newUserInput = e.currentTarget.value;
        const trimmedUserInput = newUserInput.trim();
        const filtered = suggestions
            .filter(suggestion => suggestion.toLowerCase().includes(trimmedUserInput.toLowerCase()))
            .slice(0, 3);
        setActiveSuggestion(-1);
        setFilteredSuggestions(filtered);
        if (filtered.length > 0) {
            setShowSuggestions(true);
        }
        else {
            setShowSuggestions(false);
        }
        setUserInput(newUserInput);
    };
    const addLabel = (newLabel) => {
        var _a;
        if (newLabel) {
            const currentLabels = (_a = field.value) !== null && _a !== void 0 ? _a : [];
            if (!currentLabels.includes(userInput)) {
                setValue([...currentLabels, userInput]);
            }
            setUserInput('');
        }
    };
    const onClick = (e) => {
        setActiveSuggestion(0);
        setFilteredSuggestions([]);
        setShowSuggestions(false);
        setUserInput(e.currentTarget.innerText);
    };
    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            setShowSuggestions(false);
            addLabel(userInput.trim());
        }
        else if (e.key === 'ArrowUp') {
            e.preventDefault();
            if (activeSuggestion > 0) {
                setUserInput(filteredSuggestions[activeSuggestion - 1]);
                setActiveSuggestion(activeSuggestion - 1);
            }
        }
        else if (e.key === 'ArrowDown') {
            e.preventDefault();
            if (activeSuggestion < filteredSuggestions.length - 1) {
                setUserInput(filteredSuggestions[activeSuggestion + 1]);
                setActiveSuggestion(activeSuggestion + 1);
            }
        }
    };
    // Delay closing to allow click events to register
    const onBlur = () => {
        setTimeout(() => setShowSuggestions(false), 100);
    };
    const onFocus = () => {
        if (filteredSuggestions.length > 0) {
            setShowSuggestions(true);
        }
    };
    const displaySuggestionsList = showSuggestions && userInput && filteredSuggestions.length > 0;
    const displayCreateLabelButton = userInput.trim() && !showSuggestions && suggestions.indexOf(userInput.trim()) === -1;
    return (<Fragment>
      <Box flexDirection="column">
        <LabelsHeading>
          <Trans comment="Prompt library - Title for label input">Add Label</Trans>
        </LabelsHeading>
        <Box flexDirection="row" justifyContent="space-between" gap="lg">
          <Box flexDirection="column" width="100%">
            <TextInput type="text" onChange={onChange} onKeyDown={onKeyDown} onBlur={onBlur} onFocus={onFocus} value={userInput} autoComplete="off" maxLength={MAX_LABEL_LENGTH} adornmentRight={<Button type="button" appearance="secondary" size="xs" onClick={() => addLabel(userInput.trim())} ref={addButtonRef}>
                  <Falcon icon="plus"/>
                  <Trans comment="Prompt library - Button to add a label to a prompt when editing">
                    Add
                  </Trans>
                </Button>} stretch/>
            {displaySuggestionsList && (<SuggestionsContainer>
                <SuggestionsList>
                  {filteredSuggestions.map((suggestion, index) => (<SuggestionItem key={suggestion} $isActive={index === activeSuggestion} onClick={onClick}>
                      {suggestion}
                    </SuggestionItem>))}
                </SuggestionsList>
              </SuggestionsContainer>)}
            {displayCreateLabelButton && (<CreateNewLabelContainer>
                <Trans comment="Prompt library - Creating a new label">Creating new label:</Trans>
                <Chip appearance="primary" size="small" label={userInput.trim()}/>
              </CreateNewLabelContainer>)}
          </Box>
        </Box>
      </Box>
      <Box flexDirection="column">
        <LabelsHeading>
          <Trans comment="Prompt library - title of adding labels">Labels</Trans>
        </LabelsHeading>
        {field.value.length === 0 && (<Typography $appearance="100" $fontSize="small">
            <Trans comment="Prompt library - Empty labels description">
              Add labels to easily find prompts
            </Trans>
          </Typography>)}
        {field.value.length > 0 && (<LabelsContainer>
            {field.value.map(label => (<Chip key={label} icon={<Falcon icon="dot-circle"/>} label={label} action={<ChipAction type="button" onClick={() => {
                        setValue(field.value.filter(l => l !== label));
                    }}>
                    <Falcon icon="remove"/>
                  </ChipAction>}/>))}
          </LabelsContainer>)}
      </Box>
    </Fragment>);
}
