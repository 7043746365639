import { pxToRem, themeGet, unit } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Footer = styled.div `
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: ${unit('2xl')};
  gap: ${unit('xl')};
  border-top: solid ${pxToRem(1)} ${themeGet('colors.gray.100')};
`;
