import { Trans, t } from '@lingui/macro';
import { Button, EmptyState } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Content } from '../styled';
import { EmptyStateContainer } from './styled';
export function ErrorState(props) {
    const { onClick } = props;
    return (<Content>
      <EmptyStateContainer>
        <EmptyState icon={<Falcon icon="circle-exclamation"/>} title={t({
            comment: 'Preview template - Error title',
            message: 'Could not load the template',
        })} description={t({
            comment: 'Preview template - Error description',
            message: 'Something went wrong while trying to load the template',
        })} actions={<Button appearance="secondary" onClick={onClick}>
              <Trans comment="Preview template - Go back button label">Go back</Trans>
            </Button>}/>
      </EmptyStateContainer>
    </Content>);
}
