import { useMemo } from 'react';
import { getObjectPath, sortKeyPath } from './utils';
function useSortedRows(options) {
    const { rows, sortId, sortOrder } = options;
    return useMemo(() => {
        const sortKey = sortKeyPath(sortId);
        if (sortKey && sortOrder) {
            const newSort = [...rows];
            return newSort.sort((a, b) => {
                const keyA = getObjectPath(sortKey, a);
                const keyB = getObjectPath(sortKey, b);
                if (keyA === keyB) {
                    return 0;
                }
                if (sortOrder === 'asc') {
                    return keyA > keyB ? 1 : -1;
                }
                return keyA > keyB ? -1 : 1;
            });
        }
        return rows;
    }, [sortId, sortOrder, rows]);
}
export { useSortedRows };
