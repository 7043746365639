import { ESignRoot } from 'app/domains/esigning/components/ESignRoot';
import { DocumentDrawerHeader } from 'shared/domains/documents';
import { usePollLastSignRequest } from './usePollLastSignRequest';
import { DrawerContainer } from '../../styled';
import { getTitle } from '../../utils';
export function ESigning(props) {
    const { onClose, onGoBack } = props;
    const title = getTitle('esigning');
    usePollLastSignRequest();
    return (<DrawerContainer>
      <DocumentDrawerHeader type="nested" title={title} onClose={onClose} onGoBack={onGoBack}/>
      <ESignRoot />
    </DrawerContainer>);
}
