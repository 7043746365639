import { memo } from 'react';
import { Area, AreaChart, Tooltip } from 'recharts';
import { getTheme } from '../getTheme';
export const CountChart = memo((props) => {
    const { data, dataKey, onHover, colorScheme } = props;
    const handleOnHover = (tooltipProps) => {
        if (tooltipProps.active) {
            onHover(tooltipProps.label);
        }
        return null;
    };
    const bucketsWithValue = data.filter(item => item[dataKey] !== 0);
    const theme = getTheme(colorScheme);
    if (bucketsWithValue.length <= 1) {
        return null;
    }
    return (<AreaChart width={120} height={25} data={data} margin={{
            top: 1,
            right: 1,
            left: 1,
            bottom: 1,
        }}>
      <Tooltip content={handleOnHover}/>

      <defs>
        <linearGradient id="bg" x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor={theme('single')} stopOpacity={0.125}/>
          <stop offset="100%" stopColor={theme('single')} stopOpacity={0}/>
        </linearGradient>
      </defs>

      <Area type="monotone" dataKey={dataKey} stroke={theme('line')} strokeWidth={2} fill="url(#bg)"/>
    </AreaChart>);
});
