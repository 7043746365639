import { useParams } from 'react-router-dom';
import { PageLoader } from 'shared/domains/common-ui';
import { NotFoundError, AppError } from 'shared/domains/errors';
import { Context } from './Context';
import { useGetProviderData } from './useGetProviderData';
export function InsightsProvider(props) {
    var _a;
    const { children } = props;
    const { id } = useParams();
    const { data, loading, error } = useGetProviderData(id);
    const { dashboard } = data || {};
    const { label, slots, filters } = dashboard || {};
    const value = {
        id,
        label: label || '',
        slots: slots || [],
        filters: filters || {},
    };
    if (loading) {
        return <PageLoader />;
    }
    if (error) {
        return <AppError />;
    }
    if (!((_a = data === null || data === void 0 ? void 0 : data.dashboard) === null || _a === void 0 ? void 0 : _a.id)) {
        return <NotFoundError />;
    }
    return <Context.Provider value={value}>{children}</Context.Provider>;
}
