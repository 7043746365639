import { marginRight, fontSize, marginLeft } from '@pocketlaw/tetris';
import styled from 'styled-components';
function lateralSpacing(props) {
    switch (props.$spacingDirection) {
        case 'left':
            return marginLeft(1);
        case 'right':
            return marginRight(1);
        default:
            return undefined;
    }
}
export const Copy = styled.span `
  ${fontSize('small')};
  ${lateralSpacing};
`;
