import { useEffect } from 'react';
import { Context } from './Context';
import { useScript } from '../useScript';
window.dataLayer = window.dataLayer || [];
const isProduction = process.env.APP_ENV === 'production';
const isDevelopment = process.env.APP_ENV === 'development';
const isBeta = process.env.APP_ENV === 'beta';
const isPreview = process.env.APP_ENV === 'preview';
export function GtmProvider(props) {
    const { children } = props;
    const id = process.env.GTM_ID;
    const enabled = Boolean(id && (isProduction || isDevelopment || isBeta || isPreview));
    const script = useScript(`https://www.googletagmanager.com/gtm.js?id=${id}`, {
        disabled: !enabled,
    });
    useEffect(() => {
        if (enabled && script.loaded && !script.error) {
            window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        }
    }, [enabled, script]);
    const push = (data) => {
        if (!data) {
            return;
        }
        Object.entries(data).forEach(([key, value]) => {
            if (value === undefined) {
                // eslint-disable-next-line no-param-reassign
                delete data[key];
            }
        });
        window.dataLayer.push(data);
    };
    return <Context.Provider value={{ push }}>{children}</Context.Provider>;
}
