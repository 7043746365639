import { t } from '@lingui/macro';
import { Chip } from '@pocketlaw/tetris';
import { SignRequestStatus } from 'shared/domains/apollo/generated/types';
export function StatusChip(props) {
    const { status } = props;
    switch (status) {
        case SignRequestStatus.Signed:
            return (<Chip size="small" variant="solid" appearance="success" label={t({
                    comment: 'Esigning status - Signed',
                    message: 'Completed',
                })}/>);
        case SignRequestStatus.Rejected:
            return (<Chip size="small" variant="solid" appearance="danger" label={t({
                    comment: 'Esigning status - Rejected',
                    message: 'Rejected',
                })}/>);
        case SignRequestStatus.Pending:
            return (<Chip size="small" variant="solid" appearance="info" label={t({
                    comment: 'Esigning status - Pending',
                    message: 'Pending',
                })}/>);
        default:
            return null;
    }
}
