import { select, t } from '@lingui/macro';
import { AvatarWithLabel, AvatarLetter, List } from '@pocketlaw/tetris';
import { useAccessByComposers } from '../../hooks/useAccessByComposers';
import { AccessMenuButton } from '../AccessMenuButton';
import { Empty } from '../Empty';
import { Error } from '../Error';
import { Loading } from '../Loading';
import { UpdateAccessMenuItem } from '../UpdateAccessMenuItem';
import { StyledListItem } from './styled';
export function UserAccessList(props) {
    const { users, accessDropdownPermission } = props;
    const { loading, error } = useAccessByComposers();
    const canAddPermission = accessDropdownPermission === 'noAccess' || accessDropdownPermission === 'partialAccess';
    const canRemovePermission = accessDropdownPermission === 'access' || accessDropdownPermission === 'partialAccess';
    if (error) {
        return (<Error title={t({
                message: 'Error',
                comment: 'template permission drawer: failed to load users alert title',
            })} message={t({
                message: 'Failed to load users. Please try again later.',
                comment: 'template permission drawer: failed to load users alert message',
            })}/>);
    }
    if (loading) {
        return <Loading />;
    }
    if (users.length < 1) {
        return (<Empty title={t({
                comment: 'Contract access tab empty content: Title',
                message: 'Empty in here.',
            })} subtitle={t({
                comment: 'Contract access tab empty content: Subtitle',
                message: select(accessDropdownPermission, {
                    access: 'No users with access.',
                    partialAccess: 'No users with partial access.',
                    noAccess: 'No users with no access.',
                    other: 'No users with no access.',
                }),
            })}/>);
    }
    return (<List $density="large">
      {users.map(({ id, name, role }) => {
            const firstLetter = name.charAt(0);
            return (<StyledListItem key={id}>
            <AvatarWithLabel appearance="cyan" size="md" title={name} subtitle={t({
                    comment: 'user role',
                    message: select(role, {
                        owner: 'Owner',
                        member: 'User',
                        other: 'User',
                    }),
                })}>
              <AvatarLetter>{firstLetter}</AvatarLetter>
            </AvatarWithLabel>
            {role !== 'owner' && (<AccessMenuButton>
                {canAddPermission && <UpdateAccessMenuItem type="add" userIds={[id]}/>}
                {canRemovePermission && <UpdateAccessMenuItem type="remove" userIds={[id]}/>}
              </AccessMenuButton>)}
          </StyledListItem>);
        })}
    </List>);
}
