import { createPath } from 'history';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'app/domains/auth/hooks/useAuth';
import { useIsAccessTokenExpired } from 'app/domains/auth/hooks/useIsAccessTokenExpired';
import { SessionExpiredDialog } from 'shared/domains/errors';
/*
 * From user point of view it's session expiring, but technically it's the access token expiring.
 */
export function AccessTokenExpiredDialog() {
    const auth = useAuth();
    const location = useLocation();
    useIsAccessTokenExpired();
    const handleClick = () => {
        auth.loginWithRedirect({ returnTo: createPath(location) });
    };
    return <SessionExpiredDialog onClick={handleClick}/>;
}
