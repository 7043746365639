import { Fragment } from 'react';
import { t } from '@lingui/macro';
import { useGeneratedId } from '@pocketlaw/tetris';
import { LongTextItemContainer, ItemText, StyledButton } from './styled';
export function LongTextItem(props) {
    const { children, onSelect, open, onExpand } = props;
    const uniqueId = useGeneratedId('dropdown-item');
    const maxLength = 64;
    const textLength = children.length;
    const shouldTruncate = textLength > maxLength && !open;
    const text = children.substring(0, shouldTruncate ? maxLength : textLength);
    return (<LongTextItemContainer>
      <ItemText type="button" onClick={onSelect} id={uniqueId}>
        {text}
        {shouldTruncate && <Fragment>&hellip;</Fragment>}
      </ItemText>
      {textLength > maxLength && (<StyledButton type="button" aria-controls={uniqueId} toggled={open} onClick={onExpand} aria-expanded={open}>
          {open ? t `Show less` : t `Read More`}
        </StyledButton>)}
    </LongTextItemContainer>);
}
