import { Fragment, useEffect } from 'react';
import { t } from '@lingui/macro';
import { Switch } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useField } from 'formik';
import { SectionListItem } from 'app/domains/esigning/components/components';
import { GroupMessageField } from './GroupMessageField';
export function GroupMessage() {
    const [useGroupMessage] = useField('settings.useGroupMessage');
    const [, , { setValue: setGroupMessage }] = useField('globals.groupMessage');
    const isGroupMessageActive = useGroupMessage.value;
    useEffect(() => {
        if (!isGroupMessageActive) {
            setGroupMessage(undefined);
        }
    }, [isGroupMessageActive, setGroupMessage]);
    const title = t({
        comment: 'Label for e-signing setting add message',
        message: 'Add message',
    });
    const description = t({
        comment: 'Description for e-signing setting add message',
        message: 'Include a message to all parties',
    });
    return (<Fragment>
      <SectionListItem shape="square" icon={<Falcon icon="envelope"/>} primary={title} secondary={description} endElement={<Switch name={useGroupMessage.name} checked={useGroupMessage.value} onBlur={useGroupMessage.onBlur} onChange={useGroupMessage.onChange}/>}/>
      {isGroupMessageActive && <GroupMessageField />}
    </Fragment>);
}
