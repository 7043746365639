var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { i18n } from '@lingui/core';
import { createBrowserHistory, createPath } from 'history';
import { createRoot } from 'react-dom/client';
import { generatePath } from 'react-router-dom';
import auth from 'app/domains/auth/client';
import authenticate from 'app/domains/auth/utils/authenticate';
import { dangerouslySetCurrentMarket, getCurrentMarket } from 'app/domains/markets/config';
import detectMarket from 'app/domains/markets/utils/detectMarket';
import { isPublicRoute } from 'app/domains/urls';
import { dangerouslySetCurrentLocale } from 'shared/domains/languages/config';
import { detectLocale } from 'shared/domains/languages/utils/detectLocale';
import * as Sentry from 'shared/domains/sentry';
import { Root } from './Root';
const integrations = [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
    }),
];
Sentry.init({
    dsn: process.env.SENTRY_DSN,
    maxBreadcrumbs: 50,
    debug: false,
    application: 'app',
    integrations,
    // Tracing
    tracePropagationTargets: [],
    tracesSampleRate: 0.2,
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.5,
});
const DEFAULT_MARKET = 'se';
const history = createBrowserHistory();
function initMarket(user) {
    const market = detectMarket(history.location.pathname);
    if (!market) {
        // even though the types say this should be a string, it needs to be a array to support nested paths
        const parts = history.location.pathname.split('/').filter(Boolean);
        const pathOptions = {
            marketCode: DEFAULT_MARKET,
        };
        if (parts.length) {
            Object.assign(pathOptions, {
                parts,
            });
        }
        const pathname = generatePath('/:marketCode/:parts*', pathOptions);
        history.replace(Object.assign(Object.assign({}, history.location), { pathname }));
        return initMarket(user);
    }
    dangerouslySetCurrentMarket(market.code);
    return { preferredLocale: user === null || user === void 0 ? void 0 : user.preferredLocale };
}
function initLocale(user) {
    return __awaiter(this, void 0, void 0, function* () {
        const { preferredLocale } = user;
        const locale = detectLocale(getCurrentMarket().marketLocales, preferredLocale);
        return dangerouslySetCurrentLocale(locale.code);
    });
}
function render() {
    const container = document.getElementById('app-root');
    const root = createRoot(container);
    root.render(<Root auth={auth} language={i18n.locale}/>);
}
function init() {
    return __awaiter(this, void 0, void 0, function* () {
        let user;
        try {
            user = yield authenticate({ client: auth, history });
        }
        catch (_a) {
            /**
             * Something unexpected went wrong during authentication, render the app anyway.
             * This does not happen if you're just regularly unauthenticated
             * - If the route does not require authentication, it will render normally.
             * - If the route requires authentication, it will display an error page from AuthProvider
             *
             * Don't do loginWithRedirect here, to avoid eternal loop if you're on an outdated auth/callback URL for example
             */
            const { preferredLocale } = initMarket(user);
            yield initLocale({ preferredLocale });
            return render();
        }
        // prevent initiating & cancelling requests before redirecting to login. prevents unnecessary sentry reports
        if (!user && !isPublicRoute()) {
            return auth.loginWithRedirect({ appState: { returnTo: createPath(window.location) } });
        }
        const { preferredLocale } = initMarket(user);
        yield initLocale({ preferredLocale });
        return render();
    });
}
init();
