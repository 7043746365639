import { EmptyList } from 'app/domains/tasks/components/EmptyList';
import { TaskList } from '../TaskList';
import { useTasks } from '../TasksProvider';
function TaskTabs(props) {
    const { hideSortSelector } = props;
    const { tasks, handleSelectTask, activeFilter } = useTasks();
    if (tasks.loading || tasks.error) {
        return null;
    }
    const completedTasks = tasks.data.filter(task => task.status === 'completed' || task.isCompleted);
    const openTasks = tasks.data.filter(task => task.status !== 'completed');
    const taskItems = activeFilter === 'completed' ? completedTasks : openTasks;
    return tasks.data.length > 0 ? (<TaskList tasks={taskItems} onClick={handleSelectTask} hideSortSelector={hideSortSelector}/>) : (<EmptyList />);
}
export { TaskTabs };
