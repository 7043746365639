import { gql } from '@apollo/client';
export const documentHtmlData = gql `
  query DownloadDocumentHtmlDataForPDFGeneration($documentId: ID!, $companyId: UUID!) {
    fileSystemDocument(id: $documentId) {
      id
      strippedHtmlBody
    }

    company(id: $companyId) {
      id
      logoPath: logo_path
      logoUploaded: logo_uploaded
    }
  }
`;
