import { useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Box, Button, FilterButton, Menu, MenuBody, MenuDivider, MenuFooter, MenuList, } from '@pocketlaw/tetris';
import { MenuSingleSelectItem, ToggleTabs, ToggleTabsButton, ToggleTabsButtonsContainer, ToggleTabsContent, } from 'shared/domains/common-ui';
import { CustomOffset } from './CustomOffset';
import { formatSearchDateTimeFilter } from './formatDateMathOffset';
import { searchDateTimeFilterFuturePreset, searchDateTimeFilterPastPreset } from './presets';
import { useFilter } from '../../useFilter';
export function RelativeDate(props) {
    const { name } = props;
    const filter = useFilter(name);
    const [open, setOpen] = useState(!filter.hasValue);
    const [tab, setTab] = useState('preset');
    const [selectedValue, setSelectedValue] = useState(filter.value);
    const appearance = filter.isTouched ? 'draft' : 'default';
    const applyValue = () => {
        if (!selectedValue) {
            return;
        }
        filter.setValue(selectedValue);
        setOpen(false);
    };
    const isActive = (preset) => JSON.stringify(preset) === JSON.stringify(selectedValue);
    const selectedLabel = formatSearchDateTimeFilter(filter.value);
    const fallbackLabel = t({
        message: 'Select date',
        comment: 'Search filter: Relative date filter',
    });
    return (<Menu open={open} onClose={() => setOpen(false)} target={<FilterButton type="button" appearance={appearance} onClick={() => setOpen(!open)}>
          {selectedLabel || fallbackLabel}
        </FilterButton>}>
      <ToggleTabs activeTab={tab} onSelectTab={id => setTab(id)}>
        <Box px="md" pt="md" style={{ minWidth: '17rem' }}>
          <ToggleTabsButtonsContainer stretch size="small">
            <ToggleTabsButton tabKey="preset">
              <Trans comment="Documents label">Preset</Trans>
            </ToggleTabsButton>
            <ToggleTabsButton tabKey="from-now">
              <Trans comment="Templates label">Relative</Trans>
            </ToggleTabsButton>
          </ToggleTabsButtonsContainer>
        </Box>

        <ToggleTabsContent tabKey="from-now">
          <MenuBody>
            <CustomOffset selectedValue={selectedValue} setSelectedValue={setSelectedValue}/>
          </MenuBody>
        </ToggleTabsContent>

        <ToggleTabsContent tabKey="preset">
          <MenuBody>
            <MenuList>
              {searchDateTimeFilterPastPreset.map(preset => (<MenuSingleSelectItem key={JSON.stringify(preset)} onClick={() => setSelectedValue(preset)} selected={isActive(preset)}>
                  {formatSearchDateTimeFilter(preset)}
                </MenuSingleSelectItem>))}

              <MenuDivider />

              {searchDateTimeFilterFuturePreset.map(preset => (<MenuSingleSelectItem key={JSON.stringify(preset)} onClick={() => setSelectedValue(preset)} selected={isActive(preset)}>
                  {formatSearchDateTimeFilter(preset)}
                </MenuSingleSelectItem>))}
            </MenuList>
          </MenuBody>
        </ToggleTabsContent>

        <MenuFooter>
          <Button $stretch size="small" appearance="primary" onClick={applyValue}>
            <Trans comment="Filter - Dates: Apply filter button label">Apply</Trans>
          </Button>
        </MenuFooter>
      </ToggleTabs>
    </Menu>);
}
