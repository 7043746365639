import { useCompanyFeatures } from 'app/domains/companies';
import { useDocumentApproval } from 'app/domains/esigning/hooks/useDocumentApproval';
import { ApprovalRequestApproverStatus, ApprovalRequestStatus, ApprovalType, } from 'shared/domains/apollo/generated/types';
import { useFeatureFlag } from 'shared/domains/featureFlag';
import { Context } from './Context';
import { useCancelApprovalRequest } from './useCancelApprovalRequest';
import { useCreateApprovalRequest } from './useCreateApprovalRequest';
import { useIsCurrentUserApprover } from './useIsCurrentUserApprover';
import { useLastApprovalRequest } from './useLastApprovalRequest';
const REQUESTED_STATUSES = [ApprovalRequestStatus.Pending, ApprovalRequestStatus.Approved];
const NOT_REQUESTED_STATUSES = [ApprovalRequestStatus.Cancelled, ApprovalRequestStatus.Rejected];
export function FlaggedProvider(props) {
    const { children, documentId } = props;
    const { documentApproval, loading: loadingApproval } = useDocumentApproval(documentId);
    const { lastApprovalRequest, loading: loadingLastRequest } = useLastApprovalRequest(documentId);
    const { cancelApprovalRequest, loading: cancellingRequest } = useCancelApprovalRequest(documentId);
    const { createApprovalRequest, loading: creatingRequest } = useCreateApprovalRequest();
    const { isFeatureAvailable } = useCompanyFeatures();
    const currentUser = useIsCurrentUserApprover(documentId);
    const documentRequiresApproval = Boolean(documentApproval);
    const approvalWorkflowAvailable = isFeatureAvailable('approval');
    const approvalRequired = approvalWorkflowAvailable && documentRequiresApproval;
    const approvalRequestId = lastApprovalRequest === null || lastApprovalRequest === void 0 ? void 0 : lastApprovalRequest.id;
    const approvalType = documentApproval === null || documentApproval === void 0 ? void 0 : documentApproval.type;
    const status = approvalRequired ? (lastApprovalRequest === null || lastApprovalRequest === void 0 ? void 0 : lastApprovalRequest.status) || null : null;
    const approvalNotRequested = approvalRequired && (status === null || NOT_REQUESTED_STATUSES.includes(status));
    const approvalRequested = approvalRequired && Boolean(status && REQUESTED_STATUSES.includes(status));
    const approvalPending = status === ApprovalRequestStatus.Pending;
    const approvalApproved = status === ApprovalRequestStatus.Approved;
    const approvalLoading = loadingApproval || loadingLastRequest;
    const { approvers = [] } = lastApprovalRequest || {};
    const approvedApprovers = approvers.filter(approver => approver && approver.status === ApprovalRequestApproverStatus.Approved);
    const approvalAmount = {
        current: approvedApprovers.length,
        max: approvalType === ApprovalType.All ? approvers.length : 1,
    };
    const value = {
        createRequest: createApprovalRequest,
        creatingRequest,
        cancelRequest: cancelApprovalRequest,
        cancellingRequest,
        approvalWorkflowAvailable,
        approvalNotRequested,
        approvalRequested,
        approvalPending,
        approvalApproved,
        approvalLoading,
        approvalRequestId,
        approvalType,
        approvalAmount,
        currentUser,
    };
    return <Context.Provider value={value}>{children}</Context.Provider>;
}
export function Provider(props) {
    const { children } = props;
    const isApprovalEnabled = useFeatureFlag('feature_advanced_approval_workflow');
    return isApprovalEnabled ? <FlaggedProvider {...props}/> : children;
}
