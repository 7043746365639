import gql from 'graphql-tag';
export const PromptLibraryFragment = gql `
  fragment PromptLibrary on DocumentAssistantPrompt {
    id
    name
    content
    isPublic
    labels
    isSystemPrompt @client
    createdByUser {
      id
      name
    }
  }
`;
export const listPrompts = gql `
  query ListPrompts {
    prompts: documentAssistantPromptsByUser(
      orderBy: { createdAt: desc }
      where: { isPublic: { eq: true } }
    ) {
      ...PromptLibrary
    }
  }
  ${PromptLibraryFragment}
`;
export const getPromptUsages = gql `
  query GetPromptUsages {
    promptUsages: promptUsages(limit: 10, offset: 0) {
      id
      usedAt
    }
  }
`;
