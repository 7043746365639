import { t } from '@lingui/macro';
import { TextArea } from '@pocketlaw/tetris';
import { useField, useFormikContext } from 'formik';
export function Field() {
    const { isSubmitting } = useFormikContext();
    const [field] = useField('message');
    const placeholder = t({
        message: 'Type a message...',
        comment: 'Placeholder text for text field in externally shared document chat',
    });
    return <TextArea stretch label={placeholder} disabled={isSubmitting} {...field}/>;
}
