import { gql } from '@apollo/client';
export const createUserInviteMutation = gql `
  mutation CreateUserInvite($objects: [UserInviteInsertInput!]!) {
    invite: createUserInvite(objects: $objects) {
      returning {
        id
        email
      }
    }
  }
`;
