import { t } from '@lingui/macro';
import { ChatMessageType } from 'shared/domains/apollo/generated/types';
export const getBubblePrefixByPromptType = (type) => {
    switch (type) {
        case ChatMessageType.DocumentSuggest:
            return t({
                comment: 'Document assistant chat - assistant answer intro - for a suggestion',
                message: "Here's your clause",
            });
        case ChatMessageType.DocumentSummarize:
        case ChatMessageType.DocumentSummarizeWithQuotes:
        case ChatMessageType.SelectionSummarize:
            return t({
                comment: 'Document assistant chat - assistant answer intro - for a summary',
                message: "Here's your summary",
            });
        case ChatMessageType.SelectionSummarizeWithQuotes:
        case ChatMessageType.SelectionExplain:
            return t({
                comment: 'Document assistant chat - assistant answer intro - for an explanation',
                message: "Here's your explanation",
            });
        case ChatMessageType.SelectionTranslate:
            return t({
                comment: 'Document assistant chat - assistant answer intro - for a translation',
                message: "Here's your translation",
            });
        case ChatMessageType.SelectionRewrite:
            return t({
                comment: 'Document assistant chat - assistant answer intro - for a rewrite',
                message: "Here's your rewrite",
            });
        case ChatMessageType.DocumentAsk:
        case ChatMessageType.SelectionAsk:
        case ChatMessageType.DocumentAskWithQuotes:
        case ChatMessageType.SelectionAskWithQuotes:
        case ChatMessageType.SelectionSuggest:
            return t({
                comment: 'Document assistant chat - assistant answer intro - for a question',
                message: "Here's your answer",
            });
        default:
            return t({
                comment: 'Assistant chat recipient - from assistant',
                message: 'Assistant',
            });
    }
};
