import { useMutation } from '@apollo/client';
import { ReviewFeedbackStatus } from 'shared/domains/apollo/generated/types';
import { RECOVER_FEEDBACK_MUTATION } from './mutation';
export function useRecoverFeedback() {
    const [mutate] = useMutation(RECOVER_FEEDBACK_MUTATION);
    const recoverFeedback = (feedbackId) => mutate({
        variables: { feedbackId },
        optimisticResponse: {
            revertReviewFeedback: {
                __typename: 'ReviewFeedback',
                id: feedbackId,
                status: ReviewFeedbackStatus.Pending,
            },
        },
    });
    return { recoverFeedback };
}
