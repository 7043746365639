import { t } from '@lingui/macro';
import { List, ListHeader, ListItem, Typography } from '@pocketlaw/tetris';
import { getCurrentMarket } from 'app/domains/markets/config';
import { useLanguageSwitcher, Language, localeConfig } from 'shared/domains/languages';
import { useAccount } from 'shared/domains/users';
import { FlagAvatar } from './styled';
export function LanguageSelector() {
    const account = useAccount();
    const { update } = useLanguageSwitcher();
    const { marketLocales } = getCurrentMarket();
    const currentLocale = localeConfig.getActiveLocale();
    const { userId } = account.data || {};
    const marketLocalesExceptCurrentLocale = marketLocales;
    return (<List>
      <ListHeader title={t({ message: 'Languages', comment: 'User menu: languages list header text' })}/>
      {marketLocalesExceptCurrentLocale.map(({ code, languageCode }) => {
            const { flag: FlagGlyph } = localeConfig.getLocale({ languageCode });
            return (<ListItem key={code} onClick={() => update({ locale: code, userId: userId || '' })} $selected={currentLocale.code === code}>
            <FlagAvatar>
              <FlagGlyph />
            </FlagAvatar>
            <Typography $fontSize="medium" $appearance="300">
              <Language code={code} textInLocalLanguage/>
            </Typography>
          </ListItem>);
        })}
    </List>);
}
