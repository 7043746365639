import { Typography } from '@pocketlaw/tetris';
import { ChatLoader } from '../../../ChatLoader';
import { Markdown } from '../../../Markdown';
export function MessageText(props) {
    const { text, writing = false } = props;
    if (text === '') {
        return <ChatLoader />;
    }
    return (<Typography $appearance="300">
      <Markdown contents={text} writing={writing}/>
    </Typography>);
}
