import { v4 as uuid } from 'uuid';
import { SearchAggregationMetricType, SearchAggregationType, } from 'shared/domains/apollo/generated/types';
export const getDefaultMetricValue = (field) => ({
    type: SearchAggregationType.Metric,
    name: uuid(),
    metricInput: {
        field,
        type: SearchAggregationMetricType.Avg,
        missing: 0,
    },
});
