import { Fragment, useState } from 'react';
import { t } from '@lingui/macro';
import { IconButton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useField } from 'formik';
import { ESignSignatoryFormDialog } from 'app/domains/esigning/components/ESignSignatoryFormDialog';
import { useEsigningSignatoriesFormField } from 'app/domains/esigning/components/EsigningForm';
import { ESignPartyItem } from '../../../ESignPartyItem';
import { SectionList } from '../../../components';
import { PartyActions } from '../PartyActions';
export function UnorderedSigningParties() {
    const [{ value: groupMessage }] = useField('globals.groupMessage');
    const { signatories, addSignatory } = useEsigningSignatoriesFormField();
    const [showAddPartyModal, setShowAddPartyModal] = useState(false);
    const signingSignatories = signatories.filter(({ isSigning }) => isSigning);
    const showModal = () => setShowAddPartyModal(true);
    const hideModal = () => setShowAddPartyModal(false);
    if (signingSignatories.length === 0) {
        return null;
    }
    return (<Fragment>
      {showAddPartyModal && (<ESignSignatoryFormDialog type="Signatory" allSignatories={signatories} onClose={hideModal} onSubmit={addSignatory}/>)}
      <SectionList hideTooManyItems title={t({ message: 'Signing parties', comment: 'Label for signing parties list' })} endElement={<IconButton $round data-id="add-signing-party-button" size="small" appearance="ghost" onClick={showModal}>
            <Falcon icon="plus"/>
          </IconButton>}>
        {signingSignatories.map(signatory => (<ESignPartyItem key={signatory.key} signatory={signatory} groupMessage={groupMessage} endElement={<PartyActions signatory={signatory}/>}/>))}
      </SectionList>
    </Fragment>);
}
