import { unit } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${unit('xl')};
  padding: ${unit('md')};
`;
export const StyledDl = styled.dl `
  display: flex;
  flex-direction: row;
  gap: ${unit('sm')};
`;
