var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Avatar } from '@pocketlaw/tetris';
import { RightAlign, StyledListItem, StyledTypography } from './styled';
export function JobItem(props) {
    const { icon, actions, name, disabled } = props, rest = __rest(props, ["icon", "actions", "name", "disabled"]);
    return (<StyledListItem disabled={disabled} {...rest}>
      {icon && (<Avatar shape="circle" size="sm">
          {icon}
        </Avatar>)}
      <StyledTypography $fontSize="small">{name}</StyledTypography>
      {actions && <RightAlign>{actions}</RightAlign>}
    </StyledListItem>);
}
