import { useRef } from 'react';
import { t } from '@lingui/macro';
import { Alert, Table, TableBody, TableHorizontalScroll, AvatarWithLabel } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { PageLoader, useMobile } from 'shared/domains/common-ui';
import { ApprovalPendingContractsTableHead } from './ApprovalPendingContractsTableHead';
import { ApprovalPendingContractsTableRow } from './ApprovalPendingContractsTableRow';
import { usePendingApprovalContracts } from './usePendingApprovalContracts';
import { Container, EmptyContainer } from './styled';
export function ApprovalPendingContractsTable() {
    const { pendingApprovalContracts, loading, error } = usePendingApprovalContracts();
    const tableRef = useRef(null);
    const isMobile = useMobile();
    const isEmpty = pendingApprovalContracts.length < 1;
    if (loading) {
        return <PageLoader delay={0}/>;
    }
    if (error) {
        return (<Alert appearance="danger" message={t({
                comment: 'Error message when trying to fetch documents with pending approval status for a user',
                message: 'Something went wrong, please try again later',
            })}/>);
    }
    if (isEmpty) {
        return (<EmptyContainer>
        <AvatarWithLabel subtitle={t({
                // eslint-disable-next-line lingui/text-restrictions
                message: 'Whenever there’s something new to approve it will end up here.',
                comment: 'Empty pending approval sub title',
            })} title={t({
                message: 'Hurray, nothing to approve.',
                comment: 'Empty pending approvals title',
            })} size="2xl" alignment="vertical" appearance="secondary">
          <Falcon icon="file-circle-check"/>
        </AvatarWithLabel>
      </EmptyContainer>);
    }
    return (<Container>
      {isMobile && <TableHorizontalScroll tableRef={tableRef}/>}
      <Table ref={tableRef}>
        <colgroup>
          <col width="auto"/>
          <col width="auto"/>
          <col width="auto"/>
          <col width="auto"/>
        </colgroup>
        <ApprovalPendingContractsTableHead />
        <TableBody>
          {pendingApprovalContracts.map(contract => (<ApprovalPendingContractsTableRow key={contract.id} contract={contract}/>))}
        </TableBody>
      </Table>
    </Container>);
}
