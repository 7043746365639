import styled from 'styled-components';
export const Component = styled.fieldset `
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  border: none;
  height: 100%;
  min-width: 0;
`;
