import { SidebarMenuCollapsible } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useDesktopNavigation } from '../../../DesktopNavigation';
import { CollapsibleItemLink } from '../CollapsibleItemLink';
import { useLinkMatch } from '../useLinkMatch';
export function CollapsibleCustomerSuccess() {
    const { collapsibleStates, toggleCollapsible } = useDesktopNavigation();
    const activeChild = useLinkMatch({
        matchPath: ['/customer-success'],
        matchExact: false,
    });
    return (<SidebarMenuCollapsible icon={<Falcon icon="headset"/>} label="Customer Success" activeChild={activeChild} collapsibleKey="customerSuccess" open={collapsibleStates.customerSuccess} onToggle={toggleCollapsible}>
      <CollapsibleItemLink to="/customer-success" label="Company Details" matchPath={['/customer-success']}/>
    </SidebarMenuCollapsible>);
}
