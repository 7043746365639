import { gql } from '@apollo/client';
export const teamMembersQuery = gql `
  query teamMembers($teamId: UUID!) {
    team(id: $teamId) {
      id
      members {
        id
        userId
        role
        companyUser {
          id
          role
          canManageMember: can_manage_member
        }
        user {
          id
          name
          email
        }
      }
    }
  }
`;
