import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { EntryKind } from 'shared/domains/apollo/generated/types';
import { moveDocumentEntryMutation, moveFolderEntryMutation } from './mutation';
function getMutation(type) {
    switch (type) {
        case EntryKind.Document:
            return moveDocumentEntryMutation;
        case EntryKind.Folder:
            return moveFolderEntryMutation;
        default:
            throw new Error(`Unexpected type ${type}`);
    }
}
export function useMoveEntry(options) {
    const { id, kind } = options;
    const [mutate, mutation] = useMutation(getMutation(kind));
    const handler = useCallback((parentId) => mutate({
        variables: { id, parentId },
        update(cache, { data }) {
            const { entries } = data || {};
            const { __typename } = entries || {};
            const cacheId = cache.identify({ id, __typename });
            cache.modify({
                id: cacheId,
                fields: (_, { DELETE }) => DELETE,
            });
        },
    }), [id, mutate]);
    return [handler, mutation];
}
