import { pxToRem, themeGet } from '@pocketlaw/tetris';
import { css } from 'styled-components';
export const toolbarStyles = css `
  .ck.ck-icon {
    color: ${themeGet('colors.gray.600')} !important;
  }

  .ck-flex-gap {
    flex: 1;
  }

  .ck.ck-toolbar {
    border-color: transparent;
    min-height: ${pxToRem(48)};
    background-color: ${themeGet('colors.gray.50')};

    &.ck-rounded-corners {
      border-radius: ${themeGet('borderRadius.md')};
    }

    .ck.ck-toolbar {
      height: auto;
    }

    & button {
      background: inherit;
      border-radius: ${themeGet('borderRadius.md')};
      transition: background-color 200ms ease-in-out;

      &:not(.ck-disabled) {
        cursor: pointer;

        &:hover {
          background-color: ${themeGet('colors.gray.100')};
        }
      }
    }

    .ck-thread__header {
      align-items: center;

      .ck-context-wrapper {
        height: ${pxToRem(34)};
      }

      .ck-context--quotes {
        align-items: center;
      }
    }

    .ck-switchbutton.pl-icon-toggle-button {
      gap: ${pxToRem(10)};

      &:hover {
        background: var(--ck-color-button-on-hover-background);
      }
    }
  }

  .ck-dropdown__panel:has(.ck-toolbar) {
    max-width: 80vw;
  }

  .ck-dropdown__panel .ck-toolbar {
    border-radius: ${themeGet('borderRadius.md')};
  }

  .pleditor-toolbar-fa-icon-button {
    svg:first-child {
      width: 2rem !important;
    }
  }

  .pleditor-toolbar-suggestion-mode-button {
    width: 100%;

    svg:first-child {
      width: 2rem !important;
    }
  }

  .ck.ck-color-selector {
    .ck-color-grids-fragment {
      padding: ${themeGet('spacing.md')};

      .ck-color-selector__remove-color > svg {
        margin: 0;
      }
    }
  }
`;
