import { TableBodyCell } from '@pocketlaw/tetris';
import { localizedFormatDate } from 'shared/domains/common-utils';
import { DateTypography } from './styled';
export function DateCell(props) {
    const { date, headers } = props;
    const formatted = localizedFormatDate(date, {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    });
    return (<TableBodyCell headers={headers}>
      <DateTypography $appearance="300">{formatted}</DateTypography>
    </TableBodyCell>);
}
