import { Trans } from '@lingui/macro';
import { Container, Spacer, StyledTypography } from './styled';
export function Section(props) {
    const { children } = props;
    return (<Container>
      <StyledTypography $fontSize="small" $fontWeight="medium" $appearance="200">
        <Trans comment="Document assistant welcome message">How can we help you?</Trans>
      </StyledTypography>
      <Spacer>{children}</Spacer>
    </Container>);
}
