import { gql } from '@apollo/client';
export const importDocumentToCcs = gql `
  mutation ImportDocumentToCcs($documentId: ID!) {
    importDocumentToCcsAsync(documentId: $documentId) {
      id
      documentId
      status
    }
  }
`;
