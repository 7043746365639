const localStoragePlanType = (key) => {
    function get() {
        return localStorage.getItem(key);
    }
    function set(planType) {
        localStorage.setItem(key, planType);
    }
    function clear() {
        localStorage.removeItem(key);
    }
    return { get, set, clear };
};
export const signupPlanType = localStoragePlanType('signupPlanType');
