import { t } from '@lingui/macro';
import { Alert } from '@pocketlaw/tetris';
import { useFormikContext } from 'formik';
export function SubmissionError() {
    const { status } = useFormikContext();
    if (!(status === null || status === void 0 ? void 0 : status.mutationError)) {
        return null;
    }
    return (<Alert appearance="danger" message={t({
            message: 'Could not send invite.',
            comment: 'Generic error message when inviting user by email',
        })}/>);
}
