import { Trans } from '@lingui/macro';
import { Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { localizedFormatDate } from 'shared/domains/common-utils';
import { PreviewText } from '../PreviewText';
export function Reminder(props) {
    const { reminder } = props;
    const reminderFormatted = localizedFormatDate(reminder, {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    });
    return (<PreviewText empty={!reminderFormatted} icon={<Falcon icon="alarm-clock"/>}>
      {reminderFormatted ? (<Trans comment="tasks preview modal reminder field - When task reminder is set">
          Reminder being sent <Typography $appearance="100">{reminderFormatted}</Typography>
        </Trans>) : (<Trans comment="tasks preview modal reminder field - When task reminder is not set">
          Reminder <Typography $appearance="100">None</Typography>
        </Trans>)}
    </PreviewText>);
}
