import { TableVisualization } from './TableVisualization';
import { getTopHitsEntities } from './getTopHitsEntities';
export function TopHitsTable(props) {
    const { data, aggregations } = props;
    const entries = getTopHitsEntities(data, aggregations);
    const [aggregation] = aggregations;
    const { topHitsInput } = aggregation || {};
    const { size } = topHitsInput || {};
    return <TableVisualization entries={entries} size={size}/>;
}
