var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { HighlightInDocumentDocument, } from './mutation.gql';
export const useGetDocumentHighlightEntries = () => {
    const [mutation, result] = useMutation(HighlightInDocumentDocument, {
        context: { important: true },
    });
    const getDocumentHighlightEntries = (query, texts) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            const { data } = yield mutation({
                variables: {
                    query,
                    texts,
                },
            });
            return ((_a = data === null || data === void 0 ? void 0 : data.highlightInDocument) === null || _a === void 0 ? void 0 : _a.similarities) || [];
        }
        catch (e) {
            return [];
        }
    });
    return Object.assign({ getDocumentHighlightEntries }, result);
};
