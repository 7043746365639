import { Trans, t } from '@lingui/macro';
import { Button, Dialog, DialogContent, DialogFooter, DialogHeader, AvatarWithLabel, Typography, Box, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { AuthenticatedVar } from 'shared/domains/apollo/generated/types';
import { useAuthenticatedState } from 'shared/domains/apollo/utils/cacheVars';
import { RedirectIndicator } from './RedirectIndicator';
export function SessionExpiredDialog(props) {
    const { onClick } = props;
    const authState = useAuthenticatedState();
    const dialogTitle = t({
        message: 'Session expired',
        comment: 'session expired dialog title',
    });
    const handleOnClick = () => {
        onClick();
    };
    if (authState !== AuthenticatedVar.NotAuthenticated) {
        return null;
    }
    return (<Dialog>
      <DialogHeader media={<AvatarWithLabel size="xl" shape="square" title={dialogTitle}>
            <Falcon icon="shield-keyhole"/>
          </AvatarWithLabel>}/>
      <DialogContent>
        <Box flexDirection="column">
          <Typography>
            <Trans comment="Token expired dialog: Description">
              Your session has expired due to inactivity
            </Trans>
          </Typography>
          <RedirectIndicator />
        </Box>
      </DialogContent>
      <DialogFooter>
        <Button appearance="primary" onClick={handleOnClick}>
          <Trans comment="Token expired dialog: Log in button label">Log in</Trans>
        </Button>
      </DialogFooter>
    </Dialog>);
}
