import { Fragment, useState } from 'react';
import { Trans } from '@lingui/macro';
import { Loader } from '@pocketlaw/tetris';
import { RequestApprovalDialog, useApproval } from 'app/domains/approval';
import { StyledButton } from './styled';
export function RequestApprovalButton() {
    const { creatingRequest } = useApproval();
    const [open, setOpen] = useState(false);
    const showDialog = () => setOpen(true);
    const hideDialog = () => setOpen(false);
    return (<Fragment>
      {open && <RequestApprovalDialog onClose={hideDialog}/>}
      <StyledButton appearance="primary" onClick={showDialog} disabled={creatingRequest}>
        <Trans comment="Approval drawer - request approval button label">Request approval</Trans>
        {creatingRequest && <Loader />}
      </StyledButton>
    </Fragment>);
}
