import { matchPath } from 'react-router-dom';

import { getMarketByCode } from 'app/domains/markets/config';

export default function detectMarket(pathname) {
  const match = matchPath(pathname, {
    path: `/:market`,
  });

  const market = getMarketByCode(match?.params?.market);

  if (market) {
    return market;
  }

  return undefined;
}
