import { t } from '@lingui/macro';
import { TableBodyCell, Tooltip, CheckButton } from '@pocketlaw/tetris';
import { useToggleCompleteState } from 'app/domains/tasks';
import { TaskStatusEnumEnum } from 'shared/domains/apollo/generated/types';
import { TasksIds } from '../../../types';
export function CompletedToggleCell(props) {
    const { completed, taskId, status } = props;
    const isCompleted = completed || status === TaskStatusEnumEnum.Completed;
    const toggleCompletedState = useToggleCompleteState();
    const markAsClosed = t `Mark task as completed`;
    const markAsOpen = t `Mark task as unfinished`;
    const tooltipTitle = isCompleted ? markAsOpen : markAsClosed;
    return (<TableBodyCell headers={TasksIds.completeTask}>
      <Tooltip title={tooltipTitle}>
        <div>
          <CheckButton label={t({
            message: 'Complete task',
            comment: 'Screen reader text for complete task checkbox',
        })} selected={isCompleted} onClick={() => toggleCompletedState.mutate({ isCompleted: completed, id: taskId, status })} disabled={toggleCompletedState.loading}/>
        </div>
      </Tooltip>
    </TableBodyCell>);
}
