import { useQuery } from '@apollo/client';
import { GetDocumentMetadataDocument } from './GetDocumentMetadata.gql';
import { getSystemData } from './getSystemData';
import { getPropertyPairs, checkFeatureAvailability } from './utils';
export function useGetDocumentMetadata(documentId) {
    const { data, error, loading, refetch } = useQuery(GetDocumentMetadataDocument, {
        skip: !documentId,
        variables: { documentId },
        fetchPolicy: 'cache-and-network',
    });
    const properties = data ? getPropertyPairs(data) : [];
    const systemData = getSystemData(data === null || data === void 0 ? void 0 : data.systemData);
    const autoTaggingFeatureAvailable = checkFeatureAvailability(data);
    const handleRefetch = () => refetch({ documentId });
    return {
        error,
        loading,
        properties,
        systemData,
        autoTaggingFeatureAvailable,
        refetch: handleRefetch,
    };
}
