import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { Box, Typography, Chip, EmptyState } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Visualization } from '../Visualization';
import { DRAGGABLE_HANDLE } from '../WidgetGrid/constants';
import { WidgetBody, WidgetContainer, WidgetDescription, WidgetHeader } from './styled';
export function Widget(props) {
    const { widget, filters, actions, editMode = false } = props;
    // TODO: Remove the i18n once mock is removed.
    const displayLabel = i18n._(widget.label || '');
    const displayDescription = i18n._(widget.description || '');
    const hasLocalFilters = Boolean(Object.keys((widget === null || widget === void 0 ? void 0 : widget.filters) || {}).length);
    const mergedFilters = Object.assign(Object.assign({}, widget.filters), filters);
    if (!widget.id) {
        return (<EmptyState icon={<Falcon icon="chart-line-up"/>} title={t({ message: 'No widget', comment: 'Insights: No widget found - title' })} description={t({
                message: 'No widget was found for this slot',
                comment: 'Insights: No widget found - description',
            })}/>);
    }
    return (<WidgetContainer $editMode={editMode}>
      <WidgetHeader width="100%" p="2xl" pb="0" alignItems="center" gap="md">
        <Box flexDirection="column" style={{ minWidth: 0 }}>
          {displayLabel.length && (<Typography $fontWeight="bold" $fontSize="large" $appearance="300" $lineHeight="neutralized" $truncate className={DRAGGABLE_HANDLE}>
              {displayLabel}
            </Typography>)}
          {displayDescription && (<WidgetDescription $fontSize="small" $appearance="100" $truncate>
              {displayDescription}
            </WidgetDescription>)}
        </Box>
        <Box ml="auto" alignItems="center" gap="md">
          {hasLocalFilters && <Chip label="Filtered" icon={<Falcon icon="bars-filter"/>}/>}
          {actions}
        </Box>
      </WidgetHeader>

      <WidgetBody alignItems="center" justifyContent="center" p="2xl" pt="0">
        {widget && (<Visualization type={widget.type} aggregations={widget.aggregations} filters={mergedFilters} colorScheme={widget.colorScheme} isHorizontal={widget.isHorizontal}/>)}
      </WidgetBody>
    </WidgetContainer>);
}
