var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Falcon } from '@pocketlaw/tetris/macro';
import { StyledTableCell, Container, StyledButton, StyledCellText } from './styled';
export function TitleCell(props) {
    const { title, tabIndex, onClick, disabled } = props, restProps = __rest(props, ["title", "tabIndex", "onClick", "disabled"]);
    return (<StyledTableCell {...restProps}>
      <Container>
        <StyledButton type="button" appearance="ghost" tabIndex={tabIndex} onClick={onClick} disabled={disabled}>
          <StyledCellText $appearance="300">{title}</StyledCellText>
          {!disabled && <Falcon icon="chevron-right" $size={12}/>}
        </StyledButton>
      </Container>
    </StyledTableCell>);
}
