import { IconButton, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const AvatarContainer = styled.div `
  flex: 1;
  overflow: hidden;
`;
export const StyledIconButton = styled(IconButton) `
  margin-right: ${themeGet('spacing.sm')};
`;
export const IconContainer = styled.div `
  padding-inline: ${themeGet('spacing.lg')};
`;
