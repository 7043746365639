var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Trans, t } from '@lingui/macro';
import { Dialog, DialogHeader, AvatarWithLabel, DialogContent, Grid, Column, Alert, DialogFooter, Button, Loader, useToast, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import * as Sentry from 'shared/domains/sentry';
import { useSendReminderToSignatories } from '../useSendReminderToSignatories';
export function ConfirmReminderDialog(props) {
    const { onClose } = props;
    const { addToast } = useToast();
    const [sendReminderToSignatories, { loading, error }] = useSendReminderToSignatories();
    const handleSubmit = () => __awaiter(this, void 0, void 0, function* () {
        try {
            yield sendReminderToSignatories();
            addToast({
                title: t({ comment: 'E-signing send reminder - toast message', message: 'Reminder sent' }),
                appearance: 'info',
            });
            onClose();
        }
        catch (e) {
            Sentry.captureException(e);
        }
    });
    return (<Dialog size="sm" onClose={onClose}>
      <DialogHeader media={<AvatarWithLabel size="xl" shape="square" title={t({
                comment: 'E-signing send reminder dialog - title',
                message: 'Send reminder',
            })}>
            <Falcon icon="bell"/>
          </AvatarWithLabel>}/>
      <DialogContent>
        <Grid directions="vertical" spacing={2}>
          <Column width="100%">
            <Trans comment="E-signing send reminder dialog - message">
              A reminder to sign this document will be sent to every person that has not signed yet.
            </Trans>
          </Column>
          {error && (<Column width="100%">
              <Alert appearance="danger" message={t({
                message: 'The reminder could not be sent. Please try again or wait a moment and try later.',
                comment: 'E-signing send reminder dialog - failed error message',
            })}/>
            </Column>)}
        </Grid>
      </DialogContent>
      <DialogFooter>
        <Button type="button" appearance="secondary" disabled={loading} onClick={onClose}>
          <Trans comment="E-signing send reminder dialog - cancel button label">Cancel</Trans>
        </Button>
        <Button type="button" disabled={loading} onClick={handleSubmit}>
          {loading && <Loader />}
          <Trans comment="E-signing send reminder dialog - confirm button label">Send</Trans>
        </Button>
      </DialogFooter>
    </Dialog>);
}
