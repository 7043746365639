var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { forwardRef } from 'react';
import { useAccount } from 'shared/domains/users';
import { UserAvatarWithLabel } from '../UserAvatarWithLabel';
import { UserCardWrapper } from './styled';
export const UserCard = forwardRef((props, ref) => {
    var _a, _b;
    const { isActive, onClick } = props, rest = __rest(props, ["isActive", "onClick"]);
    const account = useAccount();
    const userName = ((_a = account.data.profile) === null || _a === void 0 ? void 0 : _a.name) || '';
    const userCompany = (_b = account.data.company) === null || _b === void 0 ? void 0 : _b.name;
    return (<UserCardWrapper $isActive={isActive} onClick={onClick} {...rest} ref={ref}>
      <UserAvatarWithLabel size="md" title={userName} subtitle={userCompany}/>
    </UserCardWrapper>);
});
UserCard.displayName = 'UserCard';
