import { Trans, t } from '@lingui/macro';
import { Button, Dialog, DialogContent, DialogFooter, DialogHeader, Column, Grid, AvatarWithLabel, Typography, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
function NotsupportedDocumentTypeDialog(props) {
    const { onClose } = props;
    const title = t({
        id: 'copy-dialog-type-not-supported-title',
        message: 'Copying not possible',
        comment: 'copy document type not possible dialog title',
    });
    return (<Dialog size="sm" onClose={onClose}>
      <DialogHeader media={<AvatarWithLabel size="xl" shape="square" title={title}>
            <Falcon icon="copy"/>
          </AvatarWithLabel>}/>
      <DialogContent>
        <Grid directions="vertical" spacing={2}>
          <Column width="100%">
            <Typography $appearance="300">
              <Trans comment="Duplicate Dialog - body text" id="copy-dialog-type-not-supported-body">
                This content type cannot be copied.
              </Trans>
            </Typography>
          </Column>
        </Grid>
      </DialogContent>
      <DialogFooter>
        <Button type="button" appearance="primary" onClick={onClose}>
          <Trans>Okay</Trans>
        </Button>
      </DialogFooter>
    </Dialog>);
}
export default NotsupportedDocumentTypeDialog;
