var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment, useState } from 'react';
import { Trans } from '@lingui/macro';
import { Breadcrumb, Breadcrumbs, PageHeader, Tab, TabContent, TabNav, Tabs, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Link } from 'react-router-dom';
import { MainContent } from 'app/domains/common-ui/components/PageLayout';
import { TeamsTable, CreateTeamButton, useTeamsContext } from 'app/domains/teams';
import { InvitesTable, InviteUserButton, UsersTable, useUserInvites, useCompanyUserContext, } from 'app/domains/users';
import { SSORestricted } from 'shared/domains/users/components/SSORestricted';
import { setTabParam } from './utils/setTabParam';
import { TableToolbar } from '../TableToolbar';
import { ButtonText, IconContainer } from './styled';
import { UsersTeamsPageTabIds } from './types';
export function UsersTeamsPage(props) {
    const { initialTab, canInvite } = props;
    const { refetch, setSearch: setInviteSearch, searching: invitesSearching } = useUserInvites();
    const { setSearch: setUserSearch, searching: userSearching } = useCompanyUserContext();
    const [tab, setTab] = useState(initialTab);
    const { setSearch: setTeamsSearch, searching: teamsSearching } = useTeamsContext();
    const handleTabs = (id) => {
        if (tab === UsersTeamsPageTabIds.users) {
            setUserSearch('');
        }
        if (tab === UsersTeamsPageTabIds.invites) {
            setInviteSearch('');
        }
        if (tab === UsersTeamsPageTabIds.teams) {
            setTeamsSearch('');
        }
        setTabParam(id);
        setTab(id);
    };
    return (<Tabs onSelect={id => handleTabs(id)} activeTab={tab}>
      <PageHeader breadcrumbs={<Breadcrumbs>
            <Breadcrumb as={Link} to="/dashboard">
              <Trans comment="Breadcrumb pointing to /dashboard">Pocketlaw</Trans>
            </Breadcrumb>
            <Breadcrumb>
              <Trans comment="Users & Teams breadcrumb">Users & teams</Trans>
            </Breadcrumb>
          </Breadcrumbs>} heading={<Trans comment="Users & Teams page header">Users & teams</Trans>} actions={<Fragment>
            {canInvite && (<InviteUserButton onInviteCreated={refetch}>
                <IconContainer $hide="sm">
                  <Falcon icon="user-plus"/>
                </IconContainer>
                <IconContainer $hide="xs">
                  <Falcon icon="plus"/>
                </IconContainer>
                <ButtonText>
                  <Trans comment="Users & teams page header action - invite user">
                    Invite user
                  </Trans>
                </ButtonText>
              </InviteUserButton>)}
            <CreateTeamButton />
          </Fragment>} tabs={<TabNav>
            <Tab id={UsersTeamsPageTabIds.users}>
              <Trans comment="users & teams route - users tab">Users</Trans>
            </Tab>
            {canInvite && (<SSORestricted>
                <Tab id={UsersTeamsPageTabIds.invites}>
                  <Trans comment="users & teams route - pending invites tab">Pending invites</Trans>
                </Tab>
              </SSORestricted>)}
            <Tab id={UsersTeamsPageTabIds.teams}>
              <Trans comment="users & teams route - teams tab">Teams</Trans>
            </Tab>
          </TabNav>}/>
      <MainContent>
        <TabContent tabId={UsersTeamsPageTabIds.users}>
          <TableToolbar onSearch={setUserSearch} loading={userSearching}>
            {(_a) => {
        var { tableRef } = _a, rest = __rest(_a, ["tableRef"]);
        return <UsersTable ref={tableRef} {...rest}/>;
    }}
          </TableToolbar>
        </TabContent>
        <SSORestricted>
          <TabContent tabId={UsersTeamsPageTabIds.invites}>
            <TableToolbar onSearch={setInviteSearch} loading={invitesSearching}>
              {(_a) => {
        var { tableRef } = _a, rest = __rest(_a, ["tableRef"]);
        return <InvitesTable ref={tableRef} {...rest}/>;
    }}
            </TableToolbar>
          </TabContent>
        </SSORestricted>
        <TabContent tabId={UsersTeamsPageTabIds.teams}>
          <TableToolbar onSearch={setTeamsSearch} loading={teamsSearching}>
            {({ tableRef }) => <TeamsTable ref={tableRef}/>}
          </TableToolbar>
        </TabContent>
      </MainContent>
    </Tabs>);
}
