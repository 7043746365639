import { AccessList } from 'app/domains/filesystem';
import { useActiveDocument, DocumentDrawerHeader } from 'shared/domains/documents';
import { DrawerContainer, ScrollContainer } from '../../styled';
import { getTitle } from '../../utils';
export function Access(props) {
    const { onClose, onGoBack } = props;
    const { id } = useActiveDocument();
    const title = getTitle('access');
    return (<DrawerContainer>
      <DocumentDrawerHeader type="nested" title={title} onClose={onClose} onGoBack={onGoBack}/>
      <ScrollContainer>
        <AccessList entryId={id}/>
      </ScrollContainer>
    </DrawerContainer>);
}
