var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { DocumentMetadataPropertySetterEnum } from 'shared/domains/apollo/generated/types';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { createMetadataPairMutation, deleteMetadataPairMutation, updateMetadataPairMutation, DocumentMetadataPairFragment, verifyMetadataPairMutation, } from './mutations';
export function useMetadataActions() {
    const { data: accountData } = useAccount();
    const { userId } = accountData;
    const [createMutation, createState] = useMutation(createMetadataPairMutation);
    const [updateMutation, updateState] = useMutation(updateMetadataPairMutation);
    const [deleteMutation, deleteState] = useMutation(deleteMetadataPairMutation);
    const [verifyMutation, verifyState] = useMutation(verifyMetadataPairMutation);
    const mutationLoading = createState.loading || updateState.loading || deleteState.loading || verifyState.loading;
    const mutationError = createState.error || createState.error || deleteState.error || verifyState.error;
    const handleVerifyTags = (variables) => __awaiter(this, void 0, void 0, function* () {
        const { pairIds, onCompleted } = variables;
        return verifyMutation({
            variables,
            onCompleted,
            onQueryUpdated(observableQuery) {
                return observableQuery.refetch();
            },
            update(cache) {
                if (pairIds) {
                    pairIds.forEach(id => {
                        const cacheId = cache.identify({ id, __typename: 'DocumentMetadataPair' });
                        cache.modify({
                            id: cacheId,
                            fields: {
                                approvedByUserId() {
                                    return userId;
                                },
                                lastSetBy: () => DocumentMetadataPropertySetterEnum.User,
                            },
                        });
                    });
                }
            },
        });
    });
    const handleCreate = (createData) => {
        const { propertyName, value, documentId } = createData;
        return createMutation({
            variables: {
                propertyName,
                value,
                documentId,
            },
            update(cache, { data }) {
                cache.modify({
                    fields: {
                        documentMetadataPairs(existing = []) {
                            const { createDocumentMetadataPair } = data || {};
                            if (createDocumentMetadataPair) {
                                const cacheId = cache.identify({
                                    id: createDocumentMetadataPair.id,
                                    // eslint-disable-next-line no-underscore-dangle
                                    __typename: createDocumentMetadataPair.__typename,
                                });
                                const newPair = cache.writeFragment({
                                    id: cacheId,
                                    fragment: DocumentMetadataPairFragment,
                                    data: createDocumentMetadataPair,
                                });
                                return [...existing, newPair];
                            }
                            return existing;
                        },
                    },
                });
            },
        });
    };
    const handleUpdate = (updateData) => {
        const { id, value } = updateData;
        return updateMutation({
            variables: {
                id,
                input: {
                    value,
                },
            },
            update(cache, { data }) {
                cache.modify({
                    fields: {
                        documentMetadataPairs(existing = []) {
                            const { updateDocumentMetadataPair } = data || {};
                            if (updateDocumentMetadataPair) {
                                const cacheId = cache.identify({
                                    id: updateDocumentMetadataPair.id,
                                    // eslint-disable-next-line no-underscore-dangle
                                    __typename: updateDocumentMetadataPair.__typename,
                                });
                                cache.writeFragment({
                                    id: cacheId,
                                    fragment: DocumentMetadataPairFragment,
                                    data: updateDocumentMetadataPair,
                                });
                            }
                            return existing;
                        },
                    },
                });
            },
        });
    };
    const handleDelete = (id) => deleteMutation({
        variables: {
            id,
        },
        update(cache, { data }) {
            const { deleteDocumentMetadataPair } = data || {};
            const { __typename } = deleteDocumentMetadataPair || {};
            const cacheId = cache.identify({ id, __typename });
            cache.modify({
                id: cacheId,
                fields(_, { DELETE }) {
                    return DELETE;
                },
            });
        },
    });
    return {
        create: handleCreate,
        update: handleUpdate,
        delete: handleDelete,
        verify: handleVerifyTags,
        error: mutationError,
        loading: mutationLoading,
    };
}
