var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Suspense } from 'react';
import { Route } from 'react-router-dom';
import { PageLoader } from 'shared/domains/common-ui';
export function SuspenseRoute(props) {
    const { fallback } = props, routeProps = __rest(props, ["fallback"]);
    const suspenseFallback = fallback || <PageLoader />;
    return (<Suspense fallback={suspenseFallback}>
      <Route {...routeProps}/>
    </Suspense>);
}
