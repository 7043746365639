import { t } from '@lingui/macro';
import { LoaderOverlay } from '@pocketlaw/tetris';
import { usePlai } from 'app/domains/review';
import { ReviewStatus } from 'shared/domains/apollo/generated/types';
import { ErrorState } from './ErrorState';
import { Review } from './Review';
import { ReviewFailedState } from './ReviewFailedState';
import { Container } from './styled';
export function Root() {
    const { initiating, review } = usePlai();
    if (initiating) {
        return (<LoaderOverlay label={t({ message: 'Loading', comment: 'Plai Drawer - loading screen - loading message' })}/>);
    }
    if ((review === null || review === void 0 ? void 0 : review.status) === ReviewStatus.Error) {
        return (<Container>
        <ReviewFailedState />
      </Container>);
    }
    if (!review) {
        return (<Container>
        <ErrorState />
      </Container>);
    }
    return (<Container>
      <Review />
    </Container>);
}
