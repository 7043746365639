import { useQuery } from '@apollo/client';
import { useCompanyFeatures } from 'app/domains/companies';
import { pendingApprovalsCountQuery } from './query';
export const useApprovalDocumentsCount = () => {
    var _a;
    const { isFeatureAvailable, loading: loadingCompanyFeatures } = useCompanyFeatures();
    const isApprovalWorkflowAvailable = isFeatureAvailable('approval');
    const { data, loading: loadingQuery, error, } = useQuery(pendingApprovalsCountQuery, {
        skip: !isApprovalWorkflowAvailable,
    });
    const approvedDocumentsCount = ((_a = data === null || data === void 0 ? void 0 : data.esigningApprovalRequests) === null || _a === void 0 ? void 0 : _a.length) || 0;
    const loading = loadingCompanyFeatures || loadingQuery;
    return { approvedDocumentsCount, loading, error };
};
