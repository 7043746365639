import { HttpLink, split, concat } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { errorLink } from './errorLink';
import getEndpoint from './getEndpoint';
import isImportant from './isImportant';
const linkConfig = {
    uri: getEndpoint,
};
/**
 * If we send context.important with the request. Apollo will use
 * HttpLink. Otherwise BatchHttpLink will be used by default.
 */
const shouldBatchLink = split(isImportant, new HttpLink(linkConfig), new BatchHttpLink(linkConfig));
const link = concat(errorLink, shouldBatchLink);
export default link;
