import { useState } from 'react';
import { Menu } from '@pocketlaw/tetris';
import { AssigneeDropdown } from './AssigneeDropdown';
import { AssigneeTarget } from './AssigneeTarget';
export function AssigneesField() {
    const [isOpen, setOpen] = useState(false);
    return (<Menu open={isOpen} onClose={() => setOpen(false)} target={<AssigneeTarget onClick={() => setOpen(!isOpen)}/>}>
      <AssigneeDropdown />
    </Menu>);
}
