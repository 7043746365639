import { t } from '@lingui/macro';
export const getDialogTitle = (isComposedDocument) => {
    if (isComposedDocument) {
        return t({
            message: 'Fine-tuning?',
            comment: 'Edit composed document as free-text dialog title',
        });
    }
    return t({
        message: 'Fine-tuning?',
        comment: 'Edit uploaded document as free-text dialog title',
    });
};
export const getDialogSubtitle = (isComposedDocument) => {
    if (isComposedDocument) {
        return t({
            message: 'Use the editor to change your document.',
            comment: 'Edit composed document as free-text dialog subtitle',
        });
    }
    return t({
        message: 'Use the editor to change your document.',
        comment: 'Edit uploaded document as free-text dialog subtitle',
    });
};
export const getEditOriginalFieldTitle = (isComposedDocument) => {
    if (isComposedDocument) {
        return t({
            comment: 'Convert composed document to free-text dialog - Edit the original file',
            message: 'Edit the original file',
        });
    }
    return t({
        comment: 'Convert uploaded document to free-text dialog - Edit the original file',
        message: 'Edit the original file',
    });
};
export const getEditOriginalFieldDescription = (isComposedDocument) => {
    if (isComposedDocument) {
        return t({
            comment: 'Convert composed document to free-text dialog - Edit the original file description',
            message: 'Keep on working with your original file.',
        });
    }
    return t({
        comment: 'Convert uploaded document to free-text dialog - Edit the original file description',
        message: 'Keep on working with your original file.',
    });
};
export const getEditCopyFieldTitle = (isComposedDocument) => {
    if (isComposedDocument) {
        return t({
            comment: 'Convert composed document to free-text dialog - Edit a copy of the original file',
            message: 'Edit a copy of the original file',
        });
    }
    return t({
        comment: 'Convert uploaded document to free-text dialog - Edit a copy of the original file',
        message: 'Edit a copy of the original file',
    });
};
export const getEditCopyFieldDescription = (isComposedDocument) => {
    if (isComposedDocument) {
        return t({
            comment: 'Convert composed document to free-text dialog - Edit a copy of the original file description',
            message: 'The original file is retained and you can use that file if you want to go back and edit the answers in the template.',
        });
    }
    return t({
        comment: 'Convert uploaded document to free-text dialog - Edit a copy of the original file description',
        message: 'The original file is retained and you can use that file if you want download and refer original document',
    });
};
export const getDialogNote = (isComposedDocument) => {
    if (isComposedDocument) {
        return t({
            comment: 'Edit composed document as free text dialog note',
            message: 'When you click on Get started, you can no longer go back and edit in the template so make sure that you have answered all questions correctly.',
        });
    }
    return t({
        comment: 'Edit uploaded document as free text dialog note',
        message: 'When you click on Get started, you will loose all connection with your original uploaded file',
    });
};
