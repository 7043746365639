import styled from 'styled-components';
export const DrawerContainer = styled.div `
  display: flex;
  flex-direction: column;
  height: 100%;
`;
export const Scroller = styled.div `
  flex: 1 1 auto;
  height: 100%;
  width: 100%;
  overflow: hidden auto;
`;
