import { Typography, unit } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Header = styled.div `
  display: flex;
  flex-direction: column;
`;
export const TextWrapper = styled.div `
  display: flex;
  flex-direction: column;
  padding: ${unit('xl')};
`;
export const HeaderTitleContainer = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const Title = styled(Typography) `
  flex: 1;
`;
