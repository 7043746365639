import { pxToRem, themeGet } from '@pocketlaw/tetris';
import { Text } from '@pocketlaw/tetris/deprecated';
import styled from 'styled-components';
export const StyledText = styled(Text) `
  text-align: center;
`;
export const Container = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
export const SkeletonContainer = styled.div `
  display: grid;
  grid-template-columns: 8fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: ${pxToRem(16)};
  grid-row-gap: 0;
  position: relative;
  padding: ${themeGet('spacing.lg')};
  text-decoration: none;

  scroll-margin: ${themeGet('spacing.lg')};
`;
