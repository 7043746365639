const colors = [
    'success',
    'danger',
    'magenta',
    'cyan',
    'info',
    'warning',
    'orange',
    'brown',
    'indigo',
    'secondary',
];
export function getColor(title) {
    const firstLetter = (title === null || title === void 0 ? void 0 : title.trim().toUpperCase().charAt(0).toUpperCase()) || 'A';
    const secondLetter = (title === null || title === void 0 ? void 0 : title.trim().toUpperCase().charAt(1).toUpperCase()) || 'B';
    const code = firstLetter.charCodeAt(0) + secondLetter.charCodeAt(0);
    const charCode = code % colors.length;
    return colors[charCode];
}
