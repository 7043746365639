import { isValid } from 'date-fns';
import { localeConfig } from 'shared/domains/languages/config';
export const formatDateLabel = (label, date) => {
    const localeCode = localeConfig.getActiveLocale().code;
    const dates = Array.isArray(date) ? date : [date];
    const [startDate, endDate] = dates.reduce((prev, current) => {
        if (current && isValid(current)) {
            return [...prev, current];
        }
        return prev;
    }, []);
    if (startDate) {
        const dateTimeFormat = new Intl.DateTimeFormat(localeCode);
        if (endDate) {
            return label.concat(' ', dateTimeFormat.formatRange(startDate, endDate));
        }
        return label.concat(' ', dateTimeFormat.format(startDate));
    }
    return label;
};
