import { EsigningProviderAuthenticationModeSlug, } from 'shared/domains/apollo/generated/types';
export const cleanDefaultSignatory = (signatory) => {
    const { firstName, lastName, email, label, title, mobile, authenticationMode = EsigningProviderAuthenticationModeSlug.Email, } = signatory;
    return {
        firstName,
        lastName,
        email,
        label,
        title,
        mobile,
        authenticationMode,
    };
};
export const cleanDefaultCcRecipient = (ccRecipient) => {
    const { firstName, lastName, email, label } = ccRecipient;
    return {
        firstName,
        lastName,
        email,
        label,
    };
};
