var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@apollo/client';
import { t } from '@lingui/macro';
import { CompanyUsersQueryDocument } from './CompanyUsersQuery.gql';
const isValidUser = (user) => Boolean(user === null || user === void 0 ? void 0 : user.id);
export const useGetUsers = () => {
    const _a = useQuery(CompanyUsersQueryDocument), { data } = _a, rest = __rest(_a, ["data"]);
    const { companyUsers = [] } = data || {};
    const users = companyUsers
        .map(({ user }) => user)
        .filter(isValidUser)
        .map(user => ({
        id: user.id,
        name: user.name || t({ message: 'Unknown', comment: 'Unknown user name' }),
    }));
    return Object.assign({ users }, rest);
};
