import { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useToast } from '@pocketlaw/tetris';
import { toastNotificationVar } from '../../plugins/ToastHandler';
export function useToastHandler() {
    const { addToast } = useToast();
    const toastNotification = useReactiveVar(toastNotificationVar);
    useEffect(() => {
        if (toastNotification) {
            addToast(toastNotification);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toastNotification]);
}
