import { t } from '@lingui/macro';
import { Alert } from '@pocketlaw/tetris';
import { getIn, useFormikContext } from 'formik';
import { INVALID_SIGNING_ORDER_ERROR } from 'app/domains/esigning/components/EsigningForm/EsigningSchema';
export function SigningOrderAlert() {
    const { errors } = useFormikContext();
    const error = getIn(errors, 'signatories');
    if (error !== INVALID_SIGNING_ORDER_ERROR) {
        return null;
    }
    return (<Alert appearance="info" title={t({
            comment: 'Alert title for when the user has selected set signing order and not selected an order for all signing parties',
            message: 'Incorrect signing order',
        })} message={t({
            comment: 'Alert message for when the user has selected set signing order and not selected an order for all signing parties',
            message: 'Set the signing order to continue',
        })}/>);
}
