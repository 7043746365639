import { useQuery } from '@apollo/client';
import { InsightsProviderDashboardQueryDocument } from './InsightProviderQuery.gql';
export function useGetProviderData(id) {
    const { data, error, loading } = useQuery(InsightsProviderDashboardQueryDocument, {
        variables: {
            id,
        },
    });
    const { searchInsightsDashboard, categories, companyUsers } = data || {};
    return {
        data: {
            dashboard: searchInsightsDashboard,
            categories,
            companyUsers,
        },
        loading,
        error,
    };
}
