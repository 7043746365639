var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import apolloClient from 'app/domains/apollo';
import { base64EncodeUnicode } from 'app/domains/download/utils/base64EncodeUnicode';
import { htmlToPlast } from 'shared/domains/documents';
import { documentHtmlData } from './query';
import { getLogoUrl } from './utils';
import { getCompanyId } from '../getCompanyId';
export function getDocumentRenderInput(documentId, skipPlast) {
    return __awaiter(this, void 0, void 0, function* () {
        const companyId = yield getCompanyId();
        const { data } = yield apolloClient.query({
            query: documentHtmlData,
            variables: { documentId, companyId },
            fetchPolicy: 'network-only',
        });
        function formatContent() {
            return __awaiter(this, void 0, void 0, function* () {
                var _a;
                const html = ((_a = data.fileSystemDocument) === null || _a === void 0 ? void 0 : _a.strippedHtmlBody) || '';
                if (skipPlast) {
                    return base64EncodeUnicode(html);
                }
                const plast = yield htmlToPlast(html);
                return JSON.stringify(plast);
            });
        }
        const logoUrl = getLogoUrl(data);
        const content = yield formatContent();
        return {
            content,
            logoUrl,
        };
    });
}
