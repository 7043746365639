import { DraftPreview } from '../../DraftPreview';
import { useComposerData } from '../ComposerDataProvider';
import { ComposerSelect } from '../ComposerSelect';
export function Root() {
    const { selectedComposerId, closeDialog, goBackToSelect } = useComposerData();
    if (selectedComposerId) {
        return (<DraftPreview composerId={selectedComposerId} onClose={closeDialog} onGoBack={goBackToSelect}/>);
    }
    return <ComposerSelect />;
}
