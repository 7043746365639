import { Button } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { StyledLink, Container, Left, Center, Spacer, StyledLogotype } from './styled';
export const NAVIGATION_HEIGHT = 56;
export function Header(props) {
    const { redirectText, redirectTo, height = NAVIGATION_HEIGHT } = props;
    return (<Container $height={height}>
      <Left>
        {redirectText && (<Button appearance="ghost" as={StyledLink} to={redirectTo}>
            <Falcon icon="chevron-left" $size={16}/>
            {redirectText}
          </Button>)}
      </Left>
      <Center>
        <StyledLogotype />
      </Center>
      <Spacer />
    </Container>);
}
