import { gql } from '@apollo/client';
export const query = gql `
  query GetCompanyUsersWithRoles {
    companyUsers(order_by: [{ user: { name: asc } }]) {
      id
      role
      canManageMember: can_manage_member
      user {
        id
        name
      }
    }
  }
`;
