import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { GetAccessByComposersDocument } from 'app/domains/templates';
import { AddComposerTeamPermissionsDocument } from './AddComposerTeamPermissions.gql';
import { RemoveComposerTeamPermissionsDocument } from './RemoveComposerTeamPermissions.gql';
export function useUpdateComposerTeamPermissions() {
    const [add, addStates] = useMutation(AddComposerTeamPermissionsDocument);
    const { addToast } = useToast();
    const [remove, removeStates] = useMutation(RemoveComposerTeamPermissionsDocument);
    const update = { add, remove };
    const loading = addStates.loading || removeStates.loading;
    const [refetching, setRefetching] = useState(false);
    const updateComposerTeamPermissions = (options) => {
        const { type, teamIds, composerIds } = options;
        update[type]({
            variables: { teamIds, composerIds },
            refetchQueries: [GetAccessByComposersDocument],
            onQueryUpdated: query => {
                setRefetching(true);
                return query.refetch().finally(() => setRefetching(false));
            },
            onError: () => {
                addToast({
                    title: t({
                        message: 'Something went wrong',
                        comment: 'toast when failing to update composer access',
                    }),
                    appearance: 'danger',
                });
            },
            onCompleted: () => {
                addToast({
                    title: t({
                        message: 'Updated template access successfully',
                        comment: 'success toast when updating composer access',
                    }),
                    appearance: 'success',
                });
            },
        });
    };
    return { updateComposerTeamPermissions, loading, refetching };
}
