import { t } from '@lingui/macro';
import { ButtonGroup } from '@pocketlaw/tetris';
import { SearchDocumentTypeEnum, } from 'shared/domains/apollo/generated/types';
import { ClearFilter } from './components/ClearFilter';
import { MultiselectOperator } from './components/MultiselectOperator';
import { OperatorSelector } from './components/OperatorSelector';
import { useFilter } from './useFilter';
export const searchDocumentTypeFilterLabel = () => t({
    message: 'Content type',
    comment: 'Search entity type filter: Content type',
});
export function SearchDocumentTypeFilter() {
    const name = 'metadataDocumentType';
    const filter = useFilter(name);
    const items = [
        {
            id: SearchDocumentTypeEnum.Composed,
            title: t({
                message: 'Document (generated)',
                comment: 'Search entity type filter: Documents (generated)',
            }),
        },
        {
            id: SearchDocumentTypeEnum.Freetext,
            title: t({
                message: 'Document (freetext)',
                comment: 'Search entity type filter: Document (freetext)',
            }),
        },
        {
            id: SearchDocumentTypeEnum.Uploaded,
            title: t({
                message: 'Document (uploaded)',
                comment: 'Search entity type filter: Document (uploaded)',
            }),
        },
    ];
    return (<ButtonGroup>
      <OperatorSelector openOnNotSet={false} label={searchDocumentTypeFilterLabel()} name={name} operators={[
            {
                id: 'in',
                label: t({ message: 'is', comment: 'Search entity type filter: is' }),
                menuItem: t({ message: 'Is', comment: 'Search entity type filter: Is' }),
                onSelect: () => { var _a; return filter.setValue({ in: (_a = filter.value) === null || _a === void 0 ? void 0 : _a.notIn }); },
            },
            {
                id: 'notIn',
                label: t({ message: 'is not', comment: 'Search entity type filter: is not' }),
                menuItem: t({ message: 'Is not', comment: 'Search entity type filter: Is not' }),
                onSelect: () => { var _a; return filter.setValue({ notIn: (_a = filter.value) === null || _a === void 0 ? void 0 : _a.in }); },
            },
        ]}/>

      {Boolean(filter.operator === 'in' || filter.operator === 'notIn') && (<MultiselectOperator items={items} name={name}/>)}

      <ClearFilter name={name}/>
    </ButtonGroup>);
}
