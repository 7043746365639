import { TableRow } from '@pocketlaw/tetris';
import { ApproversCell } from '../cells/ApproversCell';
import { NameCell } from '../cells/NameCell';
import { RequestedAtCell } from '../cells/RequestedAtCell';
import { RequestedByCell } from '../cells/RequestedByCell';
export function ApprovalPendingContractsTableRow(props) {
    const { contract } = props;
    const { id, name, requester, requestedAt, approvers } = contract;
    return (<TableRow>
      <NameCell id={id} name={name}/>
      <RequestedByCell requester={requester}/>
      <RequestedAtCell timestamp={requestedAt}/>
      <ApproversCell approvers={approvers}/>
    </TableRow>);
}
