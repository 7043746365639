var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { MUTATION } from './mutation';
import { useGetDocumentRenderInput } from './useGetDocumentRenderInput';
export function useRenderPlastToDocx() {
    var _a, _b;
    const account = useAccount();
    const companyId = (_b = (_a = account.data) === null || _a === void 0 ? void 0 : _a.company) === null || _b === void 0 ? void 0 : _b.id;
    const [mutate] = useMutation(MUTATION);
    const getDocumentRenderInput = useGetDocumentRenderInput();
    const renderPlastToDocx = (options) => __awaiter(this, void 0, void 0, function* () {
        const { name, id } = options;
        const { body, logoUrl } = yield getDocumentRenderInput(id);
        return mutate({
            variables: {
                companyId,
                content: body ? JSON.stringify(body) : body,
                name,
                logoUrl,
            },
        }).then(({ data }) => data === null || data === void 0 ? void 0 : data.files);
    });
    return renderPlastToDocx;
}
