import { gql, useQuery } from '@apollo/client';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { sortByUpdatedAt, transformData } from './utils';
const SharedDocumentsQuery = gql `
  query sharedDocuments($companyId: UUID) {
    externalSharingInvites(where: { companyId: { eq: $companyId } }) {
      id
      messageThreads {
        messages {
          id
        }
        unreadMessagesCount
      }

      inviteeEmail
      # invitee can be null while inviteeEmail is defined
      invitee {
        id
        name
      }

      fileSystemDocument {
        id
        updatedAt
        name
        type
      }
    }
  }
`;
export function useSharedDocuments() {
    var _a, _b;
    const account = useAccount();
    const companyId = (_a = account.data) === null || _a === void 0 ? void 0 : _a.companyId;
    const { data, loading, error } = useQuery(SharedDocumentsQuery, {
        variables: { companyId },
    });
    const sharedDocuments = ((_b = transformData(data)) === null || _b === void 0 ? void 0 : _b.sort(sortByUpdatedAt)) || [];
    return { sharedDocuments, loading, error };
}
