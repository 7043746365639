var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { MUTATION } from './mutation';
import { APPROVAL_IGNORE_CODES } from '../../../utils';
import { useLastApprovalRequest, lastApprovalRequestQuery } from '../useLastApprovalRequest';
export const useCancelApprovalRequest = (documentId) => {
    const { lastApprovalRequest } = useLastApprovalRequest(documentId);
    const { id: approvalRequestId } = lastApprovalRequest || {};
    const { addToast } = useToast();
    const [mutate, { loading }] = useMutation(MUTATION);
    const cancelApprovalRequest = (_a) => __awaiter(void 0, [_a], void 0, function* ({ message }) {
        yield mutate({
            variables: {
                approvalId: approvalRequestId || '',
                input: { cancellationMessage: message },
            },
            refetchQueries: [lastApprovalRequestQuery],
            context: {
                sentry: {
                    ignore: APPROVAL_IGNORE_CODES,
                },
            },
            onQueryUpdated(observableQuery) {
                return observableQuery.refetch();
            },
        });
        addToast({
            appearance: 'success',
            title: t({
                comment: 'Cancel approval request success message',
                message: 'Approval request has been cancelled',
            }),
        });
    });
    return { cancelApprovalRequest, loading };
};
