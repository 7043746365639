export const hasValidValue = (value) => value != null;
export function validateValue(value, type) {
    if (hasValidValue(value)) {
        return value;
    }
    if (type !== 'boolean') {
        return '';
    }
    const defaultBooleanValue = type === 'boolean' && typeof value !== 'boolean' ? true : Boolean(value);
    return defaultBooleanValue;
}
