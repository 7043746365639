import { Button, pxToRem, TableBodyCell, Typography } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const StyledTableCell = styled(TableBodyCell) `
  max-width: ${pxToRem(500)};
`;
export const StyledButton = styled(Button) `
  padding: 0;
  justify-content: flex-start;
`;
export const StyledTypography = styled(Typography) `
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
