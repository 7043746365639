import { gql } from '@apollo/client';
export const COMPOSERS_QUERY = gql `
  query composers($composerIds: [Unstable__UUID!]!) {
    composers: unstable__composers(where: { id: { in: $composerIds } }) {
      id

      metadata {
        id
        name
        marketLocaleCode
      }

      defaultSignatories {
        composerId
        signatories {
          id
          email
          firstName
          lastName
          title
          label
          mobile
          createdAt
          updatedAt
          authenticationMode
        }
        ccRecipients {
          email
          firstName
          lastName
          label
          createdAt
          updatedAt
        }
      }
    }
  }
`;
