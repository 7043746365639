import { TableBodyCell, AvatarList, AvatarLetter } from '@pocketlaw/tetris';
import { TasksIds } from '../../../types';
export function AssigneeCell(props) {
    const { assignees = [] } = props;
    const avatars = assignees.map(({ id, user }) => {
        const firstLetter = user.name[0].toUpperCase();
        return {
            id,
            component: <AvatarLetter>{firstLetter}</AvatarLetter>,
            appearance: 'assistant',
            tooltip: { title: user.name },
        };
    });
    return (<TableBodyCell headers={TasksIds.assignee}>
      <AvatarList size="sm" limit={5} avatars={avatars}/>
    </TableBodyCell>);
}
