var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@apollo/client';
import { useActiveDocument } from 'shared/domains/documents';
import { GetDocumentDataForContextMenuDocument } from './query.gql';
export function useGetDocument() {
    const document = useActiveDocument();
    const _a = useQuery(GetDocumentDataForContextMenuDocument, {
        variables: {
            id: document.id,
        },
    }), { data } = _a, rest = __rest(_a, ["data"]);
    const { fileSystemDocument } = data || {};
    const { id, folderId, name, type } = fileSystemDocument || {};
    const dataToReturn = {
        id,
        folderId,
        name,
        type,
    };
    return Object.assign({ data: dataToReturn }, rest);
}
