import { Trans, t } from '@lingui/macro';
import { TextArea, Typography } from '@pocketlaw/tetris';
import { Field, useFormikContext } from 'formik';
export function MessageField() {
    const { isSubmitting } = useFormikContext();
    return (<div>
      <Field disabled={isSubmitting} as={TextArea} name="message" label={t({
            message: 'Message',
            comment: 'Approval Drawer - Ask for approval dialog - textarea label',
        })} placeholder={t({
            message: 'Your message...',
            comment: 'Approval Drawer - Ask for approval dialog - textarea placeholder',
        })}/>
      <Typography $fontSize="tiny" $appearance="300">
        <Trans comment="Approval Drawer - Ask for approval dialog - textarea helper">
          Visible to anyone with access to the document
        </Trans>
      </Typography>
    </div>);
}
