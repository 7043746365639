import { breakpoints } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;

  ${breakpoints.xs} {
    overflow: unset;
  }
`;
export const Content = styled.div `
  display: flex;
  flex-flow: nowrap row;

  justify-content: center;
  flex: 1 1 auto;

  overflow: hidden;
`;
