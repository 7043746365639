var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@apollo/client';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { QUERY } from './query';
export const useCompanyUsers = () => {
    var _a, _b;
    const { data: accountData } = useAccount();
    const { companyId = '' } = accountData;
    const _c = useQuery(QUERY, {
        variables: { companyId },
        skip: !companyId,
    }), { data } = _c, rest = __rest(_c, ["data"]);
    const companyUsers = ((_b = (_a = data === null || data === void 0 ? void 0 : data.company) === null || _a === void 0 ? void 0 : _a.companyUsers) === null || _b === void 0 ? void 0 : _b.map(companyUser => {
        var _a, _b;
        return ({
            companyUserId: companyUser.id,
            userId: (_a = companyUser.user) === null || _a === void 0 ? void 0 : _a.id,
            name: (_b = companyUser.user) === null || _b === void 0 ? void 0 : _b.name,
        });
    })) || [];
    return Object.assign(Object.assign({}, rest), { companyUsers });
};
