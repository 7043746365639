import { breakpoints, NavigationBar, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const StyledNavigationBar = styled(NavigationBar) `
  display: block;
  padding: ${themeGet('spacing.lg')};

  ${breakpoints.md} {
    display: none;
  }
`;
