import { t } from '@lingui/macro';
import { useGetPrePopulatedTask } from './useGetPrePopulatedTask';
import { useTaskActions } from '../../hooks/useTaskActions';
import { TaskDialog } from '../TaskDialog';
import { useTasks } from '../TasksProvider';
export function AddTask() {
    const { setDisplayAdd, displayAdd } = useTasks();
    const task = useGetPrePopulatedTask();
    const closeDialog = () => {
        setDisplayAdd(false);
    };
    const { initialValues, handleCreate, error } = useTaskActions({
        task,
        onClose: closeDialog,
    });
    if (!displayAdd) {
        return null;
    }
    return (<TaskDialog title={t({ message: 'Add task', comment: 'Add task dialog title' })} onSubmit={handleCreate} onClose={closeDialog} error={error} initialValues={initialValues} buttonTitle={t({ message: 'Add', comment: 'Add task dialog create button' })}/>);
}
