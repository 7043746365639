import { useRef } from 'react';
import { DocumentType } from 'shared/domains/apollo/generated/types';
import { PreviewContext } from './context';
import { useDimensions } from './useDimensions';
import { getPreviewType } from '../../utils/getPreviewType';
import { isComposedDocument } from '../../utils/isComposedDocument';
import { isDocxDocument } from '../../utils/isDocxDocument';
import { isFreeTextDocument } from '../../utils/isFreeTextDocument';
import { isPdfDocument } from '../../utils/isPdfDocument';
import { isSentForSigning } from '../../utils/isSentForSigning';
import { isUnknownDocument } from '../../utils/isUnknownDocument';
import { isUploadedDocument } from '../../utils/isUploadedDocument';
function PreviewProvider(props) {
    const { document, company, companyFeatures = [], children } = props;
    const { type } = document;
    const previewType = getPreviewType(document);
    const sentForSigning = isSentForSigning(document);
    const isFreeText = !sentForSigning && isFreeTextDocument(document);
    const isComposed = !sentForSigning && isComposedDocument(document);
    const isUploaded = !sentForSigning && isUploadedDocument(document);
    const isDocx = !sentForSigning && isDocxDocument(document);
    const isPdf = sentForSigning || isPdfDocument(document);
    const isNotSupported = isUnknownDocument(document) || (isUploaded && !isPdf && !isDocx);
    const isRtcAvailable = type === DocumentType.Composed || type === DocumentType.Freetext;
    const isPleditorImportExportAvailable = companyFeatures.some(({ feature }) => feature === 'IMPORT_EXPORT') &&
        (type === DocumentType.Composed || type === DocumentType.Freetext);
    const disableZoom = isNotSupported;
    const zoomRef = useRef(null);
    const { dimensions, zoom, zoomTo } = useDimensions({ ref: zoomRef, disableZoom, previewType });
    const values = {
        dimensions,
        zoomTo,
        zoom,
        disableZoom,
        zoomRef,
        previewType,
        document,
        company,
        isFreeText,
        isComposed,
        isUploaded,
        isDocx,
        isPdf,
        isSentForSigning: sentForSigning,
        isNotSupported,
        isRtcAvailable,
        isPleditorImportExportAvailable,
    };
    return <PreviewContext.Provider value={values}>{children}</PreviewContext.Provider>;
}
export { PreviewProvider };
