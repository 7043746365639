import { t } from '@lingui/macro';
import { Chip } from '@pocketlaw/tetris';
import { useFormatDate } from '../hooks/useFormatDate';
import { List, ListItem, ListItemTitle, ListItemValue } from '../styled';
import { getStatusLabel } from '../utils/getStatusLabel';
export function DropdownContent(props) {
    const emailTitle = t({
        message: 'Email',
        comment: 'invites table mobile dropdown: email title',
    });
    const roleTitle = t({
        message: 'User Role',
        comment: 'invites table mobile dropdown: role title',
    });
    const dateTitle = t({
        message: 'Date Sent',
        comment: 'invites table mobile dropdown: date sent title',
    });
    const statusTitle = t({
        message: 'Status',
        comment: 'invites table mobile dropdown: status title',
    });
    const { email, date, expired, role } = props;
    const statusLabel = getStatusLabel(expired);
    const formattedDate = useFormatDate(date);
    return (<List>
      <ListItem>
        <ListItemTitle>{emailTitle}</ListItemTitle>
        <ListItemValue>{email}</ListItemValue>
      </ListItem>

      <ListItem>
        <ListItemTitle>{roleTitle}</ListItemTitle>
        <ListItemValue>{role}</ListItemValue>
      </ListItem>

      <ListItem>
        <ListItemTitle>{dateTitle}</ListItemTitle>
        <ListItemValue>{formattedDate}</ListItemValue>
      </ListItem>

      <ListItem>
        <ListItemTitle>{statusTitle}</ListItemTitle>
        <ListItemValue>
          <Chip size="small" variant="outline" appearance={expired ? 'danger' : 'secondary'} label={statusLabel}/>
        </ListItemValue>
      </ListItem>
    </List>);
}
