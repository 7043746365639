import { useQuery } from '@apollo/client';
import { ROOT } from 'app/domains/filesystem';
import { fileSystemManagerQuery } from './query';
export function useFileSystemManager(options) {
    const { parentId } = options;
    return useQuery(fileSystemManagerQuery, {
        variables: {
            withParentId: Boolean(parentId),
            parentId: parentId || ROOT,
        },
    });
}
