import { forwardRef } from 'react';
import { t } from '@lingui/macro';
import { Table, TableBody, TableRowCollapse, LoaderOverlay, EmptyState } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useUserInvites } from 'app/domains/users';
import { ActionsCell } from './ActionsCell';
import { DateCell } from './DateCell';
import { DropdownContent } from './DropdownContent';
import { EmailCell } from './EmailCell';
import { ErrorAlert } from './ErrorAlert';
import { RoleCell } from './RoleCell';
import { StatusCell } from './StatusCell';
import { TableHead } from './TableHead';
export const InvitesTable = forwardRef((_, ref) => {
    const { sort, setSort, invites, loading, error, noSearchResults } = useUserInvites();
    if (error) {
        return <ErrorAlert />;
    }
    if (loading) {
        return <LoaderOverlay />;
    }
    if (noSearchResults) {
        return (<EmptyState withAvatar title={t({
                message: 'No invitations found',
                comment: 'invitations table - empty search title',
            })} description={t({
                message: 'There are no invitations that match your current filter.',
                comment: 'invitations table - empty search subtitle',
            })} icon={<Falcon icon="search"/>}/>);
    }
    if (invites.length < 1) {
        return (<EmptyState withAvatar title={t({
                message: 'No pending users',
                comment: 'invitations table - no invitations title',
            })} description={t({
                message: 'Invite new users and they will show up here.',
                comment: 'invitations table - no invitations subtitle',
            })} icon={<Falcon icon="envelopes"/>}/>);
    }
    return (<Table sortId={sort.id} sortOrder={sort.order} onSort={(id, order) => setSort({ id, order })} ref={ref}>
      <TableHead />
      <TableBody>
        {invites.map(invite => (<TableRowCollapse key={invite.id} colSpan={3} dropdownContent={<DropdownContent email={invite.email} date={invite.createdAt} expired={invite.expired} role={invite.role}/>} expandibleHeaders="expand" hideDropdownOnBreakpoint="sm">
            <EmailCell email={invite.email}/>
            <DateCell date={invite.createdAt}/>
            <StatusCell expired={invite.expired}/>
            <RoleCell role={invite.role}/>
            <ActionsCell inviteId={invite.id}/>
          </TableRowCollapse>))}
      </TableBody>
    </Table>);
});
