var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import auth0 from 'auth0-js';
import config from './config';
import * as state from './state';
import { ErrorCodes } from './types';
const authClient = new auth0.WebAuth(config);
function parseHashPromise() {
    return new Promise((resolve, reject) => {
        authClient.parseHash((err, credentials) => {
            window.location.hash = '';
            if (err) {
                return reject(err);
            }
            return resolve(credentials);
        });
    });
}
function checkSessionPromise(options) {
    return new Promise((resolve, reject) => {
        authClient.checkSession(options || {}, (error, credentials) => {
            if (error && error.code === ErrorCodes.LoginRequired) {
                resolve(null);
            }
            if (error) {
                return reject(error);
            }
            return resolve(credentials);
        });
    });
}
function userInfoPromise(accessToken) {
    return new Promise((resolve, reject) => {
        if (!accessToken) {
            // eslint-disable-next-line no-promise-executor-return
            return resolve(null);
        }
        // eslint-disable-next-line no-promise-executor-return
        return authClient.client.userInfo(accessToken, (error, user) => {
            if (error) {
                return reject(error);
            }
            return resolve(user);
        });
    });
}
function parseHash() {
    return __awaiter(this, void 0, void 0, function* () {
        const credentials = yield parseHashPromise();
        const user = yield userInfoPromise(credentials === null || credentials === void 0 ? void 0 : credentials.accessToken);
        state.set({ credentials, user });
        return {
            credentials,
            user,
        };
    });
}
function checkSession() {
    return __awaiter(this, void 0, void 0, function* () {
        const credentials = yield checkSessionPromise();
        const user = yield userInfoPromise(credentials === null || credentials === void 0 ? void 0 : credentials.accessToken);
        state.set({ credentials, user });
        return {
            credentials,
            user,
        };
    });
}
function initSession() {
    return __awaiter(this, void 0, void 0, function* () {
        const parsedHashResult = yield parseHash();
        if (parsedHashResult && parsedHashResult.credentials) {
            return parsedHashResult;
        }
        return checkSession();
    });
}
function logout() {
    return authClient.logout({ returnTo: `${window.location.origin}/signout` });
}
function passwordlessLogin(options) {
    return new Promise((resolve, reject) => {
        authClient.passwordlessLogin(options, error => {
            if (error) {
                return reject(error);
            }
            return resolve(null);
        });
    });
}
function passwordlessStart(options) {
    return new Promise((resolve, reject) => {
        authClient.passwordlessStart(options, error => {
            if (error) {
                return reject(error);
            }
            return resolve(null);
        });
    });
}
const inMS = (sec) => sec * 1000;
/**
 * Check if the session has expired.
 * Will return true if there is less than 5 sec left on session.
 * @returns boolean if the session has expired
 */
function expiredSession() {
    const { credentials } = state.get();
    const { idTokenPayload, expiresIn } = credentials || {};
    const { iat } = idTokenPayload || {};
    if (!iat || !expiresIn) {
        return true;
    }
    const expiresAt = inMS(iat) + inMS(expiresIn);
    const now = new Date().getTime();
    // Check if its less than 5 sec left on the session.
    const hasExpired = expiresAt - now < 5 * 1000;
    return hasExpired;
}
export { state, passwordlessLogin, passwordlessStart, logout, parseHash, checkSession, initSession, expiredSession, };
