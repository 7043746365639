import { useCallback, useMemo } from 'react';
import { useWorker } from 'shared/domains/workers';
const worker = new Worker(new URL('./search.worker', import.meta.url));
export function useTextNodeDistanceSearch() {
    const [postMessage, state] = useWorker(worker);
    const search = useCallback((textNodes, text) => {
        postMessage({ textNodes, text });
    }, [postMessage]);
    const data = useMemo(() => {
        if (state.loading === true) {
            return [];
        }
        if (state.data) {
            return state.data;
        }
        return [];
    }, [state]);
    return [search, Object.assign(Object.assign({}, state), { data })];
}
