import { Trans } from '@lingui/macro';
import { DialogFooter } from '@pocketlaw/tetris';
import { Spacer } from '@pocketlaw/tetris/deprecated';
import { useFormikContext } from 'formik';
import { SubmitButton, CancelButton } from 'shared/domains/forms';
import { usePreview } from '../../hooks/usePreview';
export function Footer(props) {
    const { onClose } = props;
    const { isComposed } = usePreview();
    const { isValid } = useFormikContext();
    return (<DialogFooter>
      <Spacer>
        <CancelButton onClick={onClose}>
          <Trans>Cancel</Trans>
        </CancelButton>
        <SubmitButton disabled={!isValid}>
          {isComposed ? (<Trans comment="Submit text to share composed document">Convert & Send</Trans>) : (<Trans comment="Submit text to share document">Send</Trans>)}
        </SubmitButton>
      </Spacer>
    </DialogFooter>);
}
