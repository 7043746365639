import { t } from '@lingui/macro';
import { TableHead, TableHeaderText, VisuallyHidden } from '@pocketlaw/tetris';
import { StyledTableRow, StyledTableHeadCell } from './styled';
export function EntriesTableHead() {
    const nameCellText = t({
        comment: 'Choose file in file browser: (screen reader) Name of table column',
        message: 'Name',
    });
    const radioCellText = t({
        comment: 'Choose file in file browser: (screen reader) Radio of table column',
        message: 'Radio',
    });
    return (<TableHead>
      <StyledTableRow>
        <StyledTableHeadCell id="name">
          <VisuallyHidden>
            <TableHeaderText>{nameCellText}</TableHeaderText>
          </VisuallyHidden>
        </StyledTableHeadCell>
        <StyledTableHeadCell id="radio">
          <VisuallyHidden>
            <TableHeaderText>{radioCellText}</TableHeaderText>
          </VisuallyHidden>
        </StyledTableHeadCell>
      </StyledTableRow>
    </TableHead>);
}
