import { useEffect } from 'react';
export const useLeaveDocumentPrompt = (options) => {
    const { busy, message } = options;
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            // eslint-disable-next-line no-param-reassign
            event.returnValue = message;
            return message;
        };
        if (busy) {
            window.addEventListener('beforeunload', handleBeforeUnload);
            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
            };
        }
        return undefined;
    }, [busy, message]);
};
