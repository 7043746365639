import { themeGet } from '@pocketlaw/tetris';
export const useGetDefaultColorScale = (entries) => {
    const fallback = themeGet('colors.secondary.600');
    const scale = [
        themeGet('colors.secondary.100'),
        themeGet('colors.secondary.300'),
        themeGet('colors.secondary.500'),
        themeGet('colors.secondary.600'),
        themeGet('colors.secondary.800'),
        themeGet('colors.secondary.900'),
    ];
    const getFill = (index) => scale[index] || fallback;
    return entries.map((entry, index) => (Object.assign(Object.assign({}, entry), { fill: entry.fill ? entry.fill : getFill(index) })));
};
