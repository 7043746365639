import { Fragment } from 'react';
import { t } from '@lingui/macro';
import { Chip } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { DocumentStatusChip } from 'app/domains/documents';
import { useDocumentMetadata } from './DocumentMetadataProvider';
import { DocumentNameChip } from './DocumentNameChip';
import { TemplateCategory } from './TemplateCategory';
export function SystemMetadataList() {
    const { data, documentId } = useDocumentMetadata();
    const { systemData } = data;
    const { createdAt, createdBy, templateName, updatedAt, dateSigned, status, type } = systemData;
    return (<Fragment>
      <DocumentNameChip />

      <DocumentStatusChip documentId={documentId} status={status} type={type} size="large" appearance="secondary" variant="solid" label={t({
            message: 'Document Status',
            comment: 'Metadata sidebar: Label for document status',
        })} icon={<Falcon icon="plus"/>}/>

      {templateName && (<Chip size="large" appearance="secondary" icon={<Falcon icon="lock"/>} label={t({ message: 'Template', comment: 'Label for document template' })} secondary={templateName}/>)}

      <Chip size="large" appearance="secondary" icon={<Falcon icon="lock"/>} label={t({ message: 'Created at', comment: 'Label for document creation date' })} secondary={createdAt}/>

      <Chip size="large" appearance="secondary" icon={<Falcon icon="lock"/>} label={t({ message: 'Updated at', comment: 'Label for document last update date' })} secondary={updatedAt}/>

      <Chip size="large" appearance="secondary" icon={<Falcon icon="lock"/>} label={t({ message: 'Date signed', comment: 'Label for document esigning signed date' })} secondary={dateSigned}/>

      <Chip size="large" appearance="secondary" icon={<Falcon icon="lock"/>} label={t({ message: 'Created by', comment: 'Label for document creator' })} secondary={createdBy}/>

      <TemplateCategory />
    </Fragment>);
}
