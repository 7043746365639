import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { EntryKind } from 'shared/domains/apollo/generated/types';
import { renameFolderMutation, renameDocumentMutation } from './mutation';
function getMutation(type) {
    switch (type) {
        case EntryKind.Folder:
            return renameFolderMutation;
        case EntryKind.Document:
            return renameDocumentMutation;
        default:
            throw new Error(`Unknown Entry#type "${type}", you must specify what type of entry you want to rename`);
    }
}
export const useRenameEntry = (options) => {
    const { id, type, onRenamed } = options;
    const [mutate, result] = useMutation(getMutation(type), {
        variables: { id },
        onCompleted({ entries }) {
            if (onRenamed) {
                onRenamed([entries]);
            }
        },
    });
    const handler = useCallback((name) => mutate({ variables: { name } }), [mutate]);
    return [handler, result];
};
