import { Trans } from '@lingui/macro';
import { Box, Typography } from '@pocketlaw/tetris';
import { usePromptLibrary } from '../../../PromptLibraryProvider';
import { FilterChip } from '../FilterChip';
export function Labels() {
    const { topLabels, selectedLabels, addLabel, removeLabel } = usePromptLibrary();
    return (<Box flexDirection="column" gap="md">
      <Typography $fontSize="small" $appearance="100" $fontWeight="bold">
        <Trans comment="Select prompt dialog - Labels label">Top Labels</Trans>
      </Typography>
      <Box gap="lg" flexDirection="column">
        {topLabels.map(([label, count]) => (<FilterChip key={label} selected={selectedLabels.includes(label)} onAdd={() => addLabel(label)} onRemove={() => removeLabel(label)} label={label} count={count}/>))}
      </Box>
    </Box>);
}
