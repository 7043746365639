import { useMutation } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { CreateTeam } from './mutation';
import { CreateTeamErrorCodes } from './types';
export function useCreateTeam() {
    const { addToast } = useToast();
    const [mutate, { loading, error }] = useMutation(CreateTeam, {
        onCompleted: () => {
            addToast({
                appearance: 'success',
                title: t({
                    message: 'Team successfully created!',
                    comment: 'Success toast title when team is created',
                }),
            });
        },
        update(cache, { data }) {
            const team = data === null || data === void 0 ? void 0 : data.createTeam;
            if (team) {
                cache.modify({
                    fields: {
                        teams(existing = []) {
                            return [...existing, team];
                        },
                    },
                });
            }
        },
        onQueryUpdated(observableQuery) {
            return observableQuery.refetch();
        },
    });
    function createTeam(input) {
        return mutate({
            variables: { input },
            context: { sentry: { ignore: [CreateTeamErrorCodes.DUPLICATE_ENTITY] } },
        });
    }
    return { loading, error, createTeam };
}
