import { Fragment, useRef } from 'react';
import { t } from '@lingui/macro';
import { ChipAction, MenuBody } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useField } from 'formik';
import { ChipDropdown } from 'shared/domains/common-ui';
import { Ranges } from './Ranges';
import { allowedPropertiesTranslations } from './translations';
import { Breakdown } from '../Breakdown';
import { SelectDataPoint } from '../SelectDataPoint';
import { FieldInputContainer } from '../styled';
export function DateRangeInput(props) {
    var _a, _b;
    const { pathBase, remove, isSubAggregation = false } = props;
    const currentPath = `${pathBase}.dateRangeInput`;
    const [field] = useField(currentPath);
    const configurationDropdownRef = useRef();
    const rangeCount = (_b = (_a = field.value) === null || _a === void 0 ? void 0 : _a.ranges) === null || _b === void 0 ? void 0 : _b.length;
    return (<Fragment>
      <FieldInputContainer>
        <SelectDataPoint label={t({ message: 'Date range', comment: 'Insights widget form: Date range field' })} selectedValue={allowedPropertiesTranslations(field.value.field)} name={pathBase} action={<ChipAction onClick={remove}>
              <Falcon icon="close"/>
            </ChipAction>}/>

        <ChipDropdown icon={rangeCount ? undefined : <Falcon icon="plus"/>} label={t({ message: 'Config', comment: 'Insights: Date range configuration' })} secondary={rangeCount || undefined} appearance="secondary" variant="outline" ref={configurationDropdownRef} dropdownContent={<MenuBody>
              <Ranges name={currentPath}/>
            </MenuBody>}/>
      </FieldInputContainer>

      {!isSubAggregation && <Breakdown pathBase={currentPath}/>}
    </Fragment>);
}
