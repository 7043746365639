import { Context } from './Context';
import { useGetApprovers } from './useGetApprovers';
export function Provider(props) {
    const { children } = props;
    const { approvers, loading: fetchingApprovers, type, refetch: refetchApprovers, } = useGetApprovers();
    const value = {
        approvers,
        type,
        loading: fetchingApprovers,
        refetch: refetchApprovers,
    };
    return <Context.Provider value={value}>{children}</Context.Provider>;
}
