var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createContext, useContext } from 'react';
const Context = createContext({ section: false });
export const useSection = () => {
    const { section } = useContext(Context);
    return section;
};
function Section(props) {
    const { children } = props, restProps = __rest(props, ["children"]);
    return (<section {...restProps}>
      <Context.Provider value={{ section: true }}>{children}</Context.Provider>
    </section>);
}
export default Section;
