import { pxToRem, themeGet, Typography } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const List = styled.ol ``;
export const Item = styled.li `
  display: grid;
  column-gap: ${themeGet('spacing.xl')};
  grid-template-columns: auto minmax(0, 1fr) minmax(0, auto) auto;
  grid-template-rows: auto;

  align-items: center;
  padding: ${themeGet('spacing.md')} 0;
`;
export const ItemContent = styled.div `
  grid-column: 2 / 3;
  grid-row: 1 / 3;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;
export const ContentRow = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${themeGet('spacing.lg')};
  flex-wrap: wrap;
`;
export const Title = styled(Typography).attrs(() => ({
    $fontWeight: 'medium',
    $appearance: '300',
    $fontSize: 'medium',
    $lineHeight: 'neutralized',
})) `
  flex: 1;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: ${props => (props.$completed ? 'line-through' : 'none')};
`;
export const DueDateText = styled(Typography) `
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const StyledButtonContent = styled.span `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: ${pxToRem(8)};
`;
export const StyledMenuContainer = styled.div `
  flex: 1;
  margin-top: ${themeGet('spacing.xl')};
`;
