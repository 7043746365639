import { Typography, unit } from '@pocketlaw/tetris';
import { useFormatDate } from '../hooks/useFormatDate';
import { DesktopTableBodyCell, MinWidth } from '../styled';
export function DateCell(props) {
    const { date } = props;
    const formattedDate = useFormatDate(date);
    return (<DesktopTableBodyCell headers="created_at">
      <MinWidth $minWidth={unit(35)}>
        <Typography $appearance="100" $fontSize="medium" $fontWeight="regular" $lineHeight="default">
          {formattedDate}
        </Typography>
      </MinWidth>
    </DesktopTableBodyCell>);
}
