import { t } from '@lingui/macro';
import { useEsigningProviderMetadata } from 'app/domains/esigning/hooks/useEsigningProviderMetadata';
import { GroupMessage } from './GroupMessage';
import { SigningOrder } from './SigningOrder';
import { SectionList } from '../../components';
export function Settings() {
    const { properties: providerProperties } = useEsigningProviderMetadata();
    const { canUseSigningOrder, canUseEsigningMessages } = providerProperties;
    return (<SectionList title={t({ comment: 'Label for e-signing settings', message: 'Advanced settings' })}>
      {canUseSigningOrder && <SigningOrder />}
      {canUseEsigningMessages && <GroupMessage />}
    </SectionList>);
}
