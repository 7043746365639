import { useCallback, useEffect, useRef, useState } from 'react';
import { t } from '@lingui/macro';
import { Avatar, IconButton, Tooltip } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useMount } from 'react-use';
import { useFilesPreview } from '../../../../hook';
import { EditContainer, StyledTextInput } from './styled';
export function EditFile(props) {
    const { id, name } = props;
    const { updateFileName, setFileIdBeingEdited } = useFilesPreview();
    const [fileName, setFileName] = useState(name);
    const textInputRef = useRef(null);
    const isInvalid = fileName.length === 0;
    useMount(() => {
        var _a;
        (_a = textInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    });
    const handleChange = (event) => setFileName(event.target.value);
    const handleCancelEditName = () => setFileIdBeingEdited('');
    const handleSaveName = useCallback(() => {
        if (!isInvalid) {
            updateFileName(id, fileName);
            setFileIdBeingEdited('');
        }
    }, [fileName, id, isInvalid, setFileIdBeingEdited, updateFileName]);
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                handleSaveName();
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleSaveName]);
    const iconButtonProps = {
        $round: true,
        size: 'small',
        appearance: 'ghost',
    };
    return (<EditContainer>
      <Avatar size="md" appearance="secondary">
        <Falcon icon="file"/>
      </Avatar>
      <StyledTextInput value={fileName} ref={textInputRef} invalid={isInvalid} onChange={handleChange}/>
      <Tooltip title={t({ comment: 'Save edited file name', message: 'Save name' })}>
        <IconButton {...iconButtonProps} disabled={isInvalid} onClick={handleSaveName}>
          <Falcon icon="check"/>
        </IconButton>
      </Tooltip>
      <Tooltip title={t({ comment: 'Cancel edit file name', message: 'Cancel' })}>
        <IconButton {...iconButtonProps} onClick={handleCancelEditName}>
          <Falcon icon="xmark"/>
        </IconButton>
      </Tooltip>
    </EditContainer>);
}
