import { Trans } from '@lingui/macro';
import { Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { PreviewText } from '../PreviewText';
export function Public(props) {
    const { isPublic } = props;
    if (!isPublic) {
        return null;
    }
    return (<PreviewText icon={<Falcon icon="eye"/>}>
      <Trans comment="tasks preview modal public field - When task is public for everyone in the company">
        Visible to <Typography $appearance="100">everyone in your company</Typography>
      </Trans>
    </PreviewText>);
}
