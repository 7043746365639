var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { forwardRef } from 'react';
import { t, Trans } from '@lingui/macro';
import { Column } from '@pocketlaw/tetris';
import Action from '../Action';
const CreateEmptyDraftButton = forwardRef((props, forwardedRef) => {
    const { onCreateDraft } = props, restProps = __rest(props, ["onCreateDraft"]);
    return (<Column width="100%">
        <Action ref={forwardedRef} tooltip={t `Choose this option to discard the entered data and start with an empty form.`} buttonText={t `Empty form`} onClick={onCreateDraft} {...restProps}>
          <Trans>Start with an empty form</Trans>
        </Action>
      </Column>);
});
export default CreateEmptyDraftButton;
