var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@apollo/client';
import { ROOT } from 'app/domains/filesystem';
import { fileSystemEntriesQuery, countFileSystemEntries, } from './query';
export function useFileSystemEntries(variables) {
    var _a, _b, _c;
    const { folderId, sortId, sortOrder, limit, offset } = variables || {};
    const countResponse = useQuery(countFileSystemEntries, {
        fetchPolicy: 'cache-and-network',
        variables: {
            parentId: folderId || ROOT,
        },
    });
    const response = useQuery(fileSystemEntriesQuery, {
        fetchPolicy: 'cache-and-network',
        variables: {
            parentId: folderId || ROOT,
            offset,
            limit,
            orderBy: sortId &&
                sortOrder && {
                [sortId]: sortOrder,
            },
        },
    });
    const count = ((_a = countResponse.data) === null || _a === void 0 ? void 0 : _a.count) || 0;
    const tmpEntries = ((_b = response.data) === null || _b === void 0 ? void 0 : _b.entries) || ((_c = response.previousData) === null || _c === void 0 ? void 0 : _c.entries) || [];
    const filteredEntries = tmpEntries.map(entry => {
        // eslint-disable-next-line no-underscore-dangle
        if (entry.__typename === 'FileSystemDocument') {
            const { type, marketCode } = entry, document = __rest(entry, ["type", "marketCode"]);
            if (type === 'COMPOSED') {
                return Object.assign({ marketCode, documentType: type }, document);
            }
            return Object.assign({ documentType: type }, document);
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return entry;
    });
    const loading = response.loading || countResponse.loading;
    const error = response.error || countResponse.error;
    return {
        updateEntries: response.updateQuery,
        error,
        loading,
        refetch: response.refetch,
        data: { entries: filteredEntries, count },
    };
}
