import { t } from '@lingui/macro';
import { SwitchButtonView, Plugin } from 'pleditor';
import { HighlightIcon } from './icons';
import { getIsOnValue, setIsOnValue } from './utils';
export class ComposedHighlightsSupport extends Plugin {
    static get pluginName() {
        return 'ComposedHighlightsSupport';
    }
    constructor(editor) {
        super(editor);
        this.button = new SwitchButtonView();
    }
    init() {
        this.button.on('change:isOn', (_evt, _propertyName, newValue) => {
            this.setValue(newValue);
        });
        this.configModelSchema();
        this.toolbarItem();
    }
    setValue(isOn) {
        var _a;
        setIsOnValue(isOn);
        (_a = this.button) === null || _a === void 0 ? void 0 : _a.set({ isOn });
        this.editor.editing.view.change(writer => {
            this.editor.editing.view.document.roots.forEach(root => {
                if (isOn) {
                    writer.addClass('pleditor-composer-highlight', root);
                }
                else {
                    writer.removeClass('pleditor-composer-highlight', root);
                }
            });
        });
    }
    toolbarItem() {
        const { editor, button } = this;
        const defaultIsOn = this.editor.config.get('composerHighlightsInitialIsOn');
        const isOn = defaultIsOn !== undefined ? defaultIsOn : getIsOnValue();
        editor.ui.componentFactory.add('toggleComposedHighlights', () => {
            button.set({
                label: t({
                    message: 'Toggle highlights',
                    comment: 'Pleditor - Toggle composer highlights',
                }),
                tooltip: true,
                icon: HighlightIcon,
                isOn,
                class: 'pl-icon-toggle-button',
            });
            button.on('execute', () => {
                button.isOn = !button.isOn;
            });
            return button;
        });
    }
    configModelSchema() {
        const { editor } = this;
        const highlightBlock = 'pl-highlight-block';
        const highlightInline = 'pl-highlight';
        const highlightDataAttribute = 'data-highlight';
        // block
        editor.model.schema.register(highlightBlock, {
            allowIn: ['$root', '$block'],
            allowChildren: ['$text', '$block', '$inlineObject'],
            isBlock: true,
        });
        editor.model.schema.extend(highlightBlock, {
            allowAttributes: highlightDataAttribute,
        });
        editor.conversion.for('editingDowncast').elementToElement({
            model: highlightBlock,
            view: 'div',
        });
        editor.conversion.elementToElement({
            view: highlightBlock,
            model: highlightBlock,
        });
        // inline
        editor.model.schema.register(highlightInline, {
            allowIn: ['$block'],
            allowChildren: ['$text'],
            isInline: true,
        });
        editor.model.schema.extend(highlightInline, {
            allowAttributes: highlightDataAttribute,
        });
        editor.conversion.for('editingDowncast').elementToElement({
            model: highlightInline,
            view: 'span',
        });
        editor.conversion.elementToElement({
            view: highlightInline,
            model: highlightInline,
        });
        // attributes
        editor.conversion.attributeToAttribute({
            view: highlightDataAttribute,
            model: highlightDataAttribute,
        });
    }
}
