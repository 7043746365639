import { useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { ToggleButton, Menu, MenuHeader, MenuBody, Checkbox, MenuList, MenuItem, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useGetComposers } from '../../hooks/useGetCategories';
export function CategoryFilterButton(props) {
    const { setCategoriesFilter, categoriesFilter } = props;
    const [open, setOpen] = useState(false);
    const { data } = useGetComposers();
    const categories = (data === null || data === void 0 ? void 0 : data.categories) || [];
    return (<Menu open={open} onClose={() => setOpen(false)} options={{ placement: 'bottom-end' }} target={<ToggleButton type="button" $toggled={open} onClick={() => setOpen(!open)} $stretch>
          {open ? <Falcon icon="chevron-up"/> : <Falcon icon="chevron-down"/>}
          <Trans comment="user invite modal - category filter dropdown title">Category</Trans>
        </ToggleButton>}>
      <MenuHeader title={t({ message: 'Category', comment: 'user invite modal - category dropdown title' })}/>
      <MenuBody>
        <MenuList>
          {categories.map(category => (<MenuItem key={category.id} as="label">
              <Checkbox checked={categoriesFilter.includes(category.id)} onChange={e => {
                if (e.target.checked) {
                    setCategoriesFilter([...categoriesFilter, category.id]);
                }
                else {
                    const newCategories = categoriesFilter.filter(id => id !== category.id);
                    setCategoriesFilter(newCategories);
                }
            }}/>
              {category.metadata[0].title}
            </MenuItem>))}
        </MenuList>
      </MenuBody>
    </Menu>);
}
