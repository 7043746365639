import { createContext, useState } from 'react';
import { useAutotagFeatureAvailability } from 'app/domains/metadata';
import { useCurrentFolder } from '../useCurrentFolder';
export const UploadContext = createContext(null);
export { useUpload } from './hook';
export function UploadProvider(props) {
    const { children, onClose, onCompleted } = props;
    const featureAvailability = useAutotagFeatureAvailability();
    const { currentFolder } = useCurrentFolder();
    const isDevelopmentEnvironment = process.env.APP_ENV !== 'production';
    const autoTagDefaultEnabled = isDevelopmentEnvironment ? false : featureAvailability.data;
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState(currentFolder);
    const [renderConfirmCancelDialog, setRenderConfirmCancelDialog] = useState(false);
    const [uploadError, setUploadError] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [autoTag, setAutoTag] = useState(autoTagDefaultEnabled);
    const isUploadDisabled = isUploading ||
        !selectedFolder ||
        selectedFiles.length === 0 ||
        uploadError === 'files:amount' ||
        uploadError === 'files:size';
    const updateSelectedFolder = (folder) => setSelectedFolder(folder);
    const startUpload = () => setIsUploading(true);
    const closeDialog = (forceClose = false) => {
        if (isUploading && !forceClose) {
            setRenderConfirmCancelDialog(true);
        }
        else {
            onClose();
        }
    };
    const onUploadSuccess = (entryIds) => {
        setRenderConfirmCancelDialog(true);
        onClose();
        onCompleted(entryIds, selectedFolder === null || selectedFolder === void 0 ? void 0 : selectedFolder.id);
    };
    const onUploadError = () => {
        setIsUploading(false);
        setUploadError('upload:error');
    };
    const value = {
        isUploading,
        uploadError,
        selectedFiles,
        selectedFolder,
        startUpload,
        setUploadError,
        isUploadDisabled,
        renderConfirmCancelDialog,
        setRenderConfirmCancelDialog,
        closeDialog,
        onUploadError,
        onUploadSuccess,
        setSelectedFiles,
        updateSelectedFolder,
        autoTag,
        setAutoTag,
    };
    return <UploadContext.Provider value={value}>{children}</UploadContext.Provider>;
}
