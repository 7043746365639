import { Trans } from '@lingui/macro';
import { Loader, Typography } from '@pocketlaw/tetris';
import { LoaderContainer } from './styled';
export function PleditorLoader() {
    return (<LoaderContainer>
      <Loader size={12}/>
      <Typography $fontSize="medium" $appearance="100">
        <Trans comment="Pleditor document loading label">Loading document</Trans>
      </Typography>
    </LoaderContainer>);
}
