import { useQuery } from '@apollo/client';
import { isSharedDocument } from './query';
export function useIsShared(id) {
    const { data } = useQuery(isSharedDocument, {
        variables: {
            id,
        },
    });
    const { invites } = data || {};
    const [invite] = invites || [];
    const isShared = Boolean(invite === null || invite === void 0 ? void 0 : invite.id);
    return isShared;
}
