import { t } from '@lingui/macro';
import { EmptyState } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
export function NoMembers() {
    return (<EmptyState withAvatar title={t({
            message: 'Add team members',
            comment: 'team members table - no team members title',
        })} description={t({
            message: 'Start by adding some new team members in here.',
            comment: 'team members table - no users subtitle',
        })} icon={<Falcon icon="person-circle-plus"/>}/>);
}
