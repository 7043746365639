import { Trans } from '@lingui/macro';
import { Icon, RadioCard } from '@pocketlaw/tetris';
import { getEsigningProviderMetadataByProviderId } from 'app/domains/esigning/utils';
import * as Sentry from 'shared/domains/sentry';
import { getProviderData } from '../../../utils/getProviderData';
import { Container, LogoContainer, StyledChip } from './styled';
export function EsigningProviderButton(props) {
    const { id, selected, disabled, connected, onSelect } = props;
    const providerData = getProviderData(id);
    const { properties } = getEsigningProviderMetadataByProviderId(id);
    const { isRecommended } = properties;
    if (!providerData) {
        Sentry.captureMessage(`Unexpected provider id "${id}" in EsigningProviderButton`);
        return null;
    }
    const { name, description, logo, ariaLabelConnected, ariaLabelUnconnected } = providerData;
    const handleChange = () => {
        onSelect(id);
    };
    return (<Container $disabled={disabled}>
      <RadioCard title={name} aria-label={connected ? ariaLabelConnected : ariaLabelUnconnected} subtitle={description} disabled={disabled} checked={selected} onChange={handleChange}/>
      <LogoContainer>
        <Icon size={16} glyph={logo}/>
      </LogoContainer>
      {isRecommended && (<StyledChip appearance="primary" label={<Trans comment="Badge for the recommended e-signing provider">Recommended</Trans>}/>)}
    </Container>);
}
