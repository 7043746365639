var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const isFileEntry = (entry) => entry !== undefined && entry.isFile;
const isDirectoryEntry = (entry) => entry !== undefined && entry.isDirectory;
const getFile = (fileEntry) => __awaiter(void 0, void 0, void 0, function* () {
    return new Promise((resolve, reject) => {
        fileEntry.file(file => resolve({ originalFile: file, fullPath: fileEntry.fullPath.substring(1) }), reject);
    });
});
const getDirectoryEntries = (directoryReader) => __awaiter(void 0, void 0, void 0, function* () {
    return new Promise((resolve, reject) => {
        directoryReader.readEntries(resolve, reject);
    });
});
const getAllDirectoryEntries = (directoryEntry) => __awaiter(void 0, void 0, void 0, function* () {
    const allEntries = [];
    const reader = directoryEntry.createReader();
    let entries = yield getDirectoryEntries(reader);
    while (entries.length > 0) {
        allEntries.push(...entries);
        // eslint-disable-next-line no-await-in-loop
        entries = yield getDirectoryEntries(reader);
    }
    return allEntries;
});
export const getFiles = (itemList) => __awaiter(void 0, void 0, void 0, function* () {
    const fileEntries = [];
    const queue = [];
    for (let i = 0; i < itemList.length; i += 1) {
        const fileSystemEntry = itemList[i].webkitGetAsEntry();
        if (fileSystemEntry) {
            queue.push(fileSystemEntry);
        }
    }
    while (queue.length > 0) {
        const entry = queue.shift();
        if (isFileEntry(entry)) {
            fileEntries.push(entry);
        }
        if (isDirectoryEntry(entry)) {
            // eslint-disable-next-line no-await-in-loop
            const directoryEntries = yield getAllDirectoryEntries(entry);
            queue.push(...directoryEntries);
        }
    }
    const files = yield Promise.all(fileEntries.map(getFile));
    return files;
});
export const isDraggingFiles = (event) => { var _a; return (_a = event.dataTransfer) === null || _a === void 0 ? void 0 : _a.types.includes('Files'); };
