import { t, plural } from '@lingui/macro';
export function formatLabel(label, users) {
    const [user] = users;
    if (!user) {
        return label;
    }
    const userName = user.name || t({ message: 'Unknown', comment: 'Unknown user name' });
    const moreUsers = users.length - 1;
    return label.concat(' ', t({
        message: plural(moreUsers, {
            0: userName,
            other: `${userName} +#`,
        }),
        comment: 'tasks user(s) filter label with user name(s) and count if more users',
    }));
}
