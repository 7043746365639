import { useMutation } from '@apollo/client';
import { deleteWorkspaceDocumentMutation } from './query';
export function useDeleteEntry(id, options) {
    const { onDeleted, onUpdate } = options || {};
    return useMutation(deleteWorkspaceDocumentMutation, {
        variables: { id },
        onCompleted: () => {
            if (onDeleted) {
                onDeleted([{ id }]);
            }
        },
        update(cache) {
            cache.modify({
                id: cache.identify({ id, __typename: 'FileSystemDocument' }),
                fields(_, { DELETE }) {
                    return DELETE;
                },
            });
            if (onUpdate)
                onUpdate(cache);
        },
    });
}
