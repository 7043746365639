import { gql } from '@apollo/client';
export const MUTATION = gql `
  mutation ApproveApprovalRequest($input: ApproveApprovalRequestInput!) {
    approveApprovalRequest(input: $input) {
      id
      status
      approvers {
        id
        status
      }
    }
  }
`;
