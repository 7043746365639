import { gql } from '@apollo/client';
export const documentApproversQuery = gql `
  query GetFileSystemDocumentApprovers($documentId: ID!) {
    fileSystemDocument(id: $documentId) {
      approval {
        id
        approvers {
          id
          companyUser {
            id
            user {
              id
              name
              email
            }
          }
        }
      }
    }
  }
`;
