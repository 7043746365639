import { Avatar, AvatarLetter, Badge, Typography } from '@pocketlaw/tetris';
import { Container, Left, Right, ThirdLine, BadgeContainer, AvatarContainer, FirstLine, } from './styled';
export function CustomListItem(props) {
    const { badge, primary, secondary, tertiary, avatarLetter } = props;
    return (<Container>
      <Left>
        <AvatarContainer>
          <Avatar size="lg">
            <AvatarLetter>{avatarLetter}</AvatarLetter>
          </Avatar>
          {badge && (<BadgeContainer>
              <Badge appearance={badge.appearance} value={badge.icon}/>
            </BadgeContainer>)}
        </AvatarContainer>
      </Left>
      <Right>
        <FirstLine $appearance="300" $fontSize="medium" $fontWeight="medium" $lineHeight="neutralized">
          {primary}
        </FirstLine>
        {secondary && (<Typography $appearance="200" $fontSize="small" $fontWeight="regular">
            {secondary}
          </Typography>)}
        {tertiary && (<ThirdLine $appearance="100" $fontSize="tiny" $fontWeight="regular">
            {tertiary}
          </ThirdLine>)}
      </Right>
    </Container>);
}
