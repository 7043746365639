import { t } from '@lingui/macro';
import { TableHead, TableHeadCell, TableHeaderText, TableRow } from '@pocketlaw/tetris';
export function ApprovalPendingContractsTableHead() {
    const nameCellText = t({
        comment: 'Pending approval documents table: name column',
        message: 'Name',
    });
    const requestedByCellText = t({
        comment: 'Pending approval documents table: requested by column',
        message: 'Requested by',
    });
    const requestedAtCellText = t({
        comment: 'Pending approval documents table: requested at column',
        message: 'Requested at',
    });
    const approversCellText = t({
        comment: 'Pending approval documents table: approvers column',
        message: 'Approvers',
    });
    return (<TableHead>
      <TableRow>
        <TableHeadCell id="nameCell">
          <TableHeaderText>{nameCellText}</TableHeaderText>
        </TableHeadCell>
        <TableHeadCell id="requestedByCell">
          <TableHeaderText>{requestedByCellText}</TableHeaderText>
        </TableHeadCell>
        <TableHeadCell id="requestedAtCell">
          <TableHeaderText>{requestedAtCellText}</TableHeaderText>
        </TableHeadCell>
        <TableHeadCell id="approversCell">
          <TableHeaderText>{approversCellText}</TableHeaderText>
        </TableHeadCell>
      </TableRow>
    </TableHead>);
}
