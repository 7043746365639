import { Fragment } from 'react';
import { HtmlStringToReact } from 'app/domains/global-search/components/HtmlStringToReact';
import { getSearchHighlightContext } from 'app/domains/global-search/utils/getSearchHighlightContext';
import { StyledText } from './styled';
export function Match(props) {
    const { name, highlight } = props;
    const { highlightText, showNameOnly } = getSearchHighlightContext(name, highlight);
    return showNameOnly ? (<StyledText size="small">
      <HtmlStringToReact allowedTags={['mark']} text={highlightText}/>
    </StyledText>) : (<Fragment>
      <StyledText size="small">{name}</StyledText>
      <StyledText appearance="neutral" size="tiny">
        … <HtmlStringToReact allowedTags={['mark']} text={highlightText}/> …
      </StyledText>
    </Fragment>);
}
