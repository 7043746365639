import { t } from '@lingui/macro';
import { Chip, Tooltip } from '@pocketlaw/tetris';
import { getUnsupportedTooltip } from './utils';
export function UnsupportedChip(props) {
    const { unsupportedReason } = props;
    const tooltip = getUnsupportedTooltip(unsupportedReason);
    if (!tooltip) {
        return null;
    }
    return (<Tooltip position="top" title={tooltip}>
      <Chip appearance="danger" label={t({
            comment: 'Unsupported file label in folder upload tree',
            message: 'Not supported',
        })}/>
    </Tooltip>);
}
