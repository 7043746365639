import { endOfDay, startOfDay } from 'date-fns';
export function buildDateRangeFilter(options) {
    const { key, dateRange } = options;
    const { startDate, endDate } = dateRange || {};
    if (!startDate && !endDate) {
        return undefined;
    }
    const filter = {};
    if (startDate) {
        Object.assign(filter, {
            _gte: startOfDay(startDate).toISOString(),
        });
    }
    if (endDate) {
        Object.assign(filter, {
            _lte: endOfDay(endDate).toISOString(),
        });
    }
    return { [key]: filter };
}
