import { Fragment, useState } from 'react';
import { t } from '@lingui/macro';
import { IconButton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useFormikContext } from 'formik';
import { ConfirmAction } from './ConfirmAction';
export function CloseTaskDialogButton(props) {
    const { onClick } = props;
    const { isSubmitting, dirty } = useFormikContext();
    const [displayConfirm, setDisplayConfirm] = useState(false);
    const handleConfirm = () => (dirty ? setDisplayConfirm(true) : onClick());
    return (<Fragment>
      <IconButton appearance="ghost" $round disabled={isSubmitting} onClick={handleConfirm} aria-label={t({ message: 'Close', comment: 'close task dialog button' })} data-testid="close-task-dialog-button">
        <Falcon icon="xmark"/>
      </IconButton>
      <ConfirmAction display={displayConfirm} onConfirm={onClick} onCancel={() => setDisplayConfirm(false)}/>
    </Fragment>);
}
