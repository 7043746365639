import { Trans, t } from '@lingui/macro';
import { Button, ButtonGroup, IconButton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { MenuMultiSelectItem, SearchableMenu } from 'shared/domains/common-ui';
import { useSearchContext } from '../SearchProvider';
export function AddFilterButton(props) {
    const { filterItems } = props;
    const { hasFilters, clearFilters, toggleFilter, activeFilters } = useSearchContext();
    if (filterItems.length === 0)
        return null;
    return (<ButtonGroup>
      <SearchableMenu items={filterItems} keys={['name']} placeholder={t({
            message: 'Search',
            comment: 'Search filters: Filter selection search input',
        })} renderTarget={({ setOpen, open }) => (<Button type="button" size="small" appearance="secondary" onClick={() => setOpen(!open)}>
            <Falcon icon="plus"/>
            <Trans comment="Search: Add filter menu button">Filters</Trans>
          </Button>)}>
        {({ items, setOpen }) => items.map(item => (<MenuMultiSelectItem key={item.id} selected={activeFilters.includes(item.id)} onClick={() => {
                toggleFilter(item.id);
                setOpen(false);
            }}>
              {item.name}
            </MenuMultiSelectItem>))}
      </SearchableMenu>
      {hasFilters && (<IconButton appearance="secondary" size="small" onClick={clearFilters} aria-label={t({ message: 'Remove all filters', comment: 'Filters: Clear all filters' })}>
          <Falcon icon="remove"/>
        </IconButton>)}
    </ButtonGroup>);
}
