import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  height: ${pxToRem(6)};
  overflow: hidden;
  border-radius: ${themeGet('borderRadius.sm')};
  background: ${themeGet('colors.ai.50')};
`;
export const Bar = styled.div `
  position: relative;
  height: 100%;
  width: ${props => props.$progress}%;
  transition: all 0.4s ease-in-out;

  background: ${props => props.$completed ? themeGet('colors.green.500') : themeGet('colors.ai.500')};
`;
