import { fontSize, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-flow: row nowrap;
  gap: ${themeGet('spacing.lg')};

  [data-icon='falcon'] {
    position: relative;
    top: ${themeGet('spacing.sm')};
  }

  &:not(:only-child) {
    margin-top: ${({ $marginTop }) => $marginTop && themeGet('spacing.md')};
  }
`;
export const Text = styled.div `
  color: ${({ $assistantColor }) => $assistantColor ? themeGet('colors.assistant.900') : themeGet('colors.text.200')};
  ${fontSize('small', 1.7)};
  max-width: 100%;
`;
