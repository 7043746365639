var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useReducer, useCallback } from 'react';
import { EntryKind } from 'shared/domains/apollo/generated/types';
import { ActionType, } from './types';
const initialState = { name: null, type: null, payload: null };
function reducer(state, action) {
    if (action.type in ActionType) {
        if (action.type !== ActionType.RESET) {
            const _a = action.payload, { type } = _a, payload = __rest(_a, ["type"]);
            return { name: action.type, type, payload };
        }
        // RESET
        return Object.assign({}, initialState);
    }
    // Default
    return state;
}
export default function useDialogState() {
    const [state, dispatch] = useReducer(reducer, initialState);
    const reset = useCallback(() => {
        dispatch({ type: ActionType.RESET });
    }, []);
    const duplicate = useCallback((options) => {
        const { id, name, type, isFreeText } = options;
        dispatch({ type: ActionType.DUPLICATE, payload: { id, name, type, isFreeText } });
    }, []);
    const rename = useCallback((options) => {
        const { id, type, name } = options;
        dispatch({ type: ActionType.RENAME, payload: { id, type, name } });
    }, []);
    const remove = useCallback((options) => {
        const { id, type } = options;
        dispatch({ type: ActionType.DELETE, payload: { id, type } });
    }, []);
    const createFolder = useCallback(() => {
        dispatch({ type: ActionType.CREATE, payload: { type: EntryKind.Folder } });
    }, []);
    const uploadFiles = useCallback((files) => {
        dispatch({ type: ActionType.CREATE, payload: { type: EntryKind.Document, files } });
    }, []);
    const uploadFolder = useCallback((files) => {
        dispatch({ type: ActionType.CREATE, payload: { type: 'UPLOAD_FOLDER', files } });
    }, []);
    const move = useCallback((options) => {
        const { type, id } = options;
        dispatch({ type: ActionType.MOVE, payload: { type, id } });
    }, []);
    const quickMove = useCallback((options) => {
        const { type, sourceId, targetId, sourceName, targetName } = options;
        dispatch({
            type: ActionType.QUICK_MOVE,
            payload: { type, sourceId, targetId, sourceName, targetName },
        });
    }, []);
    return [
        state,
        {
            reset,
            duplicate,
            rename,
            delete: remove,
            createFolder,
            uploadFiles,
            uploadFolder,
            move,
            quickMove,
        },
    ];
}
