import { gql } from '@apollo/client';
export const UPDATE_TEAM = gql `
  mutation UpdateTeam($id: UUID!, $input: UpdateTeamInput!) {
    updateTeam(id: $id, input: $input) {
      id
      name
      description
    }
  }
`;
