import { t } from '@lingui/macro';
export function getNumberDisplayValue(greaterThanValue, lessThanValue) {
    if (greaterThanValue && lessThanValue) {
        return t({
            message: `Between ${greaterThanValue} and ${lessThanValue}`,
            comment: 'Search filter: Number range filter: Between',
        });
    }
    if (greaterThanValue) {
        return t({
            message: `More than ${greaterThanValue}`,
            comment: 'Search filter: Number range filter: More than',
        });
    }
    if (lessThanValue) {
        return t({
            message: `Less than ${lessThanValue}`,
            comment: 'Search filter: Number range filter: Less than',
        });
    }
    return t({
        message: 'Select value',
        comment: 'Search filter: Number range filter: Select value',
    });
}
