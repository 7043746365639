import { t, plural } from '@lingui/macro';
import { getStatusTranslations } from 'app/domains/tasks/utils/taskStatus';
export function formatLabel(label, status) {
    const [activeStatus] = status;
    if (!activeStatus) {
        return label;
    }
    const statuses = getStatusTranslations();
    const statusFilter = statuses[activeStatus];
    const statusFilterLabel = statusFilter.label;
    const moreActiveStatuses = status.length - 1;
    return label.concat(' ', t({
        message: plural(moreActiveStatuses, {
            0: statusFilterLabel,
            other: `${statusFilterLabel} +#`,
        }),
        comment: 'tasks status filter label with user status and count if more statuses are selected',
    }));
}
