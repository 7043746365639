import { useMemo, Children, Fragment, createElement } from 'react';
import toReact from 'rehype-react';
import rehypeSanitize from 'rehype-sanitize';
import remarkBreaks from 'remark-breaks';
import externalLinks from 'remark-external-links';
import remarkGfm from 'remark-gfm';
import parse from 'remark-parse';
import toHtml from 'remark-rehype';
import { unified } from 'unified';
const mapChildren = (children) => Children.map(children, (child) => {
    if (child.type === 'code' || child.type === 'pre') {
        return null;
    }
    if (child.type === Fragment) {
        return mapChildren(child.props.children);
    }
    return child;
});
export function useMarkdown(contents) {
    const markdown = useMemo(() => {
        if (!contents) {
            return null;
        }
        const { result } = unified()
            .use(parse)
            .use(remarkGfm)
            .use(remarkBreaks)
            .use(externalLinks, {
            target: '_blank',
            rel: ['noreferrer', 'noopener'],
        })
            .use(toHtml)
            .use(rehypeSanitize)
            .use(toReact, { Fragment, createElement })
            .processSync(contents);
        return mapChildren(result);
    }, [contents]);
    return markdown;
}
