import { t } from '@lingui/macro';
import { isPossiblePhoneNumber } from '@pocketlaw/tetris';
import { object, string } from 'yup';
export const getValidationSchema = (formKey) => {
    const requiredMessage = t({
        message: 'This is a required field.',
        comment: 'Error message for empty required field',
    });
    const emailErrorMessage = t({
        message: 'This must be a valid email.',
        comment: 'Error message for invalid email for e-signing party',
    });
    const mobileErrorMessage = t({
        message: 'This must be a valid phone number.',
        comment: 'Error message for invalid phone number for e-signing party',
    });
    const emailField = string().email(emailErrorMessage).required(requiredMessage);
    const mobileField = string()
        .required(requiredMessage)
        .test('valid-phone-number', mobileErrorMessage, (mobile = '') => isPossiblePhoneNumber(mobile));
    if (formKey === 'email') {
        return object().shape({ email: emailField });
    }
    return object().shape({ mobile: mobileField });
};
