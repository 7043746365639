import { t, plural } from '@lingui/macro';
import { Alert } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { MetricCard } from './MetricCard';
import { MetricCardLoader } from './MetricCardLoader';
import { useDocumentsSentForEsigningCount } from './useDocumentsSentForEsigningCount';
export function EsignPendingDocumentsWidget() {
    const title = t({
        message: 'Pending signatures',
        comment: 'Title for the Pending signatures documents widget',
    });
    const errorMessage = t({
        message: 'Failed to load E-signing pending documents',
        comment: 'Error message for the E-signing pending documents',
    });
    const { documentsSentForEsigningCount, loading, error } = useDocumentsSentForEsigningCount();
    const suffix = t({
        message: plural(documentsSentForEsigningCount, {
            one: 'Document',
            other: 'Documents',
        }),
        comment: 'Suffix for the E-signing pending documents',
    });
    if (loading) {
        return <MetricCardLoader />;
    }
    if (error) {
        return <Alert message={errorMessage} appearance="danger"/>;
    }
    return (<MetricCard title={title} value={documentsSentForEsigningCount} suffix={suffix} icon={<Falcon icon="paper-plane"/>} to="/documents/awaiting-signatures" tooltip={t({
            message: 'Documents that are pending for e-signing',
            comment: 'Tooltip for the E-signing Documents widget',
        })}/>);
}
