import { Fragment, useRef } from 'react';
import { Trans } from '@lingui/macro';
import { MenuItem } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { FileUpload } from 'app/domains/upload';
import { usePleditor, usePreview } from 'shared/domains/documents';
import { MimeTypesByExtension } from 'shared/domains/filesystem/utils/fileExtensions';
export function ImportDocumentMenuItem(props) {
    const { onClick } = props;
    const { pleditorState, pleditor } = usePleditor();
    const { isFreeText, isPleditorImportExportAvailable } = usePreview();
    const inputRef = useRef(null);
    const handleFileUpload = () => {
        var _a;
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    const handleFileChange = (event) => {
        if (pleditor && event.target.files) {
            const file = event.target.files[0];
            pleditor.commands.execute('importWord', file, {});
        }
        onClick();
    };
    if (!isPleditorImportExportAvailable || !isFreeText) {
        return null;
    }
    return (<Fragment>
      <MenuItem onClick={handleFileUpload} disabled={pleditorState === 'initializing'}>
        <Falcon icon="file-import"/>
        <Trans comment="Dropdown label for importing document">Import version</Trans>
      </MenuItem>
      <FileUpload ref={inputRef} accept={[MimeTypesByExtension.Docx]} onChange={handleFileChange}/>
    </Fragment>);
}
