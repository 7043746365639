import { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Alert, Loader } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { AssistantPromptButton, AssistantViewType, useAssistant } from 'app/domains/assistant';
import { ChatMessageType } from 'shared/domains/apollo/generated/types';
import { DocumentComparePromptButton } from '../DocumentComparePromptButton';
import { Section } from '../Section';
import { AlertContainer } from './styled';
export function DocumentPrompts() {
    const { addComment, requestSuggestions, fetchingSuggestions, updateView } = useAssistant();
    const [error, setError] = useState(null);
    return (<Section>
      {error !== null && (<AlertContainer>
          {error === 'FILE_SIZE_EXCEEDS_MAX' && (<Alert appearance="warning" message={t({
                    comment: 'Error message when the size limit has been exceeded when uploading a file',
                    message: `The file you selected is too large (max 10 MB).`,
                })}/>)}
          {error === 'UNSUPPORTED_FILE_FORMAT' && (<Alert appearance="warning" message={t({
                    comment: 'Error message when the file format is not supported when uploading a file',
                    message: `The file you tried to upload is not supported. Please try again with another file.`,
                })}/>)}
          {error === 'NO_FILE_PROVIDED' && (<Alert appearance="warning" message={t({
                    comment: 'Error message when no file was provided when uploading a file',
                    message: `No file was provided. Please, try again.`,
                })}/>)}
        </AlertContainer>)}
      <AssistantPromptButton title={<Trans comment="Document Assistant predefined prompts - suggest clause">
            Suggest clause
          </Trans>} leftIcon={<Falcon icon="section"/>} onClick={() => addComment({
            type: ChatMessageType.DocumentSuggest,
        })}/>
      <AssistantPromptButton title={<Trans comment="Document Assistant predefined prompts - ask questions">
            Ask questions
          </Trans>} leftIcon={<Falcon icon="question"/>} rightIcon={fetchingSuggestions ? <Loader /> : undefined} onClick={() => fetchingSuggestions ? updateView(AssistantViewType.Suggestions) : requestSuggestions()}/>
      <AssistantPromptButton title={<Trans comment="Document Assistant predefined prompts - summarize">Summarize</Trans>} leftIcon={<Falcon icon="bolt"/>} onClick={() => addComment({
            type: ChatMessageType.DocumentSummarizeWithQuotes,
        })}/>
      <DocumentComparePromptButton setError={setError}/>
      <AssistantPromptButton title={<Trans comment="Document Assistant predefined prompts - negotiation support">
            Negotiation support
          </Trans>} leftIcon={<Falcon icon="eye"/>} rightIcon={<Falcon icon="clock" $size={12}/>} disabled/>
    </Section>);
}
