import { useEffect, useMemo } from 'react';
import { useFormikContext, useField } from 'formik';
import { AnimateHeightContainer } from 'app/domains/tasks/components/AnimateHeightContainer';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { PermissionAlert } from './PermissionAlert';
import { useDocumentPermissions } from './useDocumentPermissions';
export function AssigneePermission() {
    var _a, _b;
    const account = useAccount();
    const { values, setFieldValue, setSubmitting } = useFormikContext();
    const [attachment] = useField('attachments[0]');
    const { taskAssignees = [], creator } = values;
    const assigneeIds = useMemo(() => taskAssignees.map(({ id }) => id), [taskAssignees]);
    const documentId = ((_a = attachment.value) === null || _a === void 0 ? void 0 : _a.type) === 'document' ? (_b = attachment.value) === null || _b === void 0 ? void 0 : _b.attachmentId : undefined;
    const { userId } = account.data;
    const creatorId = creator === null || creator === void 0 ? void 0 : creator.id;
    const { userHasAccess, idsWithoutPermission, loading, called } = useDocumentPermissions({
        documentId,
        ids: {
            userId: userId || '',
            creatorId,
            assigneeIds,
        },
    });
    useEffect(() => {
        setSubmitting(loading);
    }, [loading, setSubmitting]);
    const displayWarning = Boolean(userHasAccess && documentId && called && idsWithoutPermission.length > 0);
    useEffect(() => {
        if (!documentId || !userHasAccess) {
            return;
        }
        setFieldValue('addDocumentPermission', idsWithoutPermission);
    }, [documentId, idsWithoutPermission, setFieldValue, userHasAccess]);
    return (<AnimateHeightContainer isOpen={displayWarning}>
      <PermissionAlert />
    </AnimateHeightContainer>);
}
