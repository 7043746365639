import { DocumentType } from 'shared/domains/apollo/generated/types';
/**
 * This function is deprecated. Use FileSystemDocument.type to
 * check what type a document is.
 * @deprecated
 */
export const isUploadedDocument = (document) => {
    const { type } = document || {};
    return type === DocumentType.Uploaded;
};
