import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { featureFlagKeys, features } from '../../constants';
import { featureFlagsVar } from '../../reactiveVar';
import { FlagState } from '../../types';
/**
 * This hook will get all feature flag keys and set the selected values
 * for each flag.
 * If no url param, or localStorage value is found it will default to the
 * values set in `features` map.
 */
export function useGetFeatureFlagsMap() {
    const location = useLocation();
    const featureFlags = featureFlagKeys.reduce((featureKeys, featureKey) => {
        const param = new URLSearchParams(location.search);
        const { value: defaultValue, force } = features[featureKey];
        const localStorageValue = window.localStorage.getItem(featureKey);
        const urlParamValue = param.get(featureKey);
        const valid = (value) => value === FlagState.ENABLED || value === FlagState.DISABLED;
        const validatedValues = [urlParamValue, localStorageValue, defaultValue].find(valid);
        const value = force ? defaultValue : validatedValues;
        return Object.assign(Object.assign({}, featureKeys), { [featureKey]: { value } });
    }, {});
    useEffect(() => {
        Object.entries(featureFlags).forEach(([key, { value }]) => {
            window.localStorage.setItem(key, value);
        });
        featureFlagsVar(featureFlags);
    }, [featureFlags]);
    return featureFlagsVar();
}
