import { TableBodyCell, AvatarWithLabel, AvatarLetter } from '@pocketlaw/tetris';
import { getFirstLetter } from 'app/domains/users';
import { StyledLink } from './styled';
export function NameCell(props) {
    const { teamId, name } = props;
    return (<TableBodyCell headers="name">
      <StyledLink to={`users-teams/team/${teamId}`}>
        <AvatarWithLabel size="md" title={name} appearance="magenta">
          <AvatarLetter>{getFirstLetter(name)}</AvatarLetter>
        </AvatarWithLabel>
      </StyledLink>
    </TableBodyCell>);
}
