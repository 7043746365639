import { Fragment } from 'react';
import { MenuList } from '@pocketlaw/tetris';
import { MenuSingleSelectItem } from './MenuSingleSelectItem';
import { SearchMenuEmptyState } from './SearchMenuEmptyState';
import { useSearchableMenu } from './SearchableMenuContext';
export function SearchableMenuSingleSelectList(props) {
    const { renderItem, onSelect, selected, emptyComponent } = props;
    const { items, hasResult, setOpen, resetSearch } = useSearchableMenu();
    const emptyStateComponent = emptyComponent || <SearchMenuEmptyState />;
    const handleOnClick = (item) => {
        setOpen(false);
        onSelect(item);
        resetSearch();
    };
    const isSelected = (id) => {
        if (Array.isArray(selected)) {
            return selected.includes(id);
        }
        return selected === id;
    };
    return (<Fragment>
      {hasResult && (<MenuList as="nav" role="menu">
          {items.map(item => (<MenuSingleSelectItem key={item.id} selected={isSelected(item.id)} onClick={() => handleOnClick(item)}>
              {renderItem(item)}
            </MenuSingleSelectItem>))}
        </MenuList>)}

      {!hasResult && emptyComponent !== false && emptyStateComponent}
    </Fragment>);
}
