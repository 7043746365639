import { pxToRem } from '@pocketlaw/tetris';
import { css } from 'styled-components';
export const customBlockStyles = css `
  .ck-custom-block-indent-1 {
    margin-left: ${pxToRem(40)};
  }

  .ck-custom-block-indent-2 {
    margin-left: ${pxToRem(80)};
  }

  .ck-custom-block-indent-3 {
    margin-left: ${pxToRem(120)};
  }
`;
