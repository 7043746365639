import { unit } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${unit('2xl')};
`;
export const TextContainer = styled.div `
  display: flex;
  flex-direction: column;
`;
