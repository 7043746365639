var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { lastApprovalRequestQuery } from 'app/domains/esigning/hooks/useLastApprovalRequest/query';
import { APPROVAL_IGNORE_CODES } from 'app/domains/esigning/utils';
import { AclAction } from 'shared/domains/apollo/generated/types';
import { useAddUserAccess } from 'shared/domains/users';
import { MUTATION } from './mutation';
export const useCreateApprovalRequest = () => {
    const { mutate: addUserAccess } = useAddUserAccess();
    const { addToast } = useToast();
    const [mutate] = useMutation(MUTATION);
    const createApprovalRequest = (fileSystemDocumentId, approverIdsWithoutPermission, message) => __awaiter(void 0, void 0, void 0, function* () {
        yield mutate({
            variables: { input: { fileSystemDocumentId, message } },
            refetchQueries: [lastApprovalRequestQuery],
            context: {
                sentry: {
                    ignore: APPROVAL_IGNORE_CODES,
                },
            },
        });
        if (approverIdsWithoutPermission.length > 0) {
            yield addUserAccess(fileSystemDocumentId, {
                users: approverIdsWithoutPermission,
                actions: [AclAction.Read],
            });
        }
        addToast({
            appearance: 'success',
            title: t({
                comment: 'Create approval request success message',
                message: 'Document is sent for approval',
            }),
        });
    });
    return { createApprovalRequest };
};
