import { t, Trans, select } from '@lingui/macro';
import { Button, Dialog, DialogHeader, DialogContent, DialogFooter, Loader, Alert, AvatarWithLabel, TextInput, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Field, Form, useField, useFormikContext } from 'formik';
import { AccessListNewEntry } from 'app/domains/filesystem';
import { AutoTag } from './AutoTag';
import SelectFolderField from '../SelectFolderField';
import { useFileSystemManager } from '../useFileSystemManager';
import { FieldContainer } from './styled';
export function FileSystemDialog(props) {
    const { selectedId, entryType, error, onDiscard, onClose } = props;
    const query = useFileSystemManager({ parentId: selectedId });
    const { selectedFolder } = query.data || {};
    const { isSubmitting, isValid, setFieldValue } = useFormikContext();
    const [parentIdField] = useField('parentId');
    const submitDisabled = isSubmitting || !isValid || !parentIdField.value;
    const handleChangeAccess = (values) => {
        const { userAcls, teamAcls } = values;
        setFieldValue('userAcls', userAcls);
        setFieldValue('teamAcls', teamAcls);
    };
    const title = select(entryType, {
        FOLDER: 'Save folder',
        DOCUMENT: 'Save file',
        other: 'Save',
    });
    if (query.loading) {
        return null;
    }
    return (<Dialog as={Form} onClose={isSubmitting ? undefined : onClose}>
      <DialogHeader media={<AvatarWithLabel size="xl" shape="square" title={title}>
            <Falcon icon="folder"/>
          </AvatarWithLabel>}/>
      <DialogContent>
        {error && (<Alert appearance="danger" message={t({
                message: 'Something went wrong, please try again later',
                comment: 'Error alert message',
            })}/>)}

        <FieldContainer>
          <Field name="name" as={TextInput} label={t `Name`} disabled={isSubmitting} stretch/>
        </FieldContainer>

        <FieldContainer>
          <SelectFolderField folder={selectedFolder}/>
        </FieldContainer>

        {entryType === 'DOCUMENT' && (<FieldContainer>
            <AutoTag />
          </FieldContainer>)}

        <AccessListNewEntry parentEntryId={selectedId} onChange={handleChangeAccess}/>
      </DialogContent>
      <DialogFooter description={onDiscard && (<Button type="button" appearance="secondary" onClick={onDiscard} disabled={isSubmitting}>
              <Trans>Continue without saving</Trans>
            </Button>)}>
        <Button type="button" appearance="secondary" disabled={isSubmitting} onClick={onClose}>
          <Trans>Cancel</Trans>
        </Button>
        <Button type="submit" disabled={submitDisabled}>
          <Trans>Save</Trans>
          {isSubmitting && <Loader />}
        </Button>
      </DialogFooter>
    </Dialog>);
}
