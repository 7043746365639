var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@apollo/client';
import { accountQuery } from './query';
function useAccount(options) {
    const _a = useQuery(accountQuery, Object.assign(Object.assign({}, options), { fetchPolicy: 'cache-only' })), { data } = _a, rest = __rest(_a, ["data"]);
    const { account, profile } = data || {};
    const { id, role, canManageMember, company, companyId, userId } = account || {};
    const memoizedData = {
        id,
        role,
        canManageMember,
        company: company,
        companyId: companyId,
        userId,
        profile,
    };
    return Object.assign(Object.assign({}, rest), { data: memoizedData });
}
export default useAccount;
