import { Trans } from '@lingui/macro';
import { DialogFooter } from '@pocketlaw/tetris';
import { CancelButton, SubmitButton } from 'shared/domains/forms';
export function Footer(props) {
    const { onClose } = props;
    return (<DialogFooter>
      <CancelButton onClick={onClose}>
        <Trans comment="Document assistant - feedback dialog - cancel button label">Cancel</Trans>
      </CancelButton>
      <SubmitButton>
        <Trans comment="Document assistant - feedback dialog - submit button label">Submit</Trans>
      </SubmitButton>
    </DialogFooter>);
}
