var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { TableBodyCell, TableCellEmpty, TableCellText } from '@pocketlaw/tetris';
import { metadataConfig } from 'shared/domains/metadata';
export function MetadataAllowedValueCell(props) {
    const { value, name } = props, rest = __rest(props, ["value", "name"]);
    const { allowedValues } = metadataConfig.get(name) || {};
    // Metadata properties might contain nested values, they are all unique.
    const allValues = allowedValues === null || allowedValues === void 0 ? void 0 : allowedValues.map(({ text, value: textValue, groupValues }) => [
        { text, value: textValue },
        ...(groupValues || []),
    ]).flat();
    const { text } = (allValues === null || allValues === void 0 ? void 0 : allValues.find(item => item.value === value)) || {};
    if (!text) {
        return (<TableBodyCell {...rest}>
        <TableCellEmpty />
      </TableBodyCell>);
    }
    return (<TableBodyCell {...rest}>
      <TableCellText primary={text}/>
    </TableBodyCell>);
}
