export const workspaceToDashboardRedirect = (routeProps) => {
    const { location } = routeProps;
    return Object.assign(Object.assign({}, location), { pathname: '/dashboard' });
};
export const workspaceToFilesRedirect = (routeProps) => {
    const { location, match } = routeProps;
    const { folderId } = match.params;
    const options = Object.assign(Object.assign({}, location), { pathname: '/files' });
    if (folderId) {
        Object.assign(options, { pathname: `/files/${folderId}` });
    }
    return options;
};
