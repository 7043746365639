import { cleanTaskData } from '../utils/cleanTaskData';
function parseTask(id, task, isCreator) {
    const { title, description, isCompleted, isPublic, dueDate } = cleanTaskData(task);
    const taskData = {
        id,
        task: {
            title,
            description,
            is_completed: Boolean(isCompleted),
            due_date: dueDate,
        },
        isPublic,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: this needs to be addressed [TECH-2382].
        isCreator,
    };
    return taskData;
}
export { parseTask };
