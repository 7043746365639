import { parsePhoneNumber } from '@pocketlaw/tetris';
import { getCurrentMarket } from 'app/domains/markets/config';
const getCountryFromPhoneNumber = (phoneNumber) => {
    var _a;
    if (phoneNumber) {
        return (_a = parsePhoneNumber(phoneNumber)) === null || _a === void 0 ? void 0 : _a.country;
    }
    return undefined;
};
export const getDefaultCountry = (phoneNumber) => {
    const phoneCode = getCountryFromPhoneNumber(phoneNumber);
    if (phoneCode) {
        return phoneCode;
    }
    const { code } = getCurrentMarket();
    if (code === 'uk') {
        return 'GB';
    }
    return code.toUpperCase();
};
