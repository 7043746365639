import { t } from '@lingui/macro';
import { SearchInput } from '@pocketlaw/tetris';
import { getSearchPlaceholder } from '../utils/getSearchPlaceholder';
export function Search(props) {
    const resetLabel = t({
        message: 'Reset',
        comment: 'user & team select: Reset search input',
    });
    const { users, teams, search, onSearch, loading } = props;
    const placeholder = getSearchPlaceholder({ users, teams });
    const handleSearch = (event) => {
        onSearch(event.target.value);
    };
    return (<SearchInput stretch placeholder={placeholder} variant="line" value={search} onChange={handleSearch} onReset={() => onSearch('')} resetLabel={resetLabel} loading={loading}/>);
}
