import { createRoot } from 'react-dom/client';
import { AttachmentWidget } from './AttachmentWidget';
import { Providers } from './Providers';
import { SelectAttachmentWidget } from './SelectAttachmentWidget';
export const renderSelectAttachment = (props, element) => {
    const root = createRoot(element);
    root.render(<Providers>
      <SelectAttachmentWidget root={root} {...props}/>
    </Providers>);
};
export const renderAttachment = (props, element) => {
    const root = createRoot(element);
    root.render(<Providers>
      <AttachmentWidget {...props}/>
    </Providers>);
};
