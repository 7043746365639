import { getFormSignatoryFromDefaultSignatory, getFormSignatoryFromSignatory, } from 'app/domains/esigning/utils';
export const getFormSignatories = (signatories, defaultSignatories) => {
    // If possible use the data from the default signatories located in `signatories`
    // We achieve this by grabbing the matching default signatory by email
    // and then transform it and assign the correct id
    const mappedDefaultSignatories = defaultSignatories.map(defaultSignatory => {
        const match = signatories.find(signatory => signatory.email === defaultSignatory.email);
        if (match) {
            const transformedMatch = getFormSignatoryFromSignatory(match);
            return Object.assign(Object.assign({}, transformedMatch), { id: defaultSignatory.id });
        }
        return getFormSignatoryFromDefaultSignatory(defaultSignatory);
    });
    // We filter out all default signatories from `signatories` because they come from `mappedDefaultSignatories`
    const mappedSignatories = signatories
        .filter(signatory => !signatory.isDefault)
        .map(getFormSignatoryFromSignatory);
    return [...mappedDefaultSignatories, ...mappedSignatories];
};
