import { DialogContent, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const StyledDialogContent = styled(DialogContent) `
  display: flex;
  flex-direction: column;
  gap: ${themeGet('spacing.2xl')};
`;
export const InputFieldContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${themeGet('spacing.xl')};
`;
export const LinkFieldContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${themeGet('spacing.lg')};
`;
export const AttachmentsFieldContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${themeGet('spacing.lg')};
`;
