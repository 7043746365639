import { createContext, useState } from 'react';
import { Menu, Button, defaultFloatingPositionMiddleware, parentWidth } from '@pocketlaw/tetris';
import { UserList } from '../UserList';
import { UsersAlert } from '../UsersAlert';
import { useSearchUsers } from '../useSearchUsers';
export const SelectUsersContext = createContext(null);
export function SelectCompanyUsers(props) {
    const { buttonLabel, buttonDisabled = false, selectedUserIds = [], acceptedUserRoles, onSelectUser, onUnselectUser, } = props;
    const [open, setOpen] = useState(false);
    const { error } = useSearchUsers(acceptedUserRoles);
    const options = {
        middleware: [...defaultFloatingPositionMiddleware, parentWidth()],
    };
    const handleCloseMenu = () => setOpen(false);
    const handleToggleMenu = () => setOpen(!open);
    const value = {
        closeMenu: handleCloseMenu,
        selectedUserIds,
        acceptedUserRoles,
        onSelectUser,
        onUnselectUser,
    };
    if (error) {
        return <UsersAlert />;
    }
    return (<SelectUsersContext.Provider value={value}>
      <Menu open={open} options={options} onClose={handleCloseMenu} target={<Button $stretch type="button" appearance="secondary" disabled={buttonDisabled} onClick={handleToggleMenu}>
            {buttonLabel}
          </Button>}>
        <UserList />
      </Menu>
    </SelectUsersContext.Provider>);
}
