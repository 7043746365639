import { forwardRef, Fragment, useRef, useState } from 'react';
import { t } from '@lingui/macro';
import { Box, Button, TextArea, useForkedRef } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useField, useFormikContext } from 'formik';
import { useAssistant } from 'app/domains/assistant';
import { PromptLibraryModal, PromptLibraryProvider } from 'app/domains/prompt-library';
import { useFeatureFlag } from 'shared/domains/featureFlag/hooks/useFeatureFlag';
import { CancelButton } from './CancelButton';
import { SubmitButton } from './SubmitButton';
import { Container } from './styled';
export const ChatInput = forwardRef((_, forwardedRef) => {
    const [field] = useField('message');
    const { isValid, submitForm, setFieldValue } = useFormikContext();
    const { cannotSubmitUserMessage, hasCredits, isStreaming } = useAssistant();
    const [promptLibraryOpen, setPromptLibraryOpen] = useState(false);
    const isFeaturePromptLibrary = useFeatureFlag('feature_prompt_library');
    const disabled = !hasCredits;
    const label = t({
        message: `Type your question`,
        comment: 'Document assistant chat textarea placeholder',
    });
    const textAreaRef = useRef(null);
    const forkedTextAreaRef = useForkedRef(forwardedRef, textAreaRef);
    const handleSelectPrompt = (content) => {
        setFieldValue(field.name, content);
        setPromptLibraryOpen(false);
        setTimeout(() => {
            var _a;
            (_a = textAreaRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }, 0);
    };
    const handleTogglePromptLibrary = () => {
        setPromptLibraryOpen(currentState => !currentState);
    };
    const handleKeyDown = (event) => {
        if (!isValid || cannotSubmitUserMessage) {
            return;
        }
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            submitForm();
        }
    };
    return (<Fragment>
      <Container>
        <TextArea {...field} ref={forkedTextAreaRef} stretch variant="outline" autoComplete="off" disabled={disabled} label={label} autosizeHeight onKeyDown={handleKeyDown} maxHeight={400}/>
        <Box justifyContent="right">
          {isFeaturePromptLibrary && (<Box mr="md">
              <Button type="button" appearance="ghost" onClick={handleTogglePromptLibrary}>
                <Falcon icon="book"/>
              </Button>
            </Box>)}
          <Box>{isStreaming ? <CancelButton /> : <SubmitButton />}</Box>
        </Box>
      </Container>
      {promptLibraryOpen && (<PromptLibraryProvider onSelect={handleSelectPrompt} onClose={handleTogglePromptLibrary} defaultView="library">
          <PromptLibraryModal />
        </PromptLibraryProvider>)}
    </Fragment>);
});
