import { useLocation, matchPath } from 'react-router-dom';
const EXCLUDED_ROUTES = [
    '/verify',
    '/onboarding',
    '/invite',
    '/signup',
    '/company-creation',
    '/logout',
];
export function useExcludedRoutes() {
    const { pathname } = useLocation();
    return Boolean(matchPath(pathname, {
        path: EXCLUDED_ROUTES,
    }));
}
