import { gql } from '@apollo/client';
export const gtmDataQuery = gql `
  query gtmData($companyId: UUID!, $includeIndustry: Boolean!) {
    company(id: $companyId) {
      id
      industry @include(if: $includeIndustry)
      industryOther: industry_other @include(if: $includeIndustry)
      plan {
        id
        plan {
          id
          planType: type
        }
      }
    }
  }
`;
