var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fragment, useState } from 'react';
import { useMutation } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { Dialog, DialogContent, DialogHeader, IconButton, useToast } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useHistory } from 'react-router-dom';
import { Context, dashboardTemplates } from './Context';
import { CreateWidgetForDashboardTemplateDocument } from './CreateWidgetForDashboardTemplate.gql';
import { OverviewHeader } from './OverviewHeader';
import { TemplateOverview } from './TemplateOverview';
import { TemplatesList } from './TemplatesList';
import { useCreateDashboard } from '../../hooks/useCreateDashboard';
import { DashboardForm } from '../DashboardForm';
export function InsightsTemplate(props) {
    const { onClose } = props;
    const [selected, setSelected] = useState();
    const [showForm, setShowForm] = useState(false);
    const { addToast } = useToast();
    const history = useHistory();
    const value = {
        selected,
        setSelected,
        createDashboard: () => setShowForm(true),
    };
    const closeForm = () => setShowForm(false);
    const [createDashboard] = useCreateDashboard();
    const [widgetMutation] = useMutation(CreateWidgetForDashboardTemplateDocument);
    const handleCreateDashboard = (dashboard) => __awaiter(this, void 0, void 0, function* () {
        const selectedTemplate = dashboardTemplates.find(mock => mock.id === selected);
        let newDashboardId = '';
        try {
            if (selectedTemplate) {
                const { slots } = selectedTemplate;
                const slotWithWidgetId = yield Promise.all(slots.map((_b) => __awaiter(this, [_b], void 0, function* ({ widget, h, w, x, y }) {
                    var _c;
                    const widgetData = {
                        label: i18n._(widget.label),
                        description: i18n._(widget.description || ''),
                        type: widget.type,
                        aggregations: widget.aggregations,
                        filters: widget.filters,
                        colorScheme: widget.colorScheme,
                        isHorizontal: widget.isHorizontal,
                    };
                    const widgetResult = yield widgetMutation({
                        variables: {
                            input: widgetData,
                        },
                    });
                    const widgetId = ((_c = widgetResult.data) === null || _c === void 0 ? void 0 : _c.createSearchInsightsWidget.id) || '';
                    return {
                        widgetId,
                        h,
                        w,
                        x,
                        y,
                    };
                })));
                const newTemplateDashboard = yield createDashboard({
                    label: dashboard.label,
                    slots: slotWithWidgetId,
                });
                newDashboardId = (newTemplateDashboard === null || newTemplateDashboard === void 0 ? void 0 : newTemplateDashboard.id) || '';
            }
            else {
                const newDashboard = yield createDashboard(dashboard);
                newDashboardId = (newDashboard === null || newDashboard === void 0 ? void 0 : newDashboard.id) || '';
            }
            onClose();
            addToast({
                title: t({
                    message: 'Dashboard created',
                    comment: 'Insights: Toast message when dashboard is created',
                }),
                appearance: 'success',
            });
            history.push(`/insights/${newDashboardId}`);
        }
        catch (_a) {
            addToast({
                title: t({
                    message: 'Dashboard creation failed',
                    comment: 'Insights: Toast message when dashboard creation failed',
                }),
                appearance: 'danger',
            });
        }
    });
    if (showForm) {
        return <DashboardForm onClose={closeForm} onSubmit={handleCreateDashboard}/>;
    }
    return (<Context.Provider value={value}>
      <Dialog size="xl" height="100%" onClose={onClose}>
        {!selected && (<Fragment>
            <DialogHeader action={<IconButton $round appearance="ghost" onClick={onClose}>
                  <Falcon icon="xmark"/>
                </IconButton>} title={t({
                message: 'Create dashboard',
                comment: 'Insights: Create dashboard dialog header',
            })} subtitle={t({
                message: 'Kickstart your new dashboard with a template or start from a clean sheet.',
                comment: 'Insights: Create dashboard dialog subtitle',
            })}/>
            <DialogContent>
              <TemplatesList />
            </DialogContent>
          </Fragment>)}

        {selected && (<Fragment>
            <OverviewHeader />
            <DialogContent>
              <TemplateOverview />
            </DialogContent>
          </Fragment>)}
      </Dialog>
    </Context.Provider>);
}
