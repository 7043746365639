import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { LabelHeading, RadioCard } from '@pocketlaw/tetris';
import { useField } from 'formik';
import { useRole } from 'app/domains/users/hooks/useRole';
import { roles } from 'app/domains/users/utils/roles';
import { ErrorMessage } from 'shared/domains/forms';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { RadioCardContainer } from './styled';
function RoleField(props) {
    const { disabled = false } = props;
    const { i18n } = useLingui();
    // eslint-disable-next-line no-underscore-dangle
    const _i18n = i18n;
    const [field, meta] = useField({ name: 'role', type: 'checkbox' });
    const userRole = useRole();
    const account = useAccount();
    const isOwner = userRole.has('owner');
    const filteredRoles = roles.filter(role => {
        var _a;
        if (role.value === 'owner' && isOwner !== true) {
            return false;
        }
        if (role.value === 'admin' && ((_a = account.data.company) === null || _a === void 0 ? void 0 : _a.ssoEnterprise)) {
            return false;
        }
        return true;
    });
    const error = Boolean(meta.touched && meta.error);
    return (<div>
      <LabelHeading noMargin>
        <Trans>Role</Trans>
      </LabelHeading>
      <RadioCardContainer>
        {filteredRoles.map(role => (<RadioCard key={role.value} name={field.name} value={role.value} checked={field.value === role.value} onChange={field.onChange} title={_i18n._(role.label)} subtitle={_i18n._(role.description)} disabled={disabled} invalid={error}/>))}
      </RadioCardContainer>
      <ErrorMessage name="role"/>
    </div>);
}
RoleField.displayName = 'RoleField';
export default RoleField;
