import { t } from '@lingui/macro';
import { IconButton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { usePreview } from '../../../hooks/usePreview';
import { ButtonGroup, Text } from './styled';
export function ZoomManager() {
    const { zoomTo, zoom } = usePreview();
    return (<ButtonGroup>
      <IconButton aria-label={t `Zoom in`} appearance="secondary" onClick={() => zoomTo(+1)} $round>
        <Falcon icon="plus" $size={16}/>
      </IconButton>
      <Text aria-label={t `Zoom level`}>{Math.round(zoom * 100)}%</Text>
      <IconButton aria-label={t `Zoom out`} appearance="secondary" onClick={() => zoomTo(-1)} $round>
        <Falcon icon="minus" $size={16}/>
      </IconButton>
    </ButtonGroup>);
}
