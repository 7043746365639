import { useQuery } from '@apollo/client';
import { currentUserTeamIdsQuery } from './currentUserTeamIdsQuery';
/**
 * Get all the teamIds of the teams the current user is a member of
 */
export function useCurrentUserTeamIds() {
    const { data, loading, error } = useQuery(currentUserTeamIdsQuery);
    const teamIds = (data === null || data === void 0 ? void 0 : data.teams.map(({ id }) => id)) || [];
    return { teamIds, loading, error };
}
