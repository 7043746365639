import { Trans } from '@lingui/macro';
import { Box, Loader as TetrisLoader, Typography } from '@pocketlaw/tetris';
export function Loader() {
    return (<Box alignItems="center" gap="lg">
      <TetrisLoader size={8}/>
      <Typography>
        <Trans comment="Loading document highlights label">Loading highlights...</Trans>
      </Typography>
    </Box>);
}
