import { Fragment, useState } from 'react';
import { t } from '@lingui/macro';
import { Chip } from '@pocketlaw/tetris';
import { RenameDocumentDialog } from 'app/domains/documents';
import { useDocumentMetadata } from './DocumentMetadataProvider';
export function DocumentNameChip() {
    const { data } = useDocumentMetadata();
    const [active, setActive] = useState(false);
    const { systemData } = data;
    const { name } = systemData;
    const toggleAction = () => setActive(!active);
    return (<Fragment>
      <Chip size="large" label={t({ message: 'Document name', comment: 'Label for document name' })} secondary={name} appearance="secondary" onClick={toggleAction}/>

      {active && <RenameDocumentDialog onClose={() => setActive(false)}/>}
    </Fragment>);
}
