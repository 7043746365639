import { themeGet, Sidebar, Drawer, unit, Typography, ListItem } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  position: fixed;
  z-index: 1;
  left: 0;
  bottom: 0;
  padding: 0.7rem 0.1rem;
  background-color: ${themeGet('colors.indigo.600')};
  cursor: pointer;
  display: inline-flex; // safari hack
`;
export const StyledDrawer = styled(Drawer) `
  max-width: 26rem;
  width: 100%;
  background-color: ${themeGet('colors.gray.0')};
  box-shadow: ${themeGet('dropShadow.400')};
`;
export const Trigger = styled(Typography) `
  writing-mode: vertical-rl;
  font-size: ${themeGet('fontSize.medium')};
  color: ${themeGet('colors.gray.0')};
`;
export const StyledSidebar = styled(Sidebar) `
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;
export const Content = styled.div `
  flex: 1;
`;
export const ActionContainer = styled.div `
  padding: ${unit('xl')};
  width: 100%;
`;
export const AlignRight = styled.div `
  margin-left: auto;
`;
export const StyledListItem = styled(ListItem) `
  display: flex;
  gap: ${themeGet('spacing.md')};
  justify-content: center;
  overflow: hidden;
`;
export const FlagText = styled(Typography) `
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;
