import { Fragment, useState } from 'react';
import { t } from '@lingui/macro';
import { IconButton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useField } from 'formik';
import { ESignSignatoryFormDialog } from 'app/domains/esigning/components/ESignSignatoryFormDialog';
import { useEsigningSignatoriesFormField } from 'app/domains/esigning/components/EsigningForm';
import { ESignPartyItem } from '../../../ESignPartyItem';
import { SectionList } from '../../../components';
import { PartyActions } from '../PartyActions';
export function NonSigningParties() {
    const [{ value: groupMessage }] = useField('globals.groupMessage');
    const [{ value: useSigningOrder }] = useField('settings.useSigningOrder');
    const { signatories, addSignatory } = useEsigningSignatoriesFormField();
    const [showAddPartyModal, setShowAddPartyModal] = useState(false);
    const recipients = signatories.filter(({ isSigning }) => !isSigning);
    const showModal = () => setShowAddPartyModal(true);
    const hideModal = () => setShowAddPartyModal(false);
    if (recipients.length === 0) {
        return null;
    }
    return (<Fragment>
      {showAddPartyModal && (<ESignSignatoryFormDialog type="Signatory" newSignatoryIsNonSigning allSignatories={signatories} onClose={hideModal} onSubmit={addSignatory}/>)}
      <SectionList hideTooManyItems title={t({
            message: 'Non signing parties',
            comment: 'Label for non signing parties list',
        })} endElement={<IconButton $round data-id="add-non-signing-party-button" size="small" appearance="ghost" onClick={showModal}>
            <Falcon icon="plus"/>
          </IconButton>}>
        {recipients.map(recipient => (<ESignPartyItem key={recipient.key} hideAvatar={useSigningOrder} signatory={recipient} groupMessage={groupMessage} endElement={<PartyActions signatory={recipient}/>}/>))}
      </SectionList>
    </Fragment>);
}
