import { unit } from '@pocketlaw/tetris';
import { Form } from 'formik';
import styled from 'styled-components';
export const StyledForm = styled(Form) `
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: ${unit(4)};
`;
