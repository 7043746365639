import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useDebouncedCallback } from 'use-debounce';
export function HandleFilterChanges() {
    const { values, submitForm, dirty } = useFormikContext();
    const debouncedSearch = useDebouncedCallback(() => {
        if (dirty) {
            submitForm();
        }
    }, 250);
    useEffect(debouncedSearch, [debouncedSearch, values]);
    return null;
}
