import { Trans, t } from '@lingui/macro';
import { PageHeader, Breadcrumbs, Breadcrumb, Button } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Link } from 'react-router-dom';
import { useTasks } from '../TasksProvider';
export function TaskHeader() {
    const { setDisplayAdd } = useTasks();
    return (<PageHeader heading={t({ message: 'Tasks', comment: 'Tasks page header title' })} breadcrumbs={<Breadcrumbs>
          <Breadcrumb as={Link} to="/dashboard">
            <Trans comment="Dashboard breadcrumb">Dashboard</Trans>
          </Breadcrumb>
          <Breadcrumb>
            <Trans comment="Tasks breadcrumb">Tasks</Trans>
          </Breadcrumb>
        </Breadcrumbs>} actions={<Button onClick={() => setDisplayAdd(true)}>
          <Falcon icon="plus"/>
          <Trans comment="add new task button">Task</Trans>
        </Button>}/>);
}
