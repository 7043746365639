import { useCallback, useEffect } from 'react';
import { useTextNodeDistanceSearch, } from 'shared/domains/documents/hooks/useTextNodeDistanceSearch';
// Find node by `root.model.document.getRoot().getNodeByPath(path)`
function pleditorToSearchableTextNodes(root) {
    function recursivleyExtractTextNodes(node) {
        if (node.is('$text')) {
            return [{ value: node.data, path: node.getPath() }];
        }
        if (node.is('element')) {
            return [...node.getChildren()].map(child => recursivleyExtractTextNodes(child)).flat();
        }
        return [];
    }
    return recursivleyExtractTextNodes(root);
}
export function useSearchTexts(pleditor) {
    const [search, state] = useTextNodeDistanceSearch();
    const fuzzySearchTextNodes = useCallback((query) => {
        const root = pleditor === null || pleditor === void 0 ? void 0 : pleditor.model.document.getRoot();
        if (root) {
            const searchableNodes = pleditorToSearchableTextNodes(root);
            search(searchableNodes, query);
        }
    }, [pleditor, search]);
    const resetFuzzySearch = useCallback(() => {
        pleditor === null || pleditor === void 0 ? void 0 : pleditor.execute('highlightReset');
    }, [pleditor]);
    useEffect(() => {
        if (pleditor) {
            const nodePaths = state.data.map(searchableTextNode => searchableTextNode.path);
            pleditor.execute('highlightAt', {
                paths: nodePaths,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.data]);
    return {
        fuzzySearchTextNodes,
        resetFuzzySearch,
    };
}
