var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@apollo/client';
import { documentApproversQuery } from './query';
export const useDocumentApprovers = (documentId) => {
    var _a, _b;
    const _c = useQuery(documentApproversQuery, { variables: { documentId } }), { data } = _c, rest = __rest(_c, ["data"]);
    const approvers = ((_b = (_a = data === null || data === void 0 ? void 0 : data.fileSystemDocument) === null || _a === void 0 ? void 0 : _a.approval) === null || _b === void 0 ? void 0 : _b.approvers) || [];
    const users = approvers.filter(approver => { var _a; return (_a = approver.companyUser) === null || _a === void 0 ? void 0 : _a.user; });
    const documentApprovers = users.map(approver => {
        var _a;
        return ((_a = approver.companyUser) === null || _a === void 0 ? void 0 : _a.user) && {
            userId: approver.companyUser.user.id,
            name: approver.companyUser.user.name,
            email: approver.companyUser.user.email,
            companyUserId: approver.companyUser.id,
        };
    });
    return Object.assign(Object.assign({}, rest), { documentApprovers });
};
