import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: ${themeGet('spacing.xl')};
  gap: ${themeGet('spacing.lg')};
  border-top: solid ${pxToRem(1)} ${themeGet('colors.border.100')};
`;
