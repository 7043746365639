import { t } from '@lingui/macro';
import { Table, TableHead, TableRow, TableHeaderText, TableBody, TableHeadCell, TableBodyCell, } from '@pocketlaw/tetris';
import { useField } from 'formik';
import { useRole } from 'app/domains/users/hooks/useRole';
import { ComposerBody } from './ComposerBody';
import { StyledAlert } from './styled';
function sortComposer(composers) {
    const tmp = [...composers];
    tmp.sort((a, b) => {
        if (a.title === b.title) {
            return 0;
        }
        return a.title > b.title ? 1 : -1;
    });
    return tmp;
}
export function SummarizeComposerTable() {
    const [composerField] = useField('composers');
    const composers = composerField.value || [];
    const sortedComposers = sortComposer(composers);
    const role = useRole();
    const isOwner = role.is() === 'owner';
    return (<Table border>
      <TableHead>
        <TableRow>
          <TableHeadCell id="composers">
            <TableHeaderText>
              {t({
            message: 'Templates',
            comment: 'user invite modal - summarize templates title',
        })}
            </TableHeaderText>
          </TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {!isOwner && (<TableRow>
            <TableBodyCell headers="composers">
              <StyledAlert appearance="warning" title={t({
                message: 'No template access',
                comment: 'user invite modal - summarize no access composer alert title',
            })} message={t({
                message: 'The user will not have access to any templates by default. Ask a company owner to grant access to templates',
                comment: 'user invite modal - summarize no access composer alert message',
            })}/>
            </TableBodyCell>
          </TableRow>)}
        {isOwner && <ComposerBody composers={sortedComposers}/>}
      </TableBody>
    </Table>);
}
