import { pxToRem, themeGet, unit } from '@pocketlaw/tetris';
import styled from 'styled-components';
import { SectionContent } from 'app/domains/esigning/components/components';
export const MessagesContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${unit('xl')};
  padding: ${unit('xl')};
  border-bottom: solid ${pxToRem(1)} ${themeGet('colors.gray.100')};
`;
export const StyledSectionContent = styled(SectionContent) `
  gap: unset;
  border-top: solid ${pxToRem(1)} ${themeGet('colors.gray.100')};
`;
