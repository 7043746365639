var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation, useQuery } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { add, format, interval, isValid, isWithinInterval } from 'date-fns';
import { localizedFormatDate } from 'shared/domains/common-utils';
import { DocumentNoticeDateDocument } from './DocumentNoticeDate.gql';
import { UpdateDocumentNoticeDateDocument } from './UpdateDocumentNoticeDate.gql';
export function useDocumentNoticeDate(options) {
    const { documentId, onCompleted } = options;
    const { addToast } = useToast();
    const { data, loading } = useQuery(DocumentNoticeDateDocument, {
        variables: {
            id: documentId,
        },
        skip: !documentId,
    });
    const [mutate, state] = useMutation(UpdateDocumentNoticeDateDocument);
    const { documentMetadataPairs } = data || {};
    const [noticeDate] = documentMetadataPairs || [];
    const { value, id: pairId } = noticeDate || {};
    const date = new Date(value);
    const safeDate = isValid(date) ? date : undefined;
    const localizedDate = safeDate ? localizedFormatDate(safeDate.toISOString()) : undefined;
    const dateInterval = interval(new Date(), add(new Date(), { months: 1 }));
    const warning = isWithinInterval(date, dateInterval);
    const handleMutation = (newDate) => __awaiter(this, void 0, void 0, function* () {
        try {
            const formattedDate = format(newDate, 'yyyy-MM-dd');
            yield mutate({
                variables: {
                    id: pairId,
                    value: formattedDate,
                },
                onCompleted,
            });
            addToast({
                title: t({
                    message: 'Notice date updated',
                    comment: 'Toast message when notice date is updated for a document',
                }),
                appearance: 'success',
            });
        }
        catch (error) {
            addToast({
                title: t({
                    message: 'Could not update notice date',
                    comment: 'Toast message when notice date could not be updated for a document',
                }),
                appearance: 'danger',
            });
        }
    });
    return {
        data: {
            value: date,
            localizedDate,
            warning,
        },
        update: handleMutation,
        savingData: state.loading,
        loadingData: loading,
    };
}
