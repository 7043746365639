import { t } from '@lingui/macro';
import { EmptyState } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Content } from '../styled';
import { EmptyStateContainer } from './styled';
export function ErrorState() {
    return (<Content>
      <EmptyStateContainer>
        <EmptyState icon={<Falcon icon="circle-exclamation"/>} title={t({
            comment: 'Prompt library dialog - Error title',
            message: 'Could not load prompts',
        })} description={t({
            comment: 'Prompt library dialog - Error description',
            message: 'Something went wrong while trying to load the prompts',
        })}/>
      </EmptyStateContainer>
    </Content>);
}
