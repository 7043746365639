import { getBucketData } from './getBucketData';
import { TERMS_AGGREGATION_MISSING_VALUE_NAME } from '../../../constants';
import { getNextAggregation } from '../TableWidget/utils';
import { bucketTypeGuard } from '../typeGuards';
/**
 * Transforms the aggregation response into a format that is consumable by the visualization components.
 *
 * @param dataPoint
 * @param aggregation
 */
export function transformAggregation(dataPoint, aggregation) {
    const isBucketedResponse = bucketTypeGuard(dataPoint);
    if (!isBucketedResponse) {
        return {
            entries: [],
            keys: [],
            missing: 0,
            count: 0,
        };
    }
    const { buckets } = dataPoint;
    const transformedData = buckets === null || buckets === void 0 ? void 0 : buckets.reduce((acc, bucket) => {
        const { name, value, aggregations: subDataPoints } = bucket;
        const { keys = [], entries, missing } = acc;
        const breakdowns = Array.isArray(subDataPoints) ? subDataPoints : [];
        const newKeys = [...keys];
        const data = getBucketData(bucket, aggregation);
        const [breakdownAggregation] = getNextAggregation([aggregation]);
        if (breakdowns.length) {
            breakdowns.forEach(subDataPoint => {
                if (!bucketTypeGuard(subDataPoint)) {
                    return;
                }
                subDataPoint.buckets.forEach(subAggregationBucket => {
                    const transformedBucket = getBucketData(subAggregationBucket, breakdownAggregation);
                    newKeys.push(transformedBucket.name);
                    Object.assign(data, {
                        [transformedBucket.name]: transformedBucket.total,
                        breakdownPayloads: [...data.breakdownPayloads, transformedBucket.payload],
                    });
                });
            });
        }
        if (!breakdowns.length) {
            newKeys.push('total');
        }
        if (name === TERMS_AGGREGATION_MISSING_VALUE_NAME) {
            return {
                entries,
                keys,
                missing: value,
            };
        }
        return {
            entries: [...entries, data],
            keys: newKeys,
            missing,
        };
    }, {
        entries: [],
        keys: [],
        missing: 0,
    });
    const { entries, keys, missing } = transformedData;
    const uniqueKeys = [...new Set(keys)];
    const count = entries.reduce((acc, curr) => acc + curr.total, 0);
    const entriesHasBreakdown = entries.map(entry => Object.keys(entry).length > 1).some(Boolean);
    const hasBreakdowns = entriesHasBreakdown && entries.length > 1;
    return {
        entries,
        missing,
        count,
        keys: uniqueKeys,
        breakdown: hasBreakdowns,
    };
}
