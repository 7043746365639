import { useReactiveVar } from '@apollo/client';
import { t } from '@lingui/macro';
import { Dialog, DialogHeader, DialogFooter, Button, AvatarWithLabel } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useGetLinks } from 'shared/domains/urls';
import { featureAvailability } from './reactiveVariable';
/**
 * This component will display a dialog if the user tries to toggle a feature that is not
 * available for the current user company.
 */
export function FeatureAvailability() {
    const feature = useReactiveVar(featureAvailability);
    const { contact } = useGetLinks();
    const title = t({
        message: 'Upgrade',
        comment: 'Pleditor: Dialog title informing the user that the feature is not available for their company.',
    });
    const text = t({
        message: 'This is an enterprise feature.',
        comment: 'Pleditor: Dialog text informing the user that the feature is not available for their company.',
    });
    const closeText = t({
        message: 'Close',
        comment: 'Pleditor: Dialog button text to close the dialog.',
    });
    const contactSupport = t({
        message: 'Contact support',
        comment: 'Pleditor: Dialog button text to close the dialog and contact support.',
    });
    const handleClose = () => featureAvailability(undefined);
    if (!feature) {
        return null;
    }
    return (<Dialog size="sm">
      <DialogHeader media={<AvatarWithLabel truncate title={title} subtitle={text} shape="circle">
            <Falcon icon="circle-up"/>
          </AvatarWithLabel>}/>
      <DialogFooter>
        <Button appearance="secondary" onClick={handleClose}>
          {closeText}
        </Button>
        <Button appearance="primary" as="a" href={contact} target="_blank" rel="noopener noreferrer">
          {contactSupport}
        </Button>
      </DialogFooter>
    </Dialog>);
}
