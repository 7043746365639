import { useState } from 'react';
import { t } from '@lingui/macro';
import { Chip, Menu, MenuList, MenuItem, ChipAction, Tooltip, MenuBody } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useUpdateStatus } from 'app/domains/tasks';
import { TaskStatusEnumEnum } from 'shared/domains/apollo/generated/types';
import { isOverdue } from './utils/isOverdue';
import { getStatusTranslations } from '../../utils/taskStatus';
export function StatusChip(props) {
    var _a;
    const { dueDate, status, id } = props;
    const [isOpen, setIsOpen] = useState(false);
    const { updateStatus, loading } = useUpdateStatus();
    const overDue = isOverdue({ date: dueDate, completed: status === TaskStatusEnumEnum.Completed });
    const statuses = getStatusTranslations();
    const { label, appearance } = statuses[status];
    const handleStatusChange = (newStatus) => {
        setIsOpen(false);
        updateStatus({ id, status: newStatus });
    };
    return (<Menu open={isOpen} onClose={() => setIsOpen(false)} target={<div>
          <Tooltip position="left" disabled={!overDue} title={t({
                message: 'Task is overdue',
                comment: 'tooltip message for status chip when a task is overdue',
            })}>
            <Chip label={label} icon={overDue === null || overDue === void 0 ? void 0 : overDue.icon} variant="outline" appearance={(_a = overDue === null || overDue === void 0 ? void 0 : overDue.appearance) !== null && _a !== void 0 ? _a : appearance} action={<ChipAction onClick={() => setIsOpen(!isOpen)} disabled={loading}>
                  {isOpen ? <Falcon icon="chevron-up"/> : <Falcon icon="chevron-down"/>}
                </ChipAction>}/>
          </Tooltip>
        </div>}>
      <MenuBody>
        <MenuList>
          <MenuItem $selected={status === TaskStatusEnumEnum.Todo} onClick={() => handleStatusChange(TaskStatusEnumEnum.Todo)}>
            <Falcon icon="circle"/>
            {statuses.todo.label}
          </MenuItem>
          <MenuItem $selected={status === TaskStatusEnumEnum.InProgress} onClick={() => handleStatusChange(TaskStatusEnumEnum.InProgress)}>
            <Falcon icon="circle-half-stroke"/>
            {statuses.in_progress.label}
          </MenuItem>
          <MenuItem $selected={status === TaskStatusEnumEnum.Completed} onClick={() => handleStatusChange(TaskStatusEnumEnum.Completed)}>
            <Falcon icon="circle-check"/>
            {statuses.completed.label}
          </MenuItem>
        </MenuList>
      </MenuBody>
    </Menu>);
}
