import { useMutation } from '@apollo/client';
import { ROOT } from 'app/domains/filesystem';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { mutation } from './mutation';
export function useCreateFolder(options) {
    var _a;
    const { onCreated } = options;
    const account = useAccount();
    const companyId = (_a = account.data) === null || _a === void 0 ? void 0 : _a.companyId;
    const [mutate, state] = useMutation(mutation, {
        onCompleted({ folder }) {
            if (onCreated) {
                onCreated([folder]);
            }
        },
    });
    const handler = (data) => {
        const { name, parentId, userAcls, teamAcls } = data;
        return mutate({
            variables: {
                name,
                companyId,
                parentId: parentId || ROOT,
                userAcls: userAcls.map(({ userId, actions }) => ({
                    userId,
                    companyId,
                    actions,
                })),
                teamAcls: teamAcls.map(({ teamId, actions }) => ({
                    teamId,
                    companyId,
                    actions,
                })),
            },
            update(cache, { data: createData }) {
                const { folder } = createData || {};
                if (!folder) {
                    return;
                }
                cache.modify({
                    fields: {
                        fileSystemEntries(existing = [], { toReference }) {
                            return [...existing, toReference(folder, true)];
                        },
                    },
                });
            },
        });
    };
    return [handler, state];
}
