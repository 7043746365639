var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { generateReturnTo } from './generateReturnTo';
import { getLocale } from './getLocale';
export function getRedirectOptions(options) {
    const { marketCode, returnTo, locale, appState } = options, rest = __rest(options, ["marketCode", "returnTo", "locale", "appState"]);
    return Object.assign(Object.assign({}, rest), { appState: Object.assign(Object.assign({}, appState), { returnTo: generateReturnTo({ marketCode, returnTo }) }), ui_locales: getLocale(locale) });
}
