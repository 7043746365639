import { ListItem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Icon = styled.div `
  display: flex;
  transform: translateX(0%);
  transition: all 275ms cubic-bezier(0, 1.13, 0.4, 1.33);

  [data-icon='falcon'] {
    color: ${themeGet('colors.gray.200')};
    transition: inherit;
  }
`;
export const StyledListItem = styled(ListItem) `
  &:hover {
    ${Icon} {
      transform: translateX(50%);

      [data-icon='falcon'] {
        color: ${themeGet('colors.primary.500')};
      }
    }
  }
`;
export const AlignRight = styled.div `
  display: flex;
  align-items: center;
  gap: ${themeGet('spacing.xl')};
  margin-left: auto;
  margin-right: ${themeGet('spacing.lg')};
`;
