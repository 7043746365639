import { Trans } from '@lingui/macro';
import { Typography, Avatar } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { StatusChip } from '../../StatusChip';
import { Container } from '../styled';
export function Status(props) {
    const { status, taskId, dueDate } = props;
    return (<Container>
      <Avatar size="sm" appearance="light">
        <Falcon icon="circle-half-stroke"/>
      </Avatar>
      <Typography $appearance="300">
        <Trans comment="task preview modal - status field">Status</Trans>
      </Typography>
      <StatusChip status={status} id={taskId} dueDate={dueDate}/>
    </Container>);
}
