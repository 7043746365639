import { Fragment } from 'react';
import { Trans, t } from '@lingui/macro';
import { Button, EmptyState } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Container, EmptyStateContainer } from './styled';
export function PickerRootWarning(props) {
    const { show, onClose, onSubmit } = props;
    if (show !== 'warning:root') {
        return null;
    }
    return (<Container>
      <EmptyStateContainer>
        <EmptyState withAvatar icon={<Falcon icon="warning"/>} title={t({
            comment: 'Root selected as destination warning - Title',
            message: "You haven't selected a location",
        })} description={t({
            comment: 'Root selected as destination warning - Description',
            message: 'To keep your documents organized we discourage saving to the root level.',
        })} actions={<Fragment>
              <Button size="small" appearance="secondary" onClick={onClose}>
                <Trans comment="Root selected as destination warning - Edit location">
                  Edit location
                </Trans>
              </Button>
              <Button size="small" appearance="danger" onClick={onSubmit}>
                <Trans comment="Root selected as destination warning - Save in root">
                  Save in root
                </Trans>
              </Button>
            </Fragment>}/>
      </EmptyStateContainer>
    </Container>);
}
