import { unit } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const OuterContainer = styled.div `
  height: 100%;
  display: flex;
  flex-direction: column;
`;
export const InnerContainer = styled.div `
  padding: ${unit('xl')};
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;
export const List = styled.div `
  display: flex;
  flex-direction: column;
`;
