import { useMutation } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { UPDATE_TEAM_MEMBER } from './mutation';
export function useUpdateTeamMember(options) {
    const { teamId, teamMemberId } = options;
    const { addToast } = useToast();
    const [mutate, { loading, error }] = useMutation(UPDATE_TEAM_MEMBER, {
        onCompleted: () => {
            addToast({
                appearance: 'success',
                title: t({
                    message: 'Team member updated',
                    comment: 'toast success message when updating team member',
                }),
            });
        },
        onError: () => {
            addToast({
                appearance: 'danger',
                title: t({
                    message: 'Something went wrong!',
                    comment: 'toast error message when updating team member fails',
                }),
            });
        },
    });
    function updateTeamMember(role) {
        return mutate({
            variables: {
                role,
                teamMemberId,
                teamId,
            },
        });
    }
    return { updateTeamMember, loading, error };
}
