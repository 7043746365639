import { Trans, t } from '@lingui/macro';
import { Box, H6 } from '@pocketlaw/tetris';
import * as yup from 'yup';
import { SearchInsightsWidgetTypeEnum } from 'shared/domains/apollo/generated/types';
import { FormDialog, SubmitButton, CancelButton, TextAreaField, TextField, } from 'shared/domains/forms';
import { AddDataPoint } from './AddDataPoint';
import { ChartSelection } from './ChartSelection';
import { ColorSelection } from './ColorSelection';
import { DataPoints } from './DataPoints';
import { FilterSelection } from './FilterSelection';
import { Label } from './Label';
import { Preview } from './Preview';
import { ColumnContainer, InputColumn, PreviewColumn, PreviewFooter } from './styled';
export function WidgetForm(props) {
    const { onSubmit, onClose, initialValues } = props;
    const values = {
        label: (initialValues === null || initialValues === void 0 ? void 0 : initialValues.label) || '',
        description: (initialValues === null || initialValues === void 0 ? void 0 : initialValues.description) || '',
        aggregations: (initialValues === null || initialValues === void 0 ? void 0 : initialValues.aggregations) || [],
        type: (initialValues === null || initialValues === void 0 ? void 0 : initialValues.type) || SearchInsightsWidgetTypeEnum.Barchart,
        colorScheme: (initialValues === null || initialValues === void 0 ? void 0 : initialValues.colorScheme) || undefined,
        filters: (initialValues === null || initialValues === void 0 ? void 0 : initialValues.filters) || {},
        isHorizontal: (initialValues === null || initialValues === void 0 ? void 0 : initialValues.isHorizontal) || false,
    };
    const validationSchema = yup.object({
        label: yup
            .string()
            .required(t({ message: 'Label is required', comment: 'Insights widget form: Label validation' })),
    });
    const handleOnSubmit = (data) => {
        const { aggregations, label, type, colorScheme, description, filters, isHorizontal, id } = data;
        onSubmit({ aggregations, label, type, colorScheme, description, filters, isHorizontal, id });
        onClose();
    };
    return (<FormDialog initialValues={values} onClose={onClose} size="xl" onSubmit={handleOnSubmit} validationSchema={validationSchema}>
      <ColumnContainer>
        <InputColumn>
          <Box mb="2xl">
            <H6>
              <Trans comment="Insights widget form: Options">Options</Trans>
            </H6>
          </Box>
          <Box gap="2xl" flexDirection="column">
            <Box flexDirection="column">
              <Label>
                <Trans comment="Insights widget form: Chart selection label">Visual</Trans>
              </Label>
              <ChartSelection />
            </Box>

            <Box flexDirection="column">
              <Label>
                <Trans comment="Insights widget form: Chart selection label">Color</Trans>
              </Label>
              <ColorSelection />
            </Box>

            <Box flexDirection="column">
              <Label>
                <Trans comment="Insights widget form: Data point selection label">Metric</Trans>
              </Label>

              <Box flexDirection="column">
                <AddDataPoint />
                <Box gap="2xl" flexDirection="column">
                  <DataPoints name="aggregations"/>
                </Box>
              </Box>
            </Box>

            <Box flexDirection="column">
              <Label>
                <Trans comment="Insights widget form: Filters label">Filters</Trans>
              </Label>
              <FilterSelection />
            </Box>
          </Box>
        </InputColumn>
        <PreviewColumn>
          <Box gap="md" flexDirection="column">
            <Preview />
            <TextField name="label" label={t({
            message: 'Name',
            comment: 'Insights widget form: Name placeholder',
        })} placeholder={t({
            message: 'Give your widget a name',
            comment: 'Insights widget form: Name placeholder',
        })}/>
            <TextAreaField name="description" label={t({ message: 'Description', comment: 'Insights widget form: Description' })} placeholder={t({
            message: 'Help others understand your widget by adding a description',
            comment: 'Insights widget form: Description placeholder',
        })} minHeight={24}/>
          </Box>

          <PreviewFooter>
            <CancelButton onClick={onClose}>
              <Trans comment="Insights widget form: Cancel button">Cancel</Trans>
            </CancelButton>
            <SubmitButton>
              <Trans comment="Insights widget form: Submit button">Submit</Trans>
            </SubmitButton>
          </PreviewFooter>
        </PreviewColumn>
      </ColumnContainer>
    </FormDialog>);
}
