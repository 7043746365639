var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment } from 'react';
import { Select as TetrisSelect } from '@pocketlaw/tetris';
import { useField, useFormikContext } from 'formik';
import { ErrorMessage } from 'shared/domains/forms/components/ErrorMessage';
export function Select(props) {
    const { name, label } = props, rest = __rest(props, ["name", "label"]);
    const { isSubmitting } = useFormikContext();
    const [field, meta] = useField(name);
    const error = Boolean(meta.touched && meta.error);
    return (<Fragment>
      <TetrisSelect disabled={isSubmitting} stretch label={label} {...field} invalid={error} {...rest}/>
      <ErrorMessage name={name}/>
    </Fragment>);
}
