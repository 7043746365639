import { Falcon } from '@pocketlaw/tetris/macro';
import { MimeTypesByExtension } from 'shared/domains/filesystem/utils/fileExtensions';
export const getDownloadIcon = (type) => {
    switch (type) {
        case MimeTypesByExtension.Pdf:
            return <Falcon icon="file-pdf"/>;
        case MimeTypesByExtension.Docx:
            return <Falcon icon="file-word"/>;
        default:
            return null;
    }
};
