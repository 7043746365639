import { t } from '@lingui/macro';
import { Box, IconToggleButton } from '@pocketlaw/tetris';
import { useField } from 'formik';
import { Blue, BlueShaded, Mono, MonoShaded, MultiColor, TwoColor } from './Icons';
export function ColorSelection() {
    const [field, , helpers] = useField('colorScheme');
    const handleOnCHange = (newValue) => {
        helpers.setValue(newValue);
    };
    const colorSchemes = [
        {
            id: 'mono',
            name: t({ message: 'Monochromatic', comment: 'Insights - Name of color scheme' }),
            icon: <Mono />,
        },
        {
            id: 'mono-shaded',
            name: t({ message: 'Monochromatic shaded', comment: 'Insights - Name of color scheme' }),
            icon: <MonoShaded />,
        },
        {
            id: 'blue',
            name: t({ message: 'Blue', comment: 'Insights - Name of color scheme' }),
            icon: <Blue />,
        },
        {
            id: 'blue-shaded',
            name: t({ message: 'Blue shaded', comment: 'Insights - Name of color scheme' }),
            icon: <BlueShaded />,
        },
        {
            id: 'two-color',
            name: t({ message: 'Two colors', comment: 'Insights - Name of color scheme' }),
            icon: <TwoColor />,
        },
        {
            id: 'multi-color',
            name: t({ message: 'Multi color', comment: 'Insights - Name of color scheme' }),
            icon: <MultiColor />,
        },
    ];
    return (<Box gap="lg">
      {colorSchemes.map(colorScheme => (<IconToggleButton key={colorScheme.id} aria-label={colorScheme.name} $toggled={colorScheme.id === field.value} onClick={() => handleOnCHange(colorScheme.id)}>
          {colorScheme.icon}
        </IconToggleButton>))}
    </Box>);
}
