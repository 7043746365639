var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Route } from 'react-router-dom';
import { GlobalProviders } from 'app/domains/init';
/**
 * Routes that can be accessed without being logged in.
 * If you add a new route, also add it to isPublicRoute
 */
export function PublicRoute(props) {
    const { component } = props, restProps = __rest(props, ["component"]);
    return (<Route {...restProps}>
      <GlobalProviders>
        <Route component={component}/>
      </GlobalProviders>
    </Route>);
}
