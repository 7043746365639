import React, { useState, createContext, useContext } from 'react';
import { t } from '@lingui/macro';
import { useUnmount } from 'react-use';
import { useCompanyFeatures } from 'app/domains/companies';
import { ReviewChat, usePlai } from 'app/domains/review';
import { ReviewStatus } from 'shared/domains/apollo/generated/types';
import { DocumentAi, DocumentDrawerHeader, useDocumentDrawer } from 'shared/domains/documents';
import { NotAvailableState } from './NotAvailableState';
import { PreReviewState } from './PreReviewState';
import { Root } from './Root';
import { DrawerContainer, Scroller } from './styled';
const PlaiDrawerContext = createContext(null);
export const usePlaiDrawer = () => {
    const context = useContext(PlaiDrawerContext);
    if (!context) {
        throw new Error('usePlaiDrawer hook must be used as a child to <ReviewDrawer>');
    }
    return context;
};
export function ReviewDrawer({ onClose }) {
    const { isFeatureAvailable } = useCompanyFeatures();
    const canUseReviewAgent = isFeatureAvailable('review-agent:basic');
    const [activeFeedbackId, setActiveFeedbackId] = useState(null);
    const { review, requestingReview, reviewing, updateActiveFeedback } = usePlai();
    const { openDrawer } = useDocumentDrawer();
    const reviewInProgress = reviewing || requestingReview;
    const noReviewYet = (review === null || (review === null || review === void 0 ? void 0 : review.status) === ReviewStatus.Cancelled) &&
        !reviewing &&
        !requestingReview;
    useUnmount(() => {
        updateActiveFeedback(null);
    });
    const handleGoBack = () => openDrawer('app:settings');
    const title = t({
        comment: 'Plai review drawer - header title',
        message: 'Contract review',
    });
    const activeFeedback = review === null || review === void 0 ? void 0 : review.feedbacks.find(feedback => feedback.feedback.id === activeFeedbackId);
    const value = {
        onSelectFeedback: id => setActiveFeedbackId(id),
        closeFeedbackChat: () => setActiveFeedbackId(null),
    };
    if (!canUseReviewAgent) {
        return (<DrawerContainer>
        <DocumentDrawerHeader type="nested" title={title} onClose={onClose} onGoBack={handleGoBack}/>
        <NotAvailableState />
      </DrawerContainer>);
    }
    if (noReviewYet || reviewInProgress) {
        return (<DrawerContainer>
        <DocumentDrawerHeader type="nested" title={title} onClose={onClose} onGoBack={handleGoBack}/>
        <DocumentAi>
          <PreReviewState noReviewYet={noReviewYet} reviewInProgress={reviewInProgress}/>
        </DocumentAi>
      </DrawerContainer>);
    }
    if (activeFeedback) {
        return (<PlaiDrawerContext.Provider value={value}>
        <DrawerContainer>
          <DocumentDrawerHeader type="nested" title={activeFeedback.feedback.name} onClose={onClose} onGoBack={() => setActiveFeedbackId(null)}/>
          <ReviewChat feedback={activeFeedback}/>
        </DrawerContainer>
      </PlaiDrawerContext.Provider>);
    }
    return (<PlaiDrawerContext.Provider value={value}>
      <DrawerContainer>
        <DocumentDrawerHeader type="nested" title={title} onClose={onClose} onGoBack={handleGoBack}/>
        <Scroller>
          <Root />
        </Scroller>
      </DrawerContainer>
    </PlaiDrawerContext.Provider>);
}
