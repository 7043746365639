var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { localeConfig } from 'shared/domains/languages/config';
import { StyledIcon } from './styled';
function Flag(props) {
    const { languageCode } = props, restProps = __rest(props, ["languageCode"]);
    if (!languageCode) {
        return null;
    }
    const { flag } = localeConfig.getLocale({ languageCode });
    if (!flag) {
        return null;
    }
    return <StyledIcon {...restProps} glyph={flag} alignment="baseline"/>;
}
export default Flag;
