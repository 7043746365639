import { t } from '@lingui/macro';
import { Alert } from '@pocketlaw/tetris';
export function CreditsErrorAlert() {
    return (<Alert appearance="danger" title={t({
            comment: 'Error alert title for the user if we could not load credits',
            message: 'Could not load credits',
        })} message={t({
            comment: 'Error alert message for the user if we could not load credits',
            message: 'Something went wrong while trying to load credits',
        })}/>);
}
