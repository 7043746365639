import { Fragment } from 'react';
import { t } from '@lingui/macro';
import { SliceInfoItem } from './SliceInfoItem';
export function SliceInfo(props) {
    const { data, active, total } = props;
    const totalName = t({
        message: 'Total',
        comment: 'Insights: Name for "total" count inside doughnut chart slice',
    });
    return (<Fragment>
      {data.map(entity => (<SliceInfoItem key={entity.name} name={entity.name} value={entity.total} active={Boolean(active === entity.name)}/>))}
      <SliceInfoItem name={totalName} value={total} active={!active}/>
    </Fragment>);
}
