import { Trans, t } from '@lingui/macro';
import { Button, EmptyState } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
export function NoApprovers() {
    return (<EmptyState withAvatar icon={<Falcon icon="thumbs-up"/>} title={t({
            message: 'No approvers added',
            comment: 'Approval drawer - No approvers added yet - title',
        })} description={t({
            message: 'Ask a colleague for approval',
            comment: 'Approval drawer - No approvers added yet - description',
        })} actions={<Button appearance="secondary">
          <Falcon icon="plus"/>
          <Trans comment="Approver drawer -  Add approvers dropdown - select approvers - button label">
            Add approvers
          </Trans>
        </Button>}/>);
}
