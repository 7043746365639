import { useQuery } from '@apollo/client';
import { templateQuery } from './query';
export function useGetTemplatesData(id) {
    var _a, _b;
    const { data, error, loading } = useQuery(templateQuery, {
        variables: {
            id: id || '',
        },
        skip: !id,
    });
    const [composerTemplate] = ((_a = data === null || data === void 0 ? void 0 : data.template) === null || _a === void 0 ? void 0 : _a.composerTemplates) || [];
    const [metadata] = ((_b = composerTemplate === null || composerTemplate === void 0 ? void 0 : composerTemplate.composer) === null || _b === void 0 ? void 0 : _b.metadata) || [];
    const { name } = metadata || {};
    return {
        data: { name },
        error,
        loading,
    };
}
