import { useState } from 'react';
import { t } from '@lingui/macro';
import { List, ListHeader, useTetrisTheme } from '@pocketlaw/tetris';
import { localTheme } from 'app/domains/common-ui';
import { useFeatureFlag } from 'shared/domains/featureFlag';
import { DarkThemeButton } from './DarkThemeButton';
import { LightThemeButton } from './LightThemeButton';
import { SystemThemeButton } from './SystemThemeButton';
import { Container } from './styled';
export function ThemeSelector() {
    const { setThemeType } = useTetrisTheme();
    const { getAppTheme, setAppTheme, getTetrisTheme } = localTheme;
    const [activeTheme, setActiveTheme] = useState(getAppTheme() || 'light');
    const isDarkModeAvailable = useFeatureFlag('feature_dark_mode');
    const setTheme = (theme) => {
        setAppTheme(theme);
        setActiveTheme(theme);
        setThemeType(getTetrisTheme());
    };
    if (!isDarkModeAvailable) {
        return null;
    }
    return (<List>
      <ListHeader title={t({ comment: 'App theme selector title', message: 'Appearance' })}/>
      <Container>
        <LightThemeButton selected={activeTheme === 'light'} onClick={() => setTheme('light')}/>
        <DarkThemeButton selected={activeTheme === 'dark'} onClick={() => setTheme('dark')}/>
        <SystemThemeButton selected={activeTheme === 'system'} onClick={() => setTheme('system')}/>
      </Container>
    </List>);
}
