import { useMutation } from '@apollo/client';
import { deletePromptMutation } from './query';
export function useDeletePrompt() {
    const [mutate, { loading, error }] = useMutation(deletePromptMutation, {
        update(cache, { data }) {
            if (data === null || data === void 0 ? void 0 : data.documentAssistantPrompt) {
                const cacheRef = cache.identify(data.documentAssistantPrompt);
                cache.evict({ id: cacheRef });
                cache.gc();
            }
        },
    });
    const deletePrompt = (id) => mutate({ variables: { id } });
    return { deletePrompt, loading, error };
}
