import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Content = styled.div `
  display: flex;
  height: 100%;
  width: 100%;
`;
export const GridContainer = styled.div `
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden auto;
`;
export const LeftColumn = styled.div `
  flex-shrink: 0;
  height: 100%;
  width: ${pxToRem(300)};
  border-right: solid ${pxToRem(1)} ${themeGet('colors.border.100')};
  overflow: hidden;
  overflow-y: auto;
`;
