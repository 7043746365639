import { Trans, t } from '@lingui/macro';
import { PageHeader, Breadcrumbs, Breadcrumb } from '@pocketlaw/tetris';
import { Link } from 'react-router-dom';
export function SharedWithMe() {
    return (<PageHeader heading={t({ message: 'Shared with me', comment: 'Shared with me page header title' })} breadcrumbs={<Breadcrumbs>
          <Breadcrumb as={Link} to="/dashboard">
            <Trans comment="Breadcrumb for - dashboard">Dashboard</Trans>
          </Breadcrumb>
          <Breadcrumb>
            <Trans comment="Breadcrumb for - Shared with me">Shared with me</Trans>
          </Breadcrumb>
        </Breadcrumbs>}/>);
}
