import { themeGet, themeGetColor } from '@pocketlaw/tetris';
export function getTheme(name) {
    const themes = {
        mono: {
            single: [themeGet('colors.primary.1000')],
            line: [themeGet('colors.primary.1000')],
            breakdown: [themeGet('colors.primary.1000')],
        },
        'mono-shaded': {
            single: [themeGet('colors.primary.1000'), themeGet('colors.primary.700')],
            line: [themeGet('colors.primary.1000')],
            breakdown: [themeGet('colors.primary.1000'), themeGet('colors.primary.700')],
        },
        blue: {
            single: [themeGet('colors.secondary.300')],
            line: [themeGet('colors.secondary.400')],
            breakdown: [themeGet('colors.secondary.300')],
        },
        'blue-shaded': {
            single: [themeGet('colors.secondary.300'), themeGet('colors.secondary.100')],
            line: [themeGet('colors.secondary.400')],
            breakdown: [themeGet('colors.secondary.300'), themeGet('colors.secondary.100')],
        },
        'two-color': {
            single: [themeGet('colors.orange.400'), themeGet('colors.secondary.300')],
            line: [themeGet('colors.secondary.400')],
            breakdown: [themeGet('colors.orange.400'), themeGet('colors.secondary.300')],
        },
        'multi-color': {
            single: [
                themeGetColor('secondary.300'),
                themeGetColor('orange.300'),
                themeGetColor('indigo.300'),
                themeGetColor('yellow.300'),
                themeGetColor('teal.300'),
                themeGetColor('red.300'),
                themeGetColor('magenta.300'),
            ],
            line: [themeGetColor('secondary.300')],
            breakdown: [
                themeGetColor('secondary.300'),
                themeGetColor('orange.300'),
                themeGetColor('indigo.300'),
                themeGetColor('yellow.300'),
                themeGetColor('teal.300'),
                themeGetColor('red.300'),
                themeGetColor('magenta.300'),
            ],
        },
    };
    const theme = name && themes[name] ? themes[name] : themes.mono;
    return (type, index = 0) => theme[type][index % theme[type].length];
}
