var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@apollo/client';
import { composersQuery } from './query';
import { useFilters } from './useFilters';
import { useNormalizeComposers } from './useNormalizeComposers';
export const useComposers = (filterData) => {
    const { filters } = useFilters(filterData);
    const _a = useQuery(composersQuery, {
        variables: { filters },
        context: {
            important: true, // expensive query, don't batch with other queries since it would slow them down
        },
    }), { data } = _a, rest = __rest(_a, ["data"]);
    const { composers: initialComposers = [] } = data || {};
    const normalizedComposers = useNormalizeComposers(initialComposers);
    const composers = [...normalizedComposers].sort((a, b) => a.metadata[0].name.localeCompare(b.metadata[0].name));
    return Object.assign({ composers }, rest);
};
