import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  margin-top: ${themeGet('spacing.md')};
  display: flex;
  justify-content: flex-end;
  opacity: 1;
  transition: opacity 0.25s ease-in;

  ${props => props.$hidden &&
    `
      opacity: 0;
    `}
`;
export const Action = styled.div `
  display: flex;

  &:not(:last-child) {
    margin-right: ${themeGet('spacing.xs')};

    &::after {
      width: ${pxToRem(1)};
      height: ${pxToRem(24)};
      background-color: ${themeGet('colors.border.100')};
      content: '';
      align-self: center;
      margin-left: ${themeGet('spacing.xs')};
    }
  }
`;
