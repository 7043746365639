var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@apollo/client';
import { t } from '@lingui/macro';
import { MetadataCompanyUsersDocument } from './MetadataCompanyUsers.gql';
export function useGetUsers() {
    const _a = useQuery(MetadataCompanyUsersDocument), { data } = _a, rest = __rest(_a, ["data"]);
    const { users } = data || {};
    const transformedUsers = (users === null || users === void 0 ? void 0 : users.map(companyUser => {
        const { user } = companyUser;
        const { id, name } = user || {};
        const fallbackName = t({ message: 'Unknown', comment: 'Metadata: Unknown user' });
        return {
            id: id || '',
            name: name || fallbackName,
        };
    })) || [];
    return Object.assign({ users: transformedUsers || [] }, rest);
}
