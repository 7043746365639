import { pxToRem, themeGet, unit } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: ${pxToRem(72)};
  padding: ${unit('xl')};
  gap: ${unit('md')};
`;
export const AlignRight = styled.div `
  margin-left: auto;
`;
export const Spacer = styled.div `
  display: flex;
  align-items: center;
  gap: ${themeGet('spacing.sm')};
`;
