import { gql } from '@apollo/client';
export const QUERY = gql `
  query getDocumentFiles($documentId: ID!) {
    document: fileSystemDocument(id: $documentId) {
      id
      files {
        id
        companyId
        name
        path
        type: mimeType
        size
        signed(name: "preview") {
          url
        }
      }
    }
  }
`;
