import { useRecipientsByStatus } from 'app/domains/esigning/hooks/useRecipientsByStatus';
import { UNASSIGNED_SIGNING_ORDER } from 'app/domains/esigning/utils';
import { OrderedSigningParties } from './OrderedSigningParties';
import { UnorderedSigningParties } from './UnorderedSigningParties';
export function PendingSigningParties() {
    const { pending: pendingParties, signed: signedParties } = useRecipientsByStatus();
    const signingParties = [...signedParties, ...pendingParties];
    const isOrdered = signingParties.some(party => party.order !== null && party.order !== undefined && party.order > UNASSIGNED_SIGNING_ORDER);
    if (isOrdered) {
        return <OrderedSigningParties signingParties={signingParties}/>;
    }
    return <UnorderedSigningParties signingParties={signingParties}/>;
}
