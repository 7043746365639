import { gql } from '@apollo/client';
export const GET_TEAM_DETAILS = gql `
  query getTeamDetails($id: UUID!) {
    team(id: $id) {
      id
      name
      description
    }
  }
`;
