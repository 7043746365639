import { unit } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const ErrorContainer = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${unit('xl')};
`;
