import { gql } from '@apollo/client';
export const getCompanyUsers = gql `
  query companyUsers($orderBy: [CompanyUserOrderBy!], $search: String) {
    companyUsers(order_by: $orderBy, where: { user: { name: { _ilike: $search } } }) {
      id
      role
      canManageMember: can_manage_member
      createdAt: created_at

      user {
        id
        name
        email
      }
    }
  }
`;
