import { array, object, string } from 'yup';
const attachmentSchema = object({
    id: string(),
    name: string(),
    path: string(),
    type: string(),
});
export const schema = object().shape({
    attachments: array().of(attachmentSchema),
});
