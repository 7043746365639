import { Trans, t } from '@lingui/macro';
import { Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { PreviewText } from '../PreviewText';
export function CreatedBy(props) {
    const { name } = props;
    const defaultName = t({
        message: 'Unknown user',
        comment: 'Default name when name does not exist',
    });
    const creatorName = name || defaultName;
    return (<PreviewText icon={<Falcon icon="pen-to-square"/>}>
      <Trans comment="Tasks creator field. Data of who created the task">
        Created by <Typography $appearance="100">{creatorName}</Typography>
      </Trans>
    </PreviewText>);
}
