import { padding, unit, breakpoints } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const LoadingContainer = styled.div `
  display: flex;
  ${padding(4)};
  align-items: center;
  justify-content: center;
`;
export const PaginationContainer = styled.div `
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  padding: ${unit('2xl')} 0;

  ${breakpoints.md} {
    justify-content: flex-start;
  }
`;
