import { themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const CustomContainer = styled.div `
  padding: ${themeGet('spacing.lg')} ${themeGet('spacing.xl')};
`;
export const LoaderContainer = styled.div `
  padding: ${themeGet('spacing.xl')};
  display: flex;
  align-items: center;
  justify-content: center;
`;
