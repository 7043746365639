import { useDraggable, TableRow } from '@pocketlaw/tetris';
import { ContextMenuCell, StatusCell, DateCell, AvatarCell, NameCell } from './TableCells';
import { getFileType } from './utils';
export default function DocumentTableRow(props) {
    const { id, type, documentType, name, date, lastSignRequest, onDuplicate, onRename, onMove, onDelete, onEditAccess, user, } = props;
    const { draggableProps } = useDraggable(`${type}:${id}`);
    const editUrl = `/document/${id}`;
    const fileType = getFileType(documentType);
    return (<TableRow {...draggableProps}>
      <NameCell headers="filesystemtable-name" to={editUrl} title={name} type={fileType}/>
      <DateCell headers="filesystemtable-updatedAt" date={date}/>
      <AvatarCell headers="filesystemtable-createdBy" primary={(user === null || user === void 0 ? void 0 : user.name) || (user === null || user === void 0 ? void 0 : user.email)}/>
      <StatusCell status={lastSignRequest === null || lastSignRequest === void 0 ? void 0 : lastSignRequest.status}/>
      <DateCell headers="filesystemtable-signedAt" date={lastSignRequest === null || lastSignRequest === void 0 ? void 0 : lastSignRequest.signedAt}/>
      <ContextMenuCell download id={id} editUrl={editUrl} onDuplicate={onDuplicate} onRename={onRename} onMove={onMove} onDelete={onDelete} onEditAccess={onEditAccess}/>
    </TableRow>);
}
