import { breakpoints } from '@pocketlaw/tetris';
import styled, { css } from 'styled-components';
export const ButtonText = styled.span `
  ${breakpoints.xs} {
    display: none;
  }
`;
export const IconContainer = styled.div `
  display: flex;

  ${props => props.$hide &&
    css `
      ${breakpoints[props.$hide]} {
        display: none;
      }
    `};
`;
