import { t } from '@lingui/macro';
import { AvatarWithLabel, Box, Skeleton, pxToRem } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { IllustrationPageRight } from '../styled';
export function IllustrationRight() {
    return (<IllustrationPageRight>
      <Box flexDirection="column" gap="xl" p="xl">
        <AvatarWithLabel solid size="sm" appearance="ai" title={t({
            message: 'Plai',
            comment: 'Review chat - message - assistant name',
        })}>
          <Falcon icon="sparkles"/>
        </AvatarWithLabel>
        <Box flexDirection="column" gap="sm">
          <Skeleton appearance="purple" height={pxToRem(3)} width="80%"/>
          <Skeleton appearance="purple" height={pxToRem(3)} width="100%"/>
          <Skeleton appearance="purple" height={pxToRem(3)} width="60%"/>
          <Skeleton appearance="purple" height={pxToRem(3)} width="70%"/>
          <Skeleton appearance="purple" height={pxToRem(3)} width="40%"/>
        </Box>
      </Box>
    </IllustrationPageRight>);
}
