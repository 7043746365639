import { t } from '@lingui/macro';
import { Box, Tooltip, Typography, pxToRem } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Digit } from './Digit';
import { AnimatedList, StyledLink } from './styled';
export function Count(props) {
    const { entries, activeName, subtitle, totalCount, to, tooltip } = props;
    const totalCountEntry = { name: 'totalCount', value: totalCount };
    const entriesWithTotalCount = (entries === null || entries === void 0 ? void 0 : entries.length) ? [totalCountEntry, ...entries] : [totalCountEntry];
    const activeEntry = activeName || 'totalCount';
    const linkTooltip = tooltip || t({ message: 'Click to see all', comment: 'Insights - Count widget link tooltip' });
    return (<Box flexDirection="column" width={pxToRem(200)} height="100%" justifyContent="center" style={{ overflow: 'hidden' }}>
      <AnimatedList>
        {entriesWithTotalCount.map(entry => (<Digit active={entry.name === activeEntry} key={entry.name}>
            {to ? (<Tooltip title={linkTooltip}>
                <StyledLink to={to}>
                  {entry.value || 0}
                  <Falcon icon="arrow-up-right-from-square"/>
                </StyledLink>
              </Tooltip>) : (entry.value || 0)}
          </Digit>))}
      </AnimatedList>

      <Typography $appearance="100" $lineHeight="neutralized" $truncate>
        {subtitle}
      </Typography>
    </Box>);
}
