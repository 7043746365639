import { gql } from '@apollo/client';
export const GET_TEAM_MEMBERS = gql `
  query GetTeamMembers($id: UUID!) {
    team(id: $id) {
      id
      members {
        id
        role
        companyUser {
          id
        }
      }
    }
  }
`;
