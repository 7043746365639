import { useEffect, useRef } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { usePrevious } from 'react-use';
export const useOnEnterRoute = (route, callback) => {
    const callbackRef = useRef(callback);
    const match = useRouteMatch(route);
    const prevMatch = usePrevious(match);
    const isNewRoute = (match === null || match === void 0 ? void 0 : match.url) !== (prevMatch === null || prevMatch === void 0 ? void 0 : prevMatch.url);
    useEffect(() => {
        if (match && isNewRoute) {
            callbackRef.current();
        }
    }, [match, isNewRoute]);
};
