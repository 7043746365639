import { Falcon } from '@pocketlaw/tetris/macro';
import { DocumentType, EntryKind } from 'shared/domains/apollo/generated/types';
import { MimeTypesByExtension } from 'shared/domains/filesystem/utils/fileExtensions';
export function KindIcon(props) {
    const { kind, documentType, mimeType } = props;
    switch (kind) {
        case 'WORKSPACE':
        case EntryKind.Folder:
            return <Falcon icon="folder"/>;
        case EntryKind.Document: {
            switch (documentType) {
                case DocumentType.Uploaded: {
                    switch (mimeType) {
                        case MimeTypesByExtension.Pdf:
                            return <Falcon icon="file-pdf"/>;
                        case MimeTypesByExtension.Xlsx:
                        case MimeTypesByExtension.Numbers:
                            return <Falcon icon="file-excel"/>;
                        case MimeTypesByExtension.Doc:
                        case MimeTypesByExtension.Docx:
                        case MimeTypesByExtension.Pages:
                            return <Falcon icon="file-word"/>;
                        case MimeTypesByExtension.Ppt:
                        case MimeTypesByExtension.Pptx:
                        case MimeTypesByExtension.Key:
                            return <Falcon icon="file-powerpoint"/>;
                        default:
                            return <Falcon icon="file-upload"/>;
                    }
                }
                case DocumentType.Freetext:
                    return <Falcon icon="file-edit"/>;
                case DocumentType.Composed:
                    return <Falcon icon="file-alt"/>;
                default:
                    return <Falcon icon="file-lines"/>;
            }
        }
        default:
            return null;
    }
}
