import { ErrorSubcodes } from '../types';
var ErrorCodes;
(function (ErrorCodes) {
    ErrorCodes["CompanyNotConnectedToProvider"] = "COMPANY_NOT_CONNECTED_TO_PROVIDER";
    ErrorCodes["DocumentDoesNotExist"] = "DOCUMENT_DOES_NOT_EXIST";
    ErrorCodes["ResourceNotFound"] = "RESOURCE_NOT_FOUND";
})(ErrorCodes || (ErrorCodes = {}));
export const ERROR_CODES = [
    ErrorCodes.CompanyNotConnectedToProvider,
    ErrorCodes.DocumentDoesNotExist,
    ErrorCodes.ResourceNotFound,
    ErrorSubcodes.CannotModifySignedSigner,
];
