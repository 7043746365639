import { useCallback } from 'react';
import { MenuBody } from '@pocketlaw/tetris';
import { useFormikContext } from 'formik';
import { SearchableMenuProvider, SearchableMenuSearchHeader, SearchableMenuSingleSelectList, } from 'shared/domains/common-ui';
import { SubmitButtonForSearchableLists } from './SubmitButtonForSearchableLists';
export function SingleSelectList(props) {
    const { data } = props;
    const { setFieldValue, values } = useFormikContext();
    const handleOnChange = useCallback((value) => {
        setFieldValue('value', value);
    }, [setFieldValue]);
    const searchableItems = data.map(item => (Object.assign({ id: item.value }, item)));
    return (<SearchableMenuProvider items={searchableItems} keys={['text']}>
      <SearchableMenuSearchHeader />
      <MenuBody>
        <SearchableMenuSingleSelectList onSelect={item => handleOnChange(item.id)} renderItem={({ text }) => text} selected={values.value}/>
      </MenuBody>
      <SubmitButtonForSearchableLists />
    </SearchableMenuProvider>);
}
