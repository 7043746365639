import { css } from 'styled-components';
export const tableStyles = css `
  figure.table.ck-widget {
    width: 100%;
  }
`;
export const tableWithoutHandleStyles = css `
  .ck .table.ck-widget {
    &,
    &:hover,
    &:focus-visible {
      outline: none;
    }

    .ck-widget__selection-handle {
      display: none;
    }
  }
`;
