var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useLazyQuery } from '@apollo/client';
import { base64EncodeUnicode } from 'app/domains/download/utils/base64EncodeUnicode';
import { documentHtmlData } from './query';
import { getLogoUrl } from './utils';
export function useRenderInput() {
    const [query] = useLazyQuery(documentHtmlData, {
        fetchPolicy: 'network-only',
    });
    return (variables) => __awaiter(this, void 0, void 0, function* () {
        var _a;
        const { data } = yield query({ variables });
        const html = ((_a = data === null || data === void 0 ? void 0 : data.fileSystemDocument) === null || _a === void 0 ? void 0 : _a.strippedHtmlBody) || '';
        const content = base64EncodeUnicode(html);
        const logoUrl = data ? getLogoUrl(data) : undefined;
        return {
            content,
            logoUrl,
        };
    });
}
