import { useTasks } from '../TasksProvider';
export function useGetPrePopulatedTask() {
    const { documentId } = useTasks();
    if (documentId) {
        return {
            attachments: [{ attachmentId: documentId, type: 'document' }],
        };
    }
    return {};
}
