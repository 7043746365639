import { SearchEntityTypeEnum } from 'shared/domains/apollo/generated/types';
import { DocumentActionMenu } from './DocumentActionMenu';
import { DraftActionMenu } from './DraftActionMenu';
export function SearchResultActionMenu(props) {
    const { entityId, entityType, cacheId, typename } = props;
    switch (entityType) {
        case SearchEntityTypeEnum.DocumentComposed:
        case SearchEntityTypeEnum.DocumentFreetext:
        case SearchEntityTypeEnum.DocumentUploaded:
            return <DocumentActionMenu cacheId={cacheId} entityId={entityId} typename={typename}/>;
        case SearchEntityTypeEnum.Draft:
            return <DraftActionMenu cacheId={cacheId} entityId={entityId} typename={typename}/>;
        default:
            return null;
    }
}
