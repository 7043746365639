import { useEffect, useState } from 'react';
import { usePleditor } from 'shared/domains/documents';
/**
 * Returns a map of the attachment id to the number of times it has been embedded in the document
 *
 * The hook communicates with a Pleditor plugin to get the data from the document
 */
export const usePleditorDocumentAttachments = () => {
    const { plugins } = usePleditor();
    const [embeddedCounter, setEmbeddedCounter] = useState({});
    useEffect(() => {
        const { attachmentStore } = plugins;
        if (!attachmentStore) {
            return;
        }
        attachmentStore.setEmbeds();
        attachmentStore.on('change:embeddedAttachmentsCounter', (event, counter) => {
            setEmbeddedCounter(counter);
        });
    }, [plugins]);
    return embeddedCounter;
};
