import { Typography, pxToRem, themeGet } from '@pocketlaw/tetris';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
function transitionStates(state) {
    const hidden = css `
    display: none;
    opacity: 0;
    transform: translate(0, ${pxToRem(-10)});
  `;
    const entering = css `
    display: flex;
    opacity: 0;
    transform: translate(0, ${pxToRem(-10)});
  `;
    const visible = css `
    opacity: 1;
    transform: translate(0, 0);
  `;
    const leaving = css `
    opacity: 0;
    transform: translate(0, ${pxToRem(10)});
  `;
    switch (state) {
        case 'hidden':
            return hidden;
        case 'entering':
            return entering;
        case 'leaving':
            return leaving;
        case 'visible':
        default:
            return visible;
    }
}
const DIGIT_HEIGHT = '2rem';
export const AnimatedList = styled(Typography).attrs(() => ({
    $fontSize: 'huge',
    $fontWeight: 'bold',
    $appearance: '300',
    $lineHeight: 'neutralized',
})) `
  position: relative;
  height: ${DIGIT_HEIGHT};
  overflow: hidden;
  margin-bottom: ${themeGet('spacing.sm')};
`;
export const AnimatedContainer = styled.div `
  display: flex;
  align-items: center;
  position: absolute;
  transition: all 0.1s ease-in-out;
  height: ${DIGIT_HEIGHT};

  top: 0;
  left: 0;

  ${props => transitionStates(props.$state)};
`;
export const StyledLink = styled(Link) `
  display: flex;
  width: 100%;
  text-decoration: none;
  gap: ${themeGet('spacing.md')};
  align-items: center;

  color: ${themeGet('colors.text.300')};

  & [data-icon='falcon'] {
    opacity: 0;
    color: ${themeGet('colors.text.100')};

    transition: opacity 0.1s ease-in-out;
  }

  &:hover [data-icon='falcon'] {
    opacity: 1;
  }
`;
