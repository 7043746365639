import { t } from '@lingui/macro';
import { Loader } from '@pocketlaw/tetris';
import { useAssistant } from 'app/domains/assistant';
import { AssistantSuggestion } from './AssistantSuggestion';
import { AssistantCommentBlock, AssistantCommentBubble } from '../AssistantComment';
export function AssistantSuggestions() {
    const { suggestions, fetchingSuggestions } = useAssistant();
    return (<AssistantCommentBlock title={t({
            comment: 'Document assistant - suggestions from the assistant - label before speech bubble',
            message: 'Suggestions',
        })} direction="right">
      {fetchingSuggestions ? (<AssistantCommentBubble direction="right">
          <Loader />
        </AssistantCommentBubble>) : (suggestions.map((suggestion, index) => (<AssistantSuggestion 
        // eslint-disable-next-line react/no-array-index-key
        key={`assistant-suggestion-${index}`} message={suggestion.message}/>)))}
    </AssistantCommentBlock>);
}
