import { plural, t } from '@lingui/macro';
import { AvatarWithLabel, Box, ButtonGroup, IconButton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useMount } from 'react-use';
import { usePlai } from 'app/domains/review';
export function Stepper(props) {
    const { docHighlights } = props;
    const { activeDocumentHighlight, updateActiveDocumentHighlight } = usePlai();
    const numberOfHighlights = docHighlights.length;
    const currentHighlightIndex = activeDocumentHighlight
        ? docHighlights.indexOf(activeDocumentHighlight)
        : -1;
    const disableStepperButton = numberOfHighlights < 2;
    const highlightNumber = currentHighlightIndex + 1;
    const title = t({
        comment: 'Document source highlights label',
        message: plural(numberOfHighlights, {
            one: `${highlightNumber}/${numberOfHighlights} source highlight`,
            other: `${highlightNumber}/${numberOfHighlights} source highlights`,
        }),
    });
    const updateActiveHighlightIndex = (newIndex) => {
        const newHighlight = docHighlights[newIndex];
        if (newHighlight) {
            updateActiveDocumentHighlight(newHighlight);
        }
    };
    const handleHighlightStepIncrease = () => {
        if (currentHighlightIndex + 1 > docHighlights.length - 1) {
            return updateActiveHighlightIndex(0);
        }
        return updateActiveHighlightIndex(currentHighlightIndex + 1);
    };
    const handleHighlightStepDecrease = () => {
        if (currentHighlightIndex - 1 < 0) {
            return updateActiveHighlightIndex(docHighlights.length - 1);
        }
        return updateActiveHighlightIndex(currentHighlightIndex - 1);
    };
    useMount(() => {
        updateActiveHighlightIndex(0);
    });
    return (<Box alignItems="center" gap="xl">
      <AvatarWithLabel size="sm" appearance="ai" title={title}>
        <Falcon icon="highlighter"/>
      </AvatarWithLabel>
      <ButtonGroup>
        <IconButton size="small" appearance="secondary" disabled={disableStepperButton} onClick={handleHighlightStepDecrease}>
          <Falcon icon="chevron-up"/>
        </IconButton>
        <IconButton size="small" appearance="secondary" disabled={disableStepperButton} onClick={handleHighlightStepIncrease}>
          <Falcon icon="chevron-down"/>
        </IconButton>
      </ButtonGroup>
    </Box>);
}
