import * as faSparkles from '@fortawesome/pro-regular-svg-icons/faSparkles';
import { t } from '@lingui/macro';
const sparklesIcon = `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="${faSparkles.svgPathData}"/>
  </svg>
`;
export function usePleditorAssistantConfig() {
    return {
        icon: sparklesIcon,
        label: t({ comment: 'Plai assistant label', message: 'Assistant' }),
    };
}
