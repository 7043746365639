import { useQuery } from '@apollo/client';
import { useCompanyFeatures } from 'app/domains/companies';
import { useActiveDocument, usePreview } from 'shared/domains/documents';
import { GetReviewDocument } from './getCurrentReview.gql';
export function useCurrentReview() {
    const { id: documentId } = useActiveDocument();
    const { isFreeText, isComposed, isDocx } = usePreview();
    const { isFeatureAvailable } = useCompanyFeatures();
    const isCorrectDocumentType = isFreeText || isComposed || isDocx;
    const isReviewAvailable = isFeatureAvailable('review-agent:basic');
    const { data, loading, startPolling, stopPolling, refetch } = useQuery(GetReviewDocument, {
        variables: { documentId },
        skip: !isCorrectDocumentType || !isReviewAvailable,
    });
    const { review } = data || {};
    return {
        refetchReview: refetch,
        review,
        loading,
        startPolling,
        stopPolling,
    };
}
