import { Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';
import { useUniversalSearch } from '../../useUniversalSearch';
import { StyledButton } from './styled';
export function LinkToSearchPage() {
    const { searchText, closeSearchPopup } = useUniversalSearch();
    const sanitizedString = (searchText === null || searchText === void 0 ? void 0 : searchText.trim()) || '';
    return (<StyledButton as={Link} type="button" size="xs" appearance="secondary" to={`/search?query=${sanitizedString}`} onClick={closeSearchPopup}>
      <Trans comment="Global search: button text to open more search results">More results</Trans>
    </StyledButton>);
}
