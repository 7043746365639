import { gql } from '@apollo/client';
export const pendingDocumentsQuery = gql `
  query pendingDocuments {
    signRequests: rawSignRequests(
      order_by: { created_at: desc }
      where: { status: { _in: [PENDING, OPENED] } }
    ) {
      id
      userId: user_id
      createdAt: created_at
      fileSystemDocument: file_system_document {
        id
        name
        type

        acl: acls {
          id
          userId
          actions
          user {
            id
            name
            email
          }
        }

        lastSignRequest {
          id
          signatories {
            id
            status
          }
        }
      }
    }
  }
`;
