import { t } from '@lingui/macro';
import { EmptyState } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { UploadAttachmentDropdown } from '../UploadAttachmentDropdown';
import { useAttachments } from '../useAttachments';
export function Empty() {
    const { hideAdd, emptyStateDescription } = useAttachments();
    const title = t({
        comment: 'No attachments added empty state - title',
        message: 'No attachments',
    });
    const defaultDescription = t({
        comment: 'No attachments added empty state - description',
        message: 'All your attachments will be listed here',
    });
    const description = emptyStateDescription || defaultDescription;
    return (<EmptyState withAvatar size="medium" icon={<Falcon icon="paperclip"/>} title={title} description={description} actions={!hideAdd && <UploadAttachmentDropdown origin="emptyState"/>}/>);
}
