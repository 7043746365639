import { t } from '@lingui/macro';
import { ButtonGroup } from '@pocketlaw/tetris';
import { DateRange } from './DateRange';
import { RelativeDate } from './RelativeDate';
import { withFilterErrorBoundary } from '../FilterErrorBoundary';
import { ClearFilter } from '../components/ClearFilter';
import { OperatorSelector } from '../components/OperatorSelector';
import { useFilter } from '../useFilter';
export const DateRangeFilter = withFilterErrorBoundary((props) => {
    const { name, label } = props;
    const filter = useFilter(name);
    return (<ButtonGroup>
      <OperatorSelector name={name} label={label} operators={[
            {
                id: 'gte',
                menuItem: t({ message: 'Date range', comment: 'Filter: Date range filter selection' }),
                onSelect: () => filter.setValue({ gte: undefined, lte: undefined }),
            },
            {
                id: 'rgte',
                menuItem: t({
                    message: 'Relative date',
                    comment: 'Filter: Relative date filter selection',
                }),
                onSelect: () => filter.setValue({ rgte: undefined }),
            },
        ]}/>
      {(filter.operator === 'gte' || filter.operator === 'lte') && <DateRange name={name}/>}

      {(filter.operator === 'rgte' || filter.operator === 'rlte') && <RelativeDate name={name}/>}

      <ClearFilter name={name}/>
    </ButtonGroup>);
});
