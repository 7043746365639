import { t } from '@lingui/macro';
import { Alert, Box } from '@pocketlaw/tetris';
import { getErrorMessage } from './utils';
import { useAttachments } from '../Provider';
export function Alerts() {
    const { error, exceedsMaxNumberOfAttachments, fileSizeTooBigWarning, maxNumberOfAttachments } = useAttachments();
    const errorMessage = getErrorMessage(error);
    const exceedsMaxNumberOfAttachmentsMessage = t({
        message: `You cannot upload more than ${maxNumberOfAttachments} attachments`,
        comment: 'This is an info message that will be visible when a user has uploaded the maximum number of allowed attachments',
    });
    const fileSizeTooBigMessage = t({
        comment: 'Error message when the size limit has been exceeded when uploading an attachment',
        message: `The file you selected is too large (max 10 mb).`,
    });
    const noErrors = [
        fileSizeTooBigWarning,
        exceedsMaxNumberOfAttachments,
        Boolean(errorMessage),
    ].every(err => !err);
    if (noErrors) {
        return null;
    }
    return (<Box flexDirection="column" gap="xl" p="xl">
      {fileSizeTooBigWarning && <Alert appearance="warning" message={fileSizeTooBigMessage}/>}
      {exceedsMaxNumberOfAttachments && (<Alert appearance="info" message={exceedsMaxNumberOfAttachmentsMessage}/>)}
      {errorMessage && <Alert appearance="danger" message={errorMessage}/>}
    </Box>);
}
