export function isUserAgent(nav) {
    switch (nav) {
        case 'mac':
            return /Mac/.test((navigator === null || navigator === void 0 ? void 0 : navigator.userAgent) || '');
        case 'win':
            return /Win/.test((navigator === null || navigator === void 0 ? void 0 : navigator.userAgent) || '');
        case 'linux':
            return /Linux/.test((navigator === null || navigator === void 0 ? void 0 : navigator.userAgent) || '');
        default:
            return false;
    }
}
