import { Fragment, useState } from 'react';
import { Trans } from '@lingui/macro';
import { Box, Button } from '@pocketlaw/tetris';
import { useEsigning } from 'app/domains/esigning/components/EsigningProvider';
import { useDocumentApproversWithStatus } from 'app/domains/esigning/hooks/useDocumentApproversWithStatus';
import { ApproverAlert } from './ApproverAlert';
import { AutomaticReminderAlert } from './AutomaticReminderAlert';
import { DefaultAlert } from './DefaultAlert';
import { useSendReminderToApprovers } from './useSendReminderToApprovers';
import { CancelApprovalRequestDialog } from '../CancelApprovalRequestDialog';
import { DocumentApprovers } from '../DocumentApprovers';
import { SectionContent, SectionFooter } from '../components';
export function ApprovalPendingState() {
    const { sendReminder, loading: sendingReminder } = useSendReminderToApprovers();
    const { loading } = useDocumentApproversWithStatus();
    const { approvalWorkflow } = useEsigning();
    const { currentUser } = approvalWorkflow;
    const [showDialog, setShowDialog] = useState(false);
    const handleClick = () => setShowDialog(true);
    const handleClose = () => setShowDialog(false);
    return (<Fragment>
      {showDialog && <CancelApprovalRequestDialog origin="sidebar" onClose={handleClose}/>}
      <SectionContent>
        <DocumentApprovers showStatus/>
      </SectionContent>
      <SectionFooter>
        {currentUser.isApprover ? <ApproverAlert /> : <DefaultAlert />}
        <AutomaticReminderAlert />
        <Box gap="xl">
          <Button $stretch appearance="secondary" disabled={loading} onClick={handleClick}>
            <Trans comment="Cancel approval request button label">Cancel request</Trans>
          </Button>
          <Button $stretch appearance="secondary" disabled={loading || sendingReminder} onClick={sendReminder}>
            <Trans comment="Send approval request reminder button label">Send reminder</Trans>
          </Button>
        </Box>
      </SectionFooter>
    </Fragment>);
}
