import { Copy } from './Copy';
import { Feedback } from './Feedback';
// import { Replace } from './Replace';
import { Action, Container } from '../styled';
export function ResolvedActions(props) {
    const { message, commentId, feedbackScore, hidden } = props;
    return (<Container $hidden={hidden}>
      {/* <Action data-testid="assistant-action">
          <Replace message={message} />
        </Action> */}
      <Action data-testid="assistant-action">
        <Copy message={message}/>
      </Action>
      <Action>
        <Feedback commentId={commentId} feedbackScore={feedbackScore}/>
      </Action>
    </Container>);
}
