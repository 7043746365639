import { Fragment } from 'react';
import { Trans } from '@lingui/macro';
import { MenuList, MenuSubheader } from '@pocketlaw/tetris';
import { useFormikContext } from 'formik';
import { MultiselectItem } from './MultiselectItem';
import { useSelectedEntries } from './useSelectedEntries';
/**
 * Displays a list of selected entries for the Filesystem entries reference type.
 *
 * @param props The component props.
 * @param props.handleSelect The function to handle selecting an entry.
 *
 * @returns The selected entries list component.
 */
export function SelectedEntriesList(props) {
    var _a;
    const { handleSelect } = props;
    const { values } = useFormikContext();
    const entries = useSelectedEntries(values.value);
    return (<Fragment>
      <MenuSubheader id="metadata-list-selected-items">
        <Trans comment="Metadata: Filesystem reference, selected values header">Selected</Trans>
      </MenuSubheader>
      <MenuList aria-labelledby="metadata-list-selected-items" as="nav" role="menu">
        {(_a = entries.data) === null || _a === void 0 ? void 0 : _a.map(entry => (<MultiselectItem key={entry.id} handleSelect={handleSelect} {...entry}>
            {entry.name}
          </MultiselectItem>))}
      </MenuList>
    </Fragment>);
}
