import { EmptyState, Loader } from '@pocketlaw/tetris';
import { Container, LoaderContainer } from './styled';
export function PickerLoaderOverlay(props) {
    const { title, description, loading } = props;
    if (!loading) {
        return null;
    }
    return (<Container>
      <LoaderContainer>
        <EmptyState icon={<Loader size={14}/>} title={title} description={description}/>
      </LoaderContainer>
    </Container>);
}
