var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { forwardRef } from 'react';
import { t } from '@lingui/macro';
import { Tooltip, Loader } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Actions, Container, StyledButton, StyledSectionSubHeading, TooltipButton } from './styled';
const Action = forwardRef((props, forwardedRef) => {
    const { children, tooltip, buttonText, onClick, disabled, loading } = props, restProps = __rest(props, ["children", "tooltip", "buttonText", "onClick", "disabled", "loading"]);
    return (<Container>
      <StyledSectionSubHeading disabled={disabled}>{children}</StyledSectionSubHeading>
      <Actions>
        <StyledButton onClick={onClick} ref={forwardedRef} disabled={disabled} {...restProps}>
          {buttonText}
          {loading && <Loader />}
        </StyledButton>
        <Tooltip title={tooltip}>
          <TooltipButton title={t `Information`} appearance="ghost">
            <Falcon icon="circle-info" $size={16}/>
          </TooltipButton>
        </Tooltip>
      </Actions>
    </Container>);
});
export default Action;
