var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useMutation } from '@apollo/client';
import { useAuth } from 'app/domains/auth/hooks/useAuth';
import { localeConfig } from 'shared/domains/languages/config';
import { createCompany, createCompanyAccountsQuery } from './mutation';
import { useGetMarketFreePlanId } from '../useGetMarketFreePlanId/useGetMarketFreePlanId';
export const useCreateCompany = () => {
    var _a;
    const userPreferredLocale = localeConfig.getActiveLocale().code;
    const auth = useAuth();
    const { userId } = ((_a = auth.data) === null || _a === void 0 ? void 0 : _a.profile) || {};
    const _b = useGetMarketFreePlanId(), { getMarketFreePlanId } = _b, queryState = __rest(_b, ["getMarketFreePlanId"]);
    const [mutate, mutationState] = useMutation(createCompany);
    const handleMutation = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const { companyMarketCode } = values, rest = __rest(values, ["companyMarketCode"]);
        const planId = yield getMarketFreePlanId(companyMarketCode);
        if (planId && userId) {
            return mutate({
                variables: Object.assign(Object.assign({}, rest), { planId,
                    userId,
                    userPreferredLocale,
                    companyMarketCode }),
                context: { withCompany: false },
                update(cache, result) {
                    var _a, _b;
                    const accounts = ((_b = (_a = result.data) === null || _a === void 0 ? void 0 : _a.accounts) === null || _b === void 0 ? void 0 : _b.returning) || [];
                    const [account] = accounts;
                    cache.writeQuery({
                        query: createCompanyAccountsQuery,
                        data: {
                            accounts,
                            account,
                        },
                    });
                },
            });
        }
        return undefined;
    });
    const error = queryState.error || mutationState.error;
    const loading = queryState.loading || mutationState.loading;
    return { createCompany: handleMutation, error, loading, data: mutationState.data };
};
