export function Global() {
    return (<svg viewBox="0 0 640 480">
      <path fill="#102E65" d="M0 0h640v480H0z"/>
      <g clipPath="url(#a)">
        <path d="M320 60c99.422 0 180 80.578 180 180s-80.578 180-180 180-180-80.578-180-180S220.578 60 320 60Zm0 337.5c11.742 0 28.406-10.125 43.523-40.289 6.961-13.922 12.797-31.359 16.313-49.711H259.531c4.149 18.352 9.985 35.789 16.946 49.711C291.594 387.375 308.258 397.5 320 397.5ZM255.523 285h128.954c1.968-14.203 3.023-29.32 3.023-45s-1.055-30.797-3.023-45H255.523c-1.968 14.203-3.023 29.32-3.023 45s1.055 30.797 3.023 45Zm124.313-112.5c-3.516-18.984-9.352-35.789-16.313-49.718C348.406 92.604 331.742 82.5 320 82.5c-11.742 0-28.406 10.104-43.523 40.282-6.961 13.929-12.797 30.734-16.946 49.718h120.305Zm26.719 22.5c2.461 14.414 3.445 29.461 3.445 45 0 15.539-.984 30.586-3.445 45h64.406c4.289-14.273 6.539-29.391 6.539-45 0-15.609-2.25-30.727-6.539-45h-64.406ZM370.273 90.698c14.766 19.92 26.438 48.333 33.188 81.802h58.851c-18.14-38.32-51.398-68.119-92.039-81.802Zm-100.546 0c-40.641 13.683-73.871 43.482-92.068 81.802h58.88c6.75-33.469 18.422-61.882 33.188-81.802ZM162.5 240c0 15.609 2.278 30.727 6.525 45h63.787c-1.828-14.414-2.812-29.461-2.812-45 0-15.539.984-30.586 2.812-45h-63.787c-4.247 14.273-6.525 29.391-6.525 45Zm299.812 67.5h-58.851c-6.75 33.469-18.422 61.875-33.188 81.773 40.641-13.64 73.899-43.453 92.039-81.773Zm-225.773 0h-58.88c18.197 38.32 51.427 68.133 92.068 81.773-14.766-19.898-26.438-48.304-33.188-81.773Z" fill="#fff"/>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="translate(140 60)" d="M0 0h360v360H0z"/>
        </clipPath>
      </defs>
    </svg>);
}
