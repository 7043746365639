import { gql } from '@apollo/client';
export const getInviteCategories = gql `
  query GetInviteCategories {
    categories(where: { _and: [{ composers: {} }] }, order_by: { order: asc }) {
      id
      metadata {
        id
        title
        marketLocaleCode: market_locale_code
      }
    }
  }
`;
