var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import slugify from '@pocketlaw/slugify';
import { v4 as uuidV4 } from 'uuid';
import { CustomError } from 'shared/domains/errors/CustomError';
function getBody(file, prefix, fields) {
    const name = file.name.slice(0, file.name.lastIndexOf('.'));
    const ext = file.name.slice(file.name.lastIndexOf('.'));
    const filename = uuidV4() + slugify(name) + ext;
    const filepath = [prefix, filename].filter(Boolean).join('/');
    const body = new FormData();
    Object.entries(fields).forEach(([key, value]) => {
        body.append(key, value);
    });
    body.append('key', filepath);
    body.append('file', file, filename);
    return body;
}
function getValueOf(xml, key) {
    var _a;
    const parser = new DOMParser();
    const xmlDocument = parser.parseFromString(xml, 'text/xml');
    const node = xmlDocument.getElementsByTagName(key).item(0);
    const value = (_a = node === null || node === void 0 ? void 0 : node.textContent) !== null && _a !== void 0 ? _a : '';
    return value;
}
export function uploadFile(file, prefix, fields, url, mimeType) {
    return __awaiter(this, void 0, void 0, function* () {
        const body = getBody(file, prefix, fields);
        const response = yield fetch(url, {
            body,
            method: 'POST',
        });
        // S3 returns XML
        const responseBody = yield response.text();
        if (!response.ok) {
            throw new CustomError('failed to upload file to S3', {
                data: {
                    body: responseBody,
                    status: response.status,
                    statusText: response.statusText,
                },
            });
        }
        const path = getValueOf(responseBody, 'Key');
        return {
            name: file.name,
            path,
            size: file.size,
            type: mimeType,
        };
    });
}
